// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

export const configJSON = require("./config");

export interface Props {
  // Customizable Area Start
  navigation: any;
  id: string;
  classes: any;
  history: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  uploadData: any
  files: any[];
  openDialog: boolean;
  isLoading: boolean;
  apiError: boolean,
  status: number;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start

  // Customizable Area End
}

export default class DashboardReportController extends BlockComponent<Props, S, SS> {

  getReportsId: string = "";
  constructor(props: Props) {
    super(props);

    this.state = {
      // Customizable Area Start
      openDialog: false,
      isLoading: true,
      apiError: false,
      status: NaN,
      uploadData: [],
      files: [],
      // Customizable Area End
    };

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage)
    ];

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages)
  }

  handleSaveData = (data: any) => {
    this.setState({ uploadData: data })
  }

  async componentDidMount() {
    this.getReports()
  }

  getReports = () => {
    const header = {
      token: document.cookie.split("; ").find((row) => row.startsWith("Token="))?.split("=")[1]
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getReportsId = requestMessage.messageId

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getReportApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };


  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const reportsApiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const reportsResponseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (reportsApiRequestCallId === this.getReportsId) {
        if (reportsResponseJson.errors || reportsResponseJson.error) {
          this.setState({ apiError: reportsResponseJson.error })
        } else {
          const rowData: any[] = [];
          reportsResponseJson.data.forEach((tableData: any) =>
            rowData.push({
              ...tableData.attributes
            })
          )
          this.setState({ uploadData: rowData })
        }
      }
    }
  }

}

// Customizable Area End