import React from "react";
// Customizable Area Start
import { connect } from "react-redux";
import Popover from '@material-ui/core/Popover';
import NotificationsNoneIcon from '@material-ui/icons/NotificationsNone';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { View } from "react-native";
import { Box, Button } from "@material-ui/core";
import { styled } from '@material-ui/styles'
import DeleteDialogWeb from "./DeleteDialogWeb.web";

// Customizable Area End
import NotificationWebController, { Props } from "./NotificationWebController"

// Customizable Area Start
const ViewBlock = styled(View)({
    position: 'relative',
    border: '1px solid #f3f1f1',
    background: '#fff',
    marginTop: '21px',
    marginBottom: '20px', 
    '&:after, &:before': {
        bottom: '100%',
        left: '50%',
        border: 'solid transparent',
        content: '""',
        height: 0,
        width: 0,
        position: 'absolute',
        pointerEvents: 'none',
    },
    '&:after': {
        borderColor: 'rgba(136, 183, 213, 0)',
        borderBottomColor: '#fff',
        borderWidth: '15px',
        marginLeft: '-15px',
    },
    '&:before': {
        borderColor: 'rgba(204, 204, 204, 0)',
        borderBottomColor: '#f3f1f1',
        borderWidth: '16px',
        marginLeft: '-16px',
    }


});
const ViewBlockSmall = styled(View)({
    position: 'relative',
    border: '1px solid #f3f1f1',
    background: '#fff',
    marginTop: '21px',
    marginBottom: '20px',
    '&:after, &:before': {
        bottom: '100%',
        left: '50%',
        border: 'solid transparent',
        content: '""',
        height: 0,
        width: 0,
        position: 'absolute',
        pointerEvents: 'none',
    },
    '&:after': {
        borderColor: 'rgba(136, 183, 213, 0)',
        borderBottomColor: '#fff',
        borderWidth: '15px',
        marginLeft: '-15px',
    },
    '&:before': {
        borderColor: 'rgba(204, 204, 204, 0)',
        borderBottomColor: '#f3f1f1',
        borderWidth: '16px',
        marginLeft: '-16px',
    }

});
const SmallPopoverBlock = styled(Popover)({
    '& .MuiPaper-root.MuiPopover-paper': {
        overflowY: 'hidden',
        boxShadow: 'none !important',
        width: '70px',
        height: '100px',
        backgroundColor: 'transparent',
    }
});
const PopoverBlock = styled(Popover)({
    '& .MuiPaper-root.MuiPopover-paper': {
        overflowY: 'hidden',
        boxShadow: 'none',
        width: '480px',
        marginTop: '11px',
        backgroundColor: 'transparent',
    }
});

const BoxBlock = styled(Box)({
    '&:last-child': {
        borderBottom: 'none !important',
    }
});
const SmallPopoverButton = styled(Button)({
    textTransform: "capitalize",
    fontWeight: "bold",
    display: 'block',
    padding: '3px 0',
})

// Customizable Area End
class NotificationPopoverWeb extends NotificationWebController {

    constructor(props: Props) {
        super(props)
        // Customizable Area Start
        // Customizable Area End
    }
    obj = { image: 'sfkmdfk', bol: false }
    render() {
        return (

            <div style={{ width: "235px" }}>
                {/* 
        // @ts-ignore */}
                <NotificationsNoneIcon data-test-id="btnNotificationIcon" aria-describedby={this.state.id} onClick={this.handleClick} />

                <PopoverBlock
                    id={`${this.state.id}`}
                    open={this.state.open}
                    anchorEl={this.state.anchorEl}
                    onClose={this.handleClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                >
                    {/* 
        // @ts-ignore */}
                    <ViewBlock style={webStyle.containerStyle}>
                        <h3 style={webStyle.h3Head}>Notifications</h3>
                        {this.state.apiError ? <p style={webStyle.contentText}>{this.state.serverSideError ? "notification load failed! please try after some time" : "No notifications yet!"}</p> : <>

                            <view style={webStyle.contentBody}>
                                {this.state.notificationList && this.state.notificationList.map((item: any) => {
                                    return (
                                        <>
                                            <h4 style={webStyle.h4Head}>{this.convertDate(item.date)}</h4>

                                            {item.data.map((items: any, index: any) => (
                                                <>
                                                    {/* 
        // @ts-ignore */}
                                                    <BoxBlock key={index} style={webStyle.notificationList}>
                                                        <view>
                                                            <p style={webStyle.contentText}>{items.attributes.contents} <a style={webStyle.clickHere} href="#">click here</a> to see.
                                                            </p>
                                                            <small style={webStyle.smallText}></small>
                                                        </view>
                                                        <div style={webStyle.contentBodyText}>
                                                            {/* 
        // @ts-ignore */}

                                                            {items.attributes.is_read ? <span style={webStyle.spanBudge}>New</span> : ""} <MoreVertIcon aria-describedby={index} onClick={this.handleClickSmallPopover} />
                                                            <SmallPopoverBlock
                                                                id={items.id}
                                                                open={this.state.smallPopoverOpen}
                                                                anchorEl={this.state.SmallEnchor}
                                                                onClose={this.handleCloseSmallPopover}
                                                                anchorOrigin={{
                                                                    vertical: "bottom",
                                                                    horizontal: "center"
                                                                }}
                                                                transformOrigin={{
                                                                    vertical: "top",
                                                                    horizontal: "center"
                                                                }}
                                                            >
                                                                <>
                                                                    {/* 
        // @ts-ignore */}
                                                                    <ViewBlockSmall style={webStyle.iconDropdown}>

                                                                        <SmallPopoverButton onClick={() => { this.handleCloseSmallPopover(), this.handleClose(), this.props.history.push("/notification") }} >Reply</SmallPopoverButton>
                                                                        <hr style={{ width: "75%" }}></hr>
                                                                        <SmallPopoverButton onClick={() => { this.setState({ openDialog: true }) }}>Delete</SmallPopoverButton>
                                                                        {/* 
        // @ts-ignore */}
                                                                        <DeleteDialogWeb handleCloseDialog={this.handleCloseDialog} openDialog={this.state.openDialog} />
                                                                    </ViewBlockSmall>
                                                                </>
                                                            </SmallPopoverBlock>
                                                        </div>
                                                    </BoxBlock>
                                                </>))}
                                        </>)
                                })}

                            </view>
                            {/* 
        // @ts-ignore */}
                            <a href="/notification" style={webStyle.contentFoot}>See all Notifications</a>
                        </>}
                    </ViewBlock>
                </PopoverBlock>
            </div >
        );
    }
}

export default NotificationPopoverWeb
// Customizable Area Start
const webStyle = {
    containerStyle: {
        background: "#fff",
        border: "1px solid rgb(243 241 241)",
        borderRadius: "12px",
        height: "100%",
        position: 'relative',

    },
    clickHere: {
        color: '#df802d',
    },
    contentBodyText: {
        display: 'flex',
        justifyCcontent: 'center',
        alignItems: 'center',
    },
    contentBody: {
        padding: "0 15px",
    },
    h3Head: {
        color: '#df802d',
        borderBottom: '1px solid #f3f1f1',
        margin: '0',
        padding: "15px",
    },
    h4Head: {
        margin: '8px 0 0',
        padding: "0 15px",
    },
    notificationList: {
        display: 'flex',
        alignItems: 'start',
        justifyContent: 'space-between',
        position: 'relative',
        borderBottom: '1px solid #ccc',
        padding: '10px',
    },

    contentText: {
        margin: '0',
        fontSize: '14px',
        width: '340px'
    },
    spanBudge: {
        background: '#df802d',
        borderRadius: '3px',
        fontSize: '10px',
        fontWeight: '600',
        color: '#fff',
        padding: '3px 10px',
        marginLeft: '10px'
    },
    iconDropdown: {
        background: "#fff",
        border: "1px solid rgb(243 241 241)",
        borderRadius: "4px",
        boxShadow: "23px 9px 36px -10px rgb(124 120 120 / 27%)",
        position: 'absolute',
        top: '0 !important',
        zIndex: '9',
        right: 'unset !important',
        left: '0 !important',
        padding: '6px 8px',
        fontSize: '12px',
        width: '100%'
    },
    smallText: {
        color: '#939191',
        fontSize: '12px',
    },
    contentFoot: {
        textDecoration: "none",
        padding: "15px 0px",
        color: '#df802d',
        fontWeight: '600',
        fontSize: '14px',
        textAlign: 'center',
        borderTop: '1px solid #ccc',
    }
};
// Customizable Area End