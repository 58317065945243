export const myInvoiceCheckLogo = require("../assets/logo.svg");
export const heroImageToolbar = require("../assets/hero_img.png");
export const quoteUploadIcon = require("../assets/quote-upload-icon.svg");
export const imgOcr = require("../assets/ocr.svg");
export const imgPdf = require("../assets/pdf.svg");
export const imgReport = require("../assets/report.svg");
export const checkboxIcon = require("../assets/checkbox-icon.svg");
export const imgHowitworks = require("../assets/howitworks.svg");
export const exportIcon = require("../assets/export-icon.svg");
export const aboutquotecheckLogo = require("../assets/aboutquotecheck.svg");
export const sortIcon = require("../assets/sort-icon.svg");
export const logoFooterMyInvoiceCheck = require("../assets/logo2.svg");
export const logoFacebook = require("../assets/facebook.svg");
export const logoTwitter = require("../assets/twitter.svg");
export const logoInstagram = require("../assets/instagram.svg");
export const sidebarLogo = require("../assets/sidebar-logo.svg");
export const quoteCompareIcon = require("../assets/quote-compare-icon.svg");
export const reportGenerationIcon = require("../assets/report-generation-icon.svg");
export const dashboardIcon = require("../assets/jfYpLL01.svg");
export const libraryIcon = require("../assets/2zB9ym01.svg");
export const quoteIcon = require("../assets/quote-icon.svg");
export const invoiceIcon = require("../assets/invoice-icon.svg");
export const reportIcon = require("../assets/report-icon.svg");
export const analyticsIcon = require("../assets/analytics-icon.svg");
export const documentIcon = require("../assets/document-icon.svg");
export const filesUploadIcon = require("../assets/upload-files-icon.svg");
export const uploadSuccessIcon = require("../assets/upload-success-icon.svg");
export const closeModalIcon = require("../assets/close-modal-icon.png");
export const pauseUploadIcon = require("../assets/pause-upload-icon.svg");
export const cancelUploadIcon = require("../assets/cancel-upload-icon.svg");
export const uploadFailedIcon = require("../assets/upload-failed-icon.svg");
export const SearchIcon = require("../assets/search-icon.svg");
export const uploadIcon = require("../assets/upload.svg")
export const closeIcon = require("../assets/close.svg")
export const reportIcon1 = require("../assets/error 1.svg")
export const reportIcon2 = require("../assets/error 2.svg")
export const upload_invoice = require("../assets/upload_invoice.png")
export const create_new_project = require("../assets/create_new_project.png")
export const no_data = require("../assets/no_data.png")
export const create_new_invoice = require("../assets/create_new_invoice.png")
export const bitmap = require("../assets/group_Bitmap.png")
export const bitmaptwo = require("../assets/group_Bitmap_two.png")
export const bitmapthree = require("../assets/group_Bitmap_three.png")
export const bitmapfour = require("../assets/group_Bitmap_four.png")
export const bitmapfive = require("../assets/group_Bitmap_five.png")
export const bitmapsix = require("../assets/group_Bitmap_six.png")
export const bitmapseven = require("../assets/group_Bitmap_seven.png")
export const bitmapeight = require("../assets/group_Bitmap_eight.png")
export const bitmapnine = require("../assets/group_Bitmap_nine.png")
export const bitmapten = require("../assets/group_Bitmap_ten.png")
export const home_background = require("../assets/home_background.png")
export const Muscle = require("../assets/Muscle.png")
export const Wave = require("../assets/Wave.png")
export const MyInvoiceCheckIcon = require("../assets/MyInvoiceCheckLogo.png")
export const headerNewLogo = require("../assets/header_new_logo.png")
export const bannerImageNew = require("../assets/banner_image_new.png")
export const greenCheckIcon = require("../assets/greenCheckIcon.png")
export const calculatorIcon = require("../assets/calculator_icon.svg")
export const easyHandIcon = require("../assets/easy_hand_icon.svg")
export const fileIcon = require("../assets/file_icon.svg")
export const folderCloudIcon = require("../assets/folder_cloud_icon.svg")
export const sectionHeadingLine = require("../assets/section_heading_line.svg")
export const howItWorkUnderline = require("../assets/howItWork_underline.svg")
export const stepperVerticalLine = require("../assets/stepper_vertical_line.svg")
export const leftArrowSlide = require("../assets/left_arrow_slide.svg")
export const rightArrowSlide = require("../assets/right_arrow_slide.svg")
export const leftArrowEnabled = require("../assets/left_arrow_enabled.svg")
export const quoteIconSlide = require("../assets/quote_icon.svg")
export const userImgSlide = require("../assets/user_img.svg")
export const dotsDesignImage = require("../assets/dots_design.svg")
export const vendorsNext = require("../assets/vendorarrows.svg")
export const globalPdf = require("../assets/globalpdf.svg");
export const arrowIcon = require("../assets/arrow.svg");