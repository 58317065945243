// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import Emitter from "../../BulkUploading/src/util";
import React from 'react'

export const configJSON = require("./config");

export interface RenderDialogProps {
    openDialog: boolean;
    handleCloseDialog: () => void;
    apiError: boolean;
    errMsg: string;
    successMsg: string;
}

export interface Props {
    openDialog: boolean;
    handleCloseDialog: () => void;
    files: any[];
    status: any;
    onDrop: (event: any) => void;
    rows: any;
    title: string;
    hasMenu: boolean;
    apiError: boolean;
    isLoading: boolean;
    handleClickOpenDialog: () => void;
    handleSaveData: (data: any, docType: string) => void;
    navigation: any;
    errMsg?: any;
    successMsg?: any;
    history: any;
}
interface SS { }
interface S {
    openDialog: boolean;
    files: any[];
    status: number;
    rows: any;
    uploadRows: any;
    comparisonRows: any;
    apiError: boolean,
    accept: any,
    isLoading: boolean,
    selectedQuoteId: string,
    invoiceRows: any,
    progressValue: number;
    remainingTime: number;
    errMsg: string;
    successMsg: string;
    comparisonData: any
}

export default class DashboardNewWebController extends BlockComponent<
    Props,
    S,
    SS
> {
    /**
     *
     */
    uploadFileId: string = "";
    getFileId: string = "";
    uploadInvoiceId: string = "";
    getUploadFiles: string = "";
    getComparisonFilesId: string = "";
    getInvoiceFileId: string = "";
    scrollToBottomRef: React.RefObject<any>;
    constructor(props: Props) {
        super(props);
        this.scrollToBottomRef = React.createRef();
        this.state = {
            openDialog: false,
            files: [],
            status: NaN,
            apiError: false,
            rows: [],
            uploadRows: [],
            invoiceRows: [],
            comparisonRows: [],
            comparisonData: [],
            accept: { "application/pdf": [".pdf"] },
            isLoading: true,
            selectedQuoteId: '',
            progressValue: 0,
            remainingTime: 20,
            errMsg: '',
            successMsg: ''
        };
        this.subScribedMessages = [
            getName(MessageEnum.CountryCodeMessage),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.ReciveUserCredentials),
        ];
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages)
    }

    componentDidMount(): any {
        this.fetchFiles()
        this.fetchUploadFiles()
        this.getInvoices()
        this.quotesComparisonData()
    }

    componentDidUpdate(prevProps: any, prevState: any): void {
        if (prevProps.files && prevProps.files.length !== this.props.files.length) {
            this.startProgress()
        }
    }

    quotesComparisonData = () => {
        const header = {
            "Content-Type": configJSON.quotesApiContentType,
            "token": document.cookie.split("; ").find((row) => row.startsWith("Token="))?.split("=")[1]
        };

        const quotesComparisonMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.getComparisonFilesId = quotesComparisonMessage.messageId;

        quotesComparisonMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.quotesComparisonAPiEndPoint
        );

        quotesComparisonMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        quotesComparisonMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.fileGetApiMethod
        );

        runEngine.sendMessage(quotesComparisonMessage.id, quotesComparisonMessage);
        return true;
    }

    getToken = () => {
        const token = document.cookie.split("; ").find((row) => row.startsWith("Token="))?.split("=")[1]
        return token;
    }

    startProgress() {
        this.scrollToBottomRef.current?.scrollIntoView({ behavior: 'smooth'})
        let counter = 1;
        const totalTime = 20;
        const intervalTime = 100;
        const interval = setInterval(() => {
            counter += intervalTime;
            const newProgress = Math.min(Math.ceil((counter / (totalTime * 1000)) * 100), 95);
            const remainingTime = Math.max(Math.ceil(totalTime - counter / 1000), 1);
            this.setState({ progressValue: newProgress, remainingTime: remainingTime })
            if ((this.props.files.length === 0 && newProgress >= 100) || !this.props.openDialog) {
                clearInterval(interval);
                this.setState({ progressValue: 0, remainingTime: 20 })
            }
        }, intervalTime);
    }

    fetchFiles() {
        const header = {
            "Content-Type": configJSON.fileUploadApiContentType,
            "token": this.getToken()
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.getFileId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.fileUploadApiEndPoint
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.fileGetApiMethod
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);

        return true;
    }

    getInvoices = () => {
        const header = {
            token: this.getToken()
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.getInvoiceFileId = requestMessage.messageId

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.getInvoiceApiEndPoint
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.fileGetApiMethod
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);

        return true;
    };

    fetchUploadFiles() {
        const header = {
            "Content-Type": configJSON.fileUploadApiContentType,
            "token": this.getToken()
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.getUploadFiles = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.getQuoteApiEndPoint
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.fileGetApiMethod
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);

        return true;
    }

    handleQuoteUploadResponse(apiRequestCallId: any, responseJson: any) {
        if (apiRequestCallId === this.uploadFileId && responseJson) {
            Emitter.emit("FETCH_NOTIFICATION_LIST_FOR_POPUP", {});
            this.setState({ isLoading: false })
            this.setState({ status: responseJson.status })
            if (responseJson.error) {
                this.setState({ apiError: true, errMsg: responseJson.error })
            } else {
                this.fetchFiles()
                this.setState({ apiError: false, successMsg: responseJson.message })
            }
        }
    }

    handleInvoiceUploadResponse(apiRequestCallId: any, responseJson: any) {
        if (apiRequestCallId === this.uploadInvoiceId && responseJson) {
            Emitter.emit("FETCH_NOTIFICATION_LIST_FOR_POPUP", {});
            this.setState({ isLoading: false })
            this.setState({ status: responseJson.status })
            if (responseJson.error) {
                this.setState({ apiError: true, errMsg: responseJson.error })
                this.fetchFiles()
            } else {
                this.fetchFiles()
                this.setState({ apiError: false, successMsg: responseJson.message })
            }
        }
    }

    async receive(from: string, message: Message) {
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            this.handleQuoteUploadResponse(apiRequestCallId, responseJson)

            this.handleInvoiceUploadResponse(apiRequestCallId, responseJson)

            if (apiRequestCallId === this.getFileId) {
                this.setState({ rows: responseJson.data.map((item: { attributes: any; }, i: any) => item.attributes) })
            }
            if (apiRequestCallId === this.getUploadFiles) {
                this.setState({ uploadRows: responseJson.data })
            }
            if (apiRequestCallId === this.getInvoiceFileId) {
                this.setState({ invoiceRows: responseJson.data.map((item: { attributes: any; }, i: any) => item.attributes) })
            }
            if (apiRequestCallId === this.getComparisonFilesId) {
                this.setState({ comparisonData: responseJson.data })
            }
        }
    }

    handleClickOpenDialog = () => {
        this.setState({ openDialog: true, selectedQuoteId: '' });
    };

    handleOpenDialogWithQuoteId = (e: any, quote_Id: string) => {
        e?.stopPropagation();
        this.setState({ openDialog: true, selectedQuoteId: quote_Id });
    }

    handleCloseDialog = () => {
        this.setState({ openDialog: false, files: [], isLoading: true, apiError: false });
    };

    onInvoiceDrop = (acceptedFile: any) => {
        this.setState({ files: acceptedFile });
        const header = {
            "token": this.getToken()
        };

        const formData: any = new FormData();

        acceptedFile.forEach((file: any) => {
            formData.append('file', file);
        });

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.uploadInvoiceId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.invoiceFileUploadApiEndPoint}?quote_id=${this.state.selectedQuoteId}`
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            formData
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.fileUploadApiMethod
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);

        return true;
    }

    onDrop = (acceptedFiles: any) => {
        this.setState({ files: acceptedFiles });
        if (this.state.files.length > 0) {
            const header = {
                "token": this.getToken()
            };

            const formData: any = new FormData();

            acceptedFiles.forEach((file: any) => {
                formData.append('files[]', file);
            });

            const requestMessage = new Message(
                getName(MessageEnum.RestAPIRequestMessage)
            );

            this.uploadFileId = requestMessage.messageId;
            requestMessage.addData(
                getName(MessageEnum.RestAPIResponceEndPointMessage),
                configJSON.fileUploadApiEndPoint
            );

            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestHeaderMessage),
                JSON.stringify(header)
            );

            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestBodyMessage),
                formData
            );

            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestMethodMessage),
                configJSON.fileUploadApiMethod
            );

            runEngine.sendMessage(requestMessage.id, requestMessage);

            return true;
        }
    };

    handleSaveData = (data: any, docType: string) => {
        if(docType == "Quote") {
            this.setState({ rows: data })
        }
        if(docType == "Invoice") {
            this.setState({ invoiceRows: data })
        } 
        if(docType == "") {
            this.setState({ rows: data, invoiceRows: data })
        }
    }
}
// Customizable Area End