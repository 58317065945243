// Customizable Area Start
import React from "react";
import {
    FormControl,
    Box,
    Typography,
    Button,
    Tabs,
    Tab,
    Link,
    Modal,
} from "@material-ui/core";
import Dropzone from "react-dropzone";
import { styled } from "@material-ui/styles";
import { createTheme, withStyles } from "@material-ui/core/styles";
import { cancelupload, failedUpload, pauseUpload, successUpload, filesUploadIcon } from "./assets";
import LinearProgress from '@material-ui/core/LinearProgress';
import OcrUploadController, { Props, OcrTabPanelProps, configJSON } from "./OcrUploadController.web";
import { DocumentOpener } from "../../customform/src/DocumentOpener.web";
import CustomLoader from "../../../framework/src/CustomLoader.web";
import Header from "../../landingpage/src/Header.web";
// Customizable Area End


// Customizable Area Start
export const theme = createTheme({});
// Customizable Area End

//Customizable Area Start
const StyledMatchAlgoButton = styled(Button)({
    width: '50%',
    border: '1px solid #e0802e',
    borderRadius: '10px',
    display: "block",
    margin: "2% auto",
    padding: '20px 0',
    textTransform: 'unset',
    color: '#ffffff',
    fontSize: '22px',
    fontWeight: "bold",
    lineHeight: '22px',
    background: '#e0802e',
    "&.Mui-disabled": {
        background: "#fff",
        color: "#e0802e",
        fontWeight: "600",
    },
    "&.MuiButton-root:hover": {
        background: "#e0802e",
    }
});

const StyledOcrDialogBrowseButton = styled(Button)({
    "&.MuiButton-root": {
        width: "191px",
        fontSize: "18px",
        color: "#df802d",
        fontWeight: "bold",
        borderRadius: "8px",
        backgroundColor: "#ffff",
        border: "1px solid #df802d",
        textTransform: "capitalize",
    }
});

export const OcrTabPanel = (props: OcrTabPanelProps) => {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

export const OcrFileUploadBox = (props: any) => {
    const { title, classes, acceptedFile, onDrop, isDisabled } = props;

    return (
        <FormControl className={classes.ocrFormSelectWrapper} size="small">
            <Box className={classes.ocrFromSelectTitle} id="demo-select-small-label">
                {title}
            </Box>
            <div>
                <Dropzone disabled={isDisabled} data-testid="dropzone" accept={acceptedFile} onDrop={onDrop}>
                    {({ getRootProps, getInputProps }: any) => (
                        <div className={classes.ocrDropZone} {...getRootProps()}>
                            <img data-testid="dropzoneImgId" src={filesUploadIcon} alt="fileupload-icon" />
                            <div className={classes.ocrDropZoneTextWrapper}>
                                <span data-testid="dropzoneDragDropTextId">Drag and Drop your files here </span>
                                <br />
                                <span data-testid="dropzoneOrTextId">Or</span>
                            </div>
                            <input id="input" {...getInputProps()} />
                            <StyledOcrDialogBrowseButton data-testid="dropzoneBrowseBtnId" variant="outlined">
                                Browse
                            </StyledOcrDialogBrowseButton>
                        </div>
                    )}
                </Dropzone>
            </div>
        </FormControl>
    )
}

export const a11yProps = (index: number) => {
    return {
        id: `vertical-tab-${index}`,
        'aria-controls': `vertical-tabpanel-${index}`,
    };
}

export const OcrUploadTitleTabPanel = (props: OcrTabPanelProps) => {

    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
            className="topBorderTabBox"
        >
            {value === index && (
                <Box p={1}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

export const OcrUploadTitleOcra11yProps = (indextab: any) => {
    return {
        id: `simple-tab-${indextab}`,
        'aria-controls': `simple-tabpanel-${indextab}`,
    };
}
//Customizable Area End
class OcrUpload extends OcrUploadController {
    constructor(props: Props) {
        super(props);
    }
    // Customizable Area Start
    // Customizable Area End
    render() {
        // Customizable Area Start
        const { classes } = this.props;
        // Customizable Area End
        return (
            // Customizable Area Start
            <>
                <Header data-testid="ocruploadHeaderId" navigation={this.props.navigation} handleSaveData={() => { }} history={undefined} />
                <Box className={classes.ocrMainBoxWrapper}>
                    <OcrUploadTitleTabPanel index={1} value={this.state.tabTitleSwitchValue}>
                        <Box className={classes.ocrTabBox}>
                            <OcrFileUploadBox
                                data-testid="quotesFileUploadId"
                                title={'Upload Quotes'}
                                classes={classes}
                                acceptedFile={this.state.acceptedFileType}
                                onDrop={this.onUploadQuotesDrop}
                                isDisabled={this.state.value != 0}
                            />
                        </Box>
                    </OcrUploadTitleTabPanel>
                </Box>
                <Box>
                    <pre>{this.state.quoteData}</pre>
                </Box>
            </>
            // Customizable Area End
        );
    }
}

// Customizable Area Start

export const ocrUploadStyle = (theme: any) => ({
    ocrTabBox: {
        background: '#ffffff',
        width: '100%',
    },
    ocrLoadingTabBox: {
        width: '100%',
        backgroundColor: "rgb(0, 0, 0, .4)",
    },
    ocrInnerBoxWrapper: {
        margin: '2% 5%',
        border: '1px solid #eee',
        borderRadius: '10px'
    },
    ocrInnerBoxTitleWrapper: {
        borderBottom: '1px solid #eee',
        padding: '10px'
    },
    ocrUploadWrapperStyle: {
        padding: '20px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'baseline',
        gap: '30px'
    },
    ocrFormSelectWrapper: {
        width: '100%',
        '& input': {
            border: '1px solid #eae1e1',
            padding: '14px',
            borderRadius: '5px',
        },
        '& fieldset': {
            border: '1px solid #eae1e1',
        }
    },
    ocrFromSelectTitle: {
        paddingBottom: '10px',
        fontWeight: 500,
        fontSize: '18px'
    },
    ocrDropZoneTextWrapper: {
        margin: "30px 0",
        fontSize: "18px",
        textAlign: "center" as "center",
        '& span': {
            fontWeight: 200,
        }
    },
    uploadIcon: {
        width: '80px',
        height: '80px'
    },
    ocrDropZone: {
        display: "flex",
        flexDirection: "column" as "column",
        alignItems: "center",
        justifyContent: "center",
        padding: "36px 0",
        borderRadius: "10px",
        backgroundColor: "transparent",
        border: "1px dashed rgba(223,128,45,0.45)",
        '& img': {
            width: '70px',
            height: '70px'
        }
    },
    ocrTitleTextWrapper: {
        margin: '10px'
    },
    tabOcrBoxTextWrapper: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '5px'
    },
    tabOcrText: {
        fontWeight: 600
    },
    tabsOcrWrapper: {
        '& span.MuiTabs-indicator': {
            display: 'none'
        },
        '& span': {
            alignItems: 'unset',
            textTransform: 'capitalize',
        },
        '& button': {
            maxWidth: '320px',
            opacity: 1,
            color: '#e0802e',
            '&.Mui-selected': {
                background: '#e0802e',
                clipPath: "polygon(90% 0, 100% 50%, 90% 100%, 0% 100%, 0 53%, 0% 0%)",
                borderRadius: '10px',
                '& p': {
                    color: '#ffffff',
                    fontWeight: '500'
                },
                '& h6': {
                    color: '#ffffff',
                    fontWeight: '600',
                    [theme.breakpoints.down('1610')]: {
                        fontSize: '18px'
                    },
                }
            }
        },
        padding: '10px',
        margin: '10px',
        borderRadius: '4px',
        width: 'max-content',
        border: '1px solid #eee',
    },
    ocrTabMenuBox: {
        textAlign: 'left' as 'left',
        minHeight: '100%',
        '&.MuiTab-root': {
            minHeight: '100%',
        }
    },
    ocrUploadCurveBox: {
        height: '30px',
        width: '31px',
        background: '#fcf7f2',
        position: 'absolute' as 'absolute',
        bottom: 0,
        left: '-31px',
    },
    curveOcrInnerBox: {
        border: '1px solid #e0802e',
        height: '29px',
        width: '31px',
        borderBottomRightRadius: '15px',
        borderTop: 'none',
        borderLeft: 'none'
    },
    curveRightOcrBox: {
        height: '32px',
        width: '30px',
        background: '#fcf7f2',
        position: 'absolute' as 'absolute',
        bottom: 0,
        right: '-30px',
    },
    curveRightOcrInnerBox: {
        width: '30px',
        height: '31px',
        borderTop: 'none',
        borderRight: 'none',
        borderBottomLeftRadius: '15px',
        border: '1px solid #e0802e'
    },
    ocrFileUploadBoxWrapper: {
        border: '1px solid #dfdfdf',
        padding: "10px 25px 25px",
        margin: '0 0%',
        borderRadius: "5px",
        '& .MuiLinearProgress-root': {
            background: '#f5e4d7',
            height: '12px',
            borderRadius: '5px'
        },
        '& .MuiLinearProgress-bar': {
            background: '#de812c',
        },
        '& h6': {
            color: '#000',
            fontWeight: '600',
        },
    },
    ocrUploadingFileInnerBox: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        '& p': {
            fontSize: '16px',
            color: '#000',
            fontWeight: '500',
            margin: '20px 0 10px 0'
        }
    },
    uploadingOcrIconBoxWrapper: {
        '& :nth-child(1)': {
            marginRight: '15px'
        }
    },
    ocrSuccessModalBox: {
        border: '1px solid #dfdfdf',
        borderRadius: '5px'
    },
    ocrSuccessModalTitle: {
        borderBottom: '1px solid #dfdfdf',
        padding: '15px 0 15px 20px',
        '& h6': {
            fontWeight: '600',
            color: '#000'
        }
    },
    ocrSuccessContent: {
        textAlign: 'center' as 'center',
        padding: '70px 0 50px 0',
        '& p': {
            fontWeight: '500',
            color: '#000',
            margin: '25px 0'
        }
    },
    ocrSuccessButtons: {
        '& button': {
            width: '250px',
            border: '1px solid #e0802e',
            margin: '0 20px',
            padding: '15px 0',
            textTransform: 'unset',
            color: '#e0802e',
            fontSize: '20px',
            lineHeight: '22px',
            '&:hover': {
                background: '#e0802e',
                color: '#ffffff'
            },
        }
    },
    ocrFailedModalBox: {
        border: '1px solid #dfdfdf',
        borderRadius: '5px'
    },
    ocrFailedModalTitle: {
        borderBottom: '1px solid #dfdfdf',
        padding: '15px 0 15px 20px',
        '& h6': {
            fontWeight: '600',
            color: '#000'
        }
    },
    ocrFailedContent: {
        textAlign: 'center' as 'center',
        padding: '70px 0 50px 0',
        '& p': {
            fontWeight: '500',
            color: '#000',
            margin: '25px 0'
        }
    },
    ocrFailedButtons: {
        '& button': {
            width: '250px',
            border: '1px solid #e0802e',
            margin: '0 20px',
            padding: '15px 0',
            textTransform: 'unset',
            color: '#e0802e',
            fontSize: '20px',
            lineHeight: '22px',
            '&:hover': {
                background: '#e0802e',
                color: '#ffffff'
            },
        }
    },
    docOpen: {
        background: 'white'
    },
    algorithmModalBox: {
        padding: '0px 20px 30px',
        background: '#ffffff',
        width: '560px',
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        borderRadius: '10px',
    },
    algorithmModalErrorContent: {
        textAlign: 'center' as 'center',
        padding: '30px 0 10px 0',
        '& p': {
            fontWeight: '500',
            color: '#000',
            margin: '25px 0'
        },
        '& img': {
            width: '200px',
            height: '200px'
        }
    },
    algorithmButtonfailedBox: {
        textAlign: 'center' as 'center',
        '& button': {
            border: '1px solid #e0802e',
            padding: '15px 35px',
            textTransform: 'unset',
            color: '#e0802e',
            fontSize: '20px',
            lineHeight: '22px',
            '&:hover': {
                background: '#e0802e',
                color: '#ffffff'
            },
            '&:first-child': {
                marginRight: '40px'
            }
        },
    },

    ocrMainBoxWrapper: {
        background: '#fff',
        padding: '0 0 0 0',
        border: '1px solid #e0802e',
        borderTopLeftRadius: '15px',
        borderTopRightRadius: '15px',
        '& .topBorderTabBox': {
            borderTop: '1px solid #e0802e'
        }
    },
    titleTabsInnerBoxWrapper: {
        '& button': {
            '& h6': {
                color: '#000',
                fontWeight: '700',
            },
            '& p': {
                color: '#000',
                textTransform: 'initial',
            },
            '&.Mui-selected': {
                overflow: 'visible',
                '& h6': {
                    color: '#e0802e',
                    fontWeight: '700'
                },
                '& p': {
                    color: '#e0802e',
                    textTransform: 'initial',
                },
            },
            '&:nth-child(2) p': {
                display: 'none'
            },
        },
        '& .MuiTabs-scroller.MuiTabs-fixed': {
            overflow: 'visible !important'
        },
        top: 0,
        width: '100%',
        overflow: 'visible',
        background: '#fcf7f2',
        padding: '10px 0 0px 30px',
        borderTopLeftRadius: '15px',
        borderTopRightRadius: '15px',
        position: 'relative' as 'relative',
        '& span.MuiTabs-indicator': {
            display: 'none'
        },
        '& span': {
            textTransform: 'capitalize',
        },
        '& div:nth-child(2)': {
            margin: '0 9px 0 16px'
        },
    },
    ocrUploadTitleTabBoxWrapper: {
        minWidth: '350px',
        textAlign: 'center' as 'center',
        padding: '10px 20px',
        '&.Mui-selected': {
            background: '#ffffff',
            border: '1px solid #e0802e',
            borderTopLeftRadius: '18px',
            borderTopRightRadius: '18px',
            borderBottom: 'none',
            position: 'relative',
            top: '2px',
        },
    },
    ocrTitleCurveBox: {
        height: '31px',
        width: '31px',
        background: '#fcf7f2',
        position: 'absolute' as 'absolute',
        bottom: 0,
        left: '-31px',
    },
    ocrTitleCurveInnerBox: {
        border: '1px solid #e0802e',
        height: '30px',
        width: '31px',
        borderBottomRightRadius: '15px',
        borderTop: 'none',
        borderLeft: 'none'
    },
    ocrTitleCurveRightBox: {
        height: '33px',
        width: '30px',
        background: '#fcf7f2',
        position: 'absolute' as 'absolute',
        bottom: 0,
        right: '-30px',
        zIndex: 99,
    },
    ocrTitleCurveRighInnerBox: {
        border: '1px solid #e0802e',
        height: '32px',
        width: '30px',
        borderBottomLeftRadius: '15px',
        borderTop: 'none',
        borderRight: 'none'
    },

})
export { OcrUpload }
export default withStyles(ocrUploadStyle)(OcrUpload)


// Customizable Area End
