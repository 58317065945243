import React from "react";
// Customizable Area Start
import "./index.css";
import { Theme,Grid,Box,styled } from "@material-ui/core";
import { withStyles, withTheme } from "@material-ui/core/styles";
import HowItWorksControllerWeb, { Props } from "./HowItWorksController.web";
import { imgHowitworks,greenCheckIcon,howItWorkUnderline,stepperVerticalLine} from "../src/assets";

const SectionHeading = styled("div")({
  fontWeight:500,
  lineHeight:1.4,
  color:"#05052B",
  fontSize:"32px",
  textAlign:"center"
})


const SectionHeadingWithUnderlineBox = styled(Box)({
  display:"flex",
  flexDirection:"column",
  justifyContent:"center",
  alignItems:"center"
})

const SectionHeadingLine = styled("img")({
marginTop:"25px",
marginBottom:"30px"
})

const RightSideBoxes = styled(Box)({
  display:"flex",
  flexDirection:"column",
  padding:"0px 10px 0px 10px",
  "@media (max-width: 1280px)":{
    alignItems:"center"
  }
})

const RightSideBoxHeading = styled("p")({
  fontWeight:500,
  lineHeight:1.4,
  color:"#05052B",
  fontSize:"32px",
  marginBottom:"20px",
  "@media (max-width: 435px)":{
    fontSize:"29px"
  }
})

const RightSideGapeBox = styled(Box)({
  display:"flex",
  gap:"30px"
})

const RightSideBox1 = styled(Box)({
  display:"flex",
  marginTop:"10px"
})

const CheckIconBox = styled(Box)({
  display:"flex",
  flexDirection:"column",
  alignItems:"center",
  marginTop:"12px"
})

const GreenCheckIconImg = styled("img")({
  height:"30px",
  width:"30px"
})


const DottedLineImg = styled("img")({
  height:"39px",
  width:"1px"
})

const RightSideBox2 = styled(Box)({
  display:"flex",
  flexDirection:"column",
  gap:"20px",
  marginTop:"10px"
})

const ChipsBox = styled(Box)({
  border:"1px solid #BEBEBE",
  borderRadius:"26px",
  padding:"12px 18px",
  height:"50px",
  width:"fit-content",
  "@media (max-width: 380px)":{
    padding:"12px 15px"
   }
})

const ChipsBoxText = styled("p")({
  fontFamily:"Poppins",
  fontWeight:500,
  fontSize:"16px",
  lineHeight:"24px",
  "@media (max-width: 435px)":{
   fontSize:"13px"
  },
  "@media (max-width: 380px)":{
    fontSize:"9px"
   }
})


const VideoTag = styled("video")({
  width:"605px",
  height:"340.32px",
  marginTop:"40px",
  "@media (max-width: 1280px)":{
    width:"100%",
    marginBottom:"10px"
   },
})


// Customizable Area End

const styles = (theme: Theme) => ({
  logo: {
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    width: "100%",
    backgroundSize: "contain",
    borderRadious: "50%",
    backgroundImage: `url(${imgHowitworks})`,
    [theme.breakpoints.up("xs")]: {
      height: "10rem"
    },
    [theme.breakpoints.up("sm")]: {
      height: "10rem"
    },
    [theme.breakpoints.up("md")]: {
      height: "14rem"
    },
    [theme.breakpoints.up("lg")]: {
      height: "25rem"
    }
  }
});

export default class ToolbarWeb extends HowItWorksControllerWeb {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  featuresCard = (classes: any) => {
    return (
      <div className="featuresCards">
        <div className={classes.logo} />
      </div>
    );
  };
  // Customizable Area End

  render() {
  // Customizable Area Start
    const { classes } = this.props;
    return (
      <div className={"featuresContainer howitworksContainer"}>
        <div className={"container"}>
          <SectionHeadingWithUnderlineBox>
          <SectionHeading>
            How does it work?
          </SectionHeading>
          <SectionHeadingLine src={howItWorkUnderline}/>
          </SectionHeadingWithUnderlineBox>
            <Grid container>
            <Grid item xs={12} lg={6}>
            {this.state.videoURL && <VideoTag  controls >
            <source src={this.state.videoURL}/>
              </VideoTag>}
            </Grid>
            <Grid item xs={12} lg={6}>
            <RightSideBoxes>
              <RightSideBoxHeading>It's a simple 5-step process</RightSideBoxHeading>
              <RightSideGapeBox>
                <RightSideBox1>
                  <CheckIconBox>
                <GreenCheckIconImg src={greenCheckIcon}/>
                <DottedLineImg src={stepperVerticalLine}/>
                <GreenCheckIconImg src={greenCheckIcon}/>
                <DottedLineImg src={stepperVerticalLine}/>
                <GreenCheckIconImg src={greenCheckIcon}/>
                <DottedLineImg src={stepperVerticalLine}/>
                <GreenCheckIconImg src={greenCheckIcon}/>
                <DottedLineImg src={stepperVerticalLine}/>
                <GreenCheckIconImg src={greenCheckIcon}/>
                </CheckIconBox>
                </RightSideBox1>
                <RightSideBox2>
                  <ChipsBox>
                    <ChipsBoxText>1. Upload Quote</ChipsBoxText>
                  </ChipsBox>
                  <ChipsBox>
                    <ChipsBoxText>2. Review & Edit Quote Details</ChipsBoxText>
                  </ChipsBox>
                  <ChipsBox>
                    <ChipsBoxText>3. Upload Invoice</ChipsBoxText>
                  </ChipsBox>
                  <ChipsBox>
                    <ChipsBoxText>4. Review & Edit Invoice Details</ChipsBoxText>
                  </ChipsBox>
                  <ChipsBox>
                    <ChipsBoxText>5. View Report Results Instantly</ChipsBoxText>
                  </ChipsBox>
                </RightSideBox2>
                </RightSideGapeBox>
            </RightSideBoxes>
            </Grid>
            </Grid>
        </div>
      </div>
    );
  }
  // Customizable Area End
}
  // Customizable Area Start
 withTheme(withStyles(styles)(ToolbarWeb));
  // Customizable Area End