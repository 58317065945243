// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { addDays } from 'date-fns';
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  // Customizable Area Start
  navigation: any;
  history: any;
  handleSaveData: (data: []) => void;
  // Customizable Area End
}
export interface TabPanelProps {
  // Customizable Area Start
  children?: React.ReactNode;
  index: number | string;
  value: number | string;
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  value: number;
  documentsData: any[];
  filterAllDocModal: boolean;
  vendorallDocValue: string;
  projectallDocValue: string;
  hasAllDocDateRangeModal: boolean;
  deleteAllDocModal: boolean;
  deleteModalId: number;
  vendorOptionValue: [];
  ProjectOptionValue: [];
  dateAllDocRangeData: [{
    startDate: Date;
    endDate: Date;
    key: string
  }];
  token: string;
  allTypeDocumentError: string;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  // Customizable Area End
}

export default class AllDocumentsController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getAllTypeDocumentAPiId: string = "";
  getAllDocumentsVendorApiId: string = "";
  getAllDocumentsProjectApiId: string = "";
  getSearchAllDocProjectApiId: string = "";
  deleteAllDocumentApiId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);

    this.state = {
      // Customizable Area Start
      value: 0,
      documentsData: [],
      filterAllDocModal: false,
      vendorallDocValue: "",
      projectallDocValue: "",
      hasAllDocDateRangeModal: false,
      deleteAllDocModal: false,
      deleteModalId: 0,
      dateAllDocRangeData: [{
        startDate: new Date(),
        endDate: addDays(new Date(), 0),
        key: 'selection'
      }],
      token: '',
      ProjectOptionValue: [],
      vendorOptionValue: [],
      allTypeDocumentError: ""
      // Customizable Area End
    };
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
    ];
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId === this.getAllTypeDocumentAPiId) {
        this.allDocumentsResponseHandler(responseJson)
      }

      if (apiRequestCallId === this.getAllDocumentsProjectApiId) {
        this.projectResponseHandler(responseJson)
      }
      if (apiRequestCallId === this.getAllDocumentsVendorApiId) {
        this.vendorResponseHandler(responseJson)
      }

      if (apiRequestCallId === this.getSearchAllDocProjectApiId) {
        this.allDocumentFilterResponsehandler(responseJson)
      }

      this.allDocDeleteResponseHandler(apiRequestCallId, responseJson)
    }
  }

  // Customizable Area Start
  async componentDidMount() {
    const token = await document.cookie.split("; ").find((row) => row.startsWith("Token="))?.split("=")[1];
    token && this.setState({ token: token });
    this.getAllTypeDocuments()
    this.getAllDocumentsVendorsValue()
    this.getAllDocumentsProjectsValue()
  }

  allDocumentsResponseHandler = (responseJson: any) => {
    if (responseJson.error) {
      this.setState({ allTypeDocumentError: responseJson.error })
    } else {
      this.setState({ documentsData: responseJson.library_document_list })
    }
  }

  projectResponseHandler = (responseJson: any) => {
    if (responseJson && responseJson.data && !responseJson.errors) {
      this.setState({ ProjectOptionValue: responseJson.data })
    }
  }
  vendorResponseHandler = (responseJson: any) => {
    if (responseJson && responseJson.data && !responseJson.errors) {
      this.setState({ vendorOptionValue: responseJson.data })
    }
  }

  allDocumentFilterResponsehandler = (responseJson: any) => {
    if (responseJson.message == "Success" && responseJson.data) {
      this.setState({ documentsData: responseJson.data })
    } else if (responseJson.message == "No records found") {
      this.setState({ allTypeDocumentError: responseJson.message, documentsData: [] })
    }
  }

  allDocDeleteResponseHandler = (apiRequestCallId: any, responseJson: any) => {
    if (apiRequestCallId === this.deleteAllDocumentApiId) {
      const updatedData = this.state.documentsData.filter((item: any) => (item.id !== this.state.deleteModalId) && (item.invoice_id !== this.state.deleteModalId))
      this.setState({ documentsData: updatedData, deleteAllDocModal: false, value: 0 })
    }
  }

  handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    this.setState({ value: newValue })
  }

  

  handleOpenFilterModal = () => {
    this.setState({ filterAllDocModal: !this.state.filterAllDocModal })
  }
  handleCloseAllDocFilterModal = () => {
    this.setState({ filterAllDocModal: false })
  }

  selectAllDocVendorValue = (event: React.ChangeEvent<{ value?: string | unknown }>) => {
    const selectedVendor = event.target.value as string;
    this.setState({ vendorallDocValue: selectedVendor });
  }

  selectProjectValue = (event: React.ChangeEvent<{ value?: string | unknown }>) => {
    const selectedProject = event.target.value as string;
    this.setState({ projectallDocValue: selectedProject });
  }

  handleAllDocSelectDateRangeValue = (data: any) => {
    this.setState({ dateAllDocRangeData: data })
  }

  handleAllDocDateRangeOpenModal = () => {
    this.setState({ hasAllDocDateRangeModal: !this.state.hasAllDocDateRangeModal })
  }

  handleOpenAllDocDeleteModal = (itemId: number) => {
    this.setState({ deleteAllDocModal: !this.state.deleteAllDocModal, deleteModalId: itemId })
  }

  handleCloseAllDocDeleteModal = () => {
    this.setState({ deleteAllDocModal: false })
  }

  getAllTypeDocuments = () => {
    const token = this.state.token;

    const header = {
      "Content-Type": configJSON.productApiContentType,
      "token": token
    };

    const requestAllDocumentsMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getAllTypeDocumentAPiId = requestAllDocumentsMessage.messageId;

    requestAllDocumentsMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.allDocumentsApiEndPoint
    );

    requestAllDocumentsMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestAllDocumentsMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );

    runEngine.sendMessage(requestAllDocumentsMessage.id, requestAllDocumentsMessage);

    return true;
  }

  getAllDocumentsProjectsValue = () => {
    const token = this.state.token;

    const header = {
      "Content-Type": configJSON.productApiContentType,
      "token": token
    };

    const allDocProjectRequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getAllDocumentsProjectApiId = allDocProjectRequestMessage.messageId;

    allDocProjectRequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.projectApiEndPoint
    );

    allDocProjectRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    allDocProjectRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );

    runEngine.sendMessage(allDocProjectRequestMessage.id, allDocProjectRequestMessage);

    return true;
  }

  getAllDocumentsVendorsValue = () => {
    const token = this.state.token;

    const header = {
      "Content-Type": configJSON.productApiContentType,
      "token": token
    };

    const allDocVendorRequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getAllDocumentsVendorApiId = allDocVendorRequestMessage.messageId;

    allDocVendorRequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.vendorApiEndPoint
    );

    allDocVendorRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    allDocVendorRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );

    runEngine.sendMessage(allDocVendorRequestMessage.id, allDocVendorRequestMessage);

    return true;
  }

  handleAllDocProjectsSearch = () => {
    this.setState({ filterAllDocModal: false, vendorallDocValue: "", projectallDocValue: "" })

    const token = this.state.token;

    const allDocProjectListData = {
      vendor: this.state.vendorallDocValue,
      project: this.state.projectallDocValue,
      date: this.state.dateAllDocRangeData
    }

    const header = {
      "Content-Type": configJSON.productApiContentType,
      "token": token
    };

    const httpBody = {
      data: allDocProjectListData,
    };

    const getSearchProjectsrequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getSearchAllDocProjectApiId = getSearchProjectsrequestMessage.messageId;

    getSearchProjectsrequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.allDocumentSearchApiEndPoint
    );

    getSearchProjectsrequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    getSearchProjectsrequestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    getSearchProjectsrequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );

    runEngine.sendMessage(getSearchProjectsrequestMessage.id, getSearchProjectsrequestMessage);

    return true;
  }

  handleDeleteAllDocument = () => {
    const token = this.state.token;

    const header = {
      "Content-Type": configJSON.productApiContentType,
      "token": token
    };

    const deleteDocumentrequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.deleteAllDocumentApiId = deleteDocumentrequestMessage.messageId;

    deleteDocumentrequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.deleteApiEndPoint}/${this.state.deleteModalId}`
    );

    deleteDocumentrequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteApiMethod
    );

    deleteDocumentrequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    runEngine.sendMessage(deleteDocumentrequestMessage.id, deleteDocumentrequestMessage);

    return true;
  }
  // Customizable Area End
}
