
export const getTimeAgo = (timestamp: any): string => {
    const currentTime = new Date().getTime();
    const timeDifference = currentTime - timestamp;
    
    const minute = 60 * 1000;
    const hour = 60 * minute;
    const day = 24 * hour;
    const month = 30 * day;
    const year = 365 * day;
    
    if (timeDifference < minute) {
        return `0 mins ago`;
    } else if (timeDifference < hour) {
        const minutes = Math.floor(timeDifference / minute);
        return `${minutes} min${minutes === 1 ? '' : 's'} ago`;
    } else if (timeDifference < day) {
        const hours = Math.floor(timeDifference / hour);
        return `${hours} hour${hours === 1 ? '' : 's'} ago`;
    } else if (timeDifference < month) {
        const days = Math.floor(timeDifference / day);
        return `${days} day${days === 1 ? '' : 's'} ago`;
    } else if (timeDifference < year) {
        const months = Math.floor(timeDifference / month);
        return `${months} month${months === 1 ? '' : 's'} ago`;
    } else {
        const years = Math.floor(timeDifference / year);
        return `${years} year${years === 1 ? '' : 's'} ago`;
    }
}
    