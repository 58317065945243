// Customizable Area Start
import React from 'react'
import {
    TextField, Button, Grid, InputAdornment, Box, IconButton,
    Typography, FormControlLabel,
    Checkbox, DialogTitle,
    Dialog,
    DialogContent,
} from "@material-ui/core";
import { styled } from '@material-ui/styles';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import VisibilityIcon from '@material-ui/icons/Visibility';
import './index.css';
import LoginController, { Props } from './LoginController.web';
import {
    home_background, MyInvoiceCheckIcon
} from "../../landingpage/src/assets";
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
// Customizable Area End

// Customizable Area Start
export interface MyFormValues {
    email: string;
    password: string;
}

const ResetButtonStyle = styled(Button)({
    background: "#292946",
    width: '60%',
    height: "45px",
    textTransform: 'capitalize',
    padding: '16px',
    fontWeight: 600,
    margin: 'auto',
    marginTop: '8%',
    display: 'flex',
    borderRadius: '8px',
    boxShadow: 'none',
    '&:hover': {
        background: '#292946',
    }

});

const ResetFormTextFeildStyle = styled(TextField)({
    '& input': {
        paddingTop: '6px',
        paddingBottom: '6px',
        height: '35px',
    },
    '& .MuiFormLabel-root[data-shrink="false"]': {
        transform: 'translate(14px, 16px) scale(1)',
    },
    '& .MuiInputLabel-outLined.MuiInputLabel-shrink': {
        transform: "translate(12px, -6px) scale(0.70)"
    }

});

export const PasswordChangedDialog = (props: any) => {
    const { handleClose, handleOpen, goToDashboard } = props

    return (
        <>
            <Dialog maxWidth="xs" style={{ padding: '10px' }} data-testid="dialogOpenId" onClose={handleClose} open={handleOpen}>
                <DialogTitle style={{ color: 'black', width: '100%', textAlign: 'center' }}>
                    Password Changed Successfully
                </DialogTitle>
                <DialogContent style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '14px', flexDirection: 'column', paddingBottom: '5%' }}>
                    <CheckCircleIcon style={{ color: '#008000a1', fontSize: 40 }} />
                    <ResetButtonStyle
                        type="button"
                        variant="contained"
                        data-test-id="btnEmailRegister"
                        color="secondary"
                        onClick={goToDashboard}
                    >
                        Go to Dashboard
                    </ResetButtonStyle>
                </DialogContent>
            </Dialog>
        </>
    )
}

export const ResetPassword = ({ password, showPassword, handlePasswordChange, handleClickShowPassword, isPasswordValid, handleResetPassword }: any) => {
    return (
        <>
            <h3 style={webStyle.resetHeadingStyle}>Reset Password</h3>
            
                <Box style={{ fontWeight: 500, marginTop: "5%" }}>
                    <label style={webStyle.labelTxtResetStyle}>Password</label>
                    <ResetFormTextFeildStyle
                        style={{ marginTop: '2%', width:"100%" }}
                        name="reset_password"
                        id="password"
                        label="Enter your new password"
                        data-test-id="txtInputPassword"
                        value={password}
                        variant="outlined"
                        type={showPassword ? 'text' : 'password'}
                        onChange={handlePasswordChange}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPassword}
                                        edge="end"
                                        
                                    >
                                        {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                </Box>
                {password?.length > 0 &&
                    <Box sx={webStyle.resetPasswordBoxStyle}>
                        <FormControlLabel
                            control={
                                <Checkbox checked={isPasswordValid?.minimumEightCharacters} icon={<RadioButtonUncheckedIcon />} checkedIcon={<CheckCircleIcon style={{ color: '#008000a1' }} />} />
                            }
                            label={<Typography style={{ color: isPasswordValid?.minimumEightCharacters ? '#008000a1' : 'gray' }}>Minimum 8 characters</Typography>}
                        />
                        <FormControlLabel
                            control={
                                <Checkbox checked={isPasswordValid?.oneUpperCase} icon={<RadioButtonUncheckedIcon />} checkedIcon={<CheckCircleIcon style={{ color: '#008000a1' }} />} />
                            }
                            label={<Typography style={{ color: isPasswordValid?.oneUpperCase ? '#008000a1' : 'gray' }}>1 Uppercase letter</Typography>}
                        />
                        <FormControlLabel
                            control={
                                <Checkbox checked={isPasswordValid?.oneNumber} icon={<RadioButtonUncheckedIcon />} checkedIcon={<CheckCircleIcon style={{ color: '#008000a1' }} />} />
                            }
                            label={<Typography style={{ color: isPasswordValid?.oneNumber ? '#008000a1' : 'gray' }}>1 Number</Typography>}
                        />
                        <FormControlLabel
                            control={
                                <Checkbox checked={isPasswordValid?.oneSpecialCharacter} icon={<RadioButtonUncheckedIcon />} checkedIcon={<CheckCircleIcon style={{ color: '#008000a1' }} />} />
                            }
                            label={<Typography style={{ color: isPasswordValid?.oneSpecialCharacter ? '#008000a1' : 'gray' }}>1 Special character</Typography>}
                        />
                    </Box>
                }
                <ResetButtonStyle
                    type="button"
                    variant="contained"
                    data-test-id="btnEmailRegister"
                    color="secondary"
                    onClick={handleResetPassword}
                    disabled={!isPasswordValid?.minimumEightCharacters || !isPasswordValid?.oneUpperCase || !isPasswordValid?.oneNumber || !isPasswordValid?.oneSpecialCharacter}
                >
                    Save password
                </ResetButtonStyle>
            
        </>
    )
}

// Customizable Area End
class ResetForgotPassword extends LoginController {

    constructor(props: Props) {
        super(props);
    }

    render() {
        // Customizable Area 
        return (

            <div style={webStyle.imageResetContainerStyle}>
                <PasswordChangedDialog handleClose={this.handleModalClose} handleOpen={this.state.isPasswordChanged} goToDashboard={this.gotoDashboard} />
                <Box style={webStyle.resetBoxStyle}>
                    <Grid container spacing={3}>
                        <Grid item style={{ padding: 0 }} sm={6} lg={8}>
                            <i style={webStyle.resetLogoWrapStyle} onClick={this.gotoHome}>
                                <img
                                    style={webStyle.iconStyle}
                                    src={MyInvoiceCheckIcon}
                                    alt=""
                                />
                            </i>
                            <Box style={webStyle.leftMainBoxStyle}>
                                <Typography style={webStyle.leftMainText}>Navigating Construction Costs Hassel-Free</Typography>
                                <Typography style={webStyle.leftSecondText}>My Invoice Check simplifies the comparison between project quotes and invoices, ensuring clarity and confidence in every transaction</Typography>
                            </Box>
                        </Grid>

                        <Grid item style={webStyle.secondGridStyle} sm={6} lg={4}>
                            <Box style={webStyle.styleResetForm}>
                                <ResetPassword
                                    handlePasswordChange={this.handleResetPasswordChange}
                                    password={this.state.resetPasswordValue}
                                    handleClickShowPassword={this.handleClickShowPassword}
                                    showPassword={this.state.showPassword}
                                    isPasswordValid={this.state.isPasswordValid}
                                    handleResetPassword={this.resetPassword}
                                />
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </div >
        )
        // Customizable Area End
    }
}
{/* 
    // @ts-ignore */}
export { ResetForgotPassword }
// Customizable Area Start
const webStyle = {
    imageResetContainerStyle: {
        backgroundImage: `url(${home_background})`,
        backgroundSize: 'cover', /* Cover the entire div with the background image */
        backgroundPosition: 'center', /* Center the background image */
        /* Add other styles as needed */
        height: '100vh',
        padding: '8%',
        overflowY: 'auto' as 'auto'
    },
    iconStyle: {
        width: '30%'
    },
    leftMainText: {
        fontSize: '20px', fontWeight: 550
    },
    leftSecondText: {
        width: '57%', marginTop: '2%'
    },
    secondGridStyle: {
        padding: 0, paddingBottom: '4%'
    },
    resetPasswordBoxStyle: {
        display: 'flex',
        flexDirection: 'column'
    },
    resetBoxStyle: {
        width: '100%',
        borderRadius: "22px",
        flexShrink: 'inherit' as 'inherit',
    },
    leftMainBoxStyle: {
        marginTop: '7%', color: 'white'
    },
    styleResetForm: {
        background: '#fff',
        padding: '35px',
        border: '1px solid rgb(243 241 241)',
        borderRadius: "12px",
        boxShadow: '23px 9px 36px -10px rgb(124 120 120 / 27%)',
    },
    txtResetStyle: {
        marginBottom: 20,
        fontWeight: 500,
    },
    resetHeadingStyle: {
        textAlign: 'center' as 'center',
        fontWeight: 550,
        fontSize: '20px',
        color: 'black'
    },
    labelTxtResetStyle: {
        marginBottom: 6,
        fontWeight: 500,
        color: 'black'
    },
    resetLogoWrapStyle: {
        cursor: 'pointer',
        display: 'block'
    }

}
{/* 
        // @ts-ignore */}
// export default withRouter(EmailAccountRegistrationWeb)
// Customizable Area End

