import React from 'react'
// Customizable Area Start
import { Message } from "../../../framework/src/Message";
import MessageEnum, { getName, } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from '../../../framework/src/IBlock';
import {
    bitmap,
    bitmaptwo,
    bitmapthree,
    bitmapfour,
    bitmapfive,
    bitmapsix,
    bitmapseven,
    bitmapeight,
    bitmapnine,
    bitmapten
} from "../../landingpage/src/assets";
export const configJSON = require("./config");
// Customizable Area End

export interface Props {
    navigation: any;
    id: string;
    history: any;
    // Customizable Area Start
    // Customizable Area End
}
interface S {
    // Customizable Area Start
    email: any;
    name: string;
    password: string;
    flag: boolean;
    apiError: boolean;
    serverSideError: boolean,
    isSuccses: boolean;
    showPassword: boolean;
    isPasswordValid: any;
    steps: any;
    activeStep: any;
    profileImageUrl: any;
    avatars: any;
    selectedAvatarIndex: any;
    image: any;
    editor: any;
    accountId: any;
    personalDetails: any;
    options: any;
    token: any;
    isEmailEntered: any;
    contactErrMsg: any;
    emailErrMsg: any;
    emailAlreadyRegistered:any,
    // Customizable Area End
}
interface SS {
    // Customizable Area Start
    id: any;
    // Customizable Area End
}
export default class SignupController extends BlockComponent<Props, S, SS>{
    // Customizable Area Start
    resgisterNewUserApi: string = "";
    profileImageId: string = "";
    getLocationsId: string = "";
    updateUserDetailsId: string = "";
    userEmailCheckApi:string ="";
    // Customizable Area End
    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        // Customizable Area Start
        this.state = {
            email: localStorage.getItem('email') ? localStorage.getItem('email'):"",
            name: "",
            password: "",
            flag: false,
            apiError: false,
            serverSideError: false,
            isSuccses: false,
            showPassword: false,
            isPasswordValid: {
                minimumEightCharacters: false,
                oneUpperCase: false,
                oneNumber: false,
                oneSpecialCharacter: false
            },
            steps: ["", "", ""],
            activeStep: 0,
            profileImageUrl: '',
            avatars: [{ image: bitmap }, { image: bitmaptwo }, { image: bitmapthree }, { image: bitmapfour }, { image: bitmapfive }, { image: bitmapsix }, { image: bitmapseven }, { image: bitmapeight }, { image: bitmapnine }, { image: bitmapten }],
            selectedAvatarIndex: "",
            image: null,
            editor: null,
            accountId: '',
            personalDetails: {
                phone_number: '',
                company_name: '',
                location: '',
                job_title: '',
            },
            emailAlreadyRegistered:"",
            options: [],
            token: '',
            isEmailEntered: false,
            contactErrMsg: '',
            emailErrMsg: ''
        }
        this.subScribedMessages = [
            getName(MessageEnum.CountryCodeMessage),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.ReciveUserCredentials),
        ];
        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages)
    }

    componentDidUpdate(prevProps: any, prevState: any): void {
        if (this.state.activeStep === 1 && this.state.options.length <= 0) {
            this.getLocations()
        }
    }

    getLocations = () => {
        const header = {
            "Content-Type": configJSON.fileUploadApiContentType,
            "token": this.state.token
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.getLocationsId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.updateEmilEndpoint}/${this.state.accountId}/all_location`
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.fileGetApiMethod
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);

        return true;
    }

    handleEmailEntered = (EnterEmail:any) => {


        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.userEmailCheckApi = requestMessage.messageId
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.loginUserApiEndPoint}/check_email?email=${EnterEmail}`
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);

        return true;
    }



    handleRegisterUserApi = ({ apiRequestCallId, responseJson }: any) => {
        if (apiRequestCallId === this.resgisterNewUserApi) {
            if (responseJson.errors) {
                this.setState({ apiError: true, emailErrMsg: responseJson.errors[0].account })
            }
            else {
                this.setState({ isSuccses: true, flag: true, accountId: responseJson.data.id, token: responseJson.meta.token });
                localStorage.removeItem("email")
            }
        }
    }

    handleProfileImageApi = (apiRequestCallId: any) => {
        if (apiRequestCallId === this.profileImageId) {
            this.setState({ activeStep: this.state.activeStep + 1 })
        }
    }


    async receive(from: string, message: Message) {
        // Customizable Area Start

        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );


            this.handleRegisterUserApi({ apiRequestCallId, responseJson })

            this.handleProfileImageApi(apiRequestCallId)

            if (apiRequestCallId === this.getLocationsId) {
                this.setState({ options: responseJson.data.cities_name })
            }
            if(apiRequestCallId === this.userEmailCheckApi){
                if(!responseJson?.isRegistered){
                        this.setState({ isEmailEntered: true,emailAlreadyRegistered:'' })
                }
                else{
                   this.setState({
                    emailAlreadyRegistered:responseJson?.message
                   })
                }
            }
            if (apiRequestCallId === this.updateUserDetailsId) {
                this.setState({ activeStep: this.state.activeStep + 1 })
            }

        }
        // Customizable Area End
    }

    // Customizable Area Start
    gotoHome = () => {
        if (this.props.navigation.navigate) {
            this.props.navigation.navigate("Home");
        }
    }

    goToDashboard = () => {
        if (this.props.navigation.navigate) {
            document.cookie = `Token=${this.state.token}`
            this.props.navigation.navigate('DashboardWeb');
        }
    }

    handleAvatarClick = (index: any) => {
        this.setState({ selectedAvatarIndex: index })
    }

    handleSelectAvatar = () => {
        this.setState({ selectedAvatarIndex: 0, image: null })
    }

    handleEditor = (ref: any) => {
        this.setState({ editor: ref })
    }

    handleSkip = () => {
        this.setState({ activeStep: this.state.activeStep + 1 })
    }

    updateImageApi = (croppedImageFile: any) => {
        const header = {
            "token": this.state.token
        };

        const formData: any = new FormData();

        formData.append('profile_image', croppedImageFile);

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.profileImageId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.updateEmilEndpoint}/${this.state.accountId}/update_image`
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            formData
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.exampleAPiMethod
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);

        return true;
    }

    handleApplyImage = () => {
        if (this.state.editor && this.state.image) {
            const canvasDataUrl = this.state.editor.getImage().toDataURL();
            fetch(canvasDataUrl)
                ?.then((res) => res.blob())
                .then((blob) => {
                    const croppedImageFile = new File([blob], 'cropped-image.png', { type: 'image/png' });
                    this.updateImageApi(croppedImageFile)
                })
        }
    };

    handleApplyAvatar = () => {
        const selectedAvatar = this.state.avatars[this.state.selectedAvatarIndex]?.image;
        fetch(selectedAvatar)
            ?.then((res) => res.blob())
            .then((blob) => {
                const croppedImageFile = new File([blob], 'cropped-image.png', { type: 'image/png' });
                this.updateImageApi(croppedImageFile)
            })
    }

    handleDrop = (acceptedFiles: any) => {
        const file = acceptedFiles[0];
        this.setState({ image: file, selectedAvatarIndex: '' })
    };

    handlePasswordChange = (event: any) => {
        const { value } = event.target;
        const trimmedValue = value.trim();
        const newPasswordRegex = /^[!@£$%&a-zA-Z0-9]*$/;
        const passwordSpecialCharRegex = /[!@£$%&]/;
        const passwordUppercaseRegex = /[A-Z]/;
        const passowrdNumberRegex = /\d/;
        const passwordMinLengthRegex = /^(?!.*\s).{8,}$/;
        if (!newPasswordRegex.test(value)) {
            if (value !== '') {
                return;
            }
        }
        const isPasswordUppercaseValid = passwordUppercaseRegex.test(trimmedValue);
        const isMinPasswordLengthValid = passwordMinLengthRegex.test(trimmedValue);
        const isPasswordSpecialCharValid = passwordSpecialCharRegex.test(trimmedValue);
        const isPasswordNumberValid = passowrdNumberRegex.test(trimmedValue);
        this.setState(prevData => ({
            ...prevData,
            isPasswordValid: {
                oneNumber: isPasswordNumberValid,
                minimumEightCharacters: isMinPasswordLengthValid,
                oneUpperCase: isPasswordUppercaseValid,
                oneSpecialCharacter: isPasswordSpecialCharValid
            }, password: trimmedValue
        }));
    };

    handleValueChange = (event: any, val: any) => {
        const { name, value } = event.target;
        let sanitizedValue = value;
        if (name === "company_name" || name === "job_title") {
            const specialCharRegex = /[!@#$%^&*(),.?":{}|<>]/g;
            sanitizedValue = value.replace(specialCharRegex, '');
        }
        this.setState(prevData => ({ ...prevData, personalDetails: { ...prevData.personalDetails, [name]: sanitizedValue, location: val ? val : this.state.personalDetails.location }, passwordErrMsg: "", contactErrMsg: '' }));
    };

    handleClickShowPassword = () => {
        this.setState({ showPassword: !this.state.showPassword })
    };

    handleUserDetails = () => {
        const phoneNumberRegex = /^\d{10}$/;
        if (phoneNumberRegex.test(this.state.personalDetails.phone_number)) {
            const header = {
                "Content-Type": configJSON.fileUploadApiContentType,
                "token": this.state.token
            };
            const quoteGraphRequestMessage = new Message(
                getName(MessageEnum.RestAPIRequestMessage)
            );
            this.updateUserDetailsId = quoteGraphRequestMessage.messageId;
            quoteGraphRequestMessage.addData(
                getName(MessageEnum.RestAPIResponceEndPointMessage),
                `${configJSON.updateEmilEndpoint}/${this.state.accountId}?name=${this.state.name}&company_name=${this.state.personalDetails.company_name}&location=${this.state.personalDetails.location}&job_title=${this.state.personalDetails.job_title}&phone_number=${this.state.personalDetails.phone_number}`
            );
            quoteGraphRequestMessage.addData(
                getName(MessageEnum.RestAPIRequestHeaderMessage),
                JSON.stringify(header)
            );
            quoteGraphRequestMessage.addData(
                getName(MessageEnum.RestAPIRequestMethodMessage),
                configJSON.updateAccountApiMethodType
            );
            runEngine.sendMessage(quoteGraphRequestMessage.id, quoteGraphRequestMessage);

            return true;
        } else {
            this.setState({ contactErrMsg: 'Invalid phone number. Please enter a 10-digit number.' })
        }
    }

    onSubmit(): boolean {

        this.setState({ flag: false })
        const data = {
            type: "email_account",
            attributes: {
                name: this.state.name,
                email: this.state.email,
                password: this.state.password,
                activated: "true"

            }
        }
        const header = {
            "Content-Type": configJSON.exampleApiContentType,
        };

        const httpBody = {
            data: data,
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.resgisterNewUserApi = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.updateEmilEndpoint
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(httpBody)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.exampleAPiMethod
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);

        return true;
    }
    // Customizable Area End
}
