import React from 'react'
// Customizable Area Start
import { connect } from "react-redux";
import { styled } from '@material-ui/styles'
import { Grid, TextField, Button } from "@material-ui/core";
import { View } from 'react-native';
import ScrollableTabsWeb from './ScrollableTabsWeb.web';
import TelegramIcon from '@material-ui/icons/Telegram';
import { deleteImg, downloadImg, fullScreenImg } from "./assets";
import TopNavigationWeb from './TopNavigationWeb.web';

// Customizable Area End
import NotificationWebController, { Props } from "./NotificationWebController"
import HeaderWeb from './HeaderWeb.web';
// Customizable Area Start
const StyledTextFieldMessage = styled(TextField)({
    marginBottom: '10px',
    '& .MuiInputBase-input': {
        height: 'auto !important',
        padding: '14px',
        lineHeight: '10px',
        paddingLeft: '20px',
    },
    '& .MuiOutlinedInput-root': {
        borderRadius: '8px',
    },
    '& .MuiInputLabel-outlined': {
        transform: 'translate(14px, 16px) scale(1)',
        paddingLeft: '35px'
    }
})
// Customizable Area End
// Customizable Area Start
class NotificationWeb extends NotificationWebController {
    constructor(props: Props) {
        super(props)
        // Customizable Area Start
        // Customizable Area End
    }
    render() {
        return (
            <>
            
                {/* 
        // @ts-ignore */}
                <HeaderWeb/>
                {/* 
        // @ts-ignore */}

                <View className="content-scroll" style={webStyle.containerStyle}>
                    {/* 
        // @ts-ignore */}
                    <View style={webStyle.contentNotifiStyle}>
                        <h2 style={webStyle.h2Head}>Notifications</h2>
                        <Grid style={webStyle.mainGrid} container spacing={3}>
                            <Grid item xs={12} sm={8}>
                                {/* 
        // @ts-ignore */}
                                <View style={webStyle.leftBoxStyle}>
                                    <h3 style={webStyle.h3Head}>Category</h3>
                                    {/* 
        // @ts-ignore */}
                                    <ScrollableTabsWeb />
                                </View>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <view style={webStyle.rightBoxStyle}>
                                    <h4 style={webStyle.h4HeadRight}>Today</h4>
                                    {/* 
        // @ts-ignore */}
                                    <view style={webStyle.contentRight}>
                                        <view style={webStyle.showData}>
{/* 
        // @ts-ignore */}
                                            {this.props.notificationStates.imageChangeFlag ? "" : <>
                                                <p style={webStyle.contentText}>Your not selecting any kindof reply to notification. <a style={webStyle.clickHere} href="#">click here</a> to see.</p>
                                                <view style={webStyle.smallText}>2min ago.</view> </>}
{/* 
        // @ts-ignore */}
                                            <view style={webStyle.noData}>
                                                {/* 
        // @ts-ignore */}
                                                <img style={this.props.notificationStates.imageChangeFlag ? webStyle.smallImg : webStyle.rightImg} src={this.state.docImg} />
                                                <br></br>
                                                {/* 
        // @ts-ignore */}
                                                {this.props.notificationStates.imageChangeFlag ? <p>Your not selecting any kindof reply to notification.</p> : ""}
                                            </view>
                                            {/* 
        // @ts-ignore */}
                                            {this.props.notificationStates.imageChangeFlag ? "" :
                                                <view style={webStyle.daysNotify}>
                                                    <view style={webStyle.dFlex}>
                                                        <img src={fullScreenImg} style={webStyle.weakIcon} />
                                                        <img src={downloadImg} style={webStyle.weakIcon} />
                                                    </view>
                                                    <img src={deleteImg} style={webStyle.weakIcon} />
                                                </view>}
                                                {/* 
        // @ts-ignore */}
                                            {this.props.notificationStates.imageChangeFlag ? "" : <>
{/* 
        // @ts-ignore */}
                                                <view style={webStyle.formBox}>
                                                    {/* 
        // @ts-ignore */}
                                                    <StyledTextFieldMessage id="outlined-basic" placeholder="Type here for reply..." variant="outlined" style={webStyle.searchField} />
                                                     {/* 
        // @ts-ignore */}
                                                    <Button variant="contained" color="secondary" style={webStyle.submitBtn}>
                                                        <TelegramIcon />
                                                    </Button>
                                                </view>
                                                </>}
                                        </view>
                                    </view>
                                </view>
                            </Grid>
                        </Grid>
                    </View>
                </View >
            </>
        )
    }
}
function mapStateToProps(state: any) {
    return {
        notificationStates: state.notificationReducer,
    }
}
export default connect(mapStateToProps, null)(NotificationWeb)
// Customizable Area End

// Customizable Area Start
const webStyle = {
    containerStyle: {
        padding: '30px',
        height: 'calc(100% - 82px)',
    },
    contentNotifiStyle: {
        border: "1px solid rgb(243 241 241)",
        borderRadius: "12px",
        padding: '15px',
        height: '100%',
    },
    leftBoxStyle: {
        background: "#fff",
        border: "1px solid rgb(243 241 241)",
        borderRadius: "8px",
        position: 'relative',
        // height: 'calc(100% - 30px)',
        padding: '15px'
    },
    rightBoxStyle: {
        background: "#fff",
        border: "1px solid rgb(243 241 241)",
        borderRadius: "8px",
        height: 'calc(100% - 30px)',
        padding: '0 15px',
        display: 'block',
        overflow: 'auto',
    },

    h2Head: {
        margin: '0',
        padding: "15px",
    },
    h3Head: {
        margin: '0',
        paddingBottom: "15px",
    },
    daysNotify: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    h4HeadRight: {
        padding: '15px',
        fontSize: '20px',
        margin: '0px',
        borderBottom: "1px solid rgb(243 241 241)",
    },
    formBox: {
        position: 'relative'
    },
    searchField: {
        width: '100%',
    },
    dFlex: {
        display: 'flex',
        alignItems: 'start'
    },
    contentText: {
        margin: '0',
        fontSize: '14px',
    },
    clickHere: {
        color: '#df802d',
    },
    smallText: {
        color: '#939191',
    },
    contentRight: {
        display: 'flex',
        alignItems: 'center',
        paddingTop: "9px",
        flexDirection: "column",
    },
    noData: {
        width: '100%',
        height: '499px',
        display: 'flex',
        textAlign: "center",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
    },
    showData: {
        width: '100%'
    },
    weakIcon: {
        margin: '10px 20px 20px 0px',
        height: '24px',
    },
    submitBtn: {
        position: 'absolute',
        right: '0',
        background: '#df802d',
        height: '45px',
        boxShadow: 'none',
        borderRadius: '0px 8px 8px 0',
    },
    rightImg: {
        width: '100%',
        height: '477px',
        marginTop: '15px',
        oajectFit: 'cover',
        border: "1px solid rgb(243 241 241)",
    },
    smallImg: {
        width: "256px",
        height: "278px",
    },
    mainGrid: {
        overflow: "auto",
        height: "100vh",
    },
};
// Customizable Area End