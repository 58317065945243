export const imgAdd = require("../assets/ic_add.png");
export const imgCancel = require("../assets/ic_cancel.png");
export const imgGstin = require("../assets/ic_gstin.png");
export const imgMapIcon = require("../assets/ic_map_icon.png");
export const imgSuccessModal = require("../assets/success_modal_image.png");
export const backIcon = require("../assets/backIcon.png");
export const imgic_password_invisible = require("../../global_assets/ic_password_invisible.png");
export const imgic_password_visible = require("../../global_assets/ic_password_visible.png");
export const closeIcon = require("../assets/close.svg");
export const reportIcon1 = require("../assets/error1.svg");
export const cancelupload = require("../assets/cancel-upload-icon.svg");
export const pauseUpload = require("../assets/pause-upload-icon.svg");
export const successUpload = require("../assets/upload-success-icon.svg");
export const failedUpload = require("../assets/upload-failed-icon.svg");
export const quotePdf = require("../assets/test_quote.pdf")
export const uploadDocument = require("../assets/document_upload.svg");
export const resize = require("../assets/maximize.png")
export const deleteIcon = require("../assets/delete.png")

