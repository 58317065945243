Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "termsandconditions";
exports.labelBodyText = "termsandconditions Body";

exports.btnExampleTitle = "CLICK ME";
exports.getTermsAndCondEndPoint="bx_block_landingpage3/terms_and_conditions"
exports.getTermsAndCondApiMethod = "GET";
exports.getPrivacyPoliciesEndPoint="bx_block_landingpage3/privacy_policy"
exports.getPrivacyPoliciesApiMethod = "GET";
// Customizable Area End