import React, { FC } from "react";
import { Message } from "../../../framework/src/Message";
import MessageEnum, { getName, } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from '../../../framework/src/IBlock';
export const configJSON = require("./config");

// Customizable Area Start
import { messageIcon, pdfImage } from "./assets";
// Customizable Area End 
export interface Props {
    navigation: any;
    id: any;
    // Customizable Area Start
    history: any;
    notificationStates: {};
    openDialog: boolean;
    handleCloseDialog: () => void;
    handleImageChange: ({ }) => void;
    // Customizable Area End 
}

interface S {
    // Customizable Area Start
    data: any;
    open: boolean;
    id: boolean;
    anchorEl: HTMLButtonElement | null;
    toggle: boolean;
    docImg: string;
    openDialog: boolean;
    imgChange: boolean;
    SmallEnchor: HTMLButtonElement | null;
    smallPopoverOpen: boolean;
    simplePopover: string | undefined;
    myAncher: any,
    tabValue: Number,
    apiError: boolean,
    serverSideError: boolean,
    checkedItems: {},
    notificationList: {
        date: any; data: any[];
    }[],
    // Customizable Area End
}
interface SS {
    // Customizable Area Start
    id: any;
    // Customizable Area End 

}
class NotificationWebController extends BlockComponent<
    Props, S, SS> {
    // Customizable Area Start
    getAllNotificationsApi: string = ""
    // Customizable Area End
    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        // Customizable Area Start
        this.state = {
            smallPopoverOpen: false,
            checkedItems: {},
            id: false,
            anchorEl: null,
            open: false,
            toggle: false,
            imgChange: true,
            docImg: `${messageIcon}`,
            openDialog: false,
            SmallEnchor: null,
            simplePopover: undefined,
            tabValue: 0,
            apiError: false,
            serverSideError: false,
            notificationList: [],
            data: [{
                "id": "4",
                "type": "notification",
                "attributes": {
                    "id": 4,
                    "created_by": 1,
                    "contents": "Your DATA file has not matched with algorithm",
                    "is_read": false,
                    "read_at": null,
                    "notifiable_type": "BxBlockCategories::Category",
                    "notifiable_id": 1,
                    "created_at": "2023-09-02T07:54:52.527Z",
                    "updated_at": "2023-02-02T07:54:52.527Z",
                    "account_id": 1
                }
            },
            {
                "id": "3",
                "type": "notification",
                "attributes": {
                    "id": 3,
                    "created_by": 1,
                    "contents": "Your DATA file has not matched with algorithm",
                    "is_read": false,
                    "read_at": null,
                    "notifiable_type": "BxBlockCategories::Category",
                    "notifiable_id": 1,
                    "created_at": "2023-02-02T07:53:16.293Z",
                    "updated_at": "2023-02-02T07:53:16.293Z",
                    "account_id": 1
                }
            },
            {
                "id": "2",
                "type": "notification",
                "attributes": {
                    "id": 2,
                    "created_by": 1,
                    "contents": "Your quote has been submitted",
                    "is_read": true,
                    "read_at": "2023-02-02T07:54:24.667Z",
                    "notifiable_type": "BxBlockCategories::Category",
                    "notifiable_id": 1,
                    "created_at": "2023-02-02T07:51:46.070Z",
                    "updated_at": "2023-02-02T07:54:24.715Z",
                    "account_id": 1
                }
            },
            {
                "id": "1",
                "type": "notification",
                "attributes": {
                    "id": 1,
                    "created_by": 1,
                    "contents": "Your files have been matched",
                    "is_read": false,
                    "read_at": null,
                    "notifiable_type": "BxBlockCategories::Category",
                    "notifiable_id": 1,
                    "created_at": "2023-02-02T07:48:39.315Z",
                    "updated_at": "2023-02-02T07:48:39.315Z",
                    "account_id": 1
                }
            }

            ],
            myAncher: null
        };
        this.subScribedMessages = [
            getName(MessageEnum.CountryCodeMessage),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.ReciveUserCredentials),
        ];
        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages)

    }
    async receive(from: string, message: Message) {
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            if (apiRequestCallId === this.getAllNotificationsApi) {

                if (responseJson) {
                    if (responseJson?.error) {
                        this.setState({ serverSideError: true })
                        this.setState({ apiError: true })

                    }
                    else if (responseJson?.errors) {
                        this.setState({ apiError: true })
                    }

                    else {
                        const result = this.sortNotificationData(responseJson.data)
                        this.setState({ notificationList: result })
                    }

                }

            }


        }
    }

    // Customizable Area Start
    tempFunct = () => {
        this.props.handleImageChange({ image: "sfknfns", bol: false })
    }
    getDate = (date: string | number | Date) => {
        let d = new Date(date);

        return d.toLocaleDateString();
    }
    convertDate = (date: string | number | Date) => {
        let currentDate: any = new Date();
        const oldDate: any = new Date(date);
        const diffTime = Math.abs(oldDate - currentDate);
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
        if (diffDays === 0) {
            return "Today";
        }
        else if (diffDays <= 1) {
            return "Yesterday";
        }
        else {
            return diffDays + " days ago";
        }
    }
    sortNotificationData = (data: any[]) => {
        let result: {
            date: any; data: any[];
        }[] = []
        data.forEach((value, index) => {
            let foundIndex = result.findIndex((item =>
                item.date === this.getDate(value.attributes.created_at)))

            if (foundIndex >= 0) {
                result[foundIndex].data.push(value);
            } else {
                result.push({
                    date: this.getDate(value.attributes.created_at),
                    data: [value]
                })
            }
        })
        return result;

    }
    async componentDidMount() {
        const res = this.sortNotificationData(this.state.data);
        this.setState({ notificationList: res })
    }
    handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            checkedItems: {
                ... this.state.checkedItems,
                [event.target.name]: event.target.checked
            }
        });
    };
    handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        this.setState({ anchorEl: event?.currentTarget }, () => {
            this.setState({ open: Boolean(this.state.anchorEl) }, () => {
                this.setState({ id: this.state.open });
            });
        });
    };
    handleClose = () => {
        this.setState({ anchorEl: null, open: false, id: false });

    };

    handleClickOpenDialog = () => {
        this.setState({ openDialog: true });
    };

    handleCloseDialog = () => {
        this.setState({ openDialog: false });
    };
    handleClickSmallPopover = (event: React.MouseEvent<HTMLButtonElement>) => {
        this.setState({ SmallEnchor: event.currentTarget }, () => {
            this.setState({ smallPopoverOpen: Boolean(this.state.SmallEnchor), })
        });
    }
    handleCloseSmallPopover = () => {
        this.setState({ SmallEnchor: null, smallPopoverOpen: false })
    }
    handleSmallPopeOverReply = () => {
        this.props.history.push("/notification")
    }



    // Customizable Area End
}

export default NotificationWebController;
