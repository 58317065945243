Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.reportFileApiContentType = "application/json",
exports.reportFileApiEndPoint = "/bx_block_reports/reports/report_file",
exports.comparisonFileApiContentType = "application/json",
exports.comparisonFileApiEndPoint = "/bx_block_reports/reports/comparison_file",
exports.dollarTileApiContentType = "application/json"
exports.dollarTileApiEndPoint = "/bx_block_reports/reports/weekly_dollar_prices";
exports.qouteInvoiceTileApiContentType = "application/json"
exports.qouteInvoiceTileApiEndPoint = "/bx_block_reports/reports/quotes_and_invoices_weekly";
exports.quotesApiContentType = "application/json";
exports.quotesComparisonAPiEndPoint = "bx_block_filter_items/quotes_libraries/comparison_file";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "Analytics";
exports.labelBodyText = "Analytics Body";

exports.btnExampleTitle = "CLICK ME";
exports.comparisonTitle = "Comparison Files"
exports.uploadTitle = "Upload Files"
exports.comparisionTitle = "Comparision Files"
exports.reportTitle = "Report Generation"
exports.fileUploadApiEndPoint = "bx_block_filter_items/quotes_libraries";
exports.fileUploadApiMethod = "POST";
exports.getUploadApiMethod = "GET";
exports.getQuoteApiEndPoint = "bx_block_filter_items/quotes_libraries/upload_file"
exports.weeklyComparisonAPiEndPoint = "bx_block_reports/reports/comparison_file_weekly"
exports.quoteinvoiceApiEndPoint = "bx_block_filter_items/invoice_libraries/combine_quote_invoice_graph"
exports.projectVendorApiEndpoint = "bx_block_filter_items/invoice_libraries/combine_project_vendor_graph"
// Customizable Area End