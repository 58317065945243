import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DashboardNewWebController, {
  Props,
  RenderDialogProps
} from "./DashboardNewWebController.web";
import { NavLink } from "react-router-dom";

import {
  filesUploadIcon,
  closeModalIcon,
  pauseUploadIcon,
  cancelUploadIcon,
  uploadSuccessIcon,
  uploadFailedIcon
} from "./assets";
import { styled } from "@material-ui/styles";
import {
  createStyles,
  Theme,
  withStyles,
  WithStyles
} from "@material-ui/core/styles";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import Dropzone from "react-dropzone";
import LinearProgress from "@material-ui/core/LinearProgress";
import CustomLoader from "../../../framework/src/CustomLoader.web";

const StyledDialogBrowseButton = styled(Button)({
  "&.MuiButton-root": {
    width: "191px",
    height: "55px",
    borderRadius: "8px",
    backgroundColor: "#df802d",
    border: "1px solid #df802d",
    color: "white",
    textTransform: "capitalize",
    fontWeight: "bold",
    fontSize: "25px"
  }
});

const StyledDialogViewFilesButton = styled(Button)({
  "&.MuiButton-root": {
    width: "391px",
    height: "55px",
    borderRadius: "8px",
    backgroundColor: "#df802d",
    border: "1px solid #df802d",
    color: "white",
    textTransform: "capitalize",
    fontWeight: "bold",
    fontSize: "25px"
  }
});

const StyledDialogView = styled(Dialog)({
  "& .MuiBackdrop-root": {
    backgroundColor: "rgba(1, 1, 1, 0.2)"
  },
  "& .MuiDialog-paperWidthSm": {
    maxWidth: "none",
    display: "flex",
    borderRadius: "15px"
  },
  "& .MuiDialogActions-root": {
    justifyContent: "space-around"
  }
});

const StyledDialogProgressBar = styled(LinearProgress)({
  "&.MuiLinearProgress-root": { height: "12px" },
  "&.MuiLinearProgress-colorPrimary": {
    backgroundColor: "rgba(223,128,45,0.2)",
    borderRadius: "6px"
  },
  "& .MuiLinearProgress-barColorPrimary": {
    backgroundColor: "#df802d",
    height: "12px",
    borderRadius: "6px"
  }
});

const StyledContentModal = styled(DialogContent)({
  "&.MuiDialogContent-root": {
    padding: "0"
  }
});

const style = (theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(2),
      margin: 0,
    },
    closeButton: {
      top: theme.spacing(1),
      right: theme.spacing(1),
      position: "absolute",
      color: theme.palette.grey[500]
    }
  });

export interface DialogTitleProp extends WithStyles<typeof style> {
  id: string;
  children: React.ReactNode;
  onClose: () => void;
}

export const DialogTitle = withStyles(style)((props: DialogTitleProp) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <img src={closeModalIcon} />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

export const RenderDialog = (props: RenderDialogProps) => {
  const { openDialog, handleCloseDialog, apiError, errMsg, successMsg } = props;
  return (
    <>
      {!apiError ? (
        <StyledDialogView
          open={openDialog}
          onClose={handleCloseDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle data-testid="dialogTitleId" id="dialog-title" children='' onClose={handleCloseDialog} />
          <div style={webStyle.DialogContentWrapper}>
            <StyledContentModal>
              <div style={webStyle.successZone}>
                <img src={uploadSuccessIcon} alt="" />
                <div style={webStyle.uploadMessage}>
                  <span>{successMsg}</span>
                </div>
                <NavLink activeStyle={{ textDecoration: 'none' }} style={webStyle.navlinkStyle} to="/quotes">
                  <StyledDialogViewFilesButton onClick={handleCloseDialog}>
                    View Files
                  </StyledDialogViewFilesButton>
                </NavLink>
              </div>
            </StyledContentModal>
          </div>
        </StyledDialogView>
      ) : (
        <StyledDialogView
          open={openDialog}
          onClose={handleCloseDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="dialog-title" children="" onClose={handleCloseDialog} />
          <div style={webStyle.DialogContentWrapper}>
            <StyledContentModal>
              <div style={webStyle.successZone}>
                <img src={uploadFailedIcon} alt="" />
                <div style={webStyle.uploadMessage}>
                  <span>{errMsg}</span>
                </div>
              </div>
            </StyledContentModal>
          </div>
        </StyledDialogView>
      )}
    </>
  );
};

export default class QuotesUploadNewDialog extends DashboardNewWebController {
  constructor(props: Props) {
    super(props);
  }
  // Customizable Area Start


  render() {
    return (
      <div>
        {!this.props.isLoading ? (
          <RenderDialog
            openDialog={this.props.openDialog}
            handleCloseDialog={this.props.handleCloseDialog}
            apiError={this.props.apiError}
            errMsg={this.props.errMsg}
            successMsg={this.props.successMsg}
          />
        ) : (
          <StyledDialogView
            open={this.props.openDialog}
            onClose={this.props.handleCloseDialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle
              id="dialog-title"
              onClose={this.props.handleCloseDialog}
            >
              Upload
            </DialogTitle>
            <div style={webStyle.DialogContentWrapper}>
              <StyledContentModal>
                <Dropzone accept={this.state.accept} onDrop={this.props.onDrop}>
                  {({ getRootProps, getInputProps }: any) => (
                    <div style={webStyle.dropZone} {...getRootProps()}>
                      <img src={filesUploadIcon} alt="" />
                      <div style={webStyle.dialogDropZoneTextWrapper}>
                        <span>Drag and Drop your files here </span>
                        <br />code
                        <span>Or</span>
                      </div>
                      <input id="input" {...getInputProps()} />
                      <StyledDialogBrowseButton>
                        Browse
                      </StyledDialogBrowseButton>
                    </div>
                  )}
                </Dropzone>
              </StyledContentModal>
              {(this.props.files.length > 0 || (this.state.progressValue > 0 && this.state.progressValue < 100)) && (
                <>
                  <div style={webStyle.dialogUploadProgressWrapper} >
                    <span style={webStyle.dialogUploadFileTitle}>
                      Uploading {this.props.files.length} files
                    </span>
                    <div style={webStyle.dialogUploadActions}>
                      {`${this.state.progressValue} % - ${this.state.remainingTime} seconds remaining`}{" "}
                      <span>
                        <img src={pauseUploadIcon} alt="" />{" "}
                        <img src={cancelUploadIcon} alt="" />
                      </span>
                    </div>
                    <StyledDialogProgressBar variant="determinate" value={this.state.progressValue} />
                  </div>
                  <div ref={this.scrollToBottomRef}></div>

                  <CustomLoader loading={true} />
                </>
              )}
            </div>
          </StyledDialogView>
        )}
      </div>
    );
  }
}
// Customizable Area End

const webStyle = {
  dropZone: {
    display: "flex",
    flexDirection: "column" as "column",
    alignItems: "center",
    justifyContent: "center",
    padding: "36px 0",
    borderRadius: "10px",
    backgroundColor: "#ffffff",
    border: "1px dashed rgba(223,128,45,0.45)"
  },
  successZone: {
    display: "flex",
    flexDirection: "column" as "column",
    alignItems: "center",
    justifyContent: "center",
    padding: "36px 0",
    borderRadius: "10px",
    backgroundColor: "#ffffff"
  },
  DialogContentWrapper: {
    maxWidth: "700px",
    width: "700px",
    padding: "0 75px 25px 75px"
  },
  dialogUploadProgressWrapper: {
    borderRadius: "10px",
    backgroundColor: "#ffffff",
    border: "1px solid #979797",
    display: "flex",
    flexDirection: "column" as "column",
    marginTop: "20px",
    padding: "18px 25px"
  },
  dialogUploadFileTitle: {
    fontSize: "23px",
    color: " #05052b",
    fontWeight: 500,
    fontFamily: "Poppins",
    marginBottom: "32px"
  },
  dialogUploadActions: {
    marginBottom: "16px",
    display: "flex",
    justifyContent: "space-between",
    fontSize: "18px"
  },
  dialogDropZoneTextWrapper: {
    margin: "30px 0",
    fontSize: "25px",
    textAlign: "center" as "center"
  },
  uploadMessage: {
    margin: "30px 0",
    fontSize: "25px",
    textAlign: "center" as "center",
    fontWeight: 500
  },
  navlinkStyle: {
    textDecoration: 'none',
    '&:focus, &:hover, &:visited, &:link, &:active': {
      textDecoration: 'none'
    }

  }
};
