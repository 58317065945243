// Customizable Area Start
import React from "react";
import {
    MenuItem,
    FormControl,
    Select,
    Box,
    Typography,
    OutlinedInput,
    Button,
    Tabs,
    Tab,
    Grid, Link, Modal
} from "@material-ui/core";
import { styled } from "@material-ui/styles";
import { createTheme, withStyles } from "@material-ui/core/styles";
import Dropzone from "react-dropzone";
import { cancelupload, pauseUpload, downloadImg, editImg, uploaderror } from "./assets";
import LinearProgress from '@material-ui/core/LinearProgress';
import Header from "../../landingpage/src/Header.web";
import ReportGenerationController, { Props, TabPanelProps } from "./ReportGenerationController.web";
import FullscreenIcon from '@material-ui/icons/Fullscreen';
import { configJSON } from "./MatchAlgorithm2Controller";
import Chart from 'react-apexcharts';
import Badge from '@material-ui/core/Badge';
import ReactFullscreen from 'react-easyfullscreen';
import CustomLoader from "../../../framework/src/CustomLoader.web";
// Customizable Area End


// Customizable Area Start
export const theme = createTheme({
    typography: {
        h5: {
            fontWeight: 600,
            color: '#000'
        },
        h6: {
            fontWeight: 600,
            color: '#000'
        },
    }
});
// Customizable Area End

//Customizable Area Start
const StyledGenerateReportButton = styled(Button)({
    width: '50%',
    border: '1px solid #e0802e',
    borderRadius: '10px',
    display: "block",
    margin: "2% auto",
    padding: '20px 0',
    textTransform: 'unset',
    color: '#ffffff',
    fontSize: '22px',
    fontWeight: "bold",
    lineHeight: '22px',
    background: '#e0802e',
    "&.Mui-disabled": {
        background: "#fff",
        color: "#e0802e",
        fontWeight: "600",
    },
    "&.MuiButton-root:hover": {
        background: "#e0802e",
    }
});


const StyledUploadFileButton = styled(Button)({
    width: '100%',
    border: '1px solid #e0802e',
    borderRadius: '8px',
    display: "block",
    margin: "0% auto",
    padding: '0px 0px',
    textTransform: 'unset',
    color: '#ffffff',
    fontSize: '20px',
    fontWeight: "bold",
    lineHeight: '22px',
    background: '#e0802e',
    "&.Mui-disabled": {
        background: "#fff",
        color: "#e0802e",
        fontWeight: "600",
    },
    "&.MuiButton-root:hover": {
        background: "#e0802e",
    }
});

export const TabPanel = (props: TabPanelProps) => {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

export const UploadBox = (props: any) => {
    const { classes, acceptedFile, onDrop } = props;

    return (
        <Dropzone accept={acceptedFile} data-testid="dropzone" onDrop={onDrop}>
            {({ getRootProps, getInputProps }: any) => (
                <div className={classes.UploadButtonWrapper} {...getRootProps()}>
                    <input id="input" {...getInputProps()} />
                    <StyledUploadFileButton data-testid="browseButton">
                        Upload
                    </StyledUploadFileButton>
                </div>
            )}
        </Dropzone>
    )
}

export const a11yProps = (index: number) => {
    return {
        id: `vertical-tab-${index}`,
        'aria-controls': `vertical-tabpanel-${index}`,
    };
}

//Customizable Area End
class ReportGeneration extends ReportGenerationController {
    constructor(props: Props) {
        super(props);
    }
    // Customizable Area Start
    // Customizable Area End
    render() {
        // Customizable Area Start
        const { classes, handleSaveData, navigation } = this.props;
        // Customizable Area End
        return (
            // Customizable Area Start
            <>
                    <Header navigation={navigation} handleSaveData={handleSaveData} history={this.props.history}/>
                    <Box className={classes.reportDocumentBox}>
                        <Box className={classes.reportDocumentInnerBox}>
                            <Box className={classes.reportDocumenTitleBox}>
                                <Typography variant="h6">Report Generation</Typography>
                                <Box className={classes.reportUploadCurveBox}>
                                    <Box className={classes.curveReportInnerBox}></Box>
                                </Box>

                                <Box className={classes.curveRightReportBox}>
                                    <Box className={classes.curveRightReportInnerBox}></Box>
                                </Box>
                            </Box>
                        </Box>
                        <Box className={classes.reportTabBox}>
                            <Box className={classes.reportTitleTextWrapper}>
                                <Typography variant="h6">Upload the Quote and Invoice to Report Generation</Typography>
                                <Typography variant="body2"> Quidem possimus a est laboriosam libero dignissimos doloribus dolor sit amet consectetur adipisicing elit.Lorem ipsum, consequuntur ad accusamus. Ipsum, tempore! Cumque pariatur labore enim aut perspiciatis, libero sapiente perferendis.</Typography>
                            </Box>
                            <Tabs
                                orientation="horizontal"
                                value={this.state.value}
                                onChange={this.handleReportsTabChange}
                                data-testid="handleTabChange"
                                className={classes.tabsReportWrapper}
                            >
                                {
                                    this.state.reportsTab.map((item: any) => {
                                        return (
                                            <Tab
                                                label={
                                                    <Box className={classes.tabBoxTextWrapper}>
                                                        <Typography className={classes.tabReportText}>{("0" + item.id).slice(-2) + "."}</Typography>
                                                        <Typography className={classes.tabReportText}>{item.type}</Typography>
                                                    </Box>
                                                }
                                                {...a11yProps(0)}
                                                className={classes.reportTabMenuBox}
                                            />
                                        )
                                    })
                                }
                            </Tabs>

                            {this.state.value != 2 &&
                                <Box className={classes.reportInnerBoxWrapper}>
                                    <Box className={classes.innerBoxTitleWrapper}>
                                        <Typography variant="h6">Upload the Quote and Invoice to Match the algorithm</Typography>
                                    </Box>
                                    <Box className={classes.selectProjectAndUploadWrapperStyle}>
                                        <FormControl className={classes.formSelectWrapper} size="small">
                                            <Box className={classes.fromSelectTitle} id="demo-select-small-label">
                                                Upload Quotes
                                            </Box>
                                            <div className={classes.selectDropdownWrapper}>
                                                <Select
                                                    labelId="demo-select-small-label"
                                                    id="demo-select-small"
                                                    input={<OutlinedInput />}
                                                    displayEmpty
                                                    value={this.state.quotesValue}
                                                    MenuProps={{
                                                        anchorOrigin: {
                                                            vertical: "bottom",
                                                            horizontal: "left"
                                                        },
                                                        transformOrigin: {
                                                            vertical: "top",
                                                            horizontal: "left"
                                                        },
                                                        getContentAnchorEl: null,
                                                    }}
                                                    onChange={(event) => this.setQuotesValue(event)}
                                                    data-testid={"projectTestID"}
                                                >
                                                    <MenuItem data-testid="menuQuotesPlaceholderId" disabled value="">Choose Quotes</MenuItem>
                                                    {this.state.quotesData.map((item: any, index: number) => {
                                                        return (
                                                            <MenuItem key={item} value={item} data-testid={`selectMenuItem-${index}`}>{item}</MenuItem>
                                                        )
                                                    })}
                                                </Select>
                                                <span>Or</span>
                                                <UploadBox data-testid="quotesUploadBoxID" acceptedFile={this.state.acceptedFileType} classes={classes} onDrop={this.onQuotesUpload} />
                                            </div>
                                        </FormControl>
                                        <FormControl className={classes.formSelectWrapper} size="small">
                                            <Box className={classes.fromSelectTitle} id="demo-select-small-label">
                                                Upload Invoices
                                            </Box>
                                            <div className={classes.selectDropdownWrapper}>
                                                <Select
                                                    labelId="demo-select-small-label"
                                                    id="demo-select-small"
                                                    input={<OutlinedInput />}
                                                    displayEmpty
                                                    value={this.state.invoicesValue}
                                                    MenuProps={{
                                                        anchorOrigin: {
                                                            vertical: "bottom",
                                                            horizontal: "left"
                                                        },
                                                        transformOrigin: {
                                                            vertical: "top",
                                                            horizontal: "left"
                                                        },
                                                        getContentAnchorEl: null
                                                    }}
                                                    onChange={(event) => this.setInvoicesValue(event)}
                                                    data-testid={"projectInvoiceTestID"}
                                                >
                                                    <MenuItem data-testid="menuInvoicesPlaceholderId" disabled value="">Choose Invoices</MenuItem>
                                                    {this.state.invoicesData.map((item: any, index: number) => {
                                                        return (
                                                            <MenuItem key={item} value={item} data-testid={`selectMenuItem-${index}`}>{item}</MenuItem>
                                                        )
                                                    })}
                                                </Select>
                                                <span>Or</span>
                                                <UploadBox data-testid="invoicesUploadBoxID" acceptedFile={this.state.acceptedFileType} classes={classes} onDrop={this.onInvoiceUpload} />
                                            </div>
                                        </FormControl>
                                    </Box>
                                    <TabPanel value={this.state.value} index={0}>
                                        {
                                            this.state.quotesValue.length > 0 && this.state.invoicesValue.length > 0 
                                                ?
                                                <StyledGenerateReportButton onClick={this.handleGenerateReport} data-testid={"generateReportId"}>
                                                    Generate Report
                                                </StyledGenerateReportButton>
                                                :
                                                <StyledGenerateReportButton variant="contained" disabled data-testid={"generateReportId"}>
                                                    Generate Report
                                                </StyledGenerateReportButton>
                                        }
                                    </TabPanel>

                                    <TabPanel value={this.state.value} index={1}>
                                        <Box data-testid="genrateFilesId" className={classes.reportFileUploadBoxWrapper}>
                                            <Typography variant="h6">Generating 2 files</Typography>
                                            <Box className={classes.uploadingFileInnerBox}>
                                                <Typography>{this.state.progressReportVal.toFixed(0)} % - {this.state.timeReportRemaining.toFixed(0)} seconds remaining</Typography>
                                                <Box className={classes.uploadingIconBoxWrapper}>
                                                    <img src={pauseUpload} alt="pauseUpload" />
                                                    <img src={cancelupload} alt="cancelupload" />
                                                </Box>
                                            </Box>
                                            <LinearProgress variant="determinate" value={this.state.progressReportVal} data-testid="linearProgressId" />
                                        </Box>
                                    </TabPanel>


                                </Box>
                            }

                            <TabPanel value={this.state.value} index={2}>
                                <Grid container spacing={2}>
                                    <Grid item xs={9} md={9} lg={9} >
                                        <Box className={classes.quoteInvoiceResultBox}>
                                            <Grid container>
                                                <Grid item lg={6} md={6} className={classes.quoteBox}>
                                                    <ReactFullscreen>
                                                        {({ ref, onRequest }: any) => (
                                                            <>
                                                                <Box className={classes.quoteInnerBox}>
                                                                    <Typography variant="h5">{configJSON.quotesTitle}</Typography>
                                                                    <Box className={classes.quotesImgBox}>
                                                                        <FullscreenIcon onClick={() => onRequest()} />
                                                                        <img src={editImg} alt="editImg" className={classes.editIcon} /> 
                                                                        <Link href={this.state.quoteUploadPdf} download="test_quote.pdf"><img src={downloadImg} alt="downloadImg" /></Link>
                                                                    </Box>
                                                                </Box>

                                                                <Box className={classes.quotePdfBox} >
                                                                    <iframe src={this.state.quoteUploadPdf} ref={ref} />
                                                                </Box>
                                                            </>
                                                        )}
                                                    </ReactFullscreen>
                                                </Grid>
                                                <Grid item lg={6} md={6} className={classes.invoiceBox}>
                                                    <ReactFullscreen>
                                                        {({ ref, onRequest }: any) => (
                                                            <>
                                                                <Box className={classes.quoteInnerBox}>
                                                                    <Typography variant="h5">{configJSON.invoiceTitle}</Typography>
                                                                    <Box className={classes.quotesImgBox}>
                                                                        <FullscreenIcon onClick={() => onRequest()} />
                                                                        <img src={editImg} alt="downloadImg" className={classes.editIcon} />
                                                                        <Link href={this.state.invoiceUploadPdf} download="test_invoice.pdf"><img src={downloadImg} alt="downloadImg" /></Link>
                                                                    </Box>
                                                                </Box>
                                                                <Box className={classes.quotePdfBox}>
                                                                    <iframe src={this.state.invoiceUploadPdf} ref={ref} />
                                                                </Box>
                                                            </>
                                                        )}
                                                    </ReactFullscreen>
                                                </Grid>
                                            </Grid>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={3} md={3} lg={3}>
                                        <Box className={classes.resultAnalysisBox}>
                                            <Typography variant="h5">{configJSON.ResultAnalysisTitle}</Typography>
                                            <Chart options={this.state.options} series={this.state.series} type="radialBar" />
                                            <Box className={classes.quoteContenBox}>
                                                <Box className={classes.quoteTitleInnerBox}>
                                                    <Typography variant="h6">{configJSON.quotesTitle}</Typography>
                                                    <Badge badgeContent={this.state.lengthQuoteItem} color="error"></Badge>
                                                </Box>
                                                {
                                                    this.state.quoteComparisonData?.Compare_Difference?.length > 0 ?

                                                    <Box className={classes.quoteContentInnerBox}>
                                                    {
                                                        this.state.quoteComparisonData?.Compare_Difference.map((quoteitem: any) => {
                                                            return (
                                                                <Typography>{quoteitem.unmatched_quote}</Typography>
                                                            )
                                                        })
                                                    }
                                                    </Box> : <Typography variant="h4">--</Typography>
                                                }
                                                
                                            </Box>
                                            <Box className={classes.invoiceContenBox}>
                                                
                                                <Box className={classes.quoteTitleInnerBox}>
                                                    <Typography variant="h6">{configJSON.invoiceTitle}</Typography>
                                                    <Badge badgeContent={this.state.lengthInvoiceItem} color="error"></Badge>
                                                </Box>
                                                {
                                                    this.state.quoteComparisonData?.Compare_Difference?.length > 0 ?

                                                    <Box className={classes.quoteContentInnerBox}>
                                                    {
                                                        this.state.quoteComparisonData?.Compare_Difference.map((invoiceItem: any) => {
                                                            return (
                                                                <Typography>{invoiceItem.unmatched_invoice}</Typography>
                                                            )
                                                        })
                                                    }
                                                    </Box> : <Typography variant="h4">--</Typography>
                                                }
                                                
                                                <Box>
                                                    <Box  className={classes.differenceBox}>  
                                                        <Typography>{configJSON.differenceLable}</Typography>
                                                        <Typography>${this.state.resultDifferenceValue}</Typography>
                                                    </Box>
                                                </Box>
                                            </Box>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </TabPanel>
                        </Box>
                    </Box>
                    <Modal open={this.state.showIdErrorModal} onClose={this.handleCloseIdErrModal}>
                        <Box className={classes.failedErrorModalBox}>
                            <Box className={classes.failedErrorContent}>
                                <img src={uploaderror} alt="failedUpload" />
                                <Typography>{this.state.getIdErrorResponse}</Typography>
                                <Box className={classes.buttonfailedBox}>
                                    <Button onClick={this.handleCloseIdErrModal} data-testid="cancelbtn">{configJSON.cancelButton}</Button>
                                    <Button onClick={this.handleCloseIdErrModal} data-testid="okbtnid">{configJSON.okButton}</Button>
                                </Box>
                            </Box>
                        </Box>
                    </Modal>
                {this.state.reportGenerationLoader && <CustomLoader loading={this.state.reportGenerationLoader} />}
            </ >
            // Customizable Area End
        );
    }
}

// Customizable Area Start
export const reportGenerationStyle = (theme: any) => ({
    reportDocumentBox: {
        background: '#fff',
        padding: '0 0 0 0',
        border: '1px solid #e0802e',
        borderTopLeftRadius: '15px',
        borderTopRightRadius: '15px',
        color: '#000000'
    },
    reportDocumentInnerBox: {
        background: '#fcf7f2',
        padding: '10px 0 0 30px',
        width: '100%',
        borderTopLeftRadius: '15px',
        borderTopRightRadius: '15px',
    },
    reportDocumenTitleBox: {
        background: '#ffffff',
        width: '300px',
        textAlign: 'center' as 'center',
        border: '1px solid #e0802e',
        borderTopLeftRadius: '18px',
        borderTopRightRadius: '18px',
        borderBottom: 'none',
        paddingBottom: '20px',
        position: 'relative' as 'relative',
        top: '2px',
        zIndex: 1,
        '& h6': {
            color: '#e0802e',
            fontWeight: '600',
            paddingTop: '8px'
        },
        '& p': {
            color: '#e0802e',
        }
    },
    reportTabBox: {
        background: '#ffffff',
        position: 'relative' as 'relative',
        borderTop: '1px solid #e0802e',
        width: '100%',
    },
    reportInnerBoxWrapper: {
        margin: '2% 10%',
        border: '1px solid #eee',
        borderRadius: '10px'
    },
    innerBoxTitleWrapper: {
        borderBottom: '1px solid #eee',
        padding: '10px'
    },
    selectProjectAndUploadWrapperStyle: {
        padding: '20px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'baseline',
        gap: '30px'
    },
    contentLabel: {
        marginBottom: '10px'
    },
    formSelectWrapper: {
        width: '100%',
        '& input': {
            border: '1px solid #eae1e1',
            padding: '14px',
            borderRadius: '5px',
        },
        '& fieldset': {
            border: '1px solid #eae1e1',
        }
    },
    uploadInvoiceBox: {
        width: '100%'
    },
    fromSelectTitle: {
        paddingBottom: '10px',
        fontWeight: 500,
        fontSize: '18px'
    },
    dialogWrapper: {
        display: 'flex',
        justifyContent: 'center',
    },
    selectDropdownWrapper: {
        display: "flex",
        flexDirection: "column" as "column",
        alignItems: "center",
        justifyContent: "center",
        padding: "36px 0",
        borderRadius: "5px",
        backgroundColor: "#ffffff",
        border: "1px dashed rgba(223,128,45,0.45)",
        width: '100%',
        "& .MuiInputBase-root": {
            width: '90%',
            '& .MuiSelect-select': {
                fontSize: '14px',
                fontWeight: 500
            }
        },
        "& h6": {
            fontSize: "16px",
            fontWeight: 500,
        },
        "& span": {
            margin: '10px 0px'
        }
    },
    dialogDropZoneText: {
        width: '100%',
        textAlign: 'center' as 'center',
        padding: '20px'
    },
    reportTitleTextWrapper: {
        margin: '10px'
    },
    tabBoxTextWrapper: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '5px'
    },
    tabReportText: {
        fontWeight: 600
    },
    tabsReportWrapper: {
        padding: '10px',
        margin: '10px',
        borderRadius: '4px',
        width: 'max-content',
        border: '1px solid #eee',
        '& button': {
            maxWidth: '320px',
            opacity: 1,
            color: '#e0802e',
            '&.Mui-selected': {
                background: '#e0802e',
                clipPath: "polygon(90% 0, 100% 50%, 90% 100%, 0% 100%, 0 53%, 0% 0%)",
                borderRadius: '10px',
                '& p': {
                    color: '#ffffff',
                    fontWeight: '500'
                },
                '& h6': {
                    color: '#ffffff',
                    fontWeight: '600',
                    [theme.breakpoints.down('1610')]: {
                        fontSize: '18px'
                    },
                }
            }
        },
        '& span.MuiTabs-indicator': {
            display: 'none'
        },
        '& span': {
            alignItems: 'unset',
            textTransform: 'capitalize',
        }
    },
    reportTabMenuBox: {
        textAlign: 'left' as 'left',
        minHeight: '100%',
        '&.MuiTab-root': {
            minHeight: '100%',
        }
    },
    reportUploadCurveBox: {
        height: '30px',
        width: '31px',
        background: '#fcf7f2',
        position: 'absolute' as 'absolute',
        bottom: 0,
        left: '-31px',
    },
    curveReportInnerBox: {
        border: '1px solid #e0802e',
        height: '29px',
        width: '31px',
        borderBottomRightRadius: '15px',
        borderTop: 'none',
        borderLeft: 'none'
    },
    curveRightReportBox: {
        height: '32px',
        width: '30px',
        background: '#fcf7f2',
        position: 'absolute' as 'absolute',
        bottom: 0,
        right: '-30px',
    },
    curveRightReportInnerBox: {
        width: '30px',
        height: '31px',
        borderTop: 'none',
        borderRight: 'none',
        borderBottomLeftRadius: '15px',
        border: '1px solid #e0802e'
    },
    reportFileUploadBoxWrapper: {
        border: '1px solid #dfdfdf',
        padding: "10px 25px 25px",
        margin: '0 0%',
        borderRadius: "5px",
        '& .MuiLinearProgress-root': {
            background: '#f5e4d7',
            height: '12px',
            borderRadius: '5px'
        },
        '& .MuiLinearProgress-bar': {
            background: '#de812c',
        },
        '& h6': {
            color: '#000',
            fontWeight: '600',
        },
    },
    uploadingFileInnerBox: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        '& p': {
            fontSize: '16px',
            color: '#000',
            fontWeight: '500',
            margin: '20px 0 10px 0'
        }
    },
    uploadingIconBoxWrapper: {
        '& :nth-child(1)': {
            marginRight: '15px'
        }
    },
    UploadButtonWrapper: {
        width: '90%',
    },
    quoteInvoiceResultBox: {
        border: '1px solid #ddd',
        boxShadow: '0 0 10px #eee',
        borderRadius: '10px'
    },
    quotesImgBox: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        '& img': {
            width: '23px',
            color: '#000',
        }
    },
    editIcon: {
        margin: '0 15px',
    },
    quoteBox: {
        padding: '10px 10px',
        borderRight: '1px solid #ddd',
        boxShadow: '0 0 10px #eee',
    },
    quoteInnerBox: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    invoiceBox: {
        padding: '10px 10px',
    },
    quotePdfBox: {
        '& iframe': {
            display: 'block',
            width: '100%',
            borderRadius: '10px',
            border: '1px solid red',
            height: '71vh',
            maxHeight: '100%',
            marginTop: '15px'
        }
    },
    resultAnalysisBox: {
        padding: '10px 10px',
        borderRadius: '10px',
        border: '1px solid #ddd',
        boxShadow: '0 0 10px #eee',
        '& h6': {
            margin: '0 24px 0 0',
            display: 'inline-block'
        },
        '& span': {
            marginTop: '-2px'
        }
    },
    quoteContenBox: {
        border: '1px solid #ddd',
        boxShadow: '0 0 10px #eee',
        borderRadius: '10px',
        '& h4':{
            textAlign: 'center',
        }
    },
    quoteTitleInnerBox: {
        borderBottom: '1px solid #ddd',
        boxShadow: '0 0 10px #eee',
        padding: '6px 15px'
    },
    quoteContentInnerBox: {
        padding: '8px 15px 40px',
        height: '117px',
        overflowY: 'scroll' as 'scroll',
        '& p': {
            color: '#000',
            marginBottom: '20px'
        }
    },
    invoiceContenBox: {
        borderRadius: '10px',
        border: '1px solid #ddd',
        boxShadow: '0 0 10px #eee',
        marginTop: '20px',
        '& h4':{
            textAlign: 'center',
        }
    },
    differenceBox: {
        borderTop: '1px solid #ddd',
        boxShadow: '0 0  10px #eee',
        display: 'flex',
        justifyContent: 'space-between',
        padding: '8px 0px',
        margin: '0px 11px',
        '& p': {
            color: 'red',
            fontWeight: 600
        }
    },
   
    failedErrorModalBox: {
        padding: '0px 20px 30px',
        background: '#ffffff',
          width: '560px',
          position: 'absolute' as 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          borderRadius: '10px',
    },
    buttonfailedBox:{
        textAlign: 'center' as 'center',
        '& button':{
          border: '1px solid #e0802e',
          padding: '15px 35px',
          textTransform: 'unset',
          color: '#e0802e',
          fontSize: '20px',
          lineHeight: '22px',
          '&:hover':{
            background: '#e0802e',
            color: '#ffffff'
          },
          '&:first-child':{
            marginRight: '40px'
          }
        },
    },
      
    failedErrorContent:{
        textAlign: 'center' as 'center',
        padding: '30px 0 10px 0',
        '& p': {
            fontWeight: '500',
            color: '#000',
            margin: '25px 0'
        },
        '& img':{
            width: '200px',
            height: '200px'
        }
    }

})
export { ReportGeneration }
export default withStyles(reportGenerationStyle)(ReportGeneration)
// Customizable Area End
