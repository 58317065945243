import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { startOfDay, endOfDay, addDays, subDays } from 'date-fns';
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  onApplyClick: (data: object) => void;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  documentType: string;
  project: string;
  vendor: string;
  keyword: string;
  AdvSearchOpen: boolean;
  documentTypeOptions: string[];
  projectOptions: string[];
  vendorOptions: string[];
  dateRange: Date[] | undefined;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

interface Data {
  title: string,
  value: number,
}

export default class SearchWebController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  searchApiCallId: any;
  vendorOptionsApi: string = "";
  projectOptionsApi: string = "";
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.ReciveUserCredentials),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      documentType: "",
      project: "",
      vendor: "",
      keyword: "",
      AdvSearchOpen: false,
      documentTypeOptions: ['pdf','excel'],
      projectOptions: [],
      vendorOptions: [],
      dateRange: undefined,
     
      // Customizable Area End
    };
    // Customizable Area Start
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    this.getVendorOptions()
    this.getProjectOptions()
    
    // Customizable Area End
  }


  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
          getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
          getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      

      if (apiRequestCallId === this.vendorOptionsApi && responseJson?.message === 'success') {
        this.setState({vendorOptions: responseJson.data})
      }

      if (apiRequestCallId === this.projectOptionsApi && responseJson?.message === 'success') {
        this.setState({projectOptions: responseJson.data})
      }
  }
    // Customizable Area End
  }

  // Customizable Area Start

  Ranges = [
    {
        label: 'today',
        value: [startOfDay(new Date()), endOfDay(new Date())],
        placement: 'left'
    },
    {
        label: 'yesterday',
        value: [startOfDay(addDays(new Date(), -1)), endOfDay(addDays(new Date(), -1))],
        placement: 'left'
    },
    {
        label: 'last7Days',
        value: [startOfDay(subDays(new Date(), 6)), endOfDay(new Date())],
        placement: 'left'
    },
    {
        label: 'Last 15 Days',
        value: [startOfDay(subDays(new Date(), 15)), endOfDay(new Date())],
        placement: 'left'
    },
    {
        label: 'Last Month',
        value: [startOfDay(subDays(new Date(), 30)), endOfDay(new Date())],
        placement: 'left'
    }
];

    advSearchOpen = (value: boolean = false) => {
        this.setState({ AdvSearchOpen: value });
    };

    handleDocumentTypeChange = (value: string) => {
      this.setState({documentType: value})
    }
    
    handleProjectTypeChange = (value: string) => {
      this.setState({project: value})
    }

    handleVendorChange = (value: string) => {
      this.setState({vendor: value})
    }

    handleKeywordChange = (e: any)=>{
      this.setState({keyword: e.target.value})
    }
    
    dateChangeHandler = (value: Date[]) => {
      this.setState({dateRange: value})
    }

    convertDateToString = (date: Date | undefined): string | null => {
      if (!date){
        return null
      }
      const newDate = date.getUTCFullYear() + '/' + (date.getUTCMonth()+1) + '/' + date.getUTCDate()
      return newDate
    }
    
    cancelBtnHandler = () => {
      this.setState({
        vendor: '',
        documentType: '',
        project: '',
        keyword: '',
        dateRange: undefined,
        AdvSearchOpen: false
      })
    }

    applyBtnHandler = (): boolean => {
      this.setState({
        AdvSearchOpen: false
      })
      return true;
    }

    getToken = ():string|undefined => {
      const token = document.cookie.split("; ").find((row) => row.startsWith("Token="))?.split("=")[1];
      return token
    }

    getVendorOptions = (): boolean => {

      const header = {
        "Content-Type": configJSON.getVendorOptionsContextType,
        "token": this.getToken()
      };

      const requestMessage = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
      );

      this.vendorOptionsApi = requestMessage.messageId;
      requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          configJSON.getVendorOptionsApiEndPoint
      );

      requestMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(header)
      );

      requestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          configJSON.getVendorOptionsApiMethod
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);

      return true;
    }

    getProjectOptions = (): boolean => {

      const header = {
        "Content-Type": configJSON.getProjectOptionsContextType,
        "token": this.getToken()
      };

      const requestMessage = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
      );

      this.projectOptionsApi = requestMessage.messageId;
      requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          configJSON.getProjectOptionsApiEndPoint
      );

      requestMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(header)
      );

      requestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          configJSON.getProjectOptionsApiMethod
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);

      return true;
    }
    
  // Customizable Area End
}
