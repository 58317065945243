// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { IBlock } from "../../../framework/src/IBlock";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
export const configJSON = require("./config");
// Customizable Area End
interface ColumnQuote {
  id: string;
  label: string;
}

type Contact = string | undefined | number;

interface CategoryData {
  id: number;
  categoryName: string;
}

interface EditResponseInterface {
  data: {
    attributes: {
      invoice: [{}];
      category_description: [],
      project: number,
      id: number,
      description: string,
      ord_quantity: string,
      unit: string,
      price: string,
      amount: string,
      report_quote_id: number,
      created_at: string,
      updated_at: string,
      tax: string,
      invoice_category_name: string
    }
    quote: any,
    quote_description: []
  }
}
interface TableDataItem {
  order_unit: string;
  category_description: any[],
  project: number,
  id: any,
  description: string,
  ord_quantity: string,
  unit: string,
  price: string,
  amount: string,
  report_invoice_id: number,
  created_at: string,
  updated_at: string,
  tax: string,
  invoice_category_name: string,
  invoice_category_subtotal: null
  taxSwitch: boolean
}

interface TableDescription {
  id: number,
  invoice_category_name: string,
  content: string,
  data: TableDataItem[],
  pagination: {
    id: number,
    page: number,
    rowsPerPage: number
  },
  warningTableBox: boolean,
  subtotalValue: number,
  selected: number[],
  categoryErrorMessage: string,
  addNewSegmentState: boolean,
  categoryBox: boolean
}

interface InvoiceTabsInterface {
  lastModified: number,
  lastModifiedDate: string,
  name: string,
  size: number,
  type: string,
  webkitRelativePath: string
}
export interface Props {
  // Customizable Area Start
  classes: any;
  navigation: any;
  id: string;
  handleSaveData: (data: string[]) => void;
  location: any;
  history: any;
  // Customizable Area End
}
export interface TableHeadProps {
  addNewSegmentState: boolean;
  categoryBox: boolean;
  editQuoteColumn: ColumnQuote[];
  handleDragDataSelectAllItemClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  data: string[],
  selected: string[],
  itemTaxData: boolean
}
export interface QuoteTableHeadProps {
  addNewSegmentState: boolean,
  categoryBox: boolean,
  handleSelectAllItemClick: (event: React.ChangeEvent<HTMLInputElement>, columnId: number) => void,
  selected: string[] | number[],
  classes: any,
  columnId: number,
  itemTaxData: boolean
}
export interface NoItemBoxProps {
  classes: any,
  data: string
}
export interface AddSegmentBoxProps {
  addNewSegmentState: boolean,
  classes: any,
  addNewItemRow: (columnId: number) => void,
  subTotalValue: number | string,
  editQuoteDetail: any,
  columnId: number
}
export interface DeleteButtonProps {
  addNewSegmentState: boolean,
  addCategory: string,
  handleInvoiceCategoryChange: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, columnId: number) => void,
  classes: any,
  handleDeleteSegmentBox: () => void,
  categoryErrorMessage: string,
  columnId: number,
  deleteInvoiceCategoryModal: (columnId: number) => void,
}
export interface DeleteIconProps {
  addNewSegmentState: boolean,
  categoryBox: boolean,
  deleteOpenTableItemModal: () => void,
  editquoteData: any,
  classes: any
}
export interface DroppableBoxProps {
  data: any[],
  handleEditQuoteDragDescription: (value: any, itemId: number, field: string) => void,
  dragDataRowItemClick: (event: React.MouseEvent<unknown>, itemId: string) => void,
  dragQuotePage: number,
  dragQuoteRowsPerPage: number,
  handleDragDataChangePage: (event: React.ChangeEvent<unknown>, newPage: number) => void,
  classes: any,
  addNewSegmentState: boolean,
  selected: string[],
  itemTaxData: boolean,
  categoryBox: boolean,
  editQuoteColumn: ColumnQuote[],
  handleDragDataSelectAllItemClick: (event: React.ChangeEvent<HTMLInputElement>) => void,
  begin: number
}
export interface AddSegmentButtonProps {
  selected: any,
  addNewSegmentHandler: () => void,
  classes: any,
  deleteOpenModal: () => void,
  deleteCloseModal: () => void
}

export interface QuoteDateBoxProps {
  editQuoteDetail: any,
  classes: any,
  typeOfFocused: any,
  handleDate: () => void,
  handleEditQuoteDetail: (field: string, floatValue: string) => void
}

export interface QuoteSiteBoxProps {
  handleEditQuoteDetail: (field: string, floatValue: string) => void,
  editQuoteDetail: any,
}

export interface QuoteExpiryDateBoxProps {
  editQuoteDetail: any,
  classes: any,
  typeOfFocused: any,
  handleDate: () => void,
  handleEditQuoteDetail: (field: string, floatValue: string) => void
}

export interface TaxDraggedTableCellProps {
  itemTaxData: boolean,
  selected: string[],
  classes: any,
  editquoteDragIdData: string,
  editquoteDragData: any,
  handleEditQuoteDragDescription: (value: any, itemId: number, field: string) => void
}

export interface TaxwiseTableCellProps {
  itemTaxData: boolean,
  classes: any,
  editquoteIdData: any,
  editquoteData: null | string,
  selected: string[],
  columnId: number,
  handleEditQuoteDescription: (value: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | Contact, columnId: number, itemId: number, field: string) => void,
  editQuoteDataDes: string
}

export interface EditTableCellProps {
  classes: any,
  editquoteIdData: any,
  editquoteData: null | string,
  selected: string[],
  columnId: number,
  handleEditQuoteDescription: (value: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | string, columnId: number, itemId: number, field: string) => void
}

export interface QuantityTableCellProps {
  classes: any,
  editquoteIdData: any,
  editquoteData: null | string,
  selected: string[],
  columnId: number,
  handleEditQuoteDescription: (value: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | string, columnId: number, itemId: number, field: string) => void
}

export interface TaxTableRowProps {
  classes: any,
  itemTaxData: boolean
}

export interface UnitTableCellProps {
  classes: any,
  editquoteData: string,
  selected: string[],
  handleEditQuoteDescription: (value: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | string | undefined | number, columnId: number, itemId: number, field: string) => void
  editquoteIdData: any
  columnId: number
}

export interface TableRowDataProps {
  addNewSegmentState: boolean,
  categoryBox: boolean,
  classes: any,
  selected: string[],
  editquoteIdData: any,
  columnId: number,
  handleRowItemClick: (event: React.MouseEvent<unknown>, columnId: number, itemId: string) => void
}



export interface PriceTableCellProps {
  classes: any,
  editquotePriceData: string,
  selected: string[],
  handleEditQuoteDescription: (value: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | string | undefined | number, columnId: number, itemId: number, field: string) => void,
  editquoteIdData: number,
  columnId: number
}

export interface PriceTableCellNewProps{
  classes:any,
  isItemSelected:any,
  price:any,
  handleEditQuoteDescription:any,
  calculateAllDataTotal:any,
  columnId:any,
  editquoteDataId:any

}

interface RentalTableDataProps {
  created_at: string,
  invoice_category_subtotal: null | number,
  order_unit: string | null,
  part_number: null | string,
  report_invoice_id: number,
  updated_at: string,
  day: string | null
  equipment: string | null
  invoice_category_name: string | null
  month: string | null
  week: string | null
  id: number;
  description: string;
  ord_quantity: null | string;
  unit: string | null;
  price: string | null;
  amount: number;
  report_quote_id: number;
  tax: number | null | string;
  minimum: number | null | string;
}
interface InvoiceDataAttributes {
  invoice_subtotal: number;
  total_price: number;
}

interface InvoiceData {
  attributes: InvoiceDataAttributes;
}

interface Invoice {
  data: InvoiceData;
}

interface TransFormInvoiceDetailProps {
  calculateValue: number;
  addNewSegmentState: any;
  categoryBox: any;
  totalValue: number;
  taxSwitch: boolean;
  invoice_description: Array<{ [key: string]: TableDataItem[] } | TableDataItem>,
  invoice: Invoice;
  editQuoteDetail: any;
  items: {}
}
interface InvoiceDetailProps {
  invoice_description: Array<{ [key: string]: TableDataItem[] } | TableDataItem>,
  invoice: Invoice;
  editQuoteDetail: any;
  items: {}
}
interface S {
  // Customizable Area Start
  editquoteComparisonData: any[],
  editQuoteDetail: any,
  quotePage: number,
  categoryDescBox: CategoryData[],
  quoteRowsPerPage: number,
  editQuoteColumn: ColumnQuote[],
  typeOfFocused: any,
  addNewSegmentState: boolean,
  addCategory: string
  selected: any[],
  selectedData: string[],
  addItemBox: boolean,
  categoryBox: boolean,
  token: string | undefined,
  deleteId: string,
  categoryErrorMessage: string,
  totalItem: number,
  orderQuantity: string,
  itemTaxData: boolean,
  dragQuotePage: number,
  dragQuoteRowsPerPage: number,
  creteReportData: any,
  deleteModalState: boolean,
  dragShowTable: boolean,
  totalValue: number,
  calculateValue: number,
  dragSubTotalValue: number,
  addCategory1: string;
  itemTaxValue: boolean;
  tablesData: TableDescription[];
  isEmptyBox: boolean;
  categoryInvoiceColumnId: number;
  tableDataBox: boolean;
  columnIdState: number;
  invoiceTabs: InvoiceTabsInterface[];
  activeTab: any;
  parentTableData: any;
  categoryValue: any;
  apiCallIndex: any;
  RentalTableData: RentalTableDataProps[]
  newUpdatedDataArr: TableDescription[];
  newUpdatedInvoiceDetails: any;
  previousTableDataRequired: any;
  reportInvoiceId:any;
  quotedetail:any;
  segmentColumnId:number;
  quotestate:any;
  editQuoteDetailPayload:any;
  invoicesSaveBtnPayload:any;
  deleteItemsArr:any,
  allUpdatedTableItems:any,
  saveBtnClick:boolean
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  // Customizable Area End
}


export default class EditQuoteScanResultController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  updateQuoteDetailApiId: string = "";
  deleteQuoteDetailApiId: string = "";
  deleteDescriptionApiId: string = "";
  addNewItemApiId: string = "";
  addNewSegmentApiId: string = "";
  // Customizable Area End

  constructor(props: Props) {

    super(props);
    this.state = {
      // Customizable Area Start
      categoryDescBox: [],
      categoryErrorMessage: "",
      editquoteComparisonData: [],
      // editquoteComparisonData: this.props.location.state.updated_table_data[0].invoice_description,
      categoryInvoiceColumnId: 0,
      tablesData: [
        {
          id: 1,
          invoice_category_name: "",
          content: "",
          data: [],
          pagination: {
            id: 1,
            page: 1,
            rowsPerPage: 10
          },
          warningTableBox: false,
          selected: [],
          subtotalValue: 0,
          categoryErrorMessage: "",
          addNewSegmentState: false,
          categoryBox: false
        }
      ],
      RentalTableData: this.props?.location?.state?.invoiceDetails?.[0]?.invoice_rental_description,
      editQuoteDetail: this.props?.location?.state?.invoiceDetails?.[0]?.invoice?.data?.attributes || this.props?.location?.state?.invoiceDetails?.[0]?.editQuoteDetail || [],
      creteReportData: this.props?.location?.state?.createReportData || [],
      typeOfFocused: {},
      quotePage: 1,
      quoteRowsPerPage: 10,
      dragQuotePage: 1,
      dragQuoteRowsPerPage: 10,
      addNewSegmentState: false,
      addItemBox: false,
      categoryBox: false,
      addCategory1: this.props?.location.state?.segmentName2 || "",
      addCategory: this.props?.location.state?.segmentName1 || "",
      selected: [],
      selectedData: [],
      editQuoteColumn: [
        {
          id: 'Sr.no', label: 'Sr. No',
        },
        {
          id: 'description', label: 'Description',
        },
        {
          id: 'OrderQty.', label: 'Order Qty.',
        },
        {
          id: 'unit', label: 'Unit',
        },
        {
          id: 'unitPrice', label: 'Unit Price',
        },
        {
          id: 'tax', label: 'Tax(%)',
        },
        {
          id: 'Ext. Price', label: 'Ext. Price'
        }
      ],
      token: '',
      deleteId: "",
      totalItem: 0,
      orderQuantity: "",
      itemTaxData: false,
      itemTaxValue: false,
      deleteModalState: false,
      dragShowTable: false,
      totalValue: 0,
      dragSubTotalValue: this.props?.location?.state?.segmentTotal2,
      calculateValue: 0,
      isEmptyBox: false,
      tableDataBox: false,
      columnIdState: 0,
      invoiceTabs: this.props?.location?.state?.invoiceTab,
      activeTab: 0,
      parentTableData: [],
      categoryValue: "",
      apiCallIndex: 0,
      newUpdatedDataArr: [],
      // newUpdatedDataArr:[],
      newUpdatedInvoiceDetails: {},
      previousTableDataRequired: [],
      reportInvoiceId:"",
      quotedetail:{},
      segmentColumnId:0,
      quotestate:{},
      editQuoteDetailPayload:{},
      invoicesSaveBtnPayload:[],
      deleteItemsArr:[],
      allUpdatedTableItems:[],
      saveBtnClick:true,
      // Customizable Area End
    };

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.ReciveUserCredentials),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.CountryCodeMessage),
    ];
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }
  // Customizable Area Start
  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const editQuoteApiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const editQuoteResponseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (editQuoteApiRequestCallId === this.updateQuoteDetailApiId) {
        this.updateEditquoteApiResponseHandle(editQuoteResponseJson)
      }

      if (editQuoteApiRequestCallId === this.addNewItemApiId) {
        this.addNewItemApiResponseHandle(editQuoteResponseJson)
      }

      if (editQuoteApiRequestCallId === this.deleteQuoteDetailApiId) {
        this.deleteQuoteDetailApiResponseHandle(editQuoteResponseJson)
      }

      if (editQuoteApiRequestCallId === this.deleteDescriptionApiId) {
        this.deleteDescriptionApiResponseHandle(editQuoteResponseJson)
      }

    }
  }

  getToken = () => {
    return document.cookie.split("; ").find((row) => row.startsWith("Token="))?.split("=")[1];
  }


  isNestedStructure = (items: InvoiceDetailProps) => {
    return Array.isArray(items.invoice_description) &&
      items.invoice_description.length > 0 &&
      typeof items.invoice_description[0] === 'object' &&
      !Array.isArray(items.invoice_description[0]) &&
      Object.keys(items.invoice_description[0]).length > 1;
  };

  transformTableData = (items: TransFormInvoiceDetailProps, index: number) => {
    if (this.isNestedStructure(items)) {
      return {
        tablesData: [
          {
            id: index + 1,
            warningTableBox: false,
            subtotalValue: items.calculateValue || 0,
            addNewSegmentState: items.addNewSegmentState,
            categoryBox: items.categoryBox,
            categoryErrorMessage: "",
            content: "",
            data: items.invoice_description,
            pagination: {
              id: 1,
              page: 1,
              rowsPerPage: 10,
            },
            selected: []
          }
        ],
        totalValue: items.totalValue || 0,
        calculateValue: items.calculateValue || 0,
        taxSwitch: items.taxSwitch || false,
        addNewSegmentState: items.addNewSegmentState,
        categoryBox: items.categoryBox,
        editQuoteDetail: items.editQuoteDetail
      };
    } else {
      const tableData = Object.entries(items.invoice_description).map(([categoryName, categoryItems]: any, catIndex) => {
        let updatedTransformdata = Object.keys(categoryItems).map(key => ({
          data: categoryItems[key]
        }));

        const categoryKey = Object.keys(categoryItems)[0];

        return {
          id: catIndex + 1,
          warningTableBox: false,
          subtotalValue: categoryItems[categoryKey][0]?.subtotalValue || 0,
          addNewSegmentState: items.addNewSegmentState || false,
          invoice_category_name: categoryKey,
          categoryErrorMessage: "",
          content: "",
          data: updatedTransformdata[0].data,
          pagination: {
            id: 1,
            page: 1,
            rowsPerPage: 10,
          },
          selected: []
        };
      });

      return {
        tablesData: tableData,
        totalValue: items.totalValue || 0,
        calculateValue: items.calculateValue || 0,
        taxSwitch: items.taxSwitch,
        addNewSegmentState: items.addNewSegmentState,
        categoryBox: items.categoryBox,
        editQuoteDetail: items.editQuoteDetail || {}
      };
    }
  };

  initializeData = (items: InvoiceDetailProps, index: number) => {
    if (this.isNestedStructure(items)) {
      return {
        tablesData: [{
          id: index + 1,
          invoice_category_name: "",
          content: "",
          data: items.invoice_description,
          pagination: {
            id: 1,
            page: 1,
            rowsPerPage: 10
          },
          warningTableBox: false,
          selected: [],
          subtotalValue: items.invoice.data.attributes.invoice_subtotal || 0,
          addNewSegmentState: false,
          categoryBox: false,
          categoryErrorMessage: "",
        }],
        editQuoteDetail: items.invoice.data.attributes || items.editQuoteDetail,
        totalValue: items.invoice.data.attributes.total_price,
        calculateValue: items.invoice.data.attributes.invoice_subtotal,
        taxSwitch: false,
        addNewSegmentState: false,
        categoryBox: false
      };
    } else {
      return Object.entries(items.invoice_description).map(([key, value], idx) => ({
        id: idx + 1,
        invoice_category_name: key,
        data: value,
        pagination: {
          id: 1,
          page: 1,
          rowsPerPage: 10,
        },
        warningTableBox: false,
        selected: [],
        subtotalValue: 0,
        addNewSegmentState: false,
        categoryErrorMessage: "",
      }));
    }
  };


  async componentDidMount() {
    super.componentDidMount();
    const { tablesData } = this.state;
    let parentTableData = [];

    if (this.props.location.state.updated_table_data.length > 0) {
      parentTableData = this.props.location.state.updated_table_data.map(this.transformTableData);
      this.setState({
        addNewSegmentState: parentTableData[this.state.activeTab].addNewSegmentState,
        itemTaxData: parentTableData[this.state.activeTab].taxSwitch,
        categoryBox: parentTableData[this.state.activeTab].categoryBox,
        tablesData: parentTableData[this.state.activeTab].tablesData || [],
      });
    } else {
      parentTableData = this.props.location.state.invoiceDetails.map(this.initializeData);
      this.setState({
        parentTableData,
        tablesData: parentTableData[this.state.activeTab].tablesData,
      });
    }

    if (this.props.location.state.quotedetail) {
      this.setState({
        quotedetail:this.props.location.state.quotedetail
      })
    }

    if (this.props.location.state.quotestate) {
      this.setState({
        quotestate:this.props.location.state.quotestate,
      })
    }


    let reportInvoiceId = String(parentTableData[0].tablesData[0].data[parentTableData[0].tablesData[0].data.length-1].report_invoice_id)
    this.setState({
      reportInvoiceId
    })

    this.setState({
      parentTableData,
      tablesData: parentTableData[this.state.activeTab].tablesData,
    }, () => {
      this.state.tablesData.forEach(table => {
        this.subTotalPrice(table.id);
      });

      const previousTableData = [...this.state.tablesData];
      this.setState({
        previousTableDataRequired: previousTableData
      }, () => {
        setTimeout(() => {
          this.calculateAllDataTotal();
        }, 1000);
      });
    });
  }

  addNewItemApiResponseHandle = (editQuoteResponseJson: any) => {
    const { tablesData } = this.state;

    const updatedTablesData = tablesData.map((table: any, index: number) => {

      if (index === this.state.columnIdState) {

        const { data, pagination } = table;
        const { page, rowsPerPage } = pagination;
        const startIndex = (page - 1) * rowsPerPage;
        const endIndex = startIndex + rowsPerPage;
        const newDescriptionData = {
          id: editQuoteResponseJson.data[0].attributes.id,
          description: editQuoteResponseJson.data[0].attributes.description,
          ord_quantity: editQuoteResponseJson.data[0].attributes.ord_quantity,
          unit: editQuoteResponseJson.data[0].attributes.unit,
          price: editQuoteResponseJson.data[0].attributes.price,
          amount: editQuoteResponseJson.data[0].attributes.amount,
          report_quote_id: editQuoteResponseJson.data[0].attributes.report_quote_id,
          created_at: editQuoteResponseJson.data[0].attributes.created_at,
          updated_at: editQuoteResponseJson.data[0].attributes.updated_at,
          tax: editQuoteResponseJson.data[0].attributes.tax,
          invoice_category_name: editQuoteResponseJson.data[0].attributes.invoice_invoice_category_name
        };

        const newData = [
          ...data.slice(0, startIndex),
          newDescriptionData,
          ...data.slice(startIndex, endIndex),
          ...data.slice(endIndex),
        ];

        return {
          ...table,
          data: newData
        };
      } else {
        return table;
      }

    });
    let parentTableData = this.state.parentTableData;
    parentTableData[this.state.activeTab].tablesData = updatedTablesData;

    this.setState({ tablesData: updatedTablesData, parentTableData: parentTableData });
  }

  deleteDescriptionApiResponseHandle = (editQuoteResponseJson: string) => {
    if (editQuoteResponseJson) {
      const updatedData = this.state.editquoteComparisonData.filter((item: any) => (!this.state.selected.includes(item.id)))
      this.setState({ editquoteComparisonData: updatedData, selected: [], deleteModalState: false }, () => {
        // this.subTotalPrice()
      })
    }
  }

 
  updateEditquoteApiResponseHandle = (editQuoteResponseJson: EditResponseInterface) => {
    if (editQuoteResponseJson.data) {
      this.setState((prevState) => ({
        creteReportData: {
          ...prevState.creteReportData,
          updated_table_data: this.state.parentTableData,
          saveBtnUpdatedResponse: editQuoteResponseJson.data.attributes.invoice,
        },
      }))
      
      const UpdatedInvoiceDetailInfo = this.state.parentTableData.map((UpdatedInvoiceDetail: EditResponseInterface) => {
        return {
          ...UpdatedInvoiceDetail,
        }
      })

      this.setState({
        parentTableData: UpdatedInvoiceDetailInfo
      })

      const message: Message = new Message(getName(MessageEnum.NavigationMessage))

     
      !this.props.location?.state?.invoiceScan ? 
      message.addData(
        getName(MessageEnum.NavigationTargetMessage),
        this.props.history.push('/report/create-report', {
          navigateId: this.props.location.state.value,
          creteReportData: this.state.creteReportData,
          updated_table_data: this.state.parentTableData,
          saveBtnUpdatedResponse: editQuoteResponseJson.data.attributes.invoice,
          newUpdatedDataArr: this.state.parentTableData,
          quotedetail:this.state.quotedetail,
          quotestate:this.state.quotestate,
        })
      ) : this.props.history.push("/invoicescanresult")

      message.addData(getName(MessageEnum.NavigationPropsMessage), this.props)
      const raiseMessage: Message = new Message(
        getName(MessageEnum.NavigationPayLoadMessage)
      );
      raiseMessage.addData(getName(MessageEnum.SessionResponseData), {
        data: editQuoteResponseJson.data.attributes,
      });
      message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
      this.send(message);


      if (this.state.apiCallIndex < this.state.parentTableData.length - 1) {
        return this.setState({ apiCallIndex: this.state.apiCallIndex + 1 }, () => {
          this.editQuoteDetailApi()
        })
      }
      // this.setState({ editQuoteDetail: editQuoteResponseJson.data.quote[0], editquoteComparisonData: editQuoteResponseJson.data.quote_description, selected: [] })
    }
  }

  deleteQuoteDetailApiResponseHandle = (editinvoiceResponseJson: string) => {
    if (editinvoiceResponseJson) {
      const allUpdatedTableItems:any = []
      const updatedTableData = this.state.tablesData.map((deleteData: any) => {
        const updatedData = deleteData.data.filter((item: any) => !this.state.deleteItemsArr.includes(item.id));
        allUpdatedTableItems.push(...updatedData)
        const subtotal = updatedData.reduce((acc: number, item: TableDataItem) => acc + parseFloat(item.amount), 0);
        return {
          ...deleteData,
          data: updatedData,
          subtotalValue: subtotal,
          selected: []
        };
      });

      let parentTableData = this.state.parentTableData;
      parentTableData[this.state.activeTab].tablesData = updatedTableData;

      if(allUpdatedTableItems.length === 0){
        this.setState({
          saveBtnClick:false
        })
      }

      this.updateInvoicePayload({...this.state.editQuoteDetail,invoice_subtotal:this.state.calculateValue, total_price: this.state.totalValue,})

      this.setState({ tablesData: updatedTableData, parentTableData,allUpdatedTableItems }, () => {
        this.calculateAllDataTotal()
      });
    }
  }

  handleDeleteSegmentBox = () => {
    this.setState({ addNewSegmentState: false, addItemBox: false });
  }

  handleInvoiceCategoryChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, columnId: number) => {
    const addCategoryValue = event.target.value;

    this.setState((prevState) => {
      const isDuplicate = prevState.tablesData.some(
        (categoryData: {invoice_category_name:string}) => {
          return  categoryData.invoice_category_name ? categoryData.invoice_category_name.toLowerCase() == addCategoryValue.toLowerCase() :false }
      );
      const updatedTablesData: TableDescription[] = prevState.tablesData.map((categoryData: TableDescription, index: number) => {
        if (index+1 === columnId) {

          const updatedData: TableDataItem[] = categoryData.data.map((item: TableDataItem | undefined) => {
            if (item) {
              return {
                ...item,
                invoice_category_name: addCategoryValue,
                categoryErrorMessage: this.duplicateValue(isDuplicate),
              };
            }
            return item;
          }).filter(Boolean) as TableDataItem[];
          return {
            ...categoryData,
            invoice_category_name: addCategoryValue,
            data: updatedData,
            categoryErrorMessage: this.duplicateValue(isDuplicate),
          };

        }
        return  {
          ...categoryData,
          categoryErrorMessage: this.duplicateValue(categoryData.invoice_category_name == addCategoryValue),
        };
      });

      const hasCategory = updatedTablesData.some((categoryData: TableDescription) => categoryData?.invoice_category_name?.length > 0);

      const copyParentTableData = [...this.state.parentTableData];

      const updatedParentTableData = copyParentTableData.map((item,index)=>{
        if(index === this.state.activeTab){
         return {
          ...item,
          tablesData:updatedTablesData
         }

        }

        return item
      })

      return {
        tablesData: updatedTablesData,
        addItemBox: hasCategory,
        categoryErrorMessage: hasCategory ? "" : "Segment/Category is required",
        parentTableData:updatedParentTableData
      };


    });

    this.setState({
      categoryValue: addCategoryValue
    })

  }

  duplicateValue = (valueDuplicate:boolean) =>{
   return valueDuplicate ? "Segment/Category Name Already Exists" : "";
  }

  addNewSegmentHandler = () => {
    const { categoryDescBox, addCategory, tablesData, parentTableData, activeTab, addNewSegmentState, addItemBox } = this.state;
    const categoryData = {
      id: categoryDescBox.length + 1,
      categoryName: addCategory,
    };
    const newDescData: TableDescription = {
      id: tablesData.length + 1,
      invoice_category_name: "",
      content: "No items in this Segment/Category",
      data: [],
      pagination: {
        id: tablesData.length + 1,
        page: 1,
        rowsPerPage: 10,
      },
      warningTableBox: false,
      selected: [],
      subtotalValue: this.props.location.state.segmentTotal1,
      categoryErrorMessage: "",
      addNewSegmentState: true,
      categoryBox: false
    };


    const updateTablesData = tablesData.map((segmentItem) => {
      const isCurrentSegmentEmpty = (segmentItem.invoice_category_name== undefined)||(segmentItem.invoice_category_name === "");
      return{
      ...segmentItem,
      categoryErrorMessage: addNewSegmentState && isCurrentSegmentEmpty ? "Segment/Category is required" : segmentItem.categoryErrorMessage,
      categoryBox: !isCurrentSegmentEmpty,
      addNewSegmentState:isCurrentSegmentEmpty ? true : segmentItem.addNewSegmentState,
      }
    });
    const shouldAddNewSegment = !tablesData.some(segmentItem => segmentItem.invoice_category_name === ""||segmentItem.invoice_category_name ==undefined);
    const newTablesData = shouldAddNewSegment ? [...updateTablesData, newDescData] : updateTablesData;


    let updatedParentTableData = [...parentTableData];

    if (updatedParentTableData[activeTab]) {
      updatedParentTableData[activeTab] = {
        ...updatedParentTableData[activeTab],
        addNewSegmentState: true,
        categoryBox: true,
        tablesData: newTablesData,
      }
    }

    this.setState({
      tablesData: newTablesData,
      parentTableData: updatedParentTableData,
      categoryErrorMessage: "",
      addNewSegmentState: true,
      categoryBox: true,
      categoryDescBox: addItemBox ? [...categoryDescBox, categoryData] : categoryDescBox,
    }, () => {
      if (newDescData.data.length === 0) {
        this.setState(prevState => ({
          tableDataBox: !prevState.tableDataBox,
        }));
      }
    });
  }
  handleRowItemClick = (event: React.MouseEvent<unknown>, columnId: number, itemId: string) => {
    const { checked }: any = event.target;

    this.setState((prevState: any) => {
      const updatedTablesData = prevState.tablesData.map((tableItems: any) => {
        if (tableItems.id === columnId) {
          const updatedSelected = checked
            ? [...tableItems.selected, itemId]
            : tableItems.selected.filter((selectedId: string) => selectedId !== itemId);

          return {
            ...tableItems,
            selected: updatedSelected
          };
        }
        return tableItems;
      });

      return { tablesData: updatedTablesData };
    });
  };

  handleTaxfieldChange = (event: React.ChangeEvent<HTMLInputElement>) => {

    let parentTableData = this.state.parentTableData;
    if (parentTableData && parentTableData[this.state.activeTab]) {
      parentTableData[this.state.activeTab].taxSwitch = !this.state.itemTaxData
    }
    this.setState({ itemTaxData: !this.state.itemTaxData, parentTableData }, () => {
      if (this.state.itemTaxData === false) {
        const newTableDataArr = [...this.state.tablesData]
        newTableDataArr.forEach(item => {
          item.data.forEach((dataItem: any) => {
            dataItem.tax = null;
            dataItem.amount = (dataItem.ord_quantity * parseFloat(dataItem.price)).toFixed(2);
          });
        });
        this.setState({
          tablesData: newTableDataArr
        });
      }
    })




  }

  handleSelectAllItemClick = (event: React.ChangeEvent<HTMLInputElement>, tableId: number) => {
    const { checked } = event.target;
    const { tablesData } = this.state;

    const updatedTablesData = tablesData.map((tableValue) => {
      if (tableValue.id === tableId) {
        return {
          ...tableValue,
          selected: checked ? tableValue.data.map((item: any) => item.id) : []
        };
      }
      return tableValue;
    });

    this.setState({ tablesData: updatedTablesData });
  };


subTotalPrice = (itemId: number) => {

  let parentData =  this.state.tablesData.find(parent =>
    parent.data.some(child => child.id === itemId)
  );
  const columnData = parentData?.data;

  const subtotal = columnData?.reduce((acc: number, item: any) => {
    return acc + item.amount; 
  }, 0);

  const updatedTablesData = this.state.tablesData.map((table) => {
    const hasMatchingId = table.data.some(child => child.id === itemId);
  
    if (hasMatchingId) {
      return {
        ...table,
        subtotalValue: subtotal,
      };
    }
    return table;
  });

  this.setState(prevState => {
    const updatedParentTableData = { ...prevState.parentTableData };
    updatedParentTableData[prevState.activeTab].tablesData = updatedTablesData;
    return {
      tablesData: updatedTablesData as any,
    };
  });
};






  calculateAllDataTotal = () => {

    const total = this.state.tablesData?.reduce((acc, table) => {
      return acc + Number(table.subtotalValue || 0)
    }, 0);

    const taxTotal = Number(this.totalTax());
    const sAndHCharges = Number(this.state.editQuoteDetail?.invoice_s_and_h_charges || 0);
    const otherCharges = Number(this.state.editQuoteDetail?.invoice_other_charges || 0);
    const discount = Number(this.state.editQuoteDetail?.discount || 0);
    const totalValue = Number((taxTotal + total + sAndHCharges + otherCharges - discount).toFixed(2));

    let parentTableData = this.state.parentTableData;

    if (!parentTableData[this.state.activeTab]) {
      parentTableData[this.state.activeTab] = {};
    }

    parentTableData[this.state.activeTab].totalValue = totalValue;
    parentTableData[this.state.activeTab].calculateValue = total;

    this.setState({ totalValue: totalValue, calculateValue: total, parentTableData });
  }

  totalTax = () => {
    const total = this.state.calculateValue;
    const taxAmount = Number(this.state.editQuoteDetail?.invoice_tax || 0);
    return taxAmount.toFixed(2)
  }

  onDragEnd = (result: any) => {
    if (!result.destination) return;
    const { source, destination } = result;

    const { tablesData } = this.state;
    const selectedItems: any[] = [];
    tablesData.forEach(table => {
      if (table.selected?.length > 0) {
        const selected = table.data.filter((item: any) => table.selected.includes(item.id));
        selectedItems.push(...selected);
      }
    });


    if (source.droppableId !== destination.droppableId) {
      const sourceColumnIndex = parseInt(source.droppableId);
      const destColumnIndex = parseInt(destination.droppableId);

      const updatedTablesData = [...tablesData];

      const sourceColumn = { ...updatedTablesData[sourceColumnIndex], selected: [] };
      const destColumn = updatedTablesData[destColumnIndex];

      const sourceItems = [...sourceColumn.data];
      const destItems = [...(destColumn?.data || [])];

      selectedItems.forEach(selectedItem => {
        const index = sourceItems.findIndex((item: any) => item.id === selectedItem.id);
        if (index !== -1) {
          sourceItems.splice(index, 1);
        }
      });

      destItems.splice(destination.index, 0, ...selectedItems);

      const updatedDestItems = destItems.map((item) => ({
        ...item,
        invoice_category_name: this.state.categoryValue
      }));


      updatedTablesData[sourceColumnIndex] = { ...sourceColumn, data: sourceItems };
      updatedTablesData[destColumnIndex] = { ...destColumn, data: updatedDestItems };

      let parentTableData = this.state.parentTableData;
      parentTableData[this.state.activeTab].tablesData = updatedTablesData;


      this.setState({
        tablesData: updatedTablesData,
        parentTableData
      }, () => {
        updatedTablesData.forEach(table => {
          const subtotal = table.data.reduce((acc: number, item: any) => acc + Number(item.amount), 0);
          table.subtotalValue = subtotal;
        });

        let parentTableData = this.state.parentTableData;
        parentTableData[this.state.activeTab].tablesData = updatedTablesData;

        this.setState({ tablesData: updatedTablesData, parentTableData, categoryValue: this.state.categoryValue }, () => {
          this.calculateAllDataTotal()
        });
      });
    }
  }




  handlePageChange = (event: any, page: any, columnId: any) => {
    const updatedTablesData = [...this.state.tablesData];
    updatedTablesData[columnId].pagination.page = page;
    this.setState({ tablesData: updatedTablesData });
  }

  handleEditQuoteDetail = (field: string, floatValue: unknown) => {

    let newValue = floatValue;

    if (field === "date" || field === "invoice_shipped_date") {
      if (!this.isValidDate(newValue)) {
        newValue = null
      }
    }

    this.setState({
      editQuoteDetail: {
        ...this.state.editQuoteDetail,
        [field]: newValue,
      },
      editQuoteDetailPayload:{
      ...this.state.editQuoteDetail,
        [field]: newValue,
        invoice_subtotal: this.state.calculateValue,
        total_price: this.state.totalValue,
      }

    }, () => {
      this.calculateAllDataTotal()
      this.updateEditQuoteDetailNewValue()
    })
  }

  handleEditQuoteDescription = (value: unknown, columnId: number, itemId: number, field: string) => {
    const { tablesData } = this.state;

  
    const valueEvent = this.extractValue(value);

    const updatedData = this.updateTableData(tablesData, columnId, itemId, field, valueEvent);

    let parentTableData = this.state.parentTableData;
    parentTableData[this.state.activeTab].tablesData = updatedData;

    this.setState({ tablesData: updatedData, parentTableData,segmentColumnId:columnId-1 }, () => {
      this.subTotalPrice(columnId)
      this.calculateAllDataTotal()
      this.updateAmountsSaveBtnPayload()
      this.updateInvoicePayload({...this.state.editQuoteDetail,invoice_subtotal:this.state.calculateValue, total_price: this.state.totalValue,})
    });

  };

  extractValue = (value:unknown)=>{
    return (value as { target?: { value: string } })?.target ? (value as { target?: { value: string } })?.target?.value : value;
  }

  updateTableData = (
    tablesData: any[],
    columnId: number,
    itemId: number,
    field: string,
    valueEvent: any
  ) => {
    return tablesData.map((table: any) => {
      if (table.id === columnId) {
        let subtotal = 0;
        const updatedItems = table.data.map((item: any) => {
          if (item.id === itemId) {
            const updatedAmount = {
              ...item,
              [field]: valueEvent,
            };
  
            if (field === "order_unit" || field === "amount") {
              const priceBeforeTax1 = updatedAmount.ord_quantity * updatedAmount.price;
              const taxAmount1 = (priceBeforeTax1 * updatedAmount.tax || 0) / 100;
              updatedAmount.amount = (priceBeforeTax1 + taxAmount1).toFixed(2);
              subtotal = parseFloat(updatedAmount.amount);
              return updatedAmount;
            } else {
              const priceBeforeTax = updatedAmount.ord_quantity * updatedAmount.price;
              const taxAmount = (priceBeforeTax * updatedAmount.tax || 0) / 100;
              updatedAmount.amount = (priceBeforeTax + taxAmount).toFixed(2);
              subtotal = parseFloat(updatedAmount.amount);
              return updatedAmount;
            }
          }
  
          
          subtotal = table.data.reduce((acc: number, item: any) => {
            return acc + parseFloat(item.amount);
          }, 0);
  
          return item;
        });
  
        return {
          ...table,
          data: updatedItems,
          subtotalValue: subtotal,
        };
      }
      return table;
    });
  };
  

  editQuoteDetailApi = () => {
    const hasEmptyCategory = this.state.tablesData.some(segmentItem => segmentItem.invoice_category_name ==undefined || segmentItem.invoice_category_name === "");
    const hasDuplicateCategory = this.state.tablesData
      .map(segmentItem => segmentItem.invoice_category_name?.toLowerCase())
      .some((categoryName, index, array) => array.indexOf(categoryName) !== index);
      const invoiceAddNewSegment = this.state.tablesData.find(segmentItem => segmentItem.id === this.state.activeTab+1);
      if((hasEmptyCategory && invoiceAddNewSegment?.addNewSegmentState) || (this.props.location?.state?.invoiceScan && hasEmptyCategory && invoiceAddNewSegment?.invoice_category_name) || hasDuplicateCategory ){
      const updateTablesData = this.state.tablesData.map(segmentItem => {

        if ((segmentItem.invoice_category_name=="" ||segmentItem.invoice_category_name ==undefined )&& segmentItem.addNewSegmentState) {
          return {
            ...segmentItem,
            categoryErrorMessage: "Segment/Category is required"
          }
        } else if (this.state.tablesData.filter(item => item.invoice_category_name.toLowerCase() === segmentItem.invoice_category_name.toLowerCase()).length > 1) {
          return {
            ...segmentItem,
            categoryErrorMessage: "Segment/Category Name Already Exists"
          };
        }else{
            return {
              ...segmentItem,
              categoryErrorMessage: ""
            }
       }
      })
    
        this.setState({
          tablesData: updateTablesData
        })
        
        return
      }
    const header = {
      "Content-Type": configJSON.categoryApiContentType,
      "token": this.getToken()
    };


    
    let invoice_descriptions = this.state.tablesData?.map((categoryData: any) => {
      return categoryData.data.map((item: any) => {
        return {
          ...item,
          invoice_category_subtotal: categoryData.subtotalValue
        };
      });
    })

   

    const invoiceDescriptions = this.state.parentTableData.flatMap((item: { tablesData: any[];editQuoteDetail:any }) => 
      item.tablesData?.flatMap((table: { data: any[]; invoice_category_name: any; }) => 
        table.data.map((dataItem: any) => ({
          ...dataItem,
          invoice_category_name: table.invoice_category_name || '',
          report_invoice_id: dataItem.report_invoice_id || item.editQuoteDetail.id 
        }))
      )
    );
   

    let data ={
      "invoice":this.state.invoicesSaveBtnPayload,
      "invoice_descriptions":invoiceDescriptions
    }


    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.updateQuoteDetailApiId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.editInvoiceSaveButton}${this.state.editQuoteDetail?.report_id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(data)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.editQuoteDetailAPIMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
   
  }

  handleDate = () => {
    this.setState({ typeOfFocused: "date" })
  }

  deleteOpenModal = () => {
    this.setState({ deleteModalState: !this.state.deleteModalState })
  }

  deleteOpenTableItemModal = (itemId: string) => {
    this.setState({ deleteModalState: !this.state.deleteModalState, deleteId: itemId })
  }

  deleteCloseModal = () => {
    this.setState({ deleteModalState: false })
  }

  deleteHandler = () => {
    const selectedIds1 = this.state.tablesData.map(idVal => idVal.selected)
    const hasCheckedValues = selectedIds1.some((ids)=>ids.length>0)
    let flattenedArray = hasCheckedValues ?  selectedIds1.reduce((acc, curr) => acc.concat(curr), []) : [this.state.deleteId]

    this.setState({deleteItemsArr:flattenedArray})
    const header = {
      "Content-Type": configJSON.categoryApiContentType,
      "token": this.getToken()
    };

    const deleteDocumentrequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.deleteQuoteDetailApiId = deleteDocumentrequestMessage.messageId;

    deleteDocumentrequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.invoiceDeleteApi}[${flattenedArray}]`
    );

    deleteDocumentrequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpDeleteType
    );

    deleteDocumentrequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    )

    runEngine.sendMessage(deleteDocumentrequestMessage.id, deleteDocumentrequestMessage);

    return true;
  }

  DeleteItemsHandler = () => {

    const selectedIds1 = this.state.tablesData.map(idVal => idVal.selected)
    const httpBody = {
      ids: selectedIds1[0]
    };
    const header = {
      "Content-Type": configJSON.categoryApiContentType,
      "token": this.getToken()
    };

    const deleteDocumentrequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.deleteDescriptionApiId = deleteDocumentrequestMessage.messageId;

    deleteDocumentrequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.deleteSegmentAPI
    );

    deleteDocumentrequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpDeleteType
    );

    deleteDocumentrequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    )
    deleteDocumentrequestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    )

    runEngine.sendMessage(deleteDocumentrequestMessage.id, deleteDocumentrequestMessage);

    return true;
  }

  addNewItemRow = (columnId: number) => {
    this.setState({
      columnIdState: columnId
    })   

    const header = {
      redirect: 'follow',
      "token": this.getToken()
    };


    const invoiceCategoryName = this.state.tablesData[columnId]?.invoice_category_name


    let firstSagmentFormData = new FormData();

    
    firstSagmentFormData.append('report_invoice_id', this.state.reportInvoiceId);
    firstSagmentFormData.append('description[]', ' ');
    firstSagmentFormData.append('ord_quantity[]', ' ');
    firstSagmentFormData.append('order_unit[]', ' ');
    firstSagmentFormData.append('price[]', ' ');
    firstSagmentFormData.append('amount[]', ' ');
    firstSagmentFormData.append('tax[]', ' ');
    firstSagmentFormData.append('part_number[]', ' ');
    firstSagmentFormData.append('invoice_category_name',invoiceCategoryName);

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.addNewItemApiId = requestMessage.messageId;


    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.invoiceAddNewItemAPI
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      firstSagmentFormData
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }

  cancelEditHandler = () => {
    this.props.location?.state?.invoiceScan ? this.props.history.goBack(): this.props.history.push('/report/create-report', { navigateId: 3, creteReportData: this.state.creteReportData,cancelData:true })
  }

  handleChangeTab = (e: any, newValue: any) => {
    let { parentTableData } = this.state
    const newUpdatedDataArr = [...parentTableData];
    let newTableData = parentTableData[newValue]?.tablesData
    let editQuoteDetail = parentTableData[newValue]?.editQuoteDetail
    let totalValue = parentTableData[newValue]?.totalValue;
    let calculateValue = parentTableData[newValue]?.calculateValue;
    let itemTaxData = parentTableData[newValue]?.taxSwitch;
    let addNewSegmentState = parentTableData[newValue]?.addNewSegmentState;
    let categoryBox = parentTableData[newValue]?.categoryBox;

    const reportInvoiceId = this.state.parentTableData[newValue].editQuoteDetail.id

    this.setState({
      activeTab: newValue,
      tablesData: newTableData,
      editQuoteDetail,
      totalValue,
      calculateValue,
      itemTaxData,
      addNewSegmentState,
      newUpdatedDataArr,
      categoryBox,
      segmentColumnId:0,
      reportInvoiceId
    }, () => {
      this.calculateAllDataTotal()

    });
  };

  deleteInvoiceCategoryModal = (columnId: number) => {

    if(!(columnId===1 && this.state.tablesData.length>1)){
      this.setState({ deleteModalState: !this.state.deleteModalState, categoryInvoiceColumnId: columnId })
    }
    
    
  }

  deleteInvoiceCategory = () => {
    const { categoryInvoiceColumnId, tablesData } = this.state;


  let updatedTablesData = tablesData.map((table,index)=>{
    return {
      ...table,
      id: index+1
    }
  })



    if (categoryInvoiceColumnId !== 1) {
      updatedTablesData = updatedTablesData.map(tableItem => {
        if (tableItem.id === 1) {
          return {
            ...tableItem,
            QuoteAddNewSegmentState: false,
          };
        }
        return tableItem;
      }).filter(tableItem => tableItem.id !== categoryInvoiceColumnId);
    }else{    
      const objectIndex = updatedTablesData.findIndex((item)=>item.id === categoryInvoiceColumnId)
      if(objectIndex !== -1){
      const updateObject = {...tablesData[objectIndex], invoice_category_name: "",addNewSegmentState:false,data: tablesData[objectIndex].data.map(dataItem => ({
        ...dataItem,
        invoice_category_name: ""
      }))}
      updatedTablesData[objectIndex] = updateObject;
      this.setState({
        addNewSegmentState:false
      })
  }}


    updatedTablesData.forEach((table: any) => {
      const subtotal = table.data.reduce((acc: any, item: any) => acc + Number(item?.amount || 0), 0);
      table.subtotalValue = subtotal;
    });

    this.setState({ tablesData: updatedTablesData }, () => {
      this.calculateAllDataTotal();
    });
  };


  isValidDate(dateString: any) {

    const regexYYYYMMDD = /^\d{4}-\d{2}-\d{2}$/;
    const regexMMDDYYYY = /^\d{2}-\d{2}-\d{4}$/;


    if (regexYYYYMMDD.test(dateString) || regexMMDDYYYY.test(dateString)) {
      return true;
    } else {
      return false;
    }

  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>, snapshot?: SS | undefined): void {
    if (prevState.editQuoteDetail.invoice_tax !== this.state.editQuoteDetail.invoice_tax) {
      const newTaxPercentage = (this.state.editQuoteDetail?.invoice_tax * 100) / this.state.calculateValue;
      this.setState(prevState => ({
        editQuoteDetail: {
          ...prevState.editQuoteDetail,
          invoice_tax_percent: newTaxPercentage.toFixed(2),
        }
      }))
    }

    if(prevState.editQuoteDetail.invoice_tax_percent !== this.state.editQuoteDetail.invoice_tax_percent){
      const newTaxAmount = (this.state.editQuoteDetail.invoice_tax_percent * this.state.calculateValue)/100;
      this.setState(prevState => ({
        editQuoteDetail: {
          ...prevState.editQuoteDetail,
          invoice_tax: newTaxAmount.toFixed(2),
        }
      }))

    } 

    if(prevState.calculateValue !== this.state.calculateValue){
      this.updateAmountsSaveBtnPayload()
    };   
    


    
  }

  updateEditQuoteDetailNewValue = () => {
    const { activeTab, parentTableData, editQuoteDetail,editQuoteDetailPayload } = this.state;

  
    this.setState({
      parentTableData: parentTableData.map((item:any, index:number) => {
        if (index === activeTab) {
          return {
            ...item,
            editQuoteDetail: editQuoteDetail
          };
        }
        return item;
      })
    });

    this.updateInvoicePayload(editQuoteDetailPayload)

  }

  updateInvoicePayload = (editQuoteDetailPayload:any) => {
    this.setState((prevState: { invoicesSaveBtnPayload?: any; }) => {
      const { invoicesSaveBtnPayload = [] } = prevState;
  
      const existingIndex = invoicesSaveBtnPayload.findIndex(
        (payload: { id: any; }) => payload.id === editQuoteDetailPayload.id
      );
  
      let updatedInvoicePayload;
      if (existingIndex !== -1) {
        updatedInvoicePayload = [
          ...invoicesSaveBtnPayload.slice(0, existingIndex),
          editQuoteDetailPayload,
          ...invoicesSaveBtnPayload.slice(existingIndex + 1),
        ];
      } else {
        updatedInvoicePayload = [...invoicesSaveBtnPayload, editQuoteDetailPayload];
      }
  
      return {
        invoicesSaveBtnPayload: updatedInvoicePayload,
      };
    });
  };

  updateAmountsSaveBtnPayload = ()=>{
    const { parentTableData, invoicesSaveBtnPayload } = this.state;


    const updatedInvoiceSaveBtnPayload = invoicesSaveBtnPayload.map((invoice: { id: any; }) => {
      const matchingTableData = parentTableData.find(
        (tableData: { editQuoteDetail: { id: any; }; }) => tableData.editQuoteDetail.id === invoice.id
      );

      if (matchingTableData) {
        return {
          ...invoice,
          invoice_subtotal:matchingTableData.calculateValue,
          total_price: matchingTableData.totalValue,
        };
      }
      return invoice;
    });

    this.setState({ invoicesSaveBtnPayload: updatedInvoiceSaveBtnPayload });
  }




  
  // Customizable Area End
}
