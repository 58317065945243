// Customizable Area Start
import React from "react";
import {
    Box,
    Typography,
    Button,
    TextField,
    Theme, DialogTitle,
    Dialog,
    DialogContent,
    IconButton,
    InputAdornment,
    Breadcrumbs,
    MenuItem,
    Select,
    OutlinedInput,
    FormControlLabel,
    FormGroup,
    Checkbox
} from "@material-ui/core";
import { styled } from "@material-ui/styles";
import { createTheme, withStyles, withTheme } from "@material-ui/core/styles";
import Header from "../../landingpage/src/Header.web";
import ProfileMainController, { Props } from "./ProfileMainController.web";
import CancelIcon from '@material-ui/icons/Cancel';
import ErrorIcon from '@material-ui/icons/Error';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import VisibilityIcon from '@material-ui/icons/Visibility';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { NavLink } from "react-router-dom";
import moment from "moment";


// Customizable Area End


// Customizable Area Start
export const theme = createTheme({});
// Customizable Area End

//Customizable Area Start

const TextFeildLoginStyle = styled(TextField)({
    '& input': {
        paddingTop: '6px',
        paddingBottom: '6px',
        height: '35px',
        width: '100%',
        backgroundColor: '#fef8f3',
    },
    '& .MuiFormLabel-root[data-shrink="false"]': {
        transform: 'translate(14px, 16px) scale(1)',
    },
    '& .MuiInputLabel-outLined.MuiInputLabel-shrink': {
        transform: "translate(12px, -6px) scale(0.70)"
    }

});

const MainLogoutButton = styled(Button)({
    "&.MuiButton-root": {
        borderRadius: "4px",
        border: "1px solid #292946",
        color: "#292946",
        width: '150px',
        textTransform: 'capitalize',
        marginTop: '3%'
    }
})

const LogoutButton = styled(Button)({
    "&.MuiButton-root": {
        borderRadius: "4px",
        border: "1px solid #292946",
        color: "#292946",
        width: '50%',
        textTransform: 'capitalize',
        padding: '1.5% 0px'
    }
})

const DeleteButton = styled(Button)({
    "&.MuiButton-root": {
        borderRadius: "4px",
        color: "#fff",
        background: "#ff00009e",
        width: '20%',
        textTransform: 'capitalize',
        padding: '1.5% 0px'
    }
})

const DeleteOutlinedButton = styled(Button)({
    "&.MuiButton-root": {
        borderRadius: "4px",
        border: "1px solid #ff0000cc",
        color: "#ff0000cc",
        width: '50%',
        textTransform: 'capitalize',
        padding: '1.5% 0px'
    }
})

const CancelButtonStyle = styled(Button)({
    "&.MuiButton-root": {
        borderRadius: "4px",
        border: "1px solid #292946",
        color: "#292946",
        width: '50%',
        textTransform: 'inherit',
        padding: '1.5% 0px'
    }
})

const LogoutButtonStyle = styled(Button)({
    "&.MuiButton-root": {
        borderRadius: "4px",
        color: "#fff",
        background: "#292946",
        width: '50%',
        textTransform: 'inherit',
        padding: '1.5% 0px'
    }
})

export const SettingsBreadCrumb = (props: any) => {
    return (<>
        <Box>
            <Breadcrumbs
                separator={">"}
                aria-label="breadcrumb"

            >
                <NavLink activeStyle={{ textDecoration: 'none' }} color="inherit" style={webStyle.headerLinkStyle} to="/DashboardWeb">
                    Dashboard
                </NavLink>
                <NavLink color="inherit" style={webStyle.headerLinkStyle} to="/profile"> Profile</NavLink>
                <Typography style={webStyle.headerLinkColorStyle}>Settings</Typography>
            </Breadcrumbs>
        </Box>
    </>)

}

export const PermanentDeleteDialog = (props: any) => {
    const { handleClose, handleOpen, deleteUserAccount, showOldPassword, handleClickShowOldPassword, handleReasonChange, reasonValue, reasonItems, oldPassword, handleChange, passwordErrMsg, isChecked, errors, handleCheckboxChange } = props

    return (
        <>
            <Dialog fullWidth maxWidth='xs' style={{ padding: '10px' }} data-testid="dialogOpenId" onClose={handleClose} open={handleOpen}>
                <DialogTitle style={{ color: 'black' }}>
                    <Box style={{ display: 'flex' }}>
                        <CancelIcon htmlColor="#ff0000cc" fontSize="large" />
                        <Typography style={{ fontSize: '18px', width: '100%', textAlign: 'center', fontWeight: 500 }}>Permanently delete account</Typography>
                    </Box>
                </DialogTitle>
                <DialogContent>
                    <Box>
                        <Box style={webStyle.messageBoxStyle}>
                            <Typography>By deleting your account, we will remove all of your data, such as your profile, projects, reports and uploaded files. You will have 14 days to stop this process. On Day 15 we will delete you account and this cannot be undone.</Typography>
                        </Box>
                        <Box sx={webStyle.labelBoxStyle}>
                            <label style={webStyle.labelName}>Current Password</label>
                            <TextFeildLoginStyle
                                name="old_password"
                                id="name"
                                data-testid="nameInputId"
                                variant="outlined"
                                placeholder="Enter password"
                                value={oldPassword}
                                onChange={handleChange}
                                error={passwordErrMsg?.length > 0}
                                helperText={passwordErrMsg}
                                type={showOldPassword ? 'text' : 'password'}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handleClickShowOldPassword}
                                                edge="end"
                                            >
                                                {showOldPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Box>
                        <Box sx={webStyle.labelBoxStyle}>
                            <label style={webStyle.labelName}>What is the main reason you are deleting your account?</label>
                            <Select
                                style={{ width: '100%' }}
                                labelId="demo-simple-select-disabled-label"
                                id="demo-simple-select-disabled"
                                input={<OutlinedInput />}
                                onChange={handleReasonChange}
                                // placeholder="Select a reason"
                                value={reasonValue}
                                MenuProps={{
                                    anchorOrigin: {
                                        vertical: "bottom",
                                        horizontal: "left"
                                    },
                                    transformOrigin: {
                                        vertical: "top",
                                        horizontal: "left"
                                    },
                                    getContentAnchorEl: null
                                }}
                                IconComponent={ExpandMoreIcon}
                                data-testid={"projectTestID"}
                            >
                                <MenuItem style={{ background: 'white', color: 'gray' }} disabled value="-1">
                                    <em style={{ background: 'white', color: 'gray', fontStyle: 'initial' }}>Select a reason</em>
                                </MenuItem>
                                {reasonItems?.map((item: any, index: any) => {
                                    return (
                                        <MenuItem key={index} style={{ background: 'white', color: 'black',whiteSpace:"pre-wrap",wordBreak:"break-word",maxWidth:"390px" }} data-testid="menuQuotesPlaceholderId" value={item.id}>
                                            <Typography style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>{item.reason}</Typography>
                                        </MenuItem>
                                    )
                                })}
                            </Select>
                        </Box>
                        <Box sx={webStyle.labelBoxStyle}>
                            <label style={webStyle.labelName}>We are sorry to see you go. Please explain why you are leaving to help us improve.</label>
                            <TextFeildLoginStyle
                                name="feedback"
                                id="name"
                                onChange={handleChange}
                                data-testid="nameInputId"
                                variant="outlined"
                                type="text"
                                placeholder="Feedback"
                            />
                        </Box>
                        <Box>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={isChecked} onClick={handleCheckboxChange} />} label="Yes, I want to permanently delete my account and all my data." />
                            </FormGroup>
                            { errors && <Typography color="error">{errors}</Typography>}
                        </Box>
                    </Box>
                    <Box sx={webStyle.textButtonBoxStyle}>
                        <LogoutButton variant='outlined' onClick={handleClose}>Cancel</LogoutButton>
                        <DeleteOutlinedButton data-testId="activeBtn1" disabled={passwordErrMsg?.length > 0} variant='outlined' onClick={deleteUserAccount}>Delete Account</DeleteOutlinedButton>
                    </Box>
                </DialogContent>
            </Dialog>
        </>
    )
}

export const ConfirmationDialog = (props: any) => {
    const { handleClose, handleOpen, handleLogout } = props

    return (
        <>
            <Dialog style={{ padding: '10px' }} data-testid="dialogOpenId" onClose={handleClose} open={handleOpen}>
                <DialogTitle style={{ color: 'black' }}>
                    Are you sure you want to log out?
                </DialogTitle>
                <DialogContent>
                    <ErrorIcon style={{ textAlign: 'center', width: '100%', marginBottom: '3%' }} fontSize="large" htmlColor="orange" />
                    <Box sx={webStyle.textButtonBoxStyle}>
                        <LogoutButtonStyle variant='contained' onClick={handleClose}>Cancel</LogoutButtonStyle>
                        <CancelButtonStyle variant='outlined' onClick={handleLogout}>Logout</CancelButtonStyle>
                    </Box>
                </DialogContent>
            </Dialog>
        </>
    )
}

export const AccountDeletedDialog = (props: any) => {
    const { handleClose, handleOpen, handleLandingPageRedirection } = props

    return (
        <>
            <Dialog style={{ padding: '10px' }} data-testid="dialogOpenId" onClose={handleClose} open={handleOpen}>
                <DialogTitle style={{ color: 'black', width: '100%', textAlign: 'center' }}>
                    Account deleted
                </DialogTitle>
                <DialogContent>
                    <CancelIcon style={{ width: '100%', textAlign: 'center', marginBottom: '3%' }} htmlColor="#ff0000cc" fontSize="large" />
                    <Typography style={{ width: '100%', textAlign: 'center' }}>You have <span style={{ color: 'orange' }}>14 days (until {moment().add(14, 'days').format('MMMM DD, YYYY')})</span> to recover your account. Thereafter it will be permanently deleted.</Typography>
                    <Box sx={webStyle.textButtonBoxStyle}>
                        <LogoutButtonStyle variant='contained' onClick={handleClose}>Get back my account</LogoutButtonStyle>
                        <CancelButtonStyle variant='outlined' onClick={handleLandingPageRedirection}>Leave My invoice check</CancelButtonStyle>
                    </Box>
                </DialogContent>
            </Dialog>
        </>
    )
}

//Customizable Area End
class Settings extends ProfileMainController {
    constructor(props: Props) {
        super(props);
    }
    // Customizable Area Start
    // Customizable Area End
    render() {
        // Customizable Area Start
        const { handleSaveData, navigation } = this.props;
        // Customizable Area End
        return (
            // Customizable Area Start
            <>
                <Header navigation={navigation} handleSaveData={handleSaveData} history={this.props.history} />
                <SettingsBreadCrumb />
                <AccountDeletedDialog handleLandingPageRedirection={this.handleLandingPageRedirection} handleOpen={this.state.isAccountDeleteModalOpen} handleClose={this.handleProfileRedirection} />
                <ConfirmationDialog handleLogout={this.handleLogout} handleOpen={this.state.isConfirmationDeleteModalOpen} handleClose={this.handleModalClose} />
                <PermanentDeleteDialog  data-testId="activeBtn" passwordErrMsg={this.state.passwordErrMsg} handleChange={this.handleChange} oldPassword={this.state.personalDetails.old_password} reasonItems={this.state.reasonItems} reasonValue={this.state.reasonValue} handleReasonChange={this.handleReasonChange} handleClickShowOldPassword={this.handleClickShowOldPassword} showOldPassword={this.state.showOldPassword} deleteUserAccount={this.deleteUserAccount} handleOpen={this.state.isPermanentDeleteModalOpen} handleClose={this.handleModalClose} isChecked={this.state.isChecked} errors={this.state.errors} handleCheckboxChange={this.handleCheckboxChange} />
                <div style={webStyle.settingsWrapperWhite}>
                    <Box style={webStyle.accountBoxStyle}>
                        <Typography style={webStyle.fontStyle}>Settings</Typography>
                    </Box>
                    <Box style={webStyle.personalDetailsBoxStyle}>
                        <Box style={webStyle.accountHeadingStyle}>
                            <Typography style={webStyle.fontStyle}>Delete account</Typography>
                            <Typography style={webStyle.subHeadStyle}>After deleting your account permanently, your account will remain deactivated for <span style={{ color: 'orange' }}>14 days</span>. You will be able to recover it in that period if you change your mind.</Typography>
                            <DeleteButton variant="contained" onClick={this.handleOpenPermanentDeleteModal}>Delete account</DeleteButton>
                        </Box>
                    </Box>
                </div>
                <MainLogoutButton variant="outlined" onClick={this.handleOpenConfirmationDeleteModal}>Logout</MainLogoutButton>
            </>
            // Customizable Area End
        );
    }
}

// Customizable Area Start

const webStyle = {
    fontStyle: {
        fontWeight: 500,
        fontSize: '18px',
        color: 'black',
        paddingBottom: '3%'
    },
    messageBoxStyle: {
        padding: "2%",
        border: "1px solid red",
        background: "#ff00001c",
        marginBottom: '3%',
        borderRadius: '4px'
    },
    headerLinkStyle: {
        textDecoration: "underline",
        color: '#87888F',
        fontWeight: 500,
        '&:focus, &:hover, &:visited, &:link, &:active': {
            textDecoration: 'none'
        }
    },
    headerLinkColorStyle: {
        color: "orange",
        fontWeight: 500
    },
    subHeadStyle: {
        fontWeight: 500,
        fontSize: '16px',
        color: 'black',
        paddingBottom: '3%'
    },
    textButtonBoxStyle: {
        gap: '3%', width: '100%', display: 'flex', justifyContent: 'center', padding: '5% 0'
    },
    userBox: {
        width: '150px',
        height: '150px',
        borderRadius: '100px',
        margin: 'auto',
        overflow: 'hidden',
        background: '#e3e3e3',
        display: 'flex',
        alignContent: 'center',
        justifyContent: 'center'
    },
    accountHeadingStyle: {
        padding: '3%',
    },
    accountBoxStyle: {
        display: 'flex',
        justifyContent: 'space-between',
        padding: '3%',
    },
    personalDetailBox: {
        display: 'flex',
        padding: '3%',
    },
    personalDetailModalBox: {
        display: 'flex',
        gap: '8%',
        padding: '3%',
    },
    settingsWrapperWhite: {
        backgroundColor: 'white',
        borderRadius: '20px',
        boxShadow: '#d2d2d2 0px 0px 8px',
        paddingBottom: '3%',
        marginTop: '3%'
    },
    personalDetailsBoxStyle: {
        backgroundColor: 'white',
        borderRadius: '10px',
        margin: '0 3%',
        border: '1px solid #808080b3',
        boxShadow: '#d2d2d2 0px 0px 8px',
    },
    labelBoxStyle: {
        width: '100%',
        marginBottom: '15px',
        display: 'flex',
        flexDirection: 'column',
    },
    labelName: {
        marginBottom: 7,
        fontWeight: 500,
        width: '100%',
        color: 'black',
        fontSize: '16px',
    },
}

const styles = (theme: Theme) => ({
    logoStyle: {
        width: "100%",
        backgroundRepeat: "no-repeat",
        backgroundSize: "contain",
        borderRadious: "50%",
        backgroundPosition: "center",
        [theme.breakpoints.up("sm")]: {
            height: "10rem"
        },
        [theme.breakpoints.up("md")]: {
            height: "14rem"
        },
        [theme.breakpoints.up("xs")]: {
            height: "10rem"
        },
        [theme.breakpoints.up("lg")]: {
            height: "25rem"
        }
    }
});

export { Settings }
export const SettingsStyle = withTheme(withStyles(styles)(Settings));



// Customizable Area End
