// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
import React from "react";
// import pdfjs from 'pdfjs-dist';

export const configJSON = require("./config");

export interface Props {
  // Customizable Area Start
  navigation: any;
  id: string;
  classes: any;
  history: any;
  location: any;
  handleSaveData: () => {},
  // iframeRef: React.RefObject<any>
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  quoteComparisonData: any,
  invoiceComparisonData: any,
  quotesTypeTabs: any,
  invoiceTypeTabs: any,
  allTypeDocumentList: any,
  value: any
  numPagesQuote: any,
  pageNumberQuote: any,
  numPagesInvoice: any,
  pageNumberInvoice: any,
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  // Customizable Area End
}

export default class QuoteInvoiceController extends BlockComponent<
  Props,
  S,
  SS
> {
  constructor(props: Props) {
    super(props);
    console.log(this.props.location)
   
    this.state = {
      // Customizable Area Start
      quoteComparisonData: this.props.location.state.quoteComparisonData,
      invoiceComparisonData: this.props.location?.state?.idState?.lenghInvoice,
      quotesTypeTabs: ["Quote"],
      invoiceTypeTabs: ["Invoice_1", "Invoice_2"],
      allTypeDocumentList: [{ project_name: "anc", quote_pdf: "abc" }],
      value: 0,
      numPagesQuote: 3,
      numPagesInvoice: 3,
      pageNumberQuote: 1,
      pageNumberInvoice: 1,

      // Customizable Area End
    };

    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    console.log(this.subScribedMessages)

  }










  

  onDocumentLoadSuccess = ({numPagesQuote}: any) => {
    
  
  };

  loadSuccess=({numPagesInvoice}:any)=>{
    
  }


  handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    this.setState({ value: newValue })
  }

  handlePrevPageQuote = () => {
    const { pageNumberQuote } = this.state;
    if (pageNumberQuote > 1) {
      this.setState({ pageNumberQuote: pageNumberQuote - 1 });
    }
  };

  handleNextPageQuote = () => {
    const { pageNumberQuote, numPagesQuote } = this.state;
    if (pageNumberQuote < numPagesQuote) {
      this.setState({ pageNumberQuote: pageNumberQuote + 1 });
    }
  };
  handlePrevPageInvoice = () => {
    const { pageNumberInvoice } = this.state;
    if (pageNumberInvoice > 1) {
      this.setState({ pageNumberInvoice: pageNumberInvoice - 1 });
    }
  };

  handleNextPageInvoice = () => {
    const { pageNumberInvoice, numPagesInvoice } = this.state;
    if (pageNumberInvoice < numPagesInvoice) {
      this.setState({ pageNumberInvoice: pageNumberInvoice + 1 });
    }
  };

  handleGenerateQuote = () => {
    this.props.history.push('/report/quotescanresult', this.state.quoteComparisonData)
  }

  handleGenerateInvoice = () => {
    this.props.history.push('/report/invoicescanresult', this.state.quoteComparisonData)
  }


}

// Customizable Area End
