// Customizable Area Start
import React from "react";
import {
    Box,
    Typography,
    Grid,
    Modal,
    Tabs,
    Tab,
    FormControl,
    Select,
    MenuItem,
    OutlinedInput,
    IconButton,
    Button,
    Link,
} from "@material-ui/core";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import DateRange from '@material-ui/icons/DateRange';
import DateRangeDialog from "../../landingpage/src/DateRangeDialog.web";
import { createTheme, withStyles } from "@material-ui/core/styles";
import { deleteIcon, downloadIcon, editIcon, filterIcon, closeIcon, deleteMsgIcon } from "./assets";
import DocumentsController, { Props, TabPanelProps, configJSON } from "./DocumentsController.web";
import { styled } from "@material-ui/styles";
import Header from "../../landingpage/src/Header.web";
import moment from "moment";
// Customizable Area End


// Customizable Area Start
export const theme = createTheme({});
// Customizable Area End

//Customizable Area Start
export const DocumentsTabPanel = (props: TabPanelProps) => {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}
export const documentsa11yProps = (index: number) => {
    return {
        id: `vertical-tab-${index}`,
        'aria-controls': `vertical-tabpanel-${index}`,
    };
}


export const DocumentsOcrTabPanel = (props: TabPanelProps) => {

    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
            className="topBorderTabBox"
        >
            {value === index && (
                <Box p={1}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

export const documentOcra11yProps = (indextab: any) => {
    return {
        id: `simple-tab-${indextab}`,
        'aria-controls': `simple-tabpanel-${indextab}`,
    };
}

const StyledButton = styled(Button)({
    textTransform: 'capitalize',
    fontSize: '0.75rem',
    fontWeight: 500,
});

//Customizable Area End
class Documents extends DocumentsController {
    constructor(props: Props) {
        super(props);
    }
    // Customizable Area Start
    // Customizable Area End
    render() {
        // Customizable Area Start
        const { classes, navigation, handleSaveData } = this.props
        // Customizable Area End
        return (
            // Customizable Area Start
            <>
                <DateRangeDialog
                    data-testid="dateRangeDailogId"
                    handleDateRangeModal={this.handleDateRangeOpenModal}
                    hasDateRange={this.state.hasDateRangeModal}
                    handleDateRangeValue={this.handleSelectDateRangeValue}
                    dateRangeValue={this.state.dateRangeData}
                    navigation={this.props.navigation}
                />
                <>
                        <Header navigation={navigation} handleSaveData={handleSaveData} history={this.props.history} />
                        <Box className={classes.documentsBox}>
                            <Tabs 
                                value={this.state.valueTab} 
                                onChange={this.handleTabChange} 
                                className={classes.documentsInnerBox}
                                data-testid="tabChangeid"
                            >
                                
                                {
                                    this.state.documentsTypeTabs.map((item: any) => {
                                        return (
                                            <Tab
                                                label={
                                                    <>
                                                        <Box className={classes.documentscurveBox}>
                                                            <Box className={classes.documentscurveInnerBox}></Box>
                                                        </Box>
                                                            <Typography variant="h6">{item}</Typography>
                                                            <Typography>You have {this.state.allTypeDocumentList.length} documents</Typography>
                                                        <Box className={classes.documentscurveRightBox}>
                                                            <Box className={classes.curveRightdocumentsInnerBox}></Box>
                                                        </Box>
                                                    </>
                                                }
                                                {...documentOcra11yProps(0)}
                                                className={classes.documentsTitletBox}
                                            />
                                        )
                                    })
                                }
                                
                            </Tabs>
                            <DocumentsOcrTabPanel index={0} value={this.state.valueTab}>
                                
                                <Box className={classes.projectTabBox}>
                                    {
                                        this.state.allTypeDocumentList?.length > 0 ?
                                        <>
                                            <Tabs
                                                orientation="vertical"
                                                variant="scrollable"
                                                value={this.state.value}
                                                onChange={this.handleChange}
                                                data-testid="handleChange"
                                                className={classes.allprojectListtabs}
                                            >
                                                {
                                                    this.state.allTypeDocumentList.map((item: any) => {
                                                        return (
                                                            <Tab
                                                                label={
                                                                    <>
                                                                        <Typography variant="h6">{item?.project_name?.toLowerCase() || item?.project?.toLowerCase()}</Typography>
                                                                        <Typography>{item.table_name || item.document_type}</Typography>
                                                                    </>
                                                                }
                                                                {...documentsa11yProps(0)}
                                                                className={classes.tabDocumentsMenuBox}
                                                            />
                                                        )
                                                    })
                                                }
                                            </Tabs>

                                            <Box className={classes.documentstabpanelBox}>
                                                <Box className={classes.filterDocsBox} onClick={this.handleOpenFilterModal} data-testid="filterModalid">
                                                    <img src={filterIcon} alt="fliter" />
                                                    <Typography>{configJSON.filterItemButton}</Typography>
                                                </Box>
                                                {
                                                    this.state.allTypeDocumentList.map((item: any, index: number) => {
                                                        return (
                                                            <>
                                                                <Box className={classes.tabpanelDocsInnerBox} data-testid="tabpanelid">
                                                                    <DocumentsTabPanel value={this.state.value} index={index}>
                                                                        <Grid container key={index} className={classes.tabpanelDocsContentBox}>
                                                                            <Grid item xs={6}>
                                                                                <Box className={classes.documentabPanelTitle}>
                                                                                    <Typography variant="h6">
                                                                                        {item?.project_name?.toLowerCase()|| item?.project?.toLowerCase()} <span>{item.table_name || item.document_type}</span>
                                                                                    </Typography>
                                                                                </Box>
                                                                                <Typography>{moment(item.date).format("D MMMM YYYY, hh:mm A")}</Typography>
                                                                            </Grid>
                                                                            <Grid item xs={6} className={classes.functionDocsBox}>
                                                                                <Box className={classes.iconDocsBox}>
                                                                                    <Link href={item.quote_pdf || item.invoice_pdf || item.pdf_url} download="test_quote.pdf"><img src={downloadIcon} alt="downloadImg" /></Link>
                                                                                    <img src={deleteIcon} alt="deleteicon" onClick={() => this.handleOpenDeleteModal(item.quotes_id || item.invoice_id)} data-testid={`deleteModalid-${index}`}/>
                                                                                </Box>
                                                                            </Grid>
                                                                        </Grid>
                                                                        <Box>
                                                                            <iframe src={item.quote_pdf || item.invoice_pdf || item.pdf_url} className={classes.documentsPdfBox} />
                                                                        </Box>
                                                                    </DocumentsTabPanel>
                                                                </Box>
                                                            </>
                                                        )
                                                    })
                                                }
                                            </Box>
                                        </>
                                    : 
                                    <Typography className={classes.recordContent}>{configJSON.recordcontent}</Typography>
                                    }
                                </Box> 
                                
                            </DocumentsOcrTabPanel>

                            <DocumentsOcrTabPanel index={1} value={this.state.valueTab}>
                                <></>
                            </DocumentsOcrTabPanel>
                            
                        </Box>

                </>
                <Modal open={this.state.filterModal} onClose={this.handleCloseFilterModal}>
                    <Box className={classes.filterModalBox}>
                        <Box className={classes.filterInnerBox}>
                            <Typography variant="h5">{configJSON.filterItemButton}</Typography>
                            <img src={filterIcon} alt="fliter" />
                        </Box>
                        <Grid container spacing={2} className={classes.projectSearchBox}>
                            <Grid item lg={6} md={6} className={classes.selectVendorBox}>
                                <FormControl className={classes.formControlBox} size="small">
                                    <Box className={classes.vendorTitleBox} id="demo-select-small-label">
                                        <Typography variant="h6">{configJSON.vendorTitle}</Typography>
                                    </Box>
                                    <Select
                                        labelId="demo-select-small-label"
                                        id="demo-select-small"
                                        IconComponent={ExpandMoreIcon}
                                        input={<OutlinedInput />}
                                        displayEmpty
                                        value={this.state.vendorValue}
                                        MenuProps={{
                                            anchorOrigin: {
                                                vertical: "bottom",
                                                horizontal: "left"
                                            },
                                            transformOrigin: {
                                                vertical: "top",
                                                horizontal: "left"
                                            },
                                            getContentAnchorEl: null
                                        }}
                                        onChange={e => this.selectVendorValue(e)}
                                        data-testid={"vendorTestID"}
                                    >
                                        <MenuItem disabled value="">{configJSON.placeholdervendorTitle}</MenuItem>
                                        {this.state.vendorOptionValue.map((item: any, index: number) => {
                                            return (
                                                <MenuItem key={item} value={item} data-testid={`selectMenuItem-${index}`}>{item}</MenuItem>
                                            )
                                        })}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item lg={6} md={6} className={classes.selectVendorBox}>
                                <FormControl className={classes.formControlBox} size="small">
                                    <Box className={classes.vendorTitleBox} id="demo-select-small-label">
                                        <Typography variant="h6">{configJSON.projectTitle}</Typography>
                                    </Box>
                                    <Select
                                        labelId="demo-select-small-label"
                                        id="demo-select-small"
                                        IconComponent={ExpandMoreIcon}
                                        input={<OutlinedInput />}
                                        displayEmpty
                                        value={this.state.projectValue}
                                        MenuProps={{
                                            anchorOrigin: {
                                                vertical: "bottom",
                                                horizontal: "left"
                                            },
                                            transformOrigin: {
                                                vertical: "top",
                                                horizontal: "left"
                                            },
                                            getContentAnchorEl: null
                                        }}
                                        onChange={e => this.selectProjectValue(e)}
                                        data-testid={"projectTestID"}
                                    >
                                        <MenuItem disabled value="">{configJSON.placeholderprojectTitle}</MenuItem>
                                        {this.state.ProjectOptionValue.map((item: any, index: number) => {
                                            return (
                                                <MenuItem key={item} value={item} data-testid={`selectMenuItem-${index}`}>{item}</MenuItem>
                                            )
                                        })}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item lg={12} md={12} className={classes.selectDateRangeBox}>
                                <Typography variant="h6">{configJSON.dateRangeTitle}</Typography>
                                <Box
                                    data-testid="btnDateRangePicker"
                                    onClick={this.handleDateRangeOpenModal}
                                    style={{ width: '100%', padding: '0 10px', marginTop: '15px', marginBottom: '30px' }}
                                >
                                    <StyledButton className={classes.buttonStyleCal}>
                                        <IconButton>
                                            <DateRange />
                                        </IconButton>
                                        {`${this.state.dateRangeData[0].startDate.toDateString()}-${this.state.dateRangeData[0].endDate.toDateString()}`}
                                    </StyledButton>
                                </Box>
                            </Grid>
                            <Box className={classes.cancelApplyButtonBox}>
                                <Button onClick={this.handleCloseFilterModal} data-testid="cancelButton">{configJSON.cancelButton}</Button>
                                <Button onClick={this.handleProjectsSearch} data-testid="applyButton">{configJSON.applyButton}</Button>
                            </Box>
                        </Grid>
                    </Box>
                </Modal>

                <Modal
                    open={this.state.deleteModal}
                    onClose={this.handleCloseDeleteModal}
                >
                    <Box className={classes.documentDeletemodalBox}>
                        <Box className={classes.closeIconBox}>
                            <img src={closeIcon} alt="close" onClick={this.handleCloseDeleteModal} />
                        </Box>
                        <Box className={classes.deleteMsgIconBox}>
                            <img src={deleteMsgIcon} alt="upload"/>
                        </Box>
                        <Typography>Are you sure you want to delete?</Typography>
                        <Box className={classes.deleteButtonBox}>
                            <Button onClick={this.handleCloseDeleteModal} data-testid="modalclosedId"> No </Button>
                            <Button onClick={this.handleDeleteDocument} data-testid="yesmodalclosedId"> Yes </Button>
                        </Box>
                    </Box>
                </Modal>
            </>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
export const documentsPageStyle = (theme: any) => ({
    documentsBox: {
        background: '#fff',
        padding: '0 0 0 0',
        border: '1px solid #e0802e',
        borderTopLeftRadius: '15px',
        borderTopRightRadius: '15px',
        '& .topBorderTabBox':{
            borderTop: '1px solid #e0802e'
        }
    },
    documentsInnerBox: {
        background: '#fcf7f2',
        padding: '10px 0 0px 30px',
        position: 'relative' as 'relative',
        width: '100%',
        borderTopLeftRadius: '15px',
        borderTopRightRadius: '15px',
        top: 0,
        overflow: 'visible',
        '& .MuiTabs-scroller.MuiTabs-fixed':{
            overflow: 'visible !important'
        },
        '& button':{
            '& h6': {
                color: '#000',
                fontWeight: '700',
            },
            '& p':{
                color: '#000',
                textTransform: 'initial',
            },
            '&.Mui-selected':{
                overflow: 'visible',
                '& h6': {
                    color: '#e0802e',
                    fontWeight: '700'
                },
                '& p':{
                    color: '#e0802e',
                    textTransform: 'initial',
                },
            },
            '&:nth-child(2) p':{
                display: 'none'
            },
        },
        '& span.MuiTabs-indicator': {
            display: 'none'
        },
        '& span': {
            textTransform: 'capitalize',
        },
        '& div:nth-child(2)': {
            margin: '0 9px 0 16px'
        },
    },
    documentsTitletBox: {
        minWidth: '350px',
        textAlign: 'center' as 'center',
        padding: '10px 20px',
        '&.Mui-selected': {
            background: '#ffffff',
            border: '1px solid #e0802e',
            borderTopLeftRadius: '18px',
            borderTopRightRadius: '18px',
            borderBottom: 'none',
            position: 'relative',
            top: '2px',
        },
    },
    projectTabBox: {
        display: 'flex',
        justifyContent: 'flex-start',
        background: '#ffffff',
        position: 'relative' as 'relative',
        width: '100%',
    },
    allprojectListtabs: {
        border: '1px solid #eee',
        margin: '10px',
        borderRadius: '10px',
        maxWidth: '25%',
        flex: '0 0 25%',
        padding: '15px 0',
        '& button': {
            borderBottom: '1px solid #eee',
            padding: '18px 0',
            opacity: 1,
            maxWidth: '320px',
            [theme.breakpoints.down('1610')]: {
                maxWidth: '300px',
            },
            '& h6': {
                fontWeight: '500',
                color: '#131324',
                textTransform: 'capitalize' as 'capitalize',
                [theme.breakpoints.down('1610')]: {
                    fontSize: '18px'
                },
            },
            '&.Mui-selected': {
                borderRadius: '10px',
                background: '#e0802e',
                padding: '15px',
                '& h6': {
                    fontWeight: '600',
                    color: '#ffffff',
                    textTransform: 'capitalize' as 'capitalize',
                    [theme.breakpoints.down('1610')]: {
                        fontSize: '18px'
                    },
                },
                '& p': {
                    color: '#ffffff',
                    fontWeight: '500',
                    textTransform: 'capitalize' as 'capitalize',
                },
                [theme.breakpoints.down('1610')]: {
                    padding: '9px',
                }
            }
        },
        '& span': {
            alignItems: 'unset',
            textTransform: 'capitalize !important',
        },
        '& span.MuiTabs-indicator': {
            display: 'none'
        },
        '& div:nth-child(2)': {
            margin: '0 9px 0 16px'
        }

    },
    tabDocumentsMenuBox: {
        textAlign: 'left' as 'left',
        textTransform: 'capitalize' as 'capitalize',
    },
    documentstabpanelBox: {
        maxWidth: '73%',
        border: '1px solid #000',
        margin: '15px 0',
        borderRadius: '8px',
        flex: '0 0 73%',
        padding: '15px',
        position: 'relative' as 'relative',
    },
    documentscurveBox: {
        height: '31px',
        width: '31px',
        background: '#fcf7f2',
        position: 'absolute' as 'absolute',
        bottom: 0,
        left: '-31px',
    },
    documentscurveInnerBox: {
        border: '1px solid #e0802e',
        height: '30px',
        width: '31px',
        borderBottomRightRadius: '15px',
        borderTop: 'none',
        borderLeft: 'none'
    },
    documentscurveRightBox: {
        height: '33px',
        width: '30px',
        background: '#fcf7f2',
        position: 'absolute' as 'absolute',
        bottom: 0,
        right: '-30px',
        zIndex: 99,
    },
    curveRightdocumentsInnerBox: {
        border: '1px solid #e0802e',
        height: '32px',
        width: '30px',
        borderBottomLeftRadius: '15px',
        borderTop: 'none',
        borderRight: 'none'
    },
    documentabPanelTitle: {
        color: '#000',
        display: 'flex',
        alignItems: 'center',
        '& h6': {
            textTransform: 'capitalize' as 'capitalize',
            [theme.breakpoints.down('1380')]: {
                fontSize: '16px'
            },
            '& span':{
                fontSize: '18px',
                fontWeight: '400',
                textTransform: 'capitalize' as 'capitalize',
            }
        },
    },
    functionDocsBox: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'end'
    },
    filterDocsBox: {
        border: '1px solid #000',
        borderRadius: '4px',
        padding: '6px 15px !important',
        display: 'inline-block',
        textAlign: 'center' as 'center',
        position: 'absolute' as 'absolute',
        right: '18%',
        top: '23px',
        [theme.breakpoints.down('1380')]: {
            padding: '6px 7px !important',
        },

        '& img': {
            width: '25px',
            height: '25px'
        },
        '& p': {
            display: 'inline-block',
            paddingLeft: '10px',
            color: '#000'
        }
    },
    iconInnerBox: {
        '& img': {
            width: '25px',
            height: '25px'
        }
    },
    iconDocsBox: {
        display: 'inline-block',
        position: 'absolute' as 'absolute',
        top: '27px',
        right: '18px',
        '& img': {
            width: '30px',
            height: '30px',
            marginLeft: '30px',
            [theme.breakpoints.down('1500')]: {
                marginLeft: '10px',
            },
        }
    },
    tabpanelDocsInnerBox: {
        '& div:nth-child(1)': {
            padding: '0',
        },
    },
    documentsPdfBox: {
        height: '100vh',
        display: 'block',
        maxHeight: '100%',
        width: '100%'
    },
    tabpanelDocsContentBox: {
        marginBottom: '10px'
    },

    filterModalBox: {
        width: '46%',
        background: '#fff',
        borderRadius: '10px',
        border: '1px solid #000',
        position: 'absolute' as 'absolute',
        left: '39%',
        top: '26%',
        overflow: 'hidden'
    },

    filterInnerBox: {
        margin: '25px',
        '& h5': {
            color: '#000',
            display: 'inline-block',
            marginRight: '20px',
            fontWeight: 600,
        },
        '& img': {
            width: '25px',
            marginTop: '-3px'
        }
    },

    projectSearchBox: {
        borderTop: '1px solid #ddd',
        padding: '5px 25px 25px',
    },

    formControlBox: {
        width: '100%',
        '& input': {
            border: '1px solid #eae1e1',
            padding: '14px',
            borderRadius: '5px',
        },
        '& fieldset': {
            border: '1px solid #eae1e1',
        },
        '& svg': {
            right: '15px'
        }
    },

    vendorTitleBox: {
        paddingBottom: '10px',
        '& h6': {
            fontWeight: 600,
            color: '#000'
        }
    },

    buttonStyleCal: {
        minHeight: '1.1876em',
        padding: '0px 8px',
        border: 'solid 1px rgba(0, 0, 0, 0.23)',
        width: '100%',
        justifyContent: 'flex-start',
        backgroundColor: 'rgb(223, 128, 45)'
    },
    selectDateRangeBox: {
        '& h6': {
            fontWeight: 600,
            color: '#000'
        }
    },
    cancelApplyButtonBox: {
        textAlign: "right" as "right",
        width: '100%',
        "& button": {
            padding: "15px 60px",
            textTransform: "unset",
            color: "#000",
            fontSize: "20px",
            lineHeight: "22px",

            "&:hover": {
                background: "#e0802e",
                color: "#ffffff"
            },
            "&:first-child": {
                marginRight: "40px"
            }
        }
    },
    recordContent:{
        margin: '10% auto',
        height: '70vh',
    },
    documentDeletemodalBox: {
        padding: "28px 30px 50px",
        background: "#fff",
        width: "560px",
        position: "absolute" as "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        borderRadius: "10px",
        '& p':{
            fontSize: "20px",
            fontWeight: 500,
            color: "#000000",
            textAlign: "center" as "center",
            margin: "25px",
        }
    },

    closeIconBox:{
        textAlign: 'right' as 'right',
        '& img':{
            marginBottom: "10px",
            width: "35px",
            height: "35px"
        }
    },
    
    deleteMsgIconBox:{
        textAlign: 'center' as 'center',
        '& img':{
            width: "100px",
            height: "100px",
        }
    },

    deleteButtonBox:{
        textAlign: 'center' as 'center',
        '& button':{
            border: "1px solid #e0802e",
            width: '100px',
            padding: "15px 0px",
            textTransform: "unset" as "unset",
            color: "#e0802e",
            fontSize: "20px",
            lineHeight: "22px",
            margin: "0 20px",
            '&:hover': {
                background: "#e0802e",
                color: "#fff"
            }
        }
    }
})

export { Documents }
export default withStyles(documentsPageStyle)(Documents)
// Customizable Area End    
