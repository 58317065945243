import React from "react";
import ReportGenerationController, { Props, configJSON } from "./ReportGenerationController.web";
import {
  MenuItem,
  FormControl,
  Select,
  Box,
  Typography,
  OutlinedInput,
  Button,
} from "@material-ui/core";
import { styled } from "@material-ui/styles";
import { createTheme, withStyles } from "@material-ui/core/styles";
import Dropzone from "react-dropzone";
import Header from "../../landingpage/src/Header.web";
import CustomLoader from "../../../framework/src/CustomLoader.web";
// Customizable Area Start
const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});

const StyledUploadFileButton = styled(Button)({
  width: '100%',
  border: '1px solid #e0802e',
  borderRadius: '8px',
  display: "block",
  margin: "0% auto",
  padding: '0px 0px',
  textTransform: 'unset',
  color: '#ffffff',
  fontSize: '20px',
  fontWeight: "bold",
  lineHeight: '22px',
  background: '#e0802e',
  "&.Mui-disabled": {
    background: "#fff",
    color: "#e0802e",
    fontWeight: "600",
  },
  "&.MuiButton-root:hover": {
    background: "#e0802e",
  }
});

export const UploadBox = (props: any) => {
  const { classes, acceptedFile, onDrop } = props;

  return (
    <Dropzone accept={acceptedFile} data-testid="dropzone" onDrop={onDrop}>
      {({ getRootProps, getInputProps }: any) => (
        <div className={classes.UploadButtonWrapper} {...getRootProps()}>
          <input id="input" {...getInputProps()} />
          <StyledUploadFileButton data-testid="browseButton">
            Upload
          </StyledUploadFileButton>
        </div>
      )}
    </Dropzone>
  )
}

const StyledGenerateReportButton = styled(Button)({
  width: '50%',
  border: '1px solid #e0802e',
  borderRadius: '10px',
  display: "block",
  margin: "2% auto",
  padding: '20px 0',
  textTransform: 'unset',
  color: '#ffffff',
  fontSize: '22px',
  fontWeight: "bold",
  lineHeight: '22px',
  background: '#e0802e',
  "&.Mui-disabled": {
    background: "#fff",
    color: "#e0802e",
    fontWeight: "600",
  },
  "&.MuiButton-root:hover": {
    background: "#e0802e",
  }
});
// Customizable Area End

class ReportComparison extends ReportGenerationController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    const { classes, handleSaveData, navigation } = this.props;
    return (
      // Customizable Area Start
      <>
        <Header navigation={navigation} handleSaveData={handleSaveData} history={this.props.history} />
        <Box className={classes.reportNewInnerBoxWrapper}>
          <Box className={classes.innerBoxNewTitleWrapper}>
            <Typography variant="h6">Upload the Quote and Invoice to Match the algorithm</Typography>
          </Box>
          <Box className={classes.selectNewProjectAndUploadWrapperStyle}>
            <FormControl className={classes.formNewSelectWrapper} size="small">
              <Box className={classes.fromNewSelectTitle} id="demo-select-small-label">
                Upload Quotes
              </Box>
              <div className={classes.selectNewDropdownWrapper}>
                <Select
                  labelId="demo-select-small-label"
                  id="demo-select-small"
                  input={<OutlinedInput />}
                  displayEmpty
                  value={this.state.quotesValue}
                  MenuProps={{
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left"
                    },
                    transformOrigin: {
                      vertical: "top",
                      horizontal: "left"
                    },
                    getContentAnchorEl: null,
                  }}
                  onChange={(event) => this.setQuotesValue(event)}
                  data-testid={"projectTestID"}
                >
                  <MenuItem data-testid="menuQuotesPlaceholderId" disabled value="">Choose Quotes</MenuItem>
                  {this.state.quotesData.map((quoteitem: any, index: number) => {
                    return (
                      <MenuItem key={quoteitem} value={quoteitem} data-testid={`selectMenuItem-${index}`}>{quoteitem}</MenuItem>
                    )
                  })}
                </Select>
                <span>Or</span>
                <UploadBox data-testid="quotesUploadBoxID" acceptedFile={this.state.acceptedFileType} classes={classes} onDrop={this.onQuotesUpload} />
              </div>
            </FormControl>
            <FormControl className={classes.formNewSelectWrapper} size="small">
              <Box className={classes.fromNewSelectTitle} id="demo-select-small-label">
                Upload Invoices
              </Box>
              <div className={classes.selectNewDropdownWrapper}>
                <Select
                  labelId="demo-select-small-label"
                  id="demo-select-small"
                  input={<OutlinedInput />}
                  displayEmpty
                  value={this.state.invoicesValue}
                  MenuProps={{
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left"
                    },
                    transformOrigin: {
                      vertical: "top",
                      horizontal: "left"
                    },
                    getContentAnchorEl: null
                  }}
                  onChange={(event) => this.setInvoicesValue(event)}
                  data-testid={"projectInvoiceTestID"}
                >
                  <MenuItem data-testid="menuInvoicesPlaceholderId" disabled value="">Choose Invoices</MenuItem>
                  {this.state.invoicesData.map((invoiceitem: any, index: number) => {
                    return (
                      <MenuItem key={invoiceitem} value={invoiceitem} data-testid={`selectMenuItem-${index}`}>{invoiceitem}</MenuItem>
                    )
                  })}
                </Select>
                <span>Or</span>
                <UploadBox data-testid="invoicesUploadBoxID" acceptedFile={this.state.acceptedFileType} classes={classes} onDrop={this.onInvoiceUpload} />
              </div>
            </FormControl>
          </Box>
          {
            this.state.quotesValue.length > 0 && this.state.invoicesValue.length > 0
              ?
              <StyledGenerateReportButton onClick={this.handleGenerateReport} data-testid={"generateReportId"}>
                Generate Report
              </StyledGenerateReportButton>
              :
              <StyledGenerateReportButton variant="contained" disabled data-testid={"generateReportId"}>
                Generate Report
              </StyledGenerateReportButton>
          }
          {this.state.reportGenerationLoader && <CustomLoader loading={this.state.reportGenerationLoader} />}
        </Box>
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start

export const reportComparisonionStyle = (theme: any) => ({

  reportNewInnerBoxWrapper: {
    margin: '2% 10%',
    border: '1px solid #eee',
    borderRadius: '10px'
  },
  innerBoxNewTitleWrapper: {
    borderBottom: '1px solid #eee',
    padding: '10px'
  },
  selectNewProjectAndUploadWrapperStyle: {
    padding: '20px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'baseline',
    gap: '30px'
  },
  formNewSelectWrapper: {
    width: '100%',
    '& input': {
      border: '1px solid #eae1e1',
      padding: '14px',
      borderRadius: '5px',
    },
    '& fieldset': {
      border: '1px solid #eae1e1',
    }
  },
  fromNewSelectTitle: {
    paddingBottom: '10px',
    fontWeight: 500,
    fontSize: '18px'
  },

  selectNewDropdownWrapper: {
    display: "flex",
    flexDirection: "column" as "column",
    alignItems: "center",
    justifyContent: "center",
    padding: "36px 0",
    borderRadius: "5px",
    backgroundColor: "#ffffff",
    border: "1px dashed rgba(223,128,45,0.45)",
    width: '100%',
    "& .MuiInputBase-root": {
      width: '90%',
      '& .MuiSelect-select': {
        fontSize: '14px',
        fontWeight: 500
      }
    },
    "& h6": {
      fontSize: "16px",
      fontWeight: 500,
    },
    "& span": {
      margin: '10px 0px'
    }
  },
})
export { ReportComparison }
export default withStyles(reportComparisonionStyle)(ReportComparison)
// Customizable Area End
