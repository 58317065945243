// Customizable Area Start
import { applyMiddleware, combineReducers, createStore } from 'redux';
import thunk from 'redux-thunk';
import { notificationReducer } from './NotificationWeb/reducer';


const rootReducer = combineReducers({
  notificationReducer: notificationReducer
});


const store = createStore(rootReducer, applyMiddleware(thunk));

export default store;
// Customizable Area End