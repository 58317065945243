// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
export const configJSON = require("./config");
// Customizable Area End



export interface Props {
  // Customizable Area Start
  openModal: boolean,
  handleClose: () => void,
  DeleteItemsHandler: () => void,
  deleteHandler: () => void,
  deleteCategory: () => void,
  selectedItem: string[]
  classes: any,
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  // Customizable Area End
}

export default class DeleteModalController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.state = {
      // Customizable Area Start
      // Customizable Area End
    };
    // Customizable Area Start
    // Customizable Area End
  }


  // Customizable Area Start
  // Customizable Area End
}

