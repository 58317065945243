import React from 'react'
// Customizable Area Start
import { TextField, Button } from "@material-ui/core"
import { styled } from '@material-ui/styles'
import Alert from '@material-ui/lab/Alert';
import {
    Formik,
    FormikProps,
    Form,

} from 'formik';
import * as Yup from 'yup'
import { View } from 'react-native';
// Customizable Area End


import ForgotAndResetPasswordWebController, { Props } from './ForgotAndResetPasswordWebController'
// Customizable Area Start
export interface MyFormValues {
    email: string;
}
const StyledForgotPasswordButton = styled(Button)({
    background: '#ef7927',
    width: '100%',
    height: "45px",
    textTransform: 'capitalize',
    padding: '16px',
    fontWeight: 600,
    marginTop: '20px',
    borderRadius: '8px',
    boxShadow: 'none',
    '&:hover': {
        background: '#ef7927',
    }

});
const StyledForgotPasswordTextFeild = styled(TextField)({
    '& input': {
        paddingTop: '6px',
        paddingBottom: '6px',
        height: '35px',
    },
    '& .MuiFormLabel-root[data-shrink="false"]': {
        transform: 'translate(14px, 16px) scale(1)',
    },
    '& .MuiFormLabel-root': {
        color: "black"
    },

});
// Customizable Area End
export default class ForgotPasswordWeb extends ForgotAndResetPasswordWebController {
    constructor(props: Props) {
        super(props)
        // Customizable Area Start
        console.log(this.state.email)
        // Customizable Area End
    }
    // Customizable Area Start
    initialValues: MyFormValues = { email: this.state.email };
    onSubmitHandler = (values: any, func: any) => {
        this.setState({ email: values.email })
        this.onSubmitForgotPassword();
        setTimeout(() => {
            if (this.state.email.length == 0) {
                func.resetForm({
                    values: {
                        email: '',
                    }
                })
            }
            func.setSubmitting(false)
        }, 2000)
    }
    // Customizable Area End

    render() {
        // Customizable Area Start

        return (
            <>
                {/* 
        // @ts-ignore */}
                <h3 style={webStyle.forgotHeading}>Forgot Your Password?</h3>
                {/* 
        // @ts-ignore */}

                <p style={webStyle.forgotDetail}>Enter your email below to receive your password reset instructions</p>
                <Formik
                    initialValues={this.initialValues}
                    onSubmit={this.onSubmitHandler}
                    validationSchema={Yup.object().shape({
                        email: Yup.string()
                            .email()
                            .required('Enter valid email id'),
                    })}
                >
                    {(props: FormikProps<MyFormValues>) => {
                        const {
                            values,
                            touched,
                            errors,
                            handleBlur,
                            handleChange,
                            isSubmitting,
                        } = props
                        return (
                            //@ts-ignore
                            <Form translate={undefined}>
                                {this.state.succesAlert ? <Alert style={webStyle.alertStyle} severity='success'>Email has been sent to your email</Alert> : ""}
                                {this.state.apiError ? <Alert severity="error">{this.state.serverSideError ? "Something went Wrong, Please try again later!" : "Invalid email!"}</Alert>
                                    : ""}
                                <ForgetPasswordForm
                                    values={values}
                                    errors={errors}
                                    touched={touched}
                                    handleChange={handleChange}
                                    handleBlur={handleBlur}
                                    isSubmitting={isSubmitting}

                                />
                            </Form>
                        )
                    }}
                </Formik>
                {/* 
        // @ts-ignore */}
                <a href='/login' data-test-id="BackToLoginLink" style={this.state.succesAlert || this.state.apiError ? webStyle.afterforgotAnchorTag : webStyle.forgotAnchorTag}>Back to Login Page</a>
            </>
        )
        // Customizable Area End

    }
}
// Customizable Area Start
export const ForgetPasswordForm = ({
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    isSubmitting,

}: any) => {
    return (
        <>
            {/* 
        // @ts-ignore */}
            <View style={webStyle.forgotTextfieldView}>
                <label style={webStyle.forgotLabelTxt}>Email</label>
                <StyledForgotPasswordTextFeild
                    name="email"
                    id="email"
                    label="Enter your email"
                    data-test-id="txtInputEmail"
                    value={values?.email}
                    variant="outlined"
                    type="email"
                    helperText={
                        errors?.email && touched?.email
                            ? errors?.email
                            : ''
                    }
                    error={
                        errors?.email && touched?.email
                            ? true
                            : false
                    }
                    onChange={handleChange}
                    onBlur={handleBlur}
                />
            </View>

            <StyledForgotPasswordButton
                type="submit"
                variant="contained"
                data-test-id="btnForgotPassword"
                color="secondary"
                disabled={isSubmitting}

            >
                Send
            </StyledForgotPasswordButton>

        </>
    )
}

const webStyle = {
    forgotAnchorTag: {
        position: "absolute",
        bottom: "75px",
        left: "0",
        right: "0",
        margin: "0 auto",
        width: "35%",
        textAlign: "center",
        textDecoration: "none",
        color: '#df802d',
    },
    afterforgotAnchorTag: {
        position: "absolute",
        bottom: "50px",
        left: "0",
        right: "0",
        margin: "0 auto",
        width: "35%",
        textAlign: "center",
        textDecoration: "none",
        color: '#df802d',
    },

    forgotTextfieldView: {
        marginBottom: '12px',
        fontWeight: '600',
    },
    forgotHeading: {
        textAlign: 'center',
        padding: '20px 0',
        color: "#1D1350",
        fontSize: '24px',
        fontWeight: '600',
        marginBottom: '0',
        paddingBottom: '0',
    },
    forgotDetail: {
        textAlign: 'center',
        fontSize: '14px',
        marginBottom: '67px',
    },
    forgotLabelTxt: {
        marginBottom: 7,
        fontWeight: 600,
    },
    alertStyle: {
        marginBottom: "15px"
    }

}
 // Customizable Area End