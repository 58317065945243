import React from "react";
// Customizable Area Start
import "./index.css";
import { CssBaseline } from "@material-ui/core";
// Customizable Area End

import LandingPageControllerWeb, { Props } from "./LandingPageController.web";
import ToolbarWeb from "./ToolbarWeb.web";
import FeaturesofQuotecheck from "./FeaturesofQuotecheck.web";
import HowItWorks from "./HowItWorks.web";
import AboutQuotecheck from "./AboutQuotecheck.web";
import Footer from "./Footer.web";

export default class LandingPageWeb extends LandingPageControllerWeb {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
    // Customizable Area Start
      <>
        <div className="navbar-container">
          <CssBaseline />
          {/* @ts-ignore */}
          <ToolbarWeb navigation={this.props.navigation} />
          {/* @ts-ignore */}
          <FeaturesofQuotecheck />
          {/* @ts-ignore */}
          <HowItWorks />
          {/* @ts-ignore */}
          <AboutQuotecheck />
          {/* @ts-ignore */}
          <Footer navigation={this.props.navigation}/>
        </div>
      </>
    // Customizable Area End
    );
  }
}
