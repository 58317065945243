// Customizable Area Start
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { IBlock } from "../../../framework/src/IBlock";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  // Customizable Area Start
  title: string;
  hasMenu: boolean;
  classes: any;
  navigation: any;
  id: string;
  handleSaveData: (data: any) => void;
  history: any
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  projectData: [],
  archiveData: [],
  reportsData: [],
  projectId: string,
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  // Customizable Area End
}

export default class LibraryController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  projectDataApi: string = "";
  archiveDataApi: string = "";
  reportsDataApi: string = ""
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.state = {
      // Customizable Area Start
      projectData: [],
      archiveData: [],
      reportsData: [],
      projectId: ""
      // Customizable Area End
    };

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage)
    ];

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async componentDidMount() {
    this.projectloadData();
    this.archiveloadData();
    this.reportsloadData();
  }


  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiRequestCallId === this.projectDataApi) {
        this.setState({ projectData: responseJson.projects.data.map((item: { attributes: [] }, i: number) => item.attributes) })
      }
    }
    
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiRequestCallId === this.archiveDataApi) {
        this.setState({ archiveData: responseJson.archives.data.map((item: { attributes: []; }, i: number) => item.attributes) })
      }
    }

    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiRequestCallId === this.reportsDataApi) {
        this.setState({ reportsData: responseJson.reports.data.map((item: { attributes: [] }, i: number) => item.attributes) })
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  projectloadData = () => {

    const header = {
      "Content-Type": configJSON.productApiContentType,
      "token": document.cookie.split("; ").find((row) => row.startsWith("Token="))?.split("=")[1]
    };

    const requestProjectMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.projectDataApi = requestProjectMessage.messageId;

    requestProjectMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.projectsAPiEndPoint
    );

    requestProjectMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestProjectMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );

    runEngine.sendMessage(requestProjectMessage.id, requestProjectMessage);
    return true;
  }

  archiveloadData = () => {

    const header = {
      "Content-Type": configJSON.productApiContentType,
      "token": document.cookie.split("; ").find((row) => row.startsWith("Token="))?.split("=")[1]
    };

    const requestArchiveMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.archiveDataApi = requestArchiveMessage.messageId;

    requestArchiveMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.archivesAPiEndPoint
    );

    requestArchiveMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestArchiveMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );

    runEngine.sendMessage(requestArchiveMessage.id, requestArchiveMessage);
    return true;
  }

  reportsloadData = () => {

    const header = {
      "Content-Type": configJSON.productApiContentType,
      "token": document.cookie.split("; ").find((row) => row.startsWith("Token="))?.split("=")[1]
    };

    const requestReportsMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.reportsDataApi = requestReportsMessage.messageId;

    requestReportsMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.reportsAPiEndPoint
    );

    requestReportsMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestReportsMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );

    runEngine.sendMessage(requestReportsMessage.id, requestReportsMessage);
    return true;
  }
  // Customizable Area End
}
