// Customizable Area Start
import React from "react";
import { NavLink } from "react-router-dom";
import { List, ListItem, ListItemText, Collapse } from "@material-ui/core";
import {
  sidebarLogo,
  invoiceIcon,
  dashboardIcon,
  analyticsIcon,
  reportIcon,
  documentIcon,
  libraryIcon,
  quoteIcon
} from "./assets";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import LayoutController from "./LayoutController.web";

const useStyles: any = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      maxWidth: 360,
      backgroundColor: theme.palette.background.paper
    },
    nested: {
      padding: "2px 0 2px 80px",
      color: "white",
      fontSize: "18px",
      fontWeight: 275,
      fontFamily: "Poppins",
      '& .MuiListItemText-root > .MuiTypography-root': {
        color: 'white !important'
      },
      '&:focus, &:hover, &:visited, &:link, &:active': {
        textDecoration: 'none'
      }
    },
    nestedUnSelected: {
      padding: "2px 0 2px 80px",
      color: "grey",
      fontSize: "18px",
      fontWeight: 275,
      fontFamily: "Poppins",
      '& .MuiListItemText-root > .MuiTypography-root': {
        color: 'grey !important'
      },
      '&:focus, &:hover, &:visited, &:link, &:active': {
        textDecoration: 'none'
      }
    },
    activeNavClass: {
      "& img": {
        filter:"invert(1)"
      }
    },
    naviconHover:{
      width:"80px",
    },
    naviconHovered:{
     width:"80px",
    },
  })
);

export const CollapseMenu = (props: any) => {
  const classes = useStyles();
  return (
    <>
      <Collapse in={props.open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {
            props.subMenuList.map((list: any) => {
              return (
                <ListItem button>
                  <NavLink
                    to={list.subPath}
                    activeStyle={{ textDecoration: 'none' }}
                    className={props.currentPath === list.subPath ? classes.nested : classes.nestedUnSelected}
                  >
                    <ListItemText primary={list.subTitle} />
                  </NavLink>
                </ListItem>
              )
            })
          }
        </List>
      </Collapse>
    </>
  );
};

const MemoCollapse = React.memo(CollapseMenu);

export const SideBarNavLink = (props: any) => {
  const classes = useStyles();
  const { title, handleClick, img, path,onHandle,isDataHovered} = props;
  const { navItem, activeNav, imgIcon,imgIconOnClose,navItemHover}: any = webStyle;
  const { activeNavClass } = classes;

  return (
    <>
      <NavLink
        data-testid="sideBarNavLinkId"
        to={path}
        activeClassName={activeNavClass}
        style={isDataHovered ? navItem : navItemHover}
        activeStyle={activeNav}
        onClick={onHandle}
      >
        <img src={img} style={isDataHovered ?  imgIcon: imgIconOnClose} /> {isDataHovered && `${title}` }
      </NavLink>
    </>
  );
};

export default class Layout extends LayoutController {

  render() {
    if (this.state.isPathValid && this?.state?.token === undefined) {
      return (
        <div style={webStyle.Wrapper}>
          <div style={webStyle.noLayoutPathMainContent}>{this.props.children}</div>
        </div>
      )
    }

    return (
      
      <div style={webStyle.Wrapper}>
        <div style={this.state.ishovered ? webStyle.sidebarOuter:  webStyle.sidebarOuterRemove}  onMouseEnter={this.handleMouseEnter} onMouseLeave={this.handleMouseLeave} data-testid="navbarExpand">
          <div style={webStyle.sidebar}>
            <NavLink to="/DashboardWeb" style={webStyle.navlogo}>
              <img style={{ width: "100%" }} src={sidebarLogo} alt="" />
            </NavLink>
            <List component="nav" aria-labelledby="nested-list-subheader" style={this.state.ishovered  ? webStyle.naviconHovered :  webStyle.naviconHover }>
              <SideBarNavLink
                key={1}
                path="/DashboardWeb"
                title="Dashboard"
                img={dashboardIcon}
                onHandle={this.handleMouseEnter}
                isDataHovered={this.state.ishovered}
              />

              <SideBarNavLink
                key={2}
                path="/projects"
                title="Projects"
                img={libraryIcon}
                onHandle={this.handleMouseEnter}
                isDataHovered={this.state.ishovered }
              />

              {/* <SideBarNavLink
                key={2}
                path="/ocrUpload"
                title="OcrUpload"
                img={libraryIcon}
              /> */}
            </List>
          </div>
        </div>

        <div style={this.state.ishovered  ? webStyle.MainContent :webStyle.MainContentHover}>{this.props.children}</div>
      </div>
    );
  }
}

const webStyle = {
  Wrapper: {
    minHeight: "100vh"
  },
  MainContent: {
    padding: "0px 64px 64px 330px",
    //minHeight: "100vh",
    backgroundColor: "#f4f7fa"
  },
  MainContentHover: {
    padding: "0px 64px 64px 130px",
    //minHeight: "100vh",
    backgroundColor: "#f4f7fa"
  },
  noLayoutPathMainContent: {
    padding: "0px 0px 0px 0px",
  },
  sidebarOuter: {
    width: "300px",
    backgroundColor: "#05052b",
    position: "fixed" as "fixed",
    top: 0,
    left: 0,
    bottom: 0,
    zIndex: 3,
  },
  sidebarOuterRemove:{
    width: "100px",
    backgroundColor: "#05052b",
    // maxWidth: "100px",
    position: "fixed" as "fixed",
    top: 0,
    left: 0,
    bottom: 0,
    zIndex: 3,
  },
  sidebar: {
    // padding: "10px",
    //maxHeight: "calc(100% - 124px)",
    height: "100%",
    overflow: "hidden",
    display: "flex",
    flexDirection: "column" as "column",
    alignItems: "center"
  },
  naviconHover:{
    width:"80px",
  },
  
  naviconHovered:{
  width:"290px",
  marginLeft:"10px"
  },

  activeNav: {
    color: "#030d45",
    textDecoration: "none",
    textTransform: "capitalize",
    backgroundColor: "white",
    margin: "0 0 5px",
    display: "flex",
    borderRadius: "10px",
    "& img": {
      border:"2px solid red",
      // filter:"invert(0)"
    }
  },
  navItem: {
    width: "280px",
    height: "65px",
    borderRadius: "10px",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    margin: "0 0px 5px",
    textDecoration: "none",
    position: "relative" as "relative",
    color: "white",
    fontSize: "25px" as "25px"
  },
  navItemHover: {
    width: "80px",
    height: "65px",
    borderRadius: "10px",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    margin: "0 0px 5px",
    textDecoration: "none",
    position: "relative" as "relative",
    color: "white",
    fontSize: "25px" as "25px"
  },
  navlogo: {
    display: "inline-block",
    verticalAlign: "top",
    margin: "20px auto 50px",
    textDecoration: "none"
  },
  imgIcon: {
    width: "15%", 
    margin: "0px 15px 0px 15px"
  },
  imgIconOnClose:{
    width: "50%", 
    margin: "0px 15px 0px 15px"
  }
};
// Customizable Area End
