import React from "react";
import HeaderController, { Props, configJSON } from "./HeaderController.web";
import SearchIcon from "@material-ui/icons/Search";
import AccountCircle from '@material-ui/icons/AccountCircle';
import SearchFilterDialog from "./SearchFilterDialog.web";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import QuotesUploadNewDialog from "./QuotesUploadNewDialog.web";
import { styled } from "@material-ui/styles";
import CloseIcon from '@material-ui/icons/Close';

import {
  Box,
  Button,
  IconButton,
  Grid,
  FormControl,
  Select,
  MenuItem,
  OutlinedInput,
  TextField,
  Typography,
  Paper,
  CircularProgress,
  Divider
} from "@material-ui/core";
import NotificationPopup from "../../BulkUploading/src/NotificationPopup.web";
import { arrowIcon, globalPdf } from "./assets";

const StyledButton = styled(Button)({
  textTransform: 'capitalize',
  position: 'absolute',
  fontSize: '16px',
  fontWeight: 'bold'
});

const StyledButtonNav = styled(Button)({
  textTransform: 'capitalize',
  fontSize: '0.75rem',
  fontWeight: 500,
});

export default class Header extends HeaderController {
  constructor(props: Props) {
    super(props);
  }


  render() {
    return (
      <div>
        <Box style={{ backgroundColor: 'white', width: 'calc(100% + 94px)', marginBottom: '30px', marginLeft: '-30px', padding: '8px 30px' }}>
          <Grid item xs={12} md={12} lg={12}>
            <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Box style={webStyle.navActionTextSearch} data-testid="searchItem">
              <Box style={webStyle.mainSearchBoxStyle}>
                <Box style={webStyle.searchBoxStyle}>
                  <Box style={webStyle.closeStyle}>
                    <SearchIcon />
                    <Textfield  
                      style={webStyle.textInputStyle}
                      data-testId="searchProjectId"
                      onChange={this.globalSearch}
                      value={this.state.globalData}
                      placeholder="Search..."
                      autoComplete="on"
                       />
                  </Box>
                  <CloseIcon onClick={this.closeModal} data-testId="filterCloseId" style={this.state.globalData != "" ? webStyle.closeBlockStyle : webStyle.closeIconStyle} />
                </Box>
               
                {this.state.globalData != "" && 
                  <Paper style={webStyle.projectSelectList}>
                    <Box style={webStyle.expandStyle}>
                      <Typography style={webStyle.bestTextStyle}>{configJSON.bestMatches}</Typography>
                      <ExpandMoreIcon style={webStyle.expandImageStyle} />
                    </Box>
                    {this.state.isLoading ?
                      <CircularProgress
                        style={{ position: 'absolute', left: '50%', marginTop: '2%' }} /> :
                      <Box style={webStyle.dataItemStyle}>
                        {this.state.globalResponse.data.Project.map((projectName: {
                          id: number;
                          quote_id: string | null;
                          date: string | null;
                          vendor: string | null;
                          project: string;
                          created_at: string;
                          updated_at: string;
                          status: "active" | "inactive";
                          account_id: number | null;
                        }) => {
                          return (
                            <>
                              <CustomBox data-testId="projectDetailsId" onClick={() => {this.projectDetails(projectName.id, projectName.project)}} style={webStyle.searchTextStyle}>
                                <img style={webStyle.globalOffStyle} src={globalPdf} />
                                <Box style={webStyle.globalSearchStyle}>
                                  <Box>
                                    <Typography style={webStyle.projectTextStyle}>{projectName.project}</Typography>
                                    <Typography style={webStyle.projectStyle}>Project</Typography>
                                  </Box>
                                  <img src={arrowIcon} className="arrowIconStyle" />
                                </Box>
                              </CustomBox>
                              <Divider/>
                            </>
                          )
                        })}
                        {this.state.globalResponse?.data?.Report?.filter(report => 
                            report.report_name.toLowerCase().includes(this.state.globalData.toLowerCase())
                        )?.map((reportName: {
                          id: number;
                          report_id: string;
                          date: string;
                          vendor: string;
                          project: string;
                          delta: boolean;
                          created_at: string;
                          updated_at: string;
                          report_name: string;
                          account_id: number;
                          project_name: string;
                        }) => {
                            return (
                              <>
                                <CustomBox data-testId="reportDetailsId" onClick={() => this.reportDetails(reportName.id, reportName.report_name, reportName.project_name)} style={webStyle.searchTextStyle}>
                                  <img style={webStyle.globalOffStyle} src={globalPdf} />
                                  <Box style={webStyle.globalSearchStyle}>
                                    <Box>
                                      <Typography style={webStyle.projectTextStyle}>{reportName.report_name}</Typography>
                                      <Typography style={webStyle.projectStyle}>Report</Typography>
                                    </Box>
                                    <img src={arrowIcon} className="arrowIconStyle"  />
                                  </Box>
                                </CustomBox>
                                <Divider/>
                              </>
                            )
                          }
                         
                        )}
                        {this.state.globalResponse.data.Vendor.map((vendorName) => {
                          const associatedReports = this.state.globalResponse.data.Report.filter(
                          report => report.vendor === vendorName.id.toString()
                         );


                         return (
                            <React.Fragment key={vendorName.id}>
                              {associatedReports.length > 0 ? (
                                associatedReports.map(report => (
                                  <CustomBox key={report.id} style={webStyle.searchTextStyle} onClick={() => this.reportDetails(report.id, report.report_name, report.project_name)}>
                                    <img style={webStyle.globalOffStyle} src={globalPdf} />
                                    <Box style={webStyle.globalSearchStyle}>
                                      <Box>
                                        <Typography style={webStyle.projectTextStyle}>{vendorName?.name.toLowerCase().includes(this.state.globalData.toLowerCase()) ? vendorName.name :vendorName.email}</Typography>
                                        <Box  style={{display:"flex",gap:"5px"}}>
                                           <Typography style={webStyle.projectStyle}>{vendorName?.name.toLowerCase().includes(this.state.globalData.toLowerCase()) ? `Vendor` : `Vendor Email`} {" "}{" "}{" "}-</Typography>
                                          <Typography style={webStyle.projectStyle}>{report.report_name}</Typography>
                                      </Box>
                                      </Box>
                                    
                                      <img src={arrowIcon} className="arrowIconStyle" />
                                    </Box>
                                  </CustomBox>
                                ))
                              ) : (
                                <CustomBox style={webStyle.searchTextStyle}>
                                  <img style={webStyle.globalOffStyle} src={globalPdf} />
                                  <Box style={webStyle.globalSearchStyle}>
                                    <Box>
                                      <Typography style={webStyle.projectTextStyle}>{vendorName?.name.toLowerCase().includes(this.state.globalData.toLowerCase()) ? vendorName.name :vendorName.email}</Typography>
                                      <Typography style={webStyle.projectStyle}>{vendorName?.name.toLowerCase().includes(this.state.globalData.toLowerCase()) ? `Vendor` : `Vendor Email`}</Typography>
                                    </Box>
                                    <img src={arrowIcon} className="arrowIconStyle" />
                                  </Box>
                                </CustomBox>
                              )}
                              <Divider />
                            </React.Fragment>
                          );
                        })}
                      </Box>
                    }
                  </Paper>
                }
              </Box>
              </Box>
              <Box style={webStyle.secondBoxStyle}>
                <div style={webStyle.notificationDivStyle}>
                  <NotificationPopup {...this.props} handleNotificationClick={() => { }} handleNotificationDeleteData={() => { }} deletenotificationMsg={undefined} />
                </div>
                <FormControl size="small">
                  <Select
                    labelId="demo-select-small-label"
                    id="demo-select-small"
                    className={`navActionButtonSelectNormal ${this.state.userActionValue ? 'active' : ''}`}
                    input={<OutlinedInput />}
                    value={this.state.name}
                    label="John Doe"
                    data-testid='selectData'
                    IconComponent={(props) =>
                    (
                      <>
                        {
                          this.state.profileImageUrl ?
                            <div {...props} style={{
                              width:"35px",
                              height:"35px",
                              overflow: 'hidden', top: '6px'
                            }}>
                              <img
                                src={this.state.profileImageUrl}
                                alt="Your Icon"
                                style={{ width: '100%', height: '100%', objectFit: 'cover', borderRadius: '50%' }}
                              />
                            </div> : <AccountCircle {...props} />
                        }</>)
                    }
                    MenuProps={{
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left"
                      },
                      transformOrigin: {
                        vertical: "top",
                        horizontal: "left"
                      },
                      getContentAnchorEl: null
                    }}
                    onOpen={() => this.handleLogout("click")}
                    onClose={() => this.handleLogout("click")}
                  >
                    {this.state.settingOptionsData.map((item: string, index: number) => {
                      return (
                        <MenuItem
                          data-testid={`MenuOptionsID-${index}`}
                          key={item}
                          value={item}
                          onClick={() => this.handleLogout(item)}
                          style={webStyle.menuOptions}
                        >
                          {item}
                        </MenuItem>
                      )
                    })}
                  </Select>
                </FormControl>
              </Box>
            </Box>
          </Grid >
        </Box >
        <QuotesUploadNewDialog
          data-testid="quoteUploadHeaderDialogId"
          files={this.state.files}
          apiError={this.state.apiError}
          status={this.state.status}
          onDrop={this.onDropHeader}
          openDialog={this.state.openQuotesDialog}
          handleCloseDialog={this.handleCloseHeaderDialog}
          isLoading={this.state.isLoading}
          rows={[]}
          title=""
          hasMenu={true}
          handleClickOpenDialog={() => { }}
          handleSaveData={() => { }}
          navigation={this.props.navigation}
          history={this.props.history}
          errMsg={this.state.errMsg}
        />
        <a id="myLink" href="#" style={{ display: 'none' }}></a>
      </div >
    )
  }
}


const webStyle = {
  navIconImageWrapper: {
    height: "65px",
    width: "65px",
    borderRadius: "13px",
    marginLeft: "20px"
  },
  projectSelectList: {
    zIndex: 1,
    borderRadius: '12px',
    height: "300px",
    padding: '20px 30px 30px 30px',
    width: "100%"
  },
  mainSearchBoxStyle: {
    zIndex: 1,
    width: "50%",
    position: "absolute" as "absolute"
  },
  searchBoxStyle: {
    display: 'flex',
    alignItems: 'center',
    borderRadius: '5px',
    padding: "0px 21px",
    height: '48px',
    boxShadow: '0px 2px 30px 0px #BBBBD231',
    backgroundColor: "#fff",
    border: "0.4px solid",
    borderColor: "rgb(184 184 184)",
    justifyContent: "space-between"
  },
  closeStyle: {
    display: 'flex',
    alignItems: 'center',
    width: "100%"
  },
  textInputStyle: {
    width: "100%"
  },
  closeIconStyle: { 
    cursor: "pointer", 
    border: "1px solid #545355", 
    borderRadius: "6px", 
    padding: "4px" ,
    display: "none"
  },
  closeBlockStyle: {
    cursor: "pointer", 
    border: "1px solid #545355", 
    borderRadius: "6px", 
    padding: "4px" ,
    display: "block"
  },
  expandStyle: {
     display: 'flex',
     alignItems: 'center',
  },
  bestTextStyle: {
   color: "#37352FA6",
   fontSize: "12px",
   fontWeight: 700,
   zIndex: 1
  },
  expandImageStyle: {
    width: "30px",
   color: "#37352F59"
  },
  dataItemStyle: {
    overflow: 'auto',
    height: "240px"
  },
  searchTextStyle: {
    display: "flex",
    gap: "10px",
    alignItems: "flex-start",
    padding: "10px 15px",
    "& .searchTextStyle:hover": {
      background: '#37352F',
      borderRadius: "3px"
    },
  },
  globalOffStyle: {
    height: 17,
    width: 14
  },
  globalSearchStyle: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%"
  },
  projectTextStyle: {
    color: "#000",
    fontSize: "15px",
    fontWeight: 400,
    lineHeight: "20px"
  },
  projectStyle: {
    fontSize: "12px",
    lineHeight: "17px",
    fontWeight: 400,
    color: "#37352F80"
  },
  navIconImage: {
    width: "42px",
    height: "43px",
    padding: "10px"
  },
  navActionText: {
    fontSize: "25px",
    fontWeight: 700,
    fontFamily: "Poppins",
    width: "100%",
    textAlign: "center"
  },
  navActionTextSearchInput: {
    borderBottom: 'solid 1px rgb(233 233 233)',
    width: '100%',
    justifyContent: 'flex-start',
    padding: '2px 12px',
    color: '#404040',
    fontSize: '16px',
    borderRadius: '5px',
    boxShadow: '0px 0px 6px #d9d9d9',
  },
  navActionTextSearch: {
    width: '60%',
    '& ::placeholder': {
      color: '#404040 !important',
      opacity: '1 !important',
    },
    '& :-ms-input-placeholder': { 
      color: '#404040 !important',
    },
    '& ::-ms-input-placeholder': {
      color: '#404040 !important',
    },
  },
  navAddButton: {
    left: '-5px',
    zIndex: 9,
    top: '7px',
    background: 'transparent !important',
  },
  secondBoxStyle: {
    marginRight: '5%',
    display: 'flex',
    gap: '10px'
  },
  notificationDivStyle: {
    display: "flex",
    top: "15px",
    position: "relative" as "relative",
    justifyContent: "center"
  },
  navAddButtonActive: {
    left: '-5px',
    zIndex: 9,
    top: '7px',
    color: 'white',
    background: 'transparent !important',
  },
  menuOptions: {
    fontWeight: 700,
    inlineSize: '180px',
    overflow: 'hidden'
  }
}

const Textfield = styled(TextField)({
  marginLeft: "10",
  color: "#05052B",
  fontSize: 14,
  "& .MuiInput-underline::before": {
    borderBottom: "none",
    transition: "none",
  },
  "& .MuiInput-underline::after": {
    borderBottom: "none"
  },
  "& .MuiInput-underline:hover:not(.Mui-disabled)::before": {
    borderBottom: "none"
  },
});

const CustomBox = styled(Box)({
  display: "flex",
  gap: "10px",
  alignItems: "flex-start",
  padding: "10px 15px",
  "&:hover": {
    background: 'rgb(239 239 238)',
    borderRadius: "3px",
    "& .arrowIconStyle": {
      display: "block",
    },
  },
  "& .arrowIconStyle": {
    display: "none",
  },
});
