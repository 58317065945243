export const imgBell = require("../../global_assets/bell.png");
export const imgic_password_invisible = require("../../global_assets/ic_password_invisible.png");
export const imgic_password_visible = require("../../global_assets/ic_password_visible.png");
export const deleteImg = require("../assets/delete.png");
export const downloadImg = require("../assets/Download.png");
export const fullScreenImg = require("../assets/fullScreen.png");
export const deleteIcon = require("../assets/delete-icon.png")
export const messageIcon = require("../assets/message-icon.png")
export const pdfImage = require("../assets/pdf.png")
export const home = require("../assets/home.png")
export const Library = require("../assets/document.png")
export const Quotes = require("../assets/Quotes.png")
export const invoice = require("../assets/invoice.png")
export const report = require("../assets/report.png")
export const document = require("../assets/document.png")
export const analytics = require("../assets/analytics.png")
export const sideBarLogo = require("../assets/sideBarLogo.png")
