import React from 'react'
// Customizable Area Start
import {
    Formik,
    FormikProps,
    Form,

} from 'formik';
import * as Yup from 'yup';
import { TextField, Button, Grid } from "@material-ui/core";
import { styled } from '@material-ui/styles';
import Alert from '@material-ui/lab/Alert';
import { View, Image } from 'react-native';
import { withRouter } from 'react-router-dom';
import { preview, Logo } from './assets';
import EmailAccountLoginWeb from '../../email-account-login/src/EmailAccountLoginWeb.web';
import './index.css';
// Customizable Area End

import EmailAccountRegistrationWebController, { Props } from './EmailAccountRegistrationWebController.web';

// Customizable Area Start
export interface MyFormValues {
    name: string;
    email: string;
    password: string;
}
const StyledButton = styled(Button)({
    background: '#ef7927',
    width: '100%',
    height: "45px",
    textTransform: 'capitalize',
    padding: '16px',
    fontWeight: 600,
    marginTop: '20px',
    borderRadius: '8px',
    boxShadow: 'none',
    '&:hover': {
        background: '#ef7927',
    }

});
const StyledButton2 = styled(Button)({
    textTransform: 'capitalize',
    fontSize: '0.75rem',
    fontWeight: 500,
});
const StyledTextFeild = styled(TextField)({
    '& input': {
        paddingTop: '6px',
        paddingBottom: '6px',
        height: '35px',
    },
    '& .MuiFormLabel-root[data-shrink="false"]': {
        transform: 'translate(14px, 16px) scale(1)',
    },
    '& .MuiInputLabel-outLined.MuiInputLabel-shrink': {
        transform: "translate(12px, -6px) scale(0.70)"
    }

});
const StyledAlert = styled(Alert)({
    marginBottom: "10px"
})
// Customizable Area End
export class EmailAccountRegistrationWeb extends EmailAccountRegistrationWebController {

    constructor(props: Props) {
        super(props);
    }
    // Customizable Area Start


    initialValues: MyFormValues = { name: this.state.name, email: this.state.email, password: this.state.password };
    onSubmitHandler = (values: any, func: any) => {
        this.setState({ name: values.name, email: values.email, password: values.password })
        this.onSubmit();
        setTimeout(() => {
            func.setSubmitting(false)
        }, 2000)
    }
    // Customizable Area End


    render() {
        // Customizable Area 
        return (

            <div className={'signUpIn'}>
                <div className={'container'}>
                    {/* 
        // @ts-ignore */}
                    <View style={webStyle.signUpMain}>
                        {/* 
        // @ts-ignore */}
                        <View style={webStyle.signUp}>
                            <Grid container spacing={3}>

                                <Grid item sm={6} lg={8}>
                                    {/* 
        // @ts-ignore */}
                                    <i style={webStyle.logoWrap} onClick={() => { this.gotoHome() }}>
                                        {/* 
                                // @ts-ignore */}
                                        <Image
                                            style={{ width: '200px', minHeight: '52px' }}
                                            source={Logo}
                                        />
                                    </i>
                                    {/* 
        // @ts-ignore */}
                                    <View style={webStyle.signImg}>
                                        {/* 
        // @ts-ignore */}
                                        <img src={preview} className={'imageSign'}

                                        />
                                    </View>
                                </Grid>

                                <Grid item sm={6} lg={4}>
                                    {/* 
        // @ts-ignore */}
                                    <View style={webStyle.signForm}>
                                        {/* 
        // @ts-ignore */}
                                        <View style={webStyle.btnContainer}>
                                            {/* 
        // @ts-ignore */}
                                            <StyledButton2
                                                type="submit"
                                                variant="contained"
                                                color="secondary"
                                                data-test-id="SignButton"
                                                style={this.state.flag ? webStyle.btnActive : webStyle.btnOuline}
                                                onClick={() => this.onButtonClick("login")}
                                            >
                                                Sign In
                                            </StyledButton2>
                                            <StyledButton2
                                                type="submit"
                                                variant="contained"
                                                data-test-id="SignUpButton"
                                                color="secondary"
                                                style={this.state.flag ? webStyle.btnOuline : webStyle.btnActive}
                                                onClick={() => this.onButtonClick("register")}
                                            >
                                                Sign Up
                                            </StyledButton2>
                                        </View>
                                        {/* 
        // @ts-ignore */}
                                        <h3 style={webStyle.heading}> {this.state.flag ? "Sign in to dashboard" : "Sign up to dashboard"}</h3>
                                        {this.state.flag ?
                                            <>
                                                {/* 
        // @ts-ignore */}
                                                <EmailAccountLoginWeb navigation={this.props.navigation} id={this.props.id} /> </> :
                                            <Formik
                                                initialValues={this.initialValues}
                                                onSubmit={this.onSubmitHandler}
                                                validationSchema={Yup.object().shape({
                                                    email: Yup.string()
                                                        .email()
                                                        .required('Enter valid email'),
                                                    name: Yup.string().required('Please enter full name'),
                                                    password: Yup.string()
                                                        .matches(
                                                            /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,15}$/
                                                        )
                                                        .required(
                                                            'Please enter valid password. One uppercase, one lowercase, one special character and no spaces'
                                                        ),
                                                })}
                                            >
                                                {(props: FormikProps<MyFormValues>) => {
                                                    const {
                                                        values,
                                                        touched,
                                                        errors,
                                                        handleBlur,
                                                        handleChange,
                                                        isSubmitting,
                                                    } = props
                                                    return (
                                                        //@ts-ignore
                                                        <Form translate={undefined}>
                                                            {this.state.isSuccses ? <StyledAlert severity="success">Congratulations, your account has been succcessfully created.</StyledAlert> : ""}
                                                            {this.state.apiError ? <StyledAlert severity="error">{this.state.serverSideError ? "Registration Failed, Please try agin later." : "Email is already used!"}</StyledAlert>
                                                                : ""}
                                                            <EmailAccountRegistrationForm
                                                                values={values}
                                                                errors={errors}
                                                                touched={touched}
                                                                handleChange={handleChange}
                                                                handleBlur={handleBlur}
                                                                isSubmitting={isSubmitting}
                                                            />
                                                        </Form>
                                                    )
                                                }}
                                            </Formik>}
                                    </View>
                                </Grid>
                            </Grid>
                        </View>
                    </View>
                </div>
            </div>
        )
        // Customizable Area End
    }
}
{/* 
    // @ts-ignore */}
export default withRouter(EmailAccountRegistrationWeb)
// Customizable Area Start
export const EmailAccountRegistrationForm = ({
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    isSubmitting,
}: any) => {
    return (
        <>
            {/* 
        // @ts-ignore */}
            <View style={webStyle.txt}>
                <label style={webStyle.labelTxt}>Name</label>
                <StyledTextFeild
                    name="name"
                    id="name"
                    label="Enter your name"
                    data-test-id="txtInputName"
                    value={values?.name}
                    type="text"
                    variant="outlined"
                    helperText={
                        errors?.name && touched?.name
                            ? errors?.name
                            : ''
                    }
                    error={
                        errors?.name && touched?.name
                            ? true
                            : false
                    }
                    onChange={handleChange}
                    onBlur={handleBlur}
                />
            </View>
            {/* 
        // @ts-ignore */}
            <View style={webStyle.txt}>
                <label style={webStyle.labelTxt}>Email</label>
                <StyledTextFeild
                    name="email"
                    id="email"
                    label="Enter your email"
                    data-test-id="txtInputEmail"
                    value={values?.email}
                    variant="outlined"
                    type="email"
                    helperText={
                        errors?.email && touched?.email
                            ? errors?.email
                            : ''
                    }
                    error={
                        errors?.email && touched?.email
                            ? true
                            : false
                    }
                    onChange={handleChange}
                    onBlur={handleBlur}
                />
            </View>
            {/* 
        // @ts-ignore */}
            <View style={webStyle.txt}>
                <label style={webStyle.labelTxt}>Password</label>
                <StyledTextFeild
                    name="password"
                    id="password"
                    label="Enter your password"
                    data-test-id="txtInputPassword"
                    value={values?.password}
                    variant="outlined"
                    type="password"
                    helperText={
                        errors?.password && touched?.password
                            ? 'Please enter valid password. One uppercase, one lowercase, one special character and no spaces'
                            : ''
                    }
                    error={
                        errors?.password && touched?.password
                            ? true
                            : false
                    }
                    onChange={handleChange}
                    onBlur={handleBlur}
                />
            </View>
            <StyledButton
                type="submit"
                variant="contained"
                data-test-id="btnEmailRegister"
                color="secondary"
                disabled={isSubmitting}

            >
                Sign up
            </StyledButton>
        </>
    )
}
// Customizable Area End
// Customizable Area Start
const webStyle = {
    signUpMain: {

    },
    signUp: {
        width: '100%',
        background: 'linear-gradient(90deg, #f3f3ff, #fff)',
        borderRadius: "22px",
        paddingTop: '30px',
        paddingBottom: '30px',
        paddingLeft: '45px',
        paddingRight: '45px',
        flexShrink: 'inherit',
    },
    signImg: {
        marginTop: 50,
        display: 'flex',
        alignItems: 'center'
    },
    btnContainer: {
        display: 'flex',
        flexDirection: 'row',
        border: '1px solid #ccc',
        alignItems: 'center',
        gap: '20px',
        padding: '3px',
        width: 'fit-content',
        borderRadius: "12px",
        marginRight: 'auto'
    },
    btnActive: {
        background: '#ef7927',
        padding: '8px 35px',
        fontWeight: 500,
        borderRadius: '8px',
    },
    btnOuline: {
        backgroundColor: 'transparent',
        border: '0',
        color: '#000',
        padding: '8px 35px',
        fontWeight: 500,
        borderRadius: '0',
        boxShadow: 'none',
    },
    signFormContainer: {
        maxWidth: "100%"
    },
    signForm: {
        background: '#fff',
        padding: '35px',
        border: '1px solid rgb(243 241 241)',
        borderRadius: "12px",
        boxShadow: '23px 9px 36px -10px rgb(124 120 120 / 27%)',
        height: '100%',
    },
    txt: {
        marginBottom: 20,
        fontWeight: 500,
    },
    heading: {
        textAlign: 'center',
        padding: '20px 0',
        fontWeight: 500,
    },
    labelTxt: {
        marginBottom: 10,
        fontWeight: 500,
    },
    leftImage: {
        width: '100%',
        minHeight: '560px',
        maxWidth: '550px',
        '@media (max-width:1620px)': {
            minHeight: '400px !important',
        }
    },
    logoWrap: {
        cursor: 'pointer',
        display: 'block'
    }

}
{/* 
        // @ts-ignore */}
// export default withRouter(EmailAccountRegistrationWeb)
// Customizable Area End

