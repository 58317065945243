// Customizable Area Start
import React from 'react'
import {
    Formik,
    FormikProps,
    Form,
} from 'formik';
import * as Yup from 'yup';
import {
    TextField, Button, Grid, InputAdornment, Box, IconButton,
    Typography, DialogTitle,
    Dialog,
    DialogContent,
} from "@material-ui/core";
import { styled } from '@material-ui/styles';
import { View } from 'react-native';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import VisibilityIcon from '@material-ui/icons/Visibility';
import './index.css';
import LoginController, { Props } from './LoginController.web';
import {
    home_background, MyInvoiceCheckIcon, Wave
} from "../../landingpage/src/assets";
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CloseIcon from '@material-ui/icons/Close';
// Customizable Area End

// Customizable Area Start
export interface MyFormValues {
    email: string;
    password: string;
}

const SignupButtonStyle = styled(Button)({
    background: "#292946",
    width: '60%',
    height: "45px",
    textTransform: 'capitalize',
    padding: '16px',
    fontWeight: 600,
    margin: 'auto',
    marginTop: '8%',
    display: 'flex',
    borderRadius: '8px',
    boxShadow: 'none',
    '&:hover': {
        background: '#292946',
    }

});

const FormTextFeildStyle = styled(TextField)({
    '& input': {
        paddingTop: '6px',
        paddingBottom: '6px',
        height: '35px',
    },
    '& .MuiFormLabel-root[data-shrink="false"]': {
        transform: 'translate(14px, 16px) scale(1)',
    },
    '& .MuiInputLabel-outLined.MuiInputLabel-shrink': {
        transform: "translate(12px, -6px) scale(0.70)"
    }

});

export const LoginEmailDialog = (props: any) => {
    const { handleClose, handleOpen } = props

    return (
        <>
            <Dialog maxWidth="xs" style={{ padding: '10px' }} data-testid="dialogOpenId" onClose={handleClose} open={handleOpen}>
                <DialogTitle style={{ color: 'black', width: '100%', textAlign: 'center' }}>
                    Link sent to email
                    <IconButton onClick={handleClose} style={{ position: 'absolute', right: '4%', top: '7%', border: '1px solid black', borderRadius: '8px', padding: 0 }}>
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '14px', flexDirection: 'column', paddingBottom: '5%' }}>
                    <CheckCircleIcon style={{ color: '#008000a1', fontSize: 40 }} />
                    <Typography style={{ width: '100%', textAlign: 'center' }}>A reset password link has been sent to your registered email</Typography>
                </DialogContent>
            </Dialog>
        </>
    )
}

export const UserLogin = ({ values,
    errors,
    touched,
    handleBlur,
    isSubmitting,
    handleChange,
    handlePasswordChange, password, handleClickShowPassword, showPassword, isPasswordValid, isEmailEntered, handleEmailEntered, loginFailErrMsg, handleForgotPassword, passwordErrMsg, EmailErrMsg }: any) => {
    return (
        <>
            {isEmailEntered ?
                <EmailAccountLoginForm values={values} errors={errors}
                    handleBlur={handleBlur}
                    handleChange={handleChange}
                    touched={touched}
                    isSubmitting={isSubmitting}
                    handlePasswordChange={handlePasswordChange} password={password} handleClickShowPassword={handleClickShowPassword} showPassword={showPassword} loginFailErrMsg={loginFailErrMsg} handleForgotPassword={handleForgotPassword} passwordErrMsg={passwordErrMsg} EmailErrMsg={EmailErrMsg} />
                :
                <EnterEmailPassword values={values} errors={errors}
                    touched={touched}
                    handleChange={handleChange}
                    handleBlur={handleBlur} handleEmailEntered={handleEmailEntered}
                    EmailErrMsg={EmailErrMsg}/>}
        </>
    )
}

export const EnterEmailPassword = ({ values,
    errors,
    touched,
    handleChange,
    handleBlur, handleEmailEntered, EmailErrMsg }: any) => {
    const emailError = errors?.email && touched?.email ? errors.email : '';
    console.log(errors,touched, "Email Error")
    const isEmailError = !!errors?.email && !!touched?.email;
    return (
        <Box style={{ marginTop: "5%" }}>
            <View style={webStyle.txtStyle}>
                <label style={webStyle.labelTxtStyle}>Email</label>
                <FormTextFeildStyle
                    name="email"
                    id="email"
                    label="Enter your email"
                    data-test-id="txtInputEmail"
                    value={values?.email}
                    variant="outlined"
                    type="email"
                    helperText={
                        emailError 
                    }
                    error={
                        !!emailError 
                    }
                    onChange={handleChange}
                    onBlur={handleBlur}
                />
            </View>
            <SignupButtonStyle
                type="button"
                variant="contained"
                data-test-id="btnEmailRegister"
                color="secondary"
                disabled={isEmailError || values?.email.length <= 0}
                onClick={()=>handleEmailEntered(values.email)}
            >
                Continue
            </SignupButtonStyle>
        </Box>
    )
}

// Customizable Area End
class Login extends LoginController {

    constructor(props: Props) {
        super(props);
    }
    // Customizable Area Start

    initialValues: MyFormValues = { email: this.state.email, password: this.state.password };
    onSubmitHandler = (values: any, func: any) => {
        this.setState({ email: values.email, password: this.state.password })
        this.onSubmit()
        setTimeout(() => {
            func.setSubmitting(false)
        }, 2000)
    };
    // Customizable Area End
    render() {
        // Customizable Area 
        return (

            <div style={webStyle.imageContainerStyle}>
                <LoginEmailDialog handleOpen={this.state.isLoginEmailModalOpen} handleClose={this.handleModalClose} />
                {/* 
        // @ts-ignore */}
                <View style={webStyle.signUpStyle}>
                    <Grid container spacing={3}>

                        <Grid item style={{ padding: 0 }} sm={6} lg={8}>
                            <i style={webStyle.logoWrapStyle} onClick={this.gotoHome}>
                                <img
                                    style={{ width: '30%' }}
                                    src={MyInvoiceCheckIcon}
                                    alt=""
                                />
                            </i>
                            <Box style={{ marginTop: '7%', color: 'white' }}>
                                <Typography style={{ fontSize: '20px', fontWeight: 550 }}>Navigating Construction Costs Hassel-Free</Typography>
                                <Typography style={{ width: '57%', marginTop: '2%' }}>My Invoice Check simplifies the comparison between project quotes and invoices, ensuring clarity and confidence in every transaction</Typography>
                            </Box>
                        </Grid>

                        <Grid item style={{ padding: 0, paddingBottom: '4%' }} sm={6} lg={4}>
                            {/* 
        // @ts-ignore */}
                            <View style={webStyle.styleSignForm}>
                                {!this.state.isEmailEntered && <h3 style={webStyle.headingStyle}> Welcome to My Invoice Check<img src={Wave} alt="" style={{ marginLeft: '1%', width: '6%' }} /></h3>}
                                {this.state.isEmailEntered && <h3 style={webStyle.headingStyle}>Good to see you again<img src={Wave} alt="" style={{ marginLeft: '1%', width: '6%' }} /></h3>}
                                <Formik
                                    data-testid="formikId"
                                    initialValues={this.initialValues}
                                    onSubmit={this.onSubmitHandler}
                                    validationSchema={Yup.object().shape({
                                        email: Yup.string()
                                            .email()
                                            .required('Enter valid email'),
                                        password: Yup.string()
                                    })}
                                >
                                    {(props: FormikProps<MyFormValues>) => {
                                        const {
                                            values,
                                            touched,
                                            errors,
                                            handleBlur,
                                            handleChange,
                                            isSubmitting,
                                        } = props
                                        const customHandleChange = (event: any) => {
                                            this.setState({ emailErrMsg: '' })
                                            handleChange(event);
                                        };
                                        return (
                                            //@ts-ignore
                                            <Form translate={undefined}>
                                                <UserLogin
                                                    handleChange={customHandleChange}
                                                    touched={touched}
                                                    handleBlur={handleBlur}
                                                    values={values}
                                                    isSubmitting={isSubmitting}
                                                    errors={errors}
                                                    handlePasswordChange={this.handlePasswordChange}
                                                    password={this.state.password}
                                                    handleClickShowPassword={this.handleClickShowPassword}
                                                    showPassword={this.state.showPassword}
                                                    isPasswordValid={this.state.isPasswordValid}
                                                    handleEmailEntered={this.handleEmailEntered}
                                                    isEmailEntered={this.state.isEmailEntered}
                                                    loginFailErrMsg={this.state.loginFailErrMsg}
                                                    handleForgotPassword={this.handleForgotPassword}
                                                    passwordErrMsg={this.state.passwordErrMsg}
                                                    EmailErrMsg={this.state.emailErrMsg}
                                                />
                                            </Form>
                                        )
                                    }}
                                </Formik>
                            </View>
                        </Grid>
                    </Grid>
                </View>
            </div >
        )
        // Customizable Area End
    }
}
{/* 
    // @ts-ignore */}
export { Login }
// Customizable Area Start
export const EmailAccountLoginForm = ({
    handleBlur,
    values,
    errors,
    touched,
    isSubmitting,
    handleChange,
    handlePasswordChange, password, handleClickShowPassword, showPassword, loginFailErrMsg, handleForgotPassword, passwordErrMsg, EmailErrMsg
}: any) => {
    const isFieldError = (fieldName: string) => !!errors?.[fieldName] && !!touched?.[fieldName];

    const emailError = errors?.email && touched?.email ? errors.email : '';

    const isDisabled =
        isSubmitting ||
        isFieldError('email') ||
        isFieldError('password');
    return (
        <Box style={{ marginTop: '20px' }}>
            {/* 
        // @ts-ignore */}
            <View style={webStyle.txtStyle}>
                <label style={webStyle.labelTxtStyle}>Email</label>
                <FormTextFeildStyle
                    name="email"
                    id="email"
                    label="Enter your email"
                    data-test-id="txtInputEmail"
                    value={values?.email}
                    variant="outlined"
                    type="email"
                    helperText={
                        EmailErrMsg ? EmailErrMsg : emailError
                    }
                    error={
                        !!emailError || EmailErrMsg?.length > 0
                    }
                    onChange={handleChange}
                    onBlur={handleBlur}
                    autoComplete='off'
                />
            </View>
            {/* 
        // @ts-ignore */}
            <View style={{ fontWeight: 500 }}>
                <label style={webStyle.labelTxtStyle}>Password</label>
                <FormTextFeildStyle
                    name="password"
                    id="password"
                    label="Enter your password"
                    data-test-id="txtInputPassword"
                    value={password}
                    variant="outlined"
                    type={showPassword ? 'text' : 'password'}
                    helperText={
                        passwordErrMsg
                    }
                    error={
                        passwordErrMsg?.length > 0
                    }
                    onChange={handlePasswordChange}
                    onBlur={handleBlur}
                    autoComplete='off'
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword}
                                    edge="end"
                                >
                                    {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                />
            </View>
            <Button variant="text" style={{ fontWeight: 500, textTransform: 'initial', color: '#292946', textDecoration: 'underline', display: 'flex', justifyContent: 'end', width: '100%' }} onClick={() => handleForgotPassword(values?.email)}>
                Forgot password
            </Button>
            <SignupButtonStyle
                type="submit"
                variant="contained"
                data-test-id="btnEmailRegister"
                color="secondary"
                disabled={isDisabled || password?.length <= 7}
            >
                Login
            </SignupButtonStyle>
        </Box>
    )
}
// Customizable Area End
// Customizable Area Start
const webStyle = {
    imageContainerStyle: {
        backgroundImage: `url(${home_background})`,
        backgroundSize: 'cover', /* Cover the entire div with the background image */
        backgroundPosition: 'center', /* Center the background image */
        /* Add other styles as needed */
        height: '100vh',
        padding: '8%',
        overflowY: 'auto' as 'auto'
    },
    signUpStyle: {
        width: '100%',
        borderRadius: "22px",
        flexShrink: 'inherit',
    },
    styleSignForm: {
        background: '#fff',
        padding: '7%',
        border: '1px solid rgb(243 241 241)',
        borderRadius: "12px",
        boxShadow: '23px 9px 36px -10px rgb(124 120 120 / 27%)',
    },
    txtStyle: {
        marginBottom: 20,
        fontWeight: 500,
    },
    headingStyle: {
        textAlign: 'center' as 'center',
        fontWeight: 550,
        fontSize: '20px',
        color: 'black'
    },
    labelTxtStyle: {
        marginBottom: 6,
        fontWeight: 500,
        color: 'black'
    },
    logoWrapStyle: {
        cursor: 'pointer',
        display: 'block'
    }

}
{/* 
        // @ts-ignore */}
// export default withRouter(EmailAccountRegistrationWeb)
// Customizable Area End

