// Customizable Area Start
import React from "react";
import { Box, Typography, Button } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import ExportModalController, { Props } from "./ExportModalController.web";
import { closeIcon, reportIcon1, uploadIcon } from "./assets";
// Customizable Area Endo
class ExportModal extends ExportModalController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes, selectedItem }: any = this.props;
    // Customizable Area End

    return (
      // Customizable Area Start
      <>
        {selectedItem.length > 0  ?
          <Box className={classes.exportmodalBox}>
            <Box className={classes.closeIconBox}>
              <img src={closeIcon} alt="close" onClick={this.props.handleClose} />
            </Box>
            <Box className={classes.uploadImgBox}>
              <img src={uploadIcon} alt="upload" />
            </Box>
            <Typography>
              You have selected {selectedItem.length} items to export
              please choose type
            </Typography>
            <Box className={classes.exportbuttonBox}>
              <Button onClick={() => { this.handleSaveAsPDF(selectedItem)}} data-testid="handleSaveAsPDF" >Export as PDF</Button>
              <Button
                onClick={() => {
                  this.handleExcelDataClick(selectedItem)
                }}
                // onClick={this.props.handleClose, () => this.handleSaveAsPDF(selectedItem)}
                data-testid="handleExportDataClick"
              >
                Export as Excel
              </Button>
            </Box>
          </Box>
          :
          <Box className={classes.exportmodalBox}>
            <Box className={classes.closeIconBox}>
              <img src={closeIcon} alt="close" onClick={this.props.handleClose} />
            </Box>
            <Box className={classes.uploadImgBox}>
              <img src={reportIcon1} alt="upload" />
            </Box>
            <Typography>
              You didn't select any item to export, please select and export the items.
            </Typography>
            <Box className={classes.exportbuttonBox}>
              <Button onClick={this.props.handleClose}>Cancel</Button>
              <Button onClick={this.props.handleClose}>Ok</Button>
            </Box>
          </Box>
        }
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start

export const exportTableStyle = () => ({
  exportmodalBox: {
    padding: "28px 30px 50px",
    background: "#ffffff",
    width: "560px",
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    borderRadius: "10px",
    "& p": {
      fontSize: "20px",
      color: "#000000",
      textAlign: "center",
      margin: "25px",
      fontWeight: '500'
    }
  },
  closeIconBox: {
    textAlign: "right" as "right",
    marginBottom: "10px",
    "& img": {
      width: "35px",
      height: "35px"
    }
  },
  uploadImgBox: {
    textAlign: "center" as "center",
    "& img": {
      width: "100px",
      height: "100px"
    }
  },
  exportbuttonBox: {
    textAlign: "center" as "center",
    "& button": {
      border: "1px solid #e0802e",
      padding: "15px 0px",
      width: '200px',
      textTransform: "unset",
      color: "#e0802e",
      fontSize: "20px",
      lineHeight: "22px",
      "&:hover": {
        background: "#e0802e",
        color: "#ffffff"
      },
      "&:first-child": {
        marginRight: "40px"
      }
    }
  }
});
export { ExportModal };
export default withStyles(exportTableStyle)(ExportModal);

// Customizable Area End
