import React from 'react'
// Customizable Area Start
import { TextField, Button } from "@material-ui/core"
import { styled } from '@material-ui/styles'
import Alert from '@material-ui/lab/Alert';
import {
    Formik,
    FormikProps,
    Form,

} from 'formik';
import * as Yup from 'yup'
import { View } from 'react-native';
// Customizable Area End
import ForgotAndResetPasswordWebController, { Props } from './ForgotAndResetPasswordWebController'
// Customizable Area Start
export interface MyFormValues {
    password: string;
    confirm_password: string;
}
const StyledButton = styled(Button)({
    background: '#ef7927',
    width: '100%',
    height: "45px",
    textTransform: 'capitalize',
    padding: '16px',
    fontWeight: 600,
    marginTop: '20px',
    borderRadius: '8px',
    boxShadow: 'none',
    '&:hover': {
        background: '#ef7927',
    }

});
const StyledTextFeild = styled(TextField)({
    '& input': {
        paddingTop: '6px',
        paddingBottom: '6px',
        height: '35px',
    },
    '& .MuiFormLabel-root[data-shrink="false"]': {
        transform: 'translate(14px, 16px) scale(1)',
    },
    '& .MuiFormLabel-root': {
        color: "black"
    },

});
// Customizable Area End
export default class ResetPasswordWeb extends ForgotAndResetPasswordWebController {
    // Customizable Area Start

    temp: string = ""
    constructor(props: Props) {
        super(props)
        this.temp = this.props.prop.history.location.search.split("=")[1].trim();
    }
    // Customizable Area End

    // Customizable Area Start
    initialValues: MyFormValues = { password: "", confirm_password: "" };
    onSubmitHandler = (values: any, func: any) => {
        this.setState({ password: values.password, token: this.temp })
        this.onSubmitResetPassword();
        setTimeout(() => {
            func.setSubmitting(false)
        }, 2000)
    }
    // Customizable Area End
    render() {
        // Customizable Area Start

        return (
            <>{/* 
        // @ts-ignore */}
                <h3 style={webStyle.heading}>Reset Password?</h3>
                {/* 
        // @ts-ignore */}
                <p style={this.state.apiError || this.state.serverSideError ? webStyle.ResetDetail : webStyle.detail}>Enter your new password below, to create a new password</p>
                <Formik
                    initialValues={this.initialValues}
                    onSubmit={this.onSubmitHandler}
                    validationSchema={Yup.object().shape({
                        password: Yup.string()
                            .matches(
                                /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,15}$/
                            )
                            .required(
                                'Please enter valid password. One uppercase, one lowercase, one special character and no spaces'
                            ),
                        confirm_password: Yup.string()
                            .oneOf([Yup.ref("password"), null], "Password must match")
                            .required("Password must match"),

                    })}
                >
                    {(props: FormikProps<MyFormValues>) => {
                        const {
                            values,
                            touched,
                            errors,
                            handleBlur,
                            handleChange,
                            isSubmitting,
                        } = props
                        return (
                            //@ts-ignore
                            <Form translate={undefined}>
                                {this.state.apiError ? <Alert style={webStyle.resetAlert} severity="error">{this.state.serverSideError ? "Something went Wrong, Please try again later!" : "Cannot use previous password"}</Alert>
                                    : ""}
                                {this.state.succesAlert ? <Alert style={webStyle.resetAlert} severity="success">Congratulations, your password is successfully changed.</Alert>
                                    : ""}
                                {/* 
        // @ts-ignore */}
                                <ResetPasswordForm
                                    values={values}
                                    errors={errors}
                                    touched={touched}
                                    handleChange={handleChange}
                                    handleBlur={handleBlur}
                                    isSubmitting={isSubmitting}

                                />
                            </Form>
                        )
                    }}
                </Formik>
            </>
        )
        // Customizable Area Start

    }
}
// Customizable Area Start
export const ResetPasswordForm = ({
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    isSubmitting,

}: any) => {
    return (
        <>
            {/* 
        // @ts-ignore */}
            <View style={webStyle.TextfieldView}>
                <label style={webStyle.labelTxt}>New Password</label>
                <StyledTextFeild
                    name="password"
                    id="password"
                    label="Enter your new password"
                    data-test-id="txtInputPassword"
                    value={values?.password}
                    variant="outlined"
                    type="password"
                    helperText={
                        errors?.password && touched?.password
                            ? 'Please enter valid password. One uppercase, one lowercase, one special character and no spaces'
                            : ''
                    }
                    error={
                        errors?.password && touched?.password
                            ? true
                            : false
                    }
                    onChange={handleChange}
                    onBlur={handleBlur}
                />
            </View>
            {/* 
        // @ts-ignore */}
            <View style={webStyle.TextfieldView}>
                <label style={webStyle.labelTxt}>Confirm Password</label>
                <StyledTextFeild
                    name="confirm_password"
                    id="confirmPassword"
                    label="Enter your confirm password"
                    data-test-id="txtInputConfirmPassword"
                    value={values?.confirm_password}
                    variant="outlined"
                    type="password"
                    helperText={
                        errors?.confirm_password && touched?.confirm_password
                            ? 'Password must match'
                            : ''
                    }
                    error={
                        errors?.confirm_password && touched?.confirm_password
                            ? true
                            : false
                    }
                    onChange={handleChange}
                    onBlur={handleBlur}
                />
            </View>


            <StyledButton
                type="submit"
                variant="contained"
                data-test-id="btnResetPassword"
                color="secondary"
                disabled={isSubmitting}

            >
                Reset Password
            </StyledButton>

        </>
    )
}

const webStyle = {

    TextfieldView: {
        marginBottom: '12px',
        fontWeight: '600',
    },
    heading: {
        textAlign: 'center',
        padding: '20px 0',
        color: "#1D1350",
        fontSize: '24px',
        fontWeight: '600',
        marginBottom: '0',
        paddingBottom: '0',
    },
    detail: {
        textAlign: 'center',
        fontSize: '14px',
        marginBottom: '67px',
        width: '90%',
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    ResetDetail: {
        textAlign: 'center',
        fontSize: '14px',
        marginBottom: '50px',
        width: '90%',
        marginLeft: 'auto',
        marginRight: 'auto',
    },

    labelTxt: {
        marginBottom: 7,
        fontWeight: 600,
    },
    resetAlert: {
        marginBottom: "15px"
    }

}
 // Customizable Area End