// Customizable Area Start
import React from "react";
import {
  Grid, Box
} from "@material-ui/core";
import { createTheme, withStyles } from "@material-ui/core/styles";
import DashboardNewTable from "../../landingpage/src/DashboardNewTable.web";
import LibraryController, { Props } from "./LibraryController.web";
import Header from "../../landingpage/src/Header.web";
// Customizable Area End

// Customizable Area Start
const theme = createTheme({});
// Customizable Area End
class Library extends LibraryController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes, navigation, handleSaveData } = this.props
    // Customizable Area End
    return (
      // Customizable Area Start
      <>
        <Box>
            <Header navigation={navigation} handleSaveData={handleSaveData} history={this.props.history}/>
            <Grid container spacing={2}>
              <Grid item lg={6} md={6} sm={12} xs={12} className={classes.headerBox}>
                <DashboardNewTable {...this.props} navigatePath="/sorting/projects"  rows={this.state.projectData} title="Projects" hasMenu={true} />
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12} className={classes.headerBox}>
                <DashboardNewTable {...this.props} navigatePath="/sorting/archive" rows={this.state.archiveData} title="Archive" hasMenu={true} />
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12} className={classes.headerReportBox}>
                <DashboardNewTable {...this.props} navigatePath="/sorting/Reports" rows={this.state.reportsData} title="Reports" hasDelta={true} hasMenu={true} />
              </Grid>
            </Grid>
        </Box>
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start

export const libraryTableStyle = (theme: any) => ({
  headerBox: {
    '& th:nth-child(1)': {
      '& img': {
        display: 'block'
      }
    },
    '& th': {
      '& img': {
        display: 'none'
      }
    },
    '& .MuiTablePagination-root': {
      display: 'none'
    }
  },
  headerReportBox: {
    '& th:nth-child(1)': {
      '& img': {
        display: 'block'
      }
    },
    '& th': {
      '& img': {
        display: 'none'
      }
    },
  }
})

export {Library}
export default withStyles(libraryTableStyle)(Library)


// Customizable Area End
