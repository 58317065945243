// Customizable Area Start

import React from 'react'
import { Message } from "../../../framework/src/Message";
import MessageEnum, { getName, } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from '../../../framework/src/IBlock';
export const configJSON = require("./config");
// Customizable Area End 

export interface Props {
    navigation: any;
    id: any;
    // Customizable Area Start
    history: any;
    prop: any;
    // Customizable Area End 
}

interface S {
    // Customizable Area Start
    email: string;
    password: string;
    apiError: boolean;
    serverSideError: boolean;
    flag: boolean;
    succesAlert: boolean;
    token: string;
    // Customizable Area End
}
interface SS {
    // Customizable Area Start
    id: any;
    // Customizable Area End 

}

export default class ForgotAndResetPasswordWebController extends BlockComponent<Props, S, SS> {
    // Customizable Area Start
    forgotPasswordApi: string = "";
    resetPasswordApi: string = "";
    // Customizable Area End
    constructor(props: Props) {
        super(props)
        this.receive = this.receive.bind(this);
        // Customizable Area Start
        this.state = {
            email: "",
            password: "",
            flag: false,
            apiError: false,
            serverSideError: false,
            succesAlert: false,
            token: "",
        }
        this.subScribedMessages = [
            getName(MessageEnum.CountryCodeMessage),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.ReciveUserCredentials),
        ];
        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages)

    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        this.handleForgotPasswordApiCall(from, message)
        this.handleResetPasswordApiCall(from, message)
        // Customizable Area end


    }

    // Customizable Area Start

    async componentDidMount() {
        if (this.props.history.location.pathname === "/resetPassword") {
            this.setState({ flag: true })
        }

    }
    handleForgotPasswordApiCall = (from: string, message: any) => {
        const apiRequestCallId = message.getData(
            getName(MessageEnum.RestAPIResponceDataMessage)
        );

        const responseJson = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
        if (apiRequestCallId === this.forgotPasswordApi) {
            if (responseJson) {
                if (responseJson?.error) {
                    this.setState({ apiError: true })
                }
                else {
                    this.setState({ succesAlert: true, email: "" })
                }

            }

        }
    }

    handleResetPasswordApiCall = (from: string, message: any) => {
        const apiRequestCallId = message.getData(
            getName(MessageEnum.RestAPIResponceDataMessage)
        );

        const responseJson = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
        if (apiRequestCallId === this.resetPasswordApi) {

            if (responseJson) {
                if (responseJson?.error) {
                    this.setState({ apiError: true, serverSideError: true })
                }
                else if (responseJson?.errors) {
                    this.setState({ apiError: true, serverSideError: true })
                }
                else if (responseJson.data) {
                    this.setState({ succesAlert: true });
                    setTimeout(() => {
                        this.props.prop.history.push("/login")
                    }, 1000)
                }
            }

        }
    }



    onSubmitForgotPassword = (): boolean => {
        
        const header = {
            "Content-Type": configJSON.forgotPasswordApiContentType,
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.forgotPasswordApi = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.forgotPasswordApiEndPoint}?email=${this.state.email}`
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.forgotPasswordApiMethod
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);

        return true;

    }

    onSubmitResetPassword = (): boolean => {
        const data = {
            "token": this.state.token,
            "new_password": this.state.password
        }

        const header = {
            "Content-Type": configJSON.resetPasswordApiContentType,
        };

        const httpBody = {
            data: data,
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.resetPasswordApi = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.resetPasswordApiEndPoint
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(httpBody)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.resetPasswordApiMethod
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);

        return true;
    }

    gotoHome() {
        this.props.history.push("/")
    }
    // Customizable Area End
}
