// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";


// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import React from "react";
import DocumentPicker from "react-native-document-picker";
// import React, { ChangeEvent } from 'react';
import { Platform } from "react-native";
// import RNFS from "react-native-fs";
// import { WebView, WebViewMessageEvent } from 'react-native-webview';


interface WebViewMessageData {
  action: string;
}

interface IKeywordResponse {
  data: [{
    id: number;
    name: string;
    border_color: string;
    keyword_color: string;
  }]
}

interface IKeywordArray {
  id: number;
  name: string;
  border_color: string;
  keyword_color: string;
  checked: boolean;
}

interface imageType {
  name: string;
  uri: string | Blob;
  type: string;
}

interface IResponseData {
  file_name: string;
  url: string;
  id: string;
  data: {
    id: string,
    attributes: {
      documents: []
    }
  },
  errors: "documents not found"
}
interface IChildRefRefArray extends React.HTMLProps<HTMLDivElement> {

  fileName: string,
  numPages: number,
  canvasRef: {
    querySelector(selector: string): Element | null,
  },
  documentRef: Document | null;

}

interface IFromData {

}

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}



interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  keywordText: string,
  allKeywords: Array<IKeywordArray>,
  unMatchedKeyword: Array<IKeywordArray>,
  onlyKeywords: Array<IKeywordArray>,
  loading: boolean,
  firstIntialLoad: boolean,
  token: string,
  value: string,
  responseData: Array<IResponseData>,
  pdfValidationPopUp: boolean,
  checkedKeyword: boolean,
  childRefRefArray: Array<IChildRefRefArray>,
  orientation: string,
  pdfDocId: number,

  pdfChooseStatus: boolean,
  webUrl: string,

  fileName: string;
  numPages: number;
  stringsToHighlight: Array<IKeywordArray>;
  keywords: Array<IKeywordArray>;
  mValue: number;
  keywordsData: [];
  highlights: any[];
  linePosition: any;
  search: string,
  textItems: any
  // defaultLayoutPluginInstance: any

  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class PdfkeywordhighlighterController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getApiId: string = ""
  postApiId: string = ""
  getAllKeywordsApiId: string = ""
  keywordRemoveCallId: string = ""
  keywordAddId: string = ""
  removeDocId: string = ""
  pdfHighlightId: string = ""

  public canvasRef: React.RefObject<HTMLCanvasElement>;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    // Customizable Area End

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      token: "",
      value: '0',
      orientation: '',
      pdfValidationPopUp: false,
      loading: false,
      childRefRefArray: [],
      keywordText: '',
      responseData: [],
      firstIntialLoad: true,
      pdfDocId: 0,
      onlyKeywords: [],
      allKeywords: [],
      unMatchedKeyword: [],
      checkedKeyword: false,
      pdfChooseStatus: false,
      webUrl: configJSON.webUrlConfig,

      fileName: "",
      numPages: 0,
      stringsToHighlight: [],
      keywords: [],
      mValue: 0,
      keywordsData: [],
      highlights: [],
      linePosition: {
        "data": [
          {
            "product": "STORM",
            "color": 'red'
          }
        ]
      },
      textItems: ["STORM"],
      search: "STORM",
      // defaultLayoutPluginInstance: defaultLayoutPlugin(),

      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start

    this.canvasRef = React.createRef<HTMLCanvasElement>();
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    this.setState({
      loading: false
    })
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {

      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId === this.getApiId) {
        this.handleGetApi(responseJson);
      }
      if (apiRequestCallId === this.postApiId) {
        this.handlePostApi(responseJson);
      }
      if (apiRequestCallId === this.getAllKeywordsApiId) {

        this.handleKeywordsResponse(responseJson);
      }
      if ((apiRequestCallId === this.keywordRemoveCallId) || (apiRequestCallId === this.keywordAddId)) {

        this.getAllKeywords();
      }
      if (apiRequestCallId === this.removeDocId) {
        this.setState({ pdfDocId: 0 })
        window.location.reload()
      }

      if (apiRequestCallId === this.pdfHighlightId) {
        this.setState({
          highlights: responseJson.data
        })
      }

    }
    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (textValue: string) => {
      this.setState({ txtInputValue: textValue });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let msgValue = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msgValue.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msgValue);
  }

  // web events
  setInputValue = (textValue: string) => {
    this.setState({ txtInputValue: textValue });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Start


  async componentDidMount() {
    super.componentDidMount();

    if(Platform.OS === 'web') {
      const urlParams = new URLSearchParams(window.location.search);
      const tokenValue = urlParams?.get('token') || ' ';
      this.setState({token:tokenValue},()=>{
        this.getAllKeywords();
      })

    }else{  
      const tokenValue = "eyJhbGciOiJIUzUxMiJ9.eyJpZCI6MzMwLCJleHAiOjE2OTM2NDQ2NTUsInRva2VuX3R5cGUiOiJsb2dpbiJ9.mW_UF4ae3oY6ZXdjP3dXreHSaq52CR8ehvTB_dbGJ_w3iybV8cYT3cRclQU7xg04sHFk-1uXXqvMfQ2R0865jg";
      this.setState({token:tokenValue},()=>{
        this.getUploadedFile();
        this.getAllKeywords();
      }) 
    }


    // window.addEventListener(
    //   'hashchange',
    //   this.scrollToHighlightFromHash,
    //   false
    // );

    // const linePosition = await findLinePosition("8ea F08R CONC CURB VALVE BOX");
    // this.setState({ linePosition: "F08R CONC CURB VALVE BOX" });
    // this.data()
  }

  handleGetApi = (responseJson: IResponseData) => {

    if (responseJson.errors === 'documents not found') {
      return
    }
    else if (responseJson) {
      this.setState({
        responseData: responseJson.data.attributes.documents,
      })
    }
  }

  handlePostApi = (responseJson: IResponseData) => {
    this.setState({ responseData: responseJson.data.attributes.documents })
  }

  handleKeywordAddition = () => {

    const textValue = this.state.keywordText;

    let tokenValue = this.state.token;

    this.setState({ keywordText: '' });

    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": tokenValue
    };
    const attrsValue = {
      name: [textValue]
    };
    const attrValue = {
      type: "keyword",
      attributes: attrsValue,
    };
    const httpBody = {
      data: attrValue
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.keywordAddId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.addkeywordAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postAPiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);

    this.setState({
      loading: true
    })

    return true;

  }

  handleKeywordRemove = (itemValue: number) => {
    let removeToken = this.state.token
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": removeToken
    };

    const attrValue = {
    };
    const httpBody = {
      data: attrValue
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.keywordRemoveCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.removeKeyworeds + "?id=" + itemValue
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteAPiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);

    this.setState({
      loading: true
    })

    return true;
  }

  handleKeywordsResponse = (responseJson: IKeywordResponse) => {
    if (responseJson.data) {
      this.getUploadedFile();
    }

    if (responseJson) {
      if (this.state.firstIntialLoad) {
        const tempAllKeywords = responseJson.data.map((item: { id: number; name: string; border_color: string, keyword_color: string; }) => {
          return {
            id: item.id,
            name: item.name,
            border_color: item.border_color,
            keyword_color: item.keyword_color,
            checked: true
          }
        });
        this.setState({ allKeywords: tempAllKeywords, firstIntialLoad: false })
        this.setState({ checkedKeyword: tempAllKeywords.every((elValue: { checked: boolean; }) => elValue.checked) });
        this.setState(prev => { this.setState({ onlyKeywords: prev.allKeywords }) })
        return
      }
      const tempAllKeywords = responseJson.data.map((item: { id: number; name: string; border_color: string, keyword_color: string; }) => {
        const itemCheck = this.state.allKeywords.find(element => element.id === item.id)?.checked;
        return {
          id: item.id,
          name: item.name,
          border_color: item.border_color,
          keyword_color: item.keyword_color,
          checked: Boolean(itemCheck === undefined ? true : itemCheck)

        }
      });
      this.setState({ allKeywords: tempAllKeywords })
      this.setState({ checkedKeyword: tempAllKeywords.every((element: { checked: boolean; }) => element.checked) });
      this.setState(prev => { this.setState({ onlyKeywords: prev.allKeywords }) })


      window.location.reload()
    }
  }

  getAllKeywords = () => {
    const header = {
      token: this.state.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getAllKeywordsApiId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.allKeyworeds
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);

    this.setState({
      loading: true
    })

    return true;
  };


  removeExtension = (fileName: string) => {
    const nameCheck = fileName.substring(0, fileName.lastIndexOf('.'));
    return nameCheck.length > 15 ? nameCheck.substring(0, 15) : nameCheck;
  }

  handleDocumentRemove = (itemValue: string) => {
    let removeToken = this.state.token
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": removeToken
    };
    const attrsValue = {
      id: itemValue
    };
    const attrValue = {
      attributes: attrsValue,
    };
    const httpBody = {
      data: attrValue
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.removeDocId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.deleteDocumentUrl
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteAPiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }


  onUploadFileAPICall = (formData: IFromData) => {

    const header = {
      token: this.state.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.postApiId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.uploadFile
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);

    this.setState({
      loading: true
    })

    return true;
  };

  getUploadedFile = () => {
    const header = {
      token: this.state.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getApiId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getFileUrl
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);

    this.setState({
      loading: true
    })

    return true;
  };

  openFile = async () => {
    const fileResponse = await DocumentPicker.pick({
      presentationStyle: 'fullScreen',
      type: [
        DocumentPicker.types.pdf,
      ],
    });

    if (fileResponse.length > 0) {
      let fileName:string=fileResponse[0].name ?? "";
      let fileUri:string=fileResponse[0].uri ?? "";

      const formData = new FormData();

      const pdfFileSend:imageType = {
        name: fileName,
        uri: fileUri,
        type: "application/pdf"
      }

      // formData.append("data[documents][]", pdfFileSend);

      this.onUploadFileAPICall(formData);
    }
  };


  btnApplyPDF = () => {
    let webUrl = this.state.webUrl + this.state.token;
    this.setState({
      pdfChooseStatus: true,
      webUrl: webUrl,
    })
  }

  handleTextChangeMobile = (textValue: string) => {
    this.setState({
      keywordText: textValue
    })
  };

  highlightPdfData = () => {
    const header = {
      token: this.state.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.pdfHighlightId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.highlightPdfData}/2221`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);

    // this.setState({
    //   loading:true
    // })

    return true;
  }

  // onHighlight = (position: any, content: any, hideTipAndSelection: any, transformSelection: any) => {
  //   const highlight = {
  //     position,
  //     content,
  //     // hideTipAndSelection,
  //     // transformSelection
  //     // You can add additional properties to the highlight object if needed
  //   };
  //   this.setState({
  //     highlights: [...this.state.highlights, highlight]
  //   })

  //   return null;
  // }

  // getHighlightById(id: any) {
  //   const { highlights } = this.state;

  //   return highlights.find((highlight) => highlight.id === id);
  // }


  // scrollViewerTo = (highlight: any) => { };

  // scrollToHighlightFromHash = () => {
  //   const highlight = this.getHighlightById(document.location.hash.slice('#highlight-'.length));

  //   if (highlight) {
  //     this.scrollViewerTo(highlight);
  //   }
  // };




  // onMessageCall = (event: WebViewMessageEvent) => {

  //     if(event.nativeEvent.data){
  //       const pdfValue = event.nativeEvent.data;
  //       const getUrl = event.nativeEvent.url;

  //       const urlDataCheck = getUrl.split('&pdfdata');

  //       if(urlDataCheck.length == 2){
  //         const delimiter = "pdf;base64,";
  //         const resultArray = pdfValue.split(delimiter);

  //         let pdfData = resultArray[1];
  //         this.saveBlobToFile(pdfData);
  //       }
  //     }

  // }

  // saveBlobToFile = async (pdfData:string) => {

  //   const filePath = `${RNFS.DownloadDirectoryPath}/updatedFile.pdf`;
  //   await RNFS.writeFile(filePath, pdfData, 'base64');

  //   alert("File downloaded successfully, can you check your download folder")
  // };
  
  
  // Customizable Area End
}

// Customizable Area End
