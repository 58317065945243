// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";

export interface Props {
  navigation:any;
  id: any;
  history: any;
  classes: any;
  location: any;
}

interface SS {}

interface S {
  token: string | undefined,
  isComment: boolean,
  inputComment: string,
  replyComment: string,
  isDisableCommentButton: boolean,
  isDisableReplyCommentButton: boolean,
  showComment: any,
  anchorEl: null,
  isDeleteCommentModalOpen: boolean,
  isEdit: boolean,
  isFromReply: boolean,
  selectedItem: any,
  noInvoivePdf: any,
  numPages: number,
  noInvoivePdfError: boolean,
  uploadfileId: string,
  rejectApproveApiError: any,
  apiError: any,
  approveRejectStatus: string
}
export const configJSON = require("./config");

export default class InvoiceCommentsController extends BlockComponent<
  Props,
  S,
  SS
> {
  /**
   *
   */
  getCommentApiId: string = "";
  addCommentApiId: string = "";
  updateCommentApiId: string = "";
  deleteCommentApiId:  string = "";
  addReplyApiId: string = "";
  updateReplyApiId: string = "";
  deleteReplyApiId: string = "";
  noinvoiceApprovalApiId: string = "";
  rejectApproveApiId: string = "";

  constructor(props: Props) {
    super(props);
    this.state = {
      token:'',
      isComment: false,
      inputComment: "",
      replyComment: "",
      isDisableCommentButton: true,
      isDisableReplyCommentButton: true,
      showComment: [],
      anchorEl: null,
      isDeleteCommentModalOpen: false,
      isEdit: false,
      isFromReply: false,
      selectedItem: {},
      noInvoivePdf: '',
      noInvoivePdfError: false,
      numPages: 0,
      uploadfileId: this.props.location?.state?.idState.id,
      rejectApproveApiError: {},
      apiError: '',
      approveRejectStatus: ""
    };
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
    ];
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages)
  }


  async componentDidMount() {
    const getToken:any = await document.cookie.split("; ").find((row) => row.startsWith("Token="))?.split("=")[1];
    this.setState({ token: getToken });
    this.getCommentApi()
    this.getNoInvoiceApprovalApi()
  }


  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const invoicesApprovalApiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const invoicesApprovalResponseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (invoicesApprovalApiRequestCallId === this.addCommentApiId) {
        this.addCommentApiResponseHandle(invoicesApprovalResponseJson)
      }
      if (invoicesApprovalApiRequestCallId === this.getCommentApiId) {
        this.getCommentApiResponseHandle(invoicesApprovalResponseJson)
      }
      if(invoicesApprovalApiRequestCallId === this.addReplyApiId) {
        this.addReplyApiResponseHandle(invoicesApprovalResponseJson)
      }
      this.handleAPIReceiver(invoicesApprovalApiRequestCallId, invoicesApprovalResponseJson)
    }
  }

  handleAPIReceiver = (invoicesApprovalApiRequestCallId:any, invoicesApprovalResponseJson:any) => {
    if(invoicesApprovalApiRequestCallId === this.updateCommentApiId) {
      this.updateCommentResponseHandle(invoicesApprovalResponseJson)
    }
    if(invoicesApprovalApiRequestCallId === this.deleteCommentApiId) {
      this.deleteCommentResponseHandle(invoicesApprovalResponseJson)
    }
    if(invoicesApprovalApiRequestCallId === this.updateReplyApiId) {
      this.updateReplyResponseHandle(invoicesApprovalResponseJson)
    }
    if(invoicesApprovalApiRequestCallId === this.deleteReplyApiId) {
      this.deleteReplyResponseHandle(invoicesApprovalResponseJson)
    }
    if(invoicesApprovalApiRequestCallId === this.noinvoiceApprovalApiId) {
      this.noCompareResponseHandle(invoicesApprovalResponseJson)
    }
    if(invoicesApprovalApiRequestCallId === this.rejectApproveApiId) {
      this.handleRejectAndApproveApiResponse(invoicesApprovalResponseJson)
    }
  }

  handleRejectAndApproveApiResponse = (responseJson: any) => {
    if(responseJson.errors || responseJson.error){
      this.setState({ rejectApproveApiError:  responseJson })
    } else {
      this.setState({ rejectApproveApiError:  {} })
    }

    if(this.state.approveRejectStatus == "To Be Paid"){
      this.props.history.push('/sorting/tobepaid-documents')
    }else{
      this.props.history.push('/sorting/reject-documents')
    }
  }

  noCompareResponseHandle = (response: any) => {
    if (response.errors || response.error) {
      this.setState({noInvoivePdfError: response.error})
    } else {
      this.setState({ noInvoivePdf: response.pdf_url})
    }
  }

  deleteReplyResponseHandle = (response: any) => {
    if (response.errors || response.error) {
      this.setState({ apiError: response.error })
    } else {
      this.getCommentApi()
      this.setState({ isDeleteCommentModalOpen: false, isFromReply: false })
    }
  }

  updateReplyResponseHandle = (response: any) => {
    if (response.errors || response.error) {
      this.setState({ apiError: response.error })
    } else {
      this.getCommentApi()
      this.setState({ 
        isDisableCommentButton: true, 
        inputComment: '',
        isFromReply: false
      })
    }
  }

  deleteCommentResponseHandle = (response: any) => {
    if (response.errors || response.error) {
      this.setState({ apiError: response.error })
    } else {
      this.getCommentApi()
      this.setState({ isDeleteCommentModalOpen: false })
    }
  }

  updateCommentResponseHandle = (response: any) => {
    if (response.errors || response.error) {
      this.setState({ apiError: response.error })
    } else {
      this.getCommentApi()
      this.setState({ 
        isDisableCommentButton: true, 
        inputComment: ''
      })
    }
  }

  addReplyApiResponseHandle = (response: any) => {
    if (response.errors || response.error) {
      this.setState({ apiError: response.error })
    } else {
      this.getCommentApi()
      this.setState({ replyComment: "", selectedItem: {} })
    }
  }

  getCommentApiResponseHandle = (response: any) => {
    if (response.errors || response.error) {
      this.setState({ apiError: response.error })
    } else {
      if(response.message == "No comments."){
        this.setState({ showComment: [], apiError: '' })
      } else {
        const data = response.data.map((comment:any) => {
          return  { ...comment, isReply: false }
        })
        this.setState({ showComment: data.reverse(), apiError: '' })
      }
    }
  }

  addCommentApiResponseHandle = (response: any) => {
    if (response.errors || response.error) {
      this.setState({ apiError: response.error })
    } else {
      this.getCommentApi()
      this.setState({ 
        isDisableCommentButton: true, 
        inputComment: ''
      })
    }
  }

  handleLeaveComment = () => {
    this.setState({ isComment: !this.state.isComment})
  }

  handleReplyCommentChange = (event:  React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const { target: { value } } = event;
    this.setState({ replyComment: value, isDisableReplyCommentButton: false })
  }

  handleReplyCancelButton = () => {
    this.setState({ replyComment: "", isDisableReplyCommentButton: true })
  }

  handleReplySubmitButton = (item: any) => {
    if(this.state.replyComment.trim() != "") {
    
      const data = this.state.showComment.map((comment:any) => 
        comment.id === item.id ? 
          {...comment, isReply: false } : comment
      )
      
      this.setState({ showComment: data })
      this.addReplyApi()
    } else {
      this.setState({ isDisableReplyCommentButton: true, replyComment: '' })
    }
  }

  handleReplyButton = (item: any) => {
    const data = this.state.showComment.map((comment:any) => 
      comment.id === item.id ? 
        { ...comment, isReply: !comment.isReply } : { ...comment, isReply: false }
    )

    this.setState({ showComment: data, selectedItem: item })
  }

  handleInputCommentChange = (event:  React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const { target: { value } } = event;
    this.setState({ inputComment: value, isDisableCommentButton: false })
  }

  handleCancelButton = () => {
    this.setState({ inputComment: "", isDisableCommentButton: true })
  }

  handleUpdateCommentReply = () => {
    this.state.isFromReply ?  
     this.editReplyApi() : this.editCommentApi()
  }

  handleSubmitApiCall = () => {
    this.state.isEdit ?  
     this.handleUpdateCommentReply() : this.addCommentApi()
  }

  handleInputSubmitButton = () => {
    if(this.state.inputComment.trim() != "") {
      this.handleSubmitApiCall()
    } else {
      this.setState({ isDisableCommentButton: true, inputComment: '' })
    }
  }

  handleOpenCommentMenu = (event: any | React.MouseEvent<HTMLButtonElement>, item: any, fromReply: boolean) => {
    event.stopPropagation();
    this.setState({ anchorEl: event.currentTarget, selectedItem: item, isFromReply: fromReply });
  };

  handleClickCloseCommentMenu = (event: any | React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    this.setState({ anchorEl: null, selectedItem: {}, isFromReply: false });
  };

  handleClickEditCommentMenu = (event: any | React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    this.setState({ 
      isEdit: true, 
      inputComment: this.state.selectedItem.comment || this.state.selectedItem.user_reply, 
      anchorEl: null, 
      isDeleteCommentModalOpen: false 
    })
  }

  handleClickDeleteCommentMenu = (event: any | React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    this.setState({ anchorEl: null, isDeleteCommentModalOpen: true, isEdit: false })
  }

  handleCloseDeleteCommentDialog = () => {
    this.setState({ isDeleteCommentModalOpen: false })
  }

  handleCommentDeleteDailogConfirm = () => {
    this.state.isFromReply ? this.deleteReplyApi(): this.deleteCommentApi()
  }

  deleteCommentApi = () => {
    const header = {
      "Content-Type": configJSON.noApprovalApiContentType,
      "token": this.state.token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.deleteCommentApiId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.deleteCommentAPiEndPoint}${this.state.selectedItem.id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }

  deleteReplyApi = () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": this.state.token
    };
    
    const httpBody = {
      reply: {
        id: this.state.selectedItem.id
      }
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.deleteReplyApiId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.deleteReplyApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }

  editReplyApi = () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": this.state.token
    };

    const httpBody = {
      reply: {
        id: this.state.selectedItem.id,
        user_reply: this.state.inputComment
      },
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.updateReplyApiId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.updateReplyApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.sellerDetailsAPIMethodPATCH
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }

  addReplyApi = () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": this.state.token
    };

    const httpBody = {
        reply: {
          user_reply: this.state.replyComment
        }
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.addReplyApiId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.addReplyApiEndPoint}${this.state.selectedItem.id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.fileUploadApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }

  editCommentApi = () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": this.state.token
    };

    const httpBody = {
      comment: {
        comment: this.state.inputComment
      },
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.updateCommentApiId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.updateCommentApiEndPoint}${this.state.selectedItem.id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.sellerDetailsAPIMethodPATCH
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }

  addCommentApi = () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": this.state.token
    };

    const httpBody = {
      comment: {
        comment: this.state.inputComment
      },
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.addCommentApiId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.addCommentApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.fileUploadApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }

  getCommentApi = () => {
      const header = {
        "Content-Type": configJSON.validationApiContentType,
        "token": this.state.token
      };
  
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
  
      this.getCommentApiId = requestMessage.messageId;
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.getCommentAPiEndPoint
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.validationApiMethodType
      );
  
      runEngine.sendMessage(requestMessage.id, requestMessage);
  
      return true;
  }

  getNoInvoiceApprovalApi = () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": this.state.token
    };

    const requestInvoiceMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.noinvoiceApprovalApiId = requestInvoiceMessage.messageId;
    
    requestInvoiceMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.noinvoiceApprovalApiEndPoint}?quote_id=${this.state.uploadfileId}`
    );

    requestInvoiceMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestInvoiceMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestInvoiceMessage.id, requestInvoiceMessage);

    return true;

  }

  rejectAndApproveBtnApi = (status: string) => {
    this.setState({approveRejectStatus: status})
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": this.state.token
    };

    const requestInvoiceDocMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.rejectApproveApiId = requestInvoiceDocMessage.messageId;
    
    requestInvoiceDocMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.rejectAndApproveToPayApiEndPoint}?id=${this.state.uploadfileId}&approval_status=${status}`
    );

    requestInvoiceDocMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestInvoiceDocMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.fileUploadApiMethod
    );

    runEngine.sendMessage(requestInvoiceDocMessage.id, requestInvoiceDocMessage);

    return true;
  }


}

// Customizable Area End