import { SET_IMAGE } from "./actions";


const initialState={
     docImgage: "dsklndkslnf",
     imageChangeFlag: true,
}


export const notificationReducer = (state=initialState, action: any) => {
    switch (action.type) {
        case SET_IMAGE: {
            return { ...state, docImg:action.payload.image,imageChangeFlag:action.payload.bol}
        }
        default :
            return { ...state };
        
    }
}