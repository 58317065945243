// Customizable Area Start
import React from "react";
import { Typography, Box } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

import Header from "../../landingpage/src/Header.web";
import DashboardNewTable from "../../landingpage/src/DashboardNewTable.web";
import DocumentsReportController, {
  Props,
  configJSON,
} from "./DocumentsReportController.web";

class DocumentsReport extends DocumentsReportController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  render() {
    const { classes } = this.props;
    return (
      <>
        <Header
          data-testid="DocumentsReportHeaderId"
          handleSaveData={this.handleSaveData}
          navigation={this.props.navigation}
          history={this.props.history}
        />
        <Box className={classes.ReportCompareBox}>
          <Box className={classes.ReportHeading}>
            <Typography variant="h5">Reports</Typography>
            <Typography>
              Lorem ipsum dolor sit amet, consectetur adipisicing elit. Natus
              unde omnis perferendis iure consectetur. Ab, earum aperiam
              perferendis molestias enim sint.
            </Typography>
          </Box>
        </Box>
        <DashboardNewTable
          rows={this.state.reportData}
          title={configJSON.documentReportTitle}
          hasMenu={true}
          hasStatusData={true}
          navigatePath="/document/report-generation"
        />
      </>
    );
  }
}

export const webStyle = {
  ReportCompareBox: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "15px",
  },
  ReportUploadButton: {
    "& button": {
      width: "100%",
      minWidth: "172px",
      marginTop: "15px",
    },
  },
  reportNavLinkStyle: {
    textDecoration: "none",
    "&:focus, &:hover, &:visited, &:link, &:active": {
      textDecoration: "none",
    },
  },
  ReportHeading: {
    paddingRight: "5px",
  },
};

export { DocumentsReport };
export default withStyles(webStyle)(DocumentsReport);

// Customizable Area End
