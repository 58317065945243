import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

export interface Props {
    hasDateRange: boolean;
    dateRangeValue: any;
    handleDateRangeValue: (item: any) => void;
    handleDateRangeModal: () => void;
    navigation: any;
}

interface SS {
}
interface S {
}

export const configJSON = require("./config");

export default class DateRangeDialogController extends BlockComponent<
  Props,
  S,
  SS
> {
  /**
   *
   */
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.state = {

    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

  }

}
