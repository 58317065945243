import React from "react";
// Customizable Area Start
import "./index.css";
import { withStyles, withTheme,styled } from "@material-ui/core/styles";
import FooterControllerWeb, { Props } from "./FooterController.web";

import {
  logoFacebook,
  logoInstagram,
  logoTwitter,
  logoFooterMyInvoiceCheck
} from "./assets";
import Grid from "@material-ui/core/Grid";
import { NavLink } from "react-router-dom";
import {Box,Typography} from "@material-ui/core";



const FooterMainBox = styled(Box)({
  display:"flex",
  gap:"30px"
})

const FooterText = styled(Typography)({
  fontFamily:"Poppins",
  fontWeight:500,
  fontSize:"16px",
  lineHeight:"24px",
  letterSpacing:"0.5px",
  color:"#FFFFFF",
  cursor:"pointer",
})


// Customizable Area End

const styles = () => ({
  logo: {
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    width: 250,
    height: 61.95,
    backgroundSize: "contain",
    borderRadious: "50%",
    backgroundImage: `url(${logoFooterMyInvoiceCheck})`
  },
  socialmediaIcon: {
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    height: 26.13,
    width: 28.19,
    backgroundSize: "contain",
    border: "1px solid red"
  },
  facebook: {
    backgroundImage: `url(${logoFacebook})`
  },
  twitter: {
    backgroundImage: `url(${logoTwitter})`
  },
  instagram: {
    backgroundImage: `url(${logoInstagram})`
  }
});

export default class Footer extends FooterControllerWeb {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  footer = (classes: any) => {
    return (
      <FooterMainBox>
        <FooterText data-test-id="privacy-policy" onClick={()=>this.props.navigation.navigate("PrivacyPolicy")}>
          Privacy Policy
        </FooterText>
        <FooterText data-test-id="terms-and-conditions" onClick={()=>this.props.navigation.navigate("Termsandconditions")}>
        Terms of Services
        </FooterText>
      </FooterMainBox>
    );
  };
  // Customizable Area End

  render() {
  // Customizable Area Start
    const { classes } = this.props;
    return (
      <div>
        <div className={"footer-container"}>
          <div className={"container"}>
            {this.footer(classes)}
          </div>
        </div>
      </div>
    );
  }
  // Customizable Area End
}
  // Customizable Area Start
 withTheme(withStyles(styles)(Footer));
  // Customizable Area End
