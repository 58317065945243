Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.labelTitleText = "Bulk Upload";
exports.uploadBtnLabel = "Upload";
exports.clearFileBtnLabel = "Clear files";
exports.submitBtnLabel = "Submit";
exports.getUploadedFileLabel = "Get All Uploaded File";
exports.idLabel = "ID:";
exports.statusLabel = "Status: ";
exports.deleteBtnLabel = "Delete";
exports.removeBtnLabel = "Remove";
exports.downloadBtnLabel = "Download";
exports.apiContentType = "application/json"
exports.createContentType = "multipart/form-data"
exports.createBulkUploadEndpoint = "bulk_uploading/attachments";
exports.createBulkUploadMethod = "POST";
exports.uploadingSuccessMsg = "File uploaded Successfully!"
exports.getBulkUploadEndpoint = "bulk_uploading/attachments";
exports.getBulkUploadMethod = "GET";
exports.deleteBulkUploadSingleEndpoint = "bulk_uploading/attachments/delete_attachment";
exports.deleteBulkUploadEndpoint = "bulk_uploading/attachments";
exports.deleteBulkUploadMethod = "DELETE";
exports.deletedSuccessMsg = "Attachment file deleted successfully!";
exports.fileSizeErrorMsg = " is too large. Allowed maximum size is 5MB!";
exports.downloadedSuccessMsg = "file has been downloaded! File Path: ";
exports.downloadingFailedMsg = "Something went wrong!";
exports.somethingWentWrongMsg = "Something went wrong!";
exports.loginAlertMessage = "Please login first.";

exports.notificationApiContentType = "application/json";
exports.putNotificationsApiMethod = "PUT";
exports.getNotificationsApiEndPoint = "bx_block_notifications/notifications";
exports.getQuotesNotificationApiEndPoint = "bx_block_notifications/quotes?search=";
exports.getInvoicesNotificationApiEndPoint = "bx_block_notifications/invoices?search=";
exports.getComparisonNotificationApiEndPoint = "bx_block_notifications/comparison?search=";
exports.getProjectsNotificationApiEndPoint = "bx_block_notifications/projects?search=";
exports.getVendorsNotificationApiEndPoint = "bx_block_notifications/vendors?search=";
exports.deleteNotificationsApiEndPoint = "bx_block_notifications/delete_multiple?notification_ids=";
exports.notificationDeleteApiEndpoint = "bx_block_notifications/notifications";
exports.markAsReadNotificationApiEndPoint = "bx_block_notifications/read_multiple?notification_ids=";
exports.searchNotificationApiEndPoint = "bx_block_notifications/notifications?search=";
exports.getClickNotificationApiEndPoint = "bx_block_notifications/notifications";
exports.replyNotificationApiEndPoint = "bx_block_notifications/reply_notification";
exports.addProjectEndPoint = "bx_block_projects/projects"


exports.replyTitle = "Reply"
exports.typeHereContent = "Type here to reply..",
exports.GotoreportTitle = "Go to report"