// Customizable Area Start
import React from "react";
import { Box, Grid, Typography } from "@material-ui/core";
import {
  createTheme,
  withStyles,
} from "@material-ui/core/styles";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ReportsController, { Props, configJSON } from "./ReportsController.web";
import DashboardNewTable from "../../landingpage/src/DashboardNewTable.web";
import Header from "../../landingpage/src/Header.web";
// Customizable Area End

// Customizable Area Start
const theme = createTheme({
  typography: {
    h6: {
      color: " #05052b",
      fontSize: "25px",
    },
  },
});
// Customizable Area End

class Reports extends ReportsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes, navigation, handleSaveData }: any = this.props;
    // Customizable Area End

    return (
      // Customizable Area Start
      <>
        <Box>
            <Header navigation={navigation} handleSaveData={handleSaveData} history={this.props.history} />
            <Typography variant="h6" className={classes.reportsTitle}>
              <ChevronLeftIcon onClick={this.handleReportBackClick} data-testid="reportsBackClick" />
              {configJSON.libraryContent}
            </Typography>
            <Grid container>
              <Grid
                item
                lg={12}
                md={12}
                sm={12}
                xs={12}
                className={classes.headerReportBox}
              >
                <DashboardNewTable
                  navigatePath="/sorting/Reports"
                  rows={this.state.reportsInfo}
                  title="Reports"
                  hasDelta={true}
                  hasMenu={true}
                />
              </Grid>
            </Grid>
        </Box>
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
export const reportTableStyle = (theme: any) => ({
  headerReportBox: {
    "& .MuiTableContainer-root": {
      height: "50vh",
    },
    "& table": {
      height: "100%",
    },
  },
  reportsTitle: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: '8px',
    padding: '20px 0',
    '& svg': {
      fontSize: '32px'
    }
  }
});

export { Reports }
export default withStyles(reportTableStyle)(Reports);
// Customizable Area End
