import React from "react";
// Customizable Area Start
import "./index.css";
import { TextField, Button, Box, Typography } from "@material-ui/core";
import { Theme } from "@material-ui/core";
import { withStyles, withTheme } from "@material-ui/core/styles";
import ProfileDetailWebController, { Props } from "./ProfileDetailWebController.web";
import { imgHowitworks } from "../src/assets";
import { styled } from '@material-ui/styles';
import {
  quoteUploadIcon,
  closeIcon,
  uploadSuccessIcon
} from "./assets";
import Header from "./Header.web";
import Modal from '@material-ui/core/Modal';
// Customizable Area End

const styles = (theme: Theme) => ({
  logo: {
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    width: "100%",
    backgroundSize: "contain",
    borderRadious: "50%",
    backgroundImage: `url(${imgHowitworks})`,
    [theme.breakpoints.up("xs")]: {
      height: "10rem"
    },
    [theme.breakpoints.up("sm")]: {
      height: "10rem"
    },
    [theme.breakpoints.up("md")]: {
      height: "14rem"
    },
    [theme.breakpoints.up("lg")]: {
      height: "25rem"
    }
  }
});

const StyledTextFeildLogin = styled(TextField)({
  '& input': {
    paddingTop: '6px',
    paddingBottom: '6px',
    height: '35px',
    width: '100%',
    backgroundColor: '#fef8f3',
  },
  '& .MuiFormLabel-root[data-shrink="false"]': {
    transform: 'translate(14px, 16px) scale(1)',
  },
  '& .MuiInputLabel-outLined.MuiInputLabel-shrink': {
    transform: "translate(12px, -6px) scale(0.70)"
  }

});

const StyledTextFeildLoginConfirmPassword = styled(TextField)({
  '& input': {
    paddingTop: '6px',
    paddingBottom: '6px',
    height: '35px',
    width: '100%',
    backgroundColor: '#fef8f3',
  },
  '& .MuiFormLabel-root[data-shrink="false"]': {
    transform: 'translate(14px, 16px) scale(1)',
  },
  '& .MuiInputLabel-outLined.MuiInputLabel-shrink': {
    transform: "translate(12px, -6px) scale(0.70)"
  }
});

const StyledTextFeildLoginNewPassword = styled(TextField)({
  '& input': {
    paddingTop: '6px',
    paddingBottom: '6px',
    height: '35px',
    width: '100%',
    backgroundColor: '#fef8f3',
  },
  '& .MuiFormLabel-root[data-shrink="false"]': {
    transform: 'translate(14px, 16px) scale(1)',
  },
  '& .MuiInputLabel-outLined.MuiInputLabel-shrink': {
    transform: "translate(12px, -6px) scale(0.70)"
  }
});

const StyledButtonLogin = styled(Button)({
  background: '#ef7927',
  width: '100%',
  height: "45px",
  textTransform: 'capitalize',
  padding: '16px',
  fontWeight: 600,
  marginTop: '20px',
  borderRadius: '8px',
  boxShadow: 'none',
  '&:hover': {
    background: '#ef7927',
  }

});

const StyledButtonChange = styled(Button)({
  background: '#ef7927',
  width: '100%',
  height: "45px",
  textTransform: 'capitalize',
  padding: '16px',
  fontWeight: 600,
  marginTop: '20px',
  borderRadius: '4px',
  boxShadow: 'none',
  '&:hover': {
    background: '#ef7927',
  }

});


 export default class ProfileDetailWeb extends ProfileDetailWebController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    return (
      <div className="profle_section">
        <Header
          navigation={this.props.navigation}
          handleSaveData={this.props.handleSaveData} history={this.props.history}        />
          <div style={webStyle.wrapperWhite}>
            <Box sx={webStyle.wrapperLeft}>
              <div style={webStyle.navUserBox}>
                <img src={quoteUploadIcon} alt="ProfileIcon" />
              </div>
              <Box sx={webStyle.navUserBoxText}>
                <div style={webStyle.navUserBoxTextName}>{this.state.initialName}</div>
                <div style={webStyle.navUserBoxTextEmail}>{this.state.email}</div>
                <button 
                  className={`reset-password ${this.state.hasChangePassword && 'active'}`}
                  onClick={() => this.handleChangePassword()}
                  data-testid="resetButtonId"
                >
                  Reset Password
                </button>
                <button 
                  className={`logout_profile ${this.state.hasChangePassword && 'active'}`}
                  onClick={() => this.handleLogout()}
                  data-testid="logoutButtonId"
                >
                  Logout
                </button>
              </Box>
            </Box>

            <Box sx={webStyle.wrapperRight}>
              {this.state.hasChangePassword ? 
                <div>
                  <Box sx={webStyle.wrapperResetTitle}>
                   <Box component={'span'} sx={webStyle.resetTitle}>{"Reset Password"}</Box>
                   <span>{"Enter your new password below,to create a new password"}</span>
                  </Box>

                  <Box sx={webStyle.labelTxtLoginBox}>
                    <label style={webStyle.labelTxtLogin}>Old Password</label>
                    <StyledTextFeildLogin
                      name="oldPassword"
                      id="oldPassword"
                      data-testid="oldpasswordId"
                      placeholder="Enter old password"
                      value={this.state.oldPassword}
                      variant="outlined"
                      type="password"
                      error={
                        this.state.passwordError !== ""
                      }
                      helperText={this.state.passwordError}
                      onChange={this.handleChangeTextField}
                    />
                  </Box>

                  <Box sx={webStyle.labelTxtLoginBox}>
                    <label style={webStyle.labelTxtLogin}>New password</label>
                    <StyledTextFeildLoginNewPassword
                      name="newPassword"
                      id="newPassword"
                      data-testid="newPassword"
                      placeholder="Enter new password"
                      value={this.state.newPassword}
                      variant="outlined"
                      type="password"
                      error={
                        this.state.newPasswordError !== ""
                      }
                      helperText={this.state.newPasswordError}
                      onChange={this.handleChangeTextField}
                    />
                  </Box>

                  <Box sx={webStyle.labelTxtLoginBox}>
                    <label style={webStyle.labelTxtLogin}>Confirm Password</label>
                    <StyledTextFeildLoginConfirmPassword
                      name="confirmPassword"
                      id="confirmPassword"
                      data-testid="confirmPasswordId"
                      placeholder="Enter confirm password"
                      value={this.state.confirmPassword}
                      variant="outlined"
                      type="password"
                      error={
                        this.state.confirmPasswordError !== ""
                      }
                      helperText={this.state.confirmPasswordError}
                      onChange={this.handleChangeTextField}
                    />
                  </Box>
                </div>
                : 
                <Box component={'div'} sx={webStyle.profileWrapper}>
                  <Box sx={webStyle.labelTxtLoginBox}>
                    <label style={webStyle.labelTxtLogin}>Name</label>
                    <StyledTextFeildLogin
                      name="name"
                      id="name"
                      data-testid="nameInputId"
                      required
                      value={this.state.name}
                      variant="outlined"
                      type="email"
                      error={
                        this.state.nameError !== ""
                      }
                      helperText={this.state.nameError}
                      onChange={(event) => this.handleNameChange(event.target.value)}
                    />
                  </Box>

                  <Box sx={webStyle.labelTxtLoginBox}>
                    <label style={webStyle.labelTxtLogin}>Email</label>
                    <StyledTextFeildLogin
                      name="email"
                      id="email"
                      data-test-id="txtInputEmailLogin"
                      value={this.state.email}
                      variant="outlined"
                      type="email"
                      disabled={true}
                    />
                  </Box>

                  <Box sx={webStyle.labelTxtLoginBoxpassword}>
                    <label style={webStyle.labelTxtLogin}>Password</label>
                    <StyledTextFeildLogin
                      name="password"
                      id="password"
                      data-test-id="txtInputPasswordLogin"
                      value={"1234567"}
                      variant="outlined"
                      type="password"
                      disabled={true}
                    />
                    <Box sx={webStyle.labelBoxBtnChange}>
                      <StyledButtonChange
                        type="button"
                        variant="contained"
                        color="secondary"
                        data-testid="changeButtonId"
                        onClick={() => this.handleChangePassword()}
                      >
                        Change
                      </StyledButtonChange>
                    </Box>
                  </Box>

                </Box>
              }

              <div style={webStyle.labelTxtLoginBoxBtn}>
                <StyledButtonLogin
                  type="button"
                  variant="contained"
                  color="secondary"
                  data-testid="btnSaveChangeId"
                  onClick={() => this.handleResetPassword()}
                  disabled={this.state.hasChangePassword && (this.state.oldPassword === "" || this.state.newPassword === "" || this.state.confirmPassword === "")}
                >
                  { this.state.hasChangePassword ? "Reset Password" : "Save Changes" }
                </StyledButtonLogin>
              </div>

            </Box>
          </div>
        <Modal
          data-testid="updatePsswordModalId"
          open={this.state.isPasswordUpdated}
          onClose={() => this.handlePasswordUpdateModal()}
        >
            <Box style={webStyle.updateModalBox}>
              <Box style={webStyle.updateModalCloseIcon}>
                <img src={closeIcon} alt="close" style={webStyle.closeIconUpdateBox} onClick={this.handlePasswordUpdateModal} />
              </Box>
              <Box style={webStyle.closeDeleteIcon}>
                  <img src={uploadSuccessIcon} alt="upload" style={webStyle.deleteImgBox} />
              </Box>
              <Typography style={webStyle.updateModalTitle}>Password updated successfully.</Typography>
            </Box>
        </Modal>
      </div>
    );
  }
}

const webStyle = {
  wrapperWhite:{
    backgroundColor:'white',
    borderRadius: '20px',
    margin: '30px auto',
    display: 'flex',
    boxShadow: '#d2d2d2 0px 0px 8px',
    maxWidth: '900px',
    minHeight: '500px',
  },
  wrapperLeft:{
    width: '350px',
    borderRight: 'solid 1px #ddd',
    padding: '25px 20px',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  wrapperRight: {
    width: 'calc(100% - 350px)',
    padding: '25px 30px',
    boxSizing: 'border-box',
  },
  navUserBoxTextName: {
    fontSize: '16px',
    color: 'black',
    fontWeight: 500,
    width: '100%',
    textAlign: 'center' as 'center',
    wordWrap: 'break-word' as 'break-word'
  },
  navUserBoxTextEmail: {
    fontSize: '12px',
    color: '#626262',
    marginBottom: '40px',
  },
  navUserBox: {
    width: '150px',
    height: '150px',
    borderRadius: '100px',
    margin: '0 auto',
    overflow: 'hidden',
    background: '#e3e3e3',
    display: 'flex',
    alignContent: 'center',
    justifyContent: 'center'
  },
  navUserBoxText: {
    width: '100%',
    marginTop: '15px',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
  },
  wrapperResetTitle: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    fontSize: '12px',
    color: '#000',
    marginBottom: '30px',
  },
  resetTitle: {
    width: '100%',
    fontSize: '20px',
    color: '#000',
    fontWeight: 600,
    marginBottom: '6px',
    textAlign: 'center',
  },
  labelTxtLoginBoxBtn: {
    width: '100%',
    marginTop: '15px',
  },
  labelTxtLoginBox: {
    width: '100%',
    marginBottom: '15px',
    display: 'flex',
    flexDirection: 'column',
  },
  labelTxtLoginBoxpassword: {
    width: '100%',
    marginBottom: '15px',
    display: 'flex',
    flexDirection: 'column',
    position: "relative",
  },
  labelBoxBtnChange: {
    position: 'absolute',
    right: '0',
    top: '11px',
    width: '120px',
  },
  labelTxtLogin: {
    marginBottom: 7,
    fontWeight: 500,
    width: '100%',
    color: 'black',
    fontSize: '16px',
  },
  profileWrapper: {
    paddingTop: 50
  },
  updateModalBox: {
    padding: "28px 30px 50px",
    background: "#fff",
    width: "560px",
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    borderRadius: "10px",
  },
  updateModalTitle: {
      fontSize: "20px",
      fontWeight: 500,
      color: "#000000",
      textAlign: "center" as "center",
      margin: "25px",
  },
  updateModalCloseIcon: {
      textAlign: 'right' as 'right'
  },
  closeIconUpdateBox: {
      marginBottom: "10px",
      width: "35px",
      height: "35px"
  },
  closeDeleteIcon: {
    textAlign: "center" as "center",
  },
  deleteImgBox: {
    width: "100px",
    height: "100px"
  },

}

export const ProfileDetailStyle = withTheme(withStyles(styles)(ProfileDetailWeb));
