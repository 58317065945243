// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

export const configJSON = require("./config");


export interface RentalItemColumn {
    id: string;
    label: string;
}

export interface RentalDescription {
    id: number;
    created_at: string;
    updated_at: string;
    description: string;
    ord_quantity: any;
    price: any;
    amount: string;
    report_invoice_id: number;
    order_unit: any;
    tax: any;
    invoice_category_name: any;
    part_number: any;
    invoice_category_subtotal: any;
    equipment: string;
    minimum: string;
    day: string;
    week: string;
    month: string;
}

export interface Props {
    // Customizable Area Start
    navigation: any;
    id: string;
    classes: any;
    history: any;
    invoiceData: any
    handleSaveData: () => {},
    onContinueButtonClick: any,
    goBackButtonNavigation: any,
    invoiceTabs: any
    // boxItems?:any
    draggedItems?: any
    segmentName1: any,
    segmentTotal1: any,
    segmentName2: any,
    updated_table_data: any,
    checkedBox: boolean
    saveBtnUpdatedResponse:any,
    newUpdatedDataArr:any,

    // Customizable Area End
}

interface S {
    // Customizable Area Start
    invoiceDetails: any;
    invoiceColumn: columnInvoice[];
    apiError: boolean;
    invoiceTabIndex: any;
    invoiceTabs: any;
    tabheaderValue: any;
    invoicePage: any,
    invoiceRowsPerPage: any,
    updated_table_data: any;
    invoiceRentalItemColumn: RentalItemColumn[],
    itemsPerPage: number,
    pagination: any,
    invoicePages: number,
    // Customizable Area End
}

interface SS {
    // Customizable Area Start
    // Customizable Area End
}

interface columnInvoice {
    id: string; 
    label: string;
    align?: "left" | "center" | "right" | "justify" | "inherit";
    minWidth?: string | number ;
}

interface InvoiceMetaDataInteface {
    gross_subtotal: number | null,
    invoice_subtotal: number | null
}

interface InvoiceMetaDataChargesInteface {
    invoice_s_and_h_charges: number | null,
}

interface InvoiceMetaDataDateInteface {
    invoice_shipped_date: string | null,
    date: string | null
}
export interface SubTotalInvoiceBoxProps {
    classes: any,
    invoiceMetaData: InvoiceMetaDataInteface,
    updatedSubtotalValue:any
}

export interface DateTextFieldProps {
    classes: any,
    invoiceMetaData: InvoiceMetaDataDateInteface
}
export interface SubTotalChargesInvoiceProps {
    classes: any,
    invoiceMetaData: InvoiceMetaDataChargesInteface
}
export default class VerifyInvoiceController extends BlockComponent<
    Props,
    S,
    SS
> {

    constructor(props: Props) {
        super(props);
        this.state = {
            // Customizable Area Start

            invoiceTabIndex: 0,
            apiError: false,
            invoiceDetails: this.props.invoiceData,
            invoiceTabs: this.props.invoiceTabs,
            invoiceColumn: [
                {
                    id: 'Sr.no', label: 'Sr. No',
                },
                {
                    id: 'description', label: 'Description',
                },
                {
                    id: 'OrderQty.', label: 'Order Qty.',
                },
                {
                    id: 'unit', label: 'Unit',
                },
                {
                    id: 'unitPrice', label: 'Unit Price',
                },
                {
                    id: 'Ext. Price', label: 'Ext. Price'
                }
            ],

            tabheaderValue: 3,
            invoicePage: 1,
            invoiceRowsPerPage: 10,
            updated_table_data: this.props.updated_table_data,
            // updated_table_data: this.props.updated_table_data,
            invoiceRentalItemColumn: [
                {
                    id: 'Sr.no', label: 'Sr. No',
                },
                {
                    id: 'Equipment', label: 'Equipment',
                },
                {
                    id: 'Description', label: 'Description',
                },
                {
                    id: 'Qty.', label: 'Qty.',
                },
                {
                    id: 'Min.', label: 'Min.',
                },
                {
                    id: 'Daily', label: 'Daily'
                },
                {
                    id: 'Weekly', label: 'Weekly'
                },
                {
                    id: 'Monthly', label: 'Monthly'
                },
                {
                    id: 'Amount', label: 'Amount'
                },

            ],
            itemsPerPage: 5,
            pagination: {},
            invoicePages: 1,
            // Customizable Area End
        };

        this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async componentDidMount() {
        this.setState({
            invoiceDetails: this.props.invoiceData
        })
    }


    handleSaveData = (data: any) => {

    };

    handleTabIndexChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        this.setState({ invoiceTabIndex: newValue })
    }

    goBackToUploadInvoice = () => {
        this.props.history.goBack()
    }


    handleChangePage = (event: unknown, newPage: number) => {
        this.setState({ invoicePage: newPage })
    };

    handlePageChange = (event: any, page: any, columnId: any) => {
        let temp_data = this.state.updated_table_data;
        if (temp_data && temp_data[this.state.invoiceTabIndex]) {
            const updatedTablesData = [...temp_data[this.state.invoiceTabIndex].tablesData];
            updatedTablesData[columnId].pagination.page = page;
            temp_data[this.state.invoiceTabIndex].tablesData = updatedTablesData

        }

        this.setState(prevState => ({
            updated_table_data: temp_data
        }));
    }

    handleDataPageChange = (event: any, categoryName: any, page: any) => {
        this.setState((prevState) => ({
            pagination: {
                ...prevState.pagination,
                [categoryName]: page
            },
        }));
    }



}

// Customizable Area End
