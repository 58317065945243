import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { WithStyles } from "@material-ui/core";
// Customizable Area End

export const configJSON = require("./config");

// Customizable Area Start
interface VoiceOfUserAttributes {
  id: number;
  name: string;
  description: string;
  designation: string;
  rating: number;
  image: string;
}

export interface VoiceOfUser {
  id: string;
  type: string;
  attributes: VoiceOfUserAttributes;
}

interface VoiceOfUserApiResponse {
  data: VoiceOfUser[];
}
// Customizable Area End

export interface Props extends WithStyles {
  // Customizable Area Start
  classes: any;
  navigation: any;
  id: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  slideItems:any,
  currentIndex:number,
  itemsPerPage: number,
  voiceOfUsers:any
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class AboutQuotecheckControllerWeb extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getVoiceOfUsersId: string = "";
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceDataMessage),
      getName(MessageEnum.RestAPIResponceSuccessMessage),
      getName(MessageEnum.RestAPIResponceMessage),];
    this.state={
      slideItems:[],
      currentIndex: 0,
      itemsPerPage: 4,
      voiceOfUsers:[]

    }
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
    );
  
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
  );
  
  if (apiRequestCallId === this.getVoiceOfUsersId) {
    this.handleGetVoiceOfUsersResponse(responseJson)
  }
      }
    // Customizable Area End
  }

  // Customizable Area Start
  goToHome() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationHomeScreenMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  handleClickPrevSlide = ()=>{
    this.setState(prevState =>({
      currentIndex:Math.max(prevState.currentIndex - prevState.itemsPerPage,0)
    }))
  }

  handleClickNextSlide = ()=>{
    const {itemsPerPage,slideItems} = this.state;
    if(slideItems.length>3){
      const lastIndex = slideItems.length - 1;
      this.setState(prevState=>({
        currentIndex:Math.min(prevState.currentIndex + prevState.itemsPerPage,lastIndex - itemsPerPage + 1)
      }))
    }
   
  }

  async componentDidMount(): Promise<void> {
    this.getVoiceOfUsersData();
  }

    getToken = () => {
        return document.cookie.split("; ").find((row) => row.startsWith("Token="))?.split("=")[1]
    }

  getVoiceOfUsersData = () => {
    const header = {
        token: this.getToken()
    };

    const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getVoiceOfUsersId = requestMessage.messageId

    requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.voiceOfUsersEndpoint
    );

    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
    );

    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.voiceOfUsersApiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
};

  handleGetVoiceOfUsersResponse = (responseJson: VoiceOfUserApiResponse) => {
    this.setState({
     slideItems:responseJson.data
    })
   }
  // Customizable Area End
}
