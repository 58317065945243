// Customizable Area Start
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { IBlock } from "../../../framework/src/IBlock";
import Emitter from "../../BulkUploading/src/util";

export const configJSON = require("./config");

export interface Props {
    classes: any,
    navigation: any,
    handleSaveData: () => {},
    history: any
}
export interface TabPanelProps {
    // Customizable Area Start
    children?: React.ReactNode;
    index: number;
    value: number;
    // Customizable Area End
}
interface S {
    // Customizable Area Start
    value: number;
    reportsTab: any;
    accept: any;
    progressReportVal: number;
    quotesApiError: boolean;
    invoicesApiError: boolean;
    reportGenApiError: boolean;
    uploadQuoteAcceptedFile: any;
    uploadInvoiceAcceptedFile: any;
    invoiceCompareResult: string;
    invoiceCompareError: boolean;
    acceptedFileType: any;
    quotesData: any;
    quotesValue: string;
    invoicesData: any;
    invoicesValue: string;
    token: string | undefined;
    series: number[];
    options: {};
    isFullscreen: boolean;
    quoteUploadPdf: string;
    invoiceUploadPdf: string;
    quoteComparisonData: any;
    quoteAmountValue: string;
    resultDifferenceValue: number;
    getQuoteListId: string;
    getInvoiceListId: string;
    getIdErrorResponse: string;
    showIdErrorModal: boolean;
    quoteComparisonSuccessData: string;
    reportGenerationLoader: boolean;
    timeReportRemaining: number;
    lengthQuoteItem: number;
    lengthInvoiceItem: number;
    // Customizable Area End
}

interface SS {
    // Customizable Area Start
    // Customizable Area End
}

export default class ReportGenerationController extends BlockComponent<Props, S, SS> {
    // Customizable Area Start

    quotesListApiId: string = '';
    invoicesListApiId: string = '';
    quotesUploadApiId: string = '';
    invoiceUploadApiId: string = '';
    generateReportApiId: string = '';

    constructor(props: Props) {
        super(props);
        this.state = {
            value: 0,
            accept: [],
            quotesApiError: false,
            invoicesApiError: false,
            reportGenApiError: false,
            acceptedFileType: { "application/pdf": [".pdf"] },
            reportsTab: [
                {
                    id: 1,
                    type: "Uploading"
                },
                {
                    id: 2,
                    type: "Generate"
                },
                {
                    id: 3,
                    type: "Result"
                }
            ],
            progressReportVal: 0,
            timeReportRemaining: 20,
            uploadQuoteAcceptedFile: [],
            uploadInvoiceAcceptedFile: [],
            invoiceCompareResult: "",
            invoiceCompareError: false,
            quotesData: [],
            quotesValue: '',
            invoicesData: [],
            invoicesValue: '',
            token: '',
            isFullscreen: false,
            series: [],
            quoteUploadPdf: '',
            invoiceUploadPdf: '',
            quoteComparisonData: [],
            quoteComparisonSuccessData: "",
            quoteAmountValue: " ",
            resultDifferenceValue: 0,
            getQuoteListId: "",
            getInvoiceListId: "",
            getIdErrorResponse: "",
            showIdErrorModal: false,
            options: {
                chart: {
                    height: 300,
                    type: 'radialBar',
                },
                plotOptions: {
                    radialBar: {
                        startAngle: 0,
                        endAngle: 270,
                        hollow: {
                            margin: 0,
                            size: "30%",
                        },
                        track: {
                            background: "#e7e7e7",
                            strokeWidth: '100%',
                            margin: 15,
                        },
                        dataLabels: {
                            name: {
                                show: false,
                            },
                            value: {
                                fontSize: '20px',
                                fontWeight: 700
                            },
                            total: {
                                show: true,
                                label: 'TOTAL'
                            }
                        }
                    }
                },
                stroke: {
                    lineCap: "round",
                },
                colors: ['#000000', '#de8131'],
                labels: ['Invoice', 'Quote'],
                legend: {
                    show: true,
                    floating: true,
                    fontSize: '14px',
                    fontWeight: 600,
                    position: 'left',
                    offsetX: 48,
                    offsetY: 8,
                    labels: {
                        useSeriesColors: false,
                    },
                    markers: {
                        size: null,
                    },
                    itemMargin: {
                        vertical: 2
                    }
                },
            },
            reportGenerationLoader: false,
            lengthQuoteItem: 0,
            lengthInvoiceItem: 0,
        };
        this.subScribedMessages = [
            getName(MessageEnum.CountryCodeMessage),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.ReciveUserCredentials),
        ];
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages)
        // Customizable Area End
    }

    async componentDidMount() {
        const token = await document.cookie.split("; ").find((row) => row.startsWith("Token="))?.split("=")[1]
        this.setState({ token: token })
        this.getQuotesApi();
        this.getInvoicesApi();

    }


    async receive(from: string, message: Message) {
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            if (apiRequestCallId === this.quotesListApiId) {
                this.handleQuotesListResponse(responseJson)
            }

            if (apiRequestCallId === this.invoicesListApiId) {
                this.handleInvoicesListResponse(responseJson)
            }

            if (apiRequestCallId === this.quotesUploadApiId) {
                this.handleQuotesUploadResponse(responseJson)
            }

            if (apiRequestCallId === this.invoiceUploadApiId) {
                this.handleInvoicesUploadResponse(responseJson)
            }

            if (apiRequestCallId === this.generateReportApiId) {
                this.handleReportGenerateResponse(responseJson)
                this.differenceAmount(responseJson.Compare_Difference)

            }
        }
    }

    handleQuotesUploadResponse = (response: any) => {
        Emitter.emit("FETCH_NOTIFICATION_LIST_FOR_POPUP", {})
        if (response.errors || response.error) {
            const errorTitle = response.error === 'Internal Server Error' ? 'Failed to upload quote.' : response.error;
            this.setState({ 
                quotesApiError: true, 
                invoicesApiError: false, 
                reportGenApiError: false, 
                reportGenerationLoader: false,
                getIdErrorResponse: errorTitle,
                showIdErrorModal: true, 
            })
        } else {
            this.setState({
                quotesApiError: false,
                invoicesApiError: false,
                reportGenApiError: false,
                getQuoteListId: response.quote_id,
                quotesValue: response.quote_id,
                reportGenerationLoader: false,
                getIdErrorResponse: '',
                showIdErrorModal: false,
            })
            this.getQuotesApi()
        }
    }

    handleInvoicesUploadResponse = (response: any) => {
        Emitter.emit("FETCH_NOTIFICATION_LIST_FOR_POPUP", {})
        if (response.errors || response.error) {
            const InvoiceErrorTitle = response.error === 'Internal Server Error' ? 'Failed to upload invoice.' : response.error;
            this.setState({ 
                invoicesApiError: true, 
                quotesApiError: false, 
                reportGenApiError: false, 
                reportGenerationLoader: false,
                getIdErrorResponse: InvoiceErrorTitle,
                showIdErrorModal: true,  
            })
        } else {
            this.setState({
                quotesApiError: false,
                invoicesApiError: false,
                reportGenApiError: false,
                getInvoiceListId: response.invoice_id,
                invoicesValue: response.invoice_id,
                reportGenerationLoader: false,
                getIdErrorResponse: '',
                showIdErrorModal: false,
            })
            this.getInvoicesApi()
        }
    }

    handleQuotesListResponse = (response: any) => {
        if (response.errors || response.error) {
            this.setState({ quotesData: [] })
        } else {
            this.setState({ quotesData: response })
        }
    }

    handleInvoicesListResponse = (response: any) => {
        if (response.errors || response.error) {
            this.setState({ invoicesData: [] })
        } else {
            this.setState({ invoicesData: response })
        }
    }

    handleReportGenerateResponse = (response: any) => {
        console.log(response, 'invoiceC')
        if(response){
            console.log(response)
            const lenghQuote = response.Compare_Difference.find((quoteitem:any) => quoteitem?.quote_description)?.quote_description;
            const lenghInvoice = response.Compare_Difference.find((invoiceitem:any) => invoiceitem?.invoice_description)?.invoice_description;
            this.setState({ quoteComparisonData: response, lengthInvoiceItem: lenghInvoice?.length, lengthQuoteItem: lenghQuote?.length });
            this.props.history.push('/report/quote-invoice', { idState: { lenghQuote: lenghQuote, lenghInvoice: lenghInvoice},quoteComparisonData: response})
        }

        // const message: Message = new Message(getName(MessageEnum.NavigationMessage))    
        // message.addData(
        //     getName(MessageEnum.NavigationTargetMessage),
        //     'quotescanresult'
        // );
        // message.addData(getName(MessageEnum.NavigationPropsMessage), this.props)
        // const raiseMessage: Message = new Message(      
        //     getName(MessageEnum.NavigationPayLoadMessage)    
        // );
        // raiseMessage.addData(getName(MessageEnum.SessionResponseData), {      
        //     data: response,    
        // });    
        // message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);    
        // this.send(message);

        // console.log(message, 'message')
    }

    onQuotesUpload = (acceptedQuotesFile: any) => {
        this.setState({ uploadQuoteAcceptedFile: acceptedQuotesFile, reportGenerationLoader: true })

        const header = {
            "token": this.state.token
        };

        const formData = new FormData()

        acceptedQuotesFile.forEach((file: any) => {
            formData.append('files[]', file);
        });

        const requestQuotesUploadMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.quotesUploadApiId = requestQuotesUploadMessage.messageId;
        requestQuotesUploadMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.quotesUploadApiEndPoint
        );

        requestQuotesUploadMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestQuotesUploadMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            formData
        );

        requestQuotesUploadMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.exampleAPiMethod
        );

        runEngine.sendMessage(requestQuotesUploadMessage.id, requestQuotesUploadMessage);

        return true;
    }

    onInvoiceUpload = (acceptedInvoiceFile: any) => {
        this.setState({ uploadInvoiceAcceptedFile: acceptedInvoiceFile, reportGenerationLoader: true })

        const header = {
            "token": this.state.token
        };

        const invoiceFormData = new FormData()

        acceptedInvoiceFile.forEach((file: any) => {
            invoiceFormData.append('file', file);
        });

        const requestInvoicesUploadMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.invoiceUploadApiId = requestInvoicesUploadMessage.messageId;
        requestInvoicesUploadMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.invoicesUploadApiEndPoint
        );

        requestInvoicesUploadMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestInvoicesUploadMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            invoiceFormData
        );

        requestInvoicesUploadMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.exampleAPiMethod
        );

        runEngine.sendMessage(requestInvoicesUploadMessage.id, requestInvoicesUploadMessage);

        return true;
    }

    setQuotesValue = (event: any) => {
        this.setState({ quotesValue: event.target.value })
    }

    setInvoicesValue = (event: any) => {
        this.setState({ invoicesValue: event.target.value })
    }

    handleReportsTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        if (newValue == 0) {
            this.setState({
                value: newValue,
                quotesValue: "",
                invoicesValue: "",
                uploadQuoteAcceptedFile: [],
                uploadInvoiceAcceptedFile: []
            })
        }
    }

    getInvoicesApi = () => {
        const header = {
            "Content-Type": configJSON.validationApiContentType,
            "token": this.state.token
        };

        const requestInvoicesListMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.invoicesListApiId = requestInvoicesListMessage.messageId;
        requestInvoicesListMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.getInvoicesListApiEndPoint
        );

        requestInvoicesListMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestInvoicesListMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );

        runEngine.sendMessage(requestInvoicesListMessage.id, requestInvoicesListMessage);

        return true;
    }

    getQuotesApi = () => {
        const header = {
            "Content-Type": configJSON.validationApiContentType,
            "token": this.state.token
        };

        const requestQuotesListMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.quotesListApiId = requestQuotesListMessage.messageId;
        requestQuotesListMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.getQuotesListApiEndPoint
        );

        requestQuotesListMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestQuotesListMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );

        runEngine.sendMessage(requestQuotesListMessage.id, requestQuotesListMessage);

        return true;
    }

    handleGenerateReport = () => {
        this.setState({ reportGenerationLoader: true, value: 1 })
        this.startReportProgress()
        const header = {
            "token": this.state.token
        };

        const requestReportGenerateMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.generateReportApiId = requestReportGenerateMessage.messageId;
        requestReportGenerateMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.generateReportApiEndPoint}quote_id=${this.state.quotesValue}&invoice_id=${this.state.invoicesValue}`
        );

        requestReportGenerateMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestReportGenerateMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.exampleAPiMethod
        );

        runEngine.sendMessage(requestReportGenerateMessage.id, requestReportGenerateMessage);

        return true;
    }

    startReportProgress = () => {
        let reportCounter = 1;
        const reporttotalTime = 20;
        const reportintervalTime = 100;
        const interval = setInterval(() => {
            reportCounter += reportintervalTime;
            const newReportProgress = Math.min((reportCounter / (reporttotalTime * 1000)) * 100, 95);
            const remainingReportTime = Math.max(reporttotalTime - reportCounter / 1000, 1);
            this.setState({
                progressReportVal: newReportProgress, timeReportRemaining: remainingReportTime
            })
            if (this.state.value !== 1 && !this.state.reportGenerationLoader) {
                clearInterval(interval);
                this.setState({ progressReportVal: 0, timeReportRemaining: 20 })
            }
        }, reportintervalTime);
    }

    differenceAmount = (responseJson: any) => {
        // const unmatchedQuotes = responseJson.find((quoteitem:any) => quoteitem.unmatched_quote)?.unmatched_quote;
        // const desiredquoteValues = unmatchedQuotes.filter((quoteItem: any) => quoteItem.includes('/')).map((quoteValue:any) => quoteValue.split(' ').pop())
        
        // const unmatchedInvoice = responseJson.find((invoiceitem:any) => invoiceitem.unmatched_invoice)?.unmatched_invoice;
        // const desiredInvoiceValues = unmatchedInvoice.filter((invoiceItem: any) => invoiceItem.includes('/')).map((invoiceValue:any) => invoiceValue.split(' ').pop())

        // let resultDifference = desiredInvoiceValues.map((invoiceNum: number, index: number) => invoiceNum - desiredquoteValues[index])
        
        // const resultDifferenceTotal = Math.round(resultDifference.reduce((total: number, value: number) => total + value, 0));

        // this.setState({ resultDifferenceValue: resultDifferenceTotal })
    }

    handleCloseIdErrModal = () => {
        this.setState({ showIdErrorModal: false })
    }

    // Customizable Area End
}
