// Customizable Area Start

import { BlockComponent } from "../../../framework/src/BlockComponent";
interface SubMenuType {
  subTitle: string;
  subPath: string;
}

interface Props { }

interface SS { }
interface S {
  openMenu: string;
  currentPathName: string;
  librarySubMenu: SubMenuType[];
  quoteSubMenu: SubMenuType[];
  invoiceSubMenu: SubMenuType[];
  reportSubMenu: SubMenuType[];
  documentSubMenu: SubMenuType[];
  nonLayoutPath: any[];
  token: string | undefined;
  isPathValid: boolean;
  ishovered:boolean;
}
export default class LayoutController extends BlockComponent<Props, S, SS> {

  constructor(props: Props) {
    super(props);
    this.state = {
      openMenu: "",
      currentPathName: '',
      librarySubMenu: [
        { subTitle: 'All', subPath: '/sorting/all-documents' }, 
        { subTitle: 'To Be Paid', subPath: '/sorting/tobepaid-documents' }, 
        { subTitle: 'Reject', subPath: '/sorting/reject-documents' }
      ],
      quoteSubMenu: [
        { subTitle: 'All', subPath: '/quotes' },
        { subTitle: 'Upload Files', subPath: '/quotes/quotesUpload' },
        { subTitle: 'Comparison Files', subPath: '/quotes/quotescomparison' }
      ],
      invoiceSubMenu: [
        { subTitle: 'Invoices', subPath: '/invoice' },
        { subTitle: 'Invoice Upload', subPath: '/invoice/invoice-upload' }
      ],
      reportSubMenu: [
        { subTitle: 'All', subPath: '/report' },
        { subTitle: 'Quotes', subPath: '/report/quotes' },
        { subTitle: 'Invoices', subPath: '/report/invoice' },
        { subTitle: 'Report Generation', subPath: '/report/report-generation' },
      ],
      documentSubMenu: [
        { subTitle: 'All', subPath: '/document' },
        { subTitle: 'OCR', subPath: '/document/ocrUpload' },
        { subTitle: 'Upload Files', subPath: '' },
        { subTitle: 'Comparison Files', subPath: '/document/comparison' },
        { subTitle: 'Report Generation Files', subPath: '/document/report-generation' },
      ],
      nonLayoutPath: [
        '/landingpage',
        '/ForgotPassword',
        '/ForgotPasswordOTP',
        '/NewPassword',
        '/EmailAccountRegistration',
        '/EmailAccountLoginBlock',
        '/registration',
        '/login',
        '/recoverPassword',
        '/resetPassword',
        '/InfoPage',
        '/signup',
        "/Termsandconditions",
        "/PrivacyPolicy",
      ],
      token: undefined,
      isPathValid: true,
      ishovered:false,
    };
  }

  handleMouseEnter = () => {
    this.setState({ ishovered: true });
  };

  handleMouseLeave = () => {
    this.setState({ ishovered: false });
  };

  async componentDidMount() {
    const path = window.location.pathname
    const cookieValue = document.cookie.split('; ').find((row) => row.startsWith('Token='))?.split('=')[1];
    const isValid = this.state.nonLayoutPath.includes(path) || path === '/'
    this.setState({ currentPathName: path, isPathValid: isValid, token: cookieValue })
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>, snapshot?: SS | undefined): void {
    if (window.location.pathname !== prevState.currentPathName) {
      this.setState({ currentPathName: window.location.pathname })
      this.check(window.location.pathname)
    }
  }

  check = (path: string) => {
    const cookieValue = document.cookie.split('; ').find((row) => row.startsWith('Token='))?.split('=')[1];
    if (this.state.nonLayoutPath.includes(path) || path === '/' || path === '/DashboardWeb') {
      this.setState({ token: cookieValue })
    }
  }



}
// Customizable Area End
