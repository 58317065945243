Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.documentDeleteApiMethod = "DELETE"
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "OcropticalCharacterRecognition";
exports.labelBodyText = "OcropticalCharacterRecognition Body";
exports.vendorApiEndPoint = "bx_block_filter_items/search/vendors"
exports.projectApiEndPoint = "bx_block_filter_items/search/projects"
exports.allProjectsApiEndPoint = "bx_block_reports/reports/unique_document_types_and_projects"
exports.filterProjectApiEndPoint = "bx_block_filter_items/search/search"
exports.deleteDocumentApiEndPoint = "bx_block_reports/reports"

exports.btnExampleTitle = "CLICK ME";
exports.documentsTitle = "Documents";
exports.projectName = "Dynamic Representative";
exports.projectdate = "5 Jan 2022, 07:01 AM";
exports.filterItemButton = "Filter Items";
exports.vendorTitle = "Vendor";
exports.projectTitle = "Project";
exports.placeholdervendorTitle = "Choose Vendor";
exports.placeholderprojectTitle = "Choose Project";
exports.dateRangeTitle = "Date Range";
exports.cancelButton = "Cancel";
exports.applyButton = "Apply"
exports.recordcontent = "No records found"

exports.documentComparisonTitle = "Comparison Files"
exports.documentsComparisonApiEndPoint = "bx_block_reports/reports/comparison_document";


exports.ocrDocumentModalTitle = "Result of Algorithm";
exports.ocrDocumentModalSuccessContent = "Algorithm has matched 100%, now you can view and download the file";
exports.documentModalViewButton = "View";
exports.documentModalDownloadButton = "Download";
exports.ocrDocumentModalFailedContent = "Your algorithm has not matched, now you can edit and re-upload the file.";
exports.documentModalEditButton = "Edit";
exports.documentModalUploadButton = "Upload";
exports.documentModalDownloadButton = "Download";
exports.ocrMatchAlgorithmEndPoint = "bx_block_filter_items/quotes_libraries/read_all_data"

exports.getNotificationsEndPoint = "push_notifications/push_notifications";
exports.getNotificationsAPIMethod = "GET";
exports.AlgoModalCancelButton = "Cancel";
exports.AlgoModalOkButton = "Ok";

exports.allDocumentsListApiEndPoint = "bx_block_reports/reports/unique_document_types_and_projects"


exports.getApiMethod = "GET";
exports.documentReportTitle = "Invoices";
exports.documentReportApiEndPoint = "bx_block_filter_items/invoice_libraries"
// Customizable Area End