import React from "react";
// Customizable Area Start
import "./index.css";
import { Theme,styled,Box,Typography } from "@material-ui/core";
import { withStyles, withTheme } from "@material-ui/core/styles";
import AboutQuotecheckControllerWeb, {
  Props,
  VoiceOfUser
} from "./AboutQuotecheckController.web";
import { aboutquotecheckLogo,howItWorkUnderline,leftArrowSlide,rightArrowSlide,quoteIconSlide, userImgSlide,leftArrowEnabled} from "../src/assets";
import Grid from "@material-ui/core/Grid";
import Rating from '@material-ui/lab/Rating';





const HeaderBox = styled(Grid)({
  display:"flex",
  justifyContent:"space-between",
  "@media (max-width: 550px)":{
    flexDirection:"column",
    alignItems:"center"
  }
})

const BlankBox = styled(Box)({
  "@media (max-width: 550px)":{
    display:"none"
   },
})


const SectionHeadingWithUnderlineBox = styled(Box)({
  display:"flex",
  flexDirection:"column",
  justifyContent:"center",
  alignItems:"center"
})

const SectionHeadingText = styled("div")({
  fontWeight:500,
  lineHeight:1.4,
  color:"#05052B",
  fontSize:"32px",
})

const SectionHeadingLine = styled("img")({
marginTop:"25px",
marginBottom:"30px"
})

const SlideArrowBox = styled(Box)({
  display:"flex",
  gap:"20px",
  alignItems:"flex-start",
  marginTop:"15px",
  "@media (max-width: 550px)":{
    marginBottom:"25px"
   },
})

const ArrowImg = styled("img")({
  cursor:"pointer"
})

const ArrowImgEnabled = styled("img")({
marginTop:"-5px",
cursor:"pointer"
})

const ArrowImgDisabled = styled("img")({

})


const SliderMainBox = styled(Box)({
  display:"flex",
  gap:"10px",
  "@media (max-width:1280px)":{
    flexDirection:"column",
    justifyContent:"center"
  }
})

const CardBox = styled(Box)({
  display:"flex",
  flexDirection:"column",
  width:"456px",
  borderRadius:"12px",
  padding:"30px",
  boxShadow:"0px 0px 13px rgba(136, 136, 136, 0.28)",
  marginBottom:"10px",
  "@media (max-width:1280px)":{
    width:"100%"
  }
})

const FirstCardBox = styled(Box)({
  display:"flex",
  justifyContent:"space-between",
  
})

const QuoteImg = styled("img")({

})

const CardDescriptionText = styled(Typography)({
  fontFamily:"Poppins",
  fontWeight:400,
  fontSize:"16px",
  lineHeight:"24px",
  letterSpacing:"0.5px",
  color:"#05052B",
  margin:"20px 0px 30px 0px"
})

const SecondCardBox = styled(Box)({
  display:"flex",
  gap:"20px"
})

const ProfileImg = styled("img")({
  width:"60px",
  height:"60px",
  borderRadius:"50%",
  objectFit:"cover"
})

const NameAndCompanyBox = styled(Box)({
  display:"flex",
  flexDirection:"column",
  marginTop:"3px",
})

const ProfileNameText = styled(Typography)({
  fontFamily:"Poppins",
  fontWeight:500,
  fontSize:"18px",
  lineHeight:"27px",
  color:"#05052B",
})

const CompanyNameText = styled(Typography)({
  fontFamily:"Poppins",
  fontWeight:500,
  fontSize:"14px",
  lineHeight:"21px",
  color:"#87888F",
})




// Customizable Area End

const styles = (theme: Theme) => ({
  logo: {
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    width: "100%",
    height: "40rem",
    backgroundSize: "contain",
    borderRadious: "50%",
    backgroundImage: `url(${aboutquotecheckLogo})`,
    [theme.breakpoints.up("xs")]: {
      width: "100%",
      height: "16rem"
    },
    [theme.breakpoints.up("sm")]: {
      width: "100%",
      height: "30rem"
    },
    [theme.breakpoints.up("lg")]: {
      width: "100%",
      height: "40rem"
    }
  }
});

export default class AboutQuotecheck extends AboutQuotecheckControllerWeb {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  aboutQuotecheck = (classes: any) => {
    const {currentIndex,itemsPerPage,slideItems} = this.state;
    const slicedData = slideItems.slice(currentIndex,currentIndex + itemsPerPage)
    return (<>
      <HeaderBox>
        <BlankBox></BlankBox>
         <SectionHeadingWithUnderlineBox>
          <SectionHeadingText>
            Voices of Our Users
          </SectionHeadingText>
          <SectionHeadingLine src={howItWorkUnderline}/>
          </SectionHeadingWithUnderlineBox>
          <SlideArrowBox>
          {currentIndex === 0 ? <ArrowImgDisabled src={leftArrowSlide}/>:<ArrowImgEnabled src={leftArrowEnabled} onClick={this.handleClickPrevSlide}/>}
          <ArrowImg data-test-id="right_slide_icon" src={rightArrowSlide} onClick={this.handleClickNextSlide}/>
          </SlideArrowBox>
      </HeaderBox>
      <SliderMainBox>
         {slicedData.map((card:VoiceOfUser)=>{
          return  <CardBox>
          <FirstCardBox>
          <QuoteImg src={quoteIconSlide}/>
          <Rating
              name="simple-controlled"
              value={card.attributes.rating}
              readOnly
            />
          </FirstCardBox>
          <CardDescriptionText>
         {card.attributes.description} 
          </CardDescriptionText>
          <SecondCardBox>
          <ProfileImg src={card.attributes.image}/>
          <NameAndCompanyBox>
            <NameAndCompanyBox>
              <ProfileNameText>{card.attributes.name}</ProfileNameText>
              <CompanyNameText>{card.attributes.designation}</CompanyNameText>
            </NameAndCompanyBox>
          </NameAndCompanyBox>
          </SecondCardBox>
          </CardBox>
         })}

      </SliderMainBox>
      </>
    );
  };
  // Customizable Area End

  render() {
// Customizable Area Start
    const { classes } = this.props;
    return (
      <div className={"featuresContainer howitworksContainer aboutContainer"}>
        <div className={"container"}>{this.aboutQuotecheck(classes)}</div>
      </div>
    );
  }
}

 withTheme(withStyles(styles)(AboutQuotecheck));
// Customizable Area End
