import { Message } from "../../../framework/src/Message";
import MessageEnum, { getName, } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from '../../../framework/src/IBlock';
export const configJSON = require("./config");
export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    // Customizable Area End

}
interface S {
    // Customizable Area Start
    email: string,
    password: string,
    apiError: boolean,
    serverSideError: boolean,
    // Customizable Area End
}
interface SS {
    // Customizable Area Start
    id: any;
    // Customizable Area End
}
export default class EmailAccountLoginWebController extends BlockComponent<Props, S, SS>{
    // Customizable Area Start
    loginUserApi: string = "";
    // Customizable Area End
    constructor(props: Props) {
        super(props)
        this.receive = this.receive.bind(this);
        // Customizable Area Start
        this.state = {
            email: "",
            password: "",
            apiError: false,
            serverSideError: false,
        }
        this.subScribedMessages = [
            getName(MessageEnum.CountryCodeMessage),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.ReciveUserCredentials),
        ];
        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages)
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {

            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            if (apiRequestCallId === this.loginUserApi) {

                if (responseJson?.errors) {
                    this.setState({ apiError: true })
                }
                else if (responseJson?.error) {
                    this.setState({ serverSideError: true })
                    this.setState({ apiError: true })
                }
                else if (responseJson?.meta) {
                    document.cookie = `Token=${responseJson.meta.token}`
                    document.cookie = `RefreshToken=${responseJson.meta.refresh_token}`
                    this.props.navigation.navigate('DashboardWeb');
                }
            }
        }
        // Customizable Area End
    }
    // Customizable Area Start
    onSubmit(): boolean {

        const data = {
            type: "email_account",
            attributes: {
                email: this.state.email,
                password: this.state.password,
            }
        }
        const header = {
            "Content-Type": configJSON.loginUserApiContentType,
        };

        const httpBody = {
            data: data,
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.loginUserApi = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.loginUserApiEndPoint
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(httpBody)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.loginUserApiMethod
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);

        return true;
    }
    // Customizable Area End

}
