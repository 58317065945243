// Customizable Area Start
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { addDays } from 'date-fns';
import { IBlock } from "../../../framework/src/IBlock";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  // Customizable Area Start
  navigation: any;
  history: any;
  handleSaveData: (data: []) => void;
  // Customizable Area End
}
export interface TabPanelProps {
    // Customizable Area Start
    children?: React.ReactNode;
    index: number | string;
    value: number | string;
    // Customizable Area End
}
interface S {
  // Customizable Area Start
value: number;
rejectDocumentsData: any[];
filterRejectDocModal: boolean;
vendorRejectDocValue: string;
projecRejectDocValue: string;
hasDateRangeRejectModal: boolean;
deleteRejectDocModal: boolean;
deleteModalId: number;
vendorOptionRejectDocValue: [];
ProjectOptionRejectDocValue: [];
dateRejetRangeData: [{
  startDate: Date;
  endDate: Date;
  key: string
}];
token: string;
rejectTypeDocumentError: string;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  // Customizable Area End
}

export default class RejectDocumentsController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getRejectTypeDocumentAPiId: string = "";
  getSearchRejectDocProjectApiId: string = "";
  getRejectDocumentsVendorApiId: string = "";
  getRejectDocumentsProjectApiId: string = "";
  deleteRejectDocumentApiId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    
    this.state = {
      // Customizable Area Start
      value: 0,
      token: '',
      rejectTypeDocumentError: "",
      rejectDocumentsData: [],
      filterRejectDocModal: false,
      vendorRejectDocValue: "",
      projecRejectDocValue: "",
      hasDateRangeRejectModal: false,
        deleteRejectDocModal: false,
        deleteModalId: 0,
        dateRejetRangeData: [{
          startDate: new Date(),
          endDate: addDays(new Date(), 0),
          key: 'selection'
        }],
        ProjectOptionRejectDocValue: [],
        vendorOptionRejectDocValue: [],
      // Customizable Area End
    };
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
    ];
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId === this.getRejectTypeDocumentAPiId) {
        this.rejectDocumentsResponseHandler(responseJson)
      }

      if (apiRequestCallId === this.getRejectDocumentsProjectApiId) {
        this.projectRejectResponseHandler(responseJson)
      } 
      if (apiRequestCallId === this.getRejectDocumentsVendorApiId) {
        this.vendorRejectResponseHandler(responseJson)
      }

      if (apiRequestCallId === this.getSearchRejectDocProjectApiId) {
        this.rejectDocumentFilterResponsehandler(responseJson)
      }

      this.rejectDeleteResponseHandler(apiRequestCallId, responseJson)

    }
  }

  // Customizable Area Start

  async componentDidMount() {
    const token = await document.cookie.split("; ").find((row) => row.startsWith("Token="))?.split("=")[1];
    token && this.setState({ token: token });
    this.getRejectTypeDocuments()
    this.getRejectDocumentsVendorsValue()
    this.getRejectDocumentsProjectsValue()
  }

  rejectDocumentsResponseHandler = (responseJson:any) => {
    if (responseJson.error) {
      this.setState({rejectTypeDocumentError: responseJson.error})
    }else{
      this.setState({rejectDocumentsData: responseJson.library_document_list})
    }
  }

  projectRejectResponseHandler = (responseJson:any) => {
    if (responseJson && responseJson.data && !responseJson.errors) {
      this.setState({ ProjectOptionRejectDocValue: responseJson.data })
    }
  }
  vendorRejectResponseHandler = (responseJson:any) => {
    if (responseJson && responseJson.data && !responseJson.errors) {
      this.setState({ vendorOptionRejectDocValue: responseJson.data })
    }
  }

  rejectDocumentFilterResponsehandler = (responseJson: any) => {
    if (responseJson.message == "Success" && responseJson.data) {
      this.setState({rejectDocumentsData: responseJson.data})
    }else if(responseJson.message == "No records found"){
      this.setState({rejectTypeDocumentError: responseJson.message, rejectDocumentsData: []})
    }
  }

  rejectDeleteResponseHandler = (apiRequestCallId: any, responseJson: any) => {
    if (apiRequestCallId === this.deleteRejectDocumentApiId) {
      const updatedData = this.state.rejectDocumentsData.filter((item: any) => (item.id  !== this.state.deleteModalId) && (item.invoice_id  !== this.state.deleteModalId))
      this.setState({ rejectDocumentsData: updatedData, deleteRejectDocModal: false, value: 0 })
    }
  }

  handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    this.setState({value: newValue})
  }

  selectRejectDocVendorValue = (event: React.ChangeEvent<{ value?: string | unknown }>)  => {
    const selectedVendor = event.target.value as string;
    this.setState({ vendorRejectDocValue: selectedVendor });
  }

  selectrejectDocProjectValue = (event: React.ChangeEvent<{ value?: string | unknown }>)  => {
    const selectedProject = event.target.value as string;
    this.setState({ projecRejectDocValue: selectedProject });
  }

  handleOpenRejectFilterModal = () => {
    this.setState({filterRejectDocModal: !this.state.filterRejectDocModal})
  }

  handleCloseRejectDocFilterModal = () => {
    this.setState({filterRejectDocModal: false})
  }

  handleRejectDateRangeOpenModal = () => {
    this.setState({ hasDateRangeRejectModal: !this.state.hasDateRangeRejectModal })
  }

  handleRejectSelectDateRangeValue = (data: any) => {
    this.setState({ dateRejetRangeData: data })
  }

  handleOpenRejectDeleteModal = (itemId: number) => {
    this.setState({deleteRejectDocModal: !this.state.deleteRejectDocModal, deleteModalId: itemId})
  }

  handleCloseRejectRDeleteModal = () => {
    this.setState({deleteRejectDocModal: false})
  }

  getRejectTypeDocuments = () => {
    const token = this.state.token;

    const header = {
      "Content-Type": configJSON.productApiContentType,
      "token": token
    };

    const requestRejectDocumentsMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getRejectTypeDocumentAPiId = requestRejectDocumentsMessage.messageId;

    requestRejectDocumentsMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.rejectDocumentApiEndPoint
    );

    requestRejectDocumentsMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestRejectDocumentsMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );

    runEngine.sendMessage(requestRejectDocumentsMessage.id, requestRejectDocumentsMessage);

    return true;
  }

  getRejectDocumentsProjectsValue = () => {
    const token = this.state.token;

    const header = {
      "Content-Type": configJSON.productApiContentType,
      "token": token
    };

    const tobepaidDocProjectRequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getRejectDocumentsProjectApiId = tobepaidDocProjectRequestMessage.messageId;

    tobepaidDocProjectRequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.projectApiEndPoint
    );

    tobepaidDocProjectRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    tobepaidDocProjectRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );

    runEngine.sendMessage(tobepaidDocProjectRequestMessage.id, tobepaidDocProjectRequestMessage);

    return true;
  }

  getRejectDocumentsVendorsValue = () => {
    const token = this.state.token;

    const header = {
      "Content-Type": configJSON.productApiContentType,
      "token": token
    };

    const tobepaidDocVendorRequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getRejectDocumentsVendorApiId = tobepaidDocVendorRequestMessage.messageId;

    tobepaidDocVendorRequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.vendorApiEndPoint
    );

    tobepaidDocVendorRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    tobepaidDocVendorRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );

    runEngine.sendMessage(tobepaidDocVendorRequestMessage.id, tobepaidDocVendorRequestMessage);

    return true;
  }

  handleRejectProjectsSearch = () => {
    this.setState({filterRejectDocModal: false, vendorRejectDocValue: "", projecRejectDocValue: "" })
    
    const token = this.state.token;

    const allDocProjectListData = {
      vendor: this.state.vendorRejectDocValue,
      project: this.state.projecRejectDocValue,
      date: this.state.dateRejetRangeData
    }

    const header = {
      "Content-Type": configJSON.productApiContentType,
      "token": token
    };

    const httpBody = {
      data: allDocProjectListData,
    };

    const getSearchTobepaidProjectsrequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getSearchRejectDocProjectApiId = getSearchTobepaidProjectsrequestMessage.messageId;

    getSearchTobepaidProjectsrequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.rejectSearchApiEndPoint
    );

    getSearchTobepaidProjectsrequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    getSearchTobepaidProjectsrequestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    getSearchTobepaidProjectsrequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );

    runEngine.sendMessage(getSearchTobepaidProjectsrequestMessage.id, getSearchTobepaidProjectsrequestMessage);

    return true;
  }


  handleRejectDeleteDocument = () => {
    const token = this.state.token;

    const header = {
      "Content-Type": configJSON.productApiContentType,
      "token": token
    };

    const deleteDocumentrequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.deleteRejectDocumentApiId = deleteDocumentrequestMessage.messageId;

    deleteDocumentrequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.deleteApiEndPoint}/${this.state.deleteModalId}`
    );

    deleteDocumentrequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteApiMethod
    );

    deleteDocumentrequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    runEngine.sendMessage(deleteDocumentrequestMessage.id, deleteDocumentrequestMessage);

    return true;
  }
  // Customizable Area End
}
