// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

export const configJSON = require("./config");

export interface Props {
  // Customizable Area Start
  navigation: any;
  id: string;
  classes: any;
  rows: any[];
  history: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  invoiceData: any;
  rows: any[];
  apiError: boolean;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  // Customizable Area End
}

export default class ReportInvoiceController extends BlockComponent<
  Props,
  S,
  SS
> {
  getInvoiceApiId: string = "";
  constructor(props: Props) {
    super(props);

    this.state = {
      // Customizable Area Start
      rows: props.rows,
      apiError: false,
      invoiceData: [],
      // Customizable Area End
    };

    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  handleSaveData = (data: any) => {
    this.setState({ invoiceData: data });
  };

  async componentDidMount() {
    this.getInvoices();
  }

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId === this.getInvoiceApiId) {
        this.handleInvoiceResponse(responseJson);
      }
    }
  }

  getInvoices = () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: document.cookie
        .split("; ")
        .find((row) => row.startsWith("Token="))
        ?.split("=")[1],
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getInvoiceApiId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.reportInvoiceApiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getUploadApiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleInvoiceResponse = (result: any) => {
    if (result.errors || result.error) {
      this.setState({ apiError: result.error });
    } else {
      this.setState({ invoiceData: result.invoices_data });
    }
  };
}

// Customizable Area End
