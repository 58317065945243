// Customizable Area Start
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { IBlock } from "../../../framework/src/IBlock";
import Emitter from "../../BulkUploading/src/util";

export const configJSON = require("./config");

export interface Props {
    classes: any,
    navigation: any,
}
export interface OcrTabPanelProps {
    // Customizable Area Start
    children?: React.ReactNode;
    index: number;
    value: number;
    // Customizable Area End
}
interface S {
    // Customizable Area Start
    value: number;
    ocrAlgoTab: any;
    accept: any;
    progressOcrVal: number;
    uploadQuoteAcceptedFile: any;
    uploadInvoiceAcceptedFile: any;
    isOcrDocumentCompareError: boolean;
    ocrAlgorithmErrorMsg: string;
    acceptedFileType: any;
    token: string | undefined;
    invoicePdfUrl: string;
    quotePdfUrl: string;
    isLoading: boolean;
    timeOcrRemaining: number;
    isAlgorithmMatch: boolean;
    documentsTitleLength: number;
    titleTabValues: string[];
    tabTitleSwitchValue: number;
    quoteData: []
    // Customizable Area End
}

interface SS {
    // Customizable Area Start
    // Customizable Area End
}

export default class OcrUploadController extends BlockComponent<Props, S, SS> {
    // Customizable Area Start

    algorithmMatchApiId: string = '';
    getAllDocumentsApiId: string = '';

    constructor(props: Props) {
        super(props);
        this.state = {
            value: 0,
            accept: [],
            invoicePdfUrl: '',
            quotePdfUrl: '',
            acceptedFileType: { "application/pdf": [".pdf"] },
            ocrAlgoTab: [
                {
                    id: 1,
                    type: "Uploading"
                },
                {
                    id: 2,
                    type: "Generate"
                },
                {
                    id: 3,
                    type: "Result"
                }
            ],
            progressOcrVal: 0,
            uploadQuoteAcceptedFile: [],
            uploadInvoiceAcceptedFile: [],
            isOcrDocumentCompareError: false,
            ocrAlgorithmErrorMsg: '',
            token: '',
            isLoading: false,
            timeOcrRemaining: 20,
            isAlgorithmMatch: false,
            documentsTitleLength: 0,
            titleTabValues: ["Documents", "OCR(Optical Character Recognition)"],
            tabTitleSwitchValue: 1,
            quoteData: [],
        };
        this.subScribedMessages = [
            getName(MessageEnum.CountryCodeMessage),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.ReciveUserCredentials),
        ];
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages)
        // Customizable Area End
    }

    async componentDidMount() {
        const token = await document.cookie.split("; ").find((row) => row.startsWith("Token="))?.split("=")[1]
        this.setState({ token: token })
        this.getAllDocumentsList()
    }


    async receive(from: string, message: Message) {
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            if (apiRequestCallId === this.algorithmMatchApiId) {
                this.handleAlgorithmMatchDataResponse(responseJson)
            }
            if (apiRequestCallId === this.getAllDocumentsApiId) {
                this.setState({ documentsTitleLength: responseJson.combined_data.length })
            } 
        }
    }

    handleAlgorithmMatchDataResponse = (response: any) => {
        this.setState({
            quoteData: response.data
        })
    }

    onUploadQuotesDrop = (invoiceFile: any) => {
        this.setState({ uploadQuoteAcceptedFile: invoiceFile, isLoading: true })
        const header = {
            "token": this.state.token
        };

        const formData: any = new FormData();

        this.state.uploadQuoteAcceptedFile.forEach((file: any) => {
            formData.append('files', file);
        });

        this.state.uploadInvoiceAcceptedFile.forEach((file: any) => {
            formData.append('files', file);
        });

        const compareRequestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.algorithmMatchApiId = compareRequestMessage.messageId;

        compareRequestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.ocrMatchAlgorithmEndPoint
        );

        compareRequestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        compareRequestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            formData
        );

        compareRequestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.exampleAPiMethod
        );

        runEngine.sendMessage(compareRequestMessage.id, compareRequestMessage);

        return true;
    }

    getAllDocumentsList = () => {
        const token = this.state.token;
    
        const header = {
            "Content-Type": configJSON.validationApiContentType,
            "token": token
        };
    
        const requestMessage = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );
    
        this.getAllDocumentsApiId = requestMessage.messageId;
    
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          configJSON.allDocumentsListApiEndPoint
        );
    
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(header)
        );
    
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          configJSON.validationApiMethodType
        );
    
        runEngine.sendMessage(requestMessage.id, requestMessage);
    
        return true;
    }

    // Customizable Area End
}
