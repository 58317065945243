import React from 'react'
// Customizable Area Start
import { styled } from '@material-ui/styles'
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { deleteIcon } from "./assets"
// Customizable Area End

const StyledDialog = styled(Dialog)({
    "& .MuiBackdrop-root" :{
        backgroundColor: "rgba(1, 1, 1, 0.2)"
    },
    "& .MuiDialog-paperWidthSm": {
        width: "500px",
        padding: "25px",
        height: "300px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        borderRadius: "10px",
    },
    "& .MuiDialogActions-root": {
        justifyContent: "space-around",
        width: "calc(100%-16px)",
    }

})
const StyledButton = styled(Button)({
    padding: "15px",
    width: "220px",
    background: "rgb(223, 128, 45)",
    textAlign: "center",
    borderRadius: "5px",
    color: "#fff",
    textTransform: "capitalize",
    fontweight: "600",
    '&:hover': {
        backgroundColor: 'rgb(223, 128, 45)',
        color: '#fff',
    }
})
const StyledCancelButton = styled(Button)({
    padding: "15px",
    width: "220px",
    background: "#fff",
    textAlign: "center",
    borderRadius: "5px",
    color: "#111",
    border: "1px solid lightGrey",
    textTransform: "capitalize",
    fontweight: "600",
    '&:hover': {
        backgroundColor: '#fff',
        color: '#111',
    }
})
import NotificationWebController, { Props } from './NotificationWebController';
export default class DeleteDialogWeb extends NotificationWebController {
    constructor(props: Props) {
        super(props)
        // Customizable Area Start
        // Customizable Area End

    }
    render() {
        return (
            <div>
                {/* 
        // @ts-ignore */}
                <StyledDialog
                    open={this.props.openDialog}
                    onClose={this.props.handleCloseDialog}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title"><img src={deleteIcon}></img></DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            <p style={webStyle.dialogText}> Confirm Delete Selected Items?</p>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <StyledCancelButton onClick={this.props.handleCloseDialog} color="primary">
                            Cancel
                        </StyledCancelButton>
                        <StyledButton onClick={this.props.handleCloseDialog} color="primary" autoFocus>
                            Delete
                        </StyledButton>
                    </DialogActions>
                </StyledDialog>
            </div>
        )
    }


}
// Customizable Area Start
const webStyle = {
    dialogText: {
        margin: "0px !important",
        fontWeight: 600,
        fontSize: "18px",
        color: "#111",
    }
}
// Customizable Area End