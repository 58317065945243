// Customizable Area Start
import React from "react";

// Customizable Area Start
import { Typography, Box, Button } from "@material-ui/core";
import { createTheme, ThemeProvider, withStyles } from "@material-ui/core/styles";
// Customizable Area End

import QuotesUploadController, { Props, configJSON } from "./QuotesUploadController.web";
import Header from "../../landingpage/src/Header.web";
import DashboardNewTable from "../../landingpage/src/DashboardNewTable.web";
import QuotesUploadNewDialog from "../../landingpage/src/QuotesUploadNewDialog.web";
import { styled } from '@material-ui/styles'

const StyledButton = styled(Button)({
  background: '#ef7927',
  width: '20%',
  height: "45px",
  textTransform: 'capitalize',
  padding: '16px',
  fontWeight: 600,
  marginTop: '20px',
  borderRadius: '8px',
  boxShadow: 'none',
  '&:hover': {
    background: '#ef7927',
  }

});

export class QuotesUpload extends QuotesUploadController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    const { classes } = this.props
    return (
      // Customizable Area Start
      <>
          <Header
          data-testid="quotesUploadHeaderId"
          handleSaveData={this.handleSaveData}
          navigation={this.props.navigation} history={this.props.history}          />
          <QuotesUploadNewDialog
          data-testid="quotesUploadDialogId"
          files={this.state.files}
          apiError={this.state.apiError}
          status={this.state.status}
          onDrop={this.onDrop}
          openDialog={this.state.openDialog}
          handleCloseDialog={this.handleCloseDialog}
          isLoading={this.state.isLoading}
          rows={[]}
          title=""
          hasMenu={true}
          handleClickOpenDialog={() => { } }
          handleSaveData={() => { } }
          navigation={this.props.navigation} history={this.props.history}          />
          <Box className={classes.QuoteCompareBox}>
            <Box className={classes.QuoteHeading}>
              <Typography variant="h5">{configJSON.uploadTitle}</Typography>
              <Typography>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Natus unde omnis perferendis iure consectetur. Ab, earum aperiam perferendis molestias enim sint.</Typography>
            </Box>
            <Box className={classes.UploadButton}>
              <StyledButton
                type="button"
                variant="contained"
                color="secondary"
                data-testid="uploadButtonId"
                onClick={() => this.handleUploadModal()}
              >
                Upload
              </StyledButton>
            </Box>

          </Box>
          <DashboardNewTable
            navigatePath="/quotes/quotesUpload"
            rows={this.state.uploadData}
            title={configJSON.uploadTitle}
            hasMenu={true}
          />
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff"
    }
  },
  typography: {
    h5: {
      fontWeight: 500,
      color: '#000'
    },
    subtitle1: {
      margin: "20px 0px"
    }
  }
});

export const webStyle = {
  QuoteCompareBox: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '15px'
  },
  UploadButton: {
    '& button': {
      width: '100%',
      minWidth: '172px',
      marginTop: '15px'
    }
  },
  QuoteHeading: {
    paddingRight: '5px'
  }
};

export default withStyles(webStyle)(QuotesUpload)

// Customizable Area End
// Customizable Area End
