// Customizable Area Start
import React from 'react'
import { styled } from '@material-ui/styles'
import { TextField, Button, Checkbox, Box,
    Dialog, DialogTitle, DialogContent, 
    DialogContentText, DialogActions } from "@material-ui/core";
import Popover from '@material-ui/core/Popover';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import SearchIcon from '@material-ui/icons/Search';
import { deleteImg } from "./assets"
import NotificationPanelListController, { Props } from './NotificationPanelListController.web';
import moment from 'moment';
// Customizable Area End
// Customizable Area Start
const StyledNotificationTextField = styled(TextField)({
    '& .MuiInputBase-input': {
        height: 'auto !important',
        padding: '14px',
        lineHeight: '10px',
        paddingLeft: "44px",
    },
    '& .MuiOutlinedInput-root': {
        borderRadius: '8px',
    },
    '& .MuiInputLabel-outlined': {
        transform: 'translate(14px, 16px) scale(1)',
        paddingLeft: '3px'
    }
})
const ViewListBlock = styled(Box)({
    marginBottom: '20px',
    border: '1px solid #d1d1d1 !important',
    background: '#fff !important',
    marginTop: '21px',
    position: 'relative',
    '&:after, &:before': {
        left: '55%',
        bottom: '100%',
        content: '""',
        height: 0,
        width: 0,
        position: 'absolute',
        border: 'solid transparent',
        pointerEvents: 'none',
    },
    '&:after': {
        borderBottomColor: '#fff',
        borderWidth: '10px',
        borderColor: 'rgba(136, 183, 213, 0)',
        marginLeft: '-10px',
    },
    '&:before': {
        borderBottomColor: '#d1d1d1',
        borderWidth: '11px',
        borderColor: 'rgba(204, 204, 204, 0)',
        marginLeft: '-11px',
    },

});
const SmallPopoverListBlock = styled(Popover)({
    '& .MuiPaper-root.MuiPopover-paper': {
        overflow: 'unset',
        boxShadow: 'none !important',
        width: '70px',
        height: '100px',
        backgroundColor: 'transparent',
    },

    
});
const NotificationPanelListBox = styled(Box)({
    display: 'flex',
    alignItems: 'start',
    justifyContent: 'space-between',
    position: 'relative',
    borderBottom: '1px solid #ccc',
    padding: '12px 0px',
    '&:last-child': {
        borderBottom: 'none !important',
    }
});

const StyledDialog = styled(Dialog)({
    "& .MuiBackdrop-root" :{
        backgroundColor: "rgba(1, 1, 1, 0.2)"
    },
    "& .MuiDialog-paperWidthSm": {
        width: "500px",
        padding: "25px",
        height: "300px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        borderRadius: "10px",
    },
    "& .MuiDialogActions-root": {
        justifyContent: "space-around",
        width: "100%",
    },
    "& .MuiDialog-paper": {
        overflow: 'hidden'
    }

})

const StyledDilaogContent = styled(DialogContent) ({
    '& .MuiDialogContent-root':{
        overflow: 'hidden'
    }
})

const StyledDialogDeleteButton = styled(Button)({
    '&:hover': {
        backgroundColor: 'rgb(223, 128, 45)',
        color: '#fff',
    },
    color: "#fff",
    width: "195px",
    padding: "12px",
    fontweight: "600",
    textAlign: "center",
    borderRadius: "5px",
    textTransform: "capitalize",
    background: "rgb(223, 128, 45)",
})

const StyledDialogCancelButton = styled(Button)({
    color: "#111",
    width: "195px",
    padding: "12px",
    fontweight: "600",
    background: "#fff",
    textAlign: "center",
    borderRadius: "5px",
    border: "1px solid lightGrey",
    textTransform: "capitalize",
    '&:hover': {
        backgroundColor: '#fff',
        color: '#111',
    },
})


export const DeleteDialogWeb = (props: any) => {
    return (
            <div>
                <StyledDialog
                    open={props.openDialog}
                    onClose={props.handleCloseDialog}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        <img src={deleteImg} style={{width: '70px', height: '70px'}}/>
                    </DialogTitle>
                    <StyledDilaogContent>
                        <DialogContentText id="alert-dialog-description" data-testid="deleteDialogContentId">
                            <p style={{
                                    margin: "0px !important",
                                    marginTop: "20px",
                                    fontWeight: 600,
                                    fontSize: "18px",
                                    color: "#111",
                                }}
                            > 
                                Confirm Delete Selected Items?
                            </p>
                        </DialogContentText>
                    </StyledDilaogContent>
                    <DialogActions>
                        <StyledDialogCancelButton data-testid="modalCancelBtnId" onClick={props.handleCloseDialog} color="primary">
                            Cancel
                        </StyledDialogCancelButton>
                        <StyledDialogDeleteButton data-testid="modalDeleteBtnId" onClick={props.handleDeleteDailog} color="primary" autoFocus>
                            Delete
                        </StyledDialogDeleteButton>
                    </DialogActions>
                </StyledDialog>
            </div>
    )
}

export const ListRender = ({
    item, state, checkCondition, handleChangeEvent, webStyle,
    handleClickPopover, handleClosePopover, templateFunct,
    handleClickOpenDialogFunction, handleNotificationClick, markAsReadNotificationApi
}: any) => {
    return (
        item.data.map((items: any, index: any) => (
            <NotificationPanelListBox >
                <div style={webStyle.ListDFlex}>
                    <Checkbox style={state.checkedItems[items.id] ? { color: '#df802d', marginTop: '-10px' }: {color: '#d3d3d3', marginTop: '-10px'}}
                        name={items.id}
                        data-testid={`checkBoxId${index}`}
                        checked={checkCondition.length > 0 ? state.checkedItems[items.id]: false}
                        onChange={handleChangeEvent}
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                    />
                    <div>
                        <p style={webStyle.ListcontentText}>{items.attributes.contents} Please <a href="javascript:void()" style={webStyle.ListclickHere} onClick={() => {handleNotificationClick(items); markAsReadNotificationApi(items)}} data-testid={`selectedItem-${index}`}>click here</a> to see.
                        </p>
                        <small style={webStyle.ListSmallText}>{ moment(items.attributes.created_at).startOf('day').fromNow() }.</small>
                    </div>
                </div>
                <div style={webStyle.ListIconBudge}>
                    { !items.attributes.is_read ? <span style={webStyle.ListSpanBudge}>New</span> : "" }
                    <MoreIconFuntion 
                        data-testid="moreIconFuntionId" 
                        handleClickSmallPopover={(event: any)=>handleClickPopover(event, items)} 
                        item={items}
                    />

                    <SmallPopoverListBlock
                        id={state.id}
                        open={state.smallPopoverOpen}
                        anchorEl={state.SmallEnchor}
                        onClose={handleClosePopover}
                        anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "center"
                        }}
                        transformOrigin={{
                            vertical: "top",
                            horizontal: "center"
                        }}
                    ><>
                            <ViewListBlock style={{
                                background: "#fff",
                                border: "1px solid rgb(243 241 241)",
                                borderRadius: "4px",
                                position: 'absolute',
                                zIndex: 9,
                                right: '0',
                                padding: '6px 8px',
                                fontSize: '12px',
                            }}>
                                <SmallPopoverContentFunction
                                    data-testid="SmallPopoverContentId"
                                    handleImageChange={templateFunct}
                                    handleClickOpenDialog={handleClickOpenDialogFunction}
                                />
                            </ViewListBlock>
                        </>
                    </SmallPopoverListBlock>
                </div>
            </NotificationPanelListBox>
        ))
        
    )
}

// Customizable Area End
export default class NotificationPanelList extends NotificationPanelListController {
    constructor(props: Props) {
        super(props)
        // Customizable Area Start
        // Customizable Area End
    }


    render() {
        const checkCondition = Object.entries(this.state.checkedItems).filter(([key, value]) => value);
        return (
            <>
                <Box sx={webStyle.ListFormBox}>
                    <SearchFieldFunction data-testid="searchFunctionCompId" onSubmit={this.searchSubmit}/>
                    <SearchIcon style={{ position: 'absolute', left: '12px', top: '14px' }} />
                </Box>


                <div style={webStyle.panelListContentBody}>
                    <div style={webStyle.ListDaysNotify}>
                        <div style={webStyle.ListDFlex}>
                            {checkCondition.length > 0 ? 
                                <>
                                    <SelectButtonsFunction 
                                        data-testid="select_deSelectBtnWrapperId"
                                        handleSelectAllBtn={this.handleSelectAllBtn} 
                                        handleDeSelectAllBtn={this.handleDeSelectAllBtn}
                                    />
                                </> 
                            : 
                                ""
                            }
                        </div>
                        {checkCondition.length > 0 ? 
                            <img 
                                data-testid="deleteAllId" 
                                src={deleteImg} 
                                style={webStyle.ListWeakIcon} 
                                onClick={()=> this.handleClickOpenDialogFunction()}
                            /> 
                            : 
                            <div 
                                data-testid="markAsReadId" 
                                style={webStyle.markAsReadWrapper} 
                                onClick={() => this.markAsReadNotificationApi("All")}
                            >
                                <p style={webStyle.markAsReadText}>Mark as read</p>
                            </div>
                        }
                    </div>
                    {this.state.notificationList && this.state.notificationList.map((item: any) => {
                        return (
                            <div style={webStyle.listStyle}>
                                <h4 style={webStyle.listH4Head}>{this.convertDates(item.date)}</h4>
                                <ListRender 
                                    data-testid="notificationListRenderId"
                                    item={item}
                                    state={this.state}
                                    checkCondition={checkCondition} 
                                    handleChangeEvent={this.handleChangeEvent}
                                    webStyle={webStyle}
                                    handleClickPopover={this.handleClickPopover}
                                    handleClosePopover={this.handleClosePopover}
                                    templateFunct={this.templateFunct}
                                    handleClickOpenDialogFunction={this.handleClickOpenDialogFunction}
                                    handleNotificationClick={this.props.handleNotificationClick}
                                    markAsReadNotificationApi={this.markAsReadNotificationApi}
                                />
                                
                            </div>
                        )
                    })}

                    {this.state.notificationList && this.state.notificationList.length == 0 && 
                        <div 
                            data-testid="noNotificationWrapperid" 
                            style={{
                                width: '100%', alignItems: 'center', 
                                textAlign: 'center', marginTop: '160px'
                            }}
                        >
                            <h5>No notifications found!</h5>
                        </div>
                    }
                </div>
                <DeleteDialogWeb
                    data-testid="deletDialogWrapperId"
                    openDialog={this.state.openDialog}
                    handleCloseDialog={this.handleCloseDialogFunction}
                    handleDeleteDailog={this.handleDeleteAll}
                />
            </>
        )
    }
}

export const SearchFieldFunction = ({ onSubmit }: any) => {
    return (
        <StyledNotificationTextField 
            id="outlined-basic" 
            data-testid="searchFields" 
            placeholder="Search notifications..." 
            variant="outlined" 
            style={webStyle.ListSearchField}
            onKeyDown={(event)=> onSubmit(event)}
        />
    )
}
export const SelectButtonsFunction = ({ handleSelectAllBtn, handleDeSelectAllBtn }:any) => {
    return (
        <>
            <Button 
                variant="outlined" 
                style={{
                    fontSize: '10px',
                    textTransform: 'capitalize',
                    margin: '0 5px',
                    padding: '4px 25px 2px',
                }}
                onClick={() => handleSelectAllBtn()}
                data-test-id="selectAll" 
            >
                Select All
            </Button>

            <Button 
                variant="outlined" 
                style={{
                    fontSize: '10px',
                    textTransform: 'capitalize',
                    margin: '0 5px',
                    padding: '4px 25px 2px',
                }}
                onClick={() => handleDeSelectAllBtn()}
                data-test-id="deSelectAll" 
            >
                Deselect All
            </Button>
        </>
    )
}

export const MoreIconFuntion = ({
    handleClickSmallPopover,
    item
}: any) => {
    return (
        <>
            <MoreVertIcon data-testid="moreicon" onClick={(event:any)=>handleClickSmallPopover(event,item)} />
        </>
    )
}

export const SmallPopoverContentFunction = ({
    handleImageChange,
    handleClickOpenDialog,
}: any) => {
    return (
        <>

            <Button 
                onClick={handleImageChange} 
                data-testid="replybtn" 
                style={{
                    textTransform: 'capitalize',
                    display: 'block',
                    fontWeight: 500,
                    padding: '3px 0',
                }}
            >
                Reply
            </Button>
            {/* <hr style={{ width: "50px" }}></hr> */}
            <Button 
                onClick={handleClickOpenDialog} 
                style={{
                    textTransform: 'capitalize',
                    display: 'block',
                    fontWeight: 500,
                    padding: '3px 0',
                }} 
                data-testid="deletebtn"
            >
                Delete
            </Button>
        </>
    )
}

// Customizable Area Start
const webStyle = {
    panelListContentBody: {
        overflow: "auto",
        display: "block",
        height: "76%",
        paddingRight: "10px",
        position: 'relative' as 'relative',
        marginTop: "12px"
    },
    ListDaysNotify: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        paddingLeft: '22%',
        position: 'absolute' as 'absolute',
        right: 0,
        width: '100%'
    },
    listStyle: {
        paddingTop: '0px'
    },
    listH4Head: {
        margin: '0px 15px 0 0',
        fontSize: '20px',
    },
    ListFormBox: {
        position: 'relative'
    },
    ListSearchField: {
        width: '100%',
    },
    ListDFlex: {
        display: 'flex',
        alignItems: 'start',
    },
    ListcontentText: {
        margin: '0',
        fontSize: '16px',
    },
    ListIconBudge: {
        display: 'flex',
        alignItems: 'center'
    },
    ListSpanBudge: {
        background: '#df802d',
        borderRadius: '3px',
        fontSize: '14px',
        fontWeight: 600,
        color: '#fff',
        padding: '3px 10px',
        marginLeft: '10px'
    },
    ListSmallText: {
        color: '#939191',
    },
    ListWeakIcon: {
        marginRight: '20px'
    },
    ListclickHere: {
        color: '#df802d',
    },
    markAsReadText: {
        paddingRight: '6px',
        marginTop: '5px',
        fontWeight: 500
    },
    markAsReadWrapper: {
        cursor: 'pointer'
    }

}
// Customizable Area End