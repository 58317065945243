// Customizable Area Start
import React from "react";
import { Box, Typography, Button } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import ReportModalController, { Props } from "./ReportModalController.web";
import { closeIcon, reportIcon1 } from "./assets";
// Customizable Area End

// Customizable Area Start
// Customizable Area End
class ReportModal extends ReportModalController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes, selectedItem } = this.props;
    // Customizable Area End

    return (
      // Customizable Area Start
      <Box className={classes.reportmodalBox}>
        <Box className={classes.repoortIconBox}>
          <img
            src={closeIcon}
            alt="close"
            onClick={this.props.handleReportClose}
          />
        </Box>
        <Box className={classes.uploadImgBx}>
          <img src={reportIcon1} alt="report" />
        </Box>
        <Typography>
          Do you want to Report Generate for the selected {selectedItem.length} items
        </Typography>
        <Box className={classes.reportbuttonBox}>
          <Button onClick={this.props.handleReportClose}>Cancel</Button>
          <Button>Generate</Button>
        </Box>
      </Box>
      // Customizable Area End
    );
  }
}

// Customizable Area Start

export const reportTableStyle = () => ({
  reportmodalBox: {
    padding: "28px 30px 50px",
    background: "#ffffff",
    width: "560px",
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    borderRadius: "10px",
    "& p": {
      fontSize: "20px",
      color: "#000000",
      textAlign: "center",
      margin: "25px",
      fontWeight: '500'
    }
  },
  repoortIconBox: {
    textAlign: "right" as "right",
    marginBottom: "10px",
    "& img": {
      width: "35px",
      height: "35px"
    }
  },
  uploadImgBx: {
    textAlign: "center" as "center",
    "& img": {
      width: "100px",
      height: "100px"
    }
  },
  reportbuttonBox: {
    textAlign: "center" as "center",
    "& button": {
      border: "1px solid #e0802e",
      padding: "15px 60px",
      textTransform: "unset",
      color: "#e0802e",
      fontSize: "20px",
      lineHeight: "22px",
      "&:hover": {
        background: "#e0802e",
        color: "#ffffff"
      },
      "&:first-child": {
        marginRight: "40px"
      }
    }
  }
});

export { ReportModal };
export default withStyles(reportTableStyle)(ReportModal);

// Customizable Area End
