// App.js - WEB
import React, { Component } from 'react';
import { View } from 'react-native';
import firebase from 'firebase';
import { Provider } from 'react-redux';
import WebRoutesGenerator from '../../components/src/NativeWebRouteWrapper';
import { ModalContainer } from 'react-router-modal';
import HomeScreen from '../../components/src/HomeScreen';
import TopNav from '../../components/src/TopNav';
import store from '../../framework/src/Redux/stores';
import { createTheme, ThemeProvider } from "@material-ui/core/styles";

import InfoPage from '../../blocks/info-page/src/InfoPageBlock';
import AlertBlock from '../../blocks/alert/src/AlertBlock.web';
import VisualAnalytics from '../../blocks/visualanalytics/src/VisualAnalytics';
import SocialMediaAccountLoginScreen from '../../blocks/social-media-account-login/src/SocialMediaAccountLoginScreen';
import SaveAsPdf from '../../blocks/saveaspdf/src/SaveAsPdf';
import ImportExportData from '../../blocks/importexportdata/src/ImportExportData';
import OTPInputAuth from '../../blocks/otp-input-confirmation/src/OTPInputAuth';
import Customform from '../../blocks/customform/src/Customform';
import DashboardInvoice from '../../blocks/customform/src/DashboardInvoice.web';
import InvoiceUpload from '../../blocks/customform/src/InvoiceUpload.web';
import Pushnotifications from '../../blocks/pushnotifications/src/Pushnotifications';
import OcrUpload from '../../blocks/OcropticalCharacterRecognition/src/OcrUpload.web';
import ForgotPassword from '../../blocks/forgot-password/src/ForgotPassword';
import ForgotPasswordOTP from '../../blocks/forgot-password/src/ForgotPasswordOTP';
import NewPassword from '../../blocks/forgot-password/src/NewPassword';
import NotificationWeb from '../../blocks/notifications/src/NotificationWeb';
import NotificationPanel from '../../blocks/BulkUploading/src/NotificationPanel.web';
import DocumentOpener from '../../blocks/customform/src/DocumentOpener';
import Analytics from '../../blocks/analytics/src/Analytics';
import AnalyticsWeb from '../../blocks/analytics/src/AnalyticsWeb.web';
import AllQuotes from '../../blocks/analytics/src/AllQuotes.web';
import Filteritems from '../../blocks/filteritems/src/Filteritems';
import Filteroptions from '../../blocks/filteritems/src/Filteroptions';
import PdfEdit from '../../blocks/pdfedit/src/PdfEdit';
import MatchAlgorithm2 from '../../blocks/MatchAlgorithm2/src/MatchAlgorithm2';
import DashboardReport from '../../blocks/MatchAlgorithm2/src/DashboardReport.web';
import ReportQuotes from '../../blocks/MatchAlgorithm2/src/ReportQuotes.web';
import ReportGeneration from '../../blocks/MatchAlgorithm2/src/ReportGeneration.web';
import BulkUploading from '../../blocks/BulkUploading/src/BulkUploading';
import Categoriessubcategories from '../../blocks/categoriessubcategories/src/Categoriessubcategories';
import CountryCodeSelector from '../../blocks/country-code-selector/src/CountryCodeSelector';
import SocialMediaAccountRegistrationScreen from '../../blocks/social-media-account-registration/src/SocialMediaAccountRegistrationScreen';
import Sorting from '../../blocks/sorting/src/Sorting';
import AdminConsole from '../../blocks/AdminConsole/src/AdminConsole';
import Catalogue from '../../blocks/catalogue/src/Catalogue';
import EmailAccountRegistration from '../../blocks/email-account-registration/src/EmailAccountRegistration';
import EmailAccountRegistrationWeb from '../../blocks/email-account-registration/src/EmailAccountRegistrationWeb.web';
import { Signup } from '../../blocks/landingpage/src/Signup.web';
import { Login } from '../../blocks/landingpage/src/Login.web';
import EmailAccountLoginBlock from '../../blocks/email-account-login/src/EmailAccountLoginBlock';
import LandingPage from '../../blocks/landingpage/src/LandingPage';
import LandingPageWeb from '../../blocks/landingpage/src/LandingPageWeb.web';
import Search from '../../blocks/search/src/Search';
import SearchWeb from '../../blocks/search/src/SearchWeb.web';
import OcropticalCharacterRecognition from '../../blocks/OcropticalCharacterRecognition/src/OcropticalCharacterRecognition';
import CfPdfScrapingCapablilities from '../../blocks/CfPdfScrapingCapablilities/src/CfPdfScrapingCapablilities';
import MatchAlgorithm from '../../blocks/MatchAlgorithm/src/MatchAlgorithm';
import Dashboard from '../../blocks/landingpage/src/Dashboard.web';
import BackGroundScreenWeb from '../../blocks/forgot-password/src/BackGroundScreenWeb.web';
import Library from '../../blocks/catalogue/src/Library.web'
import Project from '../../blocks/catalogue/src/Project.web';
import Archive from '../../blocks/catalogue/src/Archive.web';
import Reports from '../../blocks/catalogue/src/Reports.web';
import AllDocuments from '../../blocks/catalogue/src/AllDocuments.web';
import TobepaidDocuments from '../../blocks/catalogue/src/TobepaidDocuments.web';
import RejectDocuments from '../../blocks/catalogue/src/RejectDocuments.web';
import { ProfileDetailStyle } from '../../blocks/landingpage/src/ProfileDetailWeb.web';
import { ProfileMain } from '../../blocks/landingpage/src/ProfileMain.web';
import { Settings } from '../../blocks/landingpage/src/Settings.web';
import QuotesComparison from '../../blocks/analytics/src/QuotesComparison.web';
import Documents from '../../blocks/OcropticalCharacterRecognition/src/Documents.web';
import DocumentsComparison from '../../blocks/OcropticalCharacterRecognition/src/DocumentsComparison.web'
import DocumentsReport from '../../blocks/OcropticalCharacterRecognition/src/DocumentsReport.web'
import ReportInvoice from '../../blocks/customform/src/ReportInvoice.web';
import CreateReport from '../../blocks/customform/src/CreateReport.web';

import QuoteInvoice from '../../blocks/MatchAlgorithm2/src/QuoteInvoice.web'

// import Quotes from '../../blocks/analytics/src/Quotes.web'
import QuotesUpload from '../../blocks/analytics/src/QuotesUpload.web'
import InvoiceComments from "../../blocks/customform/src/InvoiceComments.web";
import ReportComparison from "../../blocks/MatchAlgorithm2/src/ReportComparison.web";
import QuoteScanResult from "../../blocks/categoriessubcategories/src/QuoteScanResult.web";
import EditInvoice from "../../blocks/categoriessubcategories/src/EditInvoice.web"
import InvoiceScanResult from "../../blocks/categoriessubcategories/src/InvoiceScanResult.web";
import EditQuoteScanResult from "../../blocks/categoriessubcategories/src/EditQuoteScanResult.web"
import Projects from "../../blocks/categoriessubcategories/src/Projects.web";
import VerifyInvoice from "../../blocks/customform/src/VerifyInvoice.web";
import ReportResults from "../../blocks/customform/src/ReportResults.web";
import { Switch } from 'react-router-dom';
import VerifyReport from "../../blocks/customform/src/VerifyReport.web";
import DescriptionPage from "../../blocks/customform/src/DescriptionPage.web"
import ViewReport from '../../blocks/categoriessubcategories/src/ViewReport.web';
import ViewReportDetails from '../../blocks/categoriessubcategories/src/ViewReportDetails.web';
import EditInvoiceScanResult from '../../blocks/categoriessubcategories/src/EditInvoiceScanResult.web';
import viewDiscrepancySummary from '../../blocks/categoriessubcategories/src/ViewDiscrepancy.web';
//PDF Keyword Hoghlight
import Pdfkeywordhighlighter from '../../blocks/pdfkeywordhighlighter/src/Pdfkeywordhighlighter.web';
import { ResetForgotPassword } from '../../blocks/landingpage/src/ResetForgotPassword.web';
import Termsandconditions from "../../blocks/termsandconditions/src/Termsandconditions.web";
import PrivacyPolicy from "../../blocks/termsandconditions/src/PrivacyPolicy.web";

export const theme = createTheme({});

const routeMap = {
  MatchAlgorithm: {
    component: MatchAlgorithm,
    path: '/MatchAlgorithm'
  },

  VisualAnalytics: {
    component: VisualAnalytics,
    path: '/VisualAnalytics'
  },
  SocialMediaAccountLoginScreen: {
    component: SocialMediaAccountLoginScreen,
    path: '/SocialMediaAccountLoginScreen'
  },
  SaveAsPdf: {
    component: SaveAsPdf,
    path: '/SaveAsPdf'
  },
  ImportExportData: {
    component: ImportExportData,
    path: '/ImportExportData'
  },
  OTPInputAuth: {
    component: OTPInputAuth,
    path: '/OTPInputAuth'
  },
  Customform: {
    component: Customform,
    path: '/Customform'
  },
  DashboardInvoice: {
    component: DashboardInvoice,
    components: [
      {
        path: "/invoice-upload",
        component: InvoiceUpload
      },
      {
        path: "/invoiceComments",
        component: InvoiceComments
      },
    ],
    path: '/invoice'
  },
  Pushnotifications: {
    component: Pushnotifications,
    path: '/Pushnotifications'
  },
  ForgotPassword: {
    component: ForgotPassword,
    path: '/ForgotPassword'
  },
  ForgotPasswordOTP: {
    component: ForgotPasswordOTP,
    path: '/ForgotPasswordOTP'
  },
  NewPassword: {
    component: NewPassword,
    path: '/NewPassword'
  },
  DocumentOpener: {
    component: DocumentOpener,
    path: '/DocumentOpener'
  },
  Analytics: {
    component: AnalyticsWeb,
    path: '/Analytics'
  },
  Filteritems: {
    component: Filteritems,
    path: '/Filteritems'
  },
  Filteroptions: {
    component: Filteroptions,
    path: '/Filteroptions'
  },
  PdfEdit: {
    component: PdfEdit,
    path: '/PdfEdit'
  },
  MatchAlgorithm2: {
    component: MatchAlgorithm2,
    path: '/MatchAlgorithm2'
  },
  BulkUploading: {
    component: BulkUploading,
    path: '/BulkUploading'
  },
  Categoriessubcategories: {
    component: Categoriessubcategories,
    path: '/Categoriessubcategories'
  },
  CountryCodeSelector: {
    component: CountryCodeSelector,
    path: '/CountryCodeSelector'
  },
  SocialMediaAccountRegistrationScreen: {
    component: SocialMediaAccountRegistrationScreen,
    path: '/SocialMediaAccountRegistrationScreen'
  },

  AdminConsole: {
    component: AdminConsole,
    path: '/AdminConsole'
  },
  Catalogue: {
    component: Catalogue,
    path: '/Catalogue'
  },
  EmailAccountRegistration: {
    component: EmailAccountRegistration,
    path: '/EmailAccountRegistration'
  },
  EmailAccountLoginBlock: {
    component: EmailAccountLoginBlock,
    path: '/EmailAccountLoginBlock'
  },
  LandingPage: {
    component: LandingPage,
    path: '/landingpage'
  },
  Search: {
    component: Search,
    path: '/Search'
  },
  OcropticalCharacterRecognition: {
    component: OcropticalCharacterRecognition,
    path: '/OcropticalCharacterRecognition'
  },
  CfPdfScrapingCapablilities: {
    component: CfPdfScrapingCapablilities,
    path: '/CfPdfScrapingCapablilities'
  },

  EmailAccountRegistrationWeb: {
    component: EmailAccountRegistrationWeb,
    path: '/registration'
  },
  DashboardWeb: {
    component: Dashboard,
    path: '/DashboardWeb'
  },
  EmailAccountLoginWeb: {
    component: EmailAccountRegistrationWeb,
    path: '/loginweb'
  },
  Login: {
    component: Login,
    path: '/login'
  },
  Signup: {
    component: Signup,
    path: '/signup'
  },
  ForgotPasswordWeb: {
    component: BackGroundScreenWeb,
    path: '/recoverPassword'
  },
  ResetPassword: {
    component: ResetForgotPassword,
    path: '/resetPassword'
  },
  NotificationPanel: {
    component: NotificationPanel,
    path: '/notification'
  },
  Home: {
    component: LandingPageWeb,
    path: '/',
    exact: true
  },
  SearchWeb: {
    component: SearchWeb,
    path: '/searchWeb'
  },
  InfoPage: {
    component: InfoPage,
    path: '/InfoPage'
  },

  AlertWeb: {
    component: AlertBlock,
    path: '*/AlertWeb',
    modal: true
  },
  // Quotes: {
  //   component: AllQuotes,
  //   path: '/quotes'
  // },
  ProfileDetailWeb: {
    component: ProfileDetailStyle,
    path: '/ProfileDetail'
  },



  ProfileMain: {
    component: ProfileMain,
    path: '/profile',
    components: [
      {
        path: "/settings",
        component: Settings
      },
    ]
  },
  Sorting: {
    component: Library,
    components: [
      {
        path: "/projects",
        component: Project
      },
      {
        path: "/archive",
        component: Archive
      },
      {
        path: "/Reports",
        component: Reports
      },
      {
        path: '/all-documents',
        component: AllDocuments,
      },
      {
        path: '/tobepaid-documents',
        component: TobepaidDocuments,
      },
      {
        path: '/reject-documents',
        component: RejectDocuments,
      }
    ],
    path: '/sorting'
  },
  OcrUpload: {
    component: OcrUpload,
    path: "/ocrUpload"
  },
  Quotes: {
    component: AllQuotes,
    path: '/quotes',
    components: [
      {
        component: QuotesUpload,
        path: '/quotesUpload',
      },
      {
        component: QuotesComparison,
        path: '/quotescomparison'
      },
    ],
  },
  ProjectS: {
    component: Projects,
    path: '/projects'
  },

  Documents: {
    component: Documents,
    path: '/document',
    components: [
      {
        component: DocumentsComparison,
        path: '/comparison',
      },
      {
        component: DocumentsReport,
        path: '/report-generation',
      },
    ],
  },
  Viewreport: {
    component: ViewReport,
    path: '/viewreport'
  },
  ViewReportDetails: {
    component: ViewReportDetails,
    path: '/viewreportdetails'
  },
  viewDiscrepancySummary: {
    component: viewDiscrepancySummary,
    path: '/viewdiscrepancysummary'
  },

  InvoiceScanResult: {
    component: InvoiceScanResult,
    path: '/invoicescanresult'
  },

  Report: {
    component: DashboardReport,
    path: '/report',
    components: [
      {
        component: ReportComparison,
        path: '/report-generation',
      },
      {
        component: QuoteInvoice,
        path: '/quote-invoice',
      },
      {
        component: ReportGeneration,
        path: '/reportgeneration',
      },
      {
        component: ReportQuotes,
        path: '/quotes',
      },
      {
        component: ReportInvoice,
        path: '/invoice',
      },
      {
        component: QuoteScanResult,
        path: '/quotescanresult'
      },
      {
        component: InvoiceScanResult,
        path: '/invoicescanresult'
      },
      {
        component: EditQuoteScanResult,
        path: '/editquotescanresult'
      },
      {
        component: CreateReport,
        path: '/create-report'
      },
      {
        component: DescriptionPage,
        path: '/descriptionquotepage'
      },
      {
        component: ReportResults,
        path: '/report-results'
      },
      {
        component: VerifyReport,
        path: '/verify-quote'
      },
      {
        component: EditInvoice,
        path: '/editinvoice'
      },
      {
        component: VerifyInvoice,
        path: '/verify-invoice'
      },
      {
        component: EditInvoiceScanResult,
        path: '/editinvoicescanresult'
      }    
    ],
  },

  Pdfkeywordhighlighter: {
    component: Pdfkeywordhighlighter,
    path: '/pdfkeywordhighlighter'
  },

  Termsandconditions: {
    component: Termsandconditions,
    path: '/Termsandconditions'
  },

  PrivacyPolicy: {
    component: PrivacyPolicy,
    path: '/PrivacyPolicy'
  },



};

const firebaseAPI = firebase.initializeApp({
  apiKey: 'AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4',
  authDomain: 'rnmasterapp-c11e9.firebaseapp.com',
  databaseURL: 'https://rnmasterapp-c11e9.firebaseio.com',
  projectId: 'rnmasterapp-c11e9',
  storageBucket: 'rnmasterapp-c11e9.appspot.com',
  messagingSenderId: '649592030497',
  appId: '1:649592030497:web:7728bee3f2baef208daa60',
  measurementId: 'G-FYBCF3Z2W3'
});

class App extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const defaultAnalytics = firebaseAPI.analytics();
    defaultAnalytics.logEvent('APP_Loaded');

    return (
      <View>
        <Provider store={store}>
          <ThemeProvider theme={theme}>
            <Switch>{WebRoutesGenerator({ routeMap })}</Switch>
          </ThemeProvider>
        </Provider>
        <ModalContainer />
      </View>
    );
  }
}

export default App;
