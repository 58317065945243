Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.productApiContentType = "application/json";
exports.apiMethodTypeGet = "GET";
exports.productAPiEndPoint = "catalogue/catalogues";


// exports.validationApiContentType = "application/json";
// exports.projectsAPiMethod = "GET";
exports.projectsAPiEndPoint = "bx_block_projects/projects";
exports.reportsAPiEndPoint = "bx_block_reports/reports";
exports.archivesAPiEndPoint = "bx_block_archives/archives";
exports.allDocumentsApiEndPoint = "bx_block_filter_items/invoice_libraries/library_document_list";
exports.rejectDocumentApiEndPoint = "bx_block_filter_items/invoice_libraries/library_document_list_rejected";
exports.toBePaidDocumentApiEndPoint = "bx_block_filter_items/invoice_libraries/library_document_list_paid";
exports.allDocumentSearchApiEndPoint = "bx_block_filter_items/search/search_for_approval";
exports.rejectSearchApiEndPoint = "bx_block_filter_items/search/search_for_approval_rejected";
exports.toBePaidSearchApiEndPoint = "bx_block_filter_items/search/search_for_approval_paid";
exports.vendorApiEndPoint = "bx_block_filter_items/search/vendors_approval";
exports.projectApiEndPoint = "bx_block_filter_items/search/projects_approval";
exports.deleteApiEndPoint = "bx_block_reports/reports";
exports.exampleAPiMethod = "POST";
exports.deleteApiMethod = "DELETE";
// exports.projectsApiContentType = "application/json";
exports.filterItemButton = "Filter Items";
exports.placeholdervendorTitle = "Choose Vendor";
exports.placeholderprojectTitle = "Choose Project";
exports.vendorTitle = "Vendor";
exports.projectTitle = "Project";
exports.dateRangeTitle = "Date Range";
exports.cancelButton = "Cancel";
exports.applyButton = "Apply";
exports.allDocRecordcontent = "No records found";
exports.documentsTitle = "Documents";
exports.deleteContent = "Are you sure you want to delete?";
exports.deleteNoButton = "No";
exports.deleteYesButton = "Yes";
exports.libraryContent = "Library"
// Customizable Area End