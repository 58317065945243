import React from 'react'
// Customizable Area Start
import { styled } from '@material-ui/styles'
import { connect } from "react-redux";
import { TextField, Button, Checkbox, Box } from "@material-ui/core";
import Popover from '@material-ui/core/Popover';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import SearchIcon from '@material-ui/icons/Search';
import { View } from 'react-native';
import { deleteImg } from "./assets"
import DeleteDialogWeb from './DeleteDialogWeb.web';
// Customizable Area End
import NotificationWebController, { Props } from './NotificationWebController'
import { handleImageChange } from '../../../framework/src/Redux/NotificationWeb/actions';
// Customizable Area Start
const StyledTextField = styled(TextField)({
    '& .MuiInputBase-input': {
        height: 'auto !important',
        padding: '14px',
        lineHeight: '10px',
        paddingLeft: "44px",
    },
    '& .MuiOutlinedInput-root': {
        borderRadius: '8px',
    },
    '& .MuiInputLabel-outlined': {
        transform: 'translate(14px, 16px) scale(1)',
        paddingLeft: '3px'
    }
})
const ViewBlock = styled(View)({
    position: 'relative',
    border: '1px solid #f3f1f1',
    background: '#fff',
    marginTop: '21px',
    marginBottom: '20px',
    '&:after, &:before': {
        bottom: '100%',
        left: '50%',
        border: 'solid transparent',
        content: '""',
        height: 0,
        width: 0,
        position: 'absolute',
        pointerEvents: 'none',
    },
    '&:after': {
        borderColor: 'rgba(136, 183, 213, 0)',
        borderBottomColor: '#fff',
        borderWidth: '15px',
        marginLeft: '-15px',
    },
    '&:before': {
        borderColor: 'rgba(204, 204, 204, 0)',
        borderBottomColor: '#f3f1f1',
        borderWidth: '16px',
        marginLeft: '-16px',
    }

});
const SmallPopoverBlock = styled(Popover)({
    '& .MuiPaper-root.MuiPopover-paper': {
        overflowY: 'hidden',
        boxShadow: 'none !important',
        width: '70px',
        height: '100px',
        backgroundColor: 'transparent',
    }
});
const NotificationListBox = styled(Box)({
    display: 'flex',
    alignItems: 'start',
    justifyContent: 'space-between',
    position: 'relative',
    borderBottom: '1px solid #ccc',
    padding: '14px',
    '&:last-child': {
        borderBottom: 'none !important',
    }
});
// Customizable Area End
class NotificationListWeb extends NotificationWebController {
    constructor(props: Props) {
        super(props)
        // Customizable Area Start
        // Customizable Area End
    }


    render() {
        const checkCondition = Object.entries(this.state.checkedItems).filter(([key, value]) => value);
        return (
            <>

                {/* 
        // @ts-ignore */}
                <view style={webStyle.formBox}>

                    <SearchFieldFunction />
                    {/* 
        // @ts-ignore */}
                    <SearchIcon style={webStyle.searchIcon} />
                </view>


                <view style={webStyle.contentBody}>
                    {/* 
        // @ts-ignore */}
                    {this.props.Qoutes ? <h4 style={webStyle.h4Head}>Qoutes </h4> : <>
                        <view style={webStyle.daysNotify}>
                            <view style={webStyle.dFlex}>
                                {/* <h4 style={webStyle.h4Head}>Today </h4> */}
                                {checkCondition.length > 0 ? <>
                                    <SelectButtonsFunction />
                                </> : ""}
                            </view>
                            {checkCondition.length > 0 ? <img src={deleteImg} style={webStyle.weakIcon} /> : <p>Mark as read</p>}
                        </view>
                        {this.state.notificationList && this.state.notificationList.map((item: any) => {
                            return (
                                <div>
                                    <h4 style={webStyle.h4Head}>{this.convertDate(item.date)}</h4>

                                    {item.data.map((items: any, index: any) => (
                                        <NotificationListBox >
                                            <view style={webStyle.dFlex}>
                                                <Checkbox style={webStyle.checkInput}
                                                    name={items.id}
                                                    data-test-id="checkBox"
                                                    // @ts-ignore
                                                    checked={this.state.checkedItems[items.id]}
                                                    onChange={this.handleChange}
                                                    inputProps={{ 'aria-label': 'primary checkbox' }}
                                                />
                                                <view>
                                                    <p style={webStyle.contentText}>Your DATA file has been match with algorithm.Please <a style={webStyle.clickHere} href="#">click here</a> to see.
                                                    </p>
                                                    <small style={webStyle.smallText}>2mins ago.</small>
                                                </view>
                                            </view>
                                            <view style={webStyle.iconBudge}>

                                            {items.attributes.is_read ?<span style={webStyle.spanBudge}>New</span> :""}<MoreIconfuntion handleClickSmallPopover={this.handleClickSmallPopover} />

                                                <SmallPopoverBlock
                                                    // @ts-ignore
                                                    id={this.state.id}
                                                    open={this.state.smallPopoverOpen}
                                                    anchorEl={this.state.SmallEnchor}
                                                    onClose={this.handleCloseSmallPopover}
                                                    anchorOrigin={{
                                                        vertical: "bottom",
                                                        horizontal: "center"
                                                    }}
                                                    transformOrigin={{
                                                        vertical: "top",
                                                        horizontal: "center"
                                                    }}
                                                ><>
                                                        {/* 
                                                        // @ts-ignore */}
                                                        <ViewBlock style={webStyle.iconDropdown}>
                                                            <SmallPopoverContentFunction
                                                                // @ts-ignore 
                                                                handleImageChange={this.tempFunct}
                                                                handleClickOpenDialog={this.handleClickOpenDialog}
                                                                handleChange openDialog={this.state.openDialog}
                                                                handleCloseDialog={this.handleCloseDialog}
                                                            />
                                                        </ViewBlock>
                                                    </>
                                                </SmallPopoverBlock>
                                            </view>
                                        </NotificationListBox>
                                    ))}
                                </div>
                            )
                        })}




                    </>}

                </view>
            </>
        )
    }
}

export const SearchFieldFunction = () => {
    return (
        <StyledTextField id="outlined-basic" data-test-id="searchFields" placeholder="Search notifications..." variant="outlined" style={webStyle.searchField} />
    )
}
export const SelectButtonsFunction = () => {
    return (
        <>
            {/* 
        // @ts-ignore */}
            <Button variant="outlined" data-test-id="selectAll" style={webStyle.daysNotifyBtn}>Select All</Button>
            {/* 
        // @ts-ignore */}
            <Button variant="outlined" data-test-id="deSelectAll" style={webStyle.daysNotifyBtn}>Deselect All</Button>
        </>
    )
}
export const MoreIconfuntion = ({
    handleClickSmallPopover
}: any) => {
    return (
        <>
            {/* 
        // @ts-ignore */}
            <MoreVertIcon data-test-id="moreicon" aria-describedby={0} onClick={handleClickSmallPopover} />
        </>
    )
}
export const SmallPopoverContentFunction = ({
    handleImageChange,
    handleClickOpenDialog,
    openDialog,
    handleCloseDialog
}: any) => {
    return (
        <>
            {/* 
        // @ts-ignore */}
            <Button onClick={handleImageChange} data-test-id="replybtn" style={webStyle.textLink}>Reply</Button>
            <hr style={{ width: "50px" }}></hr>
            {/* 
        // @ts-ignore */}
            <Button onClick={handleClickOpenDialog} style={webStyle.textLink} data-test-id="deletebtn">Delete</Button>
            {/* 
        // @ts-ignore */}
            <DeleteDialogWeb handleCloseDialog={handleCloseDialog} openDialog={openDialog} />
        </>
    )
}
function mapStateToProps(state: any) {
    return {
        notificationStates: state.notificationReducer,
    }
}
function mapDispatchToProps(dispatch: any) {
    return {
        handleImageChange: (data: object) => {
            return dispatch(handleImageChange(data))
        }
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(NotificationListWeb)
// Customizable Area Start
const webStyle = {
    containerStyle: {
        padding: '30px',
        height: 'calc(100% - 32px)',
    },
    contentNotifiStyle: {
        border: "1px solid rgb(243 241 241)",
        borderRadius: "12px",
        padding: '15px',
        height: '100%',
    },
    leftBoxStyle: {
        background: "#fff",
        border: "1px solid rgb(243 241 241)",
        borderRadius: "8px",
        position: 'relative',
        height: 'calc(100% - 30px)',
        padding: '15px'
    },
    rightBoxStyle: {
        background: "#fff",
        border: "1px solid rgb(243 241 241)",
        borderRadius: "8px",
        height: 'calc(100% - 30px)',
    },
    contentBody: {
        overflow: "scroll",
        display: "block",
        height: "505px",
        paddingRight: "10px",
    },
    h2Head: {
        margin: '0',
        padding: "15px",
    },
    h3Head: {
        color: '#df802d',
        margin: '0',
        paddingBottom: "15px",
    },
    daysNotify: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        paddingLeft: '120px',
        marginBottom: '-45px',
    },
    h4Head: {
        margin: '0px 15px 0 0',
        fontSize: '20px',
    },
    h4HeadRight: {
        padding: '15px',
        fontSize: '20px',
        margin: '0px',
        borderBottom: "1px solid rgb(243 241 241)",
    },
    daysNotifyBtn: {
        fontSize: '10px',
        textTransform: 'capitalize',
        margin: '0 5px',
        padding: '4px 25px 2px',
    },
    formBox: {
        position: 'relative'
    },
    searchIcon: {
        position: 'absolute',
        left: '12px',
        top: '10px'
    },
    searchField: {
        width: '100%',
    },
    notificationList: {
        display: 'flex',
        alignItems: 'start',
        justifyContent: 'space-between',
        position: 'relative',
        borderBottom: '1px solid #ccc',
        padding: '14px',
        '&:last-child': {
            borderBottom: 'none !important',
        }
    },
    dFlex: {
        display: 'flex',
        alignItems: 'start',
    },
    checkInput: {
        padding: '0px 9px 0 0',
        '&:checked': {
            color: 'rgb(223, 128, 45)'
        }
    },
    contentText: {
        margin: '0',
        fontSize: '16px',
    },
    iconBudge: {
        display: 'flex',
        alignItems: 'center'
    },
    spanBudge: {
        background: '#df802d',
        borderRadius: '3px',
        fontSize: '14px',
        fontWeight: 600,
        color: '#fff',
        padding: '3px 10px',
        marginLeft: '10px'
    },
    iconDropdown: {
        background: "#fff",
        border: "1px solid rgb(243 241 241)",
        borderRadius: "4px",
        boxShadow: "23px 9px 36px -10px rgb(124 120 120 / 27%)",
        position: 'absolute',
        zIndex: '9',
        right: '0',
        padding: '6px 8px',
        fontSize: '12px',
    },
    textLink: {
        textTransform: 'capitalize',
        display: 'block',
        fontWeight: '500',
        padding: '3px 0',
    },
    smallText: {
        color: '#939191',
    },
    contentRight: {
        display: 'flex',
        alignItems: 'center',
        height: '100%',
        padding: '15px'
    },
    noData: {
        display: 'none',
        textAlign: 'center',
    },
    showData: {
        width: '100%'
    },
    weakIcon: {
        margin: '10px 20px 20px 0px'
    },
    submitBtn: {
        position: 'absolute',
        right: '0',
        background: '#df802d',
        height: '45px',
        boxShadow: 'none',
        borderRadius: '0px 8px 8px 0',
    },
    rightImg: {
        width: '100%',
        height: '560px',
        marginTop: '15px',
        oajectFit: 'cover',
        border: "1px solid rgb(243 241 241)",
    },
    deleteIcon: {
        width: "20px"
    }, clickHere: {
        color: '#df802d',
    },

}
// Customizable Area End