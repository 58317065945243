// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// import { IDocument } from "react-doc-viewer";
import DashboardNewTableController from "../../landingpage/src/DashboardNewTableController.web";
export const configJSON = require("./config");

export interface Props {
    // Customizable Area Start
    navigation: any;
    id: string;
    classes: any;
    isOpen: false,
    pdfUrl:string,


    // Customizable Area End
}

interface S {
    // Customizable Area Start
    uploadData: any
    files: any[];
    openDialog: boolean;
    isLoading: boolean;
    apiError: boolean,
    status: number;
    isOpen: boolean,
    pdfUrl: string,
    // Customizable Area End
}

interface IDocument {
    uri: string;
  }

interface SS {
    // Customizable Area Start
    
    // Customizable Area End
}

  

  
export default class DocumentOpenerController extends BlockComponent<Props, S, SS> {


    constructor(props: Props) {
        super(props);

        this.state = {
            // Customizable Area Start
            openDialog: false,
            isOpen: false,
            isLoading: true,
            apiError: false,
            status: NaN,
            uploadData: [],
            files: [],
            pdfUrl:'',
        
            // Customizable Area End
        };

        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage)
        ];

        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages)
    }


     
  
    openModal = () => {
        this.setState({ isOpen: true });
    }

    closeModal = () => {
        this.setState({ isOpen: false });
    };

   




   
 

}

// Customizable Area End