// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import * as FileSaver from 'file-saver';
import XLSX from 'sheetjs-style';
import { jsPDF } from 'jspdf';
import moment from 'moment';
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  // Customizable Area Start
  openModal: boolean,
  handleClose: any,
  handleExcelDataClick?: (data: any) => void,
  handleSaveAsPDF?: (data: any) => void,
  selectedItem: any[]
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  dategen: any,
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  // Customizable Area End
}

export default class ExportModalController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.state = {
      // Customizable Area Start
      dategen: "",
      // Customizable Area End
    };
    // Customizable Area Start
    // Customizable Area End
  }

  handleExcelDataClick = (data: any) => {
    data.forEach((object: any) => {
      delete object['model_name'];
      delete object['updated_at'];
      { (window.location.pathname == '/DashboardWeb' || window.location.pathname == '/report' || window.location.pathname == '/quotes' || window.location.pathname == '/quotes/quotesUpload' || window.location.pathname == '/quotes/quotescomparison' || window.location.pathname == '/invoice' || window.location.pathname == '/report/quotes' || window.location.pathname == '/report/invoice' || window.location.pathname == '/document/report-generation') && delete object['created_at'] && delete object['tag'] && delete object['approval_status'] && delete object['quotes_library_id'] && delete object['table_name'] }
      { (window.location.pathname == '/DashboardWeb' || window.location.pathname == '/quotes/quotesUpload') && delete object['status'] && delete object['total_price'] }
      { (window.location.pathname == '/report' || window.location.pathname == '/report/quotes' || window.location.pathname == '/document/report-generation' || window.location.pathname == '/quotes/quotescomparison') && delete object['total_price'] }
      delete object['id'];
    });
    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    const fileData = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    FileSaver.saveAs(fileData, "data.xlsx");

    this.props.handleClose()
  }

  handleSaveAsPDF = (data: any) => {
    const doc = new jsPDF({
      orientation: 'p',
      unit: 'mm',
      format: 'a4',
      putOnlyUsedFonts: true,
    });

    const pathToPropertiesMap: any = {
      '/DashboardWeb': ['total_price', 'created_at', 'tag', 'approval_status', 'quotes_library_id', 'table_name', 'id', 'status', 'updated_at'],
      '/report': ['total_price', 'id', 'updated_at', 'created_at', 'tag', 'quotes_library_id', 'approval_status'],
      '/quotes': ['total_price', 'id', 'updated_at', 'created_at', 'tag'],
      '/quotes/quotesUpload': ['status', 'total_price', 'id', 'updated_at', 'created_at', 'tag'],
      '/quotes/quotescomparison': ['total_price', 'id', 'updated_at', 'created_at', 'tag'],
      '/invoice': ['id', 'updated_at', 'created_at', 'tag', 'quotes_library_id', 'approval_status'],
      '/report/quotes': ['total_price', 'id', 'updated_at', 'created_at', 'tag', 'quotes_library_id', 'approval_status'],
      '/report/invoice': ['total_price', 'id', 'updated_at', 'created_at', 'tag'],
      '/document/report-generation': ['total_price', 'id', 'updated_at', 'created_at', 'tag', 'quotes_library_id', 'approval_status'],
    };

    const currentPath = window.location.pathname;
    const propertiesToDelete = pathToPropertiesMap[currentPath] || [];
    data.forEach((object: any) => {
      propertiesToDelete.forEach((property: any) => delete object[property]);
    });

    const columns = Object.keys(data[0]);

    const rows = data.map((item: any) => {
      delete item['model_name'];
      delete item['updated_at'];
      delete item['created_at'];
      delete item['total_price'];
      delete item['id'];

      return Object.values(item);
    });


    const cellWidth = 48; 
    const cellHeight = 20;
    const tableTopMargin = 30;
    const tableLeftMargin = 10;
    doc.setFontSize(10);

    columns.forEach((column: string, columnIndex: number) => {
      doc.rect(
        tableLeftMargin + columnIndex * cellWidth,
        tableTopMargin,
        cellWidth,
        cellHeight,
        'S'
      );
      doc.text(
        column,
        tableLeftMargin + columnIndex * cellWidth + 2,
        tableTopMargin + cellHeight / 2 + 2,
        { align: 'left', baseline: 'middle' }
      );
    });

    rows.forEach((row: any, rowIndex: number) => {
      row.forEach((cell: any, cellIndex: number) => {
        const cellValue =
          cell !== null && cell !== undefined ? cell.toString() : '';
        doc.rect(
          tableLeftMargin + cellIndex * cellWidth,
          tableTopMargin + (rowIndex + 1) * cellHeight,
          cellWidth,
          cellHeight,
          'S'
        );
        doc.text(
          cellValue,
          tableLeftMargin + cellIndex * cellWidth + 2,
          tableTopMargin + (rowIndex + 1) * cellHeight + cellHeight / 2 + 2,
          { align: 'left', baseline: 'middle' }
        );
      });
    });

    doc.save('my-document.pdf'); // Save the PDF
  };

  // Customizable Area Start
  // Customizable Area End
}

