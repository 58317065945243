// Customizable Area Start
import React from "react";
import { Box, Typography } from "@material-ui/core";
import { createTheme, withStyles } from "@material-ui/core/styles";
import QuotesComparisonController, {
  Props,
  configJSON
} from "./QuotesComparisonController.web";
import Header from "../../landingpage/src/Header.web";
import DashboardNewTable from "../../landingpage/src/DashboardNewTable.web";
// Customizable Area End

class QuotesComparison extends QuotesComparisonController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    const { classes, handleSaveData } = this.props
    return (
      // Customizable Area Start
      <>
          <Header
          handleSaveData={handleSaveData}
          navigation={this.props.navigation} 
          history={this.props.history}          
        />
          <Box className={classes.QuoteCompareBox}>
            <Typography variant="h5">{configJSON.comparisonTitle}</Typography>
            <Typography>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Beatae voluptatem accusantium adipisci officia mollitia eligendi soluta consequuntur, tempora accusamus, eaque ipsa voluptate perferendis vero? Odit eaque voluptatibus voluptas impedit recusandae?</Typography>
          </Box>
          <DashboardNewTable navigatePath="/quotes/quotescomparison" rows={this.state.comparisonData} title={configJSON.comparisonTitle} hasStatusData={true} hasMenu={true} />
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const theme = createTheme({
  typography: {
    h5: {
      fontWeight: 500,
      color: '#000'
    },
  }
});

export const quoteswebStyle = {
  QuoteCompareBox: {
    '& p': {
      margin: '4px 0 20px 0'
    }
  }
};

export { QuotesComparison }
export default withStyles(quoteswebStyle)(QuotesComparison)

// Customizable Area End
