import React from "react";
// Customizable Area Start
import "./index.css";
import { withStyles, withTheme,styled } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Button from "@material-ui/core/Button";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import MoreIcon from "@material-ui/icons/MoreVert";
import ToolbarControllerWeb, { Props } from "./ToolbarController.web";
import Grid from "@material-ui/core/Grid";
import {headerNewLogo,bannerImageNew,dotsDesignImage } from "../src/assets";


const LeftSideMainText = styled("div")({
  fontFamily:"Poppins !important",
  fontWeight:400,
  fontSize:"35px",
  lineHeight:"56px",
  color:"#05052B"
})

const LeftSideSmallText = styled("p")({
  fontFamily:"Poppins !important",
  fontWeight:400,
  fontSize:"18px",
  lineHeight:"30px",
  color:"#05052B"
})

const DotsDesignImage = styled("img")({
  position:"absolute",
  top:"178px",
  right:"19%",
  "@media(max-width: 1280px)":{
    top:"97px",
  right:"37%",
  },
  "@media(max-width: 900px)":{
    top:"96px",
  right:"33%",
  },
  "@media(max-width: 700px)":{
   display:"none"
  }
})

const RightsideContent = styled("div")({
  "@media(max-width: 1280px)":{
    position:"relative"
  }
})


// Customizable Area End

let style = (theme: any) => ({
  // Customizable Area Start
  menuButton: {
    marginRight: theme.spacing(3),
    fontSize: 20,
    color: "#fff",
    textTransform: "capitalize"
  },
  menuButtonSignup: {
    marginRight: theme.spacing(3),
    fontSize: 20,
    color: "#05052B",
    textTransform: "capitalize",
    backgroundColor:"#fff",
    borderRadius:"6px !important",
    padding:"8px 30px",
  "&:hover":{
    backgroundColor:"#fff",
  }
  },
  getStartedNowBtn: {
    maxWidth:"248px",
    fontSize: 19,
    color: "#fff",
    backgroundColor:"#05052B",
    borderRadius:"6px !important",
    padding:"12px 30px",
    marginTop:"45px",
    textTransform:"none",
    FontFamily:"Poppins",
    fontWeight:400,
    "&:hover":{
    backgroundColor:"#fff",
    color:"black",
  }
  },
  grow: {
    flexGrow: 1
  },
  title: {
    display: "block"
  },
  sectionDesktop: {
    display: "none",
    "& .MuiButton-root": {
      borderRadius: "10px"
    },
    [theme.breakpoints.up("md")]: {
      display: "flex",
      gap:"30px"
    }
  },
  logo: {
    backgroundImage: `url(${headerNewLogo})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    height: 74.36,
    width: 300,
    backgroundSize: "contain"
  },
  leftsideTextContent: {
    [theme.breakpoints.up("md")]: {
      display: "none"
    }
  },
  sectionMobile: {
    display: "flex",
    marginTop:"28px",
    [theme.breakpoints.up("md")]: {
      display: "none"
    }
  },
  toolbar:{
    backgroundColor: "#05052B",
    padding:"20px 55px"
  },
  menuIcon:{
    color:"white"
  },
  menuItemMobile:{
    "&:hover":{
      backgroundColor:"#05052B !important"
    }
  }
  // Customizable Area End
});

// Customizable Area Start



class ToolbarWeb extends ToolbarControllerWeb {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  handleMenuClose = () => {
    this.setState({
      achorEl: null,
      mobileMoreAnchorEl: null
    });
  };
  handleMobileMenuClose = () => {
    this.setState({
      mobileMoreAnchorEl: null
    });
  };
  handleMenuOpen = (event: any) => {
    this.setState({
      mobileMoreAnchorEl: event.currentTarget
    });
  };
  handleProfileMenuOpen = (event: any) => {
    this.setState({
      achorEl: event.currentTarget
    });
  };
  // Customizable Area End

  render() {
    let { classes } = this.props;
    let { mobileMoreAnchorEl } = this.state;
    let isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
    // Customizable Area Start
    let mobileMenuId = "primary-search-account-menu-mobile";
    let renderMobileMenus = (
      <Menu
        anchorEl={this.state.mobileMoreAnchorEl}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        id={mobileMenuId}
        keepMounted
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        open={isMobileMenuOpen}
        onClose={this.handleMobileMenuClose}
      >
       
        <MenuItem className={classes.menuItemMobile} onClick={() =>this.props.navigation.navigate("Login")}>
          <p>Login</p>
        </MenuItem>
        <MenuItem className={classes.menuItemMobile} onClick={() =>this.props.navigation.navigate("Signup")}>
          <p>Signup</p>
        </MenuItem>
      </Menu>
    );
    // Customizable Area End

    return (
      // Customizable Area Start
        <div className={"container"}>
          <div className={`${classes.grow} navbar`}>
            <AppBar position="static" style={webStyle.appBarStyle}>
              <Toolbar className={classes.toolbar}>
                <div className={classes.logo} />
                <div className={classes.grow} />
                <div className={classes.sectionDesktop}>
                 
                  <Button
                    className={classes.menuButton}
                    onClick={() =>
                      this.props.navigation.navigate("Login")
                    }
                  >
                    Login
                  </Button>
                  <Button
                    className={classes.menuButtonSignup}
                    onClick={() =>
                      this.props.navigation.navigate(
                        "Login"
                      )
                    }
                  >
                    Signup
                  </Button>
                  
                </div>
                <div className={classes.sectionMobile}>
                  <Button
                    aria-label="show more"
                    aria-controls={mobileMenuId}
                    aria-haspopup="true"
                    onClick={this.handleMenuOpen}
                  >
                    <MoreIcon className={classes.menuIcon}/>
                  </Button>
                </div>
              </Toolbar>
            </AppBar>
            {renderMobileMenus}
            <div className="main-container">
              <Grid container spacing={1}>
                <Grid item xs={12} lg={5}>
                  <div className="leftside-content">
                    <LeftSideMainText>
                     Compare construction quotes<br />
                     and invoices effortlessly
                      
                    </LeftSideMainText>
                    <LeftSideSmallText>
                    Experience seamless invoice and quote comparisons<br/>
                    with My Invoice Check's advanced OCR technology.
                    </LeftSideSmallText>
                    <Button
                    className={classes.getStartedNowBtn}
                    onClick={() =>this.props.navigation.navigate("Login")} 
                    >
                    Get Started Now !
                    </Button>
                   </div>
                  
                </Grid>
                <Grid item xs={12} lg={7}>
                  <RightsideContent className="rightside-content">
                  <DotsDesignImage src={dotsDesignImage}/>
                    <img
                      src={bannerImageNew}
                      className="hero_image"
                      alt="hero_img"
                    />
                  </RightsideContent>
                </Grid>
              </Grid>
            </div>
          </div>
        </div>
      // Customizable Area End
    );
  }
}
//@ts-ignore
export default withTheme(withStyles(style)(ToolbarWeb));
// Customizable Area End
const webStyle = {
  // Customizable Area Start
  appBarStyle: {
    background: "transparent",
    boxShadow: "none"
  },
  homeButton: {
    // border: "1px solid #FFF"
  }
  // Customizable Area End
};
