// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import Emitter from "../../BulkUploading/src/util";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  // Customizable Area Start
  navigation: any;
  id: string;
  classes: any;
  history: any
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  uploadData: any
  files: any[];
  openDialog: boolean;
  isLoading: boolean;
  apiError: boolean,
  status: number;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class QuotesUploadController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  // Customizable Area End

  uploadFileId: string = "";
  getQuoteFile: string = "";
  constructor(props: Props) {
    super(props);

    // Customizable Area Start
    this.state = {
      // Customizable Area Start
      openDialog: false,
      isLoading: true,
      apiError: false,
      status: NaN,
      uploadData: [],
      files: [],
      // Customizable Area End
    };

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage)
    ];

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages)

    // Customizable Area Start

    // Customizable Area End
  }

  handleSaveData = (data: any) => {
    this.setState({ uploadData: data})
  }

  async componentDidMount() {
    this.getQuotes()
  }

  getToken = () => {
    const token = document.cookie.split("; ").find((row) => row.startsWith("Token="))?.split("=")[1]
    return token;
  }

  handleUploadModal = () => {
    this.setState({ openDialog: true })
  }

  handleCloseDialog = () => {
    this.setState({ openDialog: false, files: [],  isLoading: true, apiError: false })
  }

  onDrop = (acceptedFiles: any) => {
    this.setState({ files: acceptedFiles });
    if (this.state.files.length > 0) {
      const header = {
        token: this.getToken()
      };

      const quotesFormData: any = new FormData();

      acceptedFiles.forEach((file: any) => {
        quotesFormData.append('files[]', file);
      });

      const requestQuotesMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.uploadFileId = requestQuotesMessage.messageId;
      requestQuotesMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.fileUploadApiEndPoint
      );

      requestQuotesMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestQuotesMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        quotesFormData
      );

      requestQuotesMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.fileUploadApiMethod
      );

      runEngine.sendMessage(requestQuotesMessage.id, requestQuotesMessage);

      return true;
    }
  };


  getQuotes = () => {
    const header = {
      token: this.getToken()
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getQuoteFile = requestMessage.messageId

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getQuoteApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getUploadApiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };


  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const quotesApiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const quotesResponseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (quotesApiRequestCallId === this.uploadFileId && quotesResponseJson) {
        Emitter.emit("FETCH_NOTIFICATION_LIST_FOR_POPUP", {});
        this.setState({ isLoading: false })
        this.setState({ status: quotesResponseJson.status })
        if (quotesResponseJson.errors || quotesResponseJson.error) {
          this.setState({ apiError: true })
        } else {
          this.getQuotes()
          this.setState({ apiError: false })
        }
      }
      else if(quotesApiRequestCallId === this.getQuoteFile) {
        if (quotesResponseJson.errors || quotesResponseJson.error) {
        } else {
          this.setState({ uploadData: quotesResponseJson.data })
        }
      }
    }
  }


  // Customizable Area Start
  // Customizable Area End
}

// Customizable Area End