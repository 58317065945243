// Customizable Area Start
import React, { ReactNode } from "react";
import { Grid } from "@material-ui/core";
import {
    quoteUploadIcon,
    quoteCompareIcon,
} from "../../landingpage/src/assets";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import Header from "../../landingpage/src/Header.web";
import QuotesUploadNewDialog from "../../landingpage/src/QuotesUploadNewDialog.web";
import DashboardNewTable from "../../landingpage/src/DashboardNewTable.web";
import DashboardNewWebController from "../../landingpage/src/DashboardNewWebController.web";

import { NavLink } from "react-router-dom";

export const NavQuotesActionCard = (props: any) => {
    const classes = useStyles();
    return (
        <>
            <div style={webStyle.actionQuoteCard} onClick={props.handleOnClick}>
                <div
                    className={`${classes.navQuoteIconImageWrapper}`}
                    style={props.imageStyle}
                >
                    <img src={props.img} className={classes.navQuoteIconImage} alt="" />
                </div>
                <span className={classes.navQuoteActionText} style={props.textStyle}>
                    {props.actionCardText}
                </span>
            </div>
        </>
    );
};

export default class AllQuotes extends DashboardNewWebController {
    render(): ReactNode {
        return (
            <>
                <Header
                    data-testid="allQuotesHeaderId"
                    handleSaveData={this.handleSaveData}
                    navigation={this.props.navigation} 
                    history={this.props.history}                />
                {/*@ts-ignore*/}
                <QuotesUploadNewDialog
                    data-testid="allQuotesUpoadDilogId"
                    files={this.state.files}
                    apiError={this.state.apiError}
                    status={this.state.status}
                    onDrop={this.state.selectedQuoteId === "" ? this.onDrop : this.onInvoiceDrop}
                    openDialog={this.state.openDialog}
                    handleCloseDialog={this.handleCloseDialog}
                    isLoading={this.state.isLoading}
                    errMsg={this.state.errMsg}
                    successMsg={this.state.successMsg}
                />
                <Grid container spacing={6}>
                    <Grid item xs={12} md={6} lg={6}>
                        <NavQuotesActionCard
                            data-testid="allQuotesUploadCardId"
                            className="a"
                            img={quoteUploadIcon}
                            imageStyle={webStyle.quotes_UploadImg}
                            textStyle={webStyle.quote_UploadText}
                            handleOnClick={this.handleClickOpenDialog}
                            actionCardText="Quotes Upload"
                        />
                    </Grid>
                    <Grid item xs={12} md={6} lg={6}>
                        <NavLink activeStyle={{ textDecoration: 'none' }} style={webStyle.allQuotesNavLinkStyle} to="/quotes/quotescomparison">
                            <NavQuotesActionCard
                                data-testid="allQuotesComparisonCardId"
                                className="a"
                                img={quoteCompareIcon}
                                imageStyle={webStyle.quotes_CompareImg}
                                textStyle={webStyle.quotes_CompareText}
                                handleOnClick={this.handleClickOpenDialog}
                                actionCardText="Quotes Comparision"
                            />
                        </NavLink>
                    </Grid>
                    <Grid item xs={12} md={12} lg={12}>
                        {/*@ts-ignore*/}
                        <DashboardNewTable  {...this.props} navigatePath="/quotes" title="Quotes" rows={this.state.rows} hasMenu={true} hasStatus={true} handleOnClick={this.handleOpenDialogWithQuoteId}/>
                    </Grid>
                    <Grid item xs={12} md={6} lg={6}>
                        {/*@ts-ignore*/}
                        <DashboardNewTable  {...this.props} navigatePath="/quotes/quotesUpload" title="Upload Files" rows={this.state.uploadRows} hasMenu={true} />
                    </Grid>
                    <Grid item xs={12} md={6} lg={6}>
                        {/*@ts-ignore*/}
                        <DashboardNewTable  {...this.props} navigatePath="/quotes/quotescomparison" title="Comparison Files" rows={this.state.comparisonData} hasMenu={true} />
                    </Grid>
                </Grid>
            </>
        );
    }
}

const useStyles = makeStyles(() =>
    createStyles({
        navQuoteIconImageWrapper: {
            height: "65px",
            width: "65px",
            borderRadius: "13px",
            marginLeft: "20px"
        },
        navQuoteIconImage: {
            width: "100%",
            height: "100%",
            padding: "10px"
        },
        navQuoteActionText: {
            fontSize: "25px",
            fontWeight: 700,
            fontFamily: "Poppins",
            width: "100%",
            textAlign: "center"
        },
    })
);


const webStyle = {
    actionQuoteCard: {
        height: "100px",
        borderRadius: "16px",
        backgroundColor: "#ffffff",
        border: "0px solid #39b68e",
        width: "100%",
        display: "flex",
        alignItems: "center",
        cursor: 'pointer'
    },
    quotes_UploadImg: {
        opacity: "0.655",
        backgroundImage:
            "linear-gradient(180deg, rgba(198,255,230,0.55) 0%, #96e0c8 100%)"
    },
    quote_UploadText: {
        color: "#39b68e"
    },
    quotes_CompareImg: {
        backgroundImage: "linear-gradient(180deg, #e8f4fd 0%, #b6def7 100%)"
    },
    quotes_CompareText: {
        color: " #4670b6"
    },
    allQuotesNavLinkStyle: {
        textDecoration: 'none',
        '&:focus, &:hover, &:visited, &:link, &:active': {
            textDecoration: 'none'
        }
    },
};
// Customizable Area End