import React from "react";
// Customizable Area Start
import "./index.css";
import { CssBaseline } from "@material-ui/core";
import LandingPageControllerWeb, { Props } from "./LandingPageController.web";
import ToolbarWeb from "./Toolbar.web";
import FeaturesofQuotecheck from "./FeaturesofQuotecheck.web";
import HowItWorks from "./HowItWorks.web";
import AboutQuotecheck from "./AboutQuotecheck.web";
import Footer from "./Footer.web";
// Customizable Area End

// Customizable Area Start
export default class LandingPageWeb extends LandingPageControllerWeb {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  render() {
    return (
      <>
        <div className="navbar-container">
          <CssBaseline />
          {/* @ts-ignore */}
          <ToolbarWeb />
          {/* @ts-ignore */}
          <FeaturesofQuotecheck />
          {/* @ts-ignore */}
          <HowItWorks />
          {/* @ts-ignore */}
          <AboutQuotecheck />
          {/* @ts-ignore */}
          <Footer />
        </div>
      </>
    );
  }
}
// Customizable Area End
