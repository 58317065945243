import { BlockComponent } from "../../../framework/src/BlockComponent";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  // Customizable Area Start
    modalOpen: boolean,
    classes: any,
    handleReportClose: any,
    selectedItem: any[]
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  // Customizable Area End
}

export default class ReportModalController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    
    this.state = {
      // Customizable Area Start
      // Customizable Area End
    };

    // Customizable Area Start
    // Customizable Area End
  }

 

  // Customizable Area Start
  // Customizable Area End
}
