// Customizable Area Start
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { IBlock } from "../../../framework/src/IBlock";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  // Customizable Area Start
  navigation: any;
  id: string;
  classes: any;
  handleSaveData: (data: any) => void;
  history: any
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  comparisonData: any
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  // Customizable Area End
}

export default class QuotesComparisonController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  quotescomparisonApi:string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage)
    ]

    this.state = {
      // Customizable Area Start
      comparisonData: []
      // Customizable Area End
    };

    
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async componentDidMount() {
    this.quotesComparisonData()
  }
  
  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiRequestCallId === this.quotescomparisonApi) {
        this.setState({ comparisonData: responseJson.data })
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  quotesComparisonData = () => {
    const header = {
      "Content-Type": configJSON.quotesApiContentType,
      "token": document.cookie.split("; ").find((row) => row.startsWith("Token="))?.split("=")[1]
    };

    const quotesComparisonMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.quotescomparisonApi = quotesComparisonMessage.messageId;

    quotesComparisonMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.quotesComparisonAPiEndPoint
    );

    quotesComparisonMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    quotesComparisonMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getUploadApiMethod
    );

    runEngine.sendMessage(quotesComparisonMessage.id, quotesComparisonMessage);
    return true;
  }
  // Customizable Area End
}
