// Customizable Area Start
import React from "react";
import MessageEnum, { getName, } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from '../../../framework/src/IBlock';
export const configJSON = require("./config");
import { messageIcon } from "./assets";
import { Message } from "../../../framework/src/Message";

export interface Props {
    navigation?: any;
    id?: any;
    history?: any;
    notificationStates?: {};
    openDialog?: boolean;
    handleCloseDialogFunction?: () => void;
    handleImageChange?: any;
    handleSaveData?: any;
    // Customizable Area End 
}

export interface ListProps {
    quotes?: any;
    invoice?: any
}

interface S {
    // Customizable Area Start
    docImg: string;
    tabValue: number,
    selectedNotificationData: any,
    clickNotificationshow: boolean,
    token: string,
    dialogModal: boolean,
    deletenotificationMsg: string,
    replyValue: string,
    notificationReplyData: [],
    itemData: any
}
interface SS {
    id: any;

}
export default class NotificationPanelController extends BlockComponent<
    Props, S, SS> {
    getClickHereNotificationsApiId: string = "";
    deleteNotificationApiId: string = "";
    replyNotificationApiId: string = "";
    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        this.state = {
            docImg: `${messageIcon}`,
            tabValue: 0,
            selectedNotificationData: {},
            clickNotificationshow: false,
            token: '',
            dialogModal: false,
            deletenotificationMsg: "",
            replyValue: "",
            notificationReplyData: [],
            itemData: {}
        };
        this.subScribedMessages = [
            getName(MessageEnum.CountryCodeMessage),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.ReciveUserCredentials),
            getName(MessageEnum.NavigationPayLoadMessage),
        ];
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages)

    }


    // Customizable Area Start

    async receive(from: string, message: Message) {
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            if (apiRequestCallId === this.getClickHereNotificationsApiId) {
                this.handleNotificationData(responseJson)
            }

            if (apiRequestCallId === this.deleteNotificationApiId) {
                this.handleNotificationDeleteData(responseJson)
            }   

            if (apiRequestCallId === this.replyNotificationApiId) {
                this.handleNotificationReplyData(responseJson)
            }
        }

        if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
            const itemData = message.getData(
                getName(MessageEnum.SessionResponseData)
            );
            const { data } = itemData;
            this.setState({ selectedNotificationData: data, clickNotificationshow: true })
        }
    }

    async componentDidMount() {
        const getToken: any = await document.cookie.split("; ").find((row) => row.startsWith("Token="))?.split("=")[1];
        this.setState({ token: getToken })
    }

    handleValueChange = (event: React.ChangeEvent<{}> | any, newValue: number) => {
        this.setState({ tabValue: newValue });
    };

    handleNotificationData = (responseJson: any) => {
        if (responseJson.error || responseJson.errors) {
            this.setState({ clickNotificationshow: false })
        } else {
            this.setState({ selectedNotificationData: responseJson.data, notificationReplyData: [] })
        }
    }

    handleNotificationReplyData = (responseJson: any) => {
        if (responseJson.message == "Reply created") {
            this.setState({ notificationReplyData: responseJson.data.reply, replyValue: "" })
        }
    }

    handleNotificationDeleteData = (responseJson: any) => {
        if (responseJson) {
            this.setState({ clickNotificationshow: false, dialogModal: false, deletenotificationMsg: responseJson.message })
        }
    }

    closeDeleteDialogHandler = () => {
        this.setState({ dialogModal: true })
    }

    replyHandleChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        const { target: { value } } = event;
        this.setState({ replyValue: value })
    }

    handleCloseDialogFunction = () => {
        this.setState({ dialogModal: false })
    }

    handleNotificationClick = (items: any) => {
        this.setState({ clickNotificationshow: true })

        const replynotification_id = this.state.selectedNotificationData?.attributes?.id

        const header = {
            "Content-Type": configJSON.validationApiContentType,
            "token": this.state.token
        };

        const clickNotificationRequestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.getClickHereNotificationsApiId = clickNotificationRequestMessage.messageId;

        clickNotificationRequestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.getClickNotificationApiEndPoint}/${items?.attributes?.id || replynotification_id}`
        );
        clickNotificationRequestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        clickNotificationRequestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.getBulkUploadMethod
        );

        runEngine.sendMessage(clickNotificationRequestMessage.id, clickNotificationRequestMessage);

        return true;
    }


    handleReplyClick = () => {
        const header = {
            "Content-Type": configJSON.notificationApiContentType,
            "token": this.state.token
        };

        const httpBody = {
            notification_id: this.state.selectedNotificationData?.attributes?.id,
            user_reply: this.state.replyValue
        };

        const replyNotificationMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.replyNotificationApiId = replyNotificationMessage.messageId;

        replyNotificationMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.replyNotificationApiEndPoint
        );

        replyNotificationMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        replyNotificationMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(httpBody)
        );

        replyNotificationMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.createBulkUploadMethod
        );

        runEngine.sendMessage(replyNotificationMessage.id, replyNotificationMessage);

        return true;
    }

    handelDeleteNotification = () => {

        const header = {
            "Content-Type": configJSON.validationApiContentType,
            "token": this.state.token
        };

        const deletenotification_id = this.state.selectedNotificationData?.attributes?.id
        const deleteNotificationrequestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.deleteNotificationApiId = deleteNotificationrequestMessage.messageId;

        deleteNotificationrequestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.notificationDeleteApiEndpoint}/${deletenotification_id}`
        );

        deleteNotificationrequestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.deleteBulkUploadMethod
        );

        deleteNotificationrequestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        runEngine.sendMessage(deleteNotificationrequestMessage.id, deleteNotificationrequestMessage);

        return true;
    }

    // Customizable Area End
}

