import React from "react";
// Customizable Area Start
import "./index.css";
import { Theme,styled } from "@material-ui/core";
import { withStyles, withTheme } from "@material-ui/core/styles";
import FeaturesofQuotecheckControllerWeb, {
  Props
} from "./FeaturesofQuotecheckController.web";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import { imgOcr, imgPdf, imgReport,calculatorIcon,easyHandIcon,fileIcon,folderCloudIcon,sectionHeadingLine} from "../src/assets";

const SectionHeadingWithUnderlineBox = styled(Box)({
  display:"flex",
  flexDirection:"column",
  justifyContent:"center",
  alignItems:"center"
})

const SectionHeadingLine = styled("img")({
  marginTop:"25px"
})

// Customizable Area End

const styles = (theme: Theme) => ({
  // Customizable Area Start
  large: {
    width: theme.spacing(20),
    height: theme.spacing(20)
  },
  featuresCards: {
    marginTop: 10,
    marginBottom: 10,
    "& .MuiCard-root": {
      height: "100%"
    },
    display:"flex",
    justifyContent:"space-around",
    maxWidth: 1290, 
    width: '100%', 
    margin: 'auto'
  },
  logo: {
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    height: 130,
    width: 130,
    backgroundSize: "contain",
    borderRadious: "50%"
  },
  ocr: {
    backgroundImage: `url(${imgOcr})`
  },
  pdf: {
    backgroundImage: `url(${imgPdf})`
  },
  report: {
    backgroundImage: `url(${imgReport})`
  },
  featureCardBox:{
    borderRadius:"15px",
    padding:"30px 70px",
    boxShadow:"0px 0px 13px rgba(136, 136, 136, 0.28)",
    backgroundColor:"white",
    overflow: 'hidden',

  },
  blueFeatureBox:{
    background: '#60C2C8', 
    width: 120, 
    height: 120, 
    transform: 'rotate(45deg)', 
    left: '-60px', 
    top: '-60px'
  },
  orangeFeatureBox:{
    background: '#F5B069', 
    width: 120, 
    height: 120, 
    transform: 'rotate(45deg)', 
    left: '-60px', 
    top: '-60px'
  },
  purpleFeatureBox:{
    background: '#9080FB', 
    width: 120, 
    height: 120, 
    transform: 'rotate(45deg)', 
    left: '-60px', 
    top: '-60px'
  },
  greenFeatureBox:{
    background: '#A1D46C', 
    width: 120, 
    height: 120, 
    transform: 'rotate(45deg)', 
    left: '-60px', 
    top: '-60px'
  },
  featureCardIconImg:{
    transform: 'rotate(-45deg)', 
    right: 10, 
    bottom: 40
  },
  featureCardHeading:{
    fontSize:"24px",
    color:"#05052B",
    marginBottom:"20px",
    fontWeight:500,
    "@media (max-width:500px)":{
      fontSize:"20px"
    }
  },
  featureCardText:{
    fontSize:"16px",
    color:"#05052B",
    fontWeight:500,
    minHeight: 69,
    "@media (max-width:500px)":{
      fontSize:"15px"
    }
  }
  // Customizable Area End
});

class FeaturesofQuotecheck extends FeaturesofQuotecheckControllerWeb {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  featuresCard = (classes: any) => {
    return (
      <div className="featuresCards" data-test-id="featureCard">
        <Grid container spacing={3} className={classes.featuresCards}>
          <Grid  item xs={12} lg={6}>
          <Box className={classes.featureCardBox} style={{position:"relative"}}>
            <div className={classes.blueFeatureBox} style={{position:"absolute"}}>
            <img src={easyHandIcon} className={classes.featureCardIconImg} style={{position:"absolute"}}/>
            </div>
           <p className={classes.featureCardHeading}>Easy to Use</p>
           <p className={classes.featureCardText}>Simple and easy for everyone. No tech headaches, just straightforward project finance management.</p>
          </Box>
          </Grid>
          <Grid  item xs={12} lg={6}>
          <Box className={classes.featureCardBox} style={{position:"relative"}}>
          <div className={classes.orangeFeatureBox} style={{position:"absolute"}}>
            <img src={calculatorIcon} className={classes.featureCardIconImg} style={{position:"absolute"}}/>
            </div>
           <p className={classes.featureCardHeading}>Auto Calculation</p>
           <p className={classes.featureCardText}>Our latest technology handles the math, letting you focus on building instead of crunching numbers</p>
          </Box>
          </Grid>
        <Grid item xs={12} lg={6}>
        <Box className={classes.featureCardBox} style={{position:"relative"}}>
        <div className={classes.purpleFeatureBox} style={{position:"absolute"}}>
            <img src={fileIcon} className={classes.featureCardIconImg} style={{position:"absolute"}}/>
            </div>
           <p className={classes.featureCardHeading}>Instant Comparision</p>
           <p className={classes.featureCardText}>Use Optical Character Recognition (OCR) to quickly compare construction quotes and invoices in real-time, for instant clarity and better decisions.</p>
          </Box>
        </Grid>
        <Grid item xs={12} lg={6}>
        <Box className={classes.featureCardBox} style={{position:"relative"}}>
        <div className={classes.greenFeatureBox} style={{position:"absolute"}}>
            <img src={folderCloudIcon} className={classes.featureCardIconImg} style={{position:"absolute"}}/>
            </div>
           <p className={classes.featureCardHeading}>Secured Cloud-based Storage</p>
           <p className={classes.featureCardText}>Your documents are securely stored in the cloud, ready to be accessed whenever you need them.</p>
          </Box>
        </Grid>
        </Grid>
      </div>
    );
  };
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes } = this.props;
    return (
      <div className={"featuresContainer"}>
        <div className={"container"}>
          <SectionHeadingWithUnderlineBox>
          <div className="heading">
            Features
          </div>
          <SectionHeadingLine src={sectionHeadingLine}/>
          </SectionHeadingWithUnderlineBox>
          {this.featuresCard(classes)}
        </div>
      </div>
    );
  }
  // Customizable Area End
}

export default withTheme(withStyles(styles)(FeaturesofQuotecheck));
