// Customizable Area Start
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { addDays } from 'date-fns';
import { IBlock } from "../../../framework/src/IBlock";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  // Customizable Area Start
  navigation: any;
  history: any;
  handleSaveData: (data: []) => void;
  // Customizable Area End
}
export interface TabPanelProps {
    // Customizable Area Start
    children?: React.ReactNode;
    index: string | number;
    value: string | number;
    // Customizable Area End
}
interface S {
  // Customizable Area Start
value: number;
token: string;
toBePaidDocumentsData: any[];
filterTobepaidDocModal: boolean;
vendorTobepaidValue: string;
projectTobepaidvValue: string;
tobepaidhasDateRangeModal: boolean;
deleteTobepaidModal: boolean;
deleteModalId: number;
vendorOptionValue: [];
ProjectOptionValue: [];
dateTobepaidRangeData: [{
  startDate: Date;
  endDate: Date;
  key: string
}];
toBePaidTypeDocumentError: string
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  // Customizable Area End
}

export default class TobepaidDocumentsController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getToBePaidTypeDocumentAPiId: string = "";
  getSearchTobepaidDocProjectApiId: string = "";
  getTobepaidDocumentsVendorApiId: string = "";
  getTobepaidDocumentsProjectApiId: string = "";
  deleteTobepaidDocumentApiId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    
    this.state = {
      // Customizable Area Start
      value: 0,
      token: '',
      toBePaidDocumentsData: [],
      filterTobepaidDocModal: false,
      vendorTobepaidValue: "",
      projectTobepaidvValue: "",
        tobepaidhasDateRangeModal: false,
        deleteTobepaidModal: false,
        deleteModalId: 0,
        dateTobepaidRangeData: [{
          startDate: new Date(),
          endDate: addDays(new Date(), 0),
          key: 'selection'
        }],
        // token: '',
        ProjectOptionValue: [],
        vendorOptionValue: [],
        toBePaidTypeDocumentError: ""
      // Customizable Area End
    };
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
    ];
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId === this.getToBePaidTypeDocumentAPiId) {
        this.toBePaidDocumentsResponseHandler(responseJson)
      }

      if (apiRequestCallId === this.getTobepaidDocumentsProjectApiId) {
        this.projectTobepaidResponseHandler(responseJson)
      } 
      if (apiRequestCallId === this.getTobepaidDocumentsVendorApiId) {
        this.vendorTobepaidResponseHandler(responseJson)
      }

      if (apiRequestCallId === this.getSearchTobepaidDocProjectApiId) {
        this.toBepaidDocumentFilterResponsehandler(responseJson)
      }

      this.tobepaidDeleteResponseHandler(apiRequestCallId, responseJson)

    }
  }

  // Customizable Area Start

  async componentDidMount() {
    const token = await document.cookie.split("; ").find((row) => row.startsWith("Token="))?.split("=")[1];
    token && this.setState({ token: token });
    this.getToBePaidTypeDocuments()
    this.getTobepaidDocumentsVendorsValue()
    this.getTobepaidDocumentsProjectsValue()
  }

  toBePaidDocumentsResponseHandler = (responseJson:any) => {
    if (responseJson.error) {
      this.setState({toBePaidTypeDocumentError: responseJson.error})
    }else{
      this.setState({toBePaidDocumentsData: responseJson.library_document_list})
    }
  }

  projectTobepaidResponseHandler = (responseJson:any) => {
    if (responseJson && responseJson.data && !responseJson.errors) {
      this.setState({ ProjectOptionValue: responseJson.data })
    }
  }
  vendorTobepaidResponseHandler = (responseJson:any) => {
    if (responseJson && responseJson.data && !responseJson.errors) {
      this.setState({ vendorOptionValue: responseJson.data })
    }
  }

  toBepaidDocumentFilterResponsehandler = (responseJson: any) => {
    if (responseJson.message == "Success" && responseJson.data) {
      this.setState({toBePaidDocumentsData: responseJson.data})
    }else if(responseJson.message == "No records found"){
      this.setState({toBePaidTypeDocumentError: responseJson.message, toBePaidDocumentsData: []})
    }
  }

  tobepaidDeleteResponseHandler = (apiRequestCallId: any, responseJson: any) => {
    if (apiRequestCallId === this.deleteTobepaidDocumentApiId) {
      const updatedData = this.state.toBePaidDocumentsData.filter((item: any) => (item.id  !== this.state.deleteModalId) && (item.invoice_id  !== this.state.deleteModalId))
      this.setState({ toBePaidDocumentsData: updatedData, deleteTobepaidModal: false, value: 0 })
    }
  }

  handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    this.setState({value: newValue})
  }

  
  handleOpenTobepaidFilterModal = () => {
    this.setState({filterTobepaidDocModal: !this.state.filterTobepaidDocModal})
  }

  handleCloseTobepaidFilterModal = () => {
    this.setState({filterTobepaidDocModal: false})
  }

  selectTobepaidVendorValue = (event: React.ChangeEvent<{ value?: string | unknown }>)  => {
    const selectedVendor = event.target.value as string;
    this.setState({ vendorTobepaidValue: selectedVendor });
  }

  selectTobepaidProjectValue = (event: React.ChangeEvent<{ value?: string | unknown }>)  => {
    const selectedProject = event.target.value as string;
    this.setState({ projectTobepaidvValue: selectedProject });
  }

  handleTobepaidDateRangeOpenModal = () => {
    this.setState({ tobepaidhasDateRangeModal: !this.state.tobepaidhasDateRangeModal })
  }

  handleTobepaidSelectDateRangeValue = (data: any) => {
    this.setState({ dateTobepaidRangeData: data })
  }

  handleOpenTobepaidDeleteModal = (itemId: number) => {
    this.setState({deleteTobepaidModal: !this.state.deleteTobepaidModal, deleteModalId: itemId})
  }

  handleCloseTobepaidDeleteModal = () => {
    this.setState({deleteTobepaidModal: false})
  }

  getToBePaidTypeDocuments = () => {
    const token = this.state.token;

    const header = {
      "Content-Type": configJSON.productApiContentType,
      "token": token
    };

    const requestToBePaidDocumentsMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getToBePaidTypeDocumentAPiId = requestToBePaidDocumentsMessage.messageId;

    requestToBePaidDocumentsMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.toBePaidDocumentApiEndPoint
    );

    requestToBePaidDocumentsMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestToBePaidDocumentsMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );

    runEngine.sendMessage(requestToBePaidDocumentsMessage.id, requestToBePaidDocumentsMessage);

    return true;
  }

  getTobepaidDocumentsProjectsValue = () => {
    const token = this.state.token;

    const header = {
      "Content-Type": configJSON.productApiContentType,
      "token": token
    };

    const tobepaidDocProjectRequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getTobepaidDocumentsProjectApiId = tobepaidDocProjectRequestMessage.messageId;

    tobepaidDocProjectRequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.projectApiEndPoint
    );

    tobepaidDocProjectRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    tobepaidDocProjectRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );

    runEngine.sendMessage(tobepaidDocProjectRequestMessage.id, tobepaidDocProjectRequestMessage);

    return true;
  }

  getTobepaidDocumentsVendorsValue = () => {
    const token = this.state.token;

    const header = {
      "Content-Type": configJSON.productApiContentType,
      "token": token
    };

    const tobepaidDocVendorRequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getTobepaidDocumentsVendorApiId = tobepaidDocVendorRequestMessage.messageId;

    tobepaidDocVendorRequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.vendorApiEndPoint
    );

    tobepaidDocVendorRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    tobepaidDocVendorRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );

    runEngine.sendMessage(tobepaidDocVendorRequestMessage.id, tobepaidDocVendorRequestMessage);

    return true;
  }

  handletoBepaidProjectsSearch = () => {
    this.setState({filterTobepaidDocModal: false, vendorTobepaidValue: "", projectTobepaidvValue: "" })
    
    const token = this.state.token;

    const allDocProjectListData = {
      vendor: this.state.vendorTobepaidValue,
      project: this.state.projectTobepaidvValue,
      date: this.state.dateTobepaidRangeData
    }

    const header = {
      "Content-Type": configJSON.productApiContentType,
      "token": token
    };

    const httpBody = {
      data: allDocProjectListData,
    };

    const getSearchTobepaidProjectsrequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getSearchTobepaidDocProjectApiId = getSearchTobepaidProjectsrequestMessage.messageId;

    getSearchTobepaidProjectsrequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.toBePaidSearchApiEndPoint
    );

    getSearchTobepaidProjectsrequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    getSearchTobepaidProjectsrequestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    getSearchTobepaidProjectsrequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );

    runEngine.sendMessage(getSearchTobepaidProjectsrequestMessage.id, getSearchTobepaidProjectsrequestMessage);

    return true;
  }

  handleDeleteTobepaidDocument = () => {
    const token = this.state.token;

    const header = {
      "Content-Type": configJSON.productApiContentType,
      "token": token
    };

    const deleteDocumentrequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.deleteTobepaidDocumentApiId = deleteDocumentrequestMessage.messageId;

    deleteDocumentrequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.deleteApiEndPoint}/${this.state.deleteModalId}`
    );

    deleteDocumentrequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteApiMethod
    );

    deleteDocumentrequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    runEngine.sendMessage(deleteDocumentrequestMessage.id, deleteDocumentrequestMessage);

    return true;
  }
  // Customizable Area End
}
