import React from 'react'
// Customizable Area Start
import { Message } from "../../../framework/src/Message";
import MessageEnum, { getName, } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from '../../../framework/src/IBlock';
export const configJSON = require("./config");
// Customizable Area End

export interface Props {
    navigation: any;
    id: string;
    history: any;
    // Customizable Area Start
    // Customizable Area End
}
interface S {
    // Customizable Area Start
    email: string;
    name: string;
    password: string;
    flag: boolean;
    apiError: boolean;
    serverSideError: boolean,
    isSuccses: boolean;
    showPassword: boolean;
    isPasswordValid: any
    // Customizable Area End
}
interface SS {
    // Customizable Area Start
    id: any;
    // Customizable Area End
}
export default class EmailAccountRegistrationWebController extends BlockComponent<Props, S, SS>{
    // Customizable Area Start
    resgisterNewUserApi: string = "";
    // Customizable Area End
    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        // Customizable Area Start
        this.state = {
            email: "",
            name: "",
            password: "",
            flag: false,
            apiError: false,
            serverSideError: false,
            isSuccses: false,
            showPassword: false,
            isPasswordValid: {
                minimumEightCharacters: false,
                oneUpperCase: false,
                oneNumber: false,
                oneSpecialCharacter: false
            },
        }
        this.subScribedMessages = [
            getName(MessageEnum.CountryCodeMessage),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.ReciveUserCredentials),
        ];
        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages)
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start

        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            if (apiRequestCallId === this.resgisterNewUserApi) {

                if (responseJson) {
                    if (responseJson?.error) {
                        this.setState({ serverSideError: true })
                        this.setState({ apiError: true })
                    }
                    else if (responseJson?.errors) {
                        this.setState({ apiError: true })
                    }

                    else {
                        this.setState({ isSuccses: true });
                        setTimeout(() => {
                            this.setState({ flag: true })
                            this.props.history.push("/login")
                        }, 1000)

                    }

                }

            }


        }
        // Customizable Area End
    }

    async componentDidMount() {
        // Customizable Area Start
        if (this.props.history.location.pathname === "/login") {
            this.setState({ flag: true })
        }
        // Customizable Area End
    }
    // Customizable Area Start
    onButtonClick(id: string) {
        if (id === "login" && this.props.history.location.pathname === "/registration") {
            this.props.history.push("/login")
        }
        else if (id === "register" && this.props.history.location.pathname === "/login") {
            this.props.history.push("/registration")
        }
    }

    gotoHome() {
        this.props.history.push("/")
    }

    validatePassword = (name: any, value: any) => {
        // Regular expressions for validation
        const minLengthRegex = /.{8,}/;
        const uppercaseRegex = /[A-Z]/;
        const numberRegex = /\d/;
        const specialCharRegex = /[!@#$%^&*(),.?":{}|<>]/;

        const isMinLengthValid = minLengthRegex.test(value);
        const isUppercaseValid = uppercaseRegex.test(value);
        const isNumberValid = numberRegex.test(value);
        const isSpecialCharValid = specialCharRegex.test(value);
        this.setState(prevData => ({
            ...prevData,
            isPasswordValid: {
                minimumEightCharacters: isMinLengthValid,
                oneUpperCase: isUppercaseValid,
                oneNumber: isNumberValid,
                oneSpecialCharacter: isSpecialCharValid
            }, [name]: value, passwordErrMsg: ""
        }));
    };

    handleChange = (event: any) => {
        const { value } = event.target;
        const minLengthRegex = /.{8,}/;
        const uppercaseRegex = /[A-Z]/;
        const numberRegex = /\d/;
        const specialCharRegex = /[!@#$%^&*(),.?":{}|<>]/;

        const isMinLengthValid = minLengthRegex.test(value);
        const isUppercaseValid = uppercaseRegex.test(value);
        const isNumberValid = numberRegex.test(value);
        const isSpecialCharValid = specialCharRegex.test(value);
        this.setState(prevData => ({
            ...prevData,
            isPasswordValid: {
                minimumEightCharacters: isMinLengthValid,
                oneUpperCase: isUppercaseValid,
                oneNumber: isNumberValid,
                oneSpecialCharacter: isSpecialCharValid
            }, password: value
        }));
    };

    handleClickShowPassword = () => {
        this.setState({ showPassword: !this.state.showPassword })
    };

    onSubmit(): boolean {

        this.setState({ flag: false })
        const data = {
            type: "email_account",
            attributes: {
                name: this.state.name,
                email: this.state.email,
                password: this.state.password,
                activated: "true"

            }
        }
        const header = {
            "Content-Type": configJSON.resgisterNewUserApiContentType,
        };

        const httpBody = {
            data: data,
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.resgisterNewUserApi = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.resgisterNewUserApiEndPoint
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(httpBody)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.resgisterNewUserApiMethod
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);

        return true;
    }
    // Customizable Area End
}
