// Customizable Area Start
import React from "react";
import { Grid, Typography } from "@material-ui/core";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import {
  createTheme,
  withStyles,
} from "@material-ui/core/styles";
import ProjectController, { Props, configJSON } from "./ProjectController.web";
import DashboardNewTable from "../../landingpage/src/DashboardNewTable.web";
import Header from "../../landingpage/src/Header.web";
// Customizable Area End

// Customizable Area Start
const theme = createTheme({
  typography: {
    h6: {
      color: " #05052b",
      fontSize: "25px",
    },
  },
});
// Customizable Area End
class Project extends ProjectController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes, navigation, handleSaveData }: any = this.props;
    // Customizable Area End

    return (
      // Customizable Area Start
      <>
          <Header navigation={navigation} handleSaveData={handleSaveData} history={this.props.history}/>
          <Typography variant="h6" className={classes.projectTitle}>
            <ChevronLeftIcon onClick={this.handleProjectBackClick} data-testid="pojectBackClick"/>
            {configJSON.libraryContent}
          </Typography>
          <Grid container>
            <Grid
              item
              lg={12}
              md={12}
              sm={12}
              xs={12}
              className={classes.headerProjectBox}
            >
              <DashboardNewTable
                navigatePath="/sorting/projects"
                rows={this.state.projectInfo}
                title="Projects"
                hasMenu={true}
              />
            </Grid>
          </Grid>
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start

export const projectTableStyle = (theme: any) => ({
  headerProjectBox: {
    "& .MuiTableContainer-root": {
      height: "50vh",
    },
    "& table": {
      height: "100%",
    },
  },
  projectTitle: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: '8px',
    padding: '20px 0',
    '& svg': {
      fontSize: '32px'
    }
  }
});

export { Project }
export default withStyles(projectTableStyle)(Project)

// Customizable Area End
