import React from "react";
// Customizable Area Start
import "./index.css";
import { withStyles, withTheme } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Button from "@material-ui/core/Button";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import MoreIcon from "@material-ui/icons/MoreVert";
import ToolbarControllerWeb, { Props } from "./ToolbarController.web";
import Grid from "@material-ui/core/Grid";
import { myInvoiceCheckLogo, heroImageToolbar } from "../src/assets";
// Customizable Area End

const styles = (theme: any) => ({
  grow: {
    flexGrow: 1
  },
  logo: {
    backgroundImage: `url(${myInvoiceCheckLogo})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    height: 74.36,
    width: 300,
    backgroundSize: "contain"
  },
  menuButton: {
    marginRight: theme.spacing(3),
    fontSize: 24,
    color: "#fff",
    textTransform: "capitalize"
  },
  title: {
    display: "block"
  },
  sectionDesktop: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "flex"
    }
  },
  sectionMobile: {
    display: "flex",
    [theme.breakpoints.up("md")]: {
      display: "none"
    }
  },
  leftsideTextContent: {
    [theme.breakpoints.up("md")]: {
      display: "none"
    }
  }
});

class ToolbarWeb extends ToolbarControllerWeb {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  handleProfileMenuOpen = (event: any) => {
    this.setState({
      achorEl: event.currentTarget
    });
  };

  handleMobileMenuClose = () => {
    this.setState({
      mobileMoreAnchorEl: null
    });
  };

  handleMenuClose = () => {
    this.setState({
      achorEl: null,
      mobileMoreAnchorEl: null
    });
  };

  handleMobileMenuOpen = (event: any) => {
    this.setState({
      mobileMoreAnchorEl: event.currentTarget
    });
  };
  // Customizable Area End

  render() {
    const { classes } = this.props;
    const { mobileMoreAnchorEl } = this.state;
    const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

    const mobileMenuId = "primary-search-account-menu-mobile";
    const renderMobileMenu = (
      <Menu
        anchorEl={this.state.mobileMoreAnchorEl}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        id={mobileMenuId}
        keepMounted
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        open={isMobileMenuOpen}
        onClose={this.handleMobileMenuClose}
      >
        <MenuItem>
          <p>Home</p>
        </MenuItem>
        <MenuItem>
          <p>Contact us</p>
        </MenuItem>
        <MenuItem>
          <p>Sign up</p>
        </MenuItem>
        <MenuItem>
          <p>Log In</p>
        </MenuItem>
      </Menu>
    );

    return (
      <div className={"navbarContainer"}>
        <div className={`${classes.grow} navbar`}>
          <AppBar
            position="static"
            style={{ background: "transparent", boxShadow: "none" }}
          >
            <Toolbar style={{ backgroundColor: "none" }}>
              <div className={classes.logo} />
              <div className={classes.grow} />
              <div className={classes.sectionDesktop}>
                <Button
                  className={classes.menuButton}
                  style={{
                    border: "1px solid #FFF"
                  }}
                >
                  Home
                </Button>
                <Button className={classes.menuButton}>Contact us</Button>
                <Button className={classes.menuButton}>Sign up</Button>
                <Button className={classes.menuButton}>Log In</Button>
              </div>
              <div className={classes.sectionMobile}>
                <Button
                  aria-label="show more"
                  aria-controls={mobileMenuId}
                  aria-haspopup="true"
                  onClick={this.handleMobileMenuOpen}
                >
                  <MoreIcon />
                </Button>
              </div>
            </Toolbar>
          </AppBar>
          {renderMobileMenu}
          <div className="main-container">
            <Grid container spacing={1}>
              <Grid item xs={12} lg={5}>
                <div className="leftside-content">
                  <div>
                    We Help You to <br />
                    Get{" "}
                    <span className={classes.leftsideTextContent}>
                      {" "}
                      Best Business
                    </span>
                    <span className="border-container">
                      <span className="text-content"> Best Business</span>
                      <span className="border" />
                    </span>
                    <p>Solution Ever</p> <br />
                  </div>
                  <small>
                    Lorem ipsum dolor sit, amet consectetur adipisicing elit.
                    Numquam dolorem
                  </small>
                </div>
              </Grid>
              <Grid item xs={12} lg={7}>
                <div className="rightside-content">
                  <img
                    src={heroImageToolbar}
                    className="hero_image"
                    alt="hero_img"
                  />
                </div>
              </Grid>
            </Grid>
          </div>
        </div>
      </div>
    );
  }
}
//@ts-ignore
export default withTheme(withStyles(styles)(ToolbarWeb));
