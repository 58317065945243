// Customizable Area Start
Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.addkeywordAPiEndPoint = "bx_block_pdfkeywordhighlighter/create_keywords";
exports.allKeyworeds = "bx_block_pdfkeywordhighlighter/keywords"
exports.removeKeyworeds = "bx_block_pdfkeywordhighlighter/delete_keywords"
exports.uploadFile = "bx_block_pdfkeywordhighlighter/upload_document";
exports.highlightPdfData = "bx_block_pdfkeywordhighlighter2/discrepancies_highlighted"
exports.postAPiMethod = "POST";
exports.exampleAPiMethod = "POST";
exports.deleteAPiMethod = "DELETE";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Keyword";
exports.txtInputPlaceholder = "Enter Keyword";
exports.labelTitleText = "pdfkeywordhighlighter";
exports.labelBodyText = "Search your keywords in pdf";
exports.getFileUrl = "bx_block_pdfkeywordhighlighter/show_all_documents";
exports.deleteDocumentUrl="bx_block_pdfkeywordhighlighter/remove_document"
exports.uploadButtonText="+ UPLOAD"
exports.webUrlConfig='https://b991-20-244-58-182.ngrok-free.app/PdfKeywordHighlighter2?token='


exports.btnAddKeyword = "ADD";
exports.btnChoosePDF = "CHOOSE PDF";
exports.btnRemovePDF = "REMOVE PDF";
exports.btnExampleTitle = "APPLY";
exports.btnViewPDF = "VIEW PDF";
exports.btnDownloadPDF = "DOWNLOAD PDF";


// Customizable Area End
