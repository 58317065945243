// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { IBlock } from "../../../framework/src/IBlock";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
export const configJSON = require("./config");
import moment from "moment";
// Customizable Area End
type Contact = string | number | null;

interface columnQuote {
  id: string;
  label: string;
}
interface CategoryData {
  id: number;
  categoryName: string;
}
interface EditQuoteDetailData {
  id: number,
  quote_id: string | number,
  date: Contact,
  vendor: string;
  project: string | number,
  status: string;
  subtotal: string | number,
  created_at: string | number,
  dateVal: Contact,
  expiryDateVal: Contact,
  supplier_address: string | number,
  supplier_salesperson: string | number,
  supplier_contact: string | number,
  site_name: string | number,
  site_address: string | number,
  site_contactperson: string | number,
  site_contact: Contact,
  expiration_date: Contact,
  tax_rate_amount: string | number,
  tax: any,
  total_price: string | number,
  discount: string | number,
  other_charges: string | number,
  s_and_h_charges: string | number,
  report_id: string | number
  tag: string;
  quote_descriptions: null;
  updated_at: string;
  gross_subtotal: number;
  pdf_page_count: number;
}
interface EditResponseInterface {
  data: {
    attributes: {
      quote: EditQuoteDetailData[];
      category_description: [],
      project: number,
      id: number,
      description: string,
      ord_quantity: string,
      unit: string,
      price: string,
      amount: string,
      report_quote_id: number,
      created_at: string,
      updated_at: string,
      tax: string,
      category_name: string,
      quote_description: TableDataItem[],
    }
  }
}

interface UpdateResponseInterface {
  data: {
    category_description: [],
    project: number,
    id: number,
    description: string,
    ord_quantity: string,
    unit: string,
    price: string,
    amount: string,
    report_quote_id: number,
    created_at: string,
    updated_at: string,
    tax: string,
    category_name: string,
    quote_description: TableDataItem[],
    attributes: {
      quote: EditQuoteDetailData[];
      quote_description: TableDataItem[];
    }
  }
}

interface TableDataItem {
  category_description: string[],
  id: string | number,
  description: string,
  ord_quantity: string,
  unit: string,
  price: string,
  amount: string,
  report_quote_id: number,
  created_at: string,
  updated_at: string,
  tax: string,
  category_name: string,
  taxSwitch: boolean
}
interface TableDescription {
  id: number,
  category_name: string,
  content: string,
  data: (TableDataItem | undefined)[],
  pagination: {
    id: number,
    page: number,
    rowsPerPage: number
  },
  warningTableBox: boolean,
  subtotalValue: number,
  selected: number[],
  QuoteAddNewSegmentState: boolean,
  categoryBox: boolean,
  categoryErrorMessage: string,
  addItemBox: boolean,
}
export interface Props {
  // Customizable Area Start
  classes: any,
  navigation: {};
  id: string;
  handleSaveData: (data: string[]) => void;
  location: any;
  history: any;
  // Customizable Area End
}
export interface QuoteTableHeadProps {
  QuoteAddNewSegmentState: boolean,
  categoryBox: boolean,
  handleSelectAllItemClick: (event: React.ChangeEvent<HTMLInputElement>, columnId: number) => void,
  selectedData: string[] | number[],
  classes: any,
  columnId: number,
  QuoteItemTaxData: boolean | undefined
}
export interface NoItemBoxProps {
  classes: any,
  data: ""
}
export interface AddSegmentBoxProps {
  addNewSegmentVal: boolean,
  classes: any,
  addNewItemRowData: (columnId: number) => void,
  subTotalDataValue: number | string | null,
  columnIdVal: number

}
export interface DeleteButtonProps {
  addNewSegmentVal: boolean,
  addCategoryVal: string,
  handleCategoryChangeName: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, columnId: number) => void,
  classes: any,
  categoryErrorMessageVal: string,
  columnIdVal: number,
  deleteCategoryModal: (columnId: number) => void

}
export interface AddSegmentButtonProps {
  selected: boolean
  addNewSegmentHandler: () => void,
  classes: any,
  deleteOpenModal: () => void,
  deleteCloseModal: () => void
}
export interface QuoteDateBoxProps {
  editQuoteDetail: EditQuoteDetailData,
  classes: any,
  typeOfFocused: string | null,
  handleDate: () => void,
  handleEditQuoteDetail: (field: string, floatValue: string) => void
}
export interface QuoteSiteBoxProps {
  handleEditQuoteDetail: (field: string, floatValue: string) => void,
  editQuoteDetail: EditQuoteDetailData,
}
export interface QuoteExpiryDateBoxProps {
  editQuoteDetail: EditQuoteDetailData,
  classes: any,
  typeOfFocused: string | null | any,
  handleDate: () => void,
  handleEditQuoteDetail: (field: string, floatValue: string) => void
}
export interface NoDataTableRowProps {
  classes: any,
  QuoteItemTaxData: boolean
}
export interface TableCellDataProps {
  QuoteAddNewSegmentState: boolean,
  categoryBox: boolean,
  classes: any,
  selected: number[],
  handleRowItemClick: (event: React.MouseEvent<unknown>, columnId: number, itemId: string) => void,
  descriptionValue: string,
  HandleEditQuoteDescription: (value: unknown, columnId: number, itemId: string, field: string) => void,
  unitValue: string,
  priceValue: number,
  QuoteItemTaxData: boolean,
  QuoteTaxValue: number | null,
  amount: number,
  ord_quantityValue: number,
  columnId: number,
  editquoteId: string,
  begin: number,
  deleteOpenTableItemModal: (itemId: string) => void,
  index: number
}

export interface DayRentalTableProps {
  HandleEditQuoteDescription: (value: unknown, columnId: number, itemId: string, field: string) => void,
  handleRowItemClick: (event: React.MouseEvent<unknown>, columnId: number, itemId: string) => void,
  QuoteAddNewSegmentState: boolean,
  categoryBox: boolean,
  classes: any,
  selected: number[],
  cat_classValue: number,
  descriptionValue: string,
  rate_sheet_catValue: string,
  rate_sheet_minValue: string,
  QuoteItemTaxData: boolean,
  QuoteTaxValue?: number | null,
  amount: number,
  rate_sheet_dailyValue: string,
  columnId: number,
  editquoteId: string,
  begin: number,
  deleteOpenTableItemModal: (itemId: string) => void,
  index: number,
  selectedRadioValue: string,
}

export interface RentalTableCellDataProps {
  HandleEditQuoteDescription: (value: unknown, columnId: number, itemId: string, field: string) => void,
  handleRowItemClick: (event: React.MouseEvent<unknown>, columnId: number, itemId: string) => void,
  QuoteAddNewSegmentState: boolean,
  categoryBox: boolean,
  classes: any,
  selected: number[],
  cat_classValue: number,
  descriptionValue: string,
  rate_sheet_catValue: string,
  rate_sheet_minValue: string,
  QuoteItemTaxData: boolean,
  QuoteTaxValue: number | null,
  amount: number,
  rate_sheet_dailyValue: string,
  columnId: number,
  editquoteId: string,
  begin: number,
  deleteOpenTableItemModal: (itemId: string) => void,
  index: number,
  selectedRadioValue: string,
  rate_sheet_weeklyValue: string,
  rate_sheet_monthlyValue: string,
}

export interface MonthRentalTableProps {
  HandleEditQuoteDescription: (value: unknown, columnId: number, itemId: string, field: string) => void,
  handleRowItemClick: (event: React.MouseEvent<unknown>, columnId: number, itemId: string) => void,
  QuoteAddNewSegmentState: boolean,
  categoryBox: boolean,
  classes: any,
  selected: number[],
  cat_classValue: number,
  descriptionValue: string,
  rate_sheet_catValue: string,
  rate_sheet_minValue: string,
  QuoteItemTaxData: boolean,
  QuoteTaxValue?: number | null,
  amount: number,
  rate_sheet_weeklyValue: string,
  columnId: number,
  editquoteId: string,
  begin: number,
  deleteOpenTableItemModal: (itemId: string) => void,
  index: number,
  selectedRadioValue: string,
}

export interface TotalAmountBoxProps {
  classes: any,
  calculateValue: number,
  s_and_h_chargesValue: Contact,
  other_charges: Contact,
  handleEditQuoteDetail: (field: string, floatValue: unknown) => void;
  discount: Contact,
  tax_rate_amount: Contact,
  totalTax: string | number,
  totalValue: number
}

export interface QuoteTableHeadProps {
  QuoteAddNewSegmentState: boolean,
  categoryBox: boolean,
  classes: any,
  handleSelectAllItemClick: (event: React.ChangeEvent<HTMLInputElement>, columnId: number) => void,
  selectedData: string[] | number[],
  columnId: number
  checkedBox: boolean,
  columnlength: number
}

export interface QuoteTableCellDataProps {
  QuoteAddNewSegmentState: boolean,
  categoryBox: boolean,
  classes: any,
  columnId: number
  checkedBox: boolean,
  HandleEditQuoteDescription: (value: unknown, columnId: number, itemId: string, field: string) => void,
  handleRowItemClick: (event: React.MouseEvent<unknown>, columnId: number, itemId: string) => void,
  selected: number[],
  cat_classValue: number,
  descriptionValue: string,
  rate_sheet_catValue: string,
  rate_sheet_minValue: string,
  QuoteItemTaxData: boolean,
  QuoteTaxValue: number,
  amount: number,
  rate_sheet_weeklyValue: string,
  editquoteId: string,
  begin: number,
  deleteOpenTableItemModal: (itemId: string) => void,
  index: number,
  rate_sheet_dailyValue: string,
  rate_sheet_monthlyValue: string,
  unitValue: string,
  priceValue: number,
  ord_quantityValue: number,
  selectedRadioValue: string
}
interface S {
  // Customizable Area Start
  cancelData:boolean,
  editQuoteDetail: EditQuoteDetailData,
  quotePage: number,
  categoryDescBox: CategoryData[],
  quoteRowsPerPage: number,
  editQuoteColumn: columnQuote[],
  typeOfFocused: string | {},
  selected: string[],
  selectedData: string[],
  token: string | undefined,
  deleteId: string,
  categoryColumnId: number,
  orderQuantity: string,
  QuoteItemTaxData: boolean,
  dragQuotePage: number,
  dragQuoteRowsPerPage: number,
  creteReportData: [],
  deleteModalState: boolean,
  dragShowTable: boolean,
  totalValue: number,
  calculateValue: number,
  dragSubTotalValue: number,
  // addCategory1: string;
  itemTaxValue: boolean;
  tablesData: TableDescription[];
  isEmptyBox: boolean;
  tableDataBox: boolean;
  columnIdState: number;
  categoryValue: string;
  TaxItemid: string;
  selectedRadioValue: string
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  // Customizable Area End
}

export default class EditQuoteScanResultController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  updateQuoteDetailApiId: string = "";
  deleteQuoteDetailApiId: string = "";
  deleteDescriptionApiId: string = "";
  addNewItemApiId: string = "";
  addNewSegmentApiId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.state = {
      // Customizable Area Start
      cancelData:true,
      categoryDescBox: [],

      tablesData: [
        {
          id: 1,
          category_name: "",
          content: "",
          data: [] || this.props.location.state.quoteCategoryDescription,
          pagination: {
            id: 1,
            page: 1,
            rowsPerPage: 10
          },
          warningTableBox: false,
          selected: [],
          subtotalValue: this.props.location.state.quotedetail.subtotal,
          QuoteAddNewSegmentState: false,
          categoryBox: false,
          categoryErrorMessage: "",
          addItemBox: false,
        }],
      TaxItemid: "",
      editQuoteDetail: this.props.location.state.quotedetail,
      creteReportData: this.props.location.state.createReportData,
      typeOfFocused: {} || "",
      quotePage: 1,
      quoteRowsPerPage: 10,
      dragQuotePage: 1,
      dragQuoteRowsPerPage: 10,
      selected: [],
      selectedData: [],
      editQuoteColumn: [
        {
          id: 'Sr.no', label: 'Sr. No',
        },
        {
          id: 'description', label: 'Description',
        },
        {
          id: 'OrderQty.', label: 'Order Qty.',
        },
        {
          id: 'unit', label: 'Unit',
        },
        {
          id: 'unitPrice', label: 'Unit Price',
        },
        {
          id: 'tax', label: 'Tax(%)',
        },
        {
          id: 'Ext. Price', label: 'Ext. Price'
        }
      ],
      token: '',
      deleteId: "",
      categoryColumnId: 0,
      orderQuantity: "",
      QuoteItemTaxData: false,
      itemTaxValue: false,
      deleteModalState: false,
      dragShowTable: false,
      totalValue: 0,
      dragSubTotalValue: this.props.location.state.segmentTotal2,
      calculateValue: 0,
      isEmptyBox: false,
      tableDataBox: false,
      columnIdState: 0,
      categoryValue: "",
      selectedRadioValue: configJSON.dayTitle
      // Customizable Area End
    };

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.ReciveUserCredentials)
    ];
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }
  // Customizable Area Start
  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const editQuoteApiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const editQuoteResponseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (editQuoteApiRequestCallId === this.updateQuoteDetailApiId) {
        this.props.location?.state?.quotescan ? this.retrunEditquoteApiResponseHandle(editQuoteResponseJson)  
        :
        this.updateEditquoteApiResponseHandle(editQuoteResponseJson)
      }

      if (editQuoteApiRequestCallId === this.deleteQuoteDetailApiId) {
        this.deleteQuoteDetailApiResponseHandle(editQuoteResponseJson)
      }

      if (editQuoteApiRequestCallId === this.deleteDescriptionApiId) {
        this.deleteDescriptionApiResponseHandle(editQuoteResponseJson)
      }

      if (editQuoteApiRequestCallId === this.addNewItemApiId) {
        this.addNewItemApiResponseHandle(editQuoteResponseJson)
      }

    }
  }

  getToken = () => {
    return document.cookie.split("; ").find((row) => row.startsWith("Token="))?.split("=")[1];
  }

  async componentDidMount() {
    super.componentDidMount();

    const { tablesData } = this.state

    tablesData.forEach(table => {
      this.subTotalPrice(table.id);
      const eventMock = { target: { value: table.category_name } };
      this.handleCategoryChange(eventMock as React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, table.id)
    });

    setTimeout(() => {
      this.calculateAllDataTotal();
    }, 1000);
    if(this.props.location?.state?.quotescan){
      const scanQuoteedit = this.props.location.state.quotedetail
      const mergedArray = Object.values(this.props.location.state.quotestate).flat();
      const taxSwichValue = mergedArray.some((item:any) => item.tax !== null);
      if(this.props.location?.state?.typeArraysObject){
        const scanQuoteDescriptions = [this.props.location.state.quotestate]?.map((value:any,index:number) => {return{id:index+1, subtotalValue:scanQuoteedit.subtotal, category_name:value[0].category_name === null ? "" :value[0].category_name, data:value?.map((item: any) => ({ ...item, taxSwitch: taxSwichValue })), selected:[],pagination: { id: 1, page: 1, rowsPerPage: 10}}})
        this.setState({editQuoteDetail:scanQuoteedit,tablesData:scanQuoteDescriptions as any})
      }else{
        const scanQuoteDescriptions = Object.values(this.props.location.state.quotestate)?.map((value:any,index:number) => {return{id:index+1, subtotalValue:value[0].category_subtotal, category_name:value[0].category_name  , data:value?.map((item: any) => ({ ...item, taxSwitch: taxSwichValue })), selected:[],pagination: { id: 1, page: 1, rowsPerPage: 10}}})
        this.setState({editQuoteDetail:scanQuoteedit,tablesData:scanQuoteDescriptions as any})
      }
    }
    
    if (this.props.location?.state?.quoteCategoryDescription.length > 0) {
      this.setState({ tablesData: this.props.location?.state?.quoteCategoryDescription });
    } else {
      const newData = this.props.location?.state?.quotestate.map((element: TableDataItem) => {

        return ({
          ...element,
          taxSwitch: false,
        })
      });

      const updatedTablesData = tablesData.map((table) => ({
        ...table,
        data: newData
      }));

      this.setState({ tablesData: updatedTablesData });
    }

  }

  retrunEditquoteApiResponseHandle = (retrunEditQuoteResponseJson:EditResponseInterface) =>{
    this.props.history.push('/report/quotescanresult', {reportDetailsData: retrunEditQuoteResponseJson ,viewQuotescanretrun:true})
  };

  addNewItemApiResponseHandle = (editQuoteResponseJson: EditResponseInterface) => {

    const { tablesData, columnIdState } = this.state;

    const updatedTablesData = tablesData.map((table: TableDescription, index: number) => {

      if (table.id === columnIdState) {

        const { data, pagination } = table;
        const { page, rowsPerPage } = pagination;
        const startIndex = (page - 1) * rowsPerPage;
        const endIndex = startIndex + rowsPerPage;

        const newDescriptionData: TableDataItem = {
          id: editQuoteResponseJson.data.attributes.id,
          description: editQuoteResponseJson.data.attributes.description,
          ord_quantity: editQuoteResponseJson.data.attributes.description,
          unit: editQuoteResponseJson.data.attributes.unit,
          price: editQuoteResponseJson.data.attributes.price,
          amount: editQuoteResponseJson.data.attributes.amount,
          report_quote_id: editQuoteResponseJson.data.attributes.report_quote_id,
          created_at: editQuoteResponseJson.data.attributes.created_at,
          updated_at: editQuoteResponseJson.data.attributes.updated_at,
          tax: editQuoteResponseJson.data.attributes.tax,
          category_name: editQuoteResponseJson.data.attributes.category_name,
          category_description: [],
          taxSwitch: this.state.tablesData[0]?.data?.every((item) => item?.taxSwitch)
        };


        const newData: (TableDataItem | undefined)[] = [
          ...data.slice(0, startIndex),
          newDescriptionData,
          ...data.slice(startIndex, endIndex),
          ...data.slice(endIndex),
        ]

        return {
          ...table,
          data: newData
        };
      } else {
        return table;
      }

    });
    this.setState({ tablesData: updatedTablesData }, () => {
    });
  }

  deleteDescriptionApiResponseHandle = (editQuoteResponseJson: string) => {

    if (editQuoteResponseJson) {
      const updatedTablesData = this.state.tablesData.map(table => {
        const filteredData = table.data.filter((item: TableDataItem | undefined) => {
          return item !== undefined && !table.selected.includes(Number(item.id));
        });

        const subtotalValue = filteredData.reduce((acc: number, item: TableDataItem | undefined) => {
          if (item && typeof item.amount === 'string') {
            return acc + parseFloat(item.amount);
          }
          return acc;
        }, 0)
        return {
          ...table,
          data: filteredData,
          subtotalValue: subtotalValue,
          selected: []
        };
      });

      this.setState({ tablesData: updatedTablesData, deleteModalState: false }, () => {
        this.calculateAllDataTotal()
      });
    }

  }

  updateEditquoteApiResponseHandle = (editQuoteResponseJson: UpdateResponseInterface) => {
    if (editQuoteResponseJson.data) {
      if (editQuoteResponseJson.data && editQuoteResponseJson.data.quote_description) {
        const quoteDescriptions: any = editQuoteResponseJson.data.quote_description;
        const tablesDataItem: TableDescription[] = Object.keys(quoteDescriptions).map((categoryName: string) => {
          return {
            id: this.state.tablesData.length + 1,
            category_name: categoryName,
            content: "",
            data: quoteDescriptions[categoryName],
            pagination: {
              id: this.state.tablesData.length + 1,
              page: 1,
              rowsPerPage: 10
            },
            warningTableBox: false,
            subtotalValue: 0,
            selected: [],
            QuoteAddNewSegmentState: false,
            categoryBox: false,
            categoryErrorMessage: "",
            addItemBox: false
          };
        });

        this.setState({
          tablesData: tablesDataItem
        });

        this.setState((prevState) => ({
          creteReportData: {
            ...prevState.creteReportData,
            // quoteDetails: editQuoteResponseJson.data.attributes.quote[0],
          }
        }))
      }

      const message: Message = new Message(getName(MessageEnum.NavigationMessage))
      message.addData(
        getName(MessageEnum.NavigationTargetMessage),
        this.props.history.push('/report/create-report', {
          navigateId: 1,
          creteReportData: this.state.creteReportData,
          tablesData: this.state.tablesData,
          quoteDetails: editQuoteResponseJson.data.attributes.quote[0],
          editQuoteDataSaved:true,
          checkBox:this.props.location?.state.checkedBox
        })
      );
      message.addData(getName(MessageEnum.NavigationPropsMessage), this.props)
      const raiseMessage: Message = new Message(
        getName(MessageEnum.NavigationPayLoadMessage)
      );
      raiseMessage.addData(getName(MessageEnum.SessionResponseData), {
        data: editQuoteResponseJson.data.attributes,
      });
      message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
      this.send(message);
    }
  }

  deleteQuoteDetailApiResponseHandle = (editQuoteResponseJson: string) => {
    if (editQuoteResponseJson) {
      const updatedTablesData = this.state.tablesData.map((deleteData: any) => {
        const updatedData = deleteData.data.filter((item: TableDataItem) => item.id !== this.state.deleteId);
        const subtotal = updatedData.reduce((acc: number, item: TableDataItem) => acc + parseFloat(item.amount), 0);
        return {
          ...deleteData,
          data: updatedData,
          subtotalValue: subtotal
        };
      });
      this.setState({ tablesData: updatedTablesData }, () => {
        this.calculateAllDataTotal()
      });
    }
  }

  handleCategoryChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, columnId: number) => {
    const addCategoryValue = event.target.value;

    this.setState((prevState) => {
      const isDuplicate = prevState.tablesData.some(
        (categoryData: TableDescription) => categoryData.category_name.toLowerCase() === addCategoryValue.toLowerCase() && categoryData.id !== columnId
      );

      const updatedTablesData: TableDescription[] = prevState.tablesData.map((categoryData: TableDescription, index: number) => {
        if (categoryData.id === columnId) {

          const updatedData: TableDataItem[] = categoryData.data.map((item: TableDataItem | undefined) => {
            if (item) {
              return {
                ...item,
                category_name: addCategoryValue,
                categoryErrorMessage: isDuplicate ? "Segment/Category Name Already Exists" : "",

              };
            }
            return item;
          }).filter(Boolean) as TableDataItem[];
          return {
            ...categoryData,
            category_name: addCategoryValue,
            categoryErrorMessage: isDuplicate ? "Segment/Category Name Already Exists" : "",
            data: updatedData,
          };

        }
        return categoryData;
      });

      return {
        ...prevState,
        tablesData: updatedTablesData,
      };


    });

  }

  addNewSegmentHandler = () => {

    const newDescData: TableDescription = {
      id: this.state.tablesData.length + 1,
      category_name: "",
      content: "No items in this Segment/Category",
      data: [],
      pagination: {
        id: this.state.tablesData.length + 1,
        page: 1,
        rowsPerPage: 10
      },
      warningTableBox: false,
      selected: [],
      subtotalValue: 0,
      QuoteAddNewSegmentState: true,
      categoryBox: true,
      categoryErrorMessage: "",
      addItemBox: false,
    };
    const hasEmptyCategory = this.state.tablesData.every(segmentItem => segmentItem.category_name !== "");

    const updateTablesData = this.state.tablesData.map(segmentItem => {

      if (segmentItem.QuoteAddNewSegmentState && hasEmptyCategory === false && segmentItem.category_name=="") {
        return {
          ...segmentItem,
          categoryErrorMessage: "Segment/Category is required"
        }
      }
      else if (hasEmptyCategory === true) {
        return {
          ...segmentItem,
          categoryErrorMessage: "",
          categoryBox: true
        }
      }
      else {
        return {
          ...segmentItem,
          QuoteAddNewSegmentState: true,
          categoryBox: true
        }
      }
    })

    if (hasEmptyCategory === true) {
      this.setState({
        tablesData: [...this.state.tablesData, newDescData]
      })
    } else {
      this.setState({
        tablesData: updateTablesData
      })
    }
  }

  deleteCategoryModal = (columnId: number) => {
    this.setState({ deleteModalState: !this.state.deleteModalState, categoryColumnId: columnId })
  }

  deleteCategory = () => {
    const { categoryColumnId, tablesData } = this.state;
    let updatedTablesData;
    if (categoryColumnId !== 1) {
      updatedTablesData = tablesData.filter(tableItem => tableItem.id !== categoryColumnId);
      const moveIndex = 0;
    } else {
      updatedTablesData = tablesData.map(tableItem => {
        if (tableItem.id === 1) {
          return {
            ...tableItem,
            category_name:'',
            categoryErrorMessage:'',
            QuoteAddNewSegmentState: false,
          };
        }
        return tableItem;
      });

    }

    this.setState({ tablesData: updatedTablesData }, () => {
      this.calculateAllDataTotal();
    });
  };

  handleRowItemClick = (event: React.MouseEvent<unknown>, columnId: number, itemId: string) => {
    const { checked }: { checked: boolean } = event.target as HTMLInputElement;

    this.setState((prevState: any) => {
      const updatedTablesData = prevState.tablesData.map((table: any) => {
        if (table.id === columnId) {
          const updatedSelected = checked
            ? [...table.selected, itemId]
            : table.selected.filter((selectedId: string) => selectedId !== itemId);

          return {
            ...table,
            selected: updatedSelected
          };
        }
        return table;
      });

      return { tablesData: updatedTablesData };
    });
  };

  handleTaxfieldChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = event.target.checked;
    
    const updatedTablesData = this.state.tablesData?.map(table => {
      const dataCalAdd = table?.data
      .map((item: any) => Number(item?.price) * Number(item?.ord_quantity))
      .reduce((accValue, numValue) => accValue + numValue, 0);
      return {
      ...table,
      data: table.data.map((item: any) => ({
        ...item,
        tax: null,
        taxSwitch: isChecked
      })),
      subtotalValue: dataCalAdd
      }
    });


    this.setState({ tablesData: updatedTablesData },()=>{
      this.calculateAllDataTotal()
    });
  }

  handleSelectAllItemClick = (event: React.ChangeEvent<HTMLInputElement>, tableId: number) => {
    const { checked } = event.target;
    const { tablesData } = this.state;

    const updatedTablesData = tablesData.map((table) => {
      if (table.id === tableId) {
        return {
          ...table,
          selected: checked ? table.data.map((item: any) => item.id) : []
        };
      }
      return table;
    });

    this.setState({ tablesData: updatedTablesData });
  };

  subTotalPrice = (columnId: number) => {

    const columnData = this.state.tablesData.find(table => table.id === columnId);


    if (columnData) {
      const subtotal = columnData.data.reduce((acc: number, item: any) => acc + parseFloat(item?.amount || 0), 0);

      const updatedTablesData = this.state.tablesData.map(table => {
        if (table.id === columnId) {
          return {
            ...table,
            subtotalValue: subtotal
          };
        }
        return table;
      });

      this.setState({ tablesData: updatedTablesData }, () => {
        this.calculateAllDataTotal()
      });
    }
  }

  calculateAllDataTotal = () => {

    const total = this.state.tablesData.reduce((acc, table) => {
      return acc + (table.subtotalValue || 0)
    }, 0);

    const taxTotal = Number(this.totalTax());
    const sAndHCharges = Number(this.state.editQuoteDetail.s_and_h_charges || 0);
    const otherCharges = Number(this.state.editQuoteDetail.other_charges || 0);
    const discount = Number(this.state.editQuoteDetail.discount || 0);
    const totalValue = Number((taxTotal + total + sAndHCharges + otherCharges - discount).toFixed(2));
    this.setState({ totalValue: totalValue, calculateValue: total });
  }

  totalTax = () => {
    const total = this.state.calculateValue;
    const tax = Number(this.state.editQuoteDetail.tax_rate_amount || 0);
    const taxAmount = (total * tax) / 100;
    if (this.state.editQuoteDetail.tax !== taxAmount.toFixed(2)) {
      this.setState(prevState => ({
          editQuoteDetail: {
              ...prevState.editQuoteDetail,
              tax: taxAmount.toFixed(2)
          }
      }));
  }
    return taxAmount.toFixed(2)
  }

  onDragEnd = (result: any) => {
    if (!result.destination) return;
    const { source, destination } = result;

    const { tablesData } = this.state;
    const selectedItems: any[] = [];

    tablesData.forEach(table => {
      if (table.selected.length > 0) {
        const selected = table.data.filter((item: any) => table.selected.includes(item.id));
        selectedItems.push(...selected);
      }
    });

    if (source.droppableId !== destination.droppableId) {
      const sourceColumnIndex = parseInt(source.droppableId);
      const destColumnIndex = parseInt(destination.droppableId);

      const updatedTablesData = [...tablesData];

      const sourceColumn = { ...updatedTablesData[sourceColumnIndex], selected: [] };
      const destColumn = updatedTablesData[destColumnIndex];

      const sourceItems = [...sourceColumn.data];
      const destItems = [...destColumn.data];

      selectedItems.forEach(selectedItem => {
        const index = sourceItems.findIndex((item: any) => item.id === selectedItem.id);
        if (index !== -1) {
          sourceItems.splice(index, 1);
        }
      });

      destItems.splice(destination.index, 0, ...selectedItems);



      const updatedDestItems = destItems.map((item: any) => ({
        ...item,
        category_name: this.state.categoryValue
      }));

      updatedTablesData[sourceColumnIndex] = { ...sourceColumn, data: sourceItems };
      updatedTablesData[destColumnIndex] = { ...destColumn, data: updatedDestItems };


      this.setState({
        tablesData: updatedTablesData,
      }, () => {
        updatedTablesData.forEach(table => {
          const subtotal = table.data.reduce((acc: number, item: any) => acc + Number(item.amount), 0);
          table.subtotalValue = subtotal;
        });

        this.setState({ tablesData: updatedTablesData, categoryValue: this.state.categoryValue }, () => {
          this.calculateAllDataTotal()
        });
      });
    }
  }

  handlePageChange = (event: React.ChangeEvent<unknown>, page: number, columnId: number) => {
    const updatedTablesData = [...this.state.tablesData];
    updatedTablesData[columnId].pagination.page = page;
    this.setState({ tablesData: updatedTablesData });
  }


  isValidDate(dateString: string ):boolean {

    const regexYYYYMMDD = /^\d{4}-\d{2}-\d{2}$/;
    const regexMMDDYYYY = /^\d{2}-\d{2}-\d{4}$/;


    if (regexYYYYMMDD.test(dateString) || regexMMDDYYYY.test(dateString)) {
      return true;
    } else {
      return false;
    }

  }

  handleEditQuoteDetail = (field: string, floatValue: unknown) => {
    let updatedValue = floatValue;
    if ((field === "date" || field === "expiration_date") && typeof floatValue === 'string' ) {
      if (!this.isValidDate(floatValue)) {
        updatedValue = null
      }
    }
   
    this.setState({
      editQuoteDetail: {
        ...this.state.editQuoteDetail,
        [field]: updatedValue,
      }
     
    }, () => {
      this.calculateAllDataTotal()
    })

  }

  HandleEditQuoteDescription = (value: unknown, columnId: number, itemId: string, field: string) => {
    const { tablesData } = this.state;

    const valueEvent = (value as { target: { value: string } }).target ? (value as { target: { value: string } }).target.value : value;

    const updateItem = (item: TableDataItem | undefined) => {
      let subtotal = 0;
      if (!item || item.id !== itemId) {
        return item;
      }

      const updatedAmount = {
        ...item,
        [field]: valueEvent,
      };

      if (field === "amount") {
        return updatedAmount;
      }else {
        return calculateUpdatedAmount(updatedAmount, field);
      }

      
    };
    const calculateUpdatedAmount = (updatedAmount: TableDataItem, field: string) => {
        const priceBeforeTax = Number(updatedAmount.ord_quantity) * Number(updatedAmount.price);
        const taxAmount = (priceBeforeTax * Number(updatedAmount.tax)) / 100;
        updatedAmount.amount = (priceBeforeTax + taxAmount).toFixed(2);
      return updatedAmount;
    };

    const calculateSubtotal = (updatedItems: (TableDataItem | undefined)[]) => {
      return updatedItems.reduce((acc: number, item?: TableDataItem) => {
        if (item && field !== "amount") {
          acc = acc + parseFloat(item.amount);
        }
        return acc;
      }, 0);
    };

    const updatedData = tablesData.map((table: TableDescription) => {
      if (table.id !== columnId) {
        return table;
      }

      const updatedItems = table.data.map(updateItem);
      const subtotal = calculateSubtotal(updatedItems);

      return {
        ...table,
        data: updatedItems,
        subtotalValue: subtotal
      };
    });

    this.setState({ tablesData: updatedData }, () => {
      this.subTotalPrice(columnId);
      this.calculateAllDataTotal();
    });
  };

  editQuoteDetailApi = () => {
    const updateData = this.state.tablesData.some(segmentItem => segmentItem.category_name === "");
    const hasDuplicateCategory = this.state.tablesData
    .map(segmentItem => segmentItem.category_name.toLowerCase())
    .some((categoryName, index, array) => array.indexOf(categoryName) !== index);
    const QuoteAddNewSegment = this.state.tablesData.find(segmentItem => segmentItem.id === 1);
if((updateData && QuoteAddNewSegment?.QuoteAddNewSegmentState) || (this.props.location?.state?.quotescan && updateData && QuoteAddNewSegment?.category_name) || hasDuplicateCategory ){
  const updateTablesData = this.state.tablesData.map(segmentItem => {

    if (segmentItem.category_name=="" && segmentItem.QuoteAddNewSegmentState) {
      return {
        ...segmentItem,
        categoryErrorMessage: "Segment/Category is required"
      }
    } else if (this.state.tablesData.filter(item => item.category_name.toLowerCase() === segmentItem.category_name.toLowerCase()).length > 1) {
      return {
        ...segmentItem,
        categoryErrorMessage: "Segment/Category Name Already Exists"
      };
    }else{
        return {
          ...segmentItem,
          categoryErrorMessage: ""
        }
   }
  })

    this.setState({
      tablesData: updateTablesData
    })
  
}else{
    const data = this.state.tablesData.map(categoryData => {
      return categoryData.data.map(item => {
        return {
          ...item,
          category_subtotal: categoryData.subtotalValue
        };
      });
    })


    const header = {
      "Content-Type": configJSON.categoryApiContentType,
      "token": this.getToken()
    };
    const date = moment(this.state.editQuoteDetail.date).format("YYYY-MM-DD")
    const expirationDate = moment(this.state.editQuoteDetail.expiration_date).format("YYYY-MM-DD")
    const subtotalValue = this.state.tablesData[0].data.reduce((sumvalue:number, item:any) => sumvalue + parseFloat(item.amount),0)
    const httpBody = {
      quotes: {
        quote_id: this.state.editQuoteDetail.quote_id,
        date: date,
        supplier_address: this.state.editQuoteDetail.supplier_address,
        supplier_salesperson: this.state.editQuoteDetail.supplier_salesperson,
        supplier_contact: this.state.editQuoteDetail.supplier_contact,
        site_name: this.state.editQuoteDetail.site_name,
        project: this.state.editQuoteDetail.project,
        site_address: this.state.editQuoteDetail.site_address,
        site_contactperson: this.state.editQuoteDetail.site_contactperson,
        site_contact: this.state.editQuoteDetail.site_contact,
        s_and_h_charges: this.state.editQuoteDetail.s_and_h_charges,
        tax: this.state.editQuoteDetail.tax,
        other_charges: this.state.editQuoteDetail.other_charges,
        tax_rate_amount: this.state.editQuoteDetail.tax_rate_amount,
        discount: this.state.editQuoteDetail.discount,
        expiration_date: expirationDate,
        total_price: this.state.totalValue,
        gross_subtotal: this.state.calculateValue,
        subtotal:subtotalValue
      },
      quote_descriptions: data.flat()
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.updateQuoteDetailApiId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.editQuoteDetailAPIEndPoint}/${this.state.editQuoteDetail.report_id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.editQuoteDetailAPIMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  }

  handleDate = () => {
    this.setState({ typeOfFocused: "date" })
  }

  deleteOpenModal = () => {
    this.setState({ deleteModalState: !this.state.deleteModalState })
  }

  deleteOpenTableItemModal = (itemId: string) => {
    this.setState({ deleteModalState: !this.state.deleteModalState, deleteId: itemId })
  }

  deleteCloseModal = () => {
    this.setState({ deleteModalState: false,categoryColumnId:1 })
  }

  deleteHandler = () => {
    const header = {
      "Content-Type": configJSON.categoryApiContentType,
      "token": this.getToken()
    };

    const deleteDocumentrequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.deleteQuoteDetailApiId = deleteDocumentrequestMessage.messageId;

    deleteDocumentrequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.quoteDeleteApiEndPoint}/${this.state.deleteId}`
    );

    deleteDocumentrequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpDeleteType
    );

    deleteDocumentrequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    )

    runEngine.sendMessage(deleteDocumentrequestMessage.id, deleteDocumentrequestMessage);

    return true;
  }

  DeleteItemsHandler = () => {
    const selectedIds = this.state.tablesData.map(idVal => idVal.selected)
    const selectedIdsss = this.state.tablesData.map(idVal => idVal.data)
    const getDeletId = (this.state.categoryColumnId === 1 || this.state.categoryColumnId === 0 )  ?selectedIds : selectedIdsss[this.state?.categoryColumnId-1].map((element:any) => element.id)

    const httpBody = {
      ids: getDeletId
    };

    const header = {
      "Content-Type": configJSON.categoryApiContentType,
      "token": this.getToken()
    };

    const deleteDocumentrequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.deleteDescriptionApiId = deleteDocumentrequestMessage.messageId;

    deleteDocumentrequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.deleteSegmentAPI
    );

    deleteDocumentrequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpDeleteType
    );

    deleteDocumentrequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    )
    deleteDocumentrequestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    )

    runEngine.sendMessage(deleteDocumentrequestMessage.id, deleteDocumentrequestMessage);

    return true;
  }

  addNewItemRow = (columnId: number) => {
    const categorName =this.state.tablesData?.find(value => {return value?.id == columnId})?.category_name 
    this.setState({
      columnIdState: columnId
    })
    const reportItem = this.state.tablesData[0].data[0]?.report_quote_id

    const header = {
      "Content-Type": configJSON.categoryApiContentType,
      "token": this.getToken()
    };

    let data;

    if (this.props.location.state.checkedBox !== true) {
      data = {
        report_id: this.state.editQuoteDetail.report_id,
        report_quote_id: reportItem,
        description: "",
        ord_quantity: "",
        unit: "",
        price: "",
        amount: "",
        tax: "",
        category_name: categorName
      }

    } else {
      data = {
        report_id: this.state.editQuoteDetail.report_id,
        report_quote_id: reportItem,
        rate_sheet_cat: "",
        rate_sheet_min: "",
        rate_sheet_daily: "",
        rate_sheet_weekly: "",
        rate_sheet_monthly: "",
        tax: "",
        category_name: categorName
      }

    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.addNewItemApiId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.addNewItemAPI
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(data)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }

  handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ selectedRadioValue: event.target.value });
  };

  cancelEditHandler = () => {
   this.props.location?.state?.quotescan ? this.props.history.goBack():
    this.props.history.push('/report/create-report', { navigateId: 1, creteReportData: this.state.creteReportData,cancelData:this.state.cancelData })
  }


  // Customizable Area End
}