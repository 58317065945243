// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { IBlock } from "../../../framework/src/IBlock";
import { runEngine } from "../../../framework/src/RunEngine";
import { getStorageData,setStorageData } from "../../../framework/src/Utilities";
import { Message } from "../../../framework/src/Message";
// Customizable Area End

export const configJSON = require("./config");


interface InvoiceDetails {
  id: number;
  created_at: string;
  updated_at: string;
  quote_id: string;
  date: string;
  vendor: string;
  project: string;
  total_price: string;
  status: string;
  approval_status: string | null;
  invoice_supplier_address: string;
  invoice_supplier_contact: string;
  invoice_salesperson_name: string;
  invoice_site_address: string;
  invoice_site_person: string;
  invoice_site_contact: string;
  invoice_site_name: string;
  invoice_s_and_h_charges: string;
  invoice_other_charges: string;
  invoice_tax: string;
  invoice_subtotal: string;
  invoice_shipped_date: string | null;
  invoice_terms: string;
  discount: string;
  invoice_tax_percent: string;
  report_id: number;
  invoice_description: string | null;
  url_id: string;
  gross_subtotal: string | null;
  pdf_page_count: number;
  invoice_rental_subtotal: string;
  user_id: number;
}



interface columnInvoiceData {
  id: string;
  label: string;
}

interface invoiceDetailInterface{
  invoice_subtotal: string,
  invoice_shipped_date: string,
  date: string,
  quote_id: string,
  dateVal: string,
  expiryDateVal: string,
  invoice_site_name: string,
  invoice_salesperson_name: string,
  invoice_supplier_contact: string,
  invoice_supplier_address: string,
  project: string,
  invoice_site_address: string,
  invoice_site_person: string,
  invoice_site_contact: string,
  invoice_terms: string
}


interface ResultInvoiceDetails {
  message: string;
  pdf_page_count: number;
  url: string;
  invoice: Invoice;
  invoice_description: any[];
  invoice_rental_description: any[];
}

interface InvoiceTabObject{
  name:string
}

interface Invoice {
  data: InvoiceData;
}

interface InvoiceData {
  id: string;
  type: string;
  attributes: InvoiceDetails;
}
export interface Props {
  // Customizable Area Start
  classes: any;
  navigation: any;
  id: string;
  handleSaveData: (data: any) => void;
  location: any;
  history: any;
  // Customizable Area End
}

interface InvoiceItem {
  id: number;
  report_invoice_id: number;
  description: string;
  ord_quantity: number;
  order_unit: string;
  price: string;
  amount: string;
  tax: string | null;
  equipment: string | null;
  minimum: string | null;
  day: string | null;
  week: string | null;
  month: string | null;
  invoice_category_name: string | null;
  created_at: string;
  updated_at: string;
}

interface InvoiceDescriptionGroup {
  no_category_created: InvoiceItem[];
}

interface InvoiceDescriptionGroupArray {
  invoice_description_group: InvoiceDescriptionGroup[];
}


interface Segment{
  segment:string,
  hasTax:boolean,
  segmentTotalAmount:number
}

type SegmentsArrType = Segment[][]


interface InvoiceDataNew {
  id: number;
  report_invoice_id: number;
  description: string;
  ord_quantity: number;
  order_unit: string;
  price: string;
  amount: string;
  tax: string | null;
  equipment: string | null;
  minimum: string | null;
  day: string | null;
  week: string | null;
  month: string | null;
  invoice_category_name: string;
  created_at: string;
  updated_at: string;
}

interface Pagination {
  id: number;
  page: number;
  rowsPerPage: number;
}

interface InvoiceTable {
  id: number;
  invoice_category_name: string;
  content: string;
  data: InvoiceDataNew[];
  pagination: Pagination;
  warningTableBox: boolean;
  selected: any[];
  subtotalValue: number;
  addNewSegmentState: boolean;
  categoryBox: boolean;
  categoryErrorMessage: string;
}

type InvoicesTablesDataType = InvoiceTable[][];



interface S {
  // Customizable Area Start
  invoiceComparisonData: any[],
  invoiceDetailData: invoiceDetailInterface[],
  quotePage: number,
  quoteRowsPerPage: number,
  invoiceColumn: columnInvoiceData[],
  lastReportname:string,
  reportof:{id:number,projectName:string},
  invoiceViewTabIndex:number,
  invoiceSingleDetailData: invoiceDetailInterface[],
  invoiceDescGroup:InvoiceDescriptionGroupArray[],
  invoiceTaxColumn:any,
  invoicePageRequired:number,
  paginationArrScanResult:any,
  paginationKeyScan:string,
  invoiceDetails:InvoiceDetails[],
  parseItemsdata:any,
  updatedTableData:any,
  newInvoiceDetails:ResultInvoiceDetails[],
  newInvoiceTab:InvoiceTabObject[],
  allInvoicesTablesData:InvoicesTablesDataType,
  segmentsArr:SegmentsArrType,
  responseReportsData:any,
  responseNeeded:boolean,
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  // Customizable Area End
}

export default class InvoiceScanResultController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  viewReportInvoiceScanCallId = "";
  // Customizable Area End

  constructor(props: Props) {

    super(props);
    this.state = {
      // Customizable Area Start
      invoiceSingleDetailData:[],
      invoiceViewTabIndex:0,
      reportof:{id:0,projectName:""},
      lastReportname:'',
      invoiceComparisonData: [],
      invoiceDetailData: [],
      quotePage: 1,
      quoteRowsPerPage: 10,
      invoiceColumn: [
        {
          id: 'Sr.no', label: 'Sr. No',
        },
        {
          id: 'description', label: 'Description',
        },
        {
          id: 'OrderQty.', label: 'Order Qty.',
        },
        {
          id: 'unit', label: 'Unit',
        },
        {
          id: 'unitPrice', label: 'Unit Price',
        },
        {
          id: 'Ext. Price', label: 'Ext. Price'
        }
      ],
      invoiceDescGroup:[],
      invoiceTaxColumn: [
        {
          id: 'Sr.no', label: 'Sr. No',
        },
        {
          id: 'description', label: 'Description',
        },
        {
          id: 'OrderQty.', label: 'Order Qty.',
        },
        {
          id: 'unit', label: 'Unit',
        },
        {
          id: 'unitPrice', label: 'Unit Price',
        },
        {
          id: 'tax', label: 'Tax (%)',
        },
        {
          id: 'Ext. Price', label: 'Ext. Price'
        }
      ],
      invoicePageRequired:1,
      paginationArrScanResult:[],
      paginationKeyScan:"invoice_1_pagination_1",
      invoiceDetails:[],
      parseItemsdata:{},
      updatedTableData:[],
      newInvoiceDetails:[],
      newInvoiceTab:[],
      allInvoicesTablesData:[],
      segmentsArr:[],
      responseReportsData:{},
      responseNeeded:false,
      // Customizable Area End
    };

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.NavigationMessage),
      getName(MessageEnum.NavigationPropsMessage),
    ];

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async componentDidMount() {

    const itemViewDataReq= await getStorageData("dataViewreport");
    const parseItemViewData = JSON.parse(itemViewDataReq);

    this.viewReportDetailsInvoiceScan(parseItemViewData.id)

    this.updateItemsData()

  }

  updateItemsData = async()=>{
    
     const itemGetData= await getStorageData("itemsData");
    const parseItemsdata = JSON.parse(itemGetData);
    const reportofGet= await getStorageData("reportof");
    const reportofName = JSON.parse(reportofGet);
    const reportName = parseItemsdata?.reports_details?.data.attributes.report_name

    const invoiceDescGroup = parseItemsdata?.invoice_description_group

    const invoiceItemTable = [parseItemsdata].map((tablevalue) => tablevalue.invoice_description);
    const invoiceItemGet = invoiceItemTable[0][0].data
    const invoiceData = [parseItemsdata]?.find((invoiceitem) => invoiceitem?.invoices_details).invoices_details;
    const invoiceDetails = parseItemsdata?.invoices_details
    const segmentsArr = this.segmentsArrayScanResult(invoiceDescGroup) 

    this.setState({
      invoiceComparisonData:invoiceItemGet,
      invoiceSingleDetailData:invoiceData,
      invoiceDetailData: [invoiceData[0]],
      lastReportname:reportName,
      reportof:reportofName,
      invoiceDescGroup,
      invoiceDetails,
      parseItemsdata,
      segmentsArr
    })

    this.initialFunctions(invoiceDetails,invoiceDescGroup)


    setTimeout(()=>{
      this.createPaginationArrScanResult(this.state.invoiceDescGroup)
    },1000)

  }

  async componentDidUpdate(prevProps: any, prevState: any) {

   if(prevState.responseReportsData !== this.state.responseReportsData && this.state.responseNeeded){
  this.setState({
    responseNeeded:false
  })
  this.updateItemsData()
}

  
    
   
}

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {

      const invoiceScanApiCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const invoiceScanResponseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (invoiceScanApiCallId === this.viewReportInvoiceScanCallId) {
          await setStorageData("itemsData",JSON.stringify(invoiceScanResponseJson))
          this.setState({
            responseReportsData:invoiceScanResponseJson,
            responseNeeded:true
          })
      }


    }
  }

  initialFunctions = (invoiceDetails:any,invoiceDescGroup:any)=>{
    this.createTablesData(invoiceDescGroup)
    this.createEditQuoteDetails(invoiceDetails,invoiceDescGroup)
    this.createNewInvoiceDetails(invoiceDetails)
    this.createNewInvoiceTab(invoiceDetails)
  }

  handleChangeinvoicePage = (event: unknown, newPage: number,paginationKeyScan:any) => {
    const updatedPaginationArr = this.state.paginationArrScanResult.map((paginationObj:any) => {
      if (paginationObj.hasOwnProperty(paginationKeyScan)) {
        return {
          ...paginationObj,
          [paginationKeyScan]: newPage
        };
      }
      return paginationObj;
    });


    this.setState({ invoicePageRequired: newPage,paginationArrScanResult: updatedPaginationArr,paginationKeyScan})
  };

  goBackInvoicehandler = () => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage),'ViewReportDetails');
    message.addData(getName(MessageEnum.NavigationPropsMessage),this.props);
    this.send(message);
  }

  // Customizable Area Start
  getNavigationReport = async() => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage),'ProjectS');
    message.addData(getName(MessageEnum.NavigationPropsMessage),this.props);
    this.send(message);
  }

  getNavigationViewreport = async() =>{
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage),'Viewreport');
    message.addData(getName(MessageEnum.NavigationPropsMessage),this.props);
    this.send(message);
  }

  handleViewinvoceTabChange = async(event: React.ChangeEvent<{}>, newValue: number) => {
    this.setState({ invoiceViewTabIndex: newValue })
    const singleInvoicedetail = this.state.invoiceSingleDetailData && this.state.invoiceSingleDetailData[newValue]
    const itemGetData= await getStorageData("itemsData");
    const parseItemsdata = JSON.parse(itemGetData);
    const invoiceGetItemTable = [parseItemsdata].map((tablevalue) => tablevalue.invoice_description);
    const invoiceGetItemGet = invoiceGetItemTable[0][newValue].data
    this.setState({invoiceDetailData:[singleInvoicedetail],invoiceComparisonData:invoiceGetItemGet})
  }

  segmentsArrayScanResult(arr: any[]) {
    return arr.map((item1: { [x: string]: any[]; }) => {
      const segments = Object.keys(item1);
      return segments.map((segment1) => {
        const hasTax1 = item1[segment1].some((entry: { tax: any; }) => entry.tax > 0);
        const segmentTotalAmount1 = item1[segment1].reduce((total, entry) => {
          return total + parseFloat(entry.amount || 0); 
        }, 0);
        return {
          segment: segment1,
          hasTax: hasTax1,
          segmentTotalAmount:segmentTotalAmount1,
        };
      });
    });
  }

  handleChangePageInvoiceScanWrapper = (paginationKey:any) => {
    return (event: unknown, newPage: number) => {
      this.handleChangeinvoicePage(event, newPage, paginationKey);
    };
  };

  createPaginationArrScanResult = (invoiceDescriptionGroup:any)=>{
    const paginationArrScanResult = invoiceDescriptionGroup.map((group_scan:any,groupIdx:number) => {
      const paginationObj:any = {};
      let paginationCounter = 1;
  
      for (const key in group_scan) {
        if (group_scan[key] && Array.isArray(group_scan[key])) {
          paginationObj[`invoice_${groupIdx+1}_pagination_${paginationCounter}`] = 1;
          paginationCounter++;
        }
      }
  
      return paginationObj;
    });

    this.setState({
      paginationArrScanResult
    })
  }

   createTableStructure = (categoryName:string, data:any, tableId:number,groupIndex:number)=>{
    const {segmentsArr} = this.state;
    return {
      id: tableId+1,
      invoice_category_name: categoryName,
      content: "",
      data: data,
      pagination: {
      id: tableId,
      page: 1,
      rowsPerPage: 10
      },
      warningTableBox: false,
      selected: [],
      subtotalValue: segmentsArr?.[groupIndex]?.[tableId]?.segmentTotalAmount,
      addNewSegmentState: false,
      categoryBox: false,
      categoryErrorMessage: ""
    }
  }


  createTablesData = (invoiceDescGroup:any)=>{

    let tablesData:any = [];

    invoiceDescGroup.forEach((group:any,groupIndex:number)=>{
      let tables:any = [];

      Object.keys(group).forEach((categoryName:string,categoryIndex:number)=>{
        const tableId = categoryIndex
        const data = group[categoryName]

        tables.push(this.createTableStructure(categoryName,data,tableId,groupIndex))
      })

      tablesData.push(tables)
    })
    
    this.setState({
      allInvoicesTablesData:tablesData
    })
    
  }


  createEditQuoteDetails = (invoiceDetailsArr: any, invoiceDescGroup: any) => {
    const updatedTableData = invoiceDetailsArr.map((invoice: any, index: number) => {
      const invoiceDescriptionGroup = invoiceDescGroup[index];
  
      let invoiceDescription: any[] = [];
      let addNewSegmentState = false;
      let taxSwitch = false
  
      Object.keys(invoiceDescriptionGroup || {}).forEach((category,indexCategory) => {
        taxSwitch = this.state.segmentsArr[index]?.[indexCategory]?.hasTax
        if (category !== "" && category !== "no_category_created") {
          addNewSegmentState = true
          const updatedCategoryArray = invoiceDescriptionGroup[category].map((item:any)=>({
            ...item,
            subtotalValue:this.state.segmentsArr[index]?.[indexCategory]?.segmentTotalAmount
          }))
          invoiceDescription.push({
            [category]: updatedCategoryArray,
          });
        } else {
          addNewSegmentState = false
          invoiceDescriptionGroup[category].forEach((item: any) => {
            invoiceDescription.push({...item,subtotalValue:this.state.segmentsArr[index]?.[indexCategory]?.segmentTotalAmount});
          });
        }
      });
  
      return {
        tablesData: [...(this.state.allInvoicesTablesData[index] || [])],
        editQuoteDetail: { ...invoice },
        totalValue: invoice.total_price,
        calculateValue: invoice.invoice_subtotal,
        taxSwitch,
        addNewSegmentState,
        categoryBox: false,
        invoice_description: invoiceDescription,
      };
    });
  
    this.setState({
      updatedTableData,
    });
  };
  

  createNewInvoiceDetails = (invoiceDetails:InvoiceDetails[])=>{
    const newInvoiceDetails = invoiceDetails.map((invoice:InvoiceDetails)=>({
      message: "",
      pdf_page_count: invoice.pdf_page_count,
      url: "",
      invoice: {
        data: {
          id: String(invoice.id),
          type: "report_invoice",
          attributes: {
            id: invoice.id,
            created_at: invoice.created_at,
            updated_at: invoice.updated_at,
            quote_id: invoice.quote_id,
            date: invoice.date,
            vendor: invoice.vendor,
            project: invoice.project,
            total_price: invoice.total_price,
            status: invoice.status,
            approval_status: invoice.approval_status,
            invoice_supplier_address: invoice.invoice_supplier_address,
            invoice_supplier_contact: invoice.invoice_supplier_contact,
            invoice_salesperson_name: invoice.invoice_salesperson_name,
            invoice_site_address: invoice.invoice_site_address,
            invoice_site_person: invoice.invoice_site_person,
            invoice_site_contact: invoice.invoice_site_contact,
            invoice_site_name: invoice.invoice_site_name,
            invoice_s_and_h_charges: invoice.invoice_s_and_h_charges,
            invoice_other_charges: invoice.invoice_other_charges,
            invoice_tax: invoice.invoice_tax,
            invoice_subtotal: invoice.invoice_subtotal,
            invoice_shipped_date: invoice.invoice_shipped_date,
            invoice_terms: invoice.invoice_terms,
            discount: invoice.discount,
            invoice_tax_percent: invoice.invoice_tax_percent,
            report_id: invoice.report_id,
            invoice_description: invoice.invoice_description,
            url_id: invoice.url_id,
            gross_subtotal: invoice.gross_subtotal,
            pdf_page_count: invoice.pdf_page_count,
            invoice_rental_subtotal: invoice.invoice_rental_subtotal,
            user_id: invoice.user_id
          }
        }
      },
      invoice_description: [],
      invoice_rental_description: []
    }))

    this.setState({
      newInvoiceDetails
    })
  }


  createNewInvoiceTab = (invoiceDetails:InvoiceDetails[])=>{
    const newInvoiceTab = invoiceDetails.map((invoice)=>({
      name:invoice.project
    }))

    this.setState({
      newInvoiceTab
    })
  }


  getTokenInvoiceScan = () => {
    return document.cookie.split("; ").find((row) => row.startsWith("Token="))?.split("=")[1]
  }


  viewReportDetailsInvoiceScan = (id: number) => {

    const header = {
      token: this.getTokenInvoiceScan()
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.viewReportInvoiceScanCallId = requestMessage.messageId

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.reportDetailsApiInvoiceScanResult}${id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetType 
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };


  // Customizable Area End
}
