// Customizable Area Start
import React, { StyleHTMLAttributes } from "react";
import {
    Typography,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Card,
    Box,
    Grid,
    Modal,
    Tabs,
    Tab,
    FormControl,
    Select,
    MenuItem,
    OutlinedInput,
    IconButton,
    Button,
    Link,
    Breadcrumbs

} from "@material-ui/core";
// import NavigateNextIcon from '@mui/icons-material/NavigateNextIcon';
import Header from "../../landingpage/src/Header.web";
import QuoteInvoiceController, { Props, configJSON } from "./QuoteInvoiceController.web";
import { StyleProp } from "react-native";
import { createTheme, withStyles } from "@material-ui/core/styles";
import { DocumentsTabPanel } from "../../OcropticalCharacterRecognition/src/Documents.web";
import { filterIcon } from "../../OcropticalCharacterRecognition/src/assets";
import moment from "moment";
import { TabPanelProps } from "./ReportGenerationController.web";
import { classNames } from "react-select/src/utils";
import { borderRadius } from "react-select/src/theme";


export const theme = createTheme({});



export class QuoteInvoice extends QuoteInvoiceController {

    
    constructor(props: Props) {
        super(props);
        // Customizable Area Start

        // Customizable Area End


    }

   
 

    render() {
        const { classes, navigation, handleSaveData } = this.props
      
        return (
            <>

                <Header
                    data-testid="ReportsQuoteHeaderId"
                    handleSaveData={this.props.handleSaveData}
                    navigation={this.props.navigation}
                    history={this.props.history}
                />
                <Box className={classes.breadcrumbStyle}>
                    <Breadcrumbs
                        separator={">"}
                        aria-label="breadcrumb"

                    >
                        <Link color="inherit" href="/">
                            Project
                        </Link>

                        <Typography style={{ color: "orange" }}>Report Details </Typography>
                    </Breadcrumbs>
                </Box>

                <Card>
                    <div className={classes.mainwrapper}>
                        <div className={classes.outterDiv}>
                            <div className={classes.innerDiv}>
                                <p className={classes.textStyle4}>Total discrepancy</p>
                                <p className={classes.textStyle1}>$ 0</p>
                                <p className={classes.textStyle2}> You are good!</p>
                            </div>

                        </div>
                        <Box className={classes.totalInvoiceQuoteWrapper}>
                        <div className={classes.totalQuoteBox}>
                                <div className={classes.innerTotalQuoteBox}>
                                    <div className={classes.QuoteSmallBox}>

                                    </div>
                                    <div>
                                        <p className={classes.textStyle3}>Total quote amount</p>
                                        <p className={classes.textStyle1}>$ 8,675.08</p>

                                    </div>
                                </div>
                            </div>
                            <div className={classes.totalInvoiceBox}>
                                <div className={classes.innerTotalInvoiceBox}>
                                    <div className={classes.invoiceSmallBox}>

                                    </div>
                                    <div >
                                        <p className={classes.textStyle3}>Total Invoice amount</p>
                                        <p className={classes.textStyle1}>$ 8,888.06</p>
                                    </div>
                                </div>
                            </div>
                           
                        </Box>

                    </div>
                </Card>

                <Card className={classes.containerBox}>

                    <Box className={classes.innerContainerBox}>
                        <Typography variant="h6" className={classes.previewTitle}>Quote</Typography>
                        <Typography variant="h6" className={classes.previewTitle}>Invoice</Typography>
                    </Box>
                    <Box className={classes.innerContainerBox}>
                        <Box className={classes.documentsBox}>
                            <Tabs
                                value={this.state.value}
                                onChange={this.handleChange}
                                className={classes.documentsInnerBox1}
                                data-testid="tabChangeid"
                            >

                                {
                                    this.state.quotesTypeTabs.map((item: any) => {
                                        return (
                                            <Tab
                                                label={
                                                    <>
                                                        <Box className={classes.documentscurveBox}>
                                                            <Box className={classes.documentscurveInnerBox}></Box>
                                                        </Box>
                                                        <Typography variant="subtitle2">{item}</Typography>
                                                        <Box className={classes.documentscurveRightBox}>
                                                            <Box className={classes.curveRightdocumentsInnerBox}></Box>
                                                        </Box>
                                                    </>
                                                }
                                                {...documentOcra11yProps(0)}
                                                className={classes.documentsTitletBox}
                                            />
                                        )
                                    })
                                }

                            </Tabs>
                            <Box className={classes.buttonBox1}>
                                <button style={boxStyle.buttonStyle1}>
                                    <img src="https://static.thenounproject.com/png/4899145-200.png" className={classes.imgStyle1} height={18} width={17} alt="" />

                                    Reupload quote
                                </button>

                            </Box>
                            <Box className={classes.previewQuoteOutterBox}>
                                <div className={classes.emptyDiv1}></div>
                                <iframe onLoad={this.onDocumentLoadSuccess} src={`${this.state.quoteComparisonData.quote_pdf}#page=${this.state.pageNumberQuote}`} className={classes.previewQuoteInnerBox}></iframe>
                                <Box className={classes.pageBox}>

                                    <p>
                                        Page {this.state.pageNumberQuote} of {this.state.numPagesQuote}
                                    </p>

                                    <button onClick={this.handlePrevPageQuote}  data-testid={"QuotePreviousBtnId"} style={{background:"none"}} >
                                        {'<'}
                                    </button>
                                    <p style={boxStyle.pageNumber}>{this.state.pageNumberQuote}</p>
                                    <button onClick={this.handleNextPageQuote} data-testid={"QuoteNextBtnId"} style={{background:"none"}}>
                                           {'>'}
                                    </button>
                                </Box>


                            </Box>
                            <button style={boxStyle.buttonStyle} onClick={this.handleGenerateQuote}>
                                <img src="https://www.svgrepo.com/show/103061/eye.svg" className={classes.imgStyle}  height={18} width={15} alt="" />

                                View Scan Results
                            </button>


                        </Box>
                        <Box className={classes.documentsBox}>

                            <Tabs
                                value={this.state.value}
                                onChange={this.handleChange}
                                className={classes.documentsInnerBox2}
                                data-testid="tabChangeid"
                            >

                                {
                                    this.state.invoiceTypeTabs.map((item: any) => {
                                        return (
                                            <Tab
                                                label={
                                                    <>
                                                        <Box className={classes.documentscurveBox}>
                                                            <Box className={classes.documentscurveInnerBox}></Box>
                                                        </Box>
                                                        <Typography variant="subtitle2">{item}</Typography>
                                                        <Box className={classes.documentscurveRightBox}>
                                                            <Box className={classes.curveRightdocumentsInnerBox}></Box>
                                                        </Box>
                                                    </>
                                                }
                                                {...documentOcra11yProps(0)}
                                                className={classes.documentsTitletBox}
                                            />
                                        )
                                    })
                                }

                            </Tabs>
                            <Box className={classes.buttonBox}>
                                <button style={boxStyle.buttonStyle1}>
                                    <img src="https://static.thenounproject.com/png/4899145-200.png" className={classes.imgStyle1} height={18} width={17} alt="" />

                                    Reupload invoice
                                </button>

                            </Box>

                            <Box className={classes.previewInvoiceOutterBox}>
                                <div className={classes.emptyDiv}></div>
                                <iframe onLoad={this.loadSuccess} src={`${this.state.quoteComparisonData.invoice_pdf}#page=${this.state.pageNumberInvoice}`} className={classes.previewInvoiceInnerBox}></iframe>
                                <Box className={classes.pageBox}>

                                    <p>
                                        Page {this.state.pageNumberInvoice} of {this.state.numPagesInvoice}
                                    </p>

                                    <button onClick={this.handlePrevPageInvoice} data-testid={"InvoicePreviousBtnId"} style={{background:"none"}} >
                                        {'<'}
                                    </button>
                                    <p style={boxStyle.pageNumber}>{this.state.pageNumberInvoice}</p>
                                    <button onClick={this.handleNextPageInvoice} data-testid={"InvoiceNextBtnId"} style={{background:"none"}} >
                                        {'>'}
                                    </button>
                                </Box>

                            </Box>
                            <Box className={classes.buttonStyleWrapper}>
                                <button style={boxStyle.buttonStyle} data-testid={"viewQuoteScanResultId"} onClick={this.handleGenerateInvoice}>
                                    <img src="https://www.svgrepo.com/show/103061/eye.svg"  className={classes.imgStyle} height={18} width={15} alt="" />

                                    View Scan Results
                                </button>
                                <button style={boxStyle.buttonStyle}>
                                    <img src="https://assets.dryicons.com/uploads/icon/svg/6234/0a00ab7d-2cf8-432f-8b4e-54ffd56015b2.svg" className={classes.imgStyle} height={18} width={15} alt="" />
                                    Upload Another Invoice
                                </button>
                            </Box>


                        </Box>
                    </Box>

                    <Box>

                    </Box>

                </Card>


            </>
        );
    }
}

export const DocumentsOcrTabPanel = (props: TabPanelProps) => {

    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
            className="topBorderTabBox"
        >
            {value === index && (
                <Box p={1}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

export const documentsa11yProps = (index: number) => {
    return {
        id: `vertical-tab-${index}`,
        'aria-controls': `vertical-tabpanel-${index}`,
    };
}

export const documentOcra11yProps = (indextab: any) => {
    return {
        id: `simple-tab-${indextab}`,
        'aria-controls': `simple-tabpanel-${indextab}`,
    };
}





export const webStyle: any = (theme: any) => ({
    emptyDiv:{
        height: "30px",
        position: "absolute",
        background: "white",
        width: "91%",
        borderTopLeftRadius: "8px",
        borderTopRightRadius: "8px",
        margin: "2px",
    },
    emptyDiv1:{
        height: "30px",
        position: "absolute",
        background: "white",
        width: "90%",
        borderTopLeftRadius: "8px",
        borderTopRightRadius: "8px",
        margin: "2px",
    },
    breadcrumbStyle: {

        marginBottom: "25px"
    },
    pageBox: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        padding: '10px',
    },
    quoteTables: {
        background: '#fff',
        margin: '10px',
        boxShadow: '0 0 0 5px #eee'
    },
    quoteTitle: {
        margin: '10px',
        color: '#000',
        fontWeight: 600
    },
    mainwrapper: {
        display: "flex",
        flexDirection: "row",

    },
    outterDiv: {
        border: '15px solid #03037c',
        height: "200px",
        width: "200px",
        borderRadius: "50%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        margin: "20px 60px"


    },
    innerDiv: {
        border: '15px solid lightgray',
        height: "172px",
        width: "200px",
        borderRadius: "50%",
        boxSizing: "border-box",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        padding: "4px"
    },
    textStyle1: {
        fontSize: "15px",
        fontWeight: 'bold'
    },
    textStyle3: {
        fontSize: "14px",
        fontWeight: "450",
        color: "black"
    },
    textStyle2: {
        color: "green",
        fontSize: "12px",
        fontWeight: "500"
    },
    textStyle4: {
        fontWeight: "bold",
        fontSize: "12px"
    },
    totalInvoiceBox: {
        border: "1px solid gray",
        borderRadius: "5px",
        width: "240px",
        height: "74px",
        padding: "10px"
    },
    innerTotalInvoiceBox: {
        display: "flex", flexDirection: "row", gap: "8px"
    },
    totalQuoteBox: {
        border: "1px solid gray",
        borderRadius: "5px",
        width: "240px",
        height: "74px",
        padding: "10px"
    },
    innerTotalQuoteBox: {
        display: "flex", flexDirection: "row", gap: "8px"
    },
    invoiceSmallBox: {
        width: "20px",
        height: "20px",
        background: "#d5d5d5",
        borderRadius: "4px"
    },
    QuoteSmallBox: {
        width: "20px",
        height: "20px",
        background: "#090959",
        borderRadius: "4px"


    },

    containerBox: {
        display: "flex",
        flexDirection: "column",
        gap: "20px",
        marginTop: "25px",
        padding: "30px 25px"
    },
    quotePreviewCard: {
        height: "400px",
        width: "50%",
        borderRadius: "5px",
        padding: "10px"
    },
    invoicePreviewCard: {
        height: "400px",
        width: "50%",
        borderRadius: "5px",
        padding: "10px"


    },
    totalInvoiceQuoteWrapper: {
        display: "flex",
        alignItem: "center",
        justifyContent: "center",
        gap: "30px",
        margin: "83px 0"
        // height:"400px"
    },
    innerContainerBox: {
        width: "100%",
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-start"
    },
    previewTitle: {
        width: "50%"
    },
    documentsPdfBox: {
        height: '100vh',
        display: 'block',
        maxHeight: '100%',
        width: '100%'
    },
    documentsInnerBox1: {
        // background: '#fcf7f2',
        position: "absolute",
        width: '100%',
        // borderTopLeftRadius: '15px',
        // borderTopRightRadius: '15px',
        top: 0,
        zIndex: 3,
        overflow: 'visible',
        '& .MuiTabs-scroller.MuiTabs-fixed': {
            overflow: 'visible !important'
        },
        // '& button': {
        //     '& h6': {
        //         color: '#000',
        //         fontWeight: '700',
        //     },
        //     '& p': {
        //         color: '#000',
        //         textTransform: 'initial',
        //     },
        //     '&.Mui-selected': {
        //         overflow: 'visible',
        //         '& h6': {
        //             color: '#e0802e',
        //             fontWeight: '700'
        //         },
        //         '& p': {
        //             color: '#e0802e',
        //             textTransform: 'initial',
        //         },
        //     },
        //     '&:nth-child(2) p': {
        //         display: 'none'
        //     },
        // },
        '& span.MuiTabs-indicator': {
            display: 'none!important'
        },
        '& span': {
            // textTransform: 'capitalize',
        },
        '& div:nth-child(2)': {
            margin: '0 9px 0 16px'
        },
        '& MuiBox-root-67': {
            padding: 0
        }
    },
    documentsInnerBox2: {
        // background: '#fcf7f2',
        // position: 'relative' as 'relative',
        width: '100%',
        marginLeft: "12px",
        zIndex:3,
        // borderTopLeftRadius: '15px',
        // borderTopRightRadius: '15px',
        top: 0,
        overflow: 'visible',
        '& .MuiTabs-scroller.MuiTabs-fixed': {
            overflow: 'visible !important'
        },
        // '& button': {
        //     '& h6': {
        //         color: '#000',
        //         fontWeight: '700',
        //     },
        //     '& p': {
        //         color: '#000',
        //         textTransform: 'initial',
        //     },
        //     '&.Mui-selected': {
        //         overflow: 'visible',
        //         '& h6': {
        //             color: '#e0802e',
        //             fontWeight: '700'
        //         },
        //         '& p': {
        //             color: '#e0802e',
        //             textTransform: 'initial',
        //         },
        //     },
        //     '&:nth-child(2) p': {
        //         display: 'none'
        //     },
        // },
        '& span.MuiTabs-indicator': {
            display: 'none'
        },
        '& span': {
            // textTransform: 'capitalize',
        },
        '& div:nth-child(2)': {
            margin: '0 9px 0 16px'
        },
        '& MuiBox-root-67': {
            padding: 0
        }
    }, imgStyle: {
        filter: 'invert(1)',
        marginRight: '8px',
    },
    imgStyle1: {
        // filter: 'invert()',
        marginRight: '8px',
    },
    tabAndReuploadBox: {

        // position:"absolute"
    },
    buttonBox: {
        position: "absolute",
        top: "-16px",
        right: "-12px"
    },
    buttonBox1: {
        position: "absolute",
        top: "-16px",
        right: "-2px"
    },
    paginationBox: {
        height: "100px",
        padding: "20px 0"
    },
    previewQuoteOutterBox: {
        height: "135vh",
        width: "98%",
        border: "1px solid gray",
        borderRadius: "0 8px 8px 8px",
        padding: "15px",
        marginTop: "48px",
        '& body #toolbarContainer':{
            display:"none"
        }
    },
    previewInvoiceOutterBox: {
        height: "135vh",
        width: "98%",
        border: "1px solid gray",
        borderRadius: "0 8px 8px 8px",
        padding: "15px",
        marginLeft: "12px",
        marginTop: "-1px",
        // position:"relative"
    },
    previewQuoteInnerBox: {
        height: '120vh',
        display: 'block',
        maxHeight: '120vh',
        width: '100%',
        borderRadius: "8px"
    },
    previewInvoiceInnerBox: {
        height: '120vh',
        display: 'block',
        maxHeight: '120vh',
        width: '100%',
        borderRadius: "8px",
        '& iframe .toolbar #toolbarContainer #toolbarViewer':{
            display:"none!important",
        }
    },
    buttonStyleWrapper: {
        marginLeft: "20px"
    },


    buttonStyle: {
        background: "black",
        color: "white",
        height: "46px",
        marginTop: "15px",
        marginRight: "15px"

    },


    documentsBox: {
        position: "relative",
        width: "50%",
        color: "black"
    },
    documentsTitletBox: {
        minWidth: '115px',
        textAlign: 'center' as 'center',
        padding: '13px 0px',
        border: '1px solid gray',
        borderTopLeftRadius: '15px',
        borderTopRightRadius: '15px',
        borderBottom: 'none',
        backgroundColor: "white",
        '&.Mui-selected': {
            background: '#ffffff',
            border: '1px solid #gray',
            borderTopLeftRadius: '18px',
            borderTopRightRadius: '18px',
            borderBottom: 'none'
        },
    },
    projectTabBox: {
        display: 'flex',
        justifyContent: 'flex-start',
        background: '#ffffff',
        position: 'relative' as 'relative',
        width: '100%',
    },
    allprojectListtabs: {
        border: '1px solid #eee',
        margin: '10px',
        borderRadius: '10px',
        maxWidth: '25%',
        flex: '0 0 25%',
        padding: '15px 0',
        '& button': {
            borderBottom: '1px solid #eee',
            padding: '18px 0',
            opacity: 1,
            maxWidth: '320px',

            '& h6': {
                fontWeight: '500',
                color: '#131324',
                // textTransform: 'capitalize' as 'capitalize',

            },
            '&.Mui-selected': {
                borderRadius: '10px',
                background: '#e0802e',
                padding: '15px',
                '& h6': {
                    fontWeight: '600',
                    color: '#ffffff',
                    // textTransform: 'capitalize' as 'capitalize',

                },
                '& p': {
                    color: '#ffffff',
                    fontWeight: '500',
                    // textTransform: 'capitalize' as 'capitalize',
                },

            }
        },
        '& span': {
            alignItems: 'unset',
            // textTransform: 'capitalize !important',
        },
        '& span.MuiTabs-indicator': {
            display: 'none'
        },
        '& div:nth-child(2)': {
            margin: '0 9px 0 16px'
        }

    },
    tabDocumentsMenuBox: {
        textAlign: 'left' as 'left',
        // textTransform: 'capitalize' as 'capitalize',
    },
    documentstabpanelBox: {
        maxWidth: '100%',
        border: '1px solid #000',
        // margin: '15px 0',
        borderRadius: '0 8px 8px 8px ',
        flex: '0 0 100%',
        padding: '15px',
        position: 'relative' as 'relative',
        // borderRadiusTopLeft:"0px"
    },
    documentscurveBox: {
        height: '30px',
        width: '31px',
        background: '#fcf7f2',
        position: 'absolute' as 'absolute',
        bottom: 0,
        left: '-31px',
    },
    documentscurveInnerBox: {
        border: '1px solid #e0802e',
        height: '30px',
        width: '31px',
        borderBottomRightRadius: '15px',
        borderTop: 'none',
        borderLeft: 'none'
    },
    documentscurveRightBox: {
        height: '33px',
        width: '30px',
        background: '#fcf7f2',
        position: 'absolute' as 'absolute',
        bottom: 0,
        right: '-30px',
        zIndex: 99,
    },
    curveRightdocumentsInnerBox: {
        border: '1px solid #e0802e',
        height: '32px',
        width: '30px',
        borderBottomLeftRadius: '15px',
        borderTop: 'none',
        borderRight: 'none'
    },
    documentabPanelTitle: {
        color: '#000',
        display: 'flex',
        alignItems: 'center',
        '& h6': {
            // textTransform: 'capitalize' as 'capitalize',

            '& span': {
                fontSize: '18px',
                fontWeight: '400',
                // textTransform: 'capitalize' as 'capitalize',
            }
        },
    },
    functionDocsBox: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'end'
    },
    filterDocsBox: {
        border: '1px solid #000',
        borderRadius: '4px',
        padding: '6px 15px !important',
        display: 'inline-block',
        textAlign: 'center' as 'center',
        position: 'absolute' as 'absolute',
        right: '18%',
        top: '23px',


        '& img': {
            width: '25px',
            height: '25px'
        },
        '& p': {
            display: 'inline-block',
            paddingLeft: '10px',
            color: '#000'
        }
    },
    iconInnerBox: {
        '& img': {
            width: '25px',
            height: '25px'
        }
    },
    iconDocsBox: {
        display: 'inline-block',
        position: 'absolute' as 'absolute',
        top: '27px',
        right: '18px',
        '& img': {
            width: '30px',
            height: '30px',
            marginLeft: '30px',

        }
    },
    tabpanelDocsInnerBox: {
        '& div:nth-child(1)': {
            padding: '0',
        },
    },
    tabpanelDocsContentBox: {
        marginBottom: '10px'
    },

    filterModalBox: {
        width: '46%',
        background: '#fff',
        borderRadius: '10px',
        border: '1px solid #000',
        // position: 'absolute' as 'absolute',
        left: '39%',
        top: '26%',
        overflow: 'hidden'
    },

    filterInnerBox: {
        margin: '25px',
        '& h5': {
            color: '#000',
            display: 'inline-block',
            marginRight: '20px',
            fontWeight: 600,
        },
        '& img': {
            width: '25px',
            marginTop: '-3px'
        }
    },

    projectSearchBox: {
        borderTop: '1px solid #ddd',
        padding: '5px 25px 25px',
    },

    formControlBox: {
        width: '100%',
        '& input': {
            border: '1px solid #eae1e1',
            padding: '14px',
            borderRadius: '5px',
        },
        '& fieldset': {
            border: '1px solid #eae1e1',
        },
        '& svg': {
            right: '15px'
        }
    },

    vendorTitleBox: {
        paddingBottom: '10px',
        '& h6': {
            fontWeight: 600,
            color: '#000'
        }
    },

    buttonStyleCal: {
        minHeight: '1.1876em',
        padding: '0px 8px',
        border: 'solid 1px rgba(0, 0, 0, 0.23)',
        width: '100%',
        justifyContent: 'flex-start',
        backgroundColor: 'rgb(223, 128, 45)'
    },
    selectDateRangeBox: {
        '& h6': {
            fontWeight: 600,
            color: '#000'
        }
    },
    cancelApplyButtonBox: {
        textAlign: "right" as "right",
        width: '100%',
        "& button": {
            padding: "15px 60px",
            textTransform: "unset",
            color: "#000",
            fontSize: "20px",
            lineHeight: "22px",

            "&:hover": {
                background: "#e0802e",
                color: "#ffffff"
            },
            "&:first-child": {
                marginRight: "40px"
            }
        }
    },

    











});

export const boxStyle = {
    buttonStyle1: {
        // positon:"absolute",
        background: "white",
        color: "black",
        height: "42px",
        marginTop: "15px",
        marginRight: "15px",
        border: "1px solid black",
        borderRadius: "4px"
        // top: "-64px",
        // left: "376px"
    },
    buttonStyle: {
        background: "black",
        color: "white",
        height: "46px",
        marginTop: "15px",
        marginRight: "15px",
        borderRadius: "4px"


    },
    pageNumber:{
        margin:"0px 2px",
        background:"#e8e2e2",
        padding:"2px 10px",
        borderRadius:"3px"
    }
}



export default withStyles(webStyle)(QuoteInvoice)

// Customizable Area End
