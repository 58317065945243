// Customizable Area Start
  import { Message } from "../../../framework/src/Message";
  import { BlockComponent } from "../../../framework/src/BlockComponent";
  import { runEngine } from "../../../framework/src/RunEngine";
  import { IBlock } from "../../../framework/src/IBlock";
  import { addDays } from 'date-fns';
  import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
// Customizable Area End

export const configJSON = require("./config");
export interface Props {
  // Customizable Area Start
  hasDateRange: boolean;
  dateRangeValue: any;
  navigation :any;
  classes: any;
  handleSaveData: (data: any) => void;
  history: any;
  // Customizable Area End
}
export interface TabPanelProps {
  // Customizable Area Start
    children?: React.ReactNode;
    index: any;
    value: any;
  // Customizable Area End
}
interface S {
  // Customizable Area Start
    value: number;
    valueTab: number;
    filterModal: boolean;
    vendorValue: string;
    hasDateRangeModal: boolean;
    dateRangeData: [{
      startDate: Date;
      endDate: Date;
      key: string
    }];
    token: string;
    ProjectOptionValue: [];
    vendorOptionValue:[];
    projectValue: string;
    allTypeDocumentList: any[];
    documentsTypeTabs: string[];
    documentslength: number;
    allTypeDocumentError: string;
    deleteModal: boolean;
    deleteModalId: number
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  // Customizable Area End
}

export default class DocumentsController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getAllprojectsApiId: string = "";
  getProjectApiId: string = "";
  getVendorApiId: string = "";
  getSearchProjectApiId = "";
  deleteDocumentApiId = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    
    this.state = {
      // Customizable Area Start
        value: 0,
        valueTab: 0,
        allTypeDocumentList:[],
        filterModal: false,
        vendorValue: "",
        projectValue: "",
        hasDateRangeModal: false,
        deleteModal: false,
        deleteModalId: 0,
        dateRangeData: [{
          startDate: new Date(),
          endDate: addDays(new Date(), 0),
          key: 'selection'
        }],
        token: '',
        ProjectOptionValue: [],
        vendorOptionValue: [],
        documentsTypeTabs: ["Documents", "OCR(Optical Character Recognition)"],
        documentslength: 0,
        allTypeDocumentError: ""
      // Customizable Area End
    };

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
    ];
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId === this.getProjectApiId) {
        this.projectResponseHandler(responseJson)
      } 
      if (apiRequestCallId === this.getVendorApiId) {
        this.vendorResponseHandler(responseJson)
      }
      if (apiRequestCallId === this.getAllprojectsApiId) {
        this.setState({allTypeDocumentList: responseJson.combined_data})
        this.setState({documentslength: responseJson.combined_data.length})
      } 

      if (apiRequestCallId === this.getSearchProjectApiId) {
        this.allDocumentResponsehandler(responseJson)
      }

      this.deleteResponseHandler(apiRequestCallId, responseJson)
     
    }
  }

  // Customizable Area Start
  async componentDidMount() {
    const token = await document.cookie.split("; ").find((row) => row.startsWith("Token="))?.split("=")[1];
    token && this.setState({ token: token });
    this.getVendorsValue()
    this.getProjectsValue()
    this.getAllTypeProjects()
  }

  projectResponseHandler = (responseJson: any) => {
    if (responseJson && responseJson.data && !responseJson.errors) {
      this.setState({ ProjectOptionValue: responseJson.data })
    }
  }

  vendorResponseHandler = (responseJson: any) => {
    if (responseJson && responseJson.data && !responseJson.errors) {
      this.setState({ vendorOptionValue: responseJson.data })
    }
  }

  allDocumentResponsehandler = (responseJson: any) => {
    if (responseJson.message == "Success" && responseJson.data) {
      this.setState({allTypeDocumentList: responseJson.data})
    }else if(responseJson.message == "No records found"){
      this.setState({allTypeDocumentError: responseJson.message, allTypeDocumentList: []})
    }
  }

  deleteResponseHandler = (apiRequestCallId: any, responseJson: any) => {
    if (apiRequestCallId === this.deleteDocumentApiId) {
      const updatedData = this.state.allTypeDocumentList.filter((item: any) => (item.quotes_id  !== this.state.deleteModalId) && (item.invoice_id  !== this.state.deleteModalId))
      this.setState({ allTypeDocumentList: updatedData, deleteModal: false, value: 0 })
    }
  }
 
  handleOpenDeleteModal = (itemId: number) => {
    this.setState({deleteModal: !this.state.deleteModal, deleteModalId: itemId})
  }

  handleCloseDeleteModal = () => {
    this.setState({deleteModal: false})
  }
  handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    this.setState({value: newValue})
  }

  handleTabChange = (event: React.ChangeEvent<{}>, newTabValue: number) => {
    this.setState({valueTab: newTabValue})

    if(newTabValue === 1) {
      this.props.navigation.navigate('OcrUpload')
    }

  }

  handleOpenFilterModal = () => {
    this.setState({filterModal: !this.state.filterModal})
  }
  handleCloseFilterModal = () => {
    this.setState({filterModal: false})
  }

  selectVendorValue = (event: React.ChangeEvent<{ value?: string | unknown }>)  => {
    const selectedVendor = event.target.value as string;
    this.setState({ vendorValue: selectedVendor });
  }

  selectProjectValue = (event: React.ChangeEvent<{ value?: string | unknown }>)  => {
    const selectedProject = event.target.value as string;
    this.setState({ projectValue: selectedProject });
  }

  handleDateRangeOpenModal = () => {
    this.setState({ hasDateRangeModal: !this.state.hasDateRangeModal })
  }

  handleSelectDateRangeValue = (data: any) => {
    this.setState({ dateRangeData: data })
  }

  getAllTypeProjects = () => {
    const token = this.state.token;

    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getAllprojectsApiId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.allProjectsApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }

  getProjectsValue = () => {
    const token = this.state.token;

    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getProjectApiId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.projectApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }

  getVendorsValue = () => {
    const token = this.state.token;

    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getVendorApiId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.vendorApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }

  handleProjectsSearch = () => {
    this.setState({filterModal: false, vendorValue: "", projectValue: "" })
    
    const token = this.state.token;

    const projectListData = {
      vendor: this.state.vendorValue,
      project: this.state.projectValue,
      date: this.state.dateRangeData
    }

    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": token
    };

    const httpBody = {
      data: projectListData,
    };

    const getSearchProjectsrequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getSearchProjectApiId = getSearchProjectsrequestMessage.messageId;

    getSearchProjectsrequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.filterProjectApiEndPoint
    );

    getSearchProjectsrequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    getSearchProjectsrequestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    getSearchProjectsrequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );

    runEngine.sendMessage(getSearchProjectsrequestMessage.id, getSearchProjectsrequestMessage);

    return true;
  }

  handleDeleteDocument = () => {
    const token = this.state.token;

    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": token
    };

    const deleteDocumentrequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.deleteDocumentApiId = deleteDocumentrequestMessage.messageId;

    deleteDocumentrequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.deleteDocumentApiEndPoint}/${this.state.deleteModalId}`
    );

    deleteDocumentrequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.documentDeleteApiMethod
    );

    deleteDocumentrequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    runEngine.sendMessage(deleteDocumentrequestMessage.id, deleteDocumentrequestMessage);

    return true;
  }

  // Customizable Area End
}
