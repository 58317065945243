import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { WithStyles } from "@material-ui/core";
// Customizable Area End

export const configJSON = require("./config");

// Customizable Area Start

interface VideoAttributes {
  video: string;
}

interface HomePageVideo {
  id: string;
  type: string;
  attributes: VideoAttributes;
}

interface VideoResponseObject {
  data: HomePageVideo[];
}
// Customizable Area End

export interface Props extends WithStyles {
  // Customizable Area Start
  classes: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  videoURL:string;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class HowItWorksControllerWeb extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getVideoUrlId: string = "";
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [ getName(MessageEnum.RestAPIResponceDataMessage),
      getName(MessageEnum.RestAPIResponceSuccessMessage),
      getName(MessageEnum.RestAPIResponceMessage),];
      this.state={
      videoURL:""
      }
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
    );
  
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
  );
  
  if (apiRequestCallId === this.getVideoUrlId) {
    this.handleGetVideoURL(responseJson)
  }
      }
    // Customizable Area End
  }

  // Customizable Area Start
  goHome() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationHomeScreenMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  async componentDidMount(): Promise<void> {
    this.getVideoURL();
  }

    getToken = () => {
        return document.cookie.split("; ").find((row) => row.startsWith("Token="))?.split("=")[1]
    }

  getVideoURL = () => {
    const header = {
        token: this.getToken()
    };

    const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getVideoUrlId = requestMessage.messageId

    requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.videoURLEndpoint
    );

    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
    );

    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.quoteUploadDeleteApiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
};

  handleGetVideoURL = (responseJson: VideoResponseObject) => {
    this.setState({
     videoURL: responseJson.data[0].attributes.video
    })
   }



  // Customizable Area End
}
