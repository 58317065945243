// Customizable Area Start
import React from "react";
import { NavLink } from "react-router-dom";
import { styled } from '@material-ui/styles';
import { Typography, Box, Button } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

import Header from "../../landingpage/src/Header.web";
import DashboardNewTable from "../../landingpage/src/DashboardNewTable.web";
import DashboardReportController, { Props, configJSON } from "./DashboardReportController.web";

const StyledButton = styled(Button)({
    background: '#ef7927',
    width: '20%',
    height: "45px",
    textTransform: 'capitalize',
    padding: '16px',
    fontWeight: 600,
    marginTop: '20px',
    borderRadius: '8px',
    boxShadow: 'none',
    '&:hover': {
        background: '#ef7927',
    }

});

export class DashboardReport extends DashboardReportController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    render() {
        const { classes } = this.props
        return (
            <>
                <Header
                    data-testid="DashboardInvoiceHeaderId"
                    handleSaveData={this.handleSaveData}
                    navigation={this.props.navigation}
                    history={this.props.history}
                />
                <Box className={classes.ReportCompareBox}>
                    <Box className={classes.ReportHeading}>
                        <Typography variant="h5">Reports</Typography>
                        <Typography>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Natus unde omnis perferendis iure consectetur. Ab, earum aperiam perferendis molestias enim sint.</Typography>
                    </Box>
                    <Box className={classes.ReportUploadButton}>
                        <NavLink activeStyle={{ textDecoration: 'none' }} className={classes.reportNavLinkStyle} to="/report/report-generation">
                            <StyledButton
                                type="button"
                                variant="contained"
                                color="secondary"
                                data-testid="uploadButtonId"
                            >
                                Report Geneartion
                            </StyledButton>
                        </NavLink>
                    </Box>

                </Box>
                <DashboardNewTable
                    rows={this.state.uploadData}
                    title={configJSON.reportTitle}
                    hasMenu={true}
                    hasStatusData={true}
                    navigatePath="/report"
                />
            </>
        );
    }
}

export const webStyle = {
    ReportCompareBox: {
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: '15px'
    },
    ReportUploadButton: {
        '& button': {
            width: '100%',
            minWidth: '172px',
            marginTop: '15px'
        }
    },
    reportNavLinkStyle: {
        textDecoration: 'none',
        '&:focus, &:hover, &:visited, &:link, &:active': {
            textDecoration: 'none'
        }
    },
    ReportHeading: {
        paddingRight: '5px'
    }
};

export default withStyles(webStyle)(DashboardReport)

// Customizable Area End
