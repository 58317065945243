// Customizable Area Start
import React from "react";
import { NavLink } from "react-router-dom";
import { styled } from '@material-ui/styles';
import { Typography, Box, Button } from "@material-ui/core";
import { createTheme, ThemeProvider, withStyles } from "@material-ui/core/styles";

import Header from "../../landingpage/src/Header.web";
import DashboardNewTable from "../../landingpage/src/DashboardNewTable.web";
import DashboardInvoiceController, { Props, configJSON } from "./DashboardInvoiceController.web";

const StyledButton = styled(Button)({
    background: '#ef7927',
    width: '20%',
    height: "45px",
    textTransform: 'capitalize',
    padding: '16px',
    fontWeight: 600,
    marginTop: '20px',
    borderRadius: '8px',
    boxShadow: 'none',
    '&:hover': {
        background: '#ef7927',
    }

});

export class DashboardInvoice extends DashboardInvoiceController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    render() {
        const { classes } = this.props
        return (
            <>
                    <Header
                    data-testid="DashboardInvoiceHeaderId"
                    handleSaveData={this.handleSaveData}
                    navigation={this.props.navigation} history={this.props.history}                    />
                    <Box className={classes.invoiceCompareBox}>
                        <Box className={classes.InvoiceHeading}>
                            <Typography variant="h5">Invoices</Typography>
                            <Typography>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Natus unde omnis perferendis iure consectetur. Ab, earum aperiam perferendis molestias enim sint.</Typography>
                        </Box>
                        <Box className={classes.InvoiceUploadButton}>
                            <NavLink activeStyle={{ textDecoration: 'none' }} className={classes.navLinkStyle} to="/invoice/invoice-upload">
                                <StyledButton
                                    type="button"
                                    variant="contained"
                                    color="secondary"
                                    data-testid="uploadButtonId"
                                >
                                    Invoice Upload
                                </StyledButton>
                            </NavLink>
                        </Box>
                    </Box>
                    <DashboardNewTable
                        rows={this.state.uploadData}
                        title={configJSON.uploadTitle}
                        hasMenu={true}
                        hasStatusData={false}
                        hasInvoicePrice={true}
                        navigatePath="/invoice"
                    />
            </>
        );
    }
}

const invoiceTheme = createTheme({
    palette: {
        primary: {
            main: "#fff",
            contrastText: "#fff"
        }
    },
    typography: {
        h5: {
            fontWeight: 500,
            color: '#000'
        },
        subtitle1: {
            margin: "20px 0px"
        }
    }
});

export const webStyle = {
    invoiceCompareBox: {
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: '15px'
    },
    InvoiceUploadButton: {
        '& button': {
            width: '100%',
            minWidth: '172px',
            marginTop: '15px'
        }
    },
    navLinkStyle: {
        textDecoration: 'none',
        '&:focus, &:hover, &:visited, &:link, &:active': {
            textDecoration: 'none'
        }
    },
    InvoiceHeading: {
        paddingRight: '5px'
    }
};

export default withStyles(webStyle)(DashboardInvoice)

// Customizable Area End
