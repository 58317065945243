// Customizable Area Start
import React from 'react'
import { styled } from '@material-ui/styles'
import { Grid, Box, Tabs, Tab, AppBar, Typography, Link, TextField, Button } from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import FullscreenIcon from '@material-ui/icons/Fullscreen';
import SendIcon from '@material-ui/icons/Send';
import ReactFullscreen from 'react-easyfullscreen';
import Header from '../../landingpage/src/Header.web';
import { deleteImg, downloadImgsvg, pdfImage } from "./assets";
import { getTimeAgo } from '../../../framework/src/CalculateTime';
import NotificationPanelController, { Props, configJSON } from './NotificationPanelController.web';
import NotificationPanelList, { DeleteDialogWeb } from './NotificationPanelList.web';


export const theme = createTheme({});

// Customizable Area Start

const ViewPanelBlock = styled(Box)({
    '& .MuiPaper-root.MuiAppBar-root': {
        backgroundColor: 'transparent',
        boxShadow: 'none',
        marginBottom: '15px',
    },
    '& .MuiPaper-root.MuiAppBar-root button.MuiButtonBase-root': {
        minWidth: '130px',
        borderRadius: '6px',
        marginRight: '4px',
        color: '#111',
        '&:hover': {
            backgroundColor: 'rgb(223, 128, 45)',
            color: '#fff',
        }
    },
    '& .MuiPaper-root.MuiAppBar-root .MuiButtonBase-root.Mui-selected': {
        backgroundColor: 'rgb(223, 128, 45)',
        color: '#fff !important',
    },
    '& .MuiPaper-root.MuiAppBar-root .MuiTabs-indicator': {
        display: 'none',
    },
    '& .MuiBox-root-16': {
        padding: "12px 24px",
    },
    '& .tabFirst > .MuiBox-root': {
        paddingLeft: "0px",
        paddingTop: "0px",
        paddingRight: "0px",
    }
})

const StyledNotifyTextField = styled(TextField)({
    border: '1px solid #ddd',
    padding: '10px 20px',
    borderRadius: '5px',
    boxShadow: '0 0 10px #eeee',
    '& .MuiInput-underline:before': {
        borderBottom: 'none !important',
    },
    '& .MuiInput-underline:after': {
        borderBottom: 'none !important'
    },
    '& .MuiInput-underline:hover': {
        borderBottom: 'none !important'
    }

})

interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    tabValue: any;
    className: string;
}

export function TabPanel(props: TabPanelProps) {
    const { children, tabValue, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={tabValue !== index}
            id={`scrollable-auto-tabpanel-${index}`}
            aria-labelledby={`scrollable-auto-tab-${index}`}
            {...other}
        >
            {tabValue === index && (
                <>

                    <Box p={3}>
                        <Typography>{children}</Typography>
                    </Box>
                </>
            )}
        </div>
    );
}

function a11yProps(index: any) {
    return {
        id: `scrollable-auto-tab-${index}`,
        'aria-controls': `scrollable-auto-tabpanel-${index}`,
    };
}

export const TabTitle = ({ title }: any) => {
    return (
        <Typography style={{ textTransform: "capitalize" }}>{title}</Typography>
    )
}

export default class NotificationPanel extends NotificationPanelController {
    constructor(props: Props) {
        super(props)
    }

    render() {
        const { handleSaveData, navigation } = this.props;
        const { selectedNotificationData, notificationReplyData } = this.state;
        return (
            <>
                <Header data-testid="ocruploadHeaderId" navigation={navigation} handleSaveData={handleSaveData} history={this.props.history} />
                <div className="content-scroll" style={webStyle.containerPanelStyle}>
                    <div style={webStyle.contentNotifyStyle}>
                        <h2 style={webStyle.h2HeadPanel}>Notifications</h2>
                        <Grid container spacing={3} style={webStyle.mainPanelGrid}>
                            <Grid item xs={12} sm={8} >
                                <Box style={webStyle.leftPanelBoxStyle}>
                                    <h3 style={webStyle.h3HeadPanel}>Category</h3>
                                    <ViewPanelBlock>
                                        <AppBar position="static" color="default">
                                            <Tabs
                                                data-testid="TabsId"
                                                value={this.state.tabValue}
                                                onChange={this.handleValueChange}
                                                indicatorColor="primary"
                                                textColor="primary"
                                                variant="scrollable"
                                                scrollButtons="off"
                                                style={{ fontSize: '18px' }}
                                                aria-label="scrollable auto tabs example"
                                            >
                                                <Tab label={<TabTitle title="All" />} {...a11yProps(0)} />
                                                <Tab label={<TabTitle title="Quotes" />} {...a11yProps(1)} />
                                                <Tab label={<TabTitle title="Invoices" />} {...a11yProps(2)} />
                                                <Tab label={<TabTitle title="Projects" />} {...a11yProps(3)} />
                                                <Tab label={<TabTitle title="Vendors" />} {...a11yProps(4)} />
                                                <Tab label={<TabTitle title="Comparison" />} {...a11yProps(5)} />
                                            </Tabs>
                                        </AppBar>

                                        <TabPanel className="tabFirst" tabValue={this.state.tabValue} index={0}>
                                            <NotificationPanelList tabValue={this.state.tabValue} handleNotificationClick={this.handleNotificationClick} deletenotificationMsg={this.state.deletenotificationMsg} handleNotificationDeleteData={this.handleNotificationDeleteData} data-testid="selectedItem" navigation={this.props.navigation} history={this.props.history}/>
                                        </TabPanel>

                                        <TabPanel className="tabFirst" tabValue={this.state.tabValue} index={1}>
                                            <NotificationPanelList tabValue={this.state.tabValue} handleNotificationClick={this.handleNotificationClick} deletenotificationMsg={this.state.deletenotificationMsg} handleNotificationDeleteData={this.handleNotificationDeleteData} navigation={this.props.navigation} history={this.props.history}/>
                                        </TabPanel>

                                        <TabPanel className="tabFirst" tabValue={this.state.tabValue} index={2}>
                                            <NotificationPanelList tabValue={this.state.tabValue} handleNotificationClick={this.handleNotificationClick} deletenotificationMsg={this.state.deletenotificationMsg} handleNotificationDeleteData={this.handleNotificationDeleteData} navigation={this.props.navigation} history={this.props.history}/>
                                        </TabPanel>

                                        <TabPanel className="tabFirst" tabValue={this.state.tabValue} index={3}>
                                            <NotificationPanelList tabValue={this.state.tabValue} handleNotificationClick={this.handleNotificationClick} deletenotificationMsg={this.state.deletenotificationMsg} handleNotificationDeleteData={this.handleNotificationDeleteData} navigation={this.props.navigation} history={this.props.history}/>
                                        </TabPanel>

                                        <TabPanel className="tabFirst" tabValue={this.state.tabValue} index={4}>
                                            <NotificationPanelList tabValue={this.state.tabValue} handleNotificationClick={this.handleNotificationClick} deletenotificationMsg={this.state.deletenotificationMsg} handleNotificationDeleteData={this.handleNotificationDeleteData} navigation={this.props.navigation} history={this.props.history}/>
                                        </TabPanel>

                                        <TabPanel className="tabFirst" tabValue={this.state.tabValue} index={5}>
                                            <NotificationPanelList tabValue={this.state.tabValue} handleNotificationClick={this.handleNotificationClick} deletenotificationMsg={this.state.deletenotificationMsg} handleNotificationDeleteData={this.handleNotificationDeleteData} navigation={this.props.navigation} history={this.props.history} />
                                        </TabPanel>
                                    </ViewPanelBlock>
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={4} >
                                <Box style={webStyle.notifyBox}>
                                    <Box style={webStyle.notifyTitleBox}>
                                        <Typography variant="h5" style={webStyle.notifyTitleContent}>{configJSON.replyTitle}</Typography>
                                    </Box>

                                    {
                                        this.state.clickNotificationshow ?

                                            <Box style={webStyle.notifyContentBox} data-testid="tabpanelid">
                                                <Typography style={webStyle.notifyContent}>{selectedNotificationData && selectedNotificationData.attributes?.contents}</Typography>
                                                <Typography style={webStyle.notifyTimer}>{getTimeAgo(new Date(selectedNotificationData && selectedNotificationData.attributes?.created_at).getTime())}</Typography>
                                                <ReactFullscreen data-testid='fullScreen'>
                                                    {({ ref, onRequest }: any) => (
                                                        <Box style={webStyle.notifyPdfDataBox}>
                                                            <Box style={webStyle.notifyPdfDataInnerBox}>
                                                                <Box style={webStyle.notifyPdfBox}>
                                                                    <iframe src={pdfImage} style={webStyle.notifydocumentsPdfBox} ref={ref}></iframe>
                                                                </Box>
                                                                <Box style={webStyle.notifyImageBox}>
                                                                    <Box style={webStyle.notifyPdfImgBox}>
                                                                        <FullscreenIcon onClick={() => onRequest()} data-testid="fullscreenId" style={webStyle.fullscreenIcon} />
                                                                        <Link href={pdfImage} download="test_quote.pdf"><img src={downloadImgsvg} alt="downloadImg" style={webStyle.downloadIcon} /></Link>
                                                                    </Box>
                                                                    <Box>
                                                                        <img src={deleteImg} alt="deleteIcon" style={webStyle.deleteImg} onClick={this.closeDeleteDialogHandler} data-testid="deleteReplyId" />
                                                                    </Box>
                                                                </Box>
                                                            </Box>
                                                            <Box style={webStyle.notificationReplyDataBox}>
                                                                {
                                                                    notificationReplyData.map((replyMsg: any) => {
                                                                        return(
                                                                            <>
                                                                                <Typography>{replyMsg.user_reply}</Typography>
                                                                                <Typography>{getTimeAgo(new Date(replyMsg.created_at).getTime())}</Typography>
                                                                            </>
                                                                        )
                                                                    })
                                                                }

                                                            </Box>

                                                            <Box style={webStyle.notifyReplysendBox} >
                                                                <StyledNotifyTextField placeholder={configJSON.typeHereContent} value={this.state.replyValue} data-testid="replyHandleChange" onChange={(event) => this.replyHandleChange(event)} />
                                                                <Button onClick={this.handleReplyClick} style={webStyle.notifySendIconButton} data-testid="replyHandleClickId">
                                                                    <SendIcon style={webStyle.notifySendIcon} />
                                                                </Button>
                                                            </Box>
                                                        </Box>
                                                    )}
                                                </ReactFullscreen>
                                            </Box> :
                                            <Box>
                                                <div style={webStyle.showPanelData}>
                                                    <Box sx={webStyle.noPanelData}>
                                                        <img style={webStyle.smallPanelImg} src={this.state.docImg} />
                                                        <br></br>
                                                        <p style={{ paddingTop: '2%' }}>Your not selecting any kindof reply to notification.</p>
                                                    </Box>
                                                </div>
                                            </Box>
                                    }
                                </Box>
                            </Grid>
                        </Grid>
                    </div>
                </div >
                <DeleteDialogWeb
                    data-testid="deletDialogWrapperId"
                    openDialog={this.state.dialogModal}
                    handleCloseDialog={this.handleCloseDialogFunction}
                    handleDeleteDailog={this.handelDeleteNotification}
                />
            </>
        )
    }
}

const webStyle = {
    containerPanelStyle: {
        backgroundColor: "#fff",
        border: "1px solid #eee",
        borderRadius: "12px",
    },
    contentNotifyStyle: {
        border: "1px solid #80808091",
        borderRadius: "12px",
        padding: '19px 20px 13px',
        background: '#ffffff'
    },
    leftPanelBoxStyle: {
        background: "#fff",
        border: "1px solid #80808091",
        borderRadius: "8px",
        height: '98%',
        padding: '20px',
    },
    rightPanelBoxStyle: {
        background: "#fff",
        border: "1px solid #80808091",
        borderRadius: "8px",
        padding: '0 15px',
        display: 'block',
    },

    h2HeadPanel: {
        margin: '0',
        paddingBottom: "15px",
        paddingLeft: "16px",
        color: 'black'
    },
    h3HeadPanel: {
        margin: '0',
        paddingBottom: "10px",
        color: 'black',
        fontWeight: 550
    },

    noPanelData: {
        width: '100%',
    },
    showPanelData: {
        width: '100%',
        textAlign: 'center' as 'center',
        margin: '50% auto'
    },
    smallPanelImg: {
        width: "206px",
        height: "228px",
    },
    mainPanelGrid: {
        height: '800px',
        overflow: "hidden",
        justifyContent: "space-between",
    },
    notifyBox: {
        border: '1px solid #ddd',
        boxShadow: '0 0 10px #eee',
        borderRadius: '10px',
        height: '98%'
    },
    notifyTitleBox: {
        padding: '20px',
        borderBottom: '1px solid #ddd',
    },
    notifyTitleContent: {
        color: '#000',
        fontWeight: 600,
    },
    notifyContentBox: {
        padding: '20px',
    },
    notifyContent: {
        color: '#000',
        fontWeight: 500,
    },
    notifyTimer: {
        margin: '8px 0 13px'
    },
    notifyPdfBox: {
        height: '90%',
    },
    notifyPdfDataInnerBox:{
        height: '100%'
    },

    notifyPdfDataBox: {
        display: 'flex',
        flexDirection: 'column' as 'column',
        justifyContent: 'space-between',
        height: '76%',
    },
    clickHereContent: {
        textDecoration: 'underline',
        color: '#e0802e'
    },
    notifydocumentsPdfBox: {
        display: 'block',
        maxHeight: '100%',
        width: '100%',
        height: '100%'
    },
    notifyImageBox: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        margin: '10px 0 22px'
    },
    fullscreenIcon: {
        width: '30px',
        height: '30px',
        marginLeft: '-7px',
        color: '#000'
    },
    downloadIcon: {
        width: '30px',
        height: '30px',
        marginLeft: '10px'
    },
    deleteImg: {
        width: '22px',
        height: '22px'
    },
    notifyPdfImgBox: {
        display: 'inline-flex'
    },
    notifyReplysendBox: {
        display: 'inline-flex'
    },
    notifySendIconButton: {
        background: '#e0802e',
    },
    notifySendIcon: {
        color: '#fff',
        transform: 'rotate(317deg)'
    },
    notificationReplyDataBox:{
        overflowY: 'scroll' as 'scroll',
        margin: '5px 0 10px',
        maxHeight: '10%'
    }
};
// Customizable Area End