import React from "react";

import {
  Container,
  Box,
  Input,
  Button,
  InputLabel,
  Typography,
  InputAdornment,
  IconButton,
  // Customizable Area Start
  AppBar,
  Toolbar,
  styled,
  Menu,
  MenuItem,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Visibility from "@material-ui/icons/Visibility";
import MoreIcon from "@material-ui/icons/MoreVert";
import { headerNewLogo } from "./assets";

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});


const CustomAppBar = styled(AppBar)({
  background: "transparent",
  boxShadow: "none"
})

const CustomToolbar = styled(Toolbar)({
  backgroundColor: "#05052B",
  padding:"20px 55px"
})

const Logodiv = styled("div")({
  backgroundImage: `url(${headerNewLogo})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    height: 74.36,
    width: 300,
    backgroundSize: "contain"
})

const Growdiv = styled("div")({
  flexGrow: 1
})

const SectionDesktopDiv = styled("div")({
  display: "none",
  "& .MuiButton-root": {
    borderRadius: "10px"
  },
  [theme.breakpoints.up("md")]: {
    display: "flex",
    gap:"30px"
  }
})

const LoginButton = styled(Button)({
  marginRight: theme.spacing(3),
  fontSize: 20,
  color: "#fff",
  textTransform: "capitalize"
})

const SignupButton = styled(Button)({
  marginRight: theme.spacing(3),
    fontSize: 20,
    color: "#05052B",
    textTransform: "capitalize",
    backgroundColor:"#fff",
    borderRadius:"6px !important",
    padding:"8px 30px",
  "&:hover":{
    backgroundColor:"#fff",
  }
})



const SectionMobileDiv = styled("div")({
  display: "flex",
  marginTop:"28px",
  [theme.breakpoints.up("md")]: {
    display: "none"
  }
})

const CustomMoreIcon = styled(MoreIcon)({
  color:"white"
})



const CustomMenuItem = styled(MenuItem)({
    "&:hover":{
        backgroundColor:"#05052B !important"
      }
})

const mobileMenuId = "primary-search-account-menu-mobile";

// Customizable Area End

import NewHeaderController, {
  Props,
  configJSON,
} from "./NewHeaderController";

export default class NewHeader extends NewHeaderController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <Box>
        <CustomAppBar position="static">
              <CustomToolbar>
                <Logodiv/>
                <Growdiv/>
                <SectionDesktopDiv>
                 
                  <LoginButton
                    onClick={() =>
                      this.props.navigation.navigate("Login")
                    }
                  >
                    Login
                  </LoginButton>
                  <SignupButton
                    onClick={() =>
                      this.props.navigation.navigate(
                        "Login"
                      )
                    }
                  >
                    Signup
                  </SignupButton>
                  
                </SectionDesktopDiv>
                <SectionMobileDiv>
                  <Button
                    aria-label="show more"
                    aria-controls={mobileMenuId}
                    aria-haspopup="true"
                    onClick={this.handleMenuOpen}
                  >
                    <CustomMoreIcon/>
                  </Button>
                </SectionMobileDiv>
              </CustomToolbar>
            </CustomAppBar>
            <Menu
        anchorEl={this.state.mobileMoreAnchorEl}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        id={mobileMenuId}
        keepMounted
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        open={Boolean(this.state.mobileMoreAnchorEl)}
        onClose={this.handleMobileMenuClose}
      >
       
        <CustomMenuItem onClick={() =>this.props.navigation.navigate("Login")}>
          <p>Login</p>
        </CustomMenuItem>
        <CustomMenuItem onClick={() =>this.props.navigation.navigate("Signup")}>
          <p>Signup</p>
        </CustomMenuItem>
            </Menu>
      </Box>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  mainWrapper: {
    display: "flex",
    fontFamily: "Roboto-Medium",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: "30px",
    background: "#fff",
  },
  inputStyle: {
    borderBottom: "1px solid rgba(0, 0, 0, 0.6)",
    width: "100%",
    height: "100px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  buttonStyle: {
    width: "100%",
    height: "45px",
    marginTop: "40px",
    border: "none",
    backgroundColor: "rgb(98, 0, 238)",
  },
};
// Customizable Area End
