// Customizable Area Start
import React from "react";
import DashboardNewTableController, {
    EnhancedTableHeadProps,
    EnhancedTableDataProps,
    EnhancedTableToolbarProps
} from "./DashboardNewTableController.web";
import clsx from "clsx";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { Button, Box, Typography } from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Toolbar from "@material-ui/core/Toolbar";
import Paper from "@material-ui/core/Paper";
import Checkbox from "@material-ui/core/Checkbox";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import { styled } from "@material-ui/styles";
import { checkboxIcon, closeIcon, exportIcon, sortIcon, reportIcon1 } from "./assets";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { Props } from "./DashboardWebController.web";
import moment from "moment";
import TablePagination from '@material-ui/core/TablePagination';
import Modal from '@material-ui/core/Modal';
import ExportModal from './ExportModal.web';
import ReportModal from './ReportModal.web';
import DocumentOpener from '../../customform/src/DocumentOpener.web';
import { NavLink } from "react-router-dom";
interface DataInterface {
    QuoteID: string;
    ReportID: string;
    Date: string;
    Vender: string;
    Project: string;
    AddInvoice?: string;
    Status?: string;
    Delta?: string;
    InvoiceId?: string;
}

interface HeadCellInterface {
    disablePadding: boolean;
    id: keyof DataInterface;
    label: string;
    numeric: boolean;
}

interface HeadItem {
    disablePadding: boolean;
    id: keyof DataInterface;
    label: string;
    numeric: boolean;
}

const tableHeadCells: HeadCellInterface[] = [
    { id: "QuoteID", numeric: false, disablePadding: true, label: "Quote ID" },
    { id: "Date", numeric: false, disablePadding: false, label: "Date" },
    { id: "Vender", numeric: false, disablePadding: false, label: "Vendor" },
    { id: "Project", numeric: false, disablePadding: false, label: "Project" }
];

const libraryHeadCells: HeadCellInterface[] = [
    { id: "ReportID", numeric: false, disablePadding: true, label: "Report ID" },
    { id: "Date", numeric: false, disablePadding: false, label: "Date" },
    { id: "Vender", numeric: false, disablePadding: false, label: "Vendor" },
    { id: "Project", numeric: false, disablePadding: false, label: "Project" },
    { id: "Delta", numeric: false, disablePadding: false, label: "Delta $" },
];

const quoteHeadCells: HeadCellInterface[] = [
    { id: "QuoteID", numeric: false, disablePadding: true, label: "Quote ID" },
    { id: "Date", numeric: false, disablePadding: false, label: "Date" },
    { id: "Vender", numeric: false, disablePadding: false, label: "Vendor" },
    { id: "Project", numeric: false, disablePadding: false, label: "Project" },
    { id: "AddInvoice", numeric: false, disablePadding: false, label: "Add Invoice" },
    { id: "Status", numeric: false, disablePadding: false, label: "Status" },
];

const quoteComparisonHeadCells: HeadCellInterface[] = [
    { id: "QuoteID", numeric: false, disablePadding: true, label: "Quote ID" },
    { id: "Date", numeric: false, disablePadding: false, label: "Date" },
    { id: "Vender", numeric: false, disablePadding: false, label: "Vendor" },
    { id: "Project", numeric: false, disablePadding: false, label: "Project" },
    { id: "Status", numeric: false, disablePadding: false, label: "Status" },
    { id: "Delta", numeric: false, disablePadding: false, label: "Delta $" },
]

const dashboardInvoiceHeadCells: HeadCellInterface[] = [
    { id: "QuoteID", numeric: false, disablePadding: true, label: "Quote ID" },
    { id: "Date", numeric: false, disablePadding: false, label: "Date" },
    { id: "Vender", numeric: false, disablePadding: false, label: "Vendor" },
    { id: "Project", numeric: false, disablePadding: false, label: "Project" },
    { id: "InvoiceId", numeric: false, disablePadding: false, label: "Invoice" },
    { id: "Status", numeric: false, disablePadding: false, label: "Status" },
]

const dashboardReportHeadCells: HeadCellInterface[] = [
    { id: "ReportID", numeric: false, disablePadding: true, label: "ReportID" },
    { id: "Date", numeric: false, disablePadding: false, label: "Date" },
    { id: "Vender", numeric: false, disablePadding: false, label: "Vendor" },
    { id: "Project", numeric: false, disablePadding: false, label: "Project" },
    { id: "Status", numeric: false, disablePadding: false, label: "Status" },
    { id: "Delta", numeric: false, disablePadding: false, label: "Delta $" },
]

const StyledTableMenu = styled(Menu)({
    "& .MuiPaper-elevation8": {
        boxShadow: "none",
        border: "1px solid #d2d1d1",
        overflow: 'visible',
        marginTop: '54px',
        transform: 'translate(-33%, -2%) !important',
        '&::before': {
            content: '" "',
            width: '0',
            height: '0',
            position: 'absolute',
            borderBottom: 'solid 12px #dbdbdb',
            borderLeft: 'solid 11px transparent',
            borderRight: 'solid 12px transparent',
            top: '-12px',
            left: '31px',
        },
        '&::after': {
            content: '" "',
            position: 'absolute',
            width: '0',
            height: '0',
            borderBottom: 'solid 9px white',
            borderLeft: 'solid 9px transparent',
            borderRight: 'solid 10px transparent',
            top: '-9px',
            left: '33px',
        }
    }
});

export const RowTableItemMenu = (props: any) => {
    const { anchorEl, handleOpenMenu, handleClickCloseMenu, handleDocOpen, quoteId, invoice, handleDelete, pdfOpen, path } = props;
    return (
        <div>
            <MoreVertIcon data-testid="iconId" onClick={(event: any) => handleOpenMenu(event, quoteId, invoice)} />
            <StyledTableMenu
                data-testid="styledTableMenuId"
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClickCloseMenu}
            >
                <MenuItem data-testid="TableMenuOptionsOpenerId" onClick={(event: any) =>
                    handleDocOpen(path, event)}><DocumentOpener isOpen={false} pdfUrl={pdfOpen} navigation={undefined} id={""} /></MenuItem>

                <MenuItem onClick={(event: any) => handleDelete(event)} data-testid="TableMenuOptionsId">Delete</MenuItem>
            </StyledTableMenu>
        </div>
    );
};

const useTableToolbarStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(1),
            justifyContent: 'space-between'
        },
        highlight: {},
        title: {
            flex: "1 1 100%",
            fontSize: "20px",
            color: "#05052b",
            fontWeight: 500,
            fontFamily: "Poppins",
            cursor: "pointer"
        },
        reportButton: {
            marginRight: '10px'
        },
        titleNavlink: {
            textDecoration: 'none',
            '&:focus, &:hover, &:visited, &:link, &:active': {
                textDecoration: 'none'
            }
        }
    })
);

const StyledTableIconButton = styled(IconButton)({
    "&.MuiIconButton-root": {
        color: "#05052b",
        fontWeight: "400",
        fontFamily: "Poppins",
        height: "32px",
        borderRadius: " 5px",
        border: "1px solid #05052b"
    },
    "&.MuiIconButton-root p": {
        fontSize: "14px",
        marginLeft: "5px"
    }
});

export const StyledTableRowItems = styled(TableRow)({
    "&.MuiTableRow-root.Mui-selected": {
        backgroundColor: "white"
    }
});

const StyledTableCheckboxItem = styled(Checkbox)({
    "&.MuiCheckbox-colorSecondary.Mui-checked": {
        color: "black"
    }
});

const StyledHeadTable = styled(TableHead)({
    "&.MuiTableHead-root": {
        backgroundColor: "#f4f7fa"
    }
});

const StyledAddInvoiceButton = styled(Button)({
    "&.MuiButton-root": {
        borderRadius: "8px",
        backgroundColor: "#df802d",
        border: "1px solid #df802d",
        color: "white",
        textTransform: "capitalize",
        fontWeight: "normal"
    }
});

const StyledAddedInvoiceButton = styled(Button)({
    "&.MuiButton-root": {
        cursor: 'alias',
        color: "#df802d",
        textTransform: 'none'
    }
});

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: "100%",
        },
        pag: {
            display: 'flex',
            justifyContent: 'flex-end',
        },

        tableColor: {
            "& .MuiSvgIcon-root": {
                color: "orange",
                backgroundColor: 'bisque',
                padding: 1
            }
        },

        record: {
            display: 'flex',
            justifyContent: 'flex-end',
            flexGrow: .9,
            paddingLeft: 10,
        },

        paginated: {
            "& .MuiPagination-ul": {
                justifyContent: 'flex-end',
            },
            "& .MuiPaginationItem-page.Mui-selected": {
                backgroundColor: "orange",

                borderRadius: 12,
                color: "blue"
            },
            "& .MuiPaginationItem-icon": {
                backgroundColor: "orange",
                padding: 1,
                borderRadius: 2
            }
        },
        paper: {
            "&.MuiPaper-elevation1": {
                boxShadow: "none"
            },
            width: "100%",
            marginBottom: theme.spacing(2),
            filter: "drop-shadow(9.899px 9.899px 32px rgba(150,184,189,0.11))",
            backgroundColor: "#ffffff",
            border: "0px solid #979797",
            borderRadius: "16px"
        },
        tableContainer: { height: "365px", overflow: "scroll" },
        table: {
            minWidth: "500px"
        },

        styledTableBody: {
            "& .MuiTableCell-root .MuiTableCell-body": {
                width: "10px"
            }
        },
        visuallyHidden: {
            border: 0,
            clip: "rect(0 0 0 0)",
            height: 1,
            margin: -1,
            overflow: "hidden",
            padding: 0,
            position: "absolute",
            top: 20,
            width: 1
        },
        noRecordText: {
            margin: 'auto',
        },


    })
);

export const TableCheckBoxIcon = () => {
    return <img data-testid="tableCheckBoxId" src={checkboxIcon} alt="" />;
};

export const TableSortIcon = () => {
    return <img data-testid="sortIconId" style={{ marginLeft: "10px" }} src={sortIcon} alt="" />;
};

const dashboarTableReportHeadCells = (path: any) => {
    return path.includes("/report") ? dashboardReportHeadCells : tableHeadCells
}

const dashboardTableInvoiceHeadCell = (path: any) => {
    return path.includes("/invoice") ? dashboardInvoiceHeadCells : dashboarTableReportHeadCells(path)
}

const dashboardTableHeadCell = (path: string | string[], hasStatus: boolean | undefined, handleSort: any) => {
    return (
        <>
            {
                path === "/quotes" ?
                    <>
                        {(path.includes('/quotes') && hasStatus ? quoteHeadCells : tableHeadCells).map((headCell, index) => (
                            <TableCell key={headCell.id} align={"center"} padding={"normal"}>
                                <TableSortLabel data-testid={`sortDataId${index}`} onClick={() => handleSort(headCell.id)} IconComponent={index !== 0 ? TableSortIcon : undefined}>
                                    {headCell.label}
                                </TableSortLabel>
                            </TableCell>
                        ))}
                    </>
                    :
                    <>
                        {(path.includes('/quotescomparison') || path.includes('/document/comparison') ? quoteComparisonHeadCells : dashboardTableInvoiceHeadCell(path)).map((quotehead, index) => {
                            return (
                                <TableCell key={quotehead.id} align={"center"} padding={"normal"}>
                                    <TableSortLabel data-testid={`sortDataId${index}`} onClick={() => handleSort(quotehead.id)} IconComponent={index !== 0 ? TableSortIcon : undefined}>
                                        {quotehead.label}
                                    </TableSortLabel>
                                </TableCell>
                            )
                        })}
                    </>
            }
        </>
    )
}

export const EnhancedTableHead = (props: EnhancedTableHeadProps) => {
    const { onSelectAll, numSelected, rowCount, handleSort, path, hasStatus, hasMenu, hasDelta } = props;
    return (
        <StyledHeadTable>
            <TableRow>
                <TableCell align="center" padding="checkbox">
                    <StyledTableCheckboxItem
                        icon={<TableCheckBoxIcon />}
                        indeterminate={numSelected > 0 && numSelected < rowCount}
                        checked={rowCount > 0 && numSelected === rowCount}
                        onChange={onSelectAll}
                        inputProps={{ "aria-label": "select all desserts" }}
                    />
                </TableCell>
                {
                    path === "/sorting" || path === "/sorting/Reports" ?
                        <>
                            {(hasDelta ? libraryHeadCells : tableHeadCells).map((headCell, index) => (
                                <TableCell key={headCell.id} align={"center"} padding={"normal"}>
                                    <TableSortLabel data-testid={`sortDataId${index}`} onClick={() => handleSort(headCell.id)} IconComponent={index !== 0 ? TableSortIcon : undefined}>
                                        {headCell.label}
                                    </TableSortLabel>
                                </TableCell>
                            ))}
                        </> :
                        dashboardTableHeadCell(path, hasStatus, handleSort)
                }
                {hasMenu && <><TableCell></TableCell></>}
            </TableRow>
        </StyledHeadTable>
    );
};

export const EnhancedTableToolbar = (props: EnhancedTableToolbarProps) => {
    const classes = useTableToolbarStyles();
    const { numSelected, path, handleReportCick, handleExportCick, navigatePath, handleNavigation } = props;
    const pathName = window.location.pathname;
    const navigateTo = navigatePath == undefined ? "" : navigatePath

    return (
        <Toolbar
            className={clsx(classes.root, {
                [classes.highlight]: numSelected > 0
            })}
        >
            <NavLink
                to={navigateTo}
                data-testid="NavlinkId"
                onClick={e => handleNavigation(e, pathName, navigatePath)}
                className={classes.titleNavlink}
                activeStyle={{ textDecoration: 'none' }}
            >
                <Typography
                    data-testid="TableTitleTextId"
                    className={classes.title}
                    variant="h6"
                    id="tableTitle"
                    component="div"
                >
                    {props.title}
                </Typography>
            </NavLink>
            <Box>
                {
                    path === "/sorting" && numSelected > 0 ||
                        path === "/sorting/projects" && numSelected > 0 ||
                        path === "/sorting/archive" && numSelected > 0 ||
                        path === "/sorting/Reports" && numSelected > 0
                        ? (
                            <Tooltip title="Report list" className={classes.reportButton}>
                                <StyledTableIconButton data-testid="ReportGenBtnId" aria-label="Report list" onClick={handleReportCick}>
                                    <img src={exportIcon} alt="" /> <p>Report Generation</p>
                                </StyledTableIconButton>
                            </Tooltip>
                        ) : null
                }
                <Tooltip title="Export list">
                    <StyledTableIconButton aria-label="Export list" onClick={handleExportCick}>
                        <img src={exportIcon} alt="" /> <p> Export Data</p>
                    </StyledTableIconButton>
                </Tooltip>
            </Box>
        </Toolbar >

    );
};

export const HandleStatus = ({ status }: any) => {
    return (
        <>
            {status === "pending" ?
                <Button style={webStyle.pendingBox} >{status}</Button> :
                <Button style={webStyle.processingBox} >{status}</Button>
            }
        </>
    )
}

export const HandleRowStatus = ({ status }: any) => {
    return (
        <>
            {status === "completed" ?
                <Button style={webStyle.completedBox}>{status}</Button> :
                <HandleStatus status={status} />
            }
        </>
    )
}

export const HandlePriceStatus = ({ status }: any) => {
    return (
        <>
            {status && status.length > 0 ?
                <div style={webStyle.tableMenuWrapper}>
                    <HandleRowStatus status={status} />
                </div> :
                <div style={webStyle.styleInvoicePrice}>
                    -
                </div>
            }
        </>
    )
}

export const InvoicePriceData = ({ hasInvoicePrice, totalPrice, status }: any) => {
    return (
        <>
            {hasInvoicePrice &&
                <>
                    <TableCell align="center">
                        <div style={webStyle.styleInvoicePrice}>
                            {totalPrice !== null ? `${totalPrice}$` : "-"}
                        </div>
                    </TableCell>
                    <TableCell align="center">
                        <HandlePriceStatus status={status} />
                    </TableCell>
                </>
            }
        </>
    )
}

export const StatusData = ({ hasStatusData, status }: any) => {
    return (
        <>
            {hasStatusData &&
                <>
                    <TableCell>
                        <div style={webStyle.tableMenuWrapper}>
                            <HandleRowStatus data-testid="rowStatusId" status={status} />
                        </div>
                    </TableCell>
                    <TableCell align="center">
                        <div style={webStyle.tableMenuWrapper}>
                            {
                                status === 'completed' ? (
                                    <span style={webStyle.approveBox} />
                                ) : (
                                    <span style={webStyle.rejectBox} />
                                )
                            }
                        </div>
                    </TableCell>
                </>
            }
        </>
    )
}

export const EnhancedTableData = (props: EnhancedTableDataProps) => {
    const classes = useStyles();
    const {
        hasMenu,
        title,
        rows,
        selected,
        // currentPage,
        // cellsPerPage,
        // totalPages,
        handleRowItemClick,
        handleOpenMenu,
        handleClickCloseMenu,
        handleClickChangePage,
        handleChangePerPage,
        pdfOpen,
        page,
        rowsPerPage,
        anchorEl,
        handleSelectAll,
        handleSort,
        path,
        hasDelta,
        handleExportCick,
        openModal,
        hasStatus,
        hasStatusData,
        handleOnClick,
        handleDelete,
        handleDocOpen,
        navigatePath,
        hasInvoicePrice,
        handleNavigation
    } = props;
    const isSelected: any = (name: string) => props.selected.indexOf(name) !== -1;
    return (
        <div className={classes.root}>

            <Paper className={classes.paper}>
                <EnhancedTableToolbar {...props} navigatePath={navigatePath} path={path} title={title} numSelected={selected.length} handleExportCick={handleExportCick} openModal={openModal} handleNavigation={handleNavigation} />
                {
                    rows.length > 0 ?
                        <TableContainer className={classes.tableContainer}>
                            <Table
                                className={classes.table}
                                aria-labelledby="tableTitle"
                                size={"medium"}
                                aria-label="enhanced table"
                            >
                                <EnhancedTableHead
                                    rowCount={rows.length}
                                    numSelected={selected.length}
                                    onSelectAll={handleSelectAll}
                                    handleSort={handleSort}
                                    path={path}
                                    hasDelta={hasDelta}
                                    hasStatus={hasStatus}
                                    hasMenu={hasMenu}
                                />
                                <TableBody data-testid="tableBodyId" className={classes.styledTableBody}>
                                    {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                        .map((row: any, index: any) => {
                                            const isItemSelected = isSelected(row);
                                            const labelId = `enhanced-table-checkbox-${index}`;
                                            return (
                                                <StyledTableRowItems
                                                    hover
                                                    data-testid={`rowItemClickId${index}`}
                                                    onClick={(event: any) => handleRowItemClick(event, row)}
                                                    role="checkbox"
                                                    aria-checked={isItemSelected}
                                                    tabIndex={-1}
                                                    key={index}
                                                    selected={isItemSelected}
                                                >
                                                    <TableCell align="center" padding="checkbox">
                                                        <StyledTableCheckboxItem
                                                            icon={<TableCheckBoxIcon />}
                                                            checked={isItemSelected}
                                                            inputProps={{ "aria-labelledby": labelId }}
                                                        />
                                                    </TableCell>

                                                    <TableCell
                                                        id={labelId}
                                                        scope="row"
                                                        padding="none"
                                                        align="center"
                                                    >
                                                        {row.quote_id || row.report_id || "-"}
                                                    </TableCell>
                                                    <TableCell align="center">{moment(row.date || row.created_at).format("D MMM YYYY")}</TableCell>
                                                    <TableCell align="center">{row.vendor || '-'}</TableCell>
                                                    <TableCell align="center">{row.project || "-"}</TableCell>
                                                    {hasDelta &&
                                                        <TableCell align="center">
                                                            <div style={webStyle.tableMenuWrapper}>
                                                                {
                                                                    row.status === 'completed' ? (
                                                                        <span style={webStyle.approveBox} />
                                                                    ) : (
                                                                        <span style={webStyle.rejectBox} />
                                                                    )}
                                                            </div>
                                                        </TableCell>
                                                    }
                                                    <StatusData hasStatusData={hasStatusData} status={row.status} />

                                                    {hasStatus &&
                                                        <>
                                                            <TableCell align="center">
                                                                {row.status === 'processing' || row.status === 'completed' ?
                                                                    <StyledAddedInvoiceButton variant='text'>Added</StyledAddedInvoiceButton>
                                                                    :
                                                                    <StyledAddInvoiceButton data-testid={`addInvoiceId${index}`} onClick={(event: any) => handleOnClick(event, row.quote_id)}>Add</StyledAddInvoiceButton>
                                                                }
                                                            </TableCell>
                                                            <TableCell align="center">
                                                                <div style={webStyle.tableMenuWrapper}>
                                                                    <HandleRowStatus data-testid="rowStatusSecondId" status={row.status} />
                                                                </div>
                                                            </TableCell>
                                                        </>
                                                    }

                                                    <InvoicePriceData hasInvoicePrice={hasInvoicePrice} totalPrice={row.total_price} status={row.status} />
                                                    {hasMenu && (
                                                        <TableCell align="center">
                                                            <RowTableItemMenu
                                                                handleOpenMenu={handleOpenMenu}
                                                                anchorEl={anchorEl}
                                                                handleClickCloseMenu={handleClickCloseMenu}
                                                                data-testid="rowDataId"
                                                                path={path}
                                                                quoteId={row.id}
                                                                // key={row.quote_id}
                                                                pdfOpen={pdfOpen}
                                                                invoice={row.quote_id}
                                                                handleDelete={handleDelete}
                                                                handleDocOpen={handleDocOpen}
                                                            />
                                                        </TableCell>
                                                    )}
                                                </StyledTableRowItems>
                                            );
                                        })}
                                </TableBody>
                            </Table>

                        </TableContainer>
                        :
                        <Box id="noDataFound" sx={{ height: '365px', display: 'flex', borderTop: '2px solid #979797' }}>
                            <Typography component="h2" className={classes.noRecordText}>No records found</Typography>
                        </Box>
                }
                <TablePagination
                    rowsPerPageOptions={[5, 10, 15]}
                    className={classes.paginated}
                    count={rows.length}
                    component="div"
                    page={page}
                    onPageChange={handleClickChangePage}
                    rowsPerPage={rowsPerPage}
                    onRowsPerPageChange={handleChangePerPage}
                />
            </Paper >
        </div >
    );
};

export default class DashboardNewTable extends DashboardNewTableController {
    /**
     *
     */
    constructor(props: Props) {
        super(props);
    }

    path = window.location.pathname

    componentDidUpdate(prevProps: any, prevState: any): void {
        if (prevProps.rows !== this.props.rows) {
            const newRow = this.sortArray(this.props.rows)
            this.setState({ rows: newRow })
        }
    }


    render() {
        const { rows, selected, anchorEl, page, rowsPerPage, openModal, modalOpen, selectedItem, pdfUrl } = this.state;
        const { title, hasMenu, hasDelta, hasStatus, hasStatusData, handleOnClick, navigatePath, hasInvoicePrice } = this.props;

        return (
            <>
                <EnhancedTableData
                    {...this.props}
                    data-testid="tableDataId"
                    selected={selected}
                    rows={rows}
                    title={title}
                    handleRowItemClick={this.handleRowItemClick}
                    handleSelectAll={this.handleSelectAllItemClick}
                    handleOpenMenu={this.handleOpenMenu}
                    anchorEl={anchorEl}
                    pdfOpen={pdfUrl}
                    handleClickCloseMenu={this.handleClickCloseMenu}
                    handleDocOpen={this.handleDocOpen}
                    hasMenu={hasMenu}
                    handleClickChangePage={this.handleClickChangePage}
                    handleChangePerPage={this.handleChangePerPage}
                    page={page}
                    rowsPerPage={rowsPerPage}
                    handleSort={this.handleSort}
                    path={this.path}
                    hasDelta={hasDelta}
                    handleReportCick={this.handleReportCick}
                    handleExportCick={this.handleExportCick}
                    openModal={openModal}
                    modalOpen={modalOpen}
                    hasStatus={hasStatus}
                    hasStatusData={hasStatusData}
                    hasInvoicePrice={hasInvoicePrice}
                    selectedItem={selectedItem}
                    handleOnClick={handleOnClick}
                    navigatePath={navigatePath}
                    handleDelete={this.openDeleteDialog}
                    handleNavigation={this.handleNavigation}
                />

                <Modal
                    open={this.state.isDeleteModalOpen}
                    onClose={this.handleCloseDeleteDialog}
                >
                    <Box style={webStyle.deletemodalBox}>
                        <Box style={webStyle.closeIcon}>
                            <img src={closeIcon} alt="close" style={webStyle.closeIcondeleteBox} onClick={this.handleCloseDeleteDialog} />
                        </Box>
                        <Box style={webStyle.closeDeleteIcon}>
                            <img src={reportIcon1} alt="upload" style={webStyle.deleteImgBox} />
                        </Box>
                        <Typography style={webStyle.deleteModalTitle}>Are you sure you want to delete?</Typography>
                        <Box style={webStyle.closeDeleteIcon}>
                            <StyledButton onClick={this.handleCloseDeleteDialog}> No </StyledButton>
                            <StyledButton data-testid="deleteModalYesBtnId" onClick={() => this.handleDeleteDailogConfirm(title, this.path)}> Yes </StyledButton>
                        </Box>
                    </Box>
                </Modal>

                <Modal
                    open={openModal}
                    onClose={this.handleClose}
                >
                    <ExportModal
                        handleClose={this.handleClose}
                        openModal={this.state.openModal}
                        selectedItem={this.state.selected}
                    />
                </Modal>

                <Modal
                    data-testid="ReportModalId"
                    open={modalOpen}
                    onClose={this.handleReportClose}
                >
                    <ReportModal
                        modalOpen={this.state.modalOpen}
                        handleReportClose={this.handleReportClose}
                        selectedItem={this.state.selected}
                    />
                </Modal>
            </>
        );
    }
}

const StyledButton = styled(Button)({
    border: "1px solid #e0802e",
    width: '100px',
    padding: "15px 0px",
    textTransform: "unset" as "unset",
    color: "#e0802e",
    fontSize: "20px",
    lineHeight: "22px",
    margin: "0 20px",
    '&:hover': {
        background: "#e0802e",
        color: "#fff"
    }
});

const webStyle = {
    tableMenuWrapper: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    },
    styleInvoicePrice: {
        width: '100%'
    },
    approveBox: {
        width: "15px",
        height: "15px",
        borderRadius: "50%",
        background: "green",
    },
    rejectBox: {
        width: "15px",
        height: "15px",
        borderRadius: "50%",
        background: "red",
    },
    paginationBox: {
        display: "flex",
        justifyContent: "end",
        alignItems: "center",
        padding: "25px 15px",
    },
    recordContent: {
        paddingRight: '20px'
    },
    completedBox: {
        color: '#71a359',
        background: '#f2fcf5',
        border: '1px solid #c3cfc0',
        textTransform: 'capitalize' as 'capitalize',
        width: '100%'
    },
    pendingBox: {
        color: '#ad6052',
        background: '#fdf7f5',
        border: '1px solid #c0afb1',
        textTransform: 'capitalize' as 'capitalize',
        width: '100%'
    },
    processingBox: {
        color: '#daaf8b',
        background: '#fbf6f6',
        border: '1px solid #c6b4b2',
        textTransform: 'capitalize' as 'capitalize',
        width: '100%'
    },
    deletemodalBox: {
        padding: "28px 30px 50px",
        background: "#fff",
        width: "560px",
        position: "absolute" as "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        borderRadius: "10px",
    },
    deleteModalTitle: {
        fontSize: "20px",
        fontWeight: 500,
        color: "#000000",
        textAlign: "center" as "center",
        margin: "25px",
    },
    closeIcon: {
        textAlign: 'right' as 'right'
    },
    closeIcondeleteBox: {
        marginBottom: "10px",
        width: "35px",
        height: "35px"
    },
    closeDeleteIcon: {
        textAlign: "center" as "center",
    },
    deleteImgBox: {
        width: "100px",
        height: "100px"
    },
    hasDeltaBox: {
        display: 'flex',
        justifyContent: 'center'
    }
};

// Customizable Area End