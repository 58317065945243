// Customizable Area Start
import React from "react";
import { Box, Grid, Typography } from "@material-ui/core";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import {
  createTheme,
  withStyles,
} from "@material-ui/core/styles";
import ArchiveController, { Props, configJSON } from "./ArchiveController.web";
import DashboardNewTable from "../../landingpage/src/DashboardNewTable.web";
import Header from "../../landingpage/src/Header.web";
// Customizable Area End

// Customizable Area Start
const theme = createTheme({
  typography: {
    h6: {
      color: " #05052b",
      fontSize: "25px",
    },
  },
});
// Customizable Area End
class Archive extends ArchiveController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes, navigation, handleSaveData }: any = this.props;
    // Customizable Area End

    return (
      // Customizable Area Start
      <Box>
            <Header navigation={navigation} handleSaveData={handleSaveData} history={this.props.history}/>
            <Typography variant="h6" className={classes.archiveTitle}>
              <ChevronLeftIcon onClick={this.handleArchiveBackClick} data-testid="backClick"/>
              {configJSON.libraryContent}
            </Typography>
            <Grid container>
              <Grid
                item
                lg={12}
                md={12}
                sm={12}
                xs={12}
                className={classes.headerArchiveBox}
              >
                <DashboardNewTable
                  navigatePath="/sorting/archive"
                  rows={this.state.archiveInfo}
                  title="Archive"
                  hasMenu={true}
                />
              </Grid>
            </Grid>
        </Box>
      // Customizable Area End
    );
  }
}

// Customizable Area Start

export const archiveTableStyle = (theme: any) => ({
  headerArchiveBox: {
    "& .MuiTableContainer-root": {
      height: "50vh",
    },
    "& table": {
      height: "100%",
    },
  },
  archiveTitle:{
    display: 'flex',
    alignItems: 'center',
    marginLeft: '8px',
    padding: '20px 0',
    '& svg':{
      fontSize: '32px'
    }
  }
});

export { Archive }
export default withStyles(archiveTableStyle)(Archive);

// Customizable Area End
