import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

export interface Props {
  navigation:any
  handleSaveData:() => void;
  history: any;
}

interface SS {}

interface S {
  hasChangePassword: boolean
  oldPassword: string,
  newPassword:string,
  confirmPassword:string,
  name:string,
  email:string,
  token: string
  passwordError:string,
  newPasswordError: string,
  confirmPasswordError: string,
  nameError: string,
  initialName: string,
  isPasswordUpdated: boolean;
}
export const configJSON = require("./config");

export default class ProfileDetailWebController extends BlockComponent<
  Props,
  S,
  SS
> {
  getUserDetailsApiId: string = "";
  getResetPasswordApiId: string = "";
  updateUserDetailsAPIId: string = ""
  /**
   *
   */
  constructor(props: Props) {
    super(props);
    this.state = {
      hasChangePassword: false,
      oldPassword:'',
      newPassword:'',
      confirmPassword:'',
      name:'',
      email:'',
      token:'',
      passwordError:'',
      newPasswordError: '',
      confirmPasswordError: '',
      nameError: '',
      initialName: '',
      isPasswordUpdated: false,
    };
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
    ];
  runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages)
  }


  async componentDidMount() {
    const token = await document.cookie.split("; ").find((row) => row.startsWith("Token="))?.split("=")[1];
    token && this.setState({ token: token });
    this.getUserDetails()
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>, snapshot?: SS | undefined): void {
    const isChangePassword = localStorage.getItem('isChangePassword') === "true" ? true : false
    
    if(prevState.hasChangePassword !== isChangePassword){
      this.setState({ 
        hasChangePassword: isChangePassword, 
        confirmPassword: '', 
        oldPassword: '', 
        newPassword: '', 
        passwordError: '', 
        newPasswordError: '', 
        confirmPasswordError: '',
        nameError: '',
        name: this.state.initialName
      })
    }
  }

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId === this.getUserDetailsApiId) {
        this.getUserDetailResponseHandle(responseJson)
      }
      if(apiRequestCallId === this.getResetPasswordApiId) {
        this.getPasswordResetResponseHandle(responseJson)
      }
      if(apiRequestCallId === this.updateUserDetailsAPIId) {
        this.getUserUpdateResponseHandle(responseJson)
      }
    }
  }

  getUserDetails = async () => {
    const token = this.state.token;

    const header = {
      "Content-Type": configJSON.validationApiContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getUserDetailsApiId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getUserDetailsApiEndPoint}?token=${token}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }

  updateUserDetails = async () => {
    const token = this.state.token;

    const header = {
      "Content-Type": configJSON.validationApiContentType,
    };


    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.updateUserDetailsAPIId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getUserDetailsApiEndPoint}?token=${token}&name=${this.state.name}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.updateAccountApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }

  getPasswordUpdated = async () => {
    const {token} = this.state

    const data = {
        token: token,
        old_password : this.state.oldPassword,
        new_password : this.state.newPassword,
        confirm_password : this.state.confirmPassword,
        type: "email_account"
    }
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": token
    };

    const httpBody = {
      data: data,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getResetPasswordApiId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getPasswordUpdatedApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }


  handleChangePassword = () => {
    localStorage.setItem('isChangePassword', "true")
    this.setState({ hasChangePassword: true, confirmPassword: '', oldPassword: '', newPassword: '', passwordError: '' });
  };

  handleValidate = () => {
    const passwordValidate = this.state.newPassword.match(
      /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,15}$/
    )

    if(passwordValidate) {
      this.setState({ newPasswordError: ''})
      if(this.state.oldPassword !== this.state.newPassword) {
        if(this.state.newPassword === this.state.confirmPassword){
          this.setState({ confirmPasswordError: ''})
          this.getPasswordUpdated()
        } else {
        this.setState({ confirmPasswordError: "new password and confirm password doesn't match"})
        }
      } else {
        this.setState({ newPasswordError: "old and new password can't be same"})
      }
      
    } else {
      this.setState({ 
        newPasswordError: 'Please enter valid password. One uppercase, one lowercase, one special character and no spaces'
      })
    }
    
  }

  handleResetPassword = () => {
    
    if(this.state.hasChangePassword) {
      this.handleValidate()
    } else {
      if(this.state.name.trim() == ""){
        this.setState({ nameError: 'Name is invalid'})
      } else {
        this.setState({ nameError: ''})
        if(this.state.initialName !== this.state.name) {
          this.updateUserDetails()
        }
      } 
    }
  };

  handleNameChange = (name:string) => {
    this.setState({ name: name })
  }

  handleLogout = async() => {
    localStorage.clear()
    document.cookie = `Token=; expires=Thu, 18 Dec 2013 12:00:00 UTC"; path=/;`;
    document.cookie = `RefreshToken=; expires=Thu, 18 Dec 2013 12:00:00 UTC"; path=/;`;
    this.props.navigation.navigate('EmailAccountLoginWeb')
  }

  getUserDetailResponseHandle = async (responseJson: any) => {
    if (responseJson) {
      if (responseJson?.error) {
        console.log("responseJson?.error", responseJson.error)
      }
      else {
        this.setState({ name: responseJson.data.name, email: responseJson.data.email,  initialName: responseJson.data.name })
      }
    }
  }

  getPasswordResetResponseHandle = async (responseJson: any) => {
    if (responseJson) {
      if (responseJson?.errors) {
        this.setState({ passwordError: responseJson.errors})
      }
      else {
        localStorage.setItem('isChangePassword', "false")
        this.setState({ hasChangePassword: false, confirmPassword: '', oldPassword: '', newPassword: '', isPasswordUpdated: true });
      }
    }
  }

  getUserUpdateResponseHandle = async (responseJson: any) => {
    if (responseJson) {
      if (responseJson?.error) {
        console.log("responseJson?.error", responseJson.error)
      }
      else {
        localStorage.setItem('name', responseJson.data.name)
        this.setState({ name: responseJson.data.name, initialName: responseJson.data.name })
      }
    }
  }
  handleChangeTextField = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    this.setState({[event.target.name as "oldPassword"]: event.target.value })
  }

  handlePasswordUpdateModal = () => {
    this.setState({ isPasswordUpdated: false})
  }



}
