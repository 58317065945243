import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";

// Customizable Area Start
export const configJSON = require("./config");

export interface AddSegmentButtonProps {
  selected: string[],
  selectedData: string[],
  addNewSegmentHandler: () => void,
  classes: any,
  deleteOpenModal: () => void,
  deleteCloseModal: () => void
}

// Customizable Area End


export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  handleSaveData: (data: any) => void;
  classes: any;
  history: any;
  location: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  checkedB: boolean;
  activeTab: number;
  displayBox: boolean;
  textBox: boolean;
  selectAll: boolean | any;
  secoundTableSelectAll: boolean;
  textBox1: boolean;
  newOpenRow: boolean;
  showModal: boolean;
  tableRedBorder: boolean,
  showModalTable: boolean;
  editInvoicePage: any[];
  invoicepage: number;
  isModalOpen: boolean;
  invoicePerPageRowSecoundSegment: number;
  selectedItem: any;
  errorMessage: string;
  categoryValue: string;
  invoiceRowsPerPage: number;
  invoice_s_and_h_charges: number;
  invoiceTobeDeleted: any;
  draggedItems: any[];
  deletedItemIds: any[];
  boxItems: any[];
  selectedRows: any[];
  selectCheckBox: any[];
  checkBoxId: any[];
  records: any[];
  checkBoxIdSecound: any[];
  currentIndex: number,
  newRowAdd: any[];
  invoiceColumn: {
    id: string;
    label: string;
  }[],
  invoiceComparisonData: any[]
  invoiceDetailData: any;
  draggedItems11: any;
  secoundTableSelectedRows: any[];
  deleteRow: any;
  invoiceTabs: any;
  creteReportData: any,
  addCategory1: string;
  dragSubTotalValue: number,
  addCategory: string,
  subtotalValue: number,
  selectedData: any,
  selected: any,

  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class EditInvoiceController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  editInvoiceApiCallId: string = '';
  deleteInvoiceApiCallId: string = "";
  deleteRowInvoiceApiCallId: string = '';
  addNewItemInvoiceApiCallId: string = '';
  secoundTableAddNewItemInvoiceApiCallId: string = '';
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);


    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
    ];
    this.state = {
      activeTab: 0,
      displayBox: false,
      textBox: false,
      textBox1: false,
      checkedB: false,
      selectAll: false,
      secoundTableSelectAll: false,
      newOpenRow: false,
      showModal: false,
      showModalTable: false,
      tableRedBorder: false,
      isModalOpen: false,
      selectedItem: null,
      invoiceTobeDeleted: null,
      invoicePerPageRowSecoundSegment: 10,
      editInvoicePage: [],
      invoicepage: 1,
      categoryValue: "",
      errorMessage: "",
      invoiceRowsPerPage: 10,
      draggedItems: [],
      deletedItemIds: [],
      boxItems: [],
      checkBoxId: [],
      checkBoxIdSecound: [],
      selectedRows: [],
      newRowAdd: [],
      selectCheckBox: [],
      records: [],
      currentIndex: 0,
      invoice_s_and_h_charges: 0,
      invoiceColumn: [
        { id: 'Sr.no', label: 'Sr. No', },
        { id: 'description', label: 'Description', },
        { id: 'OrderQty.', label: 'Order Qty.', },
        { id: 'unit', label: 'Unit', },
        { id: 'unitPrice', label: 'Unit Price', },
        { id: 'tax', label: 'Tax(%)', },
        { id: 'Ext. Price', label: 'Ext. Price' }
      ],

      draggedItems11: [],
      invoiceDetailData: this.props.location?.state?.invoiceDetails,
      invoiceComparisonData: this.props.location?.state?.invoiceDetails,
      secoundTableSelectedRows: [],
      deleteRow: [],
      invoiceTabs: this.props.location?.state?.invoiceTab,
      creteReportData: this.props.location.state.createReportData,
      addCategory1: this.props.location.state.segmentName2 || "",
      dragSubTotalValue: this.props.location.state.segmentTotal2,
      addCategory: this.props.location.state?.segmentName1 || "",
      subtotalValue: this.props.location.state?.segmentTotal1 || 0,
      selectedData: [],
      selected: [],

    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }
  // Customizable Area Start

  async componentDidMount(): Promise<void> {
    const newData = this.props.location?.state?.invoiceDetails.map((element: any) => {

      return ({
        ...element,
        switch: false,
        addNewSegment: false,
        textfieldvalue: false,
        textfieldvalue1: false,
        boxData: [],
        paginationFirstTable: 1,
        errorMsg: "",
        draggedItems: []
      })
    });
    this.setState({ invoiceDetailData: newData }, () => {
      console.log("invoiceDetailData ", this.state.invoiceDetailData)
    });

  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      this.apiMultipleCall(apiRequestCallId, responseJson)
    }
    // Customizable Area End
  }
  // Customizable Area End

  // Customizable Area Start

  apiMultipleCall = (apiRequestCallId: string, responseJson: any) => {
    if (apiRequestCallId && responseJson) {
      this.editAPICallResponse(apiRequestCallId, responseJson)
    }
    if (apiRequestCallId && responseJson) {
      this.deleteApiCallResponce(apiRequestCallId, responseJson)
    }
    if (apiRequestCallId && responseJson) {
      this.deleteRowApiCallResponce(apiRequestCallId, responseJson)
    }
    if (apiRequestCallId && responseJson) {
      this.addNewItemApiCallResponse(apiRequestCallId, responseJson)
    }
    if (apiRequestCallId && responseJson) {
      this.secoundAddNewItemApiCallResponse(apiRequestCallId, responseJson)
    }
  }
  addNewItemApiCallResponse = (apiRequestCallId: string, firstSegmentResponseJson: any) => {
    const { invoiceComparisonData, invoiceRowsPerPage, editInvoicePage } = this.state
    const startIndex = ((editInvoicePage[this.state.activeTab] ? editInvoicePage[this.state.activeTab] : 1) - 1) * invoiceRowsPerPage;
    const endIndex = startIndex + invoiceRowsPerPage;
    const invoiceComparisonDataMetaData = invoiceComparisonData[this.state.activeTab]?.invoice_description

    if (apiRequestCallId === this.addNewItemInvoiceApiCallId) {
      if (firstSegmentResponseJson && !firstSegmentResponseJson.errors) {

        const newDescriptionData = {
          "id": firstSegmentResponseJson.data[0].attributes.id,
          "description": firstSegmentResponseJson.data[0].attributes.description,
          "ord_quantity": firstSegmentResponseJson.data[0].attributes.ord_quantity,
          "order_unit": firstSegmentResponseJson.data[0].attributes.order_unit,
          "price": firstSegmentResponseJson.data[0].attributes.price,
          "amount": firstSegmentResponseJson.data[0].attributes.amount,
          "report_invoice_id": firstSegmentResponseJson.data[0].attributes.report_invoice_id,
          "created_at": firstSegmentResponseJson.data[0].attributes.created_at,
          "updated_at": firstSegmentResponseJson.data[0].attributes.updated_at,
          "tax": firstSegmentResponseJson.data[0].attributes.tax,
          "invoice_category_name": firstSegmentResponseJson.data[0].attributes.invoice_category_name
        }

        const updatedData = [
          ...invoiceComparisonDataMetaData.slice(0, startIndex),
          newDescriptionData,
          ...invoiceComparisonDataMetaData.slice(startIndex, endIndex),
          ...invoiceComparisonDataMetaData.slice(endIndex),
        ];

        this.state.invoiceComparisonData[this.state.activeTab].invoice_description = updatedData
        this.setState({ invoiceComparisonData: this.state.invoiceComparisonData })
      }

    }
  }

  secoundAddNewItemApiCallResponse = (apiRequestCallId: string, secoundSegmentResponseJson: any) => {
    const { boxItems, invoicePerPageRowSecoundSegment, invoicepage } = this.state
    const startIndex = (invoicepage - 1) * invoicePerPageRowSecoundSegment;
    const endIndex = startIndex + invoicePerPageRowSecoundSegment;

    if (apiRequestCallId === this.secoundTableAddNewItemInvoiceApiCallId) {
      if (secoundSegmentResponseJson && !secoundSegmentResponseJson.errors) {

        const newDescriptionData = {
          "id": secoundSegmentResponseJson.data[0].attributes.id,
          "description": secoundSegmentResponseJson.data[0].attributes.description,
          "ord_quantity": secoundSegmentResponseJson.data[0].attributes.ord_quantity,
          "order_unit": secoundSegmentResponseJson.data[0].attributes.order_unit,
          "price": secoundSegmentResponseJson.data[0].attributes.price,
          "amount": secoundSegmentResponseJson.data[0].attributes.amount,
          "report_invoice_id": secoundSegmentResponseJson.data[0].attributes.report_invoice_id,
          "created_at": secoundSegmentResponseJson.data[0].attributes.created_at,
          "updated_at": secoundSegmentResponseJson.data[0].attributes.updated_at,
          "tax": secoundSegmentResponseJson.data[0].attributes.tax,
          "invoice_category_name": secoundSegmentResponseJson.data[0].attributes.invoice_category_name
        }

        const updatedData = [
          ...boxItems.slice(0, startIndex),
          newDescriptionData,
          ...boxItems.slice(startIndex, endIndex),
          ...boxItems.slice(endIndex),
        ];
        this.setState({ boxItems: updatedData })
      }
    }
  }



  editAPICallResponse = async (apiRequestCallId: string, responseJson: any) => {
    if (apiRequestCallId === this.editInvoiceApiCallId) {
      if (responseJson && !responseJson.errors) {
        const data = [
          {
            "message": responseJson?.message,
            "pdf_page_count": 1,
            "url": "test.pdf",
            "invoice": {
              "data": {
                "id": "1073",
                "type": "report_invoice",
                "attributes": responseJson?.updated_data
              }
            },
            "invoice_description": responseJson?.description
          }]
        this.props.history.push('/report/create-report', { updated_data: this.state.invoiceComparisonData, value: 3, CreatReportData: this.state.creteReportData, invoiceTab: this.state.invoiceTabs })
      }
    }
  }



  deleteApiCallResponce = (apiRequestCallId: string, responseJson: any) => {
    if (apiRequestCallId === this.deleteInvoiceApiCallId) {
      if (responseJson && !responseJson.errors) {
        this.state.invoiceComparisonData[this.state.activeTab].invoice_description = responseJson.json_description
        this.setState({ invoiceComparisonData: this.state.invoiceComparisonData, showModal: false })
      } else if (responseJson.errors) {
        this.setState({ showModal: false, selectAll: null })
      }
    }
  }

  deleteRowApiCallResponce = (apiRequestCallId: string, responseJson: any) => {
    if (apiRequestCallId === this.deleteRowInvoiceApiCallId) {
      if (responseJson && !responseJson.errors) {
        this.state.invoiceComparisonData[this.state.activeTab].invoice_description = responseJson.json_description
        this.setState({ invoiceComparisonData: this.state.invoiceComparisonData, isModalOpen: false })
      } else if (responseJson.errors) {
        this.setState({ isModalOpen: false })
      }
    }
  }

  editApiCall = () => {
    const { invoiceDetailData, invoiceComparisonData } = this.state
    let data = { 
      "invoice": invoiceDetailData[this.state.activeTab].invoice.data.attributes, 
      "invoice_descriptions": invoiceComparisonData[this.state.activeTab].invoice_description 
    }
    const header = {
      "Content-Type": 'application/json',
      "token": document.cookie.split("; ").find((row) => row.startsWith("Token="))?.split("=")[1]
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.editInvoiceApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.invoiceEditApi}${this.state.invoiceDetailData[this.state.activeTab].invoice.data.attributes.report_id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(data)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'PUT'
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;

  }

  handleNewOpenRow = () => {
    const reportItem = this.state.invoiceComparisonData?.[this.state.activeTab]?.invoice_description.map((item: any) => {
      return item.report_invoice_id
    });

    const header = {
      "token": document.cookie.split("; ").find((row) => row.startsWith("Token="))?.split("=")[1],
      redirect: 'follow'
    };

    let firstSagmentFormData = new FormData();

    firstSagmentFormData.append('report_invoice_id', reportItem[reportItem.length - 1]);
    firstSagmentFormData.append('description[]', ' ');
    firstSagmentFormData.append('ord_quantity[]', ' ');
    firstSagmentFormData.append('order_unit[]', ' ');
    firstSagmentFormData.append('price[]', ' ');
    firstSagmentFormData.append('amount[]', ' ');
    firstSagmentFormData.append('tax[]', ' ');
    firstSagmentFormData.append('part_number[]', ' ');

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.addNewItemInvoiceApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.invoiceAddNewItemAPI
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      firstSagmentFormData
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }

  secoundTableNewOpenRow = () => {
    const reportItem = this.state.boxItems.map((boxItems: any) => {
      return boxItems.report_invoice_id
    });
    const header = {
      "token": document.cookie.split("; ").find((row) => row.startsWith("Token="))?.split("=")[1],
      redirect: 'follow'
    };
    let secoundSagmentFormData = new FormData();
    secoundSagmentFormData.append('report_invoice_id', reportItem[reportItem.length - 1]);
    secoundSagmentFormData.append('description[]', ' ');
    secoundSagmentFormData.append('price[]', ' ');
    secoundSagmentFormData.append('amount[]', ' ');
    secoundSagmentFormData.append('order_unit[]', ' ');
    secoundSagmentFormData.append('ord_quantity[]', ' ');
    secoundSagmentFormData.append('tax[]', ' ');
    secoundSagmentFormData.append('part_number[]', ' ');

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.secoundTableAddNewItemInvoiceApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.invoiceAddNewItemAPI
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      secoundSagmentFormData
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostType
    );


    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }

  handleDeleteSelectedRows11 = () => {
    this.setState({ showModal: true });
  };

  showModelClose = () => {
    this.setState({ showModal: false });
  };

  handleDeleteSelectedRows = () => {
    const header = {
      "Content-Type": 'application/json',
      "token": document.cookie.split("; ").find((row) => row.startsWith("Token="))?.split("=")[1]
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.deleteInvoiceApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.invoiceDeleteApi}[${this.state.checkBoxId}]`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'DELETE'
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };

  handleCloseModal = () => {
    this.setState({ isModalOpen: false, selectedItem: null });
  };


  handleCancleButton = () => {
    this.setState({ showModal: false })
  };

  handleDeleteSelectedRowsTable = (item: any) => {
    this.setState({ isModalOpen: true, selectedItem: item });
  };

  handleConfirmDelete = () => {
    const header = {
      "Content-Type": 'application/json',
      "token": document.cookie.split("; ").find((row) => row.startsWith("Token="))?.split("=")[1]
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.deleteRowInvoiceApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.invoiceDeleteApi}[${this.state.selectedItem?.id}]`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'DELETE'
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    
    return true;
  };

  handleChangeTab = (e: any, newValue: any) => {
    this.setState({ activeTab: newValue });
  };

  handleAddCircleButton = async() => {
  
     let lstTmp = [...this.state.invoiceDetailData];
    let objImp = { ...lstTmp[this.state.activeTab], addNewSegment: true ,textBox1: false, displayBox: true };
    lstTmp[this.state.activeTab] = objImp;
    
    this.setState({ invoiceDetailData: lstTmp,textBox1: false, displayBox: true,} )

    if (this.state.displayBox && !this.state.textBox) {
      this.setState({ errorMessage: "Segment/Category is required" });
      return;
    }
    this.setState({ errorMessage: "" });

    this.setState({ displayBox: true });

    if (this.state.textBox) {
            let lstTmp = [...this.state.invoiceDetailData];
      let objImp = { ...lstTmp[this.state.activeTab], textfieldvalue: true, textfieldvalue1: true ,  textBox1: false  , addNewSegment: true};
      lstTmp[this.state.activeTab] = objImp;
      
      this.setState({ textBox1: true,invoiceDetailData: lstTmp});
      this.setState({ textBox: false });
    }
  }

  handleDeleteIconFirstTextField = () => {
    let lstTmp = [...this.state.invoiceDetailData];
    lstTmp[this.state.activeTab]= { ...lstTmp[this.state.activeTab], textfieldvalue: true, textfieldvalue1: true ,  textBox1: false ,displayBox: false}
    this.setState({ displayBox: false, textBox: false , invoiceDetailData: lstTmp});
  }

  handleSecounTextField = () => {
    const { boxItems, invoiceComparisonData } = this.state;
    const invoiceMetaComparisonData = invoiceComparisonData[this.state.activeTab].invoice_description
    this.state.invoiceComparisonData[this.state.activeTab].invoice_description = [...boxItems, ...invoiceMetaComparisonData]
    let allItem = [...invoiceComparisonData]

    allItem[this.state.activeTab] = { ...allItem[this.state.activeTab], draggedItems: [], invoice_description: [...allItem[this.state.activeTab].invoice_description] , textBox1: false}
    this.setState({ textBox1: false, invoiceComparisonData: [...allItem], boxItems: [] });
  };

  handleCategory = (e: any) => {
    const enteredValue = e.target.value;
    const allItem =[...this.state.invoiceComparisonData]
    let objImp = { ...allItem[this.state.activeTab], searchValue:e.target.value };
    allItem[this.state.activeTab] = objImp;
    
    if (enteredValue.length > 0) {
      this.setState({ textBox: true, errorMessage: "", categoryValue: enteredValue , invoiceComparisonData:allItem });
    } else {
      let textboxobj = [...this.state.invoiceComparisonData]
       textboxobj[this.state.activeTab] = {...textboxobj[this.state.activeTab] , textBox1: false}
      this.setState({ textBox: false, errorMessage: "Segment/Category is required", textBox1: false , invoiceComparisonData:textboxobj} );
    }
  }

  calculateFirstTotalPrice = () => {
    const { invoiceComparisonData } = this.state;
    const total = invoiceComparisonData?.[this.state.activeTab]?.invoice_description.reduce((acc: number, item: any) => acc + Number(item?.amount), 0);
    
    return total?.toFixed(2);
  };
 


  calculateSecoundTotalPrice = () => {
    const total = (this.state.invoiceComparisonData[this.state.activeTab]?.draggedItems || [])?.reduce((acc: any, item: any) => acc + Number(item?.amount), 0);
    return total?.toFixed(2);
  };

  calculateCombinedTotal = () => {
    const total1 = this.calculateFirstTotalPrice();
    const total2 = this.calculateSecoundTotalPrice();
    const combinedTotal = (Number(total1) + Number(total2)).toFixed(2);

    return combinedTotal;
  };

  handleTextFieldChange = (value: any) => {
    const invoiceMetaData = this.state.invoiceDetailData[this.state.activeTab].invoice.data.attributes
    const updateData = { ...invoiceMetaData, [value.target.name]: value.target.value }
    this.state.invoiceDetailData[this.state.activeTab].invoice.data.attributes = updateData
    this.setState({ invoiceDetailData: this.state.invoiceDetailData });
  };


  calculateTaxTotal = (item: any) => {
    const total = this.allTotal(item);
    const total1 = this.calculateFirstTotalPrice();
    const total2 = this.calculateSecoundTotalPrice();
    const tax = Number(item?.invoice_tax_percent || 0);

    const combinedTotal = (parseFloat(total1) + parseFloat(total2)).toFixed(2);
    const taxAmount = (parseFloat(combinedTotal) * tax) / 100;

    return taxAmount;
  };

  allTotal = (item: any) => {
    const combinedTotal = Number(this.calculateCombinedTotal());
    const sAndHCharges = Number(item?.invoice_s_and_h_charges || 0);
    const otherCharges = Number(item?.invoice_other_charges || 0);
    const discount = Number(item?.discount || 0);
    const tax = Number(item?.invoice_tax_percent || 0);

    const total = Number((combinedTotal + sAndHCharges + otherCharges - discount).toFixed(2));
    const taxAmount = (total * tax) / 100;

    return taxAmount + total;
  };

  editableFirstTable = (e: any, itemId: any, field: any, index?: any) => {
    const { invoiceComparisonData, activeTab } = this.state;
    const invoiceMetaComparisonData = invoiceComparisonData[activeTab].invoice_description;

    let tableData = invoiceMetaComparisonData[index];
    let updateObj = { ...tableData, [field]: e.target.value };
    invoiceComparisonData[this.state.activeTab].invoice_description[index] = updateObj;

    if (field !== 'amount') {
      const calculatedAmount = this.calculateAmount(updateObj);
      invoiceComparisonData[this.state.activeTab].invoice_description[index].amount = calculatedAmount;
    }
    this.setState({ invoiceComparisonData: invoiceComparisonData });
  };

  editableSecoundTable = (e: any, itemId: any, field: any) => {
    const { invoiceComparisonData } = this.state;
    const updatedDataSecoundTable = (invoiceComparisonData[this.state.activeTab]?.draggedItems || []).map((boxItem: any) => {
      if (boxItem.id === itemId) {
        const updatedItemSecoundTable = { ...boxItem, [field]: e.target.value };

        if (field !== 'amount') {
          updatedItemSecoundTable.amount = this.calculateAmount(updatedItemSecoundTable);
        }
        return updatedItemSecoundTable;
      }
      return boxItem;
    });
    const updatedInvoiceComparisonData = { ...invoiceComparisonData };
    updatedInvoiceComparisonData[this.state.activeTab].draggedItems = updatedDataSecoundTable;
    this.setState({ invoiceComparisonData: updatedInvoiceComparisonData });
  };


  calculateAmount = (item: { ord_quantity: number; price: number; tax: number }) => {
    const priceBeforeTax = item.ord_quantity * item.price;
    const taxAmount = (priceBeforeTax * item.tax) / 100;

    return (priceBeforeTax + taxAmount).toFixed(2);
  };


  switchIOS = (e: any) => {
    const isChecked = e.target.checked;

    let lstTmp = [...this.state.invoiceDetailData];
    let objImp = { ...lstTmp[this.state.activeTab], switch: isChecked };
    lstTmp[this.state.activeTab] = objImp;

    this.setState({ invoiceDetailData: lstTmp })
  };

  handleSelectAllFirstTable = () => {
    const { invoiceComparisonData, selectAll } = this.state;
    const newSelectedFirst = selectAll ? [] : [...invoiceComparisonData?.[this.state.activeTab]?.invoice_description];
    const allIds = newSelectedFirst.map((item) => item.id);

    this.setState({
      selectedRows: newSelectedFirst,
      selectAll: !selectAll,
      checkBoxId: allIds,
    });
  };

  handleCheckboxChange = (event: any, item: any) => {
    const { selectedRows ,invoiceComparisonData} = this.state;
    let allItem = [...invoiceComparisonData]
    if (event.target.checked) {
      const newSelected = [...selectedRows, item];
      const allIds = newSelected.map((selectedItem) => selectedItem.id);
      let objImp = { ...allItem[this.state.activeTab], selectedRows:newSelected };
      allItem[this.state.activeTab] = objImp;
      console.log("allItem--->",allItem);
      this.setState({ selectedRows: newSelected, checkBoxId: allIds , invoiceComparisonData:allItem});
    } else {
      const newSelected = selectedRows.filter((selectedRow) => selectedRow !== item);
      const allIds = newSelected.map((selectedItem) => selectedItem.id);
      let objImp = { ...allItem[this.state.activeTab], selectedRows:newSelected };
      allItem[this.state.activeTab] = objImp;
      console.log("allItem--->" , allItem)
      this.setState({ selectedRows: newSelected, checkBoxId: allIds , invoiceComparisonData:allItem});
    }
  };


  handleSelectAllSecoundTable = (e: any) => {
    const { boxItems, secoundTableSelectAll } = this.state;
    const newSelectedSecound = secoundTableSelectAll ? [] : [...boxItems];
    const allIdssecound = newSelectedSecound.map((boxItems) => boxItems.id)

    this.setState({
      secoundTableSelectedRows: newSelectedSecound,
      secoundTableSelectAll: !secoundTableSelectAll,
      checkBoxIdSecound: allIdssecound

    })
  };

  handleCheckboxChangeSecond = (event: any, draggedItems: any) => {
    const { secoundTableSelectedRows } = this.state;

    if (event.target.checked) {
      const newSelectedSecound = [...secoundTableSelectedRows, draggedItems];
      const allIdssecound = newSelectedSecound.map((selectedItem) => selectedItem.id);

      this.setState({ secoundTableSelectedRows: newSelectedSecound, checkBoxIdSecound: allIdssecound });
    } else {
      const newSelectedSecound = secoundTableSelectedRows.filter((secoundTableSelectedRow) => secoundTableSelectedRow !== draggedItems);
      const allIdssecound = newSelectedSecound.map((selectedItem) => selectedItem.id);

      this.setState({ secoundTableSelectedRows: newSelectedSecound, checkBoxIdSecound: allIdssecound });
    }
  };

  handleDragOverFirst = (event: any) => {
    event.preventDefault();
  };

  handleDropFirst = (event: any) => {
    event.preventDefault();
    const { draggedItems11, invoiceComparisonData, boxItems, secoundTableSelectedRows } = this.state;
    const invoiceComparisonDataMetaData = invoiceComparisonData[this.state.activeTab]?.invoice_description

    if (draggedItems11.length > 0) {
      let allItem = [...invoiceComparisonData]
      const newSecondTable = allItem[this.state.activeTab]?.draggedItems?.filter((item: any) => !draggedItems11.includes(item))
      const firstTable = this.state

      allItem[this.state.activeTab] = { ...allItem[this.state.activeTab], draggedItems: newSecondTable, invoice_description: [...allItem[this.state.activeTab].invoice_description, ...draggedItems11] }

      const updatedRows = boxItems.filter(
        (row) => !secoundTableSelectedRows.includes(row),
      );
      this.state.invoiceComparisonData[this.state.activeTab].invoice_description = [...invoiceComparisonDataMetaData, ...draggedItems11]
      this.setState({
        invoiceComparisonData: [...allItem],
        draggedItems11: [],
        boxItems: updatedRows,
        secoundTableSelectedRows: [],
      });
    }
  };


  handleDragStart = (event: any, item: any) => {
    event.dataTransfer.effectAllowed = "move";
    if (this.state.selectedRows.includes(item)) {
      this.setState({
        draggedItems: [...this.state.selectedRows],
      });
    } else {
      event.preventDefault();
    }
  };

  handleDragOverSecond = (event: any) => {
    event.preventDefault();
  };

  handleDragStartSecond = (event: any, item: any) => {
    event.dataTransfer.effectAllowed = "move";

    if (this.state.secoundTableSelectedRows.includes(item)) {
      this.setState({
        draggedItems11: [...this.state.secoundTableSelectedRows],
      });
    } else {
      event.preventDefault();
    }
  };

  handleDropSecond = (event: any) => {
    event.preventDefault();
    const { draggedItems, invoiceComparisonData, boxItems, selectedRows } = this.state;

    if (draggedItems.length > 0) {
      let allItem = [...invoiceComparisonData]
      const prev = allItem[this.state.activeTab]?.draggedItems ? allItem[this.state.activeTab]?.draggedItems : []
      const newFirstTable = allItem[this.state.activeTab]?.invoice_description.filter((item: any) => !draggedItems.includes(item))
      allItem[this.state.activeTab] = { ...allItem[this.state.activeTab], draggedItems: [...prev, ...draggedItems], invoice_description: newFirstTable }

      const updatedRows = invoiceComparisonData[this.state.activeTab]?.invoice_description.filter((row: any) => !selectedRows.includes(row));
      this.state.invoiceComparisonData[this.state.activeTab].invoice_description = updatedRows
      let data: any = [];

      let lstTmp = [...this.state.invoiceDetailData];
      let objImp = { ...lstTmp[this.state.activeTab], boxData: [...boxItems, ...draggedItems] };
      lstTmp[this.state.activeTab] = objImp;
      this.setState({ invoiceDetailData: lstTmp })

      this.setState({
        boxItems: [...boxItems, ...draggedItems],
        draggedItems: [],
        invoiceComparisonData: [...allItem],
        selectedRows: []
      });

    }
  };

  handleChangeinvoicePage = (event: unknown, newPage: number) => {
    let newEditInvoicePage = [...this.state.editInvoicePage]
    newEditInvoicePage[this.state.activeTab] = newPage

    this.setState({
      editInvoicePage: [...newEditInvoicePage], 
    });
  };

  handleChangeinvoiceTablePage = (event: unknown, invoiceNewPage: number) => {
    this.setState({ invoicepage: invoiceNewPage })
  };

  handleCancelButton = () => {
    this.props.history.push('/report/create-report', { updated_data: this.props.location.state.invoiceDetails, value: 3, description: this.props.location.state.invoiceDescription, CreatReportData: this.state.creteReportData })
  }

  // Customizable Area End
}