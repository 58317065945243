// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

export const configJSON = require("./config");

export interface Props {
  // Customizable Area Start
  navigation: any;
  id: string;
  classes: any;
  history: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  reportData: any;
  apiError: boolean;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  // Customizable Area End
}

export default class DocumentsReportController extends BlockComponent<
  Props,
  S,
  SS
> {
  getReportsId: string = "";
  constructor(props: Props) {
    super(props);

    this.state = {
      // Customizable Area Start
      apiError: false,
      reportData: [],
      // Customizable Area End
    };

    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  handleSaveData = (data: any) => {
    this.setState({ reportData: data });
  };

  async componentDidMount() {
    this.getReports();
  }

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const reportsApiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const reportsResponseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (reportsApiRequestCallId === this.getReportsId) {
        this.handleReportResponse(reportsResponseJson);
      }
    }
  }

  getReports = () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: document.cookie
        .split("; ")
        .find((row) => row.startsWith("Token="))
        ?.split("=")[1],
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getReportsId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.documentReportApiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleReportResponse = (result: any) => {
    if (result.errors || result.error) {
      this.setState({ apiError: result.error });
    } else {
      const rowData: any[] = result.data.map((tableData: any) => ({
        ...tableData.attributes,
      }));
      this.setState({ reportData: rowData });
    }
  };
}

// Customizable Area End
