// Customizable Area Start
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { IBlock } from "../../../framework/src/IBlock";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  // Customizable Area Start
    title: string;
    hasMenu: boolean;
    classes: any;
    handleArchiveBackClick: () => void;
    history: any;
    handleSaveData: (data: []) => void;
  // Customizable Area End
}

interface S {
  // Customizable Area 
  archiveInfo:[]
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  // Customizable Area End
}

export default class ArchiveController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  archiveApi: string = "";
  // Customizable Area End
  
  constructor(props: Props) {
    super(props);
    
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage)
    ]

    this.state = {
      // Customizable Area Start
      archiveInfo: [],
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  handleArchiveBackClick = () => {
    this.props.history.goBack()
  }

  async componentDidMount() {
    this.archiveData();
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start

    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiRequestCallId === this.archiveApi) {
        this.setState({ archiveInfo: responseJson.archives.data.map((item: { attributes: [] }, i: number) => item.attributes) })
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  archiveData = () => {

    const header = {
      "Content-Type": configJSON.productApiContentType,
      "token": document.cookie.split("; ").find((row) => row.startsWith("Token="))?.split("=")[1]
    };

    const requestArchiveMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.archiveApi = requestArchiveMessage.messageId;

    requestArchiveMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.archivesAPiEndPoint
    );

    requestArchiveMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestArchiveMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );

    runEngine.sendMessage(requestArchiveMessage.id, requestArchiveMessage);
    return true;
  }
  // Customizable Area End
}
