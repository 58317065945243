// Customizable Area Start
import React from "react";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { Message } from "../../../framework/src/Message";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { IBlock } from "../../../framework/src/IBlock";
import { runEngine } from "../../../framework/src/RunEngine";

export interface Props {
  rows: any[];
  title: string;
  hasMenu: boolean;
  hasDelta?: boolean;
  navigation?: any;
  history?: any;
  hasStatus?: boolean | undefined;
  hasStatusData?: boolean;
  handleOnClick?: any;
  navigatePath?: string;
  showOpener?: boolean;
  hasInvoicePrice?: boolean;
}
interface SS { }
interface S {
  rows: any[];
  selected: any[];
  anchorEl: null | HTMLElement;
  page: number;
  rowsPerPage: number;
  order: string;
  openModal: boolean;
  modalOpen: boolean;
  selectedItem: any[];
  currentPage: number;
  totalPages: number;
  quote_Id: string;
  invoice_Id: string;
  pdfUrl: string;
  key: string;
  showOpener: boolean;
  isDeleteModalOpen: boolean;
  projectId: string
}


export interface EnhancedTableHeadProps {
  numSelected: number;
  onSelectAll: (event: React.ChangeEvent<HTMLInputElement>) => void;
  rowCount: number;
  handleSort: any;
  path: string;
  title?: string;
  hasDelta?: boolean;
  hasMenu?: boolean | undefined;
  hasStatus?: boolean | undefined;
}

export interface EnhancedTableToolbarProps {
  numSelected: number;
  title: string;
  path: string;
  hasDelta?: boolean;
  handleReportCick: (event: React.MouseEvent<HTMLButtonElement>) => void
  handleExportCick: (event: React.MouseEvent<HTMLButtonElement>) => void
  openModal: boolean
  modalOpen: boolean
  navigatePath: string | undefined
  handleNavigation: any
}

export interface EnhancedTableDataProps {
  hasMenu: boolean;
  title: string;
  rows: any[];
  handleClickChangePage: (event: unknown, newPage: number) => void;
  handleChangePerPage: (event: React.ChangeEvent<HTMLInputElement>) => void;
  selected: any[];
  rowsPerPage: number;
  page: number;
  handleRowItemClick: (event: React.MouseEvent<unknown>, name: string) => void;
  handleOpenMenu: (event: React.MouseEvent<HTMLButtonElement>, id: string, invoice_Id: string) => void;
  handleClickCloseMenu: (event: React.MouseEvent<HTMLButtonElement>) => void;
  handleDocOpen: (path: string, event:any) => void;
  anchorEl: null | HTMLElement;
  handleSelectAll: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleSort: any;
  path: string;
  pdfOpen: any;
  hasDelta?: boolean;
  handleExportCick: (event: React.MouseEvent<HTMLButtonElement>) => void;
  handleReportCick: (event: React.MouseEvent<HTMLButtonElement>) => void;
  openModal: boolean;
  modalOpen: boolean;
  hasStatus: boolean | undefined;
  hasStatusData?: boolean;
  selectedItem: any[];
  handleOnClick: any;
  handleDelete: any;
  navigatePath: string | undefined;
  hasInvoicePrice?: boolean
  handleNavigation: any;
}
export const configJSON = require("./config");

export default class DashboardNewTableController extends BlockComponent<
  Props,
  S,
  SS
> {
  messagePositiveButton: any;
  messageNegativeButton: any;
  messageNeutralButton: any;
  deleteQuoteUploadApiId: string = "";
  invoiceOpenApiId: string = '';
  quoteOpenApiId: string = '';
  deleteInvoiceApiId: string = '';
  deleteProjectsApiId: string = "";
  deleteDocumentComaprisonApiId: string = "";
  /**
   *
   */
  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
    ];
    this.state = {
      pdfUrl: '',
      rows: props.rows,
      showOpener: false,
      selected: [],
      order: 'desc',
      anchorEl: null,
      page: 0,
      rowsPerPage: 5,
      openModal: false,
      selectedItem: [],
      currentPage: 1,
      totalPages: 0,
      quote_Id: "",
      invoice_Id: "",
      key: "",
      isDeleteModalOpen: false,
      modalOpen: false,
      projectId: ""
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  handleDeleteQuoteUploadResponse = (apiRequestCallId: any, responseJson: any) => {
    if (apiRequestCallId === this.deleteQuoteUploadApiId && !responseJson.errors) {
      const updatedData = this.state.rows.filter(item => item.id !== this.state.quote_Id)
      this.setState({ rows: updatedData })
    }
  }

  handleDeleteInvoiceResponse = (apiRequestCallId: any, responseJson: any) => {
    if (apiRequestCallId === this.deleteInvoiceApiId) {
      const updatedData = this.state.rows.filter(item => item.id !== this.state.quote_Id)
      this.setState({ rows: updatedData })
    }
  }

  handleInvoiceOpenResponse = (apiRequestCallId: any, responseJson: any) => {
    if (apiRequestCallId === this.invoiceOpenApiId) {
      const OpenFile = responseJson
      this.setState({ pdfUrl: OpenFile.pdf_url })
    }
  }

  handleQuoteOpenResponse = (apiRequestCallId: any, responseJson: any) => {
    if (apiRequestCallId === this.quoteOpenApiId) {
      const OpenFile = responseJson
      this.setState({ pdfUrl: OpenFile.pdf_url })
    }
  }

  handleDeleteDocumentComparisonResponse = (apiRequestCallId: any, responseJson: any) => {
    if (apiRequestCallId === this.deleteDocumentComaprisonApiId) {
      const updatedDocComparisonData = this.state.rows.filter(item => item.id !== this.state.quote_Id)
      this.setState({ rows: updatedDocComparisonData })
    }
  }

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      this.handleDeleteQuoteUploadResponse(apiRequestCallId, responseJson)
      this.handleDeleteInvoiceResponse(apiRequestCallId, responseJson)
      this.handleInvoiceOpenResponse(apiRequestCallId, responseJson)
      this.handleQuoteOpenResponse(apiRequestCallId, responseJson)
      this.handleDeleteDocumentComparisonResponse(apiRequestCallId, responseJson)
    }
  }

  getToken = () => {
    const token = document.cookie.split("; ").find((row) => row.startsWith("Token="))?.split("=")[1]
    return token;
  }

  handleDeleteEndPoint = (title: string, path: string) => {
    switch (title) {
      case 'Quotes':
        return this.deleteQuote()
      case 'Invoices':
        return this.deleteInvoice()
      case 'Comparison Files':
        return this.handleDeleteComparison(path)
      default:
        return this.deleteProjects()
    }
  }

  handleDeleteComparison = (path: string) => {
    path.includes('/document') ?
      this.deleteDocumentComparisonApiCall()
      : this.deleteQuote()
  }

  deleteQuote = async () => {

    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": this.getToken()
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.deleteQuoteUploadApiId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.quoteUploadDeleteEndPoint}/${this.state.quote_Id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.quoteUploadDeleteApiMethod
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }

  deleteDocumentComparisonApiCall = async () => {

    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": this.getToken()
    };

    const requestMessageDelComparison = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.deleteDocumentComaprisonApiId = requestMessageDelComparison.messageId;

    requestMessageDelComparison.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.documentComparisonDeleteEndPoint}?id=${this.state.quote_Id}`
    );

    requestMessageDelComparison.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.quoteUploadDeleteApiMethod
    );

    requestMessageDelComparison.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    runEngine.sendMessage(requestMessageDelComparison.id, requestMessageDelComparison);

    return true;
  }


  deleteInvoice = async () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": this.getToken()
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.deleteInvoiceApiId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.invoiceUploadDeleteEndPoint}/${this.state.quote_Id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.quoteUploadDeleteApiMethod
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }


  invoiceViewOpen = () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": this.getToken()
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.invoiceOpenApiId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.invoicePdfOpenEndPoint}quote_id=${this.state.invoice_Id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }

  quoteViewOpen = () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": this.getToken()
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.quoteOpenApiId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.quotePdfOpenEndPoint}quote_id=${this.state.invoice_Id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }



  deleteProjects = async () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": this.getToken()
    };

    const projectDeleteRequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.deleteProjectsApiId = projectDeleteRequestMessage.messageId;

    projectDeleteRequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.projectsDeleteEndPoint}/${this.state.quote_Id}`
    );

    projectDeleteRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.quoteUploadDeleteApiMethod
    );

    projectDeleteRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    runEngine.sendMessage(projectDeleteRequestMessage.id, projectDeleteRequestMessage);

    return true;
  }




  handleSelectAllItemClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelecteds = this.state.rows.map(n => n);
      this.setState({ selected: newSelecteds });
      return;
    }
    this.setState({ selected: [] });
  };

  handleRowItemClick = (event: React.MouseEvent<unknown>, name: any) => {
    const selectedIndex = this.state.selected.indexOf(name);
    let newSelected: string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(this.state.selected, name);
    }
    else {
      newSelected = newSelected.concat(this.state.selected.slice(1));
    }

    this.setState({ selected: newSelected });
  };

  handleOpenMenu = (event: React.MouseEvent<HTMLButtonElement>, id: string, invoice: string) => {
    event.stopPropagation();
    this.setState({ anchorEl: event.currentTarget, quote_Id: id, invoice_Id: invoice });
  };

  handleClickCloseMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    this.setState({ anchorEl: null });
  };

  handleDocOpen = (path: string, event: any) => {
    if (event.stopPropagation) {
      event.stopPropagation();
    }
    this.setState({ showOpener: true });
    switch (path) {
      case '/quotes':
        this.quoteViewOpen();
        break;
      case '/invoice':
        this.invoiceViewOpen();
        break;

      default:
        this.invoiceViewOpen()
    }
  }


  handleCloseDeleteDialog = () => {
    this.setState({ anchorEl: null, isDeleteModalOpen: false })
  }

  handleDeleteDailogConfirm = (title: string, path:string) => {
    this.setState({ anchorEl: null, isDeleteModalOpen: false })
    this.handleDeleteEndPoint(title, path)
  }

  openDeleteDialog = (e: any) => {
    e.stopPropagation()
    this.setState({ isDeleteModalOpen: true })
  }

  handleClickChangePage = (event: unknown, newPage: number) => {
    this.setState({ page: newPage });
  };

  handleChangePerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ page: 0, rowsPerPage: parseInt(event?.target?.value, 10) });
  };

  sortArray = (data: any) => (
    data.sort((a: any, b: any) => {
      const dateOne: any = new Date(a.created_at)
      const dateTwo: any = new Date(b.created_at)
      return dateTwo - dateOne
    })
  )

  sortByAsc = (cellId: any) => {
    switch (cellId) {
      case 'Date':
        this.state.rows.sort((a, b) => {
          const dateOne: any = new Date(a?.created_at)
          const dateTwo: any = new Date(b?.created_at)
          return dateOne - dateTwo
        })
        break;
      case 'Vender':
        this.state.rows.sort((a, b) => {
          return (a?.vendor > b?.vendor ? 1 : -1)
        });
        break;
      case 'Project':
        this.state.rows.sort((a, b) => {
          return (a?.project > b?.project ? 1 : -1)
        });
        break;
      case 'Status':
        this.state.rows.sort((a, b) => {
          return (a?.status > b?.status ? 1 : -1)
        });
        break;
      case 'InvoiceId':
        this.state.rows.sort((a, b) => {
          return (a?.total_price > b?.total_price ? 1 : -1)
        });
        break;
    }
  }

  sortByDesc = (cellId: any) => {
    switch (cellId) {
      case 'Date':
        this.state.rows.sort((a, b) => {
          const dateOne: any = new Date(a?.created_at)
          const dateTwo: any = new Date(b?.created_at)
          return dateTwo - dateOne
        })
        break;
      case 'Vender':
        this.state.rows.sort((a, b) => {
          return (a?.vendor < b?.vendor ? 1 : -1)
        });
        break;
      case 'Project':
        this.state.rows.sort((a, b) => {
          return (a?.project < b?.project ? 1 : -1)
        });
        break;
      case 'Status':
        this.state.rows.sort((a, b) => {
          return (a?.status < b?.status ? 1 : -1)
        });
        break;
      case 'InvoiceId':
        this.state.rows.sort((a, b) => {
          return (a?.total_price < b?.total_price ? 1 : -1)
        });
        break;
    }
  }
  handleSort = (cellId: any) => {
    if (this.state.order === 'asc') {
      this.setState({ order: 'desc' })
      this.sortByAsc(cellId)
    } else {
      this.setState({ order: 'asc' })
      this.sortByDesc(cellId)
    }
  }

  handleNavigation = (e: any, pathName: string, navigatePath: string) => {
    if (pathName === navigatePath) {
      e.preventDefault()
    }
  }

  handleExportCick = (event: React.MouseEvent<HTMLButtonElement>) => {
    this.setState({ openModal: true })
  }

  handleReportCick = (event: React.MouseEvent<HTMLButtonElement>) => {
    this.setState({ modalOpen: true })
  }
  handleClose = () => {
    this.setState({ openModal: false, selected: [] })
  }

  handleReportClose = () => {
    this.setState({ modalOpen: false })
  }

}

// Customizable Area End