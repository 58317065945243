// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import moment from "moment";

export const configJSON = require("./config");

export interface IAnalyticsTileProps {
    titleValue: string;
    tileDetail: { value: any, data: any[]; percentage: any; color: string[]  };
    icon: any;
    iconStyle: any;
}

export interface ITimeLineToggleProps {
    title: string;
    selection: string;
    onClick: (timeline:string) => void;
}

export interface Props {
    navigation: any;
    id: string;
    history: any
}

interface S {
    quoteSeries: any;
    quoteoptions: any;
    projectoption: any;
    projectSeries: any;
    projectSelection: any;
    selection: string;
    quoteTileDetail: { value: any, data: any[]; percentage: any; color: string[] };
    invoiceTileDetail: { value: any, data: any[]; percentage: any; color: string[] };
    dollarTileDetail: { value: any, data: any[]; percentage: any; color: string[] };
    comparisonFileDetail: { value: any, percentage: any, matchedValue: any, matchedPercentage: any, unmatchedValue: any, unmatchedPercentage: any };
    comparisonFileInterval: string;
    reportFileDetail: { series: any[], uploaded: any, matchedPercentage: any, unmatchedPercentage: any };
    reportFileInterval: string;
}

interface SS {
    id: any;
}

export default class AnalyticsWebController extends BlockComponent<Props, S, SS> {
    totalComparisionFiles: number = 0;
    quoteInvoiceTileId: string = "";
    dollarTileId: string = "";
    quoteGraphId: string = "";
    projectGraphId: string = "";
    comparisonFileId: string = "";
    reportFileId: string = "";

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        this.state = {
            quoteSeries: [],
            quoteoptions: {
                xaxis: {},
                chart: {
                    height: 350,
                    type: 'line',
                    toolbar: {
                        show: false
                    }
                },
                colors: ['#202030', '#e18128'],
                dataLabels: {
                    enabled: false
                },
                stroke: {
                    curve: 'smooth'
                },
                grid: {
                    xaxis: {
                        lines: {
                            show: true
                        }
                    }
                },
                markers: {
                    size: 5,
                    hover: {
                        size: 6,
                    }
                },
            },

            selection: 'weekly',
            projectSelection: 'weekly',

            projectSeries: [],

            projectoption: {
                chart: {
                    type: 'bar',
                    height: 350,
                    toolbar: {
                        show: false
                    }
                },
                plotOptions: {
                    bar: {
                        horizontal: false,
                        columnWidth: '30%',
                        endingShape: 'rounded'
                    },
                },
                dataLabels: {
                    enabled: false
                },
                stroke: {
                    show: true,
                    width: 2,
                    colors: ['transparent']
                },
                xaxis: {
                },
                colors: ['#202030', '#e18128'],
                fill: {
                    opacity: 1
                },
            },
            quoteTileDetail: { value: 0, data: [], percentage: 0, color: [] },
            invoiceTileDetail: { value: 0, data: [], percentage: 0, color: [] },
            dollarTileDetail: { value: 0, data: [], percentage: 0, color: [] },
            comparisonFileDetail: { value: 0, percentage: 0, matchedValue: 0, matchedPercentage: 0, unmatchedValue: 0, unmatchedPercentage: 0 },
            comparisonFileInterval: "weekly",
            reportFileDetail: { series: [], uploaded: 0, matchedPercentage: 0, unmatchedPercentage: 0 },
            reportFileInterval: "weekly",
        };

        this.subScribedMessages = [
            getName(MessageEnum.CountryCodeMessage),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.ReciveUserCredentials),
        ];
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    }

    async componentDidMount() {
        this.dollarTileApi()
        this.quoteInvoiceTileApi()
        this.comparisionFileApi()
        this.reportFileApi()
        this.updateQuoteData()
        this.updateProjectsData()
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            if (apiRequestCallId === this.quoteInvoiceTileId) {
                this.handleQuoteInvoiceTileResponse(responseJson)
            }
            if (apiRequestCallId === this.dollarTileId) {
                this.handleDollarTileResponse(responseJson)
            }

            if (apiRequestCallId === this.quoteGraphId) {
                this.handleQuoteInvoiceResponse(responseJson)
            }

            if(apiRequestCallId === this.projectGraphId) {
                this.handleprojectVendorResponse(responseJson)
            }
            if (apiRequestCallId === this.comparisonFileId) {
                this.handleComparisonFileResponse(responseJson)
            }
            if (apiRequestCallId === this.reportFileId) {
                this.handleReportFileResponse(responseJson)
            }
        }
        // Customizable Area End
    }
    handleprojectVendorResponse = (responseJson: any) => {
        if (responseJson.weekly_data_count_vendor_projects && Array.isArray(responseJson.weekly_data_count_vendor_projects)) {
            const newProjectWeeklyDate = responseJson.weekly_data_count_vendor_projects.map((projectItem: any) => projectItem.projects_count)
            const newVendorWeeklyData = responseJson.weekly_data_count_vendor_projects.map((projectItem: any) => projectItem.vendors_count)
            const newProjectWeeklyDay = responseJson.weekly_data_count_vendor_projects.map((quoteWeeklyitem: any) => quoteWeeklyitem.day)
            
            this.setState({
                projectSeries: [
                    {
                        name: 'Projects',
                        data: newProjectWeeklyDate
                    },
                    {
                        name: 'Vendors',
                        data: newVendorWeeklyData,
                    }
                ],

                projectoption: {
                    ...this.state.projectoption,
                    xaxis: {
                        categories: newProjectWeeklyDay
                    },
                },
            })
        } else if(responseJson.monthly_data_count_vendor_projects && Array.isArray(responseJson.monthly_data_count_vendor_projects)){
            const newProjectMonthlyDate = responseJson.monthly_data_count_vendor_projects.map((projectItem: any) => projectItem.projects_count)
            const newVendorMonthlyData = responseJson.monthly_data_count_vendor_projects.map((projectItem: any) => projectItem.vendors_count)
            const newProjectMonthDay = responseJson.monthly_data_count_vendor_projects.map((quoteWeeklyitem: any) => {
                const dateObj = new Date(quoteWeeklyitem.date);
                const formattedDate = `${dateObj.getDate().toString().padStart(2, '0')} ${dateObj.toLocaleString('default', { month: 'short' })}`;
                return formattedDate;
            })
            
            this.setState({
                projectSeries: [
                    {
                        name: 'Projects',
                        data: newProjectMonthlyDate
                    },
                    {
                        name: 'Vendors',
                        data: newVendorMonthlyData,
                    }
                ],

                projectoption: {
                    ...this.state.projectoption,
                    xaxis: {
                        categories: newProjectMonthDay
                    },
                },
            })

        }else if(responseJson.yearly_data_count_vendor_projects && Array.isArray(responseJson.yearly_data_count_vendor_projects)){
            const newProjectYearlrDate = responseJson.yearly_data_count_vendor_projects.map((projectItem: any) => projectItem.projects_count)
            const newVendorYearlyData = responseJson.yearly_data_count_vendor_projects.map((projectItem: any) => projectItem.vendors_count)
            const newProjectYearDay = responseJson.yearly_data_count_vendor_projects.map((quoteWeeklyitem: any) => {
                const formattedMonth = moment(quoteWeeklyitem.month, 'MMMM').format('MMM');
                const formattedYearMonth = `${quoteWeeklyitem.year} ${formattedMonth}`;
                return formattedYearMonth
            })
            this.setState({
                projectSeries: [
                    {
                        name: 'Projects',
                        data: newProjectYearlrDate
                    },
                    {
                        name: 'Vendors',
                        data: newVendorYearlyData,
                    }
                ],

                projectoption: {
                    ...this.state.projectoption,
                    xaxis: {
                        categories: newProjectYearDay
                    },
                },
            })

        }else if(responseJson.total_data_count_vendor_projects && Array.isArray(responseJson.total_data_count_vendor_projects)){
            const newProjectTotalDate = responseJson.total_data_count_vendor_projects.map((quoteMonthlyitem: any) => quoteMonthlyitem.projects_count)
            const newProjectTotalCount = responseJson.total_data_count_vendor_projects.map((quoteMonthlyitem: any) => quoteMonthlyitem.vendors_count)
            const newProjectTotalDay = responseJson.total_data_count_vendor_projects.map((quoteWeeklyitem: any) => quoteWeeklyitem.year)

            this.setState({
                projectSeries: [
                    {
                        name: 'Projects',
                        data: newProjectTotalDate
                    },
                    {
                        name: 'Vendors',
                        data: newProjectTotalCount
                    }
                ],

                projectoption: {
                    ...this.state.projectoption,
                    xaxis: {
                        type: 'category',
                        categories: newProjectTotalDay, 
                    },
                },
            })
        }
    }

    handleQuoteInvoiceResponse = (responseJson: any) => {
        if (responseJson.weekly_data_count_quote_invoice && Array.isArray(responseJson.weekly_data_count_quote_invoice)) {
            const newQuotesWeeklyDate = responseJson.weekly_data_count_quote_invoice.map((quoteWeeklyitem: any) => quoteWeeklyitem.quotes_uploaded_count)
            const newInvoiceWeeklyData = responseJson.weekly_data_count_quote_invoice.map((quoteWeeklyitem: any) => quoteWeeklyitem.invoices_uploaded_count)
            const newInvoiceWeeklyDay = responseJson.weekly_data_count_quote_invoice.map((quoteWeeklyitem: any) => {return quoteWeeklyitem.day})
            
            this.setState({
                quoteSeries: [
                    {
                        name: 'Quotes',
                        data: newQuotesWeeklyDate
                    },
                    {
                        name: 'Invoices',
                        data: newInvoiceWeeklyData
                    }
                ],

                quoteoptions: {
                    ...this.state.quoteoptions,
                    xaxis: {
                        type: 'category',
                        categories: newInvoiceWeeklyDay, 
                        tickAmount: 6,
                    },
                },
            })

        } else if (responseJson.monthly_data_count_quote_invoice && Array.isArray(responseJson.monthly_data_count_quote_invoice)) {
            const newQuotesMonthlyDate = responseJson.monthly_data_count_quote_invoice.map((quoteMonthlyitem: any) => quoteMonthlyitem.quotes_uploaded_count)
            const newInvoicesMonthlyCount = responseJson.monthly_data_count_quote_invoice.map((quoteMonthlyitem: any) => quoteMonthlyitem.invoices_uploaded_count)
            const newInvoiceMonthlyDay = responseJson.monthly_data_count_quote_invoice.map((quoteWeeklyitem: any) => {
                const dateObj = new Date(quoteWeeklyitem.date);
                const formattedDate = `${dateObj.getDate().toString().padStart(2, '0')} ${dateObj.toLocaleString('default', { month: 'short' })}`;
                return formattedDate;
            })
            this.setState({
                quoteSeries: [
                    {
                        name: 'Quotes',
                        data: newQuotesMonthlyDate
                    },
                    {
                        name: 'Invoices',
                        data: newInvoicesMonthlyCount
                    }
                ],

                quoteoptions: {
                    ...this.state.quoteoptions,
                    xaxis: {
                        type: 'category',
                        categories: newInvoiceMonthlyDay, 
                    },
                },
            })

        }else if(responseJson.yearly_data_count_quote_invoice && Array.isArray(responseJson.yearly_data_count_quote_invoice)){
            const newQuotesyearlyDate = responseJson.yearly_data_count_quote_invoice.map((quoteMonthlyitem: any) => quoteMonthlyitem.quotes_uploaded_count)
            const newInvoicesYearlyCount = responseJson.yearly_data_count_quote_invoice.map((quoteMonthlyitem: any) => quoteMonthlyitem.invoices_uploaded_count)
            const newInvoiceYearlyDay = responseJson.yearly_data_count_quote_invoice.map((quoteWeeklyitem: any) => {
                const formattedMonth = moment(quoteWeeklyitem.month, 'MMMM').format('MMM');
                const formattedYearMonth = `${quoteWeeklyitem.year} ${formattedMonth}`;
                return formattedYearMonth
            })
            this.setState({
                quoteSeries: [
                    {
                        name: 'Quotes',
                        data: newQuotesyearlyDate
                    },
                    {
                        name: 'Invoices',
                        data: newInvoicesYearlyCount
                    }
                ],

                quoteoptions: {
                    ...this.state.quoteoptions,
                    xaxis: {
                        type: 'category',
                        categories: newInvoiceYearlyDay, 
                    },
                },
            })
        }else if(responseJson.total_data_count_quote_invoice && Array.isArray(responseJson.total_data_count_quote_invoice)){
            const newQuoteTotalDate = responseJson.total_data_count_quote_invoice.map((quoteMonthlyitem: any) => quoteMonthlyitem.quotes_uploaded_count)
            const newInvoicesTotalCount = responseJson.total_data_count_quote_invoice.map((quoteMonthlyitem: any) => quoteMonthlyitem.invoices_uploaded_count)
            const newInvoiceTotalDay = responseJson.total_data_count_quote_invoice.map((quoteWeeklyitem: any) => quoteWeeklyitem.year)

            this.setState({
                quoteSeries: [
                    {
                        name: 'Quotes',
                        data: newQuoteTotalDate
                    },
                    {
                        name: 'Invoices',
                        data: newInvoicesTotalCount
                    }
                ],

                quoteoptions: {
                    ...this.state.quoteoptions,
                    xaxis: {
                        type: 'category',
                        categories: newInvoiceTotalDay, 
                    },
                },
            })
        }


    }

    getToken = () => {
        return document.cookie.split("; ").find((row) => row.startsWith("Token="))?.split("=")[1];
    }

    updateQuoteData = (timeline:string = "weekly") => {
        this.setState({ selection: timeline })

        const header = {
            "token": this.getToken()
        };
        const quoteGraphRequestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.quoteGraphId = quoteGraphRequestMessage.messageId;
        quoteGraphRequestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.quoteinvoiceApiEndPoint}?graph_type=${timeline}`
        );
        quoteGraphRequestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        quoteGraphRequestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.getUploadApiMethod
        );
        runEngine.sendMessage(quoteGraphRequestMessage.id, quoteGraphRequestMessage);
    }

    updateProjectsData = (timeline:string = "weekly") => {

        this.setState({
            projectSelection: timeline
        })

        const header = {
            "token": this.getToken(),
        };
        const quoteGraphRequestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.projectGraphId = quoteGraphRequestMessage.messageId;
        quoteGraphRequestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.projectVendorApiEndpoint}?graph_type=${timeline}`
        );
        quoteGraphRequestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        quoteGraphRequestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.getUploadApiMethod
        );
        runEngine.sendMessage(quoteGraphRequestMessage.id, quoteGraphRequestMessage);
    }

    dollarTileApi = () => {
        const header = {
            "Content-Type": configJSON.dollarTileApiContentType,
            "token": this.getToken(),
        };
        const quoteGraphRequestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.dollarTileId = quoteGraphRequestMessage.messageId;
        quoteGraphRequestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.dollarTileApiEndPoint
        );
        quoteGraphRequestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        quoteGraphRequestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.getUploadApiMethod
        );
        runEngine.sendMessage(quoteGraphRequestMessage.id, quoteGraphRequestMessage);
        return true;
    }

    quoteInvoiceTileApi = () => {
        const header = {
            "Content-Type": configJSON.qouteInvoiceTileApiContentType,
            "token": this.getToken(),
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.quoteInvoiceTileId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.qouteInvoiceTileApiEndPoint
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.getUploadApiMethod
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
        return true;
    }

    comparisionFileApi = (interval: string = "weekly") => {
        this.setState({ comparisonFileInterval:interval });
        const header = {
            "Content-Type": configJSON.comparisonFileApiContentType,
            "token": this.getToken(),
        };

        const comparisonFileMsg = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.comparisonFileId = comparisonFileMsg.messageId;
        comparisonFileMsg.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.comparisonFileApiEndPoint + `?interval_type=${interval}` 
        );
        comparisonFileMsg.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        comparisonFileMsg.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.getUploadApiMethod
        );
        runEngine.sendMessage(comparisonFileMsg.id, comparisonFileMsg);
        return true;
    }

    reportFileApi = (interval: string = "weekly") => {
        this.setState({ reportFileInterval:interval });
        const header = {
            "Content-Type": configJSON.reportFileApiContentType,
            "token": this.getToken(),
        };

        const reportFileMsg = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.reportFileId = reportFileMsg.messageId;
        reportFileMsg.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.reportFileApiEndPoint + `?interval_type=${interval}` 
        );
        reportFileMsg.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        reportFileMsg.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.getUploadApiMethod
        );
        runEngine.sendMessage(reportFileMsg.id, reportFileMsg);
        return true;
    }

    getDataFromPercentage = (percentage:any) => {
        return {
            color: typeof percentage === "number" && percentage < 0 ? ["red"] : ["green"],
            percentage: typeof percentage === "number" ? percentage + "%" : undefined,
        }
    } 

    handleQuoteInvoiceTileResponse = (result: any) => {
        let quoteList: any[] = [];
        let invoiceList: any[] = [];
        let quoteData = this.getDataFromPercentage(result.weekly_stats[0].quotes_percentage_fluctuate);
        let invoiceData = this.getDataFromPercentage(result.weekly_stats[0].invoices_percentag_fluctuate);
        
        result.weekly_stats.forEach((x: any) => {
            quoteList.push(x.quotes_upload)
            invoiceList.push(x.invoice_upload)
        });
        this.setState({
            quoteTileDetail: {
                value: result.weekly_stats[0].total_uploded_quotes,
                data: quoteList,
                percentage: quoteData.percentage,
                color: quoteData.color
            },
            invoiceTileDetail: {
                value: result.weekly_stats[0].total_uploded_invoices,
                data: invoiceList,
                percentage: invoiceData.percentage,
                color: invoiceData.color
            }
        })
    }

    handleDollarTileResponse = (result: any) => {
        let dollartValueList: any[] = result.weekly_stats.map((x: any) => (x.today_dollar_values));
        let dollarData = this.getDataFromPercentage(result.weekly_stats[0].doller_fluctuation_percent);
        this.setState({
            dollarTileDetail: {
                value: "$" + result.weekly_stats[0].main_doller_value,
                data: dollartValueList,
                percentage: dollarData.percentage,
                color: dollarData.color
            }
        })
    }

    handleComparisonFileResponse = (result: any) => {
        this.setState({ comparisonFileDetail: {
            value: result.data.data,
            percentage: result.data.percentage,
            matchedValue: result.data.matched_algorithm,
            matchedPercentage: result.data.matched_percentage,
            unmatchedValue: result.data.unmatched_algorithm,
            unmatchedPercentage: result.data.unmatched_percentage
        }})
    }

    handleReportFileResponse = (result: any) => {
        this.setState({reportFileDetail:{
            series: [result.data.report_genration_file,result.data.matched_algorithm,result.data.unmatched_algorithm],
            uploaded: result.data.uploded_files,
            matchedPercentage: result.data.matched_percentage,
            unmatchedPercentage: result.data.unmatched_percentage
        }})
    }

    comparisonFileStaticFn = () => ("Comparison Files")

    reportFileStaticFn = () => ("Uploaded Files")

    totalReportFormatterFn = (w: any) => {return this.state.reportFileDetail.uploaded}

    handleSaveData = (data: any) => {
    }
    // Customizable Area End
}
