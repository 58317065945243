// Customizable Area Start
import React, { ReactNode } from "react";
import { Grid, Button, Box, Typography, Avatar } from "@material-ui/core";
import { styled } from "@material-ui/styles";
import Layout from "../../landingpage/src/Layout.web";
import Header from "../../landingpage/src/Header.web";
import Chart from "react-apexcharts";
import AnalyticsWebController, {
  IAnalyticsTileProps,
  ITimeLineToggleProps,
  configJSON,
} from "./AnalyticsWebController.web";
import {
  analyticsDollarIcon,
  analyticsInvoiceIcon,
  analyticsQuoteIcon,
} from "./assets";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";

export const AnalyticsTileWrapper = (props: IAnalyticsTileProps) => {
  const { tileDetail, titleValue, icon, iconStyle } = props;

  return (
    <Grid item sm={12} md={6} lg={4}>
      <Grid container style={webStyle.headerTileStyle}>
        <Grid item xs={3} style={webStyle.headerTileGridStyle}>
          <Avatar
            sizes="small"
            style={webStyle.avatarStyle}
            variant="rounded"
            src={icon}
            imgProps={{ style: iconStyle }}
          />
        </Grid>
        <Grid item xs={4} style={webStyle.headerTileGridStyle}>
          <div style={webStyle.headerValueStyle}>{tileDetail.value}</div>
          <div style={webStyle.headerPtagStyle}>{titleValue}</div>
        </Grid>
        <Grid item xs={5} style={webStyle.headerTileGridStyle}>
          <Chart
            style={{ width: 165 }}
            series={[{ data: tileDetail.data }]}
            options={{
              chart: tileChartDetail.chart,
              stroke: {
                curve: "smooth",
                width: 1,
                colors: tileDetail.color,
              },
              fill: {
                colors: tileDetail.color,
                gradient: { gradientToColors: ["white"] },
              },
              yaxis: {
                ...tileChartDetail.yaxis,
                title: {
                  text: tileDetail.percentage,
                  rotate: 0,
                  offsetY: 30,
                  offsetX: 8,
                  style: {
                    color: tileDetail.color[0],
                    fontSize: "15px",
                    fontWeight: "bold",
                    fontFamily: "sans-serif !important",
                  },
                },
              },
              xaxis: tileChartDetail.xaxis,
              dataLabels: { enabled: false },
              tooltip: { enabled: false },
              grid: { show: false },
              legend: { show: false },
            }}
            type="area"
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

const StyledActiveButton = styled(Button)({
  borderRadius: 0,
  textTransform: 'unset',
  margin: '5px 0',
  padding: '0',
  width: '70px',
  display: 'inline-block',
  "&.Mui-disabled": {
    background: "#fff",
    color: "#e0802e",
    fontWeight: "600",
  },
  "&.active": {
    position: 'relative',
    background: "#e0802e",
    color: '#ffffff',
    borderRadius: '3px',
    
  },

});

const StyledChartBox = styled(Box)({
  background: "#fff",
  borderRadius: '10px',
  padding: '5px',
  '& .apexcharts-tooltip.apexcharts-theme-light': {
    background: '#fff'
  },
  '& .apexcharts-tooltip.apexcharts-theme-light .apexcharts-tooltip-title': {
    background: '#202030'
  },
  '& .apexcharts-tooltip-title': {
    fontSize: '15px !important',
    textAlign: 'center',
    color: '#fff',
    fontWeight: 600
  },
  '& .apexcharts-tooltip-series-group': {
    fontSize: '15px !important',
    fontWeight: '600 !important',
    borderRadius: '10px !important'
  }
})

export const TimelineToggleBox = (props: ITimeLineToggleProps) => {
  const { title, selection, onClick } = props;
  return (
    <Box style={webStyle.quotesButton}>
      <Typography style={webStyle.quoteTitle}>{title}</Typography>
      <Box style={webStyle.quotesButtonBox}>
        <StyledActiveButton
          onClick={() => onClick("weekly")}
          data-testid="weekly-selection"
          className={selection === "weekly" ? "active" : ""}
        >
          Weekly
        </StyledActiveButton>
        <div style={webStyle.buttonPartition} />
        <StyledActiveButton
          onClick={() => onClick("monthly")}
          data-testid="monthly-selection"
          className={`${selection === "monthly" && "active"}`}
        >
          Monthly
        </StyledActiveButton>
        <div style={webStyle.buttonPartition} />
        <StyledActiveButton
          onClick={() => onClick("yearly")}
          data-testid="yearly-selection"
          className={`${selection === "yearly" && "active"}`}
        >
          Yearly
        </StyledActiveButton>
        <div style={webStyle.buttonPartition} />
        <StyledActiveButton
          onClick={() => onClick("total")}
          data-testid="total-selection"
          className={`${selection === "total" && "active"}`}
        >
          Total
        </StyledActiveButton>
      </Box>
    </Box>
  );
};

export const ReportPercentageWrapper = (props: { value: any }) => {
  const { value } = props;
  return (
    <span style={value >= 0 ? webStyle.upIconStyle : webStyle.downIconStyle}>
      {value >= 0 ? (
        <ArrowUpwardIcon fontSize="small" />
      ) : (
        <ArrowDownwardIcon fontSize="small" />
      )}{" "}
        {Math.abs(value).toFixed(1) + "%"}
    </span>
  );
};

export default class AnalyticsWeb extends AnalyticsWebController {
  render(): ReactNode {
    return (
      <>
        <Header
          data-testid="allQuotesHeaderId"
          handleSaveData={this.handleSaveData}
          navigation={this.props.navigation} history={this.props.history}  />
        <Grid container spacing={2}>
        <AnalyticsTileWrapper
            titleValue="Dollar Value"
            tileDetail={this.state.dollarTileDetail}
            icon={analyticsDollarIcon}
            iconStyle={webStyle.dollarIconStyle}
          />
          <AnalyticsTileWrapper
            titleValue="Number of Quotes"
            tileDetail={this.state.quoteTileDetail}
            icon={analyticsQuoteIcon}
            iconStyle={webStyle.quoteIconStyle}
          />
          <AnalyticsTileWrapper
            titleValue="Number of Invoices"
            tileDetail={this.state.invoiceTileDetail}
            icon={analyticsInvoiceIcon}
            iconStyle={webStyle.invoiceIconStyle}
          />
          <Grid item xs={12} md={6} lg={6}>
            <StyledChartBox id="chart">

              <TimelineToggleBox title={'Quotes & Invoices'} selection={this.state.selection} onClick={this.updateQuoteData}/>
              <Box id="chart-timeline-quote">
                <Chart options={this.state.quoteoptions} series={this.state.quoteSeries} type="line" height={350} />
              </Box>
            </StyledChartBox>
          </Grid>
          <Grid item xs={12} md={6} lg={6}>
            <StyledChartBox id="project-chart">
            <TimelineToggleBox title={'Projects & Vendors'} selection={this.state.projectSelection} onClick={this.updateProjectsData}/>

              <Box id="chart-timeline-project">
                <Chart options={this.state.projectoption} series={this.state.projectSeries} type="bar" height={350} />
              </Box>
            </StyledChartBox>
          </Grid>
          <Grid item xs={12} md={6} lg={6}>
            <div style={webStyle.comparisionChartStyle}>
              <TimelineToggleBox
                title={configJSON.comparisionTitle}
                selection={this.state.comparisonFileInterval}
                onClick={this.comparisionFileApi}
              />
              <div style={webStyle.chartStyle}>
                <div style={{ marginLeft: "-8%" }}>
                  <Chart
                    options={{
                      ...comparisonChartOptions,
                      labels: [this.state.comparisonFileDetail.value],
                      plotOptions: {
                        radialBar: {
                          ...comparisonRadialBarOptions,
                          dataLabels: {
                            ...comparisonRadialBarOptions.dataLabels,
                            value: {
                              ...comparisonRadialBarOptions.dataLabels.value,
                              formatter: this.comparisonFileStaticFn,
                            },
                          },
                        },
                      },
                    }}
                    series={[this.state.comparisonFileDetail.percentage]}
                    type="radialBar"
                    width="300"
                  />
                </div>
                <div style={webStyle.twoChartDivStyle}>
                  <div style={webStyle.firstChartDivStyle}>
                    <Chart
                      options={matchedChartOptions}
                      series={[
                        this.state.comparisonFileDetail.matchedPercentage,
                      ]}
                      type="radialBar"
                      width="140"
                      style={{ width: "55%" }}
                    />
                    <div style={webStyle.matchedAlgoDivStyle}>
                      <p style={webStyle.firstParaStyle}>
                        {this.state.comparisonFileDetail.matchedValue}
                      </p>
                      <p style={webStyle.secondParaStyle}>Algorithm Matched</p>
                    </div>
                  </div>
                  <div style={webStyle.firstChartDivStyle}>
                    <Chart
                      options={unmatchedChartOptions}
                      series={[
                        this.state.comparisonFileDetail.unmatchedPercentage,
                      ]}
                      type="radialBar"
                      width="140"
                      style={{ width: "55%" }}
                    />
                    <div>
                      <p style={webStyle.firstParaStyle}>
                        {this.state.comparisonFileDetail.unmatchedValue}
                      </p>
                      <p style={webStyle.secondParaStyle}>
                        Algorithm Not Matched
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} md={6} lg={6}>
            <div style={webStyle.comparisionChartStyle}>
              <TimelineToggleBox
                title={configJSON.reportTitle}
                selection={this.state.reportFileInterval}
                onClick={this.reportFileApi}
              />
              <div style={webStyle.chartStyle}>
                <div style={{ marginLeft: "-8%" }}>
                  <Chart
                    options={{
                      ...reportDonutChartOptions,
                      plotOptions: {
                        pie: {
                          expandOnClick: false,
                          donut: {
                            size: "87%",
                            labels: {
                              show: true,
                              name: {
                                show: true,
                                offsetY: -5,
                                formatter: this.reportFileStaticFn,
                              },
                              total: {
                                show: true,
                                showAlways: true,
                                formatter: this.totalReportFormatterFn,
                              },
                            },
                          },
                        },
                      },
                    }}
                    series={this.state.reportFileDetail.series}
                    type="donut"
                    width="280"
                  />
                </div>
                <div style={webStyle.twoChartDivStyle}>
                  <div>
                    <p style={webStyle.firstParaStyle}>
                      {this.state.reportFileDetail.series[0]}
                    </p>
                    <p style={webStyle.secondParaStyle}>
                      Report Generation Files
                    </p>
                  </div>
                  <div style={webStyle.paraDivStyle}>
                    <p style={webStyle.firstParaStyle}>
                      {this.state.reportFileDetail.series[1]}
                      <ReportPercentageWrapper
                        value={this.state.reportFileDetail.matchedPercentage}
                      />
                    </p>
                    <p style={webStyle.secondParaStyle}>Matched Files</p>
                  </div>
                  <div style={webStyle.paraDivStyle}>
                    <p style={webStyle.firstParaStyle}>
                      {this.state.reportFileDetail.series[2]}
                      <ReportPercentageWrapper
                        value={this.state.reportFileDetail.unmatchedPercentage}
                      />
                    </p>
                    <p style={webStyle.secondParaStyle}>Not matched files</p>
                  </div>
                </div>
              </div>
            </div>
          </Grid>
        </Grid>
      </>
    );
  }
}

const webStyle = {
  buttonPartition: {
    display: "inline-block",
    background: "#000",
    margin: "5px 3px",
    position: 'relative' as "relative",
    minWidth: "1.5px",
  },
  quotesChartBox: {
    background: "#fff",
    borderRadius: "10px",
    padding: "5px",
  },
  quotesButton: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  quotesButtonBox: {
    border: "1px solid rgba(0, 0, 0, 0.16)",
    borderRadius: "5px",
    display: "flex",
    padding: "0 6px",
  },
  quoteTitle: {
    fontSize: "22px",
    fontWeight: 550,
    color: "#000",
  },
  secondParaStyle: {
    marginTop: 0,
    color: "black",
    fontWeight: 500,
  },
  firstParaStyle: {
    color: "black",
    fontSize: "18px",
    fontWeight: 550,
    display: "flex",
    alignItems: "center",
  },
  matchedAlgoDivStyle: {
    marginRight: "8%",
  },
  upIconStyle: {
    color: "green",
    fontSize: "13px",
    display: "flex",
    alignItems: "center",
    marginLeft: "2%",
  },
  downIconStyle: {
    color: "red",
    fontSize: "13px",
    display: "flex",
    alignItems: "center",
    marginLeft: "2%",
  },
  paraDivStyle: {
    paddingTop: "10%",
  },
  headerTileStyle: {
    background: "white",
    border: "1px solid #00000070",
    borderRadius: "10px",
    height: "100px",
    width: "100%",
    padding: "2%",
  },
  headerTileGridStyle: { height: "80px" },
  headerValueStyle: { fontSize: "34px", marginTop: "15px" },
  headerPtagStyle: { fontSize: "x-small" },
  avatarStyle: { backgroundColor: "#e0802a", width: "80px", height: "80px" },
  dollarIconStyle: { width: "50%", height: "55%" },
  quoteIconStyle: { width: "35%", height: "50%" },
  invoiceIconStyle: { width: "48%", height: "50%" },
  twoChartDivStyle: {
    marginLeft: "-6%",
  },
  firstChartDivStyle: {
    display: "flex",
    alignItems: "center",
  },
  comparisionChartStyle: {
    background: "white",
    padding: "3%",
    border: "1px solid #00000070",
    borderRadius: "10px",
    minHeight: "100%",
  },
  pStyle: {
    fontSize: "22px",
    fontWeight: 550,
    color: "black",
  },
  chartStyle: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
  },

  

};

const tileChartDetail = {
  chart: {
    height: 118,
    offsetY: -15,
    toolbar: { show: false },
    selection: { enabled: false },
  },
  xaxis: {
    type: "numeric" as "numeric",
    labels: { show: false },
    axisBorder: { show: true },
    axisTicks: { show: false },
    crosshairs: { show: false },
  },
  yaxis: {
    show: true,
    axisBorder: { show: false },
    axisTicks: { show: false },
    labels: { show: false },
  },
};

const comparisonChartOptions = {
  chart: {
    height: 150,
    type: "radialBar" as "radialBar",
  },
  fill: {
    type: "gradient",
    colors: ["#df802d"],
    gradient: {
      shade: "dark",
      type: "horizontal",
      shadeIntensity: 0.5,
      gradientToColors: ["#df802d"],
      opacityFrom: 1,
      inverseColors: false,
      opacityTo: 1,
      stops: [100],
    },
  },
  stroke: { lineCap: "round" as "round" },
};

const comparisonRadialBarOptions = {
  track: {
    background: "#fff",
    strokeWidth: "67%",
    margin: 0, // margin is in pixels
    dropShadow: {
      enabled: true,
      top: -3,
      left: 0,
      opacity: 0.35,
      blur: 4,
    },
  },
  hollow: {
    margin: 0,
    image: undefined,
    size: "70%",
    background: "#fff",
    imageOffsetX: 0,
    imageOffsetY: 0,
    position: "front" as "front",
    dropShadow: {
      enabled: true,
      blur: 4,
      top: 3,
      left: 0,
      opacity: 0.24,
    },
  },
  dataLabels: {
    show: true,
    name: {
      offsetY: 0,
      color: "black",
      fontSize: "14px",
      show: true,
      fontWeight: 500,
    },
    value: {
      fontSize: "15px",
      offsetY: 15,
      color: "#111",
      show: true,
    },
  },
};

const reportDonutChartOptions = {
  chart: { type: "donut" as "donut" },
  legend: { show: false },
  dataLabels: { enabled: false },
  tooltip: { enabled: false },
  fill: { colors: ["#070e2e", "#6eba1e", "#e35261"] },
  states: {
    hover: { filter: { type: "lighten", value: 0.5 } },
    active: { filter: { type: "none", value: 0 } },
  },
  stroke: { width: 2 },
  labels: [""],
};

const matchedChartOptions = {
  chart: {
    height: 150,
    type: "radialBar" as "radialBar",
  },
  fill: {
    type: "gradient",
    colors: ["green"],
    gradient: {
      shade: "dark",
      type: "horizontal",
      shadeIntensity: 0.5,
      opacityTo: 1,
      inverseColors: false,
      opacityFrom: 1,
      gradientToColors: ["green"],
      stops: [100],
    },
  },
  labels: [""],
  stroke: { lineCap: "round" as "round" },
  plotOptions: {
    radialBar: {
      hollow: {
        margin: 0,
        size: "70%",
        imageOffsetY: 0,
        background: "#fff",
        image: undefined,
        imageOffsetX: 0,
        position: "front" as "front",
        dropShadow: {
          left: 0,
          enabled: true,
          top: 3,
          blur: 4,
          opacity: 0.24,
        },
      },
      dataLabels: {
        show: true,
        name: {
          offsetY: 40,
          show: true,
          color: "black",
          fontSize: "14px",
          fontWeight: 500,
        },
        value: {
          offsetY: -10,
          color: "#111",
          fontSize: "14px",
          show: true,
        },
      },
      track: {
        background: "#fff",
        strokeWidth: "68%",
        margin: 0, // margin is in pixels
        dropShadow: {
          left: 0,
          enabled: true,
          top: -3,
          blur: 4,
          opacity: 0.35,
        },
      },
    },
  },
};

const unmatchedChartOptions = {
  chart: {
    height: 150,
    type: "radialBar" as "radialBar",
  },
  plotOptions: {
    radialBar: {
      hollow: {
        margin: 0,
        background: "#fff",
        image: undefined,
        imageOffsetX: 0,
        size: "70%",
        imageOffsetY: 0,
        position: "front" as "front",
        dropShadow: {
          top: 3,
          enabled: true,
          left: 0,
          blur: 4,
          opacity: 0.24,
        },
      },
      track: {
        background: "#fff",
        strokeWidth: "66%",
        margin: 0, // margin is in pixels
        dropShadow: {
          enabled: true,
          left: 0,
          blur: 4,
          top: -3,
          opacity: 0.35,
        },
      },
      dataLabels: {
        show: true,
        name: {
          offsetY: 40,
          color: "black",
          fontSize: "14px",
          show: true,
          fontWeight: 500,
        },
        value: {
          offsetY: -10,
          fontSize: "14px",
          color: "#111",
          show: true,
        },
      },
    },
  },
  fill: {
    type: "gradient",
    colors: ["red"],
    gradient: {
      shade: "dark",
      shadeIntensity: 0.5,
      opacityTo: 1,
      inverseColors: false,
      opacityFrom: 1,
      type: "horizontal",
      gradientToColors: ["red"],
      stops: [100],
    },
  },
  stroke: { lineCap: "round" as "round" },
  labels: [""],
};

// Customizable Area End
