// Customizable Area Start
import React from "react";
import { Box, Typography } from "@material-ui/core";
import { createTheme, withStyles } from "@material-ui/core/styles";
import DocumentsComparisonController, {
  Props,
  configJSON
} from "./DocumentsComparisonController.web";
import Header from "../../landingpage/src/Header.web";
import DashboardNewTable from "../../landingpage/src/DashboardNewTable.web";


class DocumentsComparison extends DocumentsComparisonController {
  constructor(props: Props) {
    super(props);
    
  }

  render() {
    const { classes } = this.props
    return (
      <>
          <Header
            data-testid="documentComparisonHeaderId"
            handleSaveData={this.handleSaveData}
            navigation={this.props.navigation} history={undefined}          
          />
          <Box className={classes.documentCompareBox}>
            <Typography variant="h5">{configJSON.documentComparisonTitle}</Typography>
            <Typography>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Beatae voluptatem accusantium adipisci officia mollitia eligendi soluta consequuntur, tempora accusamus, eaque ipsa voluptate perferendis vero? Odit eaque voluptatibus voluptas impedit recusandae?</Typography>
          </Box>
          <DashboardNewTable 
                navigatePath="/document/comparison" 
                rows={this.state.docsComparisonData} 
                title={configJSON.documentComparisonTitle} 
                hasStatusData={true}
                hasMenu={true} 
            />
      </>
    );
  }
}

const theme = createTheme({
  typography: {
    h5: {
        color: '#000',
        fontWeight: 500,
    },
  }
});

export const documentswebStyle = {
  documentCompareBox: {
    '& p': {
      margin: '4px 0 20px 0'
    }
  }
};

export { DocumentsComparison }
export default withStyles(documentswebStyle)(DocumentsComparison)

// Customizable Area End
