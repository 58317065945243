import { Route, Switch } from "react-router-dom";
import { ModalRoute } from "react-router-modal";
import React from "react";
import PropTypes from "prop-types";
import "react-router-modal/css/react-router-modal.css";
import Layout from "../../../blocks/landingpage/src/Layout.web";

function Wrapper({ element, history, match, routeMap, closeModal }) {
  const cookieValue = document.cookie
    .split('; ')
    .find((row) => row.startsWith('Token='))
    ?.split('=')[1];

  if (cookieValue && typeof cookieValue === 'string') {
    const tokenParts = cookieValue?.split('.');
    const decodedToken = JSON.parse(atob(tokenParts[1]));

    if (decodedToken.exp * 1000 < Date.now()) {
      document.cookie = `Token=; expires=Thu, 18 Dec 2013 12:00:00 UTC"; path=/;`;
      document.cookie = `RefreshToken=; expires=Thu, 18 Dec 2013 12:00:00 UTC"; path=/;`;
      history.push("/login")
    }
  }
  if (location.pathname != "/" && location.pathname != "/login" && location.pathname != "/registration" && location.pathname != "/signup" && location.pathname != "/resetPassword" && location.pathname != "/recoverPassword" && cookieValue == undefined && location.pathname != "/Termsandconditions" && location.pathname != "/PrivacyPolicy") {
    history.push("/")
  }
  if ((location.pathname === '/' || location.pathname === "/registration" || location.pathname === "/resetPassword" || location.pathname === "/recoverPassword") && cookieValue != undefined) {
    history.push("/DashboardWeb")
  }
  const navigate = (to, params) => {
    if (to.startsWith("sorting/" || "quotes/")) {
      history.push(to);
      return
    }
    let url = routeMap[to]?.path;
    // replace params ids in the url with actual values
    if (params && Object.keys(params).length > 0) {
      Object.keys(params).forEach(param => {
        const re = RegExp(`\:${param}\\??`); // eslint-disable-line no-useless-escape
        url = url?.replace(re, escape(params[param]));
      });
    }
    // removing empty params from url - every string between /: and ?
    url = url?.replace(/\/:(.*?)(?=\/|$)/g, "");
    // if the route is not a modal

    if (!routeMap[to]?.modal) {
      history.push(url);
      // if the route is a modal
    } else {
      // checking if the url ends with a slash or not
      const slash = /\/$/.test(match.url) ? "" : "/";
      // current url in the browser + slash + modal url with parameters
      url = match.url + slash + url;
      // removing the */ from the url
      url = url?.replace(/\*\/?/g, "");
      history.push(url);
    }
  };

  const getParam = (param, alternative) => {
    return match.params[param] || alternative;
  };

  const getLocation = () => {
    return history.location
  }

  const goBack = () => {
    history.goBack();
  };

  return React.cloneElement(element, {
    navigation: { navigate, getParam, goBack, getLocation },
    closeModal
  });
}

Wrapper.propTypes = {
  element: PropTypes.element,
  history: PropTypes.object,
  routeMap: PropTypes.object,
  closeModal: PropTypes.func,
  match: PropTypes.object
};

const WebRoutesGenerator = ({ routeMap }) => {
  return Object.keys(routeMap).map(route => {
    const currentRoute = routeMap[route];
    const Component = currentRoute.component;
    if (currentRoute.modal) {
      return (
        <ModalRoute
          key={currentRoute.path}
          path={currentRoute.path}
          component={props => (
            <Layout>
              <Wrapper element={<Component />} {...props} routeMap={routeMap} />
            </Layout>
          )}
        />
      );
    }
    else {
      if (currentRoute.path === "/sorting" || currentRoute.path == "/quotes" || currentRoute.path == "/invoice" || currentRoute.path == "/report" || currentRoute.path == "/document" || currentRoute.path == "/profile") {
        return <Route
          key={currentRoute.path}
          path={currentRoute.path}
          exact={currentRoute.exact}
          render={props => (
            <Wrapper
              element={
                <Switch>
                  <Route exact path={currentRoute.path}>
                    <Layout>
                      <Wrapper element={<Component />} {...props} routeMap={routeMap} />
                    </Layout>
                  </Route>
                  {
                    currentRoute.components.map((nestedComponent) => {

                      const NestedComp = nestedComponent.component;
                      return <Route path={`${currentRoute.path}${nestedComponent.path}`}>
                        <Layout>
                          <NestedComp {...props} />
                        </Layout>
                      </Route>
                    })
                  }

                </Switch>
              }
              {...props}
              routeMap={routeMap}
            />
          )}
        />


      }
      return (
        <Route
          key={currentRoute.path}
          path={currentRoute.path}
          exact={currentRoute.exact}
          render={props => (
            <Layout>
              <Wrapper element={<Component />} {...props} routeMap={routeMap} />
            </Layout>
          )}
        />
      );
    }
  });
};

WebRoutesGenerator.propTypes = {
  routeMap: PropTypes.object
};

export default WebRoutesGenerator;
