// Customizable Area Start
import React from "react";
import {
    Typography, Box, Table, TableHead, TableCell, TableBody, TableRow
} from "@material-ui/core";
import { createTheme, withStyles } from "@material-ui/core/styles";
import DescriptionPageController, { configJSON, Props } from "./DescriptionPageController.web";
// Customizable Area End

// Customizable Area Start
const theme = createTheme({});
// Customizable Area End
class DescriptionPage extends DescriptionPageController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
        // Customizable Area Start
        const { classes }: any = this.props
        const { quoteInformation }: any = this.state
        // Customizable Area End
        return (
            // Customizable Area Start
            <>
                <Box>
                    <Typography>Page Count: {quoteInformation.pdf_page_count}</Typography>
                    <Typography>Id: {quoteInformation.url_id.id}</Typography>
                    <Typography>Name: {quoteInformation.url_id.name}</Typography>
                    <Typography>Record TYpe: {quoteInformation.url_id.record_type}</Typography>
                    <Typography>Record Id: {quoteInformation.url_id.record_id}</Typography>
                    <Typography>Blob Id: {quoteInformation.url_id.blob_id}</Typography>
                    <Typography>Date: {quoteInformation.url_id.created_at}</Typography>
                    <Typography>{quoteInformation.url_id.default_image}</Typography>
                </Box>
                <Box>
                    <iframe src={quoteInformation.url} className={classes.QuoteDocpdfBox} />
                </Box>
                <Box>
                    <Typography>{configJSON.idTitle}: {quoteInformation.quotes.data.id}</Typography>
                    <Typography>{configJSON.Data_type}: {quoteInformation.quotes.data.type}</Typography>
                    <Typography>{configJSON.idTitle}: {quoteInformation.quotes.data.attributes.id}</Typography>
                    <Typography>{configJSON.Quote_Id}: {quoteInformation.quotes.data.attributes.quote_id}</Typography>
                    <Typography>{configJSON.ReportId}: {quoteInformation.quotes.data.attributes.report_id}</Typography>
                    <Typography>{configJSON.DateTitle}: {quoteInformation.quotes.data.attributes.date}</Typography>
                    <Typography>{configJSON.Vendor}: {quoteInformation.quotes.data.attributes.vendor}</Typography>
                    <Typography>{configJSON.Project}: {quoteInformation.quotes.data.attributes.project}</Typography>
                    <Typography>{configJSON.Status}: {quoteInformation.quotes.data.attributes.status}</Typography>
                    <Typography>{configJSON.TotalPrice}: {quoteInformation.quotes.data.attributes.total_price}</Typography>
                    <Typography>{configJSON.Supplier_Address}: {quoteInformation.quotes.data.attributes.supplier_address}</Typography>
                    <Typography>{configJSON.SupplierContact} {quoteInformation.quotes.data.attributes.supplier_contact}</Typography>
                    <Typography>{configJSON.SupplierSalesperson}: {quoteInformation.quotes.data.attributes.supplier_salesperson}</Typography>
                    <Typography>{configJSON.SiteAddress}: {quoteInformation.quotes.data.attributes.site_address}</Typography>
                    <Typography>{configJSON.SiteContactperson}: {quoteInformation.quotes.data.attributes.site_contactperson}</Typography>
                    <Typography>{configJSON.SiteContact}: {quoteInformation.quotes.data.attributes.site_contact}</Typography>
                    <Typography>{configJSON.Subtotal}: {quoteInformation.quotes.data.attributes.subtotal}</Typography>
                    <Typography>{configJSON.SandHCharges}: {quoteInformation.quotes.data.attributes.s_and_h_charges}</Typography>
                    <Typography>{configJSON.Tax}: {quoteInformation.quotes.data.attributes.tax}</Typography>
                    <Typography>{configJSON.OtherCharges}: {quoteInformation.quotes.data.attributes.other_charges}</Typography>
                    <Typography>{configJSON.Taxamount}: {quoteInformation.quotes.data.attributes.tax_rate_amount}</Typography>
                    <Typography>{configJSON.Discount}: {quoteInformation.quotes.data.attributes.discount}</Typography>
                    <Typography>{configJSON.GrossSubtotal}: {quoteInformation.quotes.data.attributes.gross_subtotal}</Typography>
                    <Typography>{configJSON.ExpirationDate}: {quoteInformation.quotes.data.attributes.expiration_date}</Typography>
                    <Typography>{configJSON.created_atTitle}: {quoteInformation.quotes.data.attributes.created_at}</Typography>
                    <Typography>{configJSON.updated_atTitle}: {quoteInformation.quotes.data.attributes.updated_at}</Typography>
                </Box>
                <Box>

                    {
                        quoteInformation.quote_descriptions.map((data: any) => {
                            return (
                                <>
                                    <pre>{data.id}
                                    {data.description}
                                    {data.ord_quantity}
                                    {data.unit}
                                    {data.price}
                                    {data.amount}
                                    {data.report_quote_id}
                                    {data.created_at}
                                    {data.updated_at}
                                    {data.part_number}
                                    {data.tax}
                                    {data.category_name}
                                    {data.category_subtotal}</pre>
                                </>
                            )
                        })

                    }

                </Box>
            </>
            // Customizable Area End
        );
    }
}

// Customizable Area Start

export const descriptionStyle = (theme: any) => ({
    QuoteDocpdfBox: {
        height: '100vh',
        display: 'block',
        maxHeight: '100%',
        width: '100%'
    },
    headerReportBox: {
        '& th:nth-child(1)': {
            '& img': {
                display: 'block'
            }
        },
        '& th': {
            '& img': {
                display: 'none'
            }
        },
    }
})

export { DescriptionPage }
export default withStyles(descriptionStyle)(DescriptionPage)


// Customizable Area End
