import React, { Component } from 'react'
import { Box, TextField } from "@material-ui/core"
import { styled } from '@material-ui/styles'
import { View } from 'react-native'
import NotificationPopoverWeb from "./NotificationPopoverWeb.web"
import SearchIcon from '@material-ui/icons/Search';
import NotificationWebController, { Props } from './NotificationWebController';

const StyledTextField = styled(TextField)({
    '& .MuiInputBase-input': {
        height: 'auto !important',
        padding: '14px',
        lineHeight: '10px',
        paddingLeft: "44px",
    },
    '& .MuiOutlinedInput-root': {
        borderRadius: '8px',
    },
    '& .MuiInputLabel-outlined': {
        transform: 'translate(14px, 16px) scale(1)',
        paddingLeft: '3px'
    }
})
export default class HeaderWeb extends NotificationWebController {
    constructor(props: Props) {
        super(props)
        // Customizable Area Start
        // Customizable Area End
    }
    render() {
        return (
            <>
                {/* 
    // @ts-ignore */}
                <Box style={webStyle.container}>
                    {/* 
    // @ts-ignore */}
                    <Box style={webStyle.searchBox}>
                        {/* 
    // @ts-ignore */}


                        <View style={webStyle.formBox}>
                            {/* 
    // @ts-ignore */}

                            <StyledTextField id="outlined-basic" data-test-id="seacrhFieldTop" placeholder="Search..." variant="outlined" />
                            {/* 
    // @ts-ignore */}

                            <SearchIcon data-test-id="searchIconTop" style={webStyle.searchIcon} />
                        </View>

                    </Box>
                    {/* 
    // @ts-ignore */}

                    <Box>
                        {/* 
    // @ts-ignore */}

                        <NotificationPopoverWeb history={this.props.history} />
                    </Box>
                </Box>
            </>
        )
    }
}
const webStyle = {
    container: {
        display: "flex",
        padding: "15px",
        justifyContent: "center",
        alignItems: "center",
    },
    searchBox: {
        position: "relative",
        left: "-2px",
        top: "10px",
        width: "75%",
    },
    formBox: {
        position: 'relative',
        width: "75%"
    },
    searchIcon: {
        position: "relative",
        bottom: "34px",
        left: "12px",
    }

}