// Customizable Area Start
import React from "react";
import { Box, Typography, Button } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import DeleteModalController, { Props, configJSON } from "./DeleteModalController.web";
// Customizable Area Endo

class DeleteModal extends DeleteModalController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes, handleClose, DeleteItemsHandler, deleteHandler, deleteCategory, selectedItem } = this.props
    // Customizable Area End

    return (
      // Customizable Area Start
          <Box className={classes.deletemodalBox}>
            <Box className={classes.deleteIconBox}>
                <ErrorOutlineIcon/>
                <Typography>{configJSON.deleteItemTitle}</Typography>
            </Box>
            <Typography className={classes.deleteItemContent}>{configJSON.deleteItemContent}</Typography>
            {
              selectedItem.length > 1 ?
              <Typography className={classes.deleteMultipleItemContent}>{configJSON.deleteMultipleItemContent}</Typography>:
              <Typography className={classes.deleteMultipleItemContent}>{configJSON.deleteSingleItemContent}</Typography>
            }
            
            <Box className={classes.exportbuttonBox}>
                <Button onClick={handleClose} data-testid="canceldeleteId">{configJSON.invoiceCancel}</Button>
                <Button onClick={() => {DeleteItemsHandler(); deleteHandler(); deleteCategory()}} data-testid="deleteButtonId">{configJSON.deleteButton}</Button>
            </Box>
          </Box>
      // Customizable Area End
    );
  }
}

// Customizable Area Start

export const deleteModalStyle = () => ({
deletemodalBox: {
    padding: "20px 25px 40px",
    background: "#ffffff",
    width: "520px",
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    borderRadius: "10px",
  },

  deleteIconBox:{
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& p':{
        color: '#000000',
        fontSize: '20px',
        fontWeight: 600,
        marginLeft: '10px',
    },
    '& svg':{
        color: '#e0802e'
    }
  },

  deleteItemContent:{
    color: '#000000',
    margin: '20px auto',
    textAlign: 'center' as 'center',
    fontWeight: 500,
    fontSize: '16px',
  },

  deleteMultipleItemContent:{
    color: '#e0802e',
    margin: '20px auto 35px',
    textAlign: 'center' as 'center',
    fontWeight: 600,
    fontSize: '16px',
    maxWidth: '90%'
  },

  exportbuttonBox: {
    textAlign: "center" as "center",
    "& button": {
      border: "1px solid #000000",
      padding: "12px 0px",
      width: '150px',
      textTransform: "unset",
      color: "#000000",
      fontSize: "20px",
      lineHeight: "22px",
      "&:hover": {
        background: "#000000",
        color: "#ffffff"
      },
      "&:first-child": {
        marginRight: "20px"
      }
    }
  }
});
export { DeleteModal };
export default withStyles(deleteModalStyle)(DeleteModal);

// Customizable Area End
