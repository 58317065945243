// Customizable Area Start
import React from "react";
import { withStyles } from "@material-ui/core/styles";

import Header from "../../landingpage/src/Header.web";
import DashboardNewTable from "../../landingpage/src/DashboardNewTable.web";
import ReportQuotesController, { Props, configJSON } from "./ReportQuotesController.web";

export class ReportQuotes extends ReportQuotesController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    render() {
        return (
            <>
                <Header
                    data-testid="ReportsQuoteHeaderId"
                    handleSaveData={this.handleSaveData}
                    navigation={this.props.navigation}
                    history={this.props.history}
                />

                <DashboardNewTable
                    rows={this.state.quotesData}
                    title={configJSON.reportQuotesTitle}
                    hasMenu={true}
                    hasStatusData={true}
                    navigatePath="/report/quotes"
                />
            </>
        );
    }
}

export const webStyle = {};

export default withStyles(webStyle)(ReportQuotes)

// Customizable Area End
