// Customizable Area Start
import React from "react";
import {
    Box,
    Typography,
    Grid,
    Link,
    Modal,
    FormControl,
    Select,
    MenuItem,
    OutlinedInput,
    IconButton,
    Button,
    Tabs,
    Tab
} from "@material-ui/core";
import { styled } from "@material-ui/styles";
import { createTheme, withStyles } from "@material-ui/core/styles";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import DateRange from '@material-ui/icons/DateRange';
import moment from "moment";
import { deleteIcon, downloadIcon, editIcon, filterIcon, closeIcon, deleteMsgIcon } from "./assets";
import Header from "../../landingpage/src/Header.web";
import DateRangeDialog from "../../landingpage/src/DateRangeDialog.web";
import RejectDocumentsController, { Props, TabPanelProps, configJSON } from "./RejectDocumentsController.web";
// Customizable Area End


// Customizable Area Start
export const theme = createTheme({});
// Customizable Area End

//Customizable Area Start
export const RejectTabPanel = (props: TabPanelProps) => {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}
export const rejectA11yProps = (index: number) => {
    return {
        id: `vertical-tab-${index}`,
        'aria-controls': `vertical-tabpanel-${index}`,
    };
}

const StyledRejectButton = styled(Button)({
    textTransform: 'capitalize',
    fontSize: '0.75rem',
    fontWeight: 500,
});
//Customizable Area End
class RejectDocuments extends RejectDocumentsController {
    constructor(props: Props) {
        super(props);
    }
    // Customizable Area Start
    // Customizable Area End
    render() {

        // Customizable Area Start
        const { classes, handleSaveData }: any = this.props
        // Customizable Area End

        return (
            // Customizable Area Start
            <>
                <DateRangeDialog
                    data-testid="dateRangeDailogId"
                    handleDateRangeModal={this.handleRejectDateRangeOpenModal}
                    hasDateRange={this.state.hasDateRangeRejectModal}
                    handleDateRangeValue={this.handleRejectSelectDateRangeValue}
                    dateRangeValue={this.state.dateRejetRangeData}
                    navigation={this.props.navigation}
                />

                <>
                    <Header navigation={this.props.navigation} handleSaveData={handleSaveData} history={this.props.history} />
                    <Box className={classes.rejectDocumentBox}>
                        <Box className={classes.rejectDocumentInnerBox}>
                            <Box className={classes.rejectDocumenTitletBox}>
                                <Typography variant="h6">Documents</Typography>
                                <Box className={classes.rejectCurveBox}>
                                    <Box className={classes.rejectCurveInnerBox}></Box>
                                </Box>

                                <Box className={classes.rejectCurveRightBox}>
                                    <Box className={classes.rejectCurveRightInnerBox}></Box>
                                </Box>

                                <Typography>You have {this.state.rejectDocumentsData.length} documents</Typography>
                            </Box>
                        </Box>
                        <Box className={classes.rejectTabBox}>
                            {
                                this.state.rejectDocumentsData.length > 0 ?
                                    <>
                                        <Tabs
                                            orientation="vertical"
                                            variant="scrollable"
                                            value={this.state.value}
                                            onChange={this.handleChange}
                                            data-testid="handleChange"
                                            aria-label="Vertical tabs example"
                                            className={classes.rejectTabs}
                                        >
                                            {
                                                this.state.rejectDocumentsData.map((item: any) => {
                                                    return (
                                                        <Tab
                                                            label={
                                                                <>
                                                                    <Typography variant="h6">{item?.project_name?.toLowerCase() || item?.project?.toLowerCase()}</Typography>
                                                                    <Typography>{item.approval_status}</Typography>
                                                                </>
                                                            }
                                                            {...rejectA11yProps(0)}
                                                            className={classes.rejectTabMenuBox}
                                                        />
                                                    )
                                                })
                                            }
                                        </Tabs>
                                        <Box className={classes.rejectTabpanelBox}>
                                            <Box className={classes.filterRejectDocBox} onClick={this.handleOpenRejectFilterModal} data-testid="filterModalid">
                                                <img src={filterIcon} alt="fliter" />
                                                <Typography>{configJSON.filterItemButton}</Typography>
                                            </Box>
                                            {
                                                this.state.rejectDocumentsData.map((item: any, index: number) => {
                                                    return (
                                                        <>
                                                            <Box className={classes.rejectTabpanelInnerBox} data-testid="tabpanelid">

                                                                <RejectTabPanel value={this.state.value} index={index} >
                                                                    <Grid container key={index} className={classes.tabpanelRejectDocsContentBox}>
                                                                        <Grid item xs={6}>
                                                                            <Box className={classes.rejectDocumentabPanelTitle}>
                                                                                <Typography variant="h6">{item?.project_name?.toLowerCase() || item?.project?.toLowerCase()}</Typography>
                                                                            </Box>
                                                                            <Typography>{moment(item.created_at || item.date).format("D MMMM YYYY, hh:mm A")}</Typography>
                                                                        </Grid>
                                                                        <Grid item xs={6} className={classes.functionRejectDocsBox}>
                                                                            <Box className={classes.iconRejectDocsBox}>
                                                                                <Link href={item.invoice_pdf|| item.pdf_url} download="test_quote.pdf"><img src={downloadIcon} alt="downloadImg" /></Link>
                                                                                <img src={editIcon} alt="editicon" />
                                                                                <img src={deleteIcon} alt="deleteicon" data-testid={`deleteModalid-${index}`} onClick={() => this.handleOpenRejectDeleteModal(item.id)}/>
                                                                            </Box>
                                                                        </Grid>
                                                                    </Grid>
                                                                    <Box>
                                                                        <iframe src={item.invoice_pdf || item.pdf_url} className={classes.rejectDocpdfBox} />
                                                                    </Box>
                                                                </RejectTabPanel>
                                                            </Box>
                                                        </>
                                                    )
                                                })
                                            }
                                        </Box>
                                    </>
                                    : <Typography className={classes.rejectRecordContent}>{configJSON.allDocRecordcontent}</Typography>}
                        </Box>
                    </Box>

                    <Modal open={this.state.filterRejectDocModal} onClose={this.handleCloseRejectDocFilterModal}>
                        <Box className={classes.rejectfilterModalBox}>
                            <Box className={classes.filterRejectInnerBox}>
                                <Typography variant="h5">{configJSON.filterItemButton}</Typography>
                                <img src={filterIcon} alt="fliter" />
                            </Box>
                            <Grid container spacing={2} className={classes.rejectProjectSearchBox}>
                                <Grid item lg={6} md={6} className={classes.selectVendorBox}>
                                    <FormControl className={classes.formControlrejectBox} size="small">
                                        <Box className={classes.vendorTitleRejectBox} id="demo-select-small-label">
                                            <Typography variant="h6">{configJSON.vendorTitle}</Typography>
                                        </Box>
                                        <Select
                                            labelId="demo-select-small-label"
                                            id="demo-select-small"
                                            IconComponent={ExpandMoreIcon}
                                            input={<OutlinedInput />}
                                            displayEmpty
                                            value={this.state.vendorRejectDocValue}
                                            MenuProps={{
                                                anchorOrigin: {
                                                    vertical: "bottom",
                                                    horizontal: "left"
                                                },
                                                transformOrigin: {
                                                    vertical: "top",
                                                    horizontal: "left"
                                                },
                                                getContentAnchorEl: null
                                            }}
                                            onChange={e => this.selectRejectDocVendorValue(e)}
                                            data-testid={"vendorTestID"}
                                        >
                                            <MenuItem disabled value="">{configJSON.placeholdervendorTitle}</MenuItem>
                                            {this.state.vendorOptionRejectDocValue.map((item: string, index: number) => {
                                                return (
                                                    <MenuItem key={item} value={item} data-testid={`selectMenuItem-${index}`}>{item}</MenuItem>
                                                )
                                            })}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item lg={6} md={6} className={classes.selectVendorBox}>
                                    <FormControl className={classes.formControlrejectBox} size="small">
                                        <Box className={classes.vendorTitleRejectBox} id="demo-select-small-label">
                                            <Typography variant="h6">{configJSON.projectTitle}</Typography>
                                        </Box>
                                        <Select
                                            labelId="demo-select-small-label"
                                            id="demo-select-small"
                                            IconComponent={ExpandMoreIcon}
                                            input={<OutlinedInput />}
                                            displayEmpty
                                            value={this.state.projecRejectDocValue}
                                            MenuProps={{
                                                anchorOrigin: {
                                                    vertical: "bottom",
                                                    horizontal: "left"
                                                },
                                                transformOrigin: {
                                                    vertical: "top",
                                                    horizontal: "left"
                                                },
                                                getContentAnchorEl: null
                                            }}
                                            onChange={e => this.selectrejectDocProjectValue(e)}
                                            data-testid={"projectTestID"}
                                        >
                                            <MenuItem disabled value="">{configJSON.placeholderprojectTitle}</MenuItem>
                                            {this.state.ProjectOptionRejectDocValue.map((item: string, index: number) => {
                                                return (
                                                    <MenuItem key={item} value={item} data-testid={`selectMenuItem-${index}`}>{item}</MenuItem>
                                                )
                                            })}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item lg={12} md={12} className={classes.rejectDocselectDateRangeBox}>
                                    <Typography variant="h6">{configJSON.dateRangeTitle}</Typography>
                                    <Box
                                        data-testid="btnDateRangePicker"
                                        onClick={this.handleRejectDateRangeOpenModal}
                                        style={{ width: '100%', padding: '0 10px', marginTop: '15px', marginBottom: '30px' }}
                                    >
                                        <StyledRejectButton className={classes.rejectDocbuttonStyleCal}>
                                            <IconButton>
                                                <DateRange/>
                                            </IconButton>
                                            {`${this.state.dateRejetRangeData[0].startDate.toDateString()}-${this.state.dateRejetRangeData[0].endDate.toDateString()}`}
                                        </StyledRejectButton>
                                    </Box>
                                </Grid>
                                <Box className={classes.rejectCancelApplyButtonBox}>
                                    <Button onClick={this.handleCloseRejectDocFilterModal} data-testid="cancelButton">{configJSON.cancelButton}</Button>
                                    <Button onClick={this.handleRejectProjectsSearch} data-testid="applyButton">{configJSON.applyButton}</Button>
                                </Box>
                            </Grid>
                        </Box>
                    </Modal>

                    <Modal
                        open={this.state.deleteRejectDocModal}
                        onClose={this.handleCloseRejectRDeleteModal}
                    >
                        <Box className={classes.rejectdocumentDeletemodalBox}>
                            <Box className={classes.rejectCloseIconBox}>
                                <img src={closeIcon} alt="close" onClick={this.handleCloseRejectRDeleteModal} />
                            </Box>
                            <Box className={classes.rejectDeleteMsgIconBox}>
                                <img src={deleteMsgIcon} alt="upload" />
                            </Box>
                            <Typography>{configJSON.deleteContent}</Typography>
                            <Box className={classes.rejectDeleteButtonBox}>
                                <Button onClick={this.handleCloseRejectRDeleteModal} data-testid="modalclosedId"> {configJSON.deleteNoButton} </Button>
                                <Button onClick={this.handleRejectDeleteDocument} data-testid="yesmodalclosedId"> {configJSON.deleteYesButton} </Button>
                            </Box>
                        </Box>
                    </Modal>
                </>
            </>
            // Customizable Area End
        );
    }
}

// Customizable Area Start

export const rejectdocumentstyle = (theme: any) => ({
    rejectDocumentBox: {
        background: '#fff',
        padding: '0 0 0 0',
        border: '1px solid #e0802e',
        borderTopLeftRadius: '15px',
        borderTopRightRadius: '15px',
    },
    rejectDocumentInnerBox: {
        background: '#fcf7f2',
        padding: '10px 0 0 30px',
        width: '100%',
        borderTopLeftRadius: '15px',
        borderTopRightRadius: '15px',
    },
    rejectDocumenTitletBox: {
        background: '#ffffff',
        width: '300px',
        textAlign: 'center' as 'center',
        border: '1px solid #e0802e',
        borderTopLeftRadius: '18px',
        borderTopRightRadius: '18px',
        borderBottom: 'none',
        paddingBottom: '20px',
        position: 'relative' as 'relative',
        top: '2px',
        zIndex: 1,
        '& h6': {
            color: '#e0802e',
            fontWeight: '600'
        },
        '& p': {
            color: '#e0802e',
        }
    },
    rejectTabBox: {
        display: 'flex',
        justifyContent: 'flex-start',
        background: '#ffffff',
        position: 'relative' as 'relative',
        borderTop: '1px solid #e0802e',
        width: '100%',
    },
    rejectTabs: {
        border: '1px solid #eee',
        margin: '10px',
        borderRadius: '10px',
        maxWidth: '25%',
        flex: '0 0 25%',
        padding: '15px 0',
        '& button': {
            borderBottom: '1px solid #eee',
            padding: '18px 0',
            maxWidth: '320px',
            opacity: 1,
            [theme.breakpoints.down('1610')]: {
                maxWidth: '300px',
            },
            '&.Mui-selected': {
                background: '#e0802e',
                borderRadius: '10px',
                padding: '15px',
                [theme.breakpoints.down('1610')]: {
                    padding: '10px',
                },
                '& p': {
                    color: '#ffffff',
                    fontWeight: '500'
                },
                '& h6': {
                    color: '#ffffff',
                    fontWeight: '600',
                    [theme.breakpoints.down('1610')]: {
                        fontSize: '18px'
                    },
                },
            }
        },
        '& span': {
            alignItems: 'unset',
            textTransform: 'capitalize',
        },
        '& h6': {
            color: '#131324',
            [theme.breakpoints.down('1610')]: {
                fontSize: '18px'
            },
        },
        '& div:nth-child(2)': {
            margin: '0 10px 0 15px'
        },
        '& span.MuiTabs-indicator': {
            display: 'none'
        }
    },
    rejectTabMenuBox: {
        textAlign: 'left' as 'left'
    },
    rejectTabpanelBox: {
        maxWidth: '73%',
        border: '1px solid #000',
        margin: '15px 0',
        borderRadius: '8px',
        flex: '0 0 73%',
        padding: '15px',
        position: 'relative' as 'relative',
    },
    filterRejectDocBox: {
        border: '1px solid #000',
        borderRadius: '4px',
        padding: '6px 15px !important',
        display: 'inline-block',
        textAlign: 'center' as 'center',
        position: 'absolute' as 'absolute',
        right: '18%',
        top: '23px',
        [theme.breakpoints.down('1380')]: {
            padding: '6px 7px !important',
        },

        '& img': {
            width: '25px',
            height: '25px'
        },
        '& p': {
            display: 'inline-block',
            paddingLeft: '10px',
            color: '#000'
        }
    },
    rejectCurveBox: {
        height: '30px',
        width: '31px',
        background: '#fcf7f2',
        position: 'absolute' as 'absolute',
        bottom: 0,
        left: '-31px',
    },
    rejectCurveInnerBox: {
        border: '1px solid #e0802e',
        height: '29px',
        width: '31px',
        borderBottomRightRadius: '15px',
        borderTop: 'none',
        borderLeft: 'none'
    },
    rejectCurveRightBox: {
        height: '32px',
        width: '30px',
        background: '#fcf7f2',
        position: 'absolute' as 'absolute',
        bottom: 0,
        right: '-30px',
    },
    rejectCurveRightInnerBox: {
        border: '1px solid #e0802e',
        height: '31px',
        width: '30px',
        borderBottomLeftRadius: '15px',
        borderTop: 'none',
        borderRight: 'none'
    },
    rejectTabpanelInnerBox: {
        '& div:nth-child(1)': {
            padding: '0',
        },
    },
    rejectDocumentabPanelTitle: {
        color: '#000',
        display: 'flex',
        alignItems: 'center',
        '& h6': {
            textTransform: 'capitalize' as 'capitalize',
            [theme.breakpoints.down('1380')]: {
                fontSize: '16px'
            },
            '& span': {
                fontSize: '18px',
                fontWeight: '400',
                textTransform: 'capitalize' as 'capitalize',
            }
        },
    },

    tabpanelRejectDocsContentBox: {
        marginBottom: '10px'
    },

    functionRejectDocsBox: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'end'
    },

    iconRejectDocsBox: {
        display: 'inline-block',
        position: 'absolute' as 'absolute',
        top: '27px',
        right: '18px',
        '& img': {
            width: '30px',
            height: '30px',
            marginLeft: '30px',
            [theme.breakpoints.down('1500')]: {
                marginLeft: '10px',
            },
        }
    },
    // pdfBox: {
    //     height: '100vh',
    //     display: 'block',
    //     maxHeight: '100%',
    //     width: '100%'
    // },
    // tabpanelContentBox: {
    //     marginBottom: '10px'
    // },
    rejectfilterModalBox: {
        width: '46%',
        background: '#fff',
        borderRadius: '10px',
        border: '1px solid #000',
        position: 'absolute' as 'absolute',
        left: '39%',
        top: '26%',
        overflow: 'hidden'
    },

    filterRejectInnerBox: {
        margin: '25px',
        '& h5': {
            color: '#000',
            display: 'inline-block',
            marginRight: '20px',
            fontWeight: 600,
        },
        '& img': {
            width: '25px',
            marginTop: '-3px'
        }
    },

    rejectProjectSearchBox: {
        borderTop: '1px solid #ddd',
        padding: '5px 25px 25px',
    },

    formControlrejectBox: {
        width: '100%',
        '& input': {
            border: '1px solid #eae1e1',
            padding: '14px',
            borderRadius: '5px',
        },
        '& fieldset': {
            border: '1px solid #eae1e1',
        },
        '& svg': {
            right: '15px'
        }
    },

    vendorTitleRejectBox: {
        paddingBottom: '10px',
        '& h6': {
            fontWeight: 600,
            color: '#000'
        }
    },

    rejectDocbuttonStyleCal: {
        minHeight: '1.1876em',
        padding: '0px 8px',
        border: 'solid 1px rgba(0, 0, 0, 0.23)',
        width: '100%',
        justifyContent: 'flex-start',
        backgroundColor: 'rgb(223, 128, 45)'
    },
    rejectDocselectDateRangeBox: {
        '& h6': {
            fontWeight: 600,
            color: '#000'
        }
    },
    rejectCancelApplyButtonBox: {
        textAlign: "right" as "right",
        width: '100%',
        "& button": {
            padding: "15px 60px",
            textTransform: "unset",
            color: "#000",
            fontSize: "20px",
            lineHeight: "22px",

            "&:hover": {
                background: "#e0802e",
                color: "#ffffff"
            },
            "&:first-child": {
                marginRight: "40px"
            }
        }
    },
    // recordContent: {
    //     margin: '10% auto',
    //     height: '70vh',
    // },
    rejectdocumentDeletemodalBox: {
        padding: "28px 30px 50px",
        background: "#fff",
        width: "560px",
        position: "absolute" as "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        borderRadius: "10px",
        '& p': {
            fontSize: "20px",
            fontWeight: 500,
            color: "#000000",
            textAlign: "center" as "center",
            margin: "25px",
        }
    },

    rejectCloseIconBox: {
        textAlign: 'right' as 'right',
        '& img': {
            marginBottom: "10px",
            width: "35px",
            height: "35px"
        }
    },

    rejectDeleteMsgIconBox: {
        textAlign: 'center' as 'center',
        '& img': {
            width: "100px",
            height: "100px",
        }
    },

    rejectDeleteButtonBox: {
        textAlign: 'center' as 'center',
        '& button': {
            border: "1px solid #e0802e",
            width: '100px',
            padding: "15px 0px",
            textTransform: "unset" as "unset",
            color: "#e0802e",
            fontSize: "20px",
            lineHeight: "22px",
            margin: "0 20px",
            '&:hover': {
                background: "#e0802e",
                color: "#fff"
            }
        }
    },

    rejectDocpdfBox: {
        height: '100vh',
        display: 'block',
        maxHeight: '100%',
        width: '100%'
    },

    rejectRecordContent:{
        margin: '20% auto',
        height: '70vh',
    }

})
export { RejectDocuments }
export default withStyles(rejectdocumentstyle)(RejectDocuments)


// Customizable Area End
