import React from 'react'
// Customizable Area Start
import { Grid, Container } from "@material-ui/core"
import { Image, View } from 'react-native'
import { withRouter } from 'react-router-dom';
import { Logo, preview } from './assets'
import ForgotPasswordWeb from './ForgotPasswordWeb.web'
import ResetPasswordWeb from './ResetPasswordWeb.web.'
// Customizable Area End
import ForgotAndResetPasswordWebController, { Props } from './ForgotAndResetPasswordWebController'
class BackGroundScreenWeb extends ForgotAndResetPasswordWebController {
    constructor(props: Props) {
        super(props)
    }
    render() {
        return (
            <Container style={webStyle.parentContainer}>
                {/* 
        // @ts-ignore */}
                <View style={webStyle.firstView}>
                    {/* 
        // @ts-ignore */}
                    <View style={webStyle.secondView}>
                        {/* 
        // @ts-ignore */}
                        <Grid container style={webStyle.parentGrid} spacing={3}>
                            <Grid item xs={7}>
                                {/* 
        // @ts-ignore */}
                                <span style={webStyle.logoWrap} onClick={() => {this.gotoHome()}}>
                                {/* 
        // @ts-ignore */}           
                                    <Image
                                        style={{ width: '200px', minHeight: '52px' }}
                                        source={Logo}
                                    />
                                </span>
                                {/* 
        // @ts-ignore */}
                                <View style={webStyle.secondImageView}>
                                    {/* 
        // @ts-ignore */}

                                    <Image style={webStyle.preivewImage}
                                        source={preview}
                                    />
                                </View>
                            </Grid>

                            <Grid item xs={5}>
                                {/* 
        // @ts-ignore */}
                                <View style={webStyle.FormView}>
                                    {/* 
        // @ts-ignore */}
                                    {this.state.flag ? <ResetPasswordWeb prop={this.props} /> : <>
                                        {/* 
        // @ts-ignore */}
                                        <ForgotPasswordWeb /> </>}
                                </View>

                            </Grid>
                        </Grid>
                    </View>
                </View>
            </Container>
        )
    }
}
{/* 
        // @ts-ignore */}
export default withRouter(BackGroundScreenWeb)
// Customizable Area Start
const webStyle = {
    preivewImage: {
        width: '100%',
        minHeight: '430px',
        bottom: '-30px',
    },
    parentGrid: {
        alignItems: 'center',
    },
    parentContainer: {
        height: 'calc(100vh - 50px)',
        display: "flex",
        alignItems: 'center',
        justifyContent: 'center',
    },
    firstView: {
        background: 'linear-gradient(90deg, rgba(243,243,255,1) 0%, rgba(255,255,255,1) 50%, #d3d3d3 100%)',
        width: '100%',
        padding: '1px',
        borderRadius: "22px",
    },
    secondView: {
        width: '100%',
        background: 'linear-gradient(90deg, #f3f3ff, #fff)',
        borderRadius: "22px",
        paddingTop: '30px',
        paddingBottom: '30px',
        paddingLeft: '45px',
        paddingRight: '45px',
        flexShrink: 'inherit',
    },
    secondImageView: {
        paddingRight: '100px',
        marginTop: '180px'
    },
    FormView: {
        background: '#fff',
        padding: '35px',
        border: '1px solid rgb(243 241 241)',
        borderRadius: "12px",
        boxShadow: '23px 9px 36px -10px rgb(124 120 120 / 27%)',
        height: '540px',
        position: 'relative',
    },
    logoWrap: {
        cursor: 'pointer',
        display: 'block'
    }

}
 // Customizable Area End