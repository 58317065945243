// Customizable Area Start
import React from "react";
import {
    MenuItem,
    FormControl,
    Select,
    Box,
    Typography,
    OutlinedInput,
    Button,
    Tabs,
    Tab,
    Modal,
    TextField,
    Link
} from "@material-ui/core";
import { styled } from "@material-ui/styles";
import InvoiceUploadController, { Props, RenderDialogProps, TabPanelProps, configJSON } from "./InvoiceUploadController.web";
import { createTheme, withStyles } from "@material-ui/core/styles";
import Dropzone from "react-dropzone";
import { filesUploadIcon, uploadFailedIcon, uploadSuccessIcon } from "../../landingpage/src/assets";
import { cancelupload, closeIcon, failedUpload, pauseUpload, reportIcon1, successUpload } from "./assets";
import LinearProgress from '@material-ui/core/LinearProgress';
import Header from "../../landingpage/src/Header.web";
import { DocumentOpener } from "./DocumentOpener.web";
import CustomLoader from "../../../framework/src/CustomLoader.web";
import { DialogTitle } from "../../landingpage/src/QuotesUploadNewDialog.web";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
// Customizable Area End


// Customizable Area Start
export const theme = createTheme({});
// Customizable Area End

//Customizable Area Start
const StyledUploadInvoiceButton = styled(Button)({
    width: '50%',
    border: '1px solid #e0802e',
    borderRadius: '10px',
    display: "block",
    margin: "2% auto",
    padding: '20px 0',
    textTransform: 'unset',
    color: '#ffffff',
    fontSize: '22px',
    fontWeight: "bold",
    lineHeight: '22px',
    background: '#e0802e',
    "&.Mui-disabled": {
        background: "#fff",
        color: "#e0802e",
        fontWeight: "600",
    },
    "&:hover": {
        background: "#e0802e",
        color: "#ffffff"
    },
});

const StyledBrowseButton = styled(Button)({
    "&.MuiButton-root": {
        borderRadius: "8px",
        border: "1px solid #df802d",
        color: "#df802d",
        textTransform: "capitalize",
        fontWeight: "bold",
        fontSize: "22px"
    }
});

const StyledDialogView = styled(Dialog)({
    "& .MuiBackdrop-root": {
        backgroundColor: "rgba(1, 1, 1, 0.2)"
    },
    "& .MuiDialog-paperWidthSm": {
        maxWidth: "none",
        //height: "620px",
        display: "flex",
        borderRadius: "15px"
    },
    "& .MuiDialogActions-root": {
        justifyContent: "space-around"
    }
});

const StyledContentDialog = styled(DialogContent)({
    "&.MuiDialogContent-root": {
        padding: "0"
    }
});

export const TabPanel = (props: TabPanelProps) => {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

export const InvoiceDropbox = (props: any) => {
    const { classes, acceptedFile, onDrop } = props;

    return (
        <Box className={classes.uploadInvoiceBox}>
            <Typography className={classes.boxTitle} variant="h6">
                Upload Invoice
            </Typography>
            <div className={classes.dialogWrapper}>
                <Dropzone accept={acceptedFile} data-testid="dropzone" onDrop={onDrop}>
                    {({ getRootProps, getInputProps }: any) => (
                        <div className={classes.dropZoneStyle} {...getRootProps()}>
                            <img src={filesUploadIcon} alt="" />
                            <div className={classes.dialogDropZoneText}>
                                <span>Drag and Drop your files here </span>
                                <br />
                                <span>Or</span>
                            </div>
                            <input id="input" {...getInputProps()} />
                            <StyledBrowseButton data-testid="browseButton">
                                Browse
                            </StyledBrowseButton>
                        </div>
                    )}
                </Dropzone>
            </div>
        </Box>
    )
}

export const RenderDialog = (props: RenderDialogProps) => {
    const { openDialog, handleCloseDialog, apiError, message, isLoading } = props;
    return (
        <>
            {!isLoading &&
                <StyledDialogView
                    open={openDialog}
                    onClose={handleCloseDialog}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    {/*@ts-ignore*/}
                    <DialogTitle data-testid="closeButtonId" id="dialog-title" onClose={handleCloseDialog} />
                    <div style={webStyle.DialogContentWrapper}>
                        <StyledContentDialog>
                            <div style={webStyle.successZone}>
                                {!apiError ?
                                    <img src={uploadSuccessIcon} alt="" /> :
                                    <img src={uploadFailedIcon} alt="" />
                                }
                                <div style={webStyle.uploadMessage}>
                                    <span>{message}</span>
                                </div>
                            </div>
                        </StyledContentDialog>
                    </div>
                </StyledDialogView>
            }
        </>
    );
};

export const a11yProps = (index: number) => {
    return {
        id: `vertical-tab-${index}`,
        'aria-controls': `vertical-tabpanel-${index}`,
    };
}
//Customizable Area End
class InvoiceUpload extends InvoiceUploadController {
    constructor(props: Props) {
        super(props);
    }
    // Customizable Area Start
    // Customizable Area End
    render() {
        // Customizable Area Start
        const { classes, handleSaveData, navigation } = this.props;
        const { compareModal } = this.state;
        // Customizable Area End
        return (
            // Customizable Area Start
            <>
                <RenderDialog
                    data-testid="renderDialogId"
                    openDialog={this.state.openDialog}
                    handleCloseDialog={this.handleCloseDialog}
                    apiError={this.state.invoiceCompareError}
                    message={this.state.invoiceCompareResult}
                    isLoading={this.state.isLoading}
                />
                <Header navigation={navigation} handleSaveData={handleSaveData} history={this.props.history} />
                <Box className={classes.invoiceDocumentBox}>
                    <Box className={classes.invoiceDocumentInnerBox}>
                        <Box className={classes.invoiceDocumenTitleBox}>
                            <Typography variant="h6">Invoice Upload</Typography>
                            <Box className={classes.invoiceUploadCurveBox}>
                                <Box className={classes.curveInvoiceInnerBox}></Box>
                            </Box>

                            <Box className={classes.curveRightInvoiceBox}>
                                <Box className={classes.curveRightInvoiceInnerBox}></Box>
                            </Box>
                        </Box>
                    </Box>
                    <Box className={classes.invoiceTabBox}>
                        <Box className={classes.uploadText}>
                            <Typography variant="h6">Upload the invoice</Typography>
                            <Typography variant="body2">Lorem ipsum, dolor sit amet consectetur adipisicing elit. Quidem possimus a est laboriosam libero dignissimos doloribus consequuntur ad accusamus. Ipsum, tempore! Cumque pariatur labore enim aut perspiciatis, libero sapiente perferendis.</Typography>
                        </Box>
                        <Tabs
                            orientation="horizontal"
                            value={this.state.value}
                            onChange={this.handleChange}
                            data-testid="handleTabChange"
                            className={classes.tabsInvoice}
                        >
                            {
                                this.state.invoiceTab.map((item: any) => {
                                    return (
                                        <Tab
                                            label={
                                                <Box className={classes.tabText}>
                                                    <Typography className={classes.paragraph}>{("0" + item.id).slice(-2) + "."}</Typography>
                                                    <Typography className={classes.paragraph}>{item.type}</Typography>
                                                </Box>
                                            }
                                            {...a11yProps(0)}
                                            className={classes.invoiceTabMenuBox}
                                        />
                                    )
                                })
                            }
                        </Tabs>
                        <TabPanel value={this.state.value} index={0}>
                            <Box className={classes.invoiceDropbox}>
                                <Box className={classes.selectProjectBox}>
                                    <Typography variant="h6">Select project/vendor or upload invoice</Typography>
                                </Box>
                                <Box className={classes.selectProjectAndUploadInvoiceStyle}>
                                    <FormControl className={classes.contentWidth} size="small">
                                        <Box className={classes.boxTitle} id="demo-select-small-label">
                                            Select Project or Vendor
                                        </Box>
                                        <Select
                                            labelId="demo-select-small-label"
                                            id="demo-select-small"
                                            input={<OutlinedInput />}
                                            displayEmpty
                                            value={this.state.projectValue}
                                            MenuProps={{
                                                anchorOrigin: {
                                                    vertical: "bottom",
                                                    horizontal: "left"
                                                },
                                                transformOrigin: {
                                                    vertical: "top",
                                                    horizontal: "left"
                                                },
                                                getContentAnchorEl: null
                                            }}
                                            onChange={e => this.setProjectValue(e)}
                                            data-testid={"projectTestID"}
                                        >
                                            <MenuItem disabled value="">Choose Project</MenuItem>
                                            {this.state.projects.map((item: any, index: number) => {
                                                return (
                                                    <MenuItem key={item} value={item} data-testid={`selectMenuItem-${index}`}>{item}</MenuItem>
                                                )
                                            })}
                                        </Select>
                                    </FormControl>
                                    <InvoiceDropbox acceptedFile={this.state.acceptedFileType} classes={classes} onDrop={this.onInvoiceDrop} data-testid="invoiceDropboxId" />
                                </Box>
                                {
                                    this.state.projectValue.length > 0 && this.state.uploadAcceptedFile.length > 0 ?
                                        <StyledUploadInvoiceButton onClick={this.comparehandleOpen} data-testid={"uploadButton"}>
                                            Upload Invoice
                                        </StyledUploadInvoiceButton>
                                        :
                                        <StyledUploadInvoiceButton disabled data-testid={"uploadButton"}>
                                            Upload Invoice
                                        </StyledUploadInvoiceButton>
                                }
                            </Box>
                            <Modal
                                open={compareModal}
                                onClose={this.comparehandleClose}
                            >
                                <Box className={classes.uploadmodalBox}>
                                    <Box className={classes.uploadIconBox}>
                                        <img
                                            src={closeIcon}
                                            alt="close"
                                            onClick={this.comparehandleClose}
                                            data-testid="comparehandleCloseId"
                                        />
                                    </Box>
                                    <Box className={classes.uploadImageBx}>
                                        <img src={reportIcon1} alt="report" />
                                    </Box>
                                    <Typography>{configJSON.compareTitle}</Typography>
                                    <Box className={classes.comparebuttonBox}>
                                        <Button onClick={this.noComparefile} data-testid="NoButtonid" >{configJSON.noBtnTitle}</Button>
                                        <Button onClick={() => { this.yesComparefile(this.state.value) }} data-testid="yesButtonid">{configJSON.yesBtnTitle}</Button>
                                    </Box>
                                </Box>
                            </Modal>
                        </TabPanel>
                        <TabPanel value={this.state.value} index={1}>
                            <Box className={classes.invoiceDropbox}>
                                <Box className={classes.selectProjectBox}>
                                    <Typography variant="h6">Select project/vendor or upload invoice</Typography>
                                </Box>
                                <Box className={classes.selectProjectAndUploadInvoiceStyle}>
                                    <FormControl className={classes.contentWidth} size="small">
                                        <Box className={classes.contentLabel} id="demo-select-small-label">
                                            Select Project or Vendor
                                        </Box>
                                        <TextField value={this.state.projectValue} variant="outlined" />
                                    </FormControl>
                                    <InvoiceDropbox data-testid="invoiceDrop" acceptedFile={this.state.acceptedFileType} classes={classes} onDrop={this.onInvoiceDrop} />
                                </Box>
                            </Box>
                            <Box className={classes.uploadingBox}>
                                <Typography variant="h6">Uploading 1 file</Typography>
                                <Box className={classes.uploadingInnerBox}>
                                    <Typography>{this.state.progressVal.toFixed(0)} % - {this.state.timeRemaining.toFixed(0)} seconds remaining</Typography>
                                    <Box className={classes.pauseIconBox}>
                                        <img src={pauseUpload} alt="pauseUpload" />
                                        <img src={cancelupload} alt="cancelupload" />
                                    </Box>
                                </Box>
                                <LinearProgress variant="determinate" value={this.state.progressVal} data-testid="linearProgress" />
                            </Box>
                        </TabPanel>

                        <TabPanel value={this.state.value} index={2}>
                            {
                                !this.state.invoiceCompareError ?
                                    <Box className={classes.successModalBox}>
                                        <Box className={classes.successModalTitle}>
                                            <Typography variant="h6">{configJSON.invoiceTitle}</Typography>
                                        </Box>
                                        <Box className={classes.successContent}>
                                            <img src={successUpload} alt="successUpload" />
                                            <Typography>{this.state.invoiceCompareResult}</Typography>
                                            <Box className={classes.successButtons}>
                                                <span onClick={this.handleDocumentOpenerClick}><DocumentOpener navigation={undefined} id={""} classes={this.props.classes} isOpen={false} pdfUrl={this.state.pdf_id} /></span>
                                                <Link href={this.state.pdf_id} download="test_invoice.pdf"><Button>{configJSON.downloadButton}</Button></Link>
                                            </Box>
                                        </Box>
                                    </Box>
                                    :
                                    <Box className={classes.failedModalBox}>
                                        <Box className={classes.failedModalTitle}>
                                            <Typography variant="h6">{configJSON.invoiceTitle}</Typography>
                                        </Box>
                                        <Box className={classes.failedContent}>
                                            <img src={failedUpload} alt="failedUpload" />
                                            <Typography>{this.state.invoiceCompareResult}</Typography>
                                            <Box className={classes.failedbuttons}>
                                                <Button onClick={this.handleUploadClick} data-testid="uploadFailedButton">{configJSON.uploadButton}</Button>
                                                <Link href={this.state.pdf_id} download="test_invoice.pdf"><Button>{configJSON.downloadButton}</Button></Link>
                                            </Box>
                                        </Box>
                                    </Box>
                            }
                        </TabPanel>
                    </Box>
                </Box>
                <CustomLoader loading={this.state.invoiceUploadLoader} />

            </>
            // Customizable Area End
        );
    }
}

// Customizable Area Start

export const invoiceStyle = (theme: any) => ({
    invoiceDocumentBox: {
        background: '#fff',
        padding: '0 0 0 0',
        border: '1px solid #e0802e',
        borderTopLeftRadius: '15px',
        borderTopRightRadius: '15px',
        color: '#000000'
    },
    invoiceDocumentInnerBox: {
        background: '#fcf7f2',
        padding: '10px 0 0 30px',
        width: '100%',
        borderTopLeftRadius: '15px',
        borderTopRightRadius: '15px',
    },
    invoiceDocumenTitleBox: {
        background: '#ffffff',
        width: '300px',
        textAlign: 'center' as 'center',
        border: '1px solid #e0802e',
        borderTopLeftRadius: '18px',
        borderTopRightRadius: '18px',
        borderBottom: 'none',
        paddingBottom: '20px',
        position: 'relative' as 'relative',
        top: '2px',
        zIndex: 1,
        '& h6': {
            color: '#e0802e',
            fontWeight: '600'
        },
        '& p': {
            color: '#e0802e',
        }
    },
    invoiceTabBox: {
        background: '#ffffff',
        position: 'relative' as 'relative',
        borderTop: '1px solid red',
        width: '100%',
    },
    invoiceDropbox: {
        margin: '2% 10%',
        border: '1px solid #eee',
        borderRadius: '10px'
    },
    selectProjectBox: {
        borderBottom: '1px solid #eee',
        padding: '10px'
    },
    selectProjectAndUploadInvoiceStyle: {
        padding: '20px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'baseline',
        gap: '30px'
    },
    contentLabel: {
        marginBottom: '10px'
    },
    contentWidth: {
        width: '100%',
        '& input': {
            border: '1px solid #eae1e1',
            padding: '14px',
            borderRadius: '5px',
        },
        '& fieldset': {
            border: '1px solid #eae1e1',
        }
    },
    uploadInvoiceBox: {
        width: '100%'
    },
    boxTitle: {
        paddingBottom: '10px',
        fontWeight: 500,
        fontSize: '18px'
    },
    dialogWrapper: {
        display: 'flex',
        justifyContent: 'center',
    },
    dropZoneStyle: {
        display: "flex",
        flexDirection: "column" as "column",
        alignItems: "center",
        justifyContent: "center",
        padding: "36px 0",
        borderRadius: "10px",
        backgroundColor: "#ffffff",
        border: "1px dashed rgba(223,128,45,0.45)",
        width: '100%'
    },
    dialogDropZoneText: {
        width: '100%',
        textAlign: 'center' as 'center',
        padding: '20px'
    },
    uploadText: {
        margin: '10px'
    },
    tabText: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '5px'
    },
    paragraph: {
        fontWeight: 600
    },
    tabsInvoice: {
        border: '1px solid #eee',
        margin: '10px',
        borderRadius: '4px',
        padding: '10px',
        width: 'max-content',
        '& button': {
            maxWidth: '320px',
            opacity: 1,
            color: '#e0802e',
            '&.Mui-selected': {
                background: '#e0802e',
                clipPath: "polygon(90% 0, 100% 50%, 90% 100%, 0% 100%, 0 53%, 0% 0%)",
                borderRadius: '10px',
                '& h6': {
                    color: '#ffffff',
                    fontWeight: '600',
                    [theme.breakpoints.down('1610')]: {
                        fontSize: '18px'
                    },
                },
                '& p': {
                    color: '#ffffff',
                    fontWeight: '500'
                }
            }
        },
        '& span': {
            alignItems: 'unset',
            textTransform: 'capitalize',
        },
        '& span.MuiTabs-indicator': {
            display: 'none'
        }
    },
    invoiceTabMenuBox: {
        textAlign: 'left' as 'left',
        minHeight: '100%',
        '&.MuiTab-root': {
            minHeight: '100%',
        }
    },
    invoiceUploadCurveBox: {
        height: '30px',
        width: '31px',
        background: '#fcf7f2',
        position: 'absolute' as 'absolute',
        bottom: 0,
        left: '-31px',
    },
    curveInvoiceInnerBox: {
        border: '1px solid #e0802e',
        height: '29px',
        width: '31px',
        borderBottomRightRadius: '15px',
        borderTop: 'none',
        borderLeft: 'none'
    },
    curveRightInvoiceBox: {
        height: '32px',
        width: '30px',
        background: '#fcf7f2',
        position: 'absolute' as 'absolute',
        bottom: 0,
        right: '-30px',
    },
    curveRightInvoiceInnerBox: {
        border: '1px solid #e0802e',
        height: '31px',
        width: '30px',
        borderBottomLeftRadius: '15px',
        borderTop: 'none',
        borderRight: 'none'
    },
    uploadmodalBox: {
        padding: "28px 30px 50px",
        background: "#ffffff",
        width: "560px",
        position: "absolute" as "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        borderRadius: "10px",
        "& p": {
            fontSize: "20px",
            color: "#000000",
            textAlign: "center",
            margin: "25px"
        }
    },
    uploadIconBox: {
        textAlign: "right" as "right",
        marginBottom: "10px",
        "& img": {
            width: "35px",
            height: "35px"
        }
    },
    uploadImageBx: {
        textAlign: "center" as "center",
        "& img": {
            width: "100px",
            height: "100px"
        }
    },
    comparebuttonBox: {
        textAlign: "center" as "center",
        "& button": {
            border: "1px solid #e0802e",
            padding: "15px 60px",
            textTransform: "unset",
            color: "#e0802e",
            fontSize: "20px",
            lineHeight: "22px",
            "&:hover": {
                background: "#e0802e",
                color: "#ffffff"
            },
            "&:first-child": {
                marginRight: "40px"
            }
        }
    },
    uploadingBox: {
        border: '1px solid #dfdfdf',
        padding: "10px 25px 25px",
        margin: '0 10%',
        '& .MuiLinearProgress-root': {
            background: '#f5e4d7',
            height: '12px',
            borderRadius: '5px'
        },
        '& .MuiLinearProgress-bar': {
            background: '#de812c',
        },
        '& h6': {
            color: '#000',
            fontWeight: '600',
        },
    },
    uploadingInnerBox: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        '& p': {
            fontSize: '16px',
            color: '#000',
            fontWeight: '500',
            margin: '20px 0 10px 0'
        }
    },
    pauseIconBox: {
        '& :nth-child(1)': {
            marginRight: '15px'
        }
    },
    successModalBox: {
        border: '1px solid #dfdfdf',
        borderRadius: '5px'
    },
    successModalTitle: {
        borderBottom: '1px solid #dfdfdf',
        padding: '15px 0 15px 20px',
        '& h6': {
            fontWeight: '600',
            color: '#000'
        }
    },
    successContent: {
        textAlign: 'center' as 'center',
        padding: '70px 0 50px 0',
        '& p': {
            fontWeight: '500',
            color: '#000',
            margin: '25px 0'
        }
    },
    successButtons: {
        '& button': {
            width: '250px',
            border: '1px solid #e0802e',
            margin: '0 20px',
            padding: '15px 0',
            textTransform: 'unset',
            color: '#e0802e',
            fontSize: '20px',
            lineHeight: '22px',
            background: 'transparent',
            borderRadius: '8px',
            '&:hover': {
                background: '#e0802e',
                color: '#ffffff'
            },
        }
    },

    failedModalBox: {
        border: '1px solid #dfdfdf',
        borderRadius: '5px'
    },
    failedModalTitle: {
        borderBottom: '1px solid #dfdfdf',
        padding: '15px 0 15px 20px',
        '& h6': {
            fontWeight: '600',
            color: '#000'
        }
    },
    failedContent: {
        textAlign: 'center' as 'center',
        padding: '70px 0 50px 0',
        '& p': {
            fontWeight: '500',
            color: '#000',
            margin: '25px 0'
        }
    },
    failedbuttons: {
        '& button': {
            width: '250px',
            border: '1px solid #e0802e',
            margin: '0 20px',
            padding: '15px 0',
            textTransform: 'unset',
            color: '#e0802e',
            fontSize: '20px',
            lineHeight: '22px',
            borderRadius: '8px',
            '&:hover': {
                background: '#e0802e',
                color: '#ffffff'
            },
        }
    }
})
export { InvoiceUpload }
export default withStyles(invoiceStyle)(InvoiceUpload)

const webStyle = {
    successZone: {
        display: "flex",
        flexDirection: "column" as "column",
        alignItems: "center",
        justifyContent: "center",
        padding: "36px 0",
        borderRadius: "10px",
        backgroundColor: "#ffffff"
    },
    DialogContentWrapper: {
        maxWidth: "700px",
        width: "700px",
        padding: "0 75px 25px 75px"
    },
    uploadMessage: {
        margin: "30px 0",
        fontSize: "25px",
        textAlign: "center" as "center",
        fontWeight: 500
    },
};


// Customizable Area End
