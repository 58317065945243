// Customizable Area Start
import React from 'react';
import { NavLink } from "react-router-dom";
import {Typography } from "@material-ui/core";
import { createTheme, ThemeProvider, withStyles } from "@material-ui/core/styles";
import DocumentOpenerController, { Props, configJSON } from "./DocumentOpenerController.web";
import Modal from '@material-ui/core/Modal';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';

export class DocumentOpener extends DocumentOpenerController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start

        // Customizable Area End
    }

    render() {
        const { classes, pdfUrl } = this.props      
        return (
            <ThemeProvider theme={invoiceTheme}>
                <button className={classes.docOpen} onClick={this.openModal}> View </button>
                <Modal style={webStyle.ModalColor} open={this.state.isOpen}>

                    <div style={{ position: 'absolute', top: '50%', backgroundColor: "#f5f5f5", left: '55%', transform: 'translate(-50%, -50%)' }}>
                        <div style={webStyle.header}>
                            <div style={webStyle.heads}>
                        <Typography variant="h5">Document Opener</Typography>
                            </div>
                            <div onClick={this.closeModal}>
                                <HighlightOffIcon style={{ fontSize: 50 }} />
                            </div>
                        </div>
                        <div onClick={(event) => event.stopPropagation()}>
                        <embed src={pdfUrl} type="application/pdf" height="550" width="1200" />  
                        </div>
                    </div>
                </Modal>
            </ThemeProvider>
        );
    }
}

const invoiceTheme = createTheme({
    palette: {
        primary: {
            main: "#fff",
            contrastText: "#fff"
        }
    },
    typography: {
        h5: {
            fontWeight: 500,
            color: '#000'
        },
        subtitle1: {
            margin: "20px 0px"
        },

    }
});

export const webStyle = {

    InvoiceHeading: {
        paddingRight: '5px'
    },
    docOpen: {
        backgroundColor: 'Transparent',
        border: 'none' as 'none',
        cursor: 'pointer',
        paddingLeft: 0
    },
    header: {
        display: 'flex',
        justifyContent: "space-between",
        padding: 10
    },
    heads: {
        paddingTop: 10,
    },
    ModalColor: {
        padding: 20,
        backgroundColor: "transparent"
    }


};

export default withStyles(webStyle)(DocumentOpener)

// Customizable Area End
