// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { RequiredQuoteDataSaved } from "./CreateReportController.web";

export const configJSON = require("./config");


interface VerifyQuoteDescription {
    id: number;
    description: string;
    ord_quantity: number;
    unit: string;
    price: string;
    rate_sheet_weekly: string;
    rate_sheet_monthly: string;
    cat_class: string;
    amount: string;
    report_quote_id: number;
    created_at: string;
    updated_at: string;
    part_number: string;
    tax: null | string;
    category_name: null | string;
    category_subtotal: null | string;
    rate_sheet_cat: string;
    rate_sheet_min: string;
    rate_sheet_daily: string;
}

interface VerifyQuoteDetail {
    data: {
        id: string,
        type: string,
        attributes: {
            id: number,
            site_name: string,
            site_address: string,
            site_contactperson: string,
            site_contact: string,
            subtotal: null,
            s_and_h_charges: null,
            tax: null,
            other_charges: null,
            tax_rate_amount: null,
            discount: null,
            expiration_date: null,
            gross_subtotal: null,
            created_at: string,
            updated_at: string,
            quote_id: string,
            report_id: number,
            date: null,
            vendor: string,
            project: string,
            status: string,
            total_price: null,
            supplier_address: string,
            supplier_salesperson: string,
            supplier_contact: string,
        }
    }
}
export interface Props {
    // Customizable Area Start
    navigation: any;
    id: string;
    classes: any;
    history: any;
    location: any;
    quoteData: {
        quoteDetails: VerifyQuoteDetail[]
        quoteDescription: VerifyQuoteDescription[]
    }
    handleSaveData: () => {};
    onContinueButtonClick: (e: string, a: number) => void;
    goBackButtonNavigation: (e: string, a: number) => void;
    segmentName1: string,
    segmentTotal1: number,
    segmentName2: string,
    segmentTotal2: number,
    quoteCategoryDescription: any,
    checkedBox: boolean,
    toggleStyle:boolean,
    editQuoteDataSaved:boolean,
    editQuoteRequiredData:RequiredQuoteDataSaved|any;
    // Customizable Area End
}


export interface TableSegmentDataProps {
    classes: any,
    quotestate: any[],
    handleCategoryDataPageChange: (event: React.ChangeEvent<unknown>, page: number, columnId: number) => void,
    segmentName: string,
    isreportPage: boolean
}
interface S {
    // Customizable Area Start
    quoteReportTab: any,
    value: any,
    quotestate: any,
    quotedetail: any,
    quotePage: any,
    quoteRowsPerPage: any,
    quoteDragDataPage: number,
    quoteDragDataRowsPerPage: number,
    // Customizable Area End
}

interface SS {
    // Customizable Area Start
    // Customizable Area End
}

export default class VerifyReportController extends BlockComponent<
    Props,
    S,
    SS
>{
    constructor(props: Props) {
        super(props)
        this.state = {
            // Customizable Area Start

            value: 1,
            quotePage: 1,
            quoteRowsPerPage: 10,
            quoteDragDataPage: 1,
            quoteDragDataRowsPerPage: 10,
            quoteReportTab: [
                {
                    id: 1,
                    type: "Upload Quote"
                },
                {
                    id: 2,
                    type: "Review Quote Details"
                },
                {
                    id: 3,
                    type: "Upload Invoice"
                },
                {
                    id: 4,
                    type: "Review Invoice Details"
                },
                {
                    id: 5,
                    type: "Results"
                },
            ],
            quotestate: this.props.quoteData.quoteDescription,
            quotedetail: this.props.quoteData.quoteDetails,
            // Customizable Area End

        }

        this.subScribedMessages = [
            getName(MessageEnum.NavigationPayLoadMessage),
        ];
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages)
    }

    handleChangePage = (event: unknown, newPage: number) => {
        this.setState({ quoteDragDataPage: newPage })
    };

    handleCategoryDataPageChange = (event: React.ChangeEvent<unknown>, page: number, columnId: number) => {
        const updatedTablesData = [...this.props.quoteCategoryDescription];
        updatedTablesData[columnId].pagination.page = page;
        this.setState({ quotestate: updatedTablesData });
    };

}
// Customizable Area End
