// Customizable Area Start
import React from "react";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { styled } from "@material-ui/styles";
import { createTheme, withStyles } from "@material-ui/core/styles";
import {
    Box,
    Typography,
    Grid,
    TextField,
    Button
} from "@material-ui/core";
import Header from "../../landingpage/src/Header.web";
import { getTimeAgo } from "../../../framework/src/CalculateTime"
import InvoiceCommentsController, { Props } from "./InvoiceCommentsController.web";
import { imgAdd, imgCancel, imgGstin, imgMapIcon, reportIcon1, backIcon } from "./assets";

const StyledTableMenu = styled(Menu)({
    "& .MuiPaper-elevation8": {
        boxShadow: "none",
        border: "1px solid #d2d1d1",
        overflow: 'visible',
        marginTop: '46px',
        left: 'unset !important',
        right: '100px',
    },

    "& ul": {
        '&::before': {
            content: '" "',
            width: '0',
            height: '0',
            position: 'absolute',
            borderBottom: 'solid 12px #dbdbdb',
            borderLeft: 'solid 11px transparent',
            borderRight: 'solid 12px transparent',
            top: '-12px',
            right: '20px',
            zIndex: 0,
        },
        '&::after': {
            content: '" "',
            position: 'absolute',
            width: '0',
            height: '0',
            borderBottom: 'solid 9px white',
            borderLeft: 'solid 9px transparent',
            borderRight: 'solid 10px transparent',
            top: '-9px',
            right: '22px'
        }
    },

});

const StyledYesButton = styled(Button)({
    border: "1px solid #e0802e",
    width: '40%',
    padding: "4px 0px",
    textTransform: "unset" as "unset",
    color: "#e0802e",
    fontSize: "14px",
    lineHeight: "22px",
    margin: "0px 6px",
    '&:hover': {
        background: "#e0802e",
        color: "#fff"
    }
});

const StyledNoButton = styled(Button)({
    border: "1px solid #929090",
    width: '40%',
    padding: "4px 0px",
    textTransform: "unset" as "unset",
    color: "black",
    fontSize: "14px",
    lineHeight: "22px",
    margin: "0px 6px",
});

export const theme = createTheme({});

export const RenderCommentReply = ({
    classes,
    item,
    state,
    handleReplyCommentChange,
    handleReplyCancelButton,
    handleReplySubmitButton,
    renderShowCommentData
}: any) => {
    return (
        item?.isReply && item.attributes.replies.length == 0 ?
            <Box className={classes.commentInputInnerWrapper}>
                <TextField
                    data-testid="commmentReplyPanelInputId"
                    id='outlined-multiline-static'
                    variant="outlined"
                    maxRows={4}
                    name="comment-section"
                    placeholder={"Write a reply.."}
                    className={classes.inputComment}
                    value={state.replyComment}
                    onChange={(event) => handleReplyCommentChange(event)}
                />
                <Box component={"div"} className={classes.inputButtonWrapper}>
                    <Button
                        data-testid="cancelReplyButtonId"
                        disabled={state.isDisableReplyCommentButton}
                        className={classes.commentCancelButton}
                        onClick={() => handleReplyCancelButton()}
                    >
                        <Typography variant="h6"> Cancel </Typography>
                    </Button>
                    <Button
                        data-testid="submitReplyButtonId"
                        disabled={state.isDisableReplyCommentButton}
                        className={
                            state.isDisableReplyCommentButton ?
                                classes.commentDisabledSubmitButton
                                : classes.commentSubmitButton
                        }
                        onClick={() => handleReplySubmitButton(item)}
                    >
                        <Typography variant="h6"> Submit </Typography>
                    </Button>
                </Box>
            </Box>
            :
            item?.isReply &&
            <Box key={`${item.id}`} data-testid="showReplyBoxWrapperId">
                {
                    item.attributes.replies.map((reply: any, index: number) => {
                        return (
                            <div data-testid={`singleReplyBoxWrapperId-${index}`} className={classes.showReplyWrapper}>
                                {renderShowCommentData(classes, reply, index, true)}
                                <Typography variant={'h5'}>{reply.user_reply}</Typography>
                            </div>
                        )

                    })
                }
            </Box>
    )
}

export const RenderCommentOptions = ({
    classes,
    item,
    index,
    fromReply,
    state,
    handleOpenCommentMenu,
    handleClickCloseCommentMenu,
    handleClickEditCommentMenu,
    handleClickDeleteCommentMenu,
}: any
) => {
    return (
        <div>
            <MoreVertIcon
                data-testid={`commentIconId-${(index + 1)}-${fromReply}`}
                className={classes.threeDotIconStyle}
                onClick={(event) => handleOpenCommentMenu(event, item, fromReply)}
            />
            <StyledTableMenu
                data-testid={`styledTableMenuCommentId-${(index + 1)}`}
                id="simple-menu"
                anchorEl={state.anchorEl}
                keepMounted
                open={Boolean(state.anchorEl)}
                onClose={(event) => handleClickCloseCommentMenu(event)}
            >
                <MenuItem 
                    onClick={(event) => handleClickCloseCommentMenu(event)} 
                    data-testid={`commentResolveOptionId-${(index + 1)}-${fromReply}`}
                >
                    Resolve
                </MenuItem>
                <MenuItem 
                    onClick={(event) => handleClickEditCommentMenu(event)} 
                    data-testid={`commentEditOptionId-${(index + 1)}-${fromReply}`}
                >
                    Edit
                </MenuItem>
                <MenuItem 
                    onClick={(event) => handleClickDeleteCommentMenu(event)} 
                    data-testid={`commentDeleteOptionId-${(index + 1)}-${fromReply}`}
                >
                    Delete
                </MenuItem>
            </StyledTableMenu>
        </div>
    )
}

export const RenderDeleteCommentModal = ({
    classes,
    state,
    handleCloseDeleteCommentDialog,
    handleCommentDeleteDailogConfirm
}: any) => {
    return (
        state.isDeleteCommentModalOpen &&
        <Box data-testid="deleteCommentWrapperId" className={classes.custommodal}>
            <Box className={classes.deletemodalBox}>
                <Box className={classes.closeDeleteIcon}>
                    <img src={reportIcon1} alt="upload" />
                </Box>
                <Typography className={classes.deleteModalTitle}>Delete Comment?</Typography>
                <Box className={classes.closeDeleteButtons}>
                    <StyledNoButton data-testid="deleteCommentNoButtonId" onClick={handleCloseDeleteCommentDialog}> No </StyledNoButton>
                    <StyledYesButton data-testid="deleteCommentYesButtonId" onClick={handleCommentDeleteDailogConfirm}> Yes </StyledYesButton>
                </Box>
            </Box>
        </Box>

    )
}

class InvoiceComments extends InvoiceCommentsController {
    constructor(props: Props) {
        super(props);
    }

    renderInputComment = (classes: any) => {
        return (
            <Box className={classes.commentInputInnerWrapper}>
                <TextField
                    data-testid="commmentPanelInputId"
                    id='outlined-multiline-static'
                    variant="outlined"
                    maxRows={4}
                    name="comment-section"
                    placeholder={"Make a comment"}
                    className={classes.inputComment}
                    value={this.state.inputComment}
                    onChange={(event) => this.handleInputCommentChange(event)}
                />
                <img
                    src={imgAdd}
                    alt="pin-icon"
                    className={classes.inputPinIcon}
                />
                <Box component={"div"} className={classes.inputButtonWrapper}>
                    <Button
                        data-testid="cancelButtonId"
                        disabled={this.state.isDisableCommentButton}
                        className={classes.commentCancelButton}
                        onClick={() => this.handleCancelButton()}
                    >
                        <Typography variant="h6"> Cancel </Typography>
                    </Button>
                    <Button
                        data-testid="submitButtonId"
                        disabled={this.state.isDisableCommentButton}
                        className={
                            this.state.isDisableCommentButton ?
                                classes.commentDisabledSubmitButton
                                : classes.commentSubmitButton
                        }
                        onClick={() => this.handleInputSubmitButton()}
                    >
                        <Typography variant="h6"> Submit </Typography>
                    </Button>
                </Box>
            </Box>
        )
    }

    renderShowCommentData = (classes: any, item: any, index: number, fromReply: boolean) => {
        const commentTimeStamp = getTimeAgo(new Date(item.created_at).getTime())
        return(
            <div className={classes.singleCommentTopWrapper}>
                <div className={classes.commentUserDetailsWrapper}>
                    <img src={imgMapIcon} alt='user-img' />
                    <div className={classes.commentUserInfoWrapper}>
                        <Typography variant={'h5'}>{item.commentable_type || item.user_name}</Typography>
                        <Typography variant={'h6'}>{commentTimeStamp}</Typography>
                    </div>
                </div>
                <div>
                    <RenderCommentOptions
                        data-testid="CommentOptionsId"
                        classes={classes}
                        item={item}
                        index={index}
                        fromReply={fromReply}
                        state={this.state}
                        handleOpenCommentMenu={this.handleOpenCommentMenu}
                        handleClickCloseCommentMenu={this.handleClickCloseCommentMenu}
                        handleClickEditCommentMenu={this.handleClickEditCommentMenu}
                        handleClickDeleteCommentMenu={this.handleClickDeleteCommentMenu}
                    />
                </div >

            </div >
        )
    }

    renderRepliedImage = (classes:any,item:any) => {
        return (
            item.attributes.replies.length > 0 && <>
                <img src={backIcon} alt="down-arrow" className={classes.replyArrowDownImg}/>
                <Typography data-testid="commentNumOfReplyId" variant={'h6'}>{item.attributes.replies.length}</Typography>
            </>
        )
    }

    renderShowComments = (classes: any) => {
        return (
            this.state.showComment.length == 0 ?

                <Box className={classes.showCommentInsideBox}>
                    <img src={imgGstin} alt="chat-plus icon" className={classes.showCommentImage} />
                    <Typography variant="h6">Give feedback, ask questions, or start a </Typography>
                    <Typography variant="h6"> discussion in the comments. </Typography>
                </Box>
                :
                    <div className={classes.renderCommentWrapper}>
                        {
                            this.state.showComment.map((item:any, index:number) => (
                                
                                <div key={`${item.id}-${index}`} className={classes.singleCommentWrapper}>
                                    {this.renderShowCommentData(classes,item.attributes,index,false)}
                                    <Typography variant={'h5'}>{item.attributes.comment}</Typography>
                                    <Box data-testid="commentReplyButtonId" className={classes.replyButtonWrapper} onClick={() => this.handleReplyButton(item)}>
                                        {this.renderRepliedImage(classes,item)}
                                        <Typography data-testid="commentReplyTextId" variant={'h6'}>Reply</Typography>
                                    </Box>
                                    <RenderCommentReply
                                        data-testid="commentReplyCompId" 
                                        classes={classes}
                                        item={item}
                                        state={this.state}
                                        handleReplyCommentChange={this.handleReplyCommentChange}
                                        handleReplyCancelButton={this.handleReplyCancelButton}
                                        handleReplySubmitButton={this.handleReplySubmitButton}
                                        renderShowCommentData={this.renderShowCommentData}
                                    />
                                    <div className={classes.singleCommentBottomLine}/>
                                </div>
                            ))
                        }
                    </div>            
        )
    }

    renderFooterButtons = (buttonStyle: any, buttonText: string, testId: string, status: string) => {
        return (
            <Box data-testid={testId} component={'button'} className={buttonStyle} onClick={() => this.rejectAndApproveBtnApi(status)} >
                    <Typography variant={'h6'}>{buttonText}</Typography>
            </Box>
        )
    }

    render() {
        const { classes } = this.props
        return (
            <>
                    <Header
                    data-testid="headerInvoiceId"
                    navigation={this.props.navigation}
                    handleSaveData={() => { } } history={this.props.history}                    />
                    <>
                        <Box data-testid="documentBoxId" className={classes.documentBox}>
                            <Box className={classes.documentHeaderInnerBox}>
                                <Box className={classes.documenHeaderTitletBox}>
                                    <Typography variant="h6">Invoice Upload</Typography>
                                    <Box component={"div"} className={classes.curveHeaderBox}>
                                        <Box component={"div"} className={classes.curveInnerHeaderBox}></Box>
                                    </Box>

                                    <Box className={classes.curveRightHeaderBox}>
                                        <Box className={classes.curveRightInnerHeaderBox}></Box>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>

                        <Box className={classes.tabInvoiceApprovalBox}>

                            <Box data-testis="tabPanelId" className={classes.tabpanelInvoiceApprovalBox}>
                                <Grid container className={classes.tabpanelContentBox}>

                                    <Grid item lg={6} md={6} sm={6} xs={6}>
                                        <Box data-testid="invoiceApprovalTextId" className={classes.tabPanelAprrovalTitle}>
                                            <Typography variant="h6">
                                                Invoice Approval
                                            </Typography>
                                        </Box>
                                    </Grid>

                                    <Grid item lg={6} md={6} sm={6} xs={6} className={classes.functionApprovalBox}>
                                        <Box className={classes.iconBox}>
                                            <img src={imgCancel} alt="fliter" />
                                        </Box>
                                        <Box data-testid="leaveCommentButtonId" component={'button'} className={classes.filterBox} onClick={() => this.handleLeaveComment()}>
                                            <img src={imgMapIcon} alt="fliter" />
                                            <Typography>Leave Comments</Typography>
                                        </Box>
                                    </Grid>

                                </Grid>

                                <Grid item lg={12} md={12} sm={12} xs={6} className={classes.docAndCommentBoxWrapper}>
                                    {
                                        !this.state.noInvoivePdfError ?
                                            <Box className={this.state.isComment ? classes.tabpanelInnerBox2 : classes.tabpanelInnerBox}>
                                                <iframe src={this.state.noInvoivePdf} className={classes.pdfBox} />
                                            </Box> 
                                        : 
                                            <Box className={classes.pdfErrorBox}>
                                                <Typography>{this.state.noInvoivePdfError}</Typography>
                                            </Box>
                                    }


                                    {this.state.isComment &&
                                        <Box data-testid="commentPanelBoxId" className={classes.commentpanelBox}>
                                            <Box className={classes.inputCommentWrapper}>
                                                {this.renderInputComment(classes)}
                                            </Box>
                                            <Box className={classes.showCommentWrapper}>
                                                {this.renderShowComments(classes)}
                                                <RenderDeleteCommentModal
                                                    data-testid='deleteModalCompID'
                                                    classes={classes}
                                                    state={this.state}
                                                    handleCloseDeleteCommentDialog={() => this.handleCloseDeleteCommentDialog()}
                                                    handleCommentDeleteDailogConfirm={() => this.handleCommentDeleteDailogConfirm()}
                                                />
                                            </Box>
                                        </Box>
                                    }
                                </Grid>

                                <Button component={'button'} className={classes.buttonWrapper}>
                                    {this.renderFooterButtons(classes.rejectButton, "Reject", "rejectButtonId", "Reject")}
                                    {this.renderFooterButtons(classes.approveToPayButton, "Approve to pay", "approveToPayButtonId", "To Be Paid")}
                                </Button>

                            </Box>
                        </Box>
                    </>
            </>
        );
    }
}

export const invoiceCommentStyle = (theme: any) => ({
    documentBox: {
        background: '#fff',
        padding: '0 0 0 0',
        border: '1px solid #e0802e',
        borderTopLeftRadius: '15px',
        borderTopRightRadius: '15px',
        borderBottom: '0px',
    },
    documentHeaderInnerBox: {
        background: '#fcf7f2',
        padding: '10px 0 0 30px',
        width: '100%',
        borderTopLeftRadius: '15px',
        borderTopRightRadius: '15px',
    },
    documenHeaderTitletBox: {
        background: '#ffffff',
        width: '300px',
        textAlign: 'center' as 'center',
        border: '1px solid #e0802e',
        borderTopLeftRadius: '18px',
        borderTopRightRadius: '18px',
        borderBottom: 'none',
        paddingBottom: '20px',
        position: 'relative' as 'relative',
        top: '2px',
        zIndex: 1,
        '& h6': {
            color: '#e0802e',
            fontWeight: '600'
        },
        '& p': {
            color: '#e0802e',
        }
    },
    tabInvoiceApprovalBox: {
        width: '100%',
        display: 'flex',
        justifyContent: 'flex-start',
        background: '#ffffff',
        position: 'relative' as 'relative',
        borderTop: '1px solid #e0802e',
    },
    tabpanelInvoiceApprovalBox: {
        minWidth: '100%',
        margin: '15px 0',
        borderRadius: '8px',
        flex: '0 0 73%',
        padding: '15px'
    },
    curveHeaderBox: {
        height: '30px',
        width: '31px',
        background: '#fcf7f2',
        position: 'absolute' as 'absolute',
        bottom: 0,
        left: '-31px',
    },
    curveInnerHeaderBox: {
        border: '1px solid #e0802e',
        height: '29px',
        width: '31px',
        borderBottomRightRadius: '15px',
        borderTop: 'none',
        borderLeft: 'none'
    },
    curveRightHeaderBox: {
        height: '32px',
        width: '30px',
        background: '#fcf7f2',
        position: 'absolute' as 'absolute',
        bottom: 0,
        right: '-30px',
    },
    curveRightInnerHeaderBox: {
        border: '1px solid #e0802e',
        height: '31px',
        width: '30px',
        borderBottomLeftRadius: '15px',
        borderTop: 'none',
        borderRight: 'none'
    },
    tabPanelAprrovalTitle: {
        color: '#000',
        display: 'flex',
        alignItems: 'center',
        '& h6': {
            marginRight: '10px',
            [theme.breakpoints.down('1380')]: {
                fontSize: '16px'
            }
        },
        '& p': {
            marginRight: '10px',
            [theme.breakpoints.down('1380')]: {
                fontSize: '14px'
            }
        }
    },
    functionApprovalBox: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'end'
    },
    filterBox: {
        backgroundColor: '#e0802e',
        borderRadius: '4px',
        padding: '6px 15px !important',
        display: 'inline-block',
        textAlign: 'center' as 'center',
        [theme.breakpoints.down('1380')]: {
            padding: '6px 7px !important',
        },

        '& img': {
            width: '25px',
            height: '25px',
            backgroundColor: '#DEB887',
        },
        '& p': {
            display: 'inline-block',
            paddingLeft: '10px',
            color: '#fff',
            fontWeight: '600',
        }
    },
    iconBox: {
        display: 'inline-block',
        width: '14%',
        '& img': {
            width: '30px',
            height: '30px',
            marginLeft: '30px',
            [theme.breakpoints.down('1380')]: {
                marginLeft: '15px',
            },
        }
    },
    tabpanelInnerBox: {
        '& div:nth-child(1)': {
            padding: '0',
        },
        width: '100%'
    },
    tabpanelInnerBox2: {
        '& div:nth-child(1)': {
            padding: '0',
        },
        width: '70%'
    },
    pdfBox: {
        height: '100vh',
        display: 'block',
        maxHeight: '100%',
        width: '100%'
    },
    tabpanelContentBox: {
        marginBottom: '10px'
    },
    commentpanelBox: {
        width: '28%',
        height: '100vh',
        backgroundColor: '#fff',
        border: '1px solid grey',
        alignitems: 'center',
        borderRadius: '5px',
        marginLeft: '10px',
    },
    docAndCommentBoxWrapper: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-around',
    },
    inputCommentWrapper: {
        width: '100%',
        padding: '2px',
        minHeight: '18vh',
        borderBottom: '1px solid grey',
        alignItems: 'center',
        justifyContent: 'center',
    },
    commentInputInnerWrapper: {
        width: '100%',
        padding: '5px 10px',
    },
    showCommentWrapper: {
        display: 'flex',
        width: '100%',
        padding: '2px',
        height: '82vh',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'relative' as 'relative'
    },
    showCommentInsideBox: {
        width: '100%',
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex',
        flexDirection: 'column' as 'column',
        '& h6': {
            fontSize: '10px',
            fontWeight: 500,
        }
    },
    showCommentImage: {
        width: '70px',
        height: '70px'
    },
    replyArrowDownImg: {
        width: '5px',
        height: '8px',
        transform: 'rotate(-90deg)',
        marginRight: '5px',
    },
    buttonWrapper: {
        width: '100%',
        marginTop: '25px',
        marginBottom: '5px',
        "& button": {
            border: "1px solid #e0802e",
            background: 'transparent',
            padding: "15px 60px",
            width: '30%',
            color: "#e0802e",
            fontSize: "20px",
            lineHeight: "22px",
            borderRadius: '5px',
            "&:hover": {
                background: "#e0802e",
                color: "#ffffff"
            },
            "&:first-child": {
                marginRight: "40px"
            }
        }
    },
    inputComment: {
        width: '100%',
        position: 'relative' as 'relative',
        '& Mui-focused': {
            border: '1px solid red'
        },
        '& div': {

            '& input': {
                paddingRight: '50px'
            }
        }
    },
    inputPinIcon: {
        width: '28px',
        top: '100px',
        right: '42px',
        position: 'absolute' as 'absolute',
        height: '28'
    },
    inputButtonWrapper: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '5px',
        marginTop: '4px'
    },
    commentSubmitButton: {
        width: '32%',
        padding: '5px 8px',
        backgroundColor: '#e0802e',
        borderRadius: '5px',
        textAlign: 'center' as 'center',
        marginLeft: '2px',
        '& h6': {
            color: '#fff',
            fontWeight: '500',
            textTransform: 'none',
            fontSize: '16px'
        },
        '&:hover': {
            background: '#e0802e',
            color: '#ffffff'
        },
    },
    commentCancelButton: {
        width: '32%',
        padding: '5px 8px',
        textAlign: 'center' as 'center',
        marginRight: '2px',
        '& h6': {
            color: 'grey',
            fontWeight: '500',
            textTransform: 'none',
            fontSize: '16px'
        },
    },
    commentDisabledSubmitButton: {
        width: '32%',
        padding: '5px 8px',
        backgroundColor: '#D3D3D3',
        borderRadius: '5px',
        textAlign: 'center' as 'center',
        marginLeft: '2px',
        '& h6': {
            color: 'grey',
            fontWeight: '500',
            textTransform: 'none',
            fontSize: '16px'
        },
        '&:hover': {
            background: '#D3D3D3',
            color: 'grey'
        },
    },
    threeDotIconStyle: {
        rotate: '90deg',
    },
    renderCommentWrapper: {
        width: '95%',
        height: '78vh',
        overflow: 'auto',
        padding: '0px 10px'
    },
    singleCommentWrapper: {
        width: '100%',
        height: 'auto',
        marginBottom: '4px',
        '& h5': {
            fontSize: '16px',
            fontWeight: '600',
            wordWrap: 'break-word',
        },

    },
    showReplyWrapper: {
        padding: "0px 10px",
        '& h5': {
            fontSize: '16px',
            fontWeight: '600'
        },
    },
    replyButtonWrapper: {
        display: 'flex',
        width: '100%',
        alignItems: 'center',
        justifyContent: 'flex-start',
        padding: '0px 2px',
        '& h6':{
            fontSize: '14px', 
            fontWeight: 'bold' as 'bold',
            marginRight: '2px'
        }
    },
    singleCommentTopWrapper: {
        display: 'flex', 
        alignItems: 'center', 
        justifyContent: 'space-between',
        width: '100%'
    },
    commentUserDetailsWrapper: {
        display: 'flex',
        width: '100%',
        '& img': {
            width: '20%',
            height: '20%',
            marginLeft: '-10px',
            marginTop: 'auto'
        }
    },
    commentUserInfoWrapper: {
        width: '80%',
        alignSelf: 'center' as 'center',
        '& h5': {
            fontSize: '14px',
            fontWeight: 'bold' as 'bold',
            whiteSpace: 'nowrap',
            width: '100%',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            inlineSize: '120px'
        },
        '& h6': {
            fontSize: '10px',
            fontWeight: '500'
        }
    },
    singleCommentBottomLine: {
        padding: '4px 0px',
        borderBottom: '1px solid #A3A3A3',
        marginBottom: '5pxx'
    },
    custommodal: {
        width: '100%',
        height: '100vh',
        display: 'flex',
        content: "''",
        background: 'rgba(1,1,1,0.5)',
        top: 0,
        transform: 'translate(0, -18%)',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'absolute' as 'absolute'

    },
    deletemodalBox: {
        padding: "15px 10px 25px",
        background: "#fff",
        width: "90%",
        borderRadius: "10px",
    },
    deleteModalTitle: {
        fontSize: "15px",
        fontWeight: 500,
        color: "#000000",
        textAlign: "center" as "center",
        margin: "10px 15px",
    },
    closeDeleteIcon: {
        textAlign: "center" as "center",
        '& img': {
            width: "44px",
            height: "44px"
        }
    },
    closeDeleteButtons: {
        textAlign: "center" as "center",
    },
    pdfErrorBox:{
        width: '100%',
        border: '1px solid #ddd',
        borderRadius: '10px',
        boxShadow: '0 0 10px #eee',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '60vh',
        '& p': {
            fontSize: '18px',
            fontWeight: '600',
            color: '#000'
        }
    }
})

export { InvoiceComments }
export default withStyles(invoiceCommentStyle)(InvoiceComments)


// Customizable Area End
