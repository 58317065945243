// Customizable Area Start
import React from "react";
import {
    Box, RadioGroup, Radio,
    Grid, FormControl, FormLabel, FormControlLabel,
    Typography, TextField, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Button
} from "@material-ui/core";
import { createTheme, withStyles } from "@material-ui/core/styles";
import Pagination from '@material-ui/lab/Pagination';
import RateSheetDetailController, { Props, configJSON, TableDataProps, QuoteDescription, QuoteInterface, TableSegmentRateDataProps, RateSheetTableHeadProps } from "./RateSheetDetailController.web";
// Customizable Area Start
export const theme = createTheme({});

export const TableRateSheetData = (props: TableDataProps) => {
    const { classes, quotestate, RateQuoteRowsPerPage, RateQuotePage, selectedRadioValue, handleRateSheetChangePage, isreportPage } = props;

    const begin = (RateQuotePage - 1) * RateQuoteRowsPerPage;
    const end = begin + RateQuoteRowsPerPage;

    return (<>
        <TableContainer className={classes.RatesheetTableHead}>
            {
                selectedRadioValue === configJSON.dayTitle && <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Sr. No</TableCell>
                            <TableCell>Cat-class</TableCell>
                            <TableCell>Description</TableCell>
                            <TableCell>Category</TableCell>
                            <TableCell>Min.</TableCell>
                            <TableCell>Daily</TableCell>
                            <TableCell>Ext. Rates</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody className={isreportPage ? classes.reportTableBodyBox : `${classes.quoteTableBodyBox}`}>
                        {
                            quotestate.slice(begin, end).map((quoteDayData, index: number) => {
                                const quoteDayDataValue = quoteDayData as QuoteDescription;
                                return (

                                    <TableRow key={index}>
                                        <TableCell className={classes.indexBox}>{begin + index + 1}</TableCell>
                                        <TableCell className={classes.editunitQuoteContent}>{quoteDayDataValue.cat_class}</TableCell>
                                        <TableCell className={classes.editQuoteInput}>{quoteDayDataValue.description}</TableCell>
                                        <TableCell className={classes.editunitQuoteContent}>{quoteDayDataValue.rate_sheet_cat}</TableCell>
                                        <TableCell>{quoteDayDataValue.rate_sheet_min}</TableCell>
                                        <TableCell>{quoteDayDataValue.rate_sheet_daily}</TableCell>
                                        <TableCell>$ {quoteDayDataValue.amount}</TableCell>
                                    </TableRow>
                                )
                            })
                        }

                    </TableBody>
                </Table>
            }

            {
                selectedRadioValue === configJSON.weekTitle && <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Sr. No</TableCell>
                            <TableCell>Cat-class</TableCell>
                            <TableCell>Description</TableCell>
                            <TableCell>Category</TableCell>
                            <TableCell>Min.</TableCell>
                            <TableCell>Weekly</TableCell>
                            <TableCell>Ext. Rates</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody className={isreportPage ? classes.reportTableBodyBox : `${classes.quoteTableBodyBox}`}>
                        {
                            quotestate.slice(begin, end).map((quoteWeekData, index: number) => {
                                const quoteWeekDataValue = quoteWeekData as QuoteDescription;
                                return (

                                    <TableRow>
                                        <TableCell className={classes.indexBox}>{begin + index + 1}</TableCell>
                                        <TableCell className={classes.editunitQuoteContent}>{quoteWeekDataValue.cat_class}</TableCell>
                                        <TableCell className={classes.editQuoteInput}>{quoteWeekDataValue.description}</TableCell>
                                        <TableCell className={classes.editunitQuoteContent}>{quoteWeekDataValue.rate_sheet_cat}</TableCell>
                                        <TableCell>{quoteWeekDataValue.rate_sheet_min}</TableCell>
                                        <TableCell>{quoteWeekDataValue.rate_sheet_weekly}</TableCell>
                                        <TableCell>$ {quoteWeekDataValue.amount}</TableCell>
                                    </TableRow>
                                )
                            })
                        }

                    </TableBody>
                </Table>
            }

            {
                selectedRadioValue === configJSON.monthTitle && <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Sr. No</TableCell>
                            <TableCell>Cat-class</TableCell>
                            <TableCell>Description</TableCell>
                            <TableCell>Category</TableCell>
                            <TableCell>Min.</TableCell>
                            <TableCell>Monthly</TableCell>
                            <TableCell>Ext. Rates</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody className={isreportPage ? classes.reportTableBodyBox : `${classes.quoteTableBodyBox}`}>
                        {
                            quotestate.slice(begin, end).map((quoteMonthData, index: number) => {
                                const quoteMonthDataValue = quoteMonthData as QuoteDescription;
                                return (

                                    <TableRow>
                                        <TableCell className={classes.indexBox}>{begin + index + 1}</TableCell>
                                        <TableCell className={classes.editunitQuoteContent}>{quoteMonthDataValue.cat_class}</TableCell>
                                        <TableCell className={classes.editQuoteInput}>{quoteMonthDataValue.description}</TableCell>
                                        <TableCell className={classes.editunitQuoteContent}>{quoteMonthDataValue.rate_sheet_cat}</TableCell>
                                        <TableCell>{quoteMonthDataValue.rate_sheet_min}</TableCell>
                                        <TableCell>{quoteMonthDataValue.rate_sheet_monthly}</TableCell>
                                        <TableCell>$ {quoteMonthDataValue.amount}</TableCell>
                                    </TableRow>
                                )
                            })
                        }

                    </TableBody>
                </Table>
            }

        </TableContainer>


        <Box style={{ marginTop: "5%" }}>
            <Pagination
                count={Math.ceil(quotestate.length / RateQuoteRowsPerPage)}
                variant="outlined"
                shape="rounded"
                onChange={handleRateSheetChangePage}
                page={RateQuotePage}
                data-testid="paginationTestId"
            />
        </Box>
    </>)
}

export const RateSheetTableHead = (props: RateSheetTableHeadProps) => {
    const { RateSheetHeadColumn, selectedRadioValue } = props
    return (
        <>
            { selectedRadioValue === configJSON.dayTitle &&
                RateSheetHeadColumn.map((dayColumn) => (
                    <>
                        {
                            (dayColumn.id == 'Weekly' || dayColumn.id == 'Monthly') ? null
                                : <TableCell
                                    key={dayColumn.id}
                                >
                                    {dayColumn.label}
                                </TableCell>
                        }
                    </>
                ))
            }

            {
                selectedRadioValue === configJSON.weekTitle && 
                RateSheetHeadColumn.map((dayColumn) => (
                    <>
                        {
                            (dayColumn.id == 'Daily' || dayColumn.id == 'Monthly') ? null
                                : <TableCell
                                    key={dayColumn.id}
                                >
                                    {dayColumn.label}
                                </TableCell>
                        }
                    </>
                ))
            }

            {selectedRadioValue === configJSON.monthTitle && RateSheetHeadColumn.map((dayColumn) => (
                <>
                    {
                        (dayColumn.id == 'Daily' || dayColumn.id == 'Weekly') ? null
                            : <TableCell
                                key={dayColumn.id}
                            >
                                {dayColumn.label}
                            </TableCell>
                    }
                </>
            ))}
        </>

    )
}

export const TableSegmentRateData = (props: TableSegmentRateDataProps) => {
    const { classes, quotestate, segmentName, selectedRadioValue, RateSheetHeadColumn, handleRateSheetCategoryDataPageChange, isreportPage } = props;
    return (<>

        {
            quotestate?.map((rentalQuoteData, columnId: number) => {
                const { page, rowsPerPage } = rentalQuoteData.pagination;
                const begin = (page - 1) * rowsPerPage;
                const end = Math.min(begin + rowsPerPage, rentalQuoteData.data.length);

                return (
                    <>
                        {rentalQuoteData.category_name &&
                            <Box style={{ margin: "10px 0 10px 6px", display: "flex", alignItems: "baseline", gap: "10px" }}>
                                <Typography className={isreportPage ? classes.quoteInvoiceDetailTitle : ""}>
                                    {configJSON.SegmentContent}
                                </Typography>
                                <TextField variant="outlined" style={{ width: "20%", textOverflow: "ellipsis" }} className={isreportPage ? classes.reportTextBox : classes.textField}
                                    value={rentalQuoteData.category_name} InputProps={{ readOnly: true }} disabled />
                            </Box>
                        }
                        <TableContainer className={classes.RatesheetTableHead}>
                            {
                                selectedRadioValue === configJSON.dayTitle && <Table>
                                    <TableHead>
                                        <TableRow>
                                            <RateSheetTableHead selectedRadioValue={selectedRadioValue} RateSheetHeadColumn={RateSheetHeadColumn} />
                                        </TableRow>
                                    </TableHead>
                                    <TableBody className={isreportPage ? classes.reportTableBodyBox : `${classes.quoteTableBodyBox}`}>
                                        {
                                            rentalQuoteData.data.slice(begin, end).map((quoteDayRateData: QuoteInterface, index: number) => {
                                                return (

                                                    <TableRow>
                                                        <TableCell className={classes.indexBox}>{begin + index + 1}</TableCell>
                                                        <TableCell className={classes.editunitQuoteContent}>{quoteDayRateData.cat_class}</TableCell>
                                                        <TableCell className={classes.editQuoteInput}>{quoteDayRateData.description}</TableCell>
                                                        <TableCell className={classes.editunitQuoteContent}>{quoteDayRateData.rate_sheet_cat}</TableCell>
                                                        <TableCell>{quoteDayRateData.rate_sheet_min}</TableCell>
                                                        <TableCell>{quoteDayRateData.rate_sheet_daily}</TableCell>
                                                        <TableCell>$ {quoteDayRateData.amount}</TableCell>
                                                    </TableRow>
                                                )
                                            })
                                        }

                                    </TableBody>
                                </Table>
                            }

                            {
                                selectedRadioValue === configJSON.weekTitle && <Table>
                                    <TableHead>

                                        <RateSheetTableHead RateSheetHeadColumn={RateSheetHeadColumn} selectedRadioValue={selectedRadioValue} />
                                    </TableHead>
                                    <TableBody className={isreportPage ? classes.reportTableBodyBox : `${classes.quoteTableBodyBox}`}>
                                        {
                                            rentalQuoteData.data.slice(begin, end).map((quoteWeekRateData: QuoteInterface, index: number) => {
                                                return (

                                                    <TableRow>
                                                        <TableCell className={classes.indexBox}>{begin + index + 1}</TableCell>
                                                        <TableCell className={classes.editunitQuoteContent}>{quoteWeekRateData.cat_class}</TableCell>
                                                        <TableCell className={classes.editQuoteInput}>{quoteWeekRateData.description}</TableCell>
                                                        <TableCell className={classes.editunitQuoteContent}>{quoteWeekRateData.rate_sheet_cat}</TableCell>
                                                        <TableCell>{quoteWeekRateData.rate_sheet_min}</TableCell>
                                                        <TableCell>{quoteWeekRateData.rate_sheet_weekly}</TableCell>
                                                        <TableCell>$ {quoteWeekRateData.amount}</TableCell>
                                                    </TableRow>
                                                )
                                            })
                                        }

                                    </TableBody>
                                </Table>
                            }

                            {
                                selectedRadioValue === configJSON.monthTitle && <Table>
                                    <TableHead>
                                        <RateSheetTableHead RateSheetHeadColumn={RateSheetHeadColumn} selectedRadioValue={selectedRadioValue} />
                                    </TableHead>
                                    <TableBody className={isreportPage ? classes.reportTableBodyBox : `${classes.quoteTableBodyBox}`}>
                                        {
                                            rentalQuoteData.data.slice(begin, end).map((quoteMonthRateData: QuoteInterface, index: number) => {
                                                return (

                                                    <TableRow>
                                                        <TableCell className={classes.indexBox}>{begin + index + 1}</TableCell>
                                                        <TableCell className={classes.editunitQuoteContent}>{quoteMonthRateData.cat_class}</TableCell>
                                                        <TableCell className={classes.editQuoteInput}>{quoteMonthRateData.description}</TableCell>
                                                        <TableCell className={classes.editunitQuoteContent}>{quoteMonthRateData.rate_sheet_cat}</TableCell>
                                                        <TableCell>{quoteMonthRateData.rate_sheet_min}</TableCell>
                                                        <TableCell>{quoteMonthRateData.rate_sheet_monthly}</TableCell>
                                                        <TableCell>$ {quoteMonthRateData.amount}</TableCell>
                                                    </TableRow>
                                                )
                                            })
                                        }

                                    </TableBody>
                                </Table>
                            }

                        </TableContainer>
                        <Box style={{ marginTop: "5%" }}>
                            <Pagination
                                count={Math.ceil(rentalQuoteData.data.length / rentalQuoteData.pagination.rowsPerPage)}
                                variant="outlined"
                                shape="rounded"
                                page={rentalQuoteData.pagination.page}
                                data-testid={`editpaginationTestId-${columnId}`}
                                onChange={(event, page) => handleRateSheetCategoryDataPageChange(event, page, columnId)}
                            />
                        </Box>
                        <Box className={isreportPage ? classes.reportSubtotalBox : classes.subTotalQuoteBox}>
                            <Typography className={classes.titleStyle}>{segmentName ? configJSON.SegmentTotalContent : configJSON.subTotalText}</Typography>&nbsp;&nbsp;
                            <TextField variant="outlined" style={{ width: '250px' }} className={isreportPage ? classes.reportTextBox : classes.textField}
                                value={`$ ${rentalQuoteData.subtotalValue || 0}`} InputProps={{ readOnly: true }} disabled />
                        </Box>
                    </>
                )

            })
        }
    </>)
}
// Customizable Area End

export class RateSheetDetail extends RateSheetDetailController {
    constructor(props: Props) {
        super(props)
        // Customizable Area Start
        // Customizable Area End
    }
    render() {

        const { classes } = this.props
        return (
            <>
                <Box style={this.props?.toggleStyle ? webStyle.quoteBoxPdf : webStyle.quoteBox}>

                    <Box style={{ position: 'relative' }}>
                        <Typography variant="h5" style={this.props?.toggleStyle ? webStyle.ItemDetailsTextPdf : webStyle.ItemDetailsText}>{configJSON.ItemDetailsTitle}</Typography>
                        <FormControl className={classes.ItemFormControl}>
                            <FormLabel style={this.props?.toggleStyle ? webStyle.ItemFormLabelPdf : webStyle.ItemFormLabel} >{configJSON.showRateTitle}</FormLabel>
                            <RadioGroup className={classes.ItemFormControlGroup} defaultValue={configJSON.dayTitle} value={this.state.selectedRadioValue} data-testid="radioTestId" onChange={this.handleRadioChange}>
                                <FormControlLabel  value={configJSON.dayTitle} control={<Radio  size="small"/>} label={configJSON.dayTitle} />
                                <FormControlLabel value={configJSON.weekTitle} control={<Radio size="small"/>} label={configJSON.weekTitle} />
                                <FormControlLabel value={configJSON.monthTitle} control={<Radio size="small"/>} label={configJSON.monthTitle} />
                            </RadioGroup>
                        </FormControl>
                        {
                            this.props?.quoteCategoryDescription.length > 0 ?
                                <TableSegmentRateData
                                    data-testid="tableDataTestId"
                                    selectedRadioValue={this.state.selectedRadioValue}
                                    isreportPage={this.props.toggleStyle}
                                    handleRateSheetCategoryDataPageChange={this.handleRateSheetCategoryDataPageChange}
                                    classes={classes}
                                    quotestate={this.props.quoteCategoryDescription}
                                    segmentName={this.props.segmentName1}
                                    RateSheetHeadColumn={this.state.RateSheetHeadColumn}
                                />
                                : <TableRateSheetData
                                    data-testid="tableDragDataTestId"
                                    selectedRadioValue={this.state.selectedRadioValue}
                                    isreportPage={this.props.toggleStyle}
                                    handleRateSheetChangePage={this.handleRateSheetChangePage}
                                    classes={classes}
                                    segmentName={this.props.segmentName2}
                                    quotestate={this.state.quotestate}
                                    RateQuoteRowsPerPage={this.state.RateQuoteRowsPerPage}
                                    RateQuotePage={this.state.RateQuotePage}
                                />
                        }
                    </Box>

                </Box>
            </>
        )
    }
}

const webStyle = {
    quoteBox: {
        background: '#fff',
        padding: '50px 30px 50px 50px',
        borderRadius: '10px',
        '& thead': {
            top: '-19px',
            position: 'relative',
        },
        '& MuiPagination-root': {
            marginTop: '5% !important'
        },
        '& p': {
            color: '#000',
            fontWeight: 500,
            margin: '20px 0 3px'
        },
        '& .MuiFilledInput-underline::after': {
            display: 'none'
        },
        '& .MuiFilledInput-underline::before': {
            display: 'none'
        },
        '& input': {
            color: "gray",
            padding: '17px',
        },
        '& .MuiTable-root': {
            borderCollapse: 'separate',
            borderSpacing: '8px'
        },
        '& .MuiTableContainer-root': {
            overflowX: 'unset',
            padding: '0 15px'
        },
        '& .MuiTableCell-root': {
            borderBottom: 'none'
        },
        '& .MuiTableCell-body': {
            color: "gray"
        },
        '& table': {
            position: 'relative'
        },
        '& table::after': {
            content: '""',
            borderRadius: '10px',
            border: '2px solid #e8e8e8',
            top: '47px',
            position: 'absolute',
            width: '101.8%',
            left: '-10px',
            height: '95%',
        }

    },
    quoteBoxPdf: {
        background: '#fff',
        borderRadius: '10px',
        padding: '20px 0px 50px 0px',
        '& thead': {
            position: 'relative',
            top: '-19px'
        },
        '& p': {
            color: '#000',
            margin: '20px 0 3px',
            fontWeight: 500,
        },
        '& MuiPagination-root': {
            marginTop: '5% !important'
        },
        '& .MuiFilledInput-underline::before': {
            display: 'none'
        },
        '& .MuiFilledInput-underline::after': {
            display: 'none'
        },
        '& input': {
            padding: '17px',
            color: "gray"
        },
        '& .MuiTable-root': {
            borderSpacing: '8px',
            borderCollapse: 'separate',
        },
        '& .MuiTableContainer-root': {
            overflowX: 'unset',
            padding: '0 15px'
        },
        '& .MuiTableCell-root': {
            borderBottom: 'none'
        },
        '& .MuiTableCell-body': {
            color: "gray"
        },
        '& table': {
            position: 'relative'
        },
        '& table::after': {
            content: '""',
            border: '2px solid #e8e8e8',
            position: 'absolute',
            top: '47px',
            borderRadius: '10px',
            left: '-10px',
            height: '95%',
            width: '101.8%',
        }

    },
    ItemDetailsTextPdf: {
        color: '#000',
        fontSize: '10px',
        fontWeight: 600,
        marginTop: '14px',
        display: "inline-block",
        marginRight: '30px',
    },
    ItemDetailsText: {
        color: '#000',
        fontWeight: 600,
        fontSize: '18px',
        display: "inline-block",
        marginTop: '8px',
        marginRight: '30px',
    },
    ItemFormLabel: {
        color: '#000',
        fontWeight: 500,
        fontSize: '16px',
        display: "inline-block",
        marginRight: '30px',
    },
    ItemFormLabelPdf: {
        color: '#000',
        fontSize: '12px',
        display: "inline-block",
        fontWeight: 500,
        marginRight: '30px',
    },
}
export const RateSheetQuoteStyle = () => ({

})

export default withStyles(RateSheetQuoteStyle)(RateSheetDetail)
