// Customizable Area Start
import React from "react";
import MessageEnum, { getName, } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from '../../../framework/src/IBlock';
export const configJSON = require("./config");

import { Message } from "../../../framework/src/Message";
import moment from "moment";
import Emitter from "./util";

export interface Props {
    navigation: any;
    id?: any;
    history: any;
    notificationStates?: {};
    openDialog?: boolean;
    handleCloseDialogFunction?: () => void;
    handleImageChange?: any;
    handleSaveData?: any;
    tabValue?: number;
    handleNotificationClick: (data: []) => void;
    deletenotificationMsg: any;
    handleNotificationDeleteData: (data: {}) => void;
    // Customizable Area End 
}

export interface ListProps {
    quotes?: any;
    invoice?: any
}

interface S {
    // Customizable Area Start
    open: boolean;
    id: any;
    anchorEl: HTMLButtonElement | null;
    toggle: boolean;
    openDialog: boolean;
    imgChange: boolean;
    SmallEnchor: HTMLButtonElement | null;
    smallPopoverOpen: boolean;
    simplePopover: string | undefined;
    myAncher: any,
    tabValue: number,
    apiError: boolean,
    serverSideError: boolean,
    checkedItems: any,
    notificationList: {
        date: any; data: any[];
    }[],
    selectedItem: any;
    token: string | undefined;
    isSelectAll: boolean;
    calledDeleteFunction: boolean,
    markAsreadNotificationId: string
}
interface SS {
    id: any;

}
export default class NotificationPanelListController extends BlockComponent<
    Props, S, SS> {
    getAllNotificationsApiId: string = "";
    deleteNotificationsApiId: string = "";
    searchNotificationsApiId: string = "";
    markAsReadNotificationApiId: string = "";
    

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        this.state = {
            checkedItems: {},
            id: "",
            anchorEl: null,
            toggle: false,
            imgChange: true,
            smallPopoverOpen: false,
            open: false,
            serverSideError: false,
            SmallEnchor: null,
            simplePopover: undefined,
            tabValue: 0,
            apiError: false,
            openDialog: false,
            notificationList: [],
            myAncher: null,
            selectedItem: {},
            token: '',
            isSelectAll: false,
            calledDeleteFunction: false,
            markAsreadNotificationId: "",
        };
        this.subScribedMessages = [
            getName(MessageEnum.CountryCodeMessage),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.ReciveUserCredentials),
        ];
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages)
    }


    // Customizable Area Start
    async receive(from: string, message: Message) {
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            if (apiRequestCallId === this.getAllNotificationsApiId) {
                this.handleGetAllNotificationApiResponse(responseJson)
            }
            if (apiRequestCallId === this.deleteNotificationsApiId) {
                this.handleDeleteNotificationAPiResponse(responseJson)
            }
            if (apiRequestCallId === this.searchNotificationsApiId) {
                this.handleSearchNotificationsAPiResponse(responseJson)
            }
            if (apiRequestCallId === this.markAsReadNotificationApiId) {
                this.handleMarkAsReadNotificationsAPiResponse(responseJson)
            }

        }
    }

    handleMarkAsReadNotificationsAPiResponse = (responseJson: any) => {
        if (responseJson.error || responseJson.errors) {
            this.setState({ serverSideError: true })
            this.setState({ apiError: true })
        }
        else {
            Emitter.emit("FETCH_NOTIFICATION_LIST_FOR_POPUP", {});
            this.handleValueChange(this.state.tabValue)
        }
    }

    handleGetAllNotificationApiResponse = (responseJson: any) => {
        if (responseJson) {
            if (responseJson.error || responseJson.errors) {
                this.setState({ serverSideError: true })
                this.setState({ apiError: true, notificationList: [], checkedItems: {}, isSelectAll: false })
            }
            else {
                const result = this.sortNotificationsData(responseJson.data)
                this.setState({
                    selectedItem: {},
                    checkedItems: {},
                    notificationList: result,
                    isSelectAll: false
                })
            }

        }
    }

    handleDeleteNotificationAPiResponse = (responseJson: any) => {
        if (responseJson) {
            if (responseJson.error || responseJson.errors) {
                this.setState({ serverSideError: true })
                this.setState({ apiError: true })
            }
            else {
                Emitter.emit("FETCH_NOTIFICATION_LIST_FOR_POPUP", {});
                this.getAllNotificationsApi(configJSON.getNotificationsApiEndPoint)
                this.setState({ selectedItem: {}, isSelectAll: false })
            }

        }
    }

    handleSearchNotificationsAPiResponse = (responseJson: any) => {
        if (responseJson) {
            if (responseJson.error || responseJson.errors) {
                this.setState({ serverSideError: true })
                this.setState({ apiError: true, notificationList: [], checkedItems: {}, isSelectAll: false })

            }
            else {
                const result = this.sortNotificationsData(responseJson.data)
                this.setState({
                    selectedItem: {},
                    checkedItems: {},
                    notificationList: result,
                    isSelectAll: false
                })
            }

        }
    }

    handleValueChange = (newValue: number) => {
        this.setState({ tabValue: newValue });

        switch (newValue) {
            case 0:
                this.getAllNotificationsApi(configJSON.getNotificationsApiEndPoint)
                break;
            case 1:
                this.getAllNotificationsApi(configJSON.getQuotesNotificationApiEndPoint)
                break;
            case 2:
                this.getAllNotificationsApi(configJSON.getInvoicesNotificationApiEndPoint)
                break;
            case 3:
                this.getAllNotificationsApi(configJSON.getProjectsNotificationApiEndPoint)
                break;
            case 4:
                this.getAllNotificationsApi(configJSON.getVendorsNotificationApiEndPoint)
                break;
            case 5:
                this.getAllNotificationsApi(configJSON.getComparisonNotificationApiEndPoint)
                break;
            default:
                break;
        }

    };

    templateFunct = () => {
        this.handleClosePopover()
        this.props.handleNotificationClick(this.state.selectedItem)
        this.markAsReadNotificationApi(this.state.selectedItem)
    }

    getNewDate = (date: string | number | Date) => {
        let d = new Date(date);

        return d.toLocaleDateString();
    }

    handleDatesDifference = (diffDays: number) => {
        if (diffDays === 1) {
            return "Yesterday";
        }
        else {
            return `${diffDays} days ago`
        }
    }

    convertDates = (date: string | number | Date) => {
        const oldDate: any = new Date(date);
        const diffDays = Math.abs(moment().utc().diff(oldDate, 'days'))
        if (diffDays === 0) {
            return "Today";
        } else {
            return this.handleDatesDifference(diffDays)
        }
    }

    sortNotificationsData = (data: any[]) => {
        let result: {
            date: any; data: any[];
        }[] = []
        data.forEach((value, index) => {
            let foundIndex = result.findIndex((item =>
                item.date === this.getNewDate(value.attributes.created_at)))

            if (foundIndex >= 0) {
                result[foundIndex].data.push(value);
            } else {
                result.push({
                    date: this.getNewDate(value.attributes.created_at),
                    data: [value]
                })
            }
        })
        return result;
    }

    async componentDidMount() {
        const getToken: any = await document.cookie.split("; ").find((row) => row.startsWith("Token="))?.split("=")[1];
        this.setState({ token: getToken })
        this.getAllNotificationsApi(configJSON.getNotificationsApiEndPoint)
        this.props.tabValue && this.handleValueChange(this.props.tabValue)
        Emitter.on("FETCH_TAB_NOTIFICATION_LIST",() => {
            this.handleValueChange(this.state.tabValue)
        })
    }

    async componentWillUnmount() {
        Emitter.off("FETCH_TAB_NOTIFICATION_LIST", undefined);
    }
    
    componentDidUpdate(prevProps: any, prevState: any): void {
        const { deletenotificationMsg } = this.props;
        const { calledDeleteFunction } = this.state;

        if (!calledDeleteFunction && deletenotificationMsg && prevProps.deletenotificationMsg !== deletenotificationMsg) {
            if (!deletenotificationMsg) {
                this.setState({ calledDeleteFunction: true }, () => {
                    this.getAllNotificationsApi(configJSON.getNotificationsApiEndPoint);
                });
            }
        }

        if (prevProps.deletenotificationMsg !== this.props.deletenotificationMsg) {
            this.getAllNotificationsApi(configJSON.getNotificationsApiEndPoint);
        }
    }

    handleChangeEvent = (event: React.ChangeEvent<HTMLInputElement> | any) => {
        this.setState({
            checkedItems: {
                ... this.state.checkedItems,
                [event.target.name]: event.target.checked
            },
            isSelectAll: false
        });

    };

    handleDeleteAll = () => {
        this.setState({ openDialog: false, smallPopoverOpen: false });
        const selectedNotificationId: any = Object.entries(this.state.checkedItems).map(([key, value]) => key).flat()
        this.deleteNotificationApi(selectedNotificationId)
    }

    handleSelectAllBtn = () => {
        const notificationListData = this.state.notificationList.reduce((acc: any, list) => {
            const ids = list.data.map((item) => item.id);
            acc = [...acc, ...ids];
            return acc
        }
            , [])

        let data = {}
        notificationListData.forEach((item: string) => {
            data = { ...data, [item]: true }
        })

        this.setState({ checkedItems: data, isSelectAll: true })
    }

    handleDeSelectAllBtn = () => {
        this.setState({ checkedItems: {}, isSelectAll: false })
    }

    handleCloseFunction = () => {
        this.setState({ anchorEl: null, open: false, id: false });
    };

    handleClickOpenDialogFunction = () => {
        this.setState({ openDialog: true });
        this.handleClosePopover()
    };

    handleCloseDialogFunction = () => {
        this.setState({ openDialog: false });
    };

    searchSubmit = (event: any) => {
        if (event.key == "Enter") {
            switch (this.state.tabValue) {
                case 0:
                    this.searchNotificationApi(configJSON.searchNotificationApiEndPoint, event.target.value)
                    break;
                case 1:
                    this.searchNotificationApi(configJSON.getQuotesNotificationApiEndPoint, event.target.value)
                    break;
                case 2:
                    this.searchNotificationApi(configJSON.getInvoicesNotificationApiEndPoint, event.target.value)
                    break;
                case 3:
                    this.searchNotificationApi(configJSON.getProjectsNotificationApiEndPoint, event.target.value)
                    break;
                case 4:
                    this.searchNotificationApi(configJSON.getVendorsNotificationApiEndPoint, event.target.value)
                    break;
                case 5:
                    this.searchNotificationApi(configJSON.getComparisonNotificationApiEndPoint, event.target.value)
                    break;
                default:
                    break;
            }
        }
    }

    markAsReadNotificationApi = (items:any) => {
        const header = {
            "Content-Type": configJSON.validationApiContentType,
            "token": this.state.token
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.markAsReadNotificationApiId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.markAsReadNotificationApiEndPoint}${ items.attributes?.id || items }`
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.putNotificationsApiMethod
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);

        return true;
    }

    searchNotificationApi = (searchApiEndPoint: string, value: string) => {
        const header = {
            "Content-Type": configJSON.validationApiContentType,
            "token": this.state.token
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.searchNotificationsApiId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${searchApiEndPoint}${value}`
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.getBulkUploadMethod
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);

        return true;
    }

    deleteNotificationApi = (deleteId: any) => {
        const deleteSingle = (deleteId.length == 0 && !this.state.isSelectAll) ? this.state.selectedItem.id : deleteId.join(',')

        const header = {
            "Content-Type": configJSON.validationApiContentType,
            "token": this.state.token
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.deleteNotificationsApiId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.deleteNotificationsApiEndPoint}${this.state.isSelectAll ? 'All' : deleteSingle}`
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.deleteBulkUploadMethod
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);

        return true;
    }

    getAllNotificationsApi = (apiEndPoint: string) => {
        const header = {
            "Content-Type": configJSON.validationApiContentType,
            "token": this.state.token
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.getAllNotificationsApiId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            apiEndPoint
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.getBulkUploadMethod
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);

        return true;
    }

    handleClickPopover = (event: any, item: any) => {
        this.setState({ SmallEnchor: event.currentTarget, selectedItem: item }, () => {
            this.setState({ smallPopoverOpen: Boolean(this.state.SmallEnchor), })
        });
    }

    handleClosePopover = () => {
        this.setState({ SmallEnchor: null, smallPopoverOpen: false })
    }

    // Customizable Area End
}

