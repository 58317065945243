Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "MatchAlgorithm2";
exports.labelBodyText = "MatchAlgorithm2 Body";
exports.reportTitle = "Invoices";
exports.reportQuotesTitle = "Quotes";

exports.getApiMethod = "GET";
exports.getReportApiEndPoint = "bx_block_filter_items/invoice_libraries"


exports.btnExampleTitle = "CLICK ME";
exports.cancelButton = "Cancel";
exports.okButton = "Ok";
exports.quotesTitle = "Quote";
exports.invoiceTitle = "Invoice";
exports.ResultAnalysisTitle = "Result Analysis";
exports.differenceLable = "Difference"
exports.quotesUploadApiEndPoint = "bx_block_filter_items/quotes_libraries"
exports.invoicesUploadApiEndPoint = "bx_block_filter_items/invoice_libraries"
exports.getQuotesListApiEndPoint = "bx_block_filter_items/quotes_libraries/quotes_id_list";
exports.getInvoicesListApiEndPoint = "bx_block_filter_items/invoice_libraries/invoice_id_list";
exports.generateReportApiEndPoint = "bx_block_reports/reports/quote_invoice_comparison?";
exports.getReportQuotesApiEndpoint = "bx_block_notifications/quotes_report"
// Customizable Area End