import React from 'react'
import { styled } from '@material-ui/styles'
import { Box, TextField, InputLabel, Grid, FormControl, Button, createTheme } from "@material-ui/core";
import { SearchOutlined } from '@material-ui/icons';
import { DateRangePicker } from 'rsuite';
import "rsuite/dist/rsuite.css";
import Autocomplete from '@material-ui/lab/Autocomplete';
import { calenderIcon, calenderIconWhite, downArrow, downArrowWhite } from '../src/assets';
import SearchWebController, {Props} from './SearchWebController.web';
import './index.css'
import { makeStyles } from "@material-ui/core/styles";
import { ThemeProvider } from 'react-native-elements';

const theme = createTheme({
    palette: {
      primary: {
        main: '#083247',
      },
    }
  });

const useStyles = makeStyles({
    optionClass: {
        '&[aria-selected="true"]': {
            backgroundColor: '#DF802D'
    }
    }
  });
  
  export const CustomAutoComplete = (props: any) => {
      const classes = useStyles()
      return (
            // @ts-ignore
          <Autocomplete id={props?.id} options={props.options || []} getOptionLabel={(option: string): string => option} style={props?.style} classes={{ paper: classes.paper, option: classes.optionClass }} renderInput={(params) => <TextField {...params} placeholder={props.placeholder} variant="outlined" />} onChange={(e,val)=>{props.onChange(val)}} />
      )
  }

const StyledTextField = styled(TextField)({
    '& .MuiInputBase-input': {
        height: 'auto !important',
        padding: '14px',
        lineHeight: '10px'
    },
    '& .MuiOutlinedInput-root': {
        borderRadius: '8px',
    },
    '& .MuiInputLabel-outlined': {
        transform: 'translate(14px, 16px) scale(1)',
        paddingLeft: '35px'
    },
})

const FormWrap = styled(Box)({
    '& .MuiOutlinedInput-root': {
        overflow: 'hidden',
    },
    '& .MuiSelect-selectMenu': {
        fontWeight: '500',
        color: '#000',
        padding: '5px 15px',
        height: '40px',
        display: 'flex',
        alignItems: 'center',
    },
    '& .MuiSelect-icon': {
        color: '#000',
    },
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderColor: 'rgb(223, 128, 45)',
    },
    '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
        borderColor: 'rgb(223, 128, 45)',
    },
    '& .MuiOutlinedInput-root.Mui-focused .MuiSelect-selectMenu': {
        color: '#fff',
        backgroundColor: 'rgb(223, 128, 45)',
    },
    '& .MuiOutlinedInput-root.Mui-focused .MuiSelect-icon': {
        color: '#fff',
    },
    '& .MuiChip-root': {
        backgroundColor: 'transparent',
        border: '1px solid rgba(0, 0, 0, 0.23)',
        borderRadius: '6px',
        marginRight: '5px',
        height: '24px',
        color: '#000',
    },
    '& .MuiOutlinedInput-root.Mui-focused .MuiChip-root': {
        color: '#fff',
        borderColor: '#fff',
    },
    '& .MuiOutlinedInput-root .PrivateNotchedOutline-legendLabelled-11 > span': {
        display: 'none',
    },

})
const SearchInputWrap = styled(Box)({
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    '& .MuiSvgIcon-root': {
        position: 'absolute',
        left: '20px',
        width: '20px',
        height: '20px',
    }
})
const StyledButton = styled(Button)({
    padding: "8px 25px",
    whiteSpace: 'nowrap',
    minWidth: '150px',
    background: "rgb(223, 128, 45)",
    textAlign: "center",
    borderRadius: "5px",
    color: "#fff",
    textTransform: "capitalize",
    fontSize: '16px',
    border: '1px solid rgb(223, 128, 45)',
    '&:hover': {
        backgroundColor: 'rgb(223, 128, 45)',
        color: '#fff',
    }
})
const StyledCancelButton = styled(Button)({
    padding: "8px 25px",
    whiteSpace: 'nowrap',
    background: "#fff",
    textAlign: "center",
    borderRadius: "5px",
    color: "#111",
    border: "1px solid transparent",
    textTransform: "capitalize",    
    marginRight: "15px",
    fontSize: '16px',
    '&:hover': {
        backgroundColor: '#fff',
        color: '#111',
    },
    '& .MuiTouchRipple-root .MuiTouchRipple-child': {
        backgroundColor: 'rgba(223, 128, 45,0.4)',
    }
})
const StyledDateRangePicker = styled(DateRangePicker)({
    '& .rs-picker-toggle':{
        borderRadius: '4px',
        padding:'5px 25px 5px 50px !important',
        height: '50px',
        display: 'flex',
        alignItems:'center',
        borderColor: 'rgba(0, 0, 0, 0.23) !important',
        boxShadow: 'none !important',
        outline : 'none !important',
        backgroundImage: `url(${calenderIcon})` ,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center left 15px',
        position: 'relative',
    },
    '& .rs-picker-toggle.rs-picker-toggle-active':{
        borderColor: 'rgb(223, 128, 45) !important',
        backgroundColor:'rgb(223, 128, 45) !important',
        backgroundImage: `url(${calenderIconWhite})` ,
    },
    '& .rs-picker-toggle::after':{
        backgroundImage: `url(${downArrow})` ,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        content: '""',
        position:'absolute',
        right: '8px',
        width:'24px',
        height: '100%',
    },
    '& .rs-picker-toggle[aria-expanded="true"]::after':{
        transform: 'rotate(180deg)',
    },
    '& .rs-picker-toggle.rs-picker-toggle-active::after':{
        backgroundImage: `url(${downArrowWhite})` ,
       
    },
    '& .rs-picker-toggle-textbox':{
        padding: '5px 25px 5px 50px',
        background: 'transparent',
        color:'#000',
    },
    '& .rs-picker-toggle.rs-picker-toggle-active .rs-picker-toggle-textbox':{
        color: '#fff',
    },
    '& .rs-picker-toggle-placeholder':{
        color:'#000',       
        opacity: '1', 
        fontSize: '16px',
        fontWeight: '500',
    },
    '& .rs-picker-toggle.rs-picker-toggle-active .rs-picker-toggle-placeholder':{
        color: 'transparent',
    },
    '& .rs-picker-toggle.rs-picker-toggle-active .rs-picker-toggle-textbox::-webkit-input-placeholder ':{
        color: 'transparent',
    },
    '& .rs-picker-toggle .rs-picker-toggle-value':{
        color:'#000',
    },
    '& .rs-picker-toggle.rs-picker-toggle-active .rs-picker-toggle-value':{
        color:'#fff',
    },
    '& .rs-picker-toggle.rs-btn .rs-picker-toggle-caret':{       
        display: 'none',
    },
    '& .rs-picker-toggle.rs-btn .rs-picker-toggle-clean':{
        padding: '15px 25px 0 0'
    }
})

const StyledSearchButton = styled(Button)({
    display: 'inline-block',
    width: '100%',
    textAlign: 'left',
    paddingLeft: '50px'
})

const BoxSearchWrapper = styled(Box)({
    width: "800px",
    margin: "20px auto",
    border: '1px solid #f1f1f1',
    borderRadius: '10px',
    backgroundColor: '#fff',
    '@media (max-width: 960px)': {
        width: '100%'
    }
})
interface S {
    open: boolean;
}
export default class SearchWeb extends SearchWebController {
    constructor(props: Props) {
        super(props)
    }

    applyHandler = () => {
        const data = {
            'documentType': this.state.documentType, 
            'project': this.state.project,
            'vendor': this.state.vendor,
            'keyword': this.state.keyword,
            'start_date': this.convertDateToString(this.state.dateRange?.[0]),
            'end_date': this.convertDateToString(this.state.dateRange?.[1])
          }
          this.applyBtnHandler()
        //   this.props?.onApplyClick(data)
    }

    render() {
        return (
            <>
                {/* 
            // @ts-ignore */}
                <ThemeProvider theme={theme}>
                <BoxSearchWrapper>
                    {/* 
            // @ts-ignore */}
                    <Box>
                        <SearchInputWrap onClick={()=>{this.advSearchOpen(!this.state.AdvSearchOpen)}} data-test-id='searchBar'>
                            <StyledSearchButton>
                                <SearchOutlined style={{top: '8px'}}/>
                                Search...
                            </StyledSearchButton>
                        </SearchInputWrap>
                    </Box>
                    {this.state.AdvSearchOpen ?
                        <>
                        {/* 
            // @ts-ignore */}
                        <Box style={webStyle.advanceSearch}>
                            <h4 style={webStyle.advanceHeading}> Advanced Search</h4>
                            <form>
                                <FormWrap>
                                    <Grid container spacing={3}>
                                        <Grid item xs={6}>
                                            <InputLabel style={webStyle.selectLabel}>Document Type</InputLabel>
                                            <FormControl variant="outlined" style={webStyle.selectField} data-test-id='documentTypeInput'>
                                                <CustomAutoComplete
                                                    id="Document"
                                                    options={this.state.documentTypeOptions}
                                                    style={{ width: '100%' }}
                                                    placeholder={'Choose Document'}
                                                    // @ts-ignore 
                                                    onChange={this.handleDocumentTypeChange}
                                                />
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <InputLabel style={webStyle.selectLabel}>Project</InputLabel>
                                            <FormControl variant="outlined" style={webStyle.selectField} >
                                                <CustomAutoComplete
                                                    id="project"
                                                    open={true}
                                                    options={this.state.projectOptions}
                                                    style={{ width: '100%' }}
                                                    placeholder="Choose Project"
                                                    // @ts-ignore
                                                    onChange={this.handleProjectTypeChange}
                                                />
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <InputLabel style={webStyle.selectLabel}>Vendor</InputLabel>
                                            <FormControl variant="outlined" style={webStyle.selectField} >
                                                <CustomAutoComplete
                                                    id="vendor"
                                                    options={this.state.vendorOptions}
                                                    style={{ width: '100%' }}
                                                    placeholder="Choose Venfor"
                                                    // @ts-ignore
                                                    onChange={this.handleVendorChange}
                                                />
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <InputLabel style={webStyle.selectLabel}>Key Words</InputLabel>
                                            <FormControl variant="outlined" style={webStyle.selectField} >
                                                <StyledTextField placeholder="Search..." variant="outlined" style={webStyle.searchField} onChange={this.handleKeywordChange}>
                                                </StyledTextField>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <InputLabel style={webStyle.selectLabel}>Date Range</InputLabel>
                                            <FormControl variant="outlined" style={webStyle.selectField} >
                                                <StyledDateRangePicker onChange={this.dateChangeHandler} placeholder="Choose Date" ranges={this.Ranges} character=" - " />                                                
                                            </FormControl>
                                        </Grid>
                                        <Grid style={webStyle.btnGrid}>
                                            <StyledCancelButton onClick={this.cancelBtnHandler}>Cancel</StyledCancelButton>
                                            <StyledButton onClick={this.applyHandler} data-test-id={'applyBtn'}>Apply</StyledButton>
                                        </Grid>
                                    </Grid>
                                </FormWrap>
                            </form>
                        </Box></> : ""
                    }
                </BoxSearchWrapper>
                </ThemeProvider>
            </>
        )
    }
}
const webStyle = {
    searchField: {
        width: "100%",
    },
    advanceSearch: {
        padding: "20px",
    },
    selectField: {
        width: "100%",
    },
    advanceHeading: {
        width: "100%",
        fontSize: "24px",
        margin: "0 0 20px",
    },
    selectLabel: {
        fontSize: "18px",
        margin: "0 0 10px",
        lineHeight: "1",
        color: "#000",
        fontWeight: 700,
    },
    btnGrid: {
        display: "flex",
        justifyContent: "flex-end",
        width: "100%",
        padding: "15px",
    },
    
}
