// Customizable Area Start
import React from "react";
import { Typography, Box } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

import Header from "../../landingpage/src/Header.web";
import DashboardNewTable from "../../landingpage/src/DashboardNewTable.web";
import ReportInvoiceController, { Props, configJSON } from "./ReportInvoiceController.web";

export class ReportInvoice extends ReportInvoiceController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    render() {
        const { classes } = this.props
        return (
            <>
                <Header
                    data-testid="ReportInvoiceHeaderId"
                    handleSaveData={this.handleSaveData}
                    navigation={this.props.navigation}
                    history={this.props.history}
                />
                <Box className={classes.invoiceCompareBox}>
                    <Box className={classes.InvoiceHeading}>
                        <Typography variant="h5">{configJSON.invoiceText}</Typography>
                        <Typography>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Natus unde omnis perferendis iure consectetur. Ab, earum aperiam perferendis molestias enim sint.</Typography>
                    </Box>
                </Box>
                <DashboardNewTable
                    rows={this.state.invoiceData}
                    title={configJSON.uploadTitle}
                    hasMenu={true}
                    hasStatusData={false}
                    hasInvoicePrice={true}
                    navigatePath="/report/invoice"
                />
            </>
        );
    }
}

export const webStyle = {
    invoiceCompareBox: {
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: '15px'
    },
    InvoiceUploadButton: {
        '& button': {
            width: '100%',
            minWidth: '172px',
            marginTop: '15px'
        }
    },
    navLinkStyle: {
        textDecoration: 'none',
        '&:focus, &:hover, &:visited, &:link, &:active': {
            textDecoration: 'none'
        }
    },
    InvoiceHeading: {
        paddingRight: '5px'
    }
};

export default withStyles(webStyle)(ReportInvoice)

// Customizable Area End
