// Customizable Area Start
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import React from "react";
import { DateRangePicker } from 'react-date-range';
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import {Box } from "@material-ui/core";

import { styled } from "@material-ui/styles";
import DateRangeDialogController, { Props } from './DateRangeDialogController';


const StyledDialog = styled(Dialog)({
  position: 'absolute',
  top: '20px',
  "& .MuiBackdrop-root": {
    backgroundColor: "rgba(1, 1, 1, 0.2)"
  },
  "& .MuiDialog-paperWidthSm": {
    maxWidth: "none",
    //height: "620px",
    display: "flex",
    borderRadius: "15px"
  },
  "& .MuiDialogActions-root": {
    justifyContent: "space-around"
  },
  "& .MuiDialog-paper": {
    position: 'absolute',
    top: '10px',
    maxHeight: 'inherit',
    left: '287px',
    borderRadius: '15px',
    overflowX: 'hidden',
  },
  "& .rdrCalendarWrapper": {
    width: '580px',
  },
  "& .rdrInRange": {
    color: 'rgb(223, 128, 45) !important',
  },
  "& .rdrEndEdge":{
    color: 'rgb(223, 128, 45) !important',
  },
  "& .rdrStartEdge": {
    color: 'rgb(223, 128, 45) !important',
  },
  "& .rdrDateDisplay": {
    color: 'rgb(223, 128, 45) !important',
  },
});

const StyledButton = styled(Button)({
  textTransform: 'capitalize',
  fontSize: '0.75rem',
  fontWeight: 500,
});


export default class DateRangeDialog extends DateRangeDialogController {
  /**
   *
   */
  constructor(props:Props) {
    super(props);
  }

  render() {
    return (
      <div> 
        <StyledDialog
          open={this.props.hasDateRange}
          // onClose={this.props.handleCloseDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
        <DateRangePicker
          onChange={(item:any) => this.props.handleDateRangeValue([item.selection])}
          // showSelectionPreview={true}
          maxDate={new Date()}
          moveRangeOnFirstSelection={false}
          months={2}
          ranges={this.props.dateRangeValue}
          direction="horizontal"
        />
        <Box sx={webStyle.buttonStyle}>
          <Box
            data-test-id="btnCancel"
            onClick={() => this.props.handleDateRangeModal()}
          >
            <StyledButton style={webStyle.buttonCancel} color={"primary"}>{'Next'}</StyledButton>
          </Box>
        </Box>
        </StyledDialog>
      </div>
    );
  }
}


const webStyle = {
  contentWrapperMain: {
    position: 'absolute',
    top: '20px',
  },
  contentWrapper: {
    maxWidth: "615px",
    width: "615px",
    padding: "0 25px 25px 25px",
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap'
  },
  contentWidthHalf: {
    width: 'calc(50% - 20px)',
    margin: '10px 10px'
  },
  contentWidth: {
    width: '100%',
    marginTop: '20px',
  },
  contentLabel: {
    color: 'black',
    fontSize: '20px',
    fontWeight: 'bold',
  },
  buttonStyle: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'end',
    marginTop: '10px',
    width: '100%',
    marginBottom: '20px',
    paddingRight: '25px',
  },
  buttonCancel: {
    color: '#fff',
    backgroundColor: 'rgb(223, 128, 45)',
    borderRadius: '6px',
    padding: '8px 18px',
  },
};
// Customizable Area End
