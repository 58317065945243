// Customizable Area Start
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { IBlock } from "../../../framework/src/IBlock";
import Emitter from "../../BulkUploading/src/util";

export const configJSON = require("./config");

export interface Props {
    modalOpen: boolean,
    classes: any,
    navigation: any,
    handleSaveData: () => {},
    history: any,
    tabIndexValue:any;
    location:any;
    projectValue:any;
}
export interface TabPanelProps {
    // Customizable Area Start
    children?: React.ReactNode;
    index: number;
    value: number;
    // Customizable Area End
}

export interface RenderDialogProps {
    openDialog: boolean;
    handleCloseDialog: () => void;
    apiError: boolean;
    message: string;
    isLoading: boolean;
}

interface S {
    // Customizable Area Start
    value: number;
    invoiceTab: any;
    accept: any;
    projects: any;
    projectValue: string;
    progressVal: number;
    compareModal: boolean;
    pdf_id: string;
    quote_id: string;
    apiError: boolean;
    uploadAcceptedFile: [];
    invoiceCompareResult: string;
    invoiceCompareError: boolean;
    acceptedFileType: any;
    noCompareFileId: null;
    documentModal: boolean;
    timeRemaining: any;
    invoiceUploadLoader: boolean;
    openDialog: boolean;
    isLoading: boolean;
    // Customizable Area End
}

interface SS {
    // Customizable Area Start
    // Customizable Area End
}

export default class InvoiceUploadController extends BlockComponent<Props, S, SS> {
    // Customizable Area Start

    projectListId: string = '';
    compareInvoiceApiId: string = '';
    invoiceUploadId: string = '';
    invoiceOpenApiId: string = '';
    noCompareInvoiceAPIId: string = '';

    constructor(props: Props) {
        super(props);
        this.state = {
            value: 0,
            accept: [],
            pdf_id: '',
            quote_id: '',
            projects: [],
            apiError: false,
            projectValue: '',
            openDialog: false,
            acceptedFileType: { "application/pdf": [".pdf"] },
            invoiceTab: [
                {
                    id: 1,
                    type: "Select"
                },
                {
                    id: 2,
                    type: "Uploading"
                },
                {
                    id: 3,
                    type: "Result"
                }
            ],
            progressVal: 0,
            compareModal: false,
            documentModal: false,
            uploadAcceptedFile: [],
            invoiceCompareResult: "",
            invoiceCompareError: false,
            noCompareFileId: null,
            timeRemaining: 20,
            invoiceUploadLoader: false,
            isLoading: false
        };
        this.subScribedMessages = [
            getName(MessageEnum.CountryCodeMessage),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.ReciveUserCredentials),
        ];
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages)
        // Customizable Area End
    }

    handleCloseDialog = () => {
        this.setState({ openDialog: false, uploadAcceptedFile: [], apiError: false, invoiceCompareResult: '' });
    };

    componentDidMount(): any {
        this.fetchProjects();
    }

    onInvoiceDrop = (acceptedFile: any) => {
        this.setState({ uploadAcceptedFile: acceptedFile })
    }

    setProjectValue = (event: React.ChangeEvent<{ value?: string | unknown }>) => {
        const selectedValue = event.target.value as string;
        this.setState({ projectValue: selectedValue });
    }

    getToken = () => {
        return document.cookie.split("; ").find((row) => row.startsWith("Token="))?.split("=")[1]
    }

    fetchProjects() {
        const header = {
            "Content-Type": configJSON.fileUploadApiContentType,
            "token": this.getToken()
        };

        const requestProjectListMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.projectListId = requestProjectListMessage.messageId;
        requestProjectListMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.getProjectsApiEndPoint
        );

        requestProjectListMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestProjectListMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.fileGetApiMethod
        );

        runEngine.sendMessage(requestProjectListMessage.id, requestProjectListMessage);

        return true;
    }

    async receive(from: string, message: Message) {
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            if (apiRequestCallId === this.projectListId) {
                this.setState({ projects: responseJson })
            }

            if (apiRequestCallId === this.compareInvoiceApiId) {
                this.invoiceUploadHandler(responseJson)
                let url = responseJson.quote_id
                this.setState({ pdf_id: url })
            }

            if (apiRequestCallId === this.noCompareInvoiceAPIId) {
                this.invoiceUploadNoFlowHandler(responseJson)
                this.setState({ noCompareFileId: responseJson.invoice_id, invoiceUploadLoader: false, isLoading: false })
            }

            if (apiRequestCallId === this.invoiceOpenApiId && !(responseJson.errors)) {
                const OpenFile = responseJson
                this.setState({ pdf_id: OpenFile.pdf_url })
            }

        }
    }

    invoiceUploadNoFlowHandler = (responseJson: any) => {
        if (responseJson.errors || responseJson.error) {
            this.setState({ invoiceCompareError: true, invoiceCompareResult: responseJson.error })
        } else {
            this.setState({ invoiceCompareError: false, invoiceCompareResult: responseJson.message })
            this.props.history.push('/invoice/invoiceComments', { idState: { id: responseJson.invoice_id } })
        }
    }

    invoiceUploadHandler = (responseJson: any) => {
        Emitter.emit("FETCH_NOTIFICATION_LIST_FOR_POPUP", {})
        if (responseJson.errors || responseJson.error) {
            this.setState({ invoiceCompareError: true, invoiceCompareResult: responseJson.error })
        } else {
            this.setState({ invoiceCompareError: false, invoiceCompareResult: responseJson.message })
        }
        this.setState({ value: 2, invoiceUploadLoader: false })
    }

    handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        if (newValue == 0) {
            this.setState({ value: newValue, projectValue: "", uploadAcceptedFile: [] })
        }
    }

    handleUploadClick = () => {
        this.setState({ value: this.state.value - 2, projectValue: "" })
    }

    comparehandleClose = () => {
        this.setState({ compareModal: false, documentModal: false })
    }

    comparehandleOpen = () => {
        this.setState({ compareModal: !this.state.compareModal })
    }

    handleDocumentOpenerClick = () => {
        this.setState({ documentModal: true })
        this.invoiceViewOpen()
    }

    startProgres = () => {
        let counter = 1;
        const totalTime = 20;
        const intervalTime = 100;
        const interval = setInterval(() => {
            counter += intervalTime;

            const newProgress = Math.min((counter / (totalTime * 1000)) * 100, 95);
            const remainingTime = Math.max(totalTime - counter / 1000, 1);
            this.setState({
                progressVal: newProgress, timeRemaining: remainingTime
            })
            if (!this.state.invoiceUploadLoader && this.state.value !== 1) {
                clearInterval(interval);
                this.setState({ progressVal: 0, timeRemaining: 20 })
            }
        }, 200);
    }

    yesComparefile = (newValue: number) => {
        this.setState({ value: 1, invoiceUploadLoader: true, compareModal: false })
        this.startProgres()

        const header = {
            "token": this.getToken()
        };

        const formData: any = new FormData();

        this.state.uploadAcceptedFile.forEach((file: any) => {
            formData.append('file', file);
        });

        const compareRequestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.compareInvoiceApiId = compareRequestMessage.messageId;

        compareRequestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.compareApiEndPoint}?vendor_name=${this.state.projectValue}`
        );

        compareRequestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        compareRequestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            formData
        );

        compareRequestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.compareApiMethodType
        );

        runEngine.sendMessage(compareRequestMessage.id, compareRequestMessage);

        return true;
    };

    noComparefile = () => {
        this.setState({ compareModal: false, invoiceUploadLoader: true, openDialog: true, isLoading: true })
        const header = {
            "token": this.getToken()
        };

        const formData: any = new FormData();

        this.state.uploadAcceptedFile.forEach((file: any) => {
            formData.append('file', file);
        });

        const nocompareRequestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.noCompareInvoiceAPIId = nocompareRequestMessage.messageId;

        nocompareRequestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.noCompareApiEndPoint
        );

        nocompareRequestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        nocompareRequestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            formData
        );

        nocompareRequestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.compareApiMethodType
        );

        runEngine.sendMessage(nocompareRequestMessage.id, nocompareRequestMessage);

        return true;

    };

    handleOpenerClick = () => {
        this.invoiceViewOpen()

    }
    invoiceViewOpen = () => {
        const header = {
            "Content-Type": configJSON.validationApiContentType,
            "token": this.getToken()
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.invoiceOpenApiId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.invoicePdfOpenEndPoint}quote_id=${this.state.pdf_id}`
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
        return true;
    }
    // Customizable Area End
}
