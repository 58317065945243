// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

export const configJSON = require("./config");

export interface Props {
  // Customizable Area Start
  navigation: any;
  id: string;
  classes: any;
  rows: any [];
  history: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  uploadData: any;
  rows: any [];
  quote_Id: string;
  id:any;
  files: any[];
  openDialog: boolean;
  isLoading: boolean;
  apiError: boolean,
  status: number;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start

  // Customizable Area End
}

export default class DashboardInvoiceController extends BlockComponent<Props, S, SS> {

  getInvoiceFile: string = "";
  constructor(props: Props) {
    super(props);

    this.state = {
      // Customizable Area Start
      rows:props.rows,
      openDialog: false,
      isLoading: true,
      apiError: false,
      status: NaN,
      uploadData: [],
      quote_Id:'',
      id:'',
      files: [],
      // Customizable Area End
    };

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage)
    ];

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages)
  }

  handleSaveData = (data: any) => {
    this.setState({ uploadData: data })
  }

  async componentDidMount() {
    this.getInvoices()
  }

  getInvoices = () => {
    const header = {
      token: document.cookie.split("; ").find((row) => row.startsWith("Token="))?.split("=")[1]
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getInvoiceFile = requestMessage.messageId

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getQuoteApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getUploadApiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };

  // handleDelete = async(event: React.MouseEvent<HTMLButtonElement>, id: string)=>{
  //   await this.deleteInvoice()
  //   this.setState({ quote_Id: id });


  // }


  // deleteInvoice = async () => {

  //   const token = document.cookie.split("; ").find((row) => row.startsWith("Token="))?.split("=")[1]
    
  //   const header = {
  //     "Content-Type": configJSON.validationApiContentType,
  //   };

  //   const requestMessage = new Message(
  //     getName(MessageEnum.RestAPIRequestMessage)
  //   );

  //   this.deleteQuoteUploadApiId = requestMessage.messageId;

  //   requestMessage.addData(
  //     getName(MessageEnum.RestAPIResponceEndPointMessage),
  //     `${configJSON.invoiceDeleteApi}/${this.state.quote_Id}?token=${token}`
  //   );

  //   requestMessage.addData(
  //     getName(MessageEnum.RestAPIRequestMethodMessage),
  //     configJSON.quoteUploadDeleteApiMethod
  //   );

  //   requestMessage.addData(
  //     getName(MessageEnum.RestAPIRequestHeaderMessage),
  //     JSON.stringify(header)
  //   );

  //   runEngine.sendMessage(requestMessage.id, requestMessage);

  //   return true;
  // }

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const invoicesApiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const invoicesResponseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (invoicesApiRequestCallId === this.getInvoiceFile) {
        if (invoicesResponseJson.errors || invoicesResponseJson.error) {
          this.setState({ apiError: invoicesResponseJson.error })
        } else {
          const rowData: any[] = [];
          invoicesResponseJson.data.forEach((tableData: any) =>
            rowData.push({
              ...tableData.attributes
            })
          )
          this.setState({ uploadData: rowData })
        }
    }}
  }

}

// Customizable Area End