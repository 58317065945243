import React from 'react'
// Customizable Area Start

import {
  Formik,
  FormikProps,
  Form,

} from 'formik';
import * as Yup from 'yup'
import { TextField, Button } from "@material-ui/core"
import { View } from 'react-native';
import { styled } from '@material-ui/styles'
import Alert from '@material-ui/lab/Alert';
// Customizable Area End
import EmailAccountLoginWebController, { Props } from "./EmailAccountLoginWebController.web";
// Customizable Area Start
export interface MyFormValues {
  password: string;
  email: string;
}
const StyledButtonLogin = styled(Button)({
  background: '#ef7927',
  width: '100%',
  height: "45px",
  textTransform: 'capitalize',
  padding: '16px',
  fontWeight: 600,
  marginTop: '20px',
  borderRadius: '8px',
  boxShadow: 'none',
  '&:hover': {
    background: '#ef7927',
  }

});
const StyledTextFeildLogin = styled(TextField)({
  '& input': {
    paddingTop: '6px',
    paddingBottom: '6px',
    height: '35px',
  },
  '& .MuiFormLabel-root[data-shrink="false"]': {
    transform: 'translate(14px, 16px) scale(1)',
  },
  '& .MuiInputLabel-outLined.MuiInputLabel-shrink': {
    transform: "translate(12px, -6px) scale(0.70)"
  }

});
const StyledAlert = styled(Alert)({
  marginBottom: "10px"
})
// Customizable Area End
export default class EmailAccountLoginWeb extends EmailAccountLoginWebController {

  constructor(props: Props) {
    super(props)
  }
  // Customizable Area Start
  initialValues: MyFormValues = { email: this.state.email, password: this.state.password };
  onSubmitHandler = (values: any, func: any) => {
    this.setState({ email: values.email, password: values.password })
    this.onSubmit();
    setTimeout(() => { func.setSubmitting(false) }, 2000);
  }
  // Customizable Area End
  render() {
    // Customizable Area Start
    return (
      <Formik
        initialValues={this.initialValues}
        onSubmit={this.onSubmitHandler}

        validationSchema={Yup.object().shape({
          email: Yup.string()
            .email()
            .required('Enter valid email-id'),
          password: Yup.string()
            .matches(
              /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,15}$/
            )
            .required(
              'Please enter valid password. One uppercase, one lowercase, one special character and no spaces'
            ),
        })}

      >
        {(props: FormikProps<MyFormValues>) => {
          const {
            values,
            touched,
            errors,
            handleBlur,
            handleChange,
            isSubmitting,
          } = props
          return (
            //@ts-ignore
            <Form translate={undefined}>
              {this.state.apiError ? <StyledAlert severity="error">{this.state.serverSideError ? "Login Failed, Please try again later" : "Invalid username of password!"}</StyledAlert>
                : ""}
              <EmailAccountLoginForm
                values={values}
                errors={errors}
                touched={touched}
                handleChange={handleChange}
                handleBlur={handleBlur}
                isSubmitting={isSubmitting}
              />
            </Form>
          )
        }}
      </Formik>
    )
    // Customizable Area End
  }
}

// Customizable Area Start
export const EmailAccountLoginForm = ({
  values,
  errors,
  touched,
  handleChange,
  handleBlur,
  isSubmitting,
}: any) => {
  return (
    <>
      {/* 
        // @ts-ignore */}
      <View style={webStyle.txtLogin}>
        <label style={webStyle.labelTxtLogin}>Email</label>
        <StyledTextFeildLogin
          name="email"
          id="email"
          label="Enter your email"
          data-test-id="txtInputEmailLogin"
          value={values?.email}
          variant="outlined"
          type="email"
          helperText={
            errors?.email && touched?.email
              ? errors?.email
              : ''
          }
          error={
            errors?.email && touched?.email
              ? true
              : false
          }
          onChange={handleChange}
          onBlur={handleBlur}
        />
      </View>
      {/* 
        // @ts-ignore */}
      <View style={webStyle.txtLogin}>
        <label style={webStyle.labelTxtLogin}>Password</label>
        <StyledTextFeildLogin
          name="password"
          id="password"
          label="Enter your password"
          data-test-id="txtInputPasswordLogin"
          value={values?.password}
          variant="outlined"
          type="password"
          helperText={
            errors?.password && touched?.password
              ? 'Please enter valid password. One uppercase, one lowercase, one special character and no spaces'
              : ''
          }
          error={
            errors?.password && touched?.password
              ? true
              : false
          }
          onChange={handleChange}
          onBlur={handleBlur}
        />
      </View>
      {/* 
        // @ts-ignore */}
      <View style={webStyle.txtPasswordLogin}>
        <a data-test-id="anchorTag" style={webStyle.txtLinkLogin} href="/recoverPassword">Forgot Password?</a>
      </View>
      <StyledButtonLogin
        type="submit"
        variant="contained"
        color="secondary"
        data-test-id="btnEmailLogIn"
        disabled={isSubmitting}
      >
        Sign In
      </StyledButtonLogin>
    </>
  )
}
// Customizable Area End
// Customizable Area Start
const webStyle = {
  txtLogin: {
    marginBottom: 20,
    fontWeight: 500,
  },

  labelTxtLogin: {
    marginBottom: 10,
    fontWeight: 500,
  },

  txtPasswordLogin: {
    textAlign: 'right',
    fontWeight: 300,
  },

  txtLinkLogin: {
    color: '#000',
    textDecoration: 'none'
  }

}
// Customizable Area End