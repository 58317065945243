// Customizable Area Start
import React from "react";
import {
    Box,
    Grid, Button, Tabs, Tab,
    Typography, TextField, TableContainer, Table, TableHead, TableRow, TableCell, Breadcrumbs, Link, TableBody, Switch, Input
} from "@material-ui/core";
import Pagination from '@material-ui/lab/Pagination';
import { createTheme, withStyles } from "@material-ui/core/styles";
import Header from "../../landingpage/src/Header.web";
import EditInvoiceController, { Props, configJSON } from "./EditInvoiceController.web";
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import Checkbox from '@material-ui/core/Checkbox';
import { deleteIcon } from "./assets";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import { NumericFormat } from "react-number-format";


// Customizable Area End

// Customizable Area Start
const theme = createTheme({
    palette: {
        primary: {
            main: "#fff",
            contrastText: "#fff",
        },
    },
    typography: {
        h6: {
            fontWeight: 500,
        },
        subtitle1: {
            margin: "20px 0px",
        },
    },
});
export const documentOcra11yProps = (indextab: any) => {
    return {
        id: `simple-tab-${indextab}`,
        'aria-controls': `simple-tabpanel-${indextab}`,
    };
}    

export const AddSegmentButton = (props : any) => {
    const { selected, addNewSegmentHandler, classes, deleteOpenModal, selectedData } = props
    return (
        <Box>
            {(selected.length > 0 || selectedData.length > 0) && <Button className={classes.deleteButton} data-testid="deleteModalTestid" onClick={deleteOpenModal}>{configJSON.deleteSelectedButton}</Button>}
            <Button onClick={addNewSegmentHandler} data-testid="addNewsegmentid"><AddCircleOutlineIcon />{configJSON.newSegment}</Button>
        </Box>
    )
}


// Customizable Area End


class EditInvoice extends EditInvoiceController {
    textfieldvalue1(textBox1: boolean, textfieldvalue1: any, arg2: string) {
      throw new Error("Method not implemented.");
    }
    constructor(props: Props) {
        super(props);
        // Customizable Area Start

        // Customizable Area End
    }

    // Customizable Area Start
    tableCell = (item: any, index: number) => {
        const { selectedRows, invoiceRowsPerPage, editInvoicePage , activeTab} = this.state;
        const begin = ((editInvoicePage[activeTab] ? editInvoicePage[activeTab] : 1 )- 1) * invoiceRowsPerPage;

        return (
            <>
                <TableCell className="invoiceTableId">{begin + index + 1}.</TableCell>
                <TableCell className="invoiceTableDescription" style={{ border: selectedRows.includes(item) ? '2px solid black' : '2px solid #d5d5d5' }}><Input id='description' style={{ width: "100%" }} disableUnderline value={item.description} onChange={(e) => this.editableFirstTable(e, item.id, 'description', index)}></Input></TableCell>
                <TableCell className="invoiceTableQuantity" style={{ border: selectedRows.includes(item) ? '2px solid black' : '2px solid #d5d5d5' }}><Input id='quantity' disableUnderline value={item.ord_quantity} onChange={(e) => this.editableFirstTable(e, item.id, 'ord_quantity', index)}></Input></TableCell>
                <TableCell className="invoiceTableUnit" style={{ border: selectedRows.includes(item) ? '2px solid black' : '2px solid #d5d5d5' }}><Input id='unit' disableUnderline value={item.order_unit} onChange={(e) => this.editableFirstTable(e, item.id, 'order_unit', index)}></Input></TableCell>
                <TableCell className="invoiceTableUnitPrice" style={{ border: selectedRows.includes(item) ? '2px solid black' : '2px solid #d5d5d5' }}>
                    <NumericFormat
                        id='unit_price'
                        value={item.price}
                        onValueChange={(values: any) => this.editableFirstTable({ target: { value: values.value } }, item.id, 'price', index)}
                        thousandSeparator={true}
                        prefix={'$ '}
                        allowNegative={false}
                        decimalScale={2}
                        displayType="input"
                        customInput={Input}
                        disableUnderline
                    />
                </TableCell>
                {this.state.invoiceDetailData[this.state.activeTab]?.switch && (
                    <TableCell className="invoiceTableTax" style={{ border: selectedRows.includes(item) ? '2px solid black' : '2px solid #d5d5d5' }}>
                        <NumericFormat
                            id='tax'
                            value={item.tax}
                            onValueChange={(values: any) => this.editableFirstTable({ target: { value: values.value } }, item.id, 'tax', index)}
                            suffix=" %"
                            allowNegative={false}
                            defaultValue={0}
                            decimalScale={2}
                            displayType="input"
                            customInput={Input}
                            disableUnderline
                            data-testid="taxInput"
                        />
                    </TableCell>)}
                <TableCell className="invoiceTableTotalPrice" style={{ border: selectedRows.includes(item) ? '2px solid black' : '2px solid #d5d5d5' }}>
                    <NumericFormat
                        id='amount'
                        value={item.amount}
                        onValueChange={(values: any) => this.editableFirstTable({ target: { value: values.value } }, item.id, 'amount', index)}
                        thousandSeparator={true}
                        prefix={'$ '}
                        allowNegative={false}
                        decimalScale={2}
                        displayType="input"
                        customInput={Input}
                        disableUnderline
                    />
                </TableCell>
            </>
        )
    };

    firstTable = () => {
        const { classes } = this.props;
        const { textBox1, selectAll, selectedRows, invoiceRowsPerPage, editInvoicePage, invoiceComparisonData, isModalOpen, invoiceDetailData , activeTab} = this.state;
        const invoiceComparisonDataMetaData = invoiceComparisonData[this.state.activeTab]?.invoice_description
        const begin = ((editInvoicePage[activeTab] ? editInvoicePage[activeTab] : 1 )- 1) * invoiceRowsPerPage;
        const end = begin + invoiceRowsPerPage;
        const countVal = Math.ceil(invoiceComparisonDataMetaData?.length / invoiceRowsPerPage);
        const textfieldvalue1 = invoiceComparisonData[this.state.activeTab]?.searchValue


        return (
            <>
                <Grid id='drag-drop' onDragOver={this.handleDragOverFirst} onDrop={this.handleDropFirst}>
                    <TableContainer >
                        <Table>
                            <TableHead>
                                <TableRow>
                                    {textBox1 && textfieldvalue1 && (
                                        <Checkbox
                                            id='handleSelectAllClick'
                                            style={{ color: selectAll ? 'black' : 'grey' }}
                                            inputProps={{ "aria-label": "select all desserts" }}
                                            checked={selectAll}
                                            onChange={this.handleSelectAllFirstTable}
                                            indeterminate
                                        />
                                    )}
                                    {this.state.invoiceColumn.map((column: any) => (
                                        (this.state.invoiceDetailData[this.state.activeTab]?.switch || column.id !== 'tax') && (<TableCell className="invoiceTableHeader" key={column.id} align={column.align} style={{ minWidth: column.minWidth }}>{column.label}</TableCell>)
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody className={classes.tableBodyBox} style={{ position: 'relative', zIndex: 1 }}>
                                {invoiceComparisonDataMetaData?.slice(begin, end).map((item: any, index: number) => (
                                    <>
                                        <TableRow
                                            id='invoiceDataTable'
                                            data-test-id='table-data'
                                            tabIndex={-1}
                                            key={item.id}
                                            draggable
                                            onDragStart={(e) => this.handleDragStart(e, item)}>
                                            {textBox1 && textfieldvalue1 && (
                                                <Checkbox
                                                    id="checkBox"
                                                    style={{ color: selectedRows.includes(item) ? 'black' : 'grey' }}
                                                    inputProps={{ "aria-label": "select all desserts" }}
                                                    checked={selectedRows.includes(item)}
                                                    onChange={(e) => this.handleCheckboxChange(e, item)}
                                                />
                                            )}
                                            {this.tableCell(item, index)}

                                            {!(textBox1 && textfieldvalue1) && (<>
                                                <img src={deleteIcon} data-test-id='singleDeleteIcon' className="InoviceDeleteIcon" onClick={() => this.handleDeleteSelectedRowsTable(item)} />
                                                <Dialog className={classes.rowDeleteModel} open={isModalOpen} onClose={this.handleCloseModal}>
                                                    <Grid className={classes.invoiceModelFirst}>
                                                        <ErrorOutlineIcon className="invoiceModelIcon" />
                                                        <DialogTitle className="invoiceModelDeleteText">{configJSON.deleteItemTitle}</DialogTitle>
                                                    </Grid>
                                                    <DialogContent>
                                                        <DialogContentText className={classes.modelMailtext}>{configJSON.deleteItemContent}</DialogContentText>
                                                        <DialogContentText className={classes.modelText}>{configJSON.deleteMultipleItemContent}</DialogContentText>
                                                    </DialogContent>
                                                    <DialogActions className={classes.invoiceModelBothButton}>
                                                        <Button className={classes.cancleButtonModel} data-test-id='handleOneCloseBtn' onClick={this.handleCloseModal} >{configJSON.invoiceCancel}</Button>
                                                        <Button className={classes.deleteButtonModel} data-test-id='singleDeleteRow' onClick={() => this.handleConfirmDelete()} >{configJSON.deleteButton}</Button>
                                                    </DialogActions>
                                                </Dialog>
                                            </>)}
                                        </TableRow>
                                    </>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
                <Pagination
                    count={countVal}
                    variant="outlined"
                    shape="rounded"
                    onChange={this.handleChangeinvoicePage}
                    page={editInvoicePage[activeTab] ? editInvoicePage[activeTab] : 1}
                    data-test-id="paginationTestId"
                />
            </>

        )
    };

    secoundTableBody = () => {
        const { classes } = this.props;
        const { textBox1, secoundTableSelectedRows, invoicePerPageRowSecoundSegment, invoicepage  , invoiceComparisonData } = this.state;
        const begin = (invoicepage - 1) * invoicePerPageRowSecoundSegment;
        const end = begin + invoicePerPageRowSecoundSegment;
        const boxData = this.state.invoiceDetailData[this.state.activeTab]?.boxData
        const draggedItems = invoiceComparisonData[this.state.activeTab]?.draggedItems
        
        return (
            <>
            
                <TableBody className={classes.tableBodyBox} style={{ position: 'relative', zIndex: 1 }}>
                    {draggedItems.slice(begin, end).map((boxItems: any, index: number) => (
                        <TableRow id='boxitemid' tabIndex={-1} key={boxItems.id}
                            draggable
                            onDragStart={(e) => this.handleDragStartSecond(e, boxItems)}>
                            {textBox1 && (
                                <Checkbox
                                    id="checkBox"
                                    style={{ color: secoundTableSelectedRows.includes(boxItems) ? 'black' : 'gery' }}
                                    inputProps={{ "aria-label": "select all desserts" }}
                                    checked={secoundTableSelectedRows.includes(boxItems)}
                                    onChange={(e) => this.handleCheckboxChangeSecond(e, boxItems)}
                                />
                            )}
                            <TableCell className="invoiceTableId">{begin + index + 1}.</TableCell>
                            <TableCell className="invoiceTableDescription" style={{ border: secoundTableSelectedRows.includes(boxItems) ? '2px solid black' : '2px solid #d5d5d5' }}><Input id='description' disableUnderline  value={boxItems.description} onChange={(e) => this.editableSecoundTable(e, boxItems.id, 'description')}></Input></TableCell>
                            <TableCell className="invoiceTableQuantity" style={{ border: secoundTableSelectedRows.includes(boxItems) ? '2px solid black' : '2px solid #d5d5d5' }}><Input id='quantity' disableUnderline value={boxItems.ord_quantity} onChange={(e) => this.editableSecoundTable(e, boxItems.id, 'ord_quantity')}></Input></TableCell>
                            <TableCell className="invoiceTableUnit" style={{ border: secoundTableSelectedRows.includes(boxItems) ? '2px solid black' : '2px solid #d5d5d5' }}><Input id='unit' disableUnderline value={boxItems.order_unit} onChange={(e) => this.editableSecoundTable(e, boxItems.id, 'order_unit')}></Input></TableCell>
                            <TableCell className="invoiceTableUnitPrice" style={{ border: secoundTableSelectedRows.includes(boxItems) ? '2px solid black' : '2px solid #d5d5d5' }}>
                                <NumericFormat
                                    id='unit_price'
                                    value={boxItems.price}
                                    onValueChange={(values: any) => this.editableSecoundTable({ target: { value: values.value } }, boxItems.id, 'price')}
                                    thousandSeparator={true}
                                    prefix={'$ '}
                                    allowNegative={false}
                                    decimalScale={2}
                                    displayType="input"
                                    customInput={Input}
                                    disableUnderline
                                />
                            </TableCell>
                            {this.state.invoiceDetailData[this.state.activeTab]?.switch && (
                                <TableCell className="invoiceTableTax" style={{ border: secoundTableSelectedRows.includes(boxItems) ? '2px solid black' : '2px solid #d5d5d5' }}>
                                    <NumericFormat
                                        id='tax'
                                        value={boxItems.tax}
                                        onValueChange={(values: any) => this.editableSecoundTable({ target: { value: values.value } }, boxItems.id, 'tax')}
                                        suffix=" %"
                                        allowNegative={false}
                                        defaultValue={0}
                                        decimalScale={2}
                                        displayType="input"
                                        customInput={Input}
                                        disableUnderline
                                    />
                                </TableCell>)}
                            <TableCell className="invoiceTableTotalPrice" style={{ border: secoundTableSelectedRows.includes(boxItems) ? '2px solid black' : '2px solid #d5d5d5' }}>
                                <NumericFormat
                                    id='amount'
                                    value={boxItems.amount}
                                    onValueChange={(values: any) => this.editableSecoundTable({ target: { value: values.value } }, boxItems.id, 'amount')}
                                    thousandSeparator={true}
                                    prefix={'$ '}
                                    allowNegative={false}
                                    decimalScale={2}
                                    displayType="input"
                                    customInput={Input}
                                    disableUnderline
                                />
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </>
        )
    };

    secondTable = () => {
        const { textBox1, secoundTableSelectAll, invoicePerPageRowSecoundSegment, invoicepage,invoiceComparisonData } = this.state;
        const boxData = this.state.invoiceDetailData[this.state.activeTab]?.boxData
        const countVal = Math.ceil(boxData?.length / invoicePerPageRowSecoundSegment)
        const draggedItems = invoiceComparisonData[this.state.activeTab]?.draggedItems
        
        return (
            <>
                {draggedItems && draggedItems.length > 0 && (
                    
                    <>
                        <TableContainer>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        {textBox1 && (
                                            <Checkbox
                                                id='handleSelectAll'
                                                style={{ color: secoundTableSelectAll ? 'black' : 'grey' }}
                                                inputProps={{ "aria-label": "select all desserts" }}
                                                checked={secoundTableSelectAll}
                                                onChange={this.handleSelectAllSecoundTable}
                                                indeterminate
                                            />
                                        )}
                                        {this.state.invoiceColumn.map((columnHeader: any) => (
                                            (this.state.invoiceDetailData[this.state.activeTab]?.switch || columnHeader.id !== 'tax') && (<TableCell className="invoiceTableHeader" key={columnHeader.id} align={columnHeader.align} style={{ minWidth: columnHeader.minWidth }}>{columnHeader.label}</TableCell>)
                                        ))}
                                    </TableRow>
                                </TableHead>

                                {this.secoundTableBody()}

                            </Table>
                        </TableContainer>
                        <Pagination
                            count={countVal}
                            variant="outlined"
                            shape="rounded"
                            onChange={this.handleChangeinvoiceTablePage}
                            page={invoicepage}
                            data-testid="paginationTestId"
                        />
                    </>
                )}
            </>
        )
    };

    tabOneFunction = () => {
        const { classes } = this.props;
        const { invoiceDetailData, showModal, invoiceComparisonData } = this.state;
        const invoiceMetaData = invoiceDetailData[this.state.activeTab]?.invoice?.data?.attributes
        const invoiceMetaComparisonData = invoiceComparisonData[this.state.activeTab]?.invoice_description
        const item = invoiceMetaData
        const checkedSwitch = invoiceDetailData[this.state.activeTab]?.switch
        const addNewSegment = invoiceDetailData[this.state.activeTab]?.addNewSegment
        const textfieldvalue = invoiceComparisonData[this.state.activeTab]?.searchValue
        const textfieldvalue1 = invoiceDetailData[this.state.activeTab]?.textfieldvalue1
        const draggedItems = invoiceComparisonData[this.state.activeTab]?.draggedItems
   
        return (
            <Grid className="invoiceTab">
                <Box className={classes.invoiceScanBox}>
                    <Grid className="invoiceMainGrid" container spacing={2}>
                        <Typography variant="h5" className={classes.editInoviceTitle}>{configJSON.invoiceTitle}</Typography>
                        <Grid className={classes.invoiceIOSButton}>
                            <Switch
                                data-test-id='switch'
                                checked={checkedSwitch ? true : false}
                                onChange={this.switchIOS}
                                name="checkedB"
                                inputProps={{ "aria-label": "secondary checkbox" }}
                            />
                            <Typography className="switchText">{configJSON.invoiceIosSwitch}</Typography>
                        </Grid>

                    </Grid>
                    <Grid className={classes.invoiceDetailBox}>
                        <Grid container spacing={3}>
                            <Grid item xs={12} sm={6} md={4}>
                                <Typography className={classes.invoiceFistText}>{configJSON.invoiceDetails}</Typography>
                                <Typography>{configJSON.invoiceNumberContent}</Typography>
                                <TextField className={classes.invoiceTextField} id='quote_id' name="quote_id" value={item?.quote_id} onChange={(e) => this.handleTextFieldChange(e)} InputProps={{ disableUnderline: true }} />
                            </Grid>
                            <Grid item xs={12} sm={6} md={4}>
                                <Typography className={classes.invoiceFistText}>{configJSON.invoiceSupplier}</Typography>
                                <Typography>{configJSON.supplierNameContent}</Typography>
                                <TextField className={classes.invoiceTextField} id='project' name='project' value={item?.project} onChange={(e) => this.handleTextFieldChange(e)} InputProps={{ disableUnderline: true }} />
                            </Grid>
                            <Grid item xs={12} sm={6} md={4}>
                                <Typography className={classes.invoiceFistText}>{configJSON.invoiceShip}</Typography>
                                <Typography>{configJSON.siteNameContent}</Typography>
                                <TextField className={classes.invoiceTextField} id='invoice_site_name' name='invoice_site_name' value={item?.invoice_site_name} onChange={(e) => this.handleTextFieldChange(e)} InputProps={{ disableUnderline: true, }} />
                            </Grid>
                            <Grid item xs={12} sm={6} md={4}>
                                <Typography>{configJSON.invoiceShipDateContent}</Typography>
                                <TextField className={classes.invoiceTextField} id='invoice_shipped_date' type="date" name='invoice_shipped_date' value={item?.invoice_shipped_date} onChange={(e) => this.handleTextFieldChange(e)} InputProps={{ disableUnderline: true, }} />
                            </Grid>
                            <Grid item xs={12} sm={6} md={4}>
                                <Typography>{configJSON.supplierAddressContent}</Typography>
                                <TextField className={classes.invoiceTextField} id='invoice_supplier_address' name='invoice_supplier_address' value={item?.invoice_supplier_address} onChange={(e) => this.handleTextFieldChange(e)} InputProps={{ disableUnderline: true, }} />
                            </Grid>
                            <Grid item xs={12} sm={6} md={4}>
                                <Typography>{configJSON.siteAddressContent}</Typography>
                                <TextField className={classes.invoiceTextField} id='invoice_site_address' name='invoice_site_address' value={item?.invoice_site_address} onChange={(e) => this.handleTextFieldChange(e)} InputProps={{ disableUnderline: true, }} />
                            </Grid>
                            <Grid item xs={12} sm={6} md={4}>
                                <Typography>{configJSON.invoiceDateContent}</Typography>
                                <TextField className={classes.invoiceTextField} id='date' type="date" name='date' value={item?.date} onChange={(e) => this.handleTextFieldChange(e)} InputProps={{ disableUnderline: true, }} />
                            </Grid>
                            <Grid item xs={12} sm={6} md={4}>
                                <Typography>{configJSON.salesPersonTitle}</Typography>
                                <TextField className={classes.invoiceTextField} id='invoice_salesperson_name' name='invoice_salesperson_name' value={item?.invoice_salesperson_name} onChange={(e) => this.handleTextFieldChange(e)} InputProps={{ disableUnderline: true, }} />
                            </Grid>
                            <Grid item xs={12} sm={6} md={4}>
                                <Typography>{configJSON.contactPersonTitle}</Typography>
                                <TextField className={classes.invoiceTextField} id='invoice_salesperson_name' name='invoice_site_person' value={item?.invoice_site_person} onChange={(e) => this.handleTextFieldChange(e)} InputProps={{ disableUnderline: true, }} />
                            </Grid>
                            <Grid item xs={12} sm={6} md={4}>
                                <Typography>{configJSON.invoiceTerms}</Typography>
                                <TextField className={classes.invoiceTextField} id='invoice_terms' name='invoice_terms' value={item?.invoice_terms} onChange={(e) => this.handleTextFieldChange(e)} InputProps={{ disableUnderline: true, }} />
                            </Grid>
                            <Grid item xs={12} sm={6} md={4}>
                                <Typography>{configJSON.supplierContactContent}</Typography>
                                <TextField className={classes.invoiceTextField} id='invoice_supplier_contact' name='invoice_supplier_contact' value={item?.invoice_supplier_contact} onChange={(e) => this.handleTextFieldChange(e)} InputProps={{ disableUnderline: true, }} />
                            </Grid>
                            <Grid item xs={12} sm={6} md={4}>
                                <Typography>{configJSON.siteContactContent}</Typography>
                                <TextField className={classes.invoiceTextField} id='invoice_site_contact' name='invoice_site_contact' value={item?.invoice_site_contact} onChange={(e) => this.handleTextFieldChange(e)} InputProps={{ disableUnderline: true, }} />
                            </Grid>
                        </Grid>
                    </Grid>

                    <Box className={invoiceMetaComparisonData.length <= 0 ? [classes.itemtableBox, classes.itemErrorTableBox] : classes.itemtableBox}>
                        <Grid className="invoiceMainGrid">
                            <Grid>
                                <Typography variant="h6" className={classes.invoiceDetailContact}>{configJSON.itemdetailsContent}</Typography>
                            </Grid>
                            <Grid className="invoiceMainGrid" spacing={5}>
                                {(this?.state?.invoiceComparisonData?.[this.state.activeTab]?.selectedRows?.length || this.state.secoundTableSelectedRows.length > 0 )  &&(
                                    
                                    <>
                                        <Button data-test-id='iconText1' className="iconText1" onClick={() => this.handleDeleteSelectedRows11()}>{configJSON.invoiceAllDeleteButton}</Button>
                                        <Dialog className={classes.invoiceButtonModel} open={showModal} data-test-id='closeModal' onClose={() => this.showModelClose()} >
                                            <Grid className={classes.invoiceModelFirst}>
                                                <ErrorOutlineIcon className="invoiceModelIcon" />
                                                <DialogTitle className="invoiceModelDeleteText">{configJSON.deleteItemsTitle}</DialogTitle>
                                            </Grid>
                                            <DialogContent>
                                                <DialogContentText className={classes.modelMailtext}>{configJSON.deleteItemsContent}</DialogContentText>
                                                <DialogContentText className={classes.modelText}>{configJSON.deleteMultipleItemContent} </DialogContentText>
                                            </DialogContent>
                                            <DialogActions className={classes.invoiceModelBothButton}>
                                                <Button className={classes.cancleButtonModel} data-test-id='multipleCancleBtn' onClick={() => this.handleCancleButton()} >{configJSON.invoiceCancel}</Button>
                                                <Button className={classes.deleteButtonModel} data-test-id='multipleDeleteBtn' onClick={this.handleDeleteSelectedRows} >{configJSON.deleteButton}</Button>
                                            </DialogActions>
                                        </Dialog>
                                    </>
                                )}
                                <Button data-test-id='add-circle-button' className="iconText" onClick={() => this.handleAddCircleButton()}>
                                    <AddCircleOutlineIcon className="invoiceIconText" />{configJSON.newSegment}
                                </Button>

                            </Grid>

                        </Grid>

                        {this.state.invoiceDetailData[this.state.activeTab].displayBox && addNewSegment &&
                            <Grid className="invoiceTypographyText">
                                <Grid>
                                    <Typography className="invoiceTextCategory">{configJSON.invoiceCategory}</Typography>
                                </Grid>
                                <Grid>
                                    <TextField data-test-id='category-field' className={classes.invoiceTextFieldFirst} placeholder="Enter name of category" onChange={(e) => this.handleCategory(e)} InputProps={{ disableUnderline: true, }} value={this.state.invoiceComparisonData?.[this.state.activeTab]?.searchValue}/>
                                    {this.state.errorMessage && textfieldvalue && (<Grid className="invoiceErrorMsg">{this.state.errorMessage}</Grid>)}
                                </Grid>
                                <Grid>
                                    <img data-testid='deleteIconTextField' src={deleteIcon} className="deleteIcon" onClick={() => this.handleDeleteIconFirstTextField()} />
                                </Grid>
                            </Grid>
                        }

                        {this.firstTable()}

                        <Box className="itemtableBox">
                            <Grid className="invoiceText1">
                                <Grid>
                                    <Button id='invoiceSecoundIcone' className="invoiceSecoundIcone" onClick={this.handleNewOpenRow}>
                                        <AddCircleOutlineIcon className="invoiceIconText" />{configJSON.addNewItem}
                                    </Button>
                                </Grid>
                                <Grid className="invoiceTypography">
                                    {this.state.displayBox ? <Typography>{configJSON.invoiceCategoryTotal}</Typography> : <Typography>{configJSON.segmentContent}</Typography>}
                                    <TextField
                                        variant="outlined"
                                        value={`$ ${this.calculateFirstTotalPrice()}`}
                                        InputProps={{ readOnly: true }}
                                        disabled
                                    />

                                </Grid>
                            </Grid>
                        </Box>
                        <Box className={classes.itemtableBox1} >
                            {this.state.textBox1 && textfieldvalue1 &&
                                <Grid id='drag-drop' onDragOver={this.handleDragOverSecond} onDrop={this.handleDropSecond}>
                                    <Grid className="invoiceDataCategory" >
                                        <Typography className="invoiceTextCategory1">{configJSON.invoiceCategory}</Typography>&nbsp;
                                        <TextField placeholder="Enter name of category" variant="outlined" />
                                        <img src={deleteIcon} className="deleteIconTable" onClick={() => this.handleSecounTextField()} />
                                    </Grid>
                                    <Grid >
                                        {(!draggedItems || (draggedItems && draggedItems.length === 0) )&& (
                                            <Grid className="invoiceTextBox">
                                                <Typography className="invoiceBoxTextEdit">{configJSON.invoiceBoxText}</Typography>
                                                <Typography className="invoiceBoxTextEdit"><b>{configJSON.invoiceBoxTextBold}</b></Typography>
                                            </Grid>
                                        )}

                                        {this.secondTable()}

                                    </Grid>

                                    <Grid className="invoiceText">
                                        <Grid>
                                            <Button id='secoundTableNewRow' className="invoiceSecoundIcone" onClick={this.secoundTableNewOpenRow}><AddCircleOutlineIcon />&nbsp;{configJSON.addNewItem}</Button>
                                        </Grid>
                                        <Grid className="invoiceTypography">
                                            <Typography className="invoiceTextCategory1">{configJSON.invoiceCategoryTotal}</Typography>&nbsp;&nbsp;
                                            <TextField
                                                variant="outlined"
                                                value={`$ ${this.calculateSecoundTotalPrice()}`}
                                                InputProps={{ readOnly: true }}
                                                disabled
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            }
                        </Box>
                    </Box>
                    <Box className="inoviceTotalAmountBox" >
                        <Grid className={classes.subTotalContentBox}>
                            <Typography>{configJSON.subTotalContent}</Typography>
                            <NumericFormat
                                className={classes.CalculationCombinedTotal}
                                value={this.calculateCombinedTotal()}
                                onValueChange={(values: any) => this.handleTextFieldChange({ target: { name: 'gross_subtotal', value: values.value } })}
                                displayType="input"
                                thousandSeparator={true}
                                prefix={'$ '}
                                disabled
                            />
                        </Grid>
                        <Grid className={classes.subTotalContentBox}>
                            <Typography>{configJSON.chargesContent}</Typography>
                            <NumericFormat
                                className={classes.invoiceTextFieldSecound}
                                id='invoice_s_and_h_charges'
                                name="invoice_s_and_h_charges"
                                value={item?.invoice_s_and_h_charges}
                                onValueChange={(values: any) => this.handleTextFieldChange({ target: { name: 'invoice_s_and_h_charges', value: values.value } })}
                                displayType="input"
                                thousandSeparator={true}
                                prefix={'$ '}
                                customInput={Input}
                                disableUnderline
                            />
                        </Grid>
                        <Grid className={classes.subTotalContentBox}>
                            <Typography>{configJSON.otherchargesContent}</Typography>
                            <NumericFormat
                                className={classes.invoiceTextFieldSecound}
                                id='invoice_other_charges'
                                name="invoice_other_charges"
                                value={item?.invoice_other_charges}
                                onValueChange={(values: any) => this.handleTextFieldChange({ target: { name: 'invoice_other_charges', value: values.value } })}
                                displayType="input"
                                thousandSeparator={true}
                                prefix={'$ '}
                                customInput={Input}
                                disableUnderline
                            />
                        </Grid>
                        <Grid className={classes.subTotalContentBox}>
                            <Typography>{configJSON.discountContent}</Typography>
                            <NumericFormat
                                className={classes.invoiceTextFieldSecound}
                                id='discount'
                                name="discount"
                                value={item?.discount}
                                onValueChange={(values: any) => this.handleTextFieldChange({ target: { name: 'discount', value: values.value } })}
                                displayType="input"
                                thousandSeparator={true}
                                prefix={'$ '}
                                customInput={Input}
                                disableUnderline
                            />
                        </Grid>
                        <Grid className={classes.taxContent}>

                            <Grid>
                                <Typography>{configJSON.taxContent}</Typography>
                            </Grid>

                            <Grid className={classes.taxInputBox}>
                                <NumericFormat
                                    className={classes.invoiceTextFieldTaxSign}
                                    id='invoice_tax_percent'
                                    name="invoice_tax_percent"
                                    value={Math.round(parseFloat(item?.invoice_tax_percent || 0))}
                                    onValueChange={(values: any) => this.handleTextFieldChange({ target: { name: 'invoice_tax_percent', value: values.value } })}
                                    displayType="input"
                                    suffix=" %"
                                    customInput={Input}
                                    disableUnderline
                                />
                                <NumericFormat
                                    className={classes.invoiceTextFieldTaxTotalLast}
                                    id='invoice_tax'
                                    name="invoice_tax"
                                    value={this.calculateTaxTotal(item).toFixed(2)}
                                    onValueChange={(values: any) => this.handleTextFieldChange({ target: { name: 'invoice_tax', value: values.value } })}
                                    displayType="input"
                                    thousandSeparator={true}
                                    prefix={'$ '}
                                    customInput={Input}
                                    disableUnderline
                                />
                            </Grid>
                        </Grid>

                        <Grid className={classes.subTotalContentBox}>
                            <Typography className={classes.invoiceTextLastTotal}>{configJSON.totalContent}</Typography>
                            <NumericFormat
                                id='totalTax'
                                className={classes.CalculationCombinedTotal}
                                value={this.allTotal(item).toFixed(2)}
                                onValueChange={(values) => this.handleTextFieldChange({ target: { name: 'totalTax', value: values.value } })}
                                displayType="input"
                                thousandSeparator={true}
                                prefix={'$ '}
                                disabled
                            />
                        </Grid>
                    </Box>
                </Box>
            </Grid>
        )
    }

    // Customizable Area End

    render() {
        // Customizable Area Start
        const { classes, navigation, handleSaveData } = this.props        
        // Customizable Area End
        return (
            // Customizable Area Start
            <>
                <Header navigation={navigation} handleSaveData={handleSaveData} history={this.props.history} />
                <Box className={classes.invoiceHeaderName}>
                    <Box >
                        <Breadcrumbs separator={">"} aria-label="breadcrumb">
                            <Link color="inherit" className={classes.headerLink} href="/DashboardWeb">{configJSON.invoiceDashbord}</Link>
                            <Link color="inherit" className={classes.headerLink} onClick={this.handleCancelButton}>{configJSON.createNewReport}</Link>
                            <Typography className={classes.headerLinkColor}>{configJSON.invoiceTitle}</Typography>
                        </Breadcrumbs>
                    </Box>
                </Box>
                <Tabs value={this.state.activeTab} onChange={this.handleChangeTab} className={classes.invoiceInnerBox2} data-testid="tabChangeid">
                    {this.state.invoiceTabs?.map((data: any) => {
                        return (
                            <Tab
                                label={
                                    <>
                                        <Box className={classes.invoicecurveBox}>
                                            <Box className={classes.invoicecurveInnerBox}></Box>
                                        </Box>
                                        <Typography variant="subtitle2" className={classes.invoiceTabData}>{data.name}</Typography>
                                        <Box className={classes.invoicecurveRightBox}>
                                            <Box className={classes.curveRightinvoiceInnerBox}></Box>
                                        </Box>
                                    </>
                                }
                                {...documentOcra11yProps(1)}
                                className={classes.invoiceTitleBox}
                            />
                        )
                    })
                    }
                </Tabs>
                {this.tabOneFunction()}

                <Grid className={classes.invoiceButton}>
                    <Button id='cancelButton' className={classes.goBackutton} onClick={() => this.handleCancelButton()} >{configJSON.invoiceCancel}</Button>
                    <Button id='editApiCall' variant="contained" className={classes.invoiceSave} disabled={this.state.invoiceComparisonData.length === 0} onClick={() => this.editApiCall()}>{configJSON.invoiceSave}</Button>
                </Grid>

            </>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
export const InvoiceEditStyle = () => ({
    invoiceInnerBox2: {
        top: 0,
        width: '100%',
        overflow: 'visible',
        '& MuiBox-root-67': {
            padding: 0
        },
        '& span.MuiTabs-indicator': {
            display: 'none'
        },
        '& .MuiTabs-scroller.MuiTabs-fixed': {
            overflow: 'visible !important'
        },
        '& span': {
            textTransform: 'none',
        },
        '& div:nth-child(2)': {
            margin: '0 9px 0 16px'
        },
    },
    invoiceHeaderName: {
        padding: "0px 0 30px 0"
    },
    headerLink: {
        textDecoration: "underline"
    },
    headerLinkColor: {
        color: "orange"
    },
    invoicecurveBox: {
        background: '#fcf7f2',
        width: '31px',
        bottom: 0,
        left: '-31px',
        height: '30px',
        position: 'absolute' as 'absolute',
    },
    invoicecurveInnerBox: {
        borderBottomRightRadius: '15px',
        borderTop: 'none',
        height: '30px',
        border: '1px solid #e0802e',
        borderLeft: 'none',
        width: '31px',
    },
    invoiceTabData: {
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        maxWidth: "8ch"
    },

    invoicecurveRightBox: {
        right: '-30px',
        background: '#fcf7f2',
        position: 'absolute' as 'absolute',
        height: '33px',
        bottom: 0,
        zIndex: 99,
        width: '30px',
    },
    curveRightinvoiceInnerBox: {
        borderBottomLeftRadius: '15px',
        width: '30px',
        borderTop: 'none',
        borderRight: 'none',
        border: '1px solid #e0802e',
        height: '32px',
    },
    invoiceTitleBox: {
        borderTopRightRadius: '12px',
        borderTopLeftRadius: '12px',
        minWidth: '115px',
        textAlign: 'center' as 'center',
        padding: '13px 0px',
        border: '0.5px solid #e4e3e3',
        // borderBottom: 'none',
        backgroundColor: "white",
        '&.Mui-selected': {
            borderTopLeftRadius: '12px',
            borderTopRightRadius: '12px',
            border: '0.5px solid white',
            background: '#ffffff',
            borderBottom: 'none'
        },
    },
    documentsInnerBox2: {
        width: '100%',
        marginLeft: "12px",
        top: 0,
        overflow: 'visible',
        '& .MuiTabs-scroller.MuiTabs-fixed': {
            overflow: 'visible !important'
        },
        '& span.MuiTabs-indicator': {
            display: 'none'
        },
        '& span': {
            textTransform: 'capitalize',
        },
        '& div:nth-child(2)': {
            margin: '0 9px 0 16px'
        },
        '& MuiBox-root-67': {
            padding: 0
        },
        '& .MuiOutlinedInput-root. Mui-focused .MuiOutlinedInput-notchedOutline ': {
            border: '1px solid gray',
        },

    },
    invoiceFistText: {
        fontWeight: 600,
    },
    documentsTitletBox: {
        minWidth: '115px',
        textAlign: 'center' as 'center',
        padding: '13px 0px',
        border: '1px solid gray',
        borderTopLeftRadius: '15px',
        borderTopRightRadius: '15px',
        borderBottom: 'none',
        backgroundColor: "white",
        '&.Mui-selected': {
            background: '#ffffff',
            border: '1px solid #gray',
            borderTopLeftRadius: '18px',
            borderTopRightRadius: '18px',
            borderBottom: 'none'
        },
    },
    invoiceTabPdf: {
        textTransform: "none" as "none",
    },
    invoiceIOSButton: {
        display: 'flex',
        alignItems: 'center',
        "& .switchText": {
            margin: "3px 30px 0px 0px",
            fontWeight: 400,

        },
        "& .MuiSwitch-root": {
            height: "46px",
            width: "62px",
        },
        "& .MuiSwitch-thumb": {
            marginTop: "-3px",
        },
        "& .MuiSwitch-colorSecondary, .Mui-checked": {
            color: "white",
        },
        "& .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track": {
            backgroundColor: "#000000",
            opacity: 1,
        },
        "& .MuiSwitch-track": {
            borderRadius: "12px",
        },
        "& .MuiSwitch-switchBase.Mui-checked": {
            transform: "translateX(14px)",
        },
        "& .MuiSwitch-switchBase": {
            left: "5px",
        },
    },
    invoiceScanBox: {
        borderRadius: '10px',
        background: '#fff',
        padding: '50px 30px 50px 50px',
        '& p': {
            color: '#000',
            margin: '20px 0 8px'
        },
        "& .invoiceSecoundIcone": {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            border: "1px solid #000000ab",
            padding: "10px",
            textTransform: "none",
        },
        "& .invoiceMainGrid": {
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "20px",
            color: "#000000",
            gap: "20px",
            marginTop: "20px"
        },
        '& .inoviceTotalAmountBox': {
            border: '2px solid #0000002e',
            borderRadius: '8px',
            padding: '20px',
            width: '45%',
            margin: '0 20px 0 auto',
            marginTop: '50px',
        },
        '& .invoiceBothTable': {
            fontWeight: 500,
            color: "grey"
        },
        '& .invoiceTotalLast': {
            fontWeight: 500,
            color: "rgb(5, 5, 43)",
            marginLeft: "12px"

        },
        '& .invoiceTypography': {
            display: "flex",
            alignItems: "center"
        },
        "& .invoiceErrorMsg": {
            color: 'red',
            fontSize: "15px",
            marginTop: "8px",
            textAlign: "center"
        },
        '& .invoiceTypographyText': {
            display: "flex",
        },
        '& .invoiceText': {
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginTop: "65px"
        },
        '& .invoiceText1': {
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginTop: "65px",
            "& .invoiceTypography": {
                "& p": {
                    margin: '15px'
                }
            }
        },
        '& .MuiFilledInput-underline::before': {
            display: 'none'
        },
        '& .MuiFilledInput-underline::after': {
            display: 'none'
        },
        '& .invoiceDataCategory': {
            display: "flex",
            alignItems: "center",
            marginTop: "20px"
        },
        '&. invoiceAddNew': {
            display: 'flex',
            justifyContent: "right",
            alignItems: "right",

        },
        '& .invoiceTab': {
            display: 'flex',
            justifyContent: "space-between",
            alignItems: "center",
        },
        '& .invoiceBoxTextEdit': {
            textAlign: 'center',
        },
        '& .invoiceTextBox': {
            border: "1px solid #E6B25C",
            marginTop: "50px",
            padding: "10px",
            borderRadius: "10px",
        },
        '& .iconText': {
            display: 'flex',
            justifyContent: "space-between",
            alignItems: "center",
            border: "1px solid #000000ab",
            padding: "10px",
            textTransform: "none",
        },
        '& .iconText1': {
            border: "1px solid #ff0000ad",
            color: "#ff0000ad",
            padding: "10px",
            textTransform: "none",
            height: "47px",
        },
        '& input': {
            padding: '17px',
            fontWeight: 500,
            paddingLeft: "18px",
        },

        '& .MuiTable-root': {
            borderCollapse: 'separate',
            borderSpacing: '15px'
        },
        '& .MuiTableContainer-root': {
            overflowX: 'unset',
        },
        '& .MuiIconButton-label': {
            marginTop: "7px "
        }
    },
    invoiceButton: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center"
    },
    editInoviceTitle: {
        color: '#000',
        fontWeight: 600
    },
    invoiceDetailBox: {
        paddingBottom: '20px',
        marginTop: '10px',
        '& .MuiFormControl-root': {
            width: '95%'
        }
    },
    invoiceDetailContact: {
        color: '#000',
        fontWeight: 600,
        fontSize: '18px',
        '& .MuiTypography-h6 ': {
            color: 'red',
        },

    },

    invoiceButtonModel: {
        '& .MuiDialog-paperWidthSm': {
            maxWidth: "460px",
            padding: "12px",
            borderRadius: "10px"
        },
    },


    tableBodyBox: {
        "& td": {
            border: '2px solid #d5d5d5',
            borderRadius: '5px',
            color: 'black',
            fontWeight: 500,
        },
        ' & .invoiceTableId': {
            width: "6%",
            textAlign: "end",
            color: '#5c6066 !important',
            height: "50px"
        },
        ' & .invoiceTableDescription': {
            width: "40%",
            padding: "0%",
        },
        ' & .invoiceTableQuantity': {
            width: "12%",
            padding: "0%",
        },
        ' & .invoiceTableUnit': {
            width: "12%",
            padding: "0%"
        },
        ' & .invoiceTableUnitPrice': {
            width: "13%",
            padding: "0%"
        },
        ' & .invoiceTableTax': {
            padding: "0%",
            width: "6%"
        },
        ' & .invoiceTableTotalPrice': {
            width: "14%",
            padding: "0%",
            color: "#000000c4",
        },
        ' & .dollerSign': {
            position: "absolute",
            padding: "16px 0 0 8px",
            fontSize: "15px",
            color: "rgb(5, 5, 43)"
        },
        ' & .taxSign': {
            position: "absolute",
            padding: "16px 0 0 45px",
            fontSize: "15px",
            color: "rgb(5, 5, 43)"
        },
        '& .InoviceDeleteIcon': {
            width: "50%",
            marginTop: "15px",
            cursor: "pointer"
        },

    },
    rowDeleteModel: {
        "& .MuiBackdrop-root": {
            backgroundColor: '#0000000f',
        },
        "& .MuiPaper-elevation24": {
            boxShadow: 'none',
            maxWidth: "440px",
        },
        "& .MuiDialog-paperWidthSm": {
            maxWidth: "460px",
            padding: "10px",
            borderRadius: "10px"
        },
    },
    subTotalContentBox: {
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: '20px',
        '& .MuiFormControl-root': {
            width: '35%',
        },
        '& .MuiInputBase-input': {
            border: 'none',
        }
    },
    taxContent: {
        display: 'flex',
        marginBottom: '20px',
        justifyContent: 'space-between',
    },
    taxInputBox: {
        textAlign: 'right' as 'right',
        display: "flex",
        gap: "10px",
        '& .MuiFormControl-root': {
            width: '37%',
        },
        '& .MuiFormControl-root:first-child': {
            width: '20%',
            marginRight: '20px',
        },
    },

    invoiceModelFirst: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        '& .invoiceModelIcon': {
            color: "#e7706e",
            marginRight: "-19px"
        },
        '& .invoiceModelDeleteText': {
            color: "rgb(5, 5, 43)"
        },
    },

    invoiceModelBothButton: {
        gap: "7px",
        marginRight: "65px"
    },

    cancleButtonModel: {
        color: "#ffff",
        border: "1px solid black",
        width: "40%",
        backgroundColor: "rgb(5, 5, 43)",
        textTransform: "none",
        height: "45px",
        "&:hover": {
            backgroundColor: "#ffff",
            color: "rgb(5, 5, 43)"
        },
    },
    modelText: {
        color: "#d38a12",
        textAlign: "center",
        fontWeight: 500
    },
    modelMailtext: {
        textAlign: "center",
        color: "#060513"
    },
    deleteButtonModel: {
        color: "black",
        border: "1px solid black",
        width: "40%",
        height: "45px",
        textTransform: "none",
        "&:hover": {
            backgroundColor: "rgb(5, 5, 43)",
            color: "#ffff"
        },
    },
    itemtableBox: {
        marginRight: '29px',
        '& table': {
            position: 'relative'
        },
        '& table::after': {
            content: '""',
            border: '2px solid #0000002e',
            borderRadius: '10px',
            position: 'absolute',
            top: '63px',
            left: '0px',
            width: '100%',
            height: '91%',
            zIndex: 0
        },
        '& .MuiPagination-root': {
            marginTop: '6%',
            background: '#fff',
            boxShadow: '0 0 7px #e3d3d3',
            display: 'inline-block',
            padding: '15px 20px',
        },
        '& .invoiceIconText': {
            margin: "0 10px 0 0",
            color: "#212121"
        },
        '& .invoiceTableHeader': {
            fontWeight: 600,
            borderBottom: "none",
            padding: '13px 4px',
            fontSize: "15px"
        },
        '& .invoiceTextCategory': {
            margin: "15px 20px 0 0"
        },
        "& .deleteIcon": {
            height: "23px",
            marginTop: "13px",
            cursor: "pointer",
            marginLeft: "25px"
        },
        '& .invoiceTextCategory1': {
            margin: "15px 20px 13px 0"
        },
        '& .deleteIconTable': {
            height: "23px",
            cursor: "pointer",
            marginLeft: "25px"
        },

    },
    itemErrorTableBox: {
        '& table::after': {
            content: '""',
            border: '2px solid red',
            borderRadius: '10px',
            position: 'absolute',
            top: '63px',
            left: '0px',
            width: '100%',
            height: '90%',
            zIndex: 0
        },
    },
    itemtableBox1: {
        marginRight: '29px',
        '& table': {
            position: 'relative'
        },
        '& table::after': {
            content: '""',
            border: '2px solid #0000002e',
            borderRadius: '10px',
            position: 'absolute',
            top: '63px',
            left: '5px',
            width: '100%',
            height: '90%',
            zIndex: 0
        },
        '& .MuiPagination-root': {
            marginTop: '5%',
            background: '#fff',
            boxShadow: '0 0 7px #e3d3d3',
            display: 'inline-block',
            padding: '15px 20px',
        }
    },
    invoiceTextLastTotal: {
        fontWeight: 600
    },
    goBackutton: {
        padding: '15px 60px',
        border: '1px solid #000',
        background: 'transparent',
        color: '#000',
        fontSize: '16px',
        marginTop: '30px',
        display: 'block',
        textTransform: "none" as "none",
    },
    invoiceSave: {
        background: '#05052b',
        color: 'white',
        padding: '15px 60px',
        fontSize: '16px',
        marginTop: '30px',
        border: '1px solid #000',
        display: 'block',
        textTransform: "none" as "none",
        "&.MuiButton-root:hover": {
            background: "#05052b",
        },
        '&.MuiButton-contained.Mui-disabled': {
            color: '#ffffffb8 !important',
            boxShadow: 'none',
            backgroundColor: '#3939487a !important',
            borderColor: '#3939487a !important',
        }

    },
    // documentsInnerBox1: {
    //     "& .PrivateTabIndicator-colorSecondary-37": {
    //         backgroundColor: "#a6a6a6 !important"
    //     }
    // },
    invoiceTextField: {
        border: '2px solid #d5d5d5',
        borderRadius: "5px",
        padding: "2px",
        width: "52%"
    },
    invoiceTextFieldFirst: {
        border: '2px solid #d5d5d5',
        borderRadius: "5px",
        padding: "2px",
        width: "100%"
    },
    invoiceTextFieldSecound: {
        border: '2px solid #d5d5d5',
        borderRadius: "5px",
        padding: "2px",
        width: "46%"
    },
    invoiceTextFieldTaxTotalLast: {
        border: '2px solid #d5d5d5',
        borderRadius: "5px",
        padding: "2px",
        width: "58%"
    },
    invoiceTextFieldTaxSign: {
        border: '2px solid #d5d5d5',
        borderRadius: "5px",
        padding: "2px",
        width: "27%",
        marginLeft: "157px"
    },
    CalculationCombinedTotal: {
        border: '2px solid #d5d5d5',
        borderRadius: "5px",
        padding: "2px",
        width: "46%",
        fontSize: "15px",
        fontWeight: 400
    }
})

export { EditInvoice }
export default withStyles((InvoiceEditStyle) as any)(EditInvoice)


// Customizable Area End