// Customizable Area Start
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { IBlock } from "../../../framework/src/IBlock";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  // Customizable Area Start
  title: string;
  hasMenu: boolean;
  classes: any;
  handleReportBackClick: () => void;
  history: any;
  handleSaveData: (data: []) => void;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  reportsInfo: any,
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  // Customizable Area End
}

export default class ReportsController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  reportsApi: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage)
    ]

    this.state = {
      // Customizable Area Start
      reportsInfo: [],
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  handleReportBackClick = () => {
    this.props.history.goBack()
  }

  async componentDidMount() {
    this.reportsData()
  }
  
  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiRequestCallId === this.reportsApi) {
        this.setState({ reportsInfo: responseJson.reports.data.map((item: { attributes: []; }, i: number) => item.attributes) })
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start

  reportsData = () => {

    const header = {
      "Content-Type": configJSON.productApiContentType,
      "token": document.cookie.split("; ").find((row) => row.startsWith("Token="))?.split("=")[1]
    };

    const requestReportsMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.reportsApi = requestReportsMessage.messageId;

    requestReportsMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.reportsAPiEndPoint
    );

    requestReportsMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestReportsMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );

    runEngine.sendMessage(requestReportsMessage.id, requestReportsMessage);
    return true;
  }
  // Customizable Area End
}
