export const imgPasswordVisible = require("../../global_assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../../global_assets/ic_password_invisible.png");
export const downloadIcon = require("../assets/download.svg")
export const deleteIcon = require("../assets/delete.svg")
export const editIcon = require("../assets/edit.svg")
export const filterIcon = require("../assets/filter-svgrepo-com.svg")
export const closeIcon = require("../assets/close.svg")
export const deleteMsgIcon = require("../assets/error 1.svg")

export const cancelupload = require("../assets/cancel-upload-icon.svg");
export const pauseUpload = require("../assets/pause-upload-icon.svg");
export const filesUploadIcon = require("../assets/upload-files-icon.svg");

export const successUpload = require("../assets/upload-success-icon.svg");
export const failedUpload = require("../assets/upload-failed-icon.svg");

