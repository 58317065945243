import React, { Component } from 'react'
// Customizable Area Start
import { Tabs, Tab, AppBar, Typography, Box } from "@material-ui/core";
import { styled } from '@material-ui/styles'
import { View } from 'react-native';
import NotificationListWeb from "./NotificationListWeb.web"
// Customizable Area End
// Customizable Area Start
const ViewBlock = styled(View)({
    '& .MuiPaper-root.MuiAppBar-root': {
        backgroundColor: 'transparent',
        boxShadow: 'none',
        marginBottom: '15px',
    },
    '& .MuiPaper-root.MuiAppBar-root button.MuiButtonBase-root': {
        minWidth: '130px',
        borderRadius: '6px',
        marginRight: '4px',
        color: '#111',
        '&:hover': {
            backgroundColor: 'rgb(223, 128, 45)',
            color: '#fff',
        }
    },
    '& .MuiPaper-root.MuiAppBar-root .MuiButtonBase-root.Mui-selected': {
        backgroundColor: 'rgb(223, 128, 45)',
        color: '#fff !important',
    },
    '& .MuiPaper-root.MuiAppBar-root .MuiTabs-indicator': {
        display: 'none',
    },
    '& .MuiBox-root-16': {
        padding: "12px 24px",
    },
})
// Customizable Area End

interface S {
    // Customizable Area Start
    value: number;
    // Customizable Area End
}
// Customizable Area Start
interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
}
// Customizable Area End
// Customizable Area Start
function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-auto-tabpanel-${index}`}
            aria-labelledby={`scrollable-auto-tab-${index}`}
            {...other}
        >
            {value === index && (
                <>
                    {/* 
        // @ts-ignore */}
                    <Box p={3}>
                        <Typography>{children}</Typography>
                    </Box>
                </>
            )}
        </div>
    );
}

function a11yProps(index: any) {
    return {
        id: `scrollable-auto-tab-${index}`,
        'aria-controls': `scrollable-auto-tabpanel-${index}`,
    };
}
// Customizable Area End
export default class ScrollableTabsWeb extends Component<S>{
    constructor(props: S) {
        super(props)
        // Customizable Area Start
        this.state = {
            value: 0
        }
        // Customizable Area End
    }
    // Customizable Area Start
    handlevalueChange = (event:React.ChangeEvent<{}>,newValue: number) => {
        this.setState({ value: newValue });
    };
    // Customizable Area End

    render() {
        return (
            <>
                {/* 
        // @ts-ignore */}
                <ViewBlock>
                    <AppBar position="static" color="default">
                        <Tabs
                            data-test-id="Tabs"
                            // @ts-ignore 
                            value={this.state.value}
                            onChange={this.handlevalueChange}
                            indicatorColor="primary"
                            textColor="primary"
                            variant="scrollable"
                            scrollButtons="off"
                            aria-label="scrollable auto tabs example"
                        >
                            <Tab label="All" {...a11yProps(0)} />
                            <Tab label="Qoutes" {...a11yProps(1)} />
                            <Tab label="Invoices" {...a11yProps(2)} />
                            <Tab label="Projects" {...a11yProps(3)} />
                            <Tab label="Vendors" {...a11yProps(4)} />
                            <Tab label="Item Six" {...a11yProps(5)} />
                            <Tab label="Item Seven" {...a11yProps(6)} />
                            <Tab label="Item eight" {...a11yProps(7)} />
                            <Tab label="Item Nine" {...a11yProps(8)} />
                            <Tab label="Item Ten" {...a11yProps(9)} />
                        </Tabs>
                    </AppBar>
                    {/* 
        // @ts-ignore */}
                    <TabPanel value={this.state.value} index={0}>
                        {/* 
        // @ts-ignore */}
                        <NotificationListWeb />
                    </TabPanel>
                    {/* 
        // @ts-ignore */}
                    <TabPanel value={this.state.value} index={1}>
                        {/* 
        // @ts-ignore */}
                        <NotificationListWeb Qoutes={"Qoutes"} />
                    </TabPanel>
                    {/* 
        // @ts-ignore */}
                    <TabPanel value={this.state.value} index={2}>
                        {/* 
        // @ts-ignore */}
                        <NotificationListWeb Invoices={"Invoices"} />
                    </TabPanel>
                    {/* 
        // @ts-ignore */}
                    <TabPanel value={this.state.value} index={3}>
                        {/* 
        // @ts-ignore */}
                        <NotificationListWeb />
                    </TabPanel>
                    {/* 
        // @ts-ignore */}
                    <TabPanel value={this.state.value} index={4}>
                        {/* 
        // @ts-ignore */}
                        <NotificationListWeb />
                    </TabPanel>
                    {/* 
        // @ts-ignore */}
                    <TabPanel value={this.state.value} index={5}>
                        {/* 
        // @ts-ignore */}
                        <NotificationListWeb />
                    </TabPanel>
                    {/* 
        // @ts-ignore */}
                    <TabPanel value={this.state.value} index={6}>
                        {/* 
        // @ts-ignore */}
                        <NotificationListWeb />
                    </TabPanel>
                    {/* 
        // @ts-ignore */}
                    <TabPanel value={this.state.value} index={7}>
                        {/* 
        // @ts-ignore */}
                        <NotificationListWeb />
                    </TabPanel>
                    {/* 
        // @ts-ignore */}
                    <TabPanel value={this.state.value} index={8}>
                        {/* 
        // @ts-ignore */}
                        <NotificationListWeb />
                    </TabPanel>
                    {/* 
        // @ts-ignore */}
                    <TabPanel value={this.state.value} index={9}>
                        {/* 
        // @ts-ignore */}
                        <NotificationListWeb />
                    </TabPanel>
                </ViewBlock>
            </>
        )
    }
}
